import { TransformToDate } from "@daytrip/legacy-transformers";
import { Expose, Transform } from "class-transformer";
import { IsBoolean, IsDefined, IsEnum, IsInt, IsString, IsUUID } from "class-validator";
import { v4 as uuid } from "uuid";

import { Currency } from "../domain/Currency";
import { CDBaseEntity, CDColumn, CDCreateDateColumn, CDEntity, CDObjectIdColumn } from "../orm";

@CDEntity("penalties")
export class Penalty extends CDBaseEntity {
    @CDObjectIdColumn()
    @IsUUID("4")
    @Expose()
    public _id: string = uuid();

    @CDColumn()
    @IsInt()
    @IsDefined()
    @Expose()
    @Transform((value) => (value >= 0 ? value : 0))
    public value: number;

    @CDColumn()
    @IsString()
    @Expose()
    public managementNote?: string;

    @CDColumn()
    @IsBoolean()
    @Expose()
    public shouldAffectRating: boolean = true;

    @CDColumn()
    @IsString()
    @IsDefined()
    @Expose()
    public description: string;

    @CDColumn()
    @IsEnum(Currency)
    @IsDefined()
    @Expose()
    public currency = Currency.Euro;

    @CDCreateDateColumn()
    @Expose()
    @TransformToDate
    public createdAt: Date = new Date();
}
