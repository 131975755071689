/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
/* eslint-disable max-classes-per-file */
import type { ParameterlessConstructor } from "@legacy/utils";
import autobind from "autobind-decorator";
import { plainToClass } from "class-transformer";
import type { ValidationError } from "class-validator";
import { validate, ValidatorOptions } from "class-validator";
import isNil from "lodash/isNil";
import type { IReactionDisposer } from "mobx";
import { action, computed, extendObservable, observable, reaction, toJS } from "mobx";

import { getAuthenticationStore } from "../domain/authentication/AuthenticationStore.singleton";
import { globalManagementLogger } from "../global-logger";
import { getRpcClient } from "../rpc-browser-sdk";
import { FetchDataStatus } from "../utils/FetchDataStatus";
import { observeModel } from "../utils/observeModel";

import type { ModelOperatorOptions } from "./ModelOperatorOptions";

@autobind
export class ModelOperator<TModel, TOptions, TModules, TData, TDataFetched> {
    public toJSON() {
        throw Error("Don't stringify whole operator!");
    }

    protected rpcClient = getRpcClient();

    protected authenticationStore = getAuthenticationStore();

    public modelConstructor: ParameterlessConstructor<TModel>;

    @observable
    public model: TModel;

    @observable
    public editedModel?: TModel;

    public skipMissingPropertiesBeforeSave?: boolean;

    public validateTimeout: number;

    public validateOptions: ValidatorOptions;

    public nonObservableProperties: Array<keyof TModel>;

    // service (Don't use them for data fetching)
    public modules: TModules;

    // data like locations, and so..
    @observable
    public data: TData;

    public constructor(options: ModelOperatorOptions<TModel, TModules, TData> & TOptions) {
        this.modelConstructor = options.modelConstructor;
        const { onFetchData } = options;

        this.model = options.model || new options.modelConstructor();

        this.nonObservableProperties = options.nonObservableProperties ?? [];

        observeModel(this.model, this.nonObservableProperties as string[]);

        // data fetching

        if (options.isDataFetchedCondition) {
            this.isDataFetchedCondition = options.isDataFetchedCondition;
        }

        if (onFetchData) {
            this.onFetchData = async (...arg) => {
                await onFetchData(...arg);
                return FetchDataStatus.Success;
            };
        }

        this.modules = options.modules;

        this.data = options.data;

        // edit

        if (options.beforeEdit) {
            this.beforeEdit = options.beforeEdit;
        }

        if (options.afterEdit) {
            this.afterEdit = options.afterEdit;
        }

        if (options.canCancelEdit) {
            this.canCancelEdit = options.canCancelEdit;
        }

        // save

        if (options.beforeSave) {
            this.beforeSave = options.beforeSave;
        }

        if (options.onSave) {
            this.onSave = options.onSave;
        }

        if (options.afterSave) {
            this.afterSave = options.afterSave;
        }

        // validate

        if (options.beforeValidate) {
            this.beforeValidate = options.beforeValidate;
        }

        if (options.afterValidate) {
            this.afterValidate = options.afterValidate;
        }

        this.validateTimeout = options.validateTimeout ?? 1000;
        this.validateOptions = options.validateOptions || {};

        if (options.skipMissingPropertiesBeforeSave) {
            this.skipMissingPropertiesBeforeSave = options.skipMissingPropertiesBeforeSave;
            this.validateOptions.skipMissingProperties = true;
        }
    }

    @computed
    public get m(): TModel {
        return this.isEdited() ? this.editedModel : this.model;
    }

    // data fetching

    public isDataFetchedCondition = (_operator: this) => true;

    public isDataFetched(): this is this & TDataFetched {
        return this.isDataFetchedCondition(this);
    }

    @observable
    public fetchDataStatus = FetchDataStatus.NotFetched;

    @action
    public onFetchData = async (_operator: this) => FetchDataStatus.Success;

    @action
    public async fetchData(): Promise<FetchDataStatus> {
        this.fetchDataStatus = FetchDataStatus.Fetching;
        try {
            this.fetchDataStatus = await this.onFetchData(this);
            if (this.fetchDataStatus == FetchDataStatus.Fetching) {
                this.fetchDataStatus = FetchDataStatus.Success;
            }
        } catch (e: any) {
            globalManagementLogger.error(e);
            this.fetchDataStatus = FetchDataStatus.Error;
        }
        return this.fetchDataStatus;
    }

    public processWhenDataFetched<TResult>(
        callback: (self: this & { data: TData & TDataFetched }) => TResult,
    ): TResult | undefined {
        if (this.isDataFetched()) {
            return callback(this as any);
        }

        return undefined;
    }

    // validations

    @observable
    public validations: ValidationError[] = [];

    @computed
    public get isValid(): boolean {
        return this.validations.length === 0;
    }

    public getValidations(propertyName: keyof TModel): Array<ValidationError> {
        return this.validations.filter((v) => v.property === propertyName);
    }

    public getAllValidationErrorsMessages(): Array<string> {
        const getValidationMessages = (validations: Array<ValidationError>) => {
            const validationErrors: Array<string> = [];
            validations.forEach((error) => {
                Object.keys(error.constraints || {}).forEach((childKey) => {
                    validationErrors.push(`${error.constraints && error.constraints[childKey]}\n`);
                });
                if (error.children && error.children.length > 0) {
                    validationErrors.push(...getValidationMessages(error.children));
                }
            });
            return validationErrors;
        };
        return getValidationMessages(this.validations);
    }

    public getValidationMessages(propertyName: keyof TModel): string | undefined {
        const getValidationMessages = (validationErrors: Array<ValidationError>): string => {
            let validationMeassage = "";
            validationErrors.forEach((error) => {
                // eslint-disable-next-line no-restricted-syntax
                for (const childKey in error.constraints) {
                    validationMeassage += `${error.constraints[childKey]}\n`;
                }
                if (error && error.children?.length) {
                    validationMeassage += getValidationMessages(error?.children);
                }
            });
            return validationMeassage;
        };

        const validationErros = this.getValidations(propertyName);
        const result = getValidationMessages(validationErros);

        return result || undefined;
    }

    public validationAlert(): void {
        alert(
            `Oh, something is wrong. :(\n\nValidation error: \n${this.validations
                .map((v) => this.getValidationMessages(v.property as keyof TModel))
                .join("")}`,
        );
    }

    @observable
    public isValidating = false;

    public beforeValidate = async (_validations: Array<ValidationError>) => {};

    public afterValidate = async (_validations: Array<ValidationError>) => {};

    @action
    public async validate(options?: ValidatorOptions): Promise<void> {
        await this.beforeValidate(this.validations);

        this.isValidating = true;
        let validations = await validate(this.m as Object, options || this.validateOptions);
        if (
            options != undefined &&
            options.skipMissingProperties == true &&
            this.skipMissingPropertiesBeforeSave == true
        ) {
            // TODO add support for nested objects
            validations = validations.filter((v) => v.constraints?.isDefined == undefined);
        }

        this.validations = validations;
        this.isValidating = false;

        await this.afterValidate(this.validations);
    }

    public validationTimeout?: ReturnType<typeof setTimeout>;

    public validationReactions: IReactionDisposer[] = [];

    // editing

    @action
    public beforeEdit?: (editedModel?: TModel) => Promise<void>;

    public afterEdit?: (editedModel: TModel) => Promise<void>;

    @action
    public edit(changeCallback?: (model: TModel) => void): void {
        if (this.hasOwnProperty("beforeEdit") && typeof this.beforeEdit === "function") {
            this.beforeEdit(this.editedModel);
        }

        if (!this.editedModel) {
            this.editedModel = plainToClass(this.modelConstructor, toJS(this.model)); // to create a new instance, not a reference to the this.model

            const addObjectReactions = (object: Object) => {
                for (const key in object) {
                    if (!this.nonObservableProperties.find((k) => k === key)) {
                        if (
                            object[key] instanceof Object &&
                            !(object[key] instanceof Date) && // do not observe date as object
                            (!(object[key] instanceof Array) || // add object reaction to the simple object
                                (object[key] instanceof Array &&
                                    object[key].length > 0 &&
                                    object[key][0] instanceof Object)) // add reactions for each object child of array
                        ) {
                            // we want to have an observable array, it makes objects with properties inside that array also observable
                            // but this old version of mobx doesn't like observing objects so we have to check for keys
                            // that are not arrays and only make their properties observable without making them observable directly
                            // if you have issues with nested fields, this is the place to look
                            if (object[key] instanceof Array) {
                                extendObservable(object, { [key]: object[key] });
                            }

                            addObjectReactions(object[key]);
                        } else {
                            object = extendObservable(object, { [key]: object[key] });
                            this.validationReactions.push(
                                reaction(
                                    () => object[key],
                                    () => {
                                        if (!isNil(this.validationTimeout)) {
                                            clearTimeout(this.validationTimeout);
                                        }

                                        this.validationTimeout = setTimeout(async () => {
                                            await this.validate(this.validateOptions);
                                        }, this.validateTimeout);
                                    },
                                ),
                            );
                        }
                    }
                }
            };

            addObjectReactions(this.editedModel);
        }

        if (changeCallback) {
            changeCallback(this.editedModel);
        }

        if (typeof this.afterEdit === "function") {
            this.afterEdit(this.editedModel);
        }
    }

    public canCancelEdit = (_editedModel: TModel) => true;

    @action
    public cancelEdit(): this is { editedModel: undefined } {
        if (this.editedModel && this.canCancelEdit(this.editedModel)) {
            this.editedModel = undefined;
            return true;
        }
        return false;
    }

    public isEdited(): this is { editedModel: TModel } {
        return this.editedModel != undefined;
    }

    // saving

    public beforeSave = async (_editedModel: TModel) => {};

    public onSave = async (_editedModel: TModel) => {};

    public afterSave = async () => {};

    @observable
    public isSaving = false;

    @action
    public async save(): Promise<void> {
        if (this.editedModel != undefined && this.skipMissingPropertiesBeforeSave == true) {
            this.validateOptions.skipMissingProperties = false;
            await this.validate(this.validateOptions);
        }
        if (this.editedModel && this.isValid) {
            this.isSaving = true;

            try {
                await this.beforeSave(toJS(this.editedModel));
                await this.onSave(toJS(this.editedModel));

                this.model = plainToClass(this.modelConstructor, toJS(this.editedModel));
                observeModel(this.model, this.nonObservableProperties as string[]);

                this.editedModel = undefined;
                await this.afterSave();
            } catch (error) {
                alert(`Not saved. Reason: ${error}`);
                globalManagementLogger.error(error);
                throw error;
            } finally {
                this.isSaving = false;
            }
        } else {
            this.validationAlert();

            globalManagementLogger.error({
                message: "Can't save",
                payload: toJS(this.validations),
            });

            return Promise.reject(new Error("Validation error"));
        }
    }
}
