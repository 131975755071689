import { Exclude, Expose } from "class-transformer";
import { IsBoolean, IsDefined } from "class-validator";

@Exclude()
export class OrderAddressValidator {
    @IsBoolean()
    @IsDefined()
    @Expose()
    public isMeetingPosition: boolean = false;

    @IsBoolean()
    @IsDefined()
    @Expose()
    public isBoat: boolean = false;

    @IsBoolean()
    @IsDefined()
    @Expose()
    public isTrain: boolean = false;

    @IsDefined()
    @Expose()
    public airportResult: { isAirport: boolean; flightNumberMatch: boolean };

    @IsBoolean()
    @IsDefined()
    @Expose()
    public isIncomplete: boolean = false;
}
