import { action, observable } from "mobx";

import { ModelOperator } from "./ModelOperator";
import { ModelOperatorOptions } from "./ModelOperatorOptions";
import { Tag } from "@daytrip/legacy-models";
import { MultiValue } from "react-select";

interface TagOperatorOptions extends ModelOperatorOptions<Tag, null, null> {
    children: Array<TagOperator>;
    level: number;
    isNew: boolean;
}

export class TagOperator extends ModelOperator<Tag, TagOperatorOptions, null, null, null> {
    public constructor(options: TagOperatorOptions) {
        super(options);

        this.children = options.children;
        this.level = options.level;
        this.isNew = options.isNew;
    }

    @observable
    public children: Array<TagOperator>;

    @observable
    public isOpened: boolean = false;

    @observable
    public level: number;

    @observable
    public isEditCancelled: boolean = false;

    @observable
    public isNew: boolean = false;

    @observable
    public isApplicableForChanged: boolean = false;

    @observable
    public onSaving: boolean = false;

    @action.bound
    public addChildTag(): void {
        this.isOpened = true;

        const tagModel = new Tag();
        tagModel.parentTagId = this.m._id;
        tagModel.applicableFor = this.m.applicableFor;

        const newTag = new TagOperator({
            children: [],
            level: this.level + 1,
            isNew: true,
            modelConstructor: Tag,
            model: tagModel,
            data: null,
            modules: null,
            onSave: async (model: Tag) => {
                try {
                    const tagId = await this.rpcClient.content.createTag(model);
                    newTag.m._id = tagId;
                    newTag.isNew = false;
                } catch (e) {
                    alert("Unable to create tag.");
                    newTag.isEditCancelled = true;
                }
            },
        });

        newTag.edit(() => {});

        this.children.unshift(newTag);
    }

    @action.bound
    public editApplicableFor(
        selections: MultiValue<{ value: string; label: string }>,
        isChildren: boolean = false,
    ): void {
        // if applicableFor is changed, update all child tags with new applicableFor and open the tag so the change is visible
        this.isOpened = true;
        this.isApplicableForChanged = true;
        if (isChildren) {
            this.m.applicableFor = selections.map((selection) => selection.value);
            this.children.forEach((child) => child.editApplicableFor(selections, true));
            return;
        }
        this.edit((tag) => {
            tag.applicableFor = selections.map((selection) => selection.value);
            this.children.forEach((child) => child.editApplicableFor(selections, true));
        });
    }

    @action.bound
    public async customSave(
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
        isChildren: boolean = false,
    ): Promise<void> {
        // update all child tags with new applicableFor as parent
        this.onSaving = true;
        if (this.isApplicableForChanged) {
            if (this.children.length > 0) {
                await Promise.all(this.children.map((child) => child.customSave(event, true)));
            }
        }
        if (isChildren) {
            return this.rpcClient.content.updateTag(this.m._id, this.m);
        }
        await this.save();
        this.onSaving = false;
    }

    @action.bound
    public customCancel(applicableFor?: string[]): void {
        // cancel model changes in child tags and revert to parent's default
        if (applicableFor) {
            this.m.applicableFor = applicableFor;
        } else {
            this.isEditCancelled = true;
            this.cancelEdit();
        }
        if (this.isApplicableForChanged) {
            this.isApplicableForChanged = false;
            if (this.children.length > 0) {
                this.children.forEach((child) => child.customCancel(this.m.applicableFor));
            }
        }
    }
}
