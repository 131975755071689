/* eslint-disable max-len */
import type { SimpleUser } from "@legacy/domain/SimpleUser";
import autobind from "autobind-decorator";
import { action, computed, observable, toJS } from "mobx";
import queryString from "query-string";

import type { RoutingStore } from "../../container";
import { container, stores } from "../../container";
import type { AuthenticationStore } from "../../domain/authentication/AuthenticationStore";
import { getAuthenticationStore } from "../../domain/authentication/AuthenticationStore.singleton";
import { globalManagementLogger } from "../../global-logger";
import type { RpcClient } from "../../rpc-browser-sdk";
import { getRpcClient } from "../../rpc-browser-sdk";

@autobind
export class DriversCompanyLayoutStore {
    private authenticationStore: AuthenticationStore;

    public routerStore = container.get<RoutingStore>(stores.routing);

    public rpcClient: RpcClient;

    public constructor() {
        this.rpcClient = getRpcClient();
        this.authenticationStore = getAuthenticationStore();
    }

    @observable
    public simpleUsers?: Array<SimpleUser> = [];

    @observable
    public selectedCompanyId: string = "";

    @observable
    public isCompaniesLoading: boolean = false;

    @computed
    public get isOwnerOrManager(): boolean {
        const { hasInternalManagementPermission, isRegionalManager } = this.authenticationStore;

        if (hasInternalManagementPermission || isRegionalManager) {
            return true;
        }
        return this.authenticationStore.userJWT?.userId === this.companyId;
    }

    @computed
    public get companyId(): string | undefined {
        return this.routerStore.location?.query?.userId ?? this.routerStore.location?.query?.payouts_userId;
    }

    @action
    public async fetchData(): Promise<void> {
        this.selectedCompanyId = this.companyId ?? "";

        if (!this.companyId) {
            this.selectedCompanyId = "";
            this.simpleUsers = [];
        } else {
            this.selectedCompanyId = this.companyId;
            try {
                this.simpleUsers = await this.rpcClient.user.retrieveSimpleUsers({ userIds: [this.companyId] });
            } catch (e: any) {
                globalManagementLogger.error(
                    `Unable to get simple users. It's probably a company driver who wants to see his company profile.\n${e}`,
                );
            }
        }
    }

    @action
    public async companiesFetch(): Promise<void> {
        this.isCompaniesLoading = true;
        this.simpleUsers = await this.rpcClient.user.retrieveSimpleUsers({ isDriversCompany: true });
        this.isCompaniesLoading = false;
    }

    public isDataFetched(): this is DriversCompanyLayoutStore & DriversCompanyLayoutStoreDataFetched {
        return !!this.simpleUsers;
    }

    @action
    public selectCompany(companyId: string): void {
        this.selectedCompanyId = companyId;
        const query = toJS(this.routerStore.location.query);
        query.userId = companyId;
        this.routerStore.push({
            pathname: this.routerStore.location.pathname,
            search: `?${queryString.stringify(query)}`,
        });
    }
}

interface DriversCompanyLayoutStoreDataFetched {
    simpleUsers: Array<SimpleUser>;
}
