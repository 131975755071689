import { FrequentlyAskedQuestionType, TravelAgentCategory } from "@daytrip/legacy-enums";
import { LocalizedField } from "@daytrip/legacy-models";
import { Expose, Type } from "class-transformer";
import {
    IsDefined,
    IsInt,
    IsString,
    IsUUID,
    IsOptional,
    IsNotEmpty,
    IsBoolean,
    IsEnum,
    ValidateNested,
    IsArray,
} from "class-validator";
import { v4 as uuid } from "uuid";

import { GField, GObjectType } from "../graphql";
import { CDBaseEntity, CDColumn, CDEntity, CDObjectIdColumn } from "../orm";

@GObjectType("FrequentlyAskedQuestionMetadata")
export class FrequentlyAskedQuestionMetadata {
    @GField(() => [TravelAgentCategory], { nullable: true })
    @IsArray()
    @IsOptional()
    @IsEnum(TravelAgentCategory, { each: true })
    @IsOptional()
    visibleToTravelAgentCategories?: TravelAgentCategory[];
}

@GObjectType("FrequentlyAskedQuestion")
@CDEntity("frequentlyAskedQuestions")
export class FrequentlyAskedQuestion extends CDBaseEntity {
    @GField()
    @CDObjectIdColumn()
    @IsUUID("4")
    @Expose()
    public _id: string = uuid();

    @GField()
    @CDColumn()
    @IsString()
    @IsNotEmpty()
    @Expose()
    public question: string;

    @GField()
    @CDColumn()
    @IsString()
    @IsNotEmpty()
    @Expose()
    public answer: string;

    @GField({ nullable: true })
    @CDColumn()
    @IsInt()
    @IsDefined()
    @Expose()
    public order: number;

    @GField({ nullable: true })
    @CDColumn()
    @IsString()
    @IsDefined()
    @Expose()
    public humanReadableAnchor: string;

    @GField()
    @CDColumn()
    @IsString()
    @IsNotEmpty()
    @Expose()
    public category: string;

    @GField({ nullable: true })
    @CDColumn()
    @IsString()
    @IsOptional()
    @Expose()
    public machineName?: string;

    @GField()
    @CDColumn()
    @IsBoolean()
    @IsNotEmpty()
    @IsDefined()
    @Expose()
    public isPublished: boolean;

    @GField(() => FrequentlyAskedQuestionType, { nullable: true })
    @CDColumn()
    @IsEnum(FrequentlyAskedQuestionType)
    @IsOptional()
    @Expose()
    public type?: FrequentlyAskedQuestionType;

    @GField(() => FrequentlyAskedQuestionMetadata, { nullable: true })
    @CDColumn()
    @ValidateNested()
    @IsOptional()
    @Expose()
    public metadata?: FrequentlyAskedQuestionMetadata;

    @CDColumn()
    @IsDefined()
    @Type(() => LocalizedField)
    @Expose()
    public answerLocalized: LocalizedField = new LocalizedField();

    @CDColumn()
    @IsDefined()
    @Type(() => LocalizedField)
    @Expose()
    public questionLocalized: LocalizedField = new LocalizedField();

    @CDColumn()
    @IsDefined()
    @Type(() => LocalizedField)
    @Expose()
    public categoryLocalized: LocalizedField = new LocalizedField();
}
