import type { DriverFaq } from "@daytrip/api";
import { observable } from "mobx";

import { ModelOperator } from "../../operators/ModelOperator";
import type { ModelOperatorOptions } from "../../operators/ModelOperatorOptions";

interface DriverFaqOperatorOptions extends ModelOperatorOptions<DriverFaq, null, null> {
    isNew: boolean;
    isDeleted: boolean;
}

export class DriverFaqOperator extends ModelOperator<DriverFaq, DriverFaqOperatorOptions, null, null, null> {
    public constructor(options: DriverFaqOperatorOptions) {
        super(options);

        this.isNew = options.isNew;
        this.isDeleted = options.isDeleted;
    }

    @observable
    public isNew: boolean = false;

    @observable
    public isDeleted: boolean = false;
}
