import { TransformToDate } from "@daytrip/legacy-transformers";
import { Expose } from "class-transformer";
import { IsDefined, IsEnum, IsNumber, IsUUID } from "class-validator";
import { v4 as uuid } from "uuid";

import { Currency } from "../domain/Currency";
import { CDBaseEntity, CDColumn, CDEntity, CDObjectIdColumn } from "../orm";

@CDEntity("assignationPayouts")
export class AssignationPayout extends CDBaseEntity {
    @CDObjectIdColumn()
    @IsUUID("4")
    @IsDefined()
    @Expose()
    public _id: string = uuid();

    @CDColumn()
    @IsUUID("4")
    @IsDefined()
    @Expose()
    public assignationId: string;

    @CDColumn()
    @IsUUID("4")
    @IsDefined()
    @Expose()
    public payoutId: string;

    @CDColumn()
    @IsNumber()
    @IsDefined()
    @Expose()
    public amount: number;

    @CDColumn()
    @IsNumber()
    @IsDefined()
    @Expose()
    public payoutAmount: number;

    @CDColumn()
    @IsNumber()
    @IsDefined()
    @Expose()
    public vatDeducted: number = 0;

    @CDColumn()
    @IsEnum(Currency)
    @IsDefined()
    @Expose()
    public currency = Currency.Euro;

    @CDColumn()
    @Expose()
    @TransformToDate
    public createdAt?: Date = new Date();
}
