import { transformSimpleUsersToSelectOptions } from "@daytrip/legacy-transformers";
import { transformTimestampStringToDate } from "@daytrip/legacy-transformers";
import { isUndefinedOrNull } from "@daytrip/utils";
import { valueOrArrayToArray } from "@daytrip/utils";
import type { SimpleLocation } from "@legacy/domain/SimpleLocation";
import type { SimpleOrder } from "@legacy/domain/SimpleOrder";
import type { SimpleUser } from "@legacy/domain/SimpleUser";
import { CustomerFeedback } from "@legacy/models/CustomerFeedback";
import type { RetrieveCustomerFeedbacksOptions } from "@legacy/options/RetrieveCustomerFeedbacksOptions";
import type { RetrieveUsersOptions } from "@legacy/options/RetrieveUsersOptions";
import autobind from "autobind-decorator";
import { action, computed, observable } from "mobx";
import type { Option } from "react-select-legacy";

import { CustomerFeedbackOperator } from "../../operators/CustomerFeedbackOperator";
import { PageStore } from "../../stores/PageStore";

import type { CustomersFeedbacksPageRouter } from "./CustomersFeedbacksPageRouter";

@autobind
export class CustomersFeedbacksPageStore extends PageStore<CustomersFeedbacksPageRouter, null> {
    @observable
    public feedback?: Array<CustomerFeedbackOperator>;

    @observable
    public feedbacksCount?: number;

    @observable
    public feedbacksScores?: Array<number>;

    @observable
    public orders?: Array<SimpleOrder>;

    @observable
    public filterCustomers: Array<Option> = [];

    @observable
    public simpleUsers?: Array<SimpleUser>;

    @observable
    public feedbacksDriverUsers?: Array<SimpleUser>;

    @observable
    public feedbacksCustomerUsers: Array<SimpleUser> = [];

    @observable
    public simpleLocations?: Array<SimpleLocation>;

    @action
    public async onFetchData() {
        await this.fetchContent();
    }

    @action
    public async fetchContent(): Promise<void> {
        this.feedback = undefined;
        this.feedbacksCount = undefined;
        this.orders = undefined;
        this.simpleUsers = undefined;
        this.simpleLocations = undefined;

        const options: RetrieveCustomerFeedbacksOptions = {
            skip: this.pageRouter.skip,
            limit: this.pageRouter.limit,
            sortBy: this.pageRouter.sortBy,
            sortDirection: this.pageRouter.sortDirection,
            // eslint-disable-next-line no-nested-ternary
            driverIds: !isUndefinedOrNull(this.pageRouter.driverIds)
                ? this.pageRouter.driverIds
                : !isUndefinedOrNull(this.pageRouter.driverLayoutUserId)
                  ? valueOrArrayToArray(this.pageRouter.driverLayoutUserId)
                  : undefined,
            userIds: this.pageRouter.userIds,
            createdAtFrom: transformTimestampStringToDate(this.pageRouter.createdAtFrom),
            createdAtTo: transformTimestampStringToDate(this.pageRouter.createdAtTo),
            scores: this.pageRouter.scores,
        } as RetrieveCustomerFeedbacksOptions;

        if (this.pageRouter.isCompany) {
            options.driverCompanyIds = options.driverIds;
        }

        const feedbacksPromise = this.rpcClient.feedback.retrieveCustomerFeedbacks(options);
        const feedbacksCountPromise = this.rpcClient.feedback.retrieveCustomerFeedbacksCount(options);

        const [feedbacks, feedbacksCount] = await Promise.all([feedbacksPromise, feedbacksCountPromise]);

        const { ordersIds, driversIds } = feedbacks.reduce(
            (acc, feedback) => {
                acc.ordersIds.add(feedback.orderId);
                acc.driversIds.add(feedback.driverUserId);
                return acc;
            },
            {
                ordersIds: new Set(),
                driversIds: new Set(),
            },
        );

        this.feedbacksCount = feedbacksCount;
        this.feedback = feedbacks.map(
            (f) =>
                new CustomerFeedbackOperator({
                    modelConstructor: CustomerFeedback,
                    model: f,
                    data: null,
                    modules: null,
                    onSave: async (model) => {
                        await this.rpcClient.feedback.updateCustomerFeedback(model._id, model);
                    },
                }),
        );

        const retrieveScoresOptions = {} as RetrieveCustomerFeedbacksOptions;
        if (options.driverCompanyIds) {
            retrieveScoresOptions.driverCompanyIds = options.driverCompanyIds;
        }
        if (options.driverIds) {
            retrieveScoresOptions.driverIds = options.driverIds;
        }
        this.feedbacksScores = await this.rpcClient.feedback.retrieveCustomerFeedbacksScores(retrieveScoresOptions);

        let orders: Array<SimpleOrder> = [];

        if (!isUndefinedOrNull(this.feedback) && this.feedback.length > 0) {
            orders = await this.rpcClient.order.retrieveSimpleOrders({ ids: Array.from(ordersIds) });
            if (!isUndefinedOrNull(orders) && orders.length > 0) {
                const customersIds = orders.map((order) => order.userId);

                const locationIds = orders.flatMap((order) => [order.originLocationId, order.destinationLocationId]);
                const uniqueLocationsIds = Array.from(new Set(locationIds));

                const [simpleLocations, customersUsers] = await Promise.all([
                    this.rpcClient.content.retrieveSimpleLocations({ ids: uniqueLocationsIds }),
                    this.rpcClient.user.retrieveSimpleUsers({ userIds: Array.from(customersIds) }),
                ]);
                this.simpleLocations = simpleLocations;
                this.feedbacksCustomerUsers = customersUsers;
            }
        }

        this.orders = orders;
        this.feedbacksDriverUsers = await this.rpcClient.user.retrieveSimpleUsers({ userIds: Array.from(driversIds) });
    }

    @computed
    public get driversAverageRating(): number {
        let sum = 0;

        if (this.feedbacksScores && this.feedbacksScores.length > 0 && this.pageRouter.driverLayoutUserId) {
            sum = this.feedbacksScores.reduce((prev: number, f: number) => prev + f, sum);

            sum /= this.feedbacksScores.length;
            return Number(
                sum.toLocaleString("en", {
                    maximumFractionDigits: 2,
                    useGrouping: false,
                }),
            );
        }

        return 0;
    }

    @computed
    public get maximumRatingsCount(): number {
        return [5, 4, 3, 2, 1].map((score) => this.getRatingsLength(score)).sort((a, b) => (a < b ? 1 : -1))[0];
    }

    public getRatingsLength(ratingValue: number) {
        // eslint-disable-next-line eqeqeq
        if (this.feedbacksScores != undefined && this.pageRouter.driverLayoutUserId != undefined) {
            return this.feedbacksScores.filter((f) => f === ratingValue).length;
        }
        return 0;
    }

    @action
    public async fetchFilterCustomers(searchString: string): Promise<{ options: Array<Option> }> {
        let options: Array<Option> = [];
        if (searchString && searchString.length > 2) {
            const userOptions = {
                searchString: searchString,
                isDriver: false,
            } as RetrieveUsersOptions;
            const users = await this.rpcClient.user.retrieveSimpleUsers(userOptions);
            options = transformSimpleUsersToSelectOptions(users);
        }
        return { options };
    }

    @action
    public async fetchFilterDrivers(searchString: string): Promise<{ options: Array<Option> }> {
        let options: Array<Option> = [];
        if (searchString && searchString.length > 2) {
            const driverOptions = {
                searchString: searchString,
                isDriver: true,
            } as RetrieveUsersOptions;
            const users = await this.rpcClient.user.retrieveSimpleUsers(driverOptions);
            options = transformSimpleUsersToSelectOptions(users);
        }
        return { options };
    }

    public isDataFetched(): this is CustomersFeedbacksPageStore & CustomerFeedbacksPageStoreDataFetched {
        return (
            !isUndefinedOrNull(this.feedback) &&
            !isUndefinedOrNull(this.feedbacksScores) &&
            !isUndefinedOrNull(this.feedbacksCount) &&
            !isUndefinedOrNull(this.orders) &&
            !isUndefinedOrNull(this.feedbacksCustomerUsers) &&
            !isUndefinedOrNull(this.feedbacksDriverUsers)
        );
    }
}

export interface CustomerFeedbacksPageStoreDataFetched {
    feedback: Array<CustomerFeedbackOperator>;
    feedbacksCount: number;
    orders: Array<SimpleOrder>;
    feedbacksCustomerUsers: Array<SimpleUser>;
    feedbacksDriverUsers: Array<SimpleUser>;
}
