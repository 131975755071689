import { VehicleType } from "../domain/VehicleType";
import type { Driver } from "../models/Driver";
import type { DriversCompany } from "../models/DriversCompany";

const determineSuitableVehicleTypes = (willingToDoLite: boolean, suitableForLuxury: boolean) => {
    const driverVehicleTypes = [VehicleType.Sedan, VehicleType.Mpv, VehicleType.Van, VehicleType.Shuttle];
    if (suitableForLuxury) {
        driverVehicleTypes.push(VehicleType.LuxurySedan);
    }
    if (willingToDoLite) {
        driverVehicleTypes.push(VehicleType.SedanLite);
    }
    return driverVehicleTypes;
};

type RequiredDriverProps = Pick<Driver, "willingToDoLite" | "canSpeakEnglish" | "isSuitableForLuxury">;
type RequiredDriversCompanyProps = Pick<DriversCompany, "willingToDoLite">;

type IndependentDriverProp = { driver: RequiredDriverProps };
type CompanyDriverProps = { driver: RequiredDriverProps; driversCompany: RequiredDriversCompanyProps };
type DriversCompanyProps = { driversCompany: RequiredDriversCompanyProps; companyDrivers: RequiredDriverProps[] };
type VehicleTypeProp = { vehicleType: VehicleType };

export const determineSuitableVehicleTypesForDriver = ({ driver }: IndependentDriverProp) => {
    if (!driver.canSpeakEnglish) {
        return [VehicleType.SedanLite];
    }

    return determineSuitableVehicleTypes(driver.willingToDoLite, driver.isSuitableForLuxury);
};

export const determineSuitableVehicleTypesForCompanyDriver = ({ driver, driversCompany }: CompanyDriverProps) => {
    if (!driver.canSpeakEnglish) {
        return [VehicleType.SedanLite];
    }

    // Willingness to rent luxury sedan doesn't matter for company drivers
    return determineSuitableVehicleTypes(driversCompany.willingToDoLite, driver.isSuitableForLuxury);
};

export const determineSuitableVehicleTypesForDriversCompany = ({
    driversCompany,
    companyDrivers,
}: DriversCompanyProps) => {
    if (companyDrivers.length === 0) {
        return [];
    }

    const hasEnglishSpeakingDriver = companyDrivers.some((driver) => driver.canSpeakEnglish);
    if (!hasEnglishSpeakingDriver) {
        return [VehicleType.SedanLite];
    }

    const hasSuitableForLuxuryDriver = companyDrivers.some(
        ({ isSuitableForLuxury, canSpeakEnglish }) => canSpeakEnglish && isSuitableForLuxury,
    );

    return determineSuitableVehicleTypes(driversCompany.willingToDoLite, hasSuitableForLuxuryDriver);
};

export const isDriverSuitableForVehicleType = ({ driver, vehicleType }: IndependentDriverProp & VehicleTypeProp) => {
    return determineSuitableVehicleTypesForDriver({ driver }).includes(vehicleType);
};

export const isCompanyDriverSuitableForVehicleType = ({
    driver,
    driversCompany,
    vehicleType,
}: CompanyDriverProps & VehicleTypeProp) => {
    return determineSuitableVehicleTypesForCompanyDriver({ driver, driversCompany }).includes(vehicleType);
};

export const isDriversCompanySuitableForVehicleType = ({
    driversCompany,
    companyDrivers,
    vehicleType,
}: DriversCompanyProps & VehicleTypeProp) => {
    return determineSuitableVehicleTypesForDriversCompany({ driversCompany, companyDrivers }).includes(vehicleType);
};
