import { gql } from "@apollo/client";
import { shallAuthRefreshTokenBeRefreshed } from "@legacy/utils/jwt";

import { getApolloClient } from "../../apolloClient/apolloClient";
import type { MutationRefreshTokensArgs } from "../../types/generated/graphql-types";
import type { PickMutation } from "../../types/graphql-types-helpers";
import { getManagementRefreshToken } from "../../utils/authentication/authenticationUtils";

export const MUTATION_REFRESH_TOKENS = gql`
    mutation RefreshTokens($refreshBoth: Boolean) {
        refreshTokens(refreshBoth: $refreshBoth) {
            refresh
            authentication
        }
    }
`;

export async function refreshTokens(expiration: number) {
    const refresh = getManagementRefreshToken();
    if (!refresh) {
        throw Error("Tokens missing");
    }

    const refreshBoth = shallAuthRefreshTokenBeRefreshed(refresh, expiration);

    return getApolloClient().mutate<PickMutation<"refreshTokens">, MutationRefreshTokensArgs>({
        mutation: MUTATION_REFRESH_TOKENS,
        variables: { refreshBoth },
    });
}
