import type { Ride } from "@daytrip/legacy-models";
import { AssignationStatus } from "@legacy/domain/AssignationStatus";
import type { SimpleLocation } from "@legacy/domain/SimpleLocation";
import type { SimpleUser } from "@legacy/domain/SimpleUser";
import type { VehicleInfo } from "@legacy/domain/VehicleInfo";
import { Assignation } from "@legacy/models/Assignation";
import type { RetrieveRidesOptions } from "@legacy/options/RetrieveRidesOptions";
import { transformTimestampStringToDate } from "@daytrip/legacy-transformers";
import autobind from "autobind-decorator";
import { plainToClass } from "class-transformer";
import uniq from "lodash/uniq";
import { action, observable } from "mobx";
import type { Option } from "react-select-legacy";

import { PageStore } from "../../stores/PageStore";

import type { RidesPageRouter } from "./RidesPageRouter";

export type RideWithAssignationInfo = {
    ride: Ride;
    assignation?: Assignation;
    assignedUser?: SimpleUser;
    vehicleInfo?: VehicleInfo;
};

@autobind
export class RidesPageStore extends PageStore<RidesPageRouter, {}> {
    @observable
    public ridesWithAssignationInfo?: RideWithAssignationInfo[] = undefined;

    @observable
    public ridesCount?: number;

    @observable
    public locations?: SimpleLocation[] = undefined;

    public async onFetchData() {
        await this.fetchData();
    }

    @action
    public async fetchData() {
        const options = {
            skip: this.pageRouter.skip,
            limit: this.pageRouter.limit,
            sortBy: this.pageRouter.sortBy,
            sortDirection: this.pageRouter.sortDirection,

            departureAtFrom: transformTimestampStringToDate(this.pageRouter.departureAtFrom),
            departureAtTo: transformTimestampStringToDate(this.pageRouter.departureAtTo),
            originLocationIds: this.pageRouter.originLocationIds,
            destinationLocationIds: this.pageRouter.destinationLocationIds,
        } as RetrieveRidesOptions;

        const [rides, ridesCount] = await Promise.all([
            this.rpcClient.ride.retrieveRides(options),
            this.rpcClient.ride.retrieveRidesCount(options),
        ]);

        this.ridesCount = ridesCount;

        const locationIds: string[] = rides.reduce<string[]>((curr, ride) => {
            let ids = curr;

            if (!ids.includes(ride.originLocationId)) {
                ids = [...ids, ride.originLocationId];
            }

            if (!ids.includes(ride.destinationLocationId)) {
                ids = [...ids, ride.destinationLocationId];
            }

            ride.stops.forEach(({ locationId }) => {
                if (!ids.includes(locationId)) {
                    ids = [...ids, locationId];
                }
            });

            return ids;
        }, []);

        const [assignations, locations] = await Promise.all([
            this.rpcClient.assignation.retrieveAssignations({
                rideIds: rides.map((ride) => ride._id),
                statusIn: [AssignationStatus.Pending, AssignationStatus.Accepted],
            }),
            this.rpcClient.content.retrieveSimpleLocations({ ids: locationIds }),
        ]);

        const assignationVehicleIds = uniq(
            assignations.filter((assignation) => assignation.vehicleId).map((assignation) => assignation.vehicleId!),
        );
        const assignationUserIds = uniq(assignations.map((assignation) => assignation.userId));

        const [vehiclesInfo, assignedUsers] = await Promise.all([
            this.rpcClient.vehicle.retrieveVehiclesInfo(assignationVehicleIds),
            this.rpcClient.user.retrieveSimpleUsers({ userIds: assignationUserIds }),
        ]);

        this.ridesWithAssignationInfo = rides.map((ride) => {
            const assignation = assignations.find((a) => a.rideId === ride._id);

            if (!assignation) return { ride };

            return {
                ride,
                assignation: plainToClass(Assignation, assignation),
                assignedUser: assignedUsers.find((user) => user._id === assignation.userId),
                vehicleInfo: vehiclesInfo.find((vehicleInfo) => vehicleInfo._id === assignation.vehicleId),
            };
        });

        this.locations = locations;
    }

    public async fetchLocations(value: string): Promise<{ options: Array<Option> }> {
        let options: Array<Option> = [];
        if (value && value.length > 2) {
            const locations = await this.rpcClient.content.retrieveSimpleLocations({
                isDestination: true,
                searchString: value,
            });

            options = locations.map((location) => ({
                value: location.locationId,
                label: location.name + (location.countryName ? `, ${location.countryName}` : ""),
            }));
        }

        return { options };
    }

    public isDataFetched(): this is RidesPageStore & RidesPageStoreDataFetched {
        return (
            this.ridesWithAssignationInfo !== undefined && this.locations !== undefined && this.ridesCount !== undefined
        );
    }
}

export interface RidesPageStoreDataFetched {
    ridesWithAssignationInfo: RideWithAssignationInfo[];
    locations: SimpleLocation[];
    ridesCount: number;
}
