import { Type } from "class-transformer";

import { AssignationPayout } from "../models/AssignationPayout";
import { Compensation } from "../models/Compensation";
import { DriverDebt } from "../models/DriverDebt";
import { Payout } from "../models/Payout";
import { Penalty } from "../models/Penalty";
import { Subsidy } from "../models/Subsidy";
import { User } from "../models/User";

import type { AssignationStatus } from "./AssignationStatus";
import { SimpleOrder } from "./SimpleOrder";
import { SimpleUser } from "./SimpleUser";

export class AssignationDataForPayout {
    public assignationId: string;

    public assignationStatus: AssignationStatus;

    public vehicleTitle: string;

    public vehicleId?: string;

    public acceptationNote?: string;

    @Type(() => User)
    public user: User;

    @Type(() => SimpleOrder)
    public simpleOrder: SimpleOrder;

    public paidCash: number;

    public paidOnline: number;

    @Type(() => Penalty)
    public penalties: Array<Penalty>;

    @Type(() => Compensation)
    public compensations: Array<Compensation>;

    @Type(() => Subsidy)
    public subsidies: Array<Subsidy>;

    public sumOfPenalties: number;

    public sumOfCompensations: number;

    public sumOfSubsidies: number;

    @Type(() => AssignationPayout)
    public assignationPayouts: Array<AssignationPayout>;

    @Type(() => Payout)
    public payouts: Array<Payout>;

    @Type(() => DriverDebt)
    public driverDebts: Array<DriverDebt> = [];

    @Type(() => SimpleUser)
    public debtsAuthorSimpleUsers: Array<SimpleUser>;

    public sumOfPayouts: number;

    public sumOfDebts: number;

    public amountForDriver: number;

    public driversBalance: number;

    feeVatDeducted: number;

    amountToPayout: number;
}
