import { LuggageType } from "@legacy/domain/LuggageType";
import {
    VehicleType,
    VehicleTypesCapacity,
    VehicleTypesCarryOns,
    VehicleTypesSuitcases,
} from "@legacy/domain/VehicleType";
import { Passenger } from "@daytrip/legacy-models";

// test commit
export function isEnoughSpaceForLuggage(vehicles: Array<VehicleType>, passengers: Array<Passenger>): boolean {
    let remainingSuitcases = 0;
    let remainingCarryOns = 0;
    passengers.forEach((p) => {
        p.luggage.forEach((l) => {
            if (l === LuggageType.Suitcase) {
                remainingSuitcases += 1;
            } else if (l === LuggageType.CarryOn) {
                remainingCarryOns += 1;
            }
        });
    });

    vehicles.forEach((v) => {
        remainingSuitcases -= VehicleTypesSuitcases[v];
        remainingCarryOns -= VehicleTypesCarryOns[v];
    });

    if (remainingCarryOns + remainingSuitcases > 0) {
        return false;
    }

    return true;
}

export function calculateCapacityOfVehicles(vehicles: Array<VehicleType>) {
    return vehicles.reduce<number>(
        (sum: number, vehicleTypeId: number) => sum + VehicleTypesCapacity[vehicleTypeId],
        0,
    );
}
