import type { Permission } from "@daytrip/access-control";
import { getModifySubsidyPermission, getModifyCompensationPermission } from "@daytrip/access-control";
import { CancellationInfo } from "@daytrip/legacy-models";
import { Currency } from "@legacy/domain/Currency";
import { EmailTypes } from "@legacy/domain/EmailTypes";
import { RentalVehicle } from "@legacy/domain/RentalVehicle";
import { SimpleDriver } from "@legacy/domain/SimpleDriver";
import type { VehicleInfo } from "@legacy/domain/VehicleInfo";
import { VehicleType } from "@legacy/domain/VehicleType";
import type { UpcomingTripReminderForDriverEmailArgs } from "@legacy/emails/assignation/upcomingTripReminderForDriver/UpcomingTripReminderForDriverEmailProvider";
import type { Assignation } from "@legacy/models/Assignation";
import { Compensation } from "@legacy/models/Compensation";
import { CustomerFeedback } from "@legacy/models/CustomerFeedback";
import { Penalty } from "@legacy/models/Penalty";
import { Subsidy } from "@legacy/models/Subsidy";
import { isUndefinedOrNull } from "@legacy/utils";
import autobind from "autobind-decorator";
import type { ValidationError } from "class-validator";
import { action, computed, observable, reaction, toJS } from "mobx";

import { observeModel } from "../utils/observeModel";

import { CompensationOperator } from "./CompensationOperator";
import { CustomerFeedbackOperator } from "./CustomerFeedbackOperator";
import { ModelOperator } from "./ModelOperator";
import type { ModelOperatorOptions } from "./ModelOperatorOptions";
import { PenaltyOperator } from "./PenaltyOperator";
import { SubsidyOperator } from "./SubsidyOperator";

interface AssignationOperatorOptions extends ModelOperatorOptions<Assignation, null, AssignationOperatorData> {
    driver: SimpleDriver;
    vehicle: VehicleType;
    newAssignation?: boolean;
    price?: number;
    pricingCurrency?: number;
    vehicleTitle?: string;
    availableVehicles?: Array<VehicleInfo>;
}

interface AssignationOperatorData {
    penalties: Array<Penalty>;
    subsidies: Array<Subsidy>;
    compensations: Array<Compensation>;
}

interface AssignationOperatorDataFetched extends AssignationOperatorData {
    penalties: Array<Penalty>;
    subsidies: Array<Subsidy>;
    compensations: Array<Compensation>;
}

@autobind
export class AssignationOperator extends ModelOperator<
    Assignation,
    AssignationOperatorOptions,
    null,
    AssignationOperatorData,
    AssignationOperatorDataFetched
> {
    public constructor(options: AssignationOperatorOptions) {
        super(options);

        this.driver = options.driver;
        this.vehicle = options.vehicle;

        if (options.price) {
            this.price = options.price;
        }

        if (options.pricingCurrency) {
            this.pricingCurrency = options.pricingCurrency;
        }

        if (options.newAssignation) {
            this.isSaved = false;
        }

        this.lastAmountOfAddons = this.m.subsidyIds.length + this.m.compensationIds.length + this.m.penaltyIds.length;

        if (options.vehicleTitle) {
            this.vehicleTitle = options.vehicleTitle;
        }

        if (options.availableVehicles) {
            this.availableVehicles = options.availableVehicles;
        }
    }

    @observable
    public vehicleTitle: string;

    @observable
    public availableVehicles?: Array<VehicleInfo>;

    @observable
    public price: number = 0;

    @observable
    public pricingCurrency: Currency;

    @computed
    public get calculatedPrice(): number {
        return this.price;
    }

    @observable
    public driver?: SimpleDriver;

    @observable
    public vehicle: VehicleType;

    @observable
    public isSaved: boolean = true;

    @observable
    public lastAmountOfAddons: number = 0;

    @observable
    public isUpdatingAssignation: boolean;

    @observable
    public rentalVehicle: RentalVehicle;

    private priceModifiersCustomValidation(requiredPermission: Permission): ValidationError | undefined {
        let validationError;
        if (!this.authenticationStore.hasPermissions(requiredPermission)) {
            validationError = {
                property: "value",
                constraints: {
                    overLimit: `Larger compensations and/or subsidies can only be added by specific users. Post your request in the #drivers_operations channel in Slack and tag @senior_operators`,
                },
            };
        }

        return validationError;
    }

    @action
    public setUpdatingState(value: boolean) {
        this.isUpdatingAssignation = value;
    }

    @action
    public async updateAssignationPrice(): Promise<void> {
        const price = await this.rpcClient.assignation.retrieveAssignationPrice(this.m);
        this.price = price.price;
    }

    public reaction = reaction(
        () => this.m.subsidyIds.length + this.m.compensationIds.length + this.m.penaltyIds.length,
        async () => {
            if (
                this.lastAmountOfAddons ===
                    this.m.subsidyIds.length + this.m.compensationIds.length + this.m.penaltyIds.length ||
                this.isUpdatingAssignation
            ) {
                return;
            }

            if (this.isSaved) {
                this.setUpdatingState(true);
                try {
                    const assignation = await this.rpcClient.assignation.retrieveAssignation(this.m._id);

                    const subsidyIdsToFetch = assignation.subsidyIds.filter(
                        (subsidyId) => this.subsidies.map((s) => s._id).indexOf(subsidyId) === -1,
                    );

                    if (subsidyIdsToFetch.length > 0) {
                        this.data.subsidies.concat(
                            await this.rpcClient.assignation.retrieveSubsidies({ ids: subsidyIdsToFetch }),
                        );
                    }

                    const penaltyIdsToFetch = assignation.penaltyIds.filter(
                        (penaltyId) => this.penalties.map((s) => s._id).indexOf(penaltyId) === -1,
                    );

                    if (penaltyIdsToFetch.length > 0) {
                        this.data.penalties.concat(
                            await this.rpcClient.assignation.retrievePenalties({ ids: penaltyIdsToFetch }),
                        );
                    }

                    const compensationIdsToFetch = assignation.compensationIds.filter(
                        (compensationId) => this.compensations.map((s) => s._id).indexOf(compensationId) === -1,
                    );

                    if (compensationIdsToFetch.length > 0) {
                        this.data.compensations.concat(
                            await this.rpcClient.assignation.retrieveCompensations({ ids: compensationIdsToFetch }),
                        );
                    }

                    this.model = assignation;

                    observeModel(this.model, this.nonObservableProperties);
                } catch (e: any) {
                    // eslint-disable-next-line no-alert
                    alert(
                        "Unable to update assignation. It could be the result of someone updated this assignation meanwhile. Please, reload the page and try again.",
                    );
                    // eslint-disable-next-line no-console
                    console.error(e);
                } finally {
                    this.lastAmountOfAddons =
                        this.m.subsidyIds.length + this.m.compensationIds.length + this.m.penaltyIds.length;
                    this.setUpdatingState(false);
                }
            }
        },
    );

    @action
    public async accept(note: string, driverId?: string, vehicleId?: string, rentalVehicle?: RentalVehicle) {
        if (this.isSaved) {
            await this.rpcClient.assignation.acceptAssignation(this.m._id, vehicleId, note, driverId, rentalVehicle);
        }

        this.edit((m) => {
            m.acceptedAt = new Date();
            m.acceptationNote = note;
            // Update assignation user id if assignation is accepted for company driver
            if (driverId) {
                m.userId = driverId;
            }

            // update assignation version increment and recordSendEmail version increment
            m.version += 2;
        });
    }

    @action
    public async cancel(cancellationInfo: CancellationInfo) {
        if (this.isSaved) {
            this.model.cancelledAt = new Date();
            this.model.cancellationInfo = cancellationInfo;
            await this.rpcClient.assignation.cancelAssignationOnOrderPage(this.m._id, cancellationInfo);
        }
    }

    // penalties

    @observable
    public selectedPenaltyId?: string;

    @action
    public async removePenalty(id: string): Promise<void> {
        await this.rpcClient.assignation.removePenaltyFromAssignation(this.m._id, id);
        this.edit((a) =>
            a.penaltyIds.splice(
                a.penaltyIds.findIndex((pid) => pid === id),
                1,
            ),
        );
        await this.updateAssignationPrice();
    }

    @computed
    public get penalties(): Array<Penalty> {
        return this.m.penaltyIds.map((pId) => this.data.penalties.find((p) => p._id === pId) as Penalty);
    }

    @observable
    public penaltyOperator?: PenaltyOperator;

    @observable
    public isAddingPenaltyDisplayed = false;

    @action
    public displayAddingPenalty() {
        if (!this.penaltyOperator) {
            this.penaltyOperator = new PenaltyOperator({
                modelConstructor: Penalty,
                afterValidate: async (validations) => {
                    const validationError = this.priceModifiersCustomValidation("Assignation:Modify");
                    if (validationError) validations.push(validationError);
                },
                onSave: async (penalty) => {
                    if (!this.data.penalties) {
                        this.data.penalties = [];
                    }

                    penalty._id = await this.rpcClient.assignation.addPenaltyToAssignation(this.m._id, penalty);
                    await this.updateAssignationPrice();

                    this.data.penalties.push(penalty);
                    this.m.penaltyIds.push(penalty._id);

                    this.isSaved = true;
                    this.selectedPenaltyId = undefined;
                    this.isAddingPenaltyDisplayed = false;
                },
                modules: {},
                data: {},
            });
        }

        this.isAddingPenaltyDisplayed = true;
    }

    @action
    public hideAddingPenalty() {
        this.isAddingPenaltyDisplayed = false;
    }

    @observable
    public selectedSubsidyId?: string;

    @action
    public async removeSubsidy(id: string): Promise<void> {
        await this.rpcClient.assignation.removeSubsidyFromAssignation(this.m._id, id);
        this.edit((a) =>
            a.subsidyIds.splice(
                this.m.subsidyIds.findIndex((sid) => sid === id),
                1,
            ),
        );
        await this.updateAssignationPrice();
    }

    @computed
    public get subsidies(): Array<Subsidy> {
        return this.m.subsidyIds.map((dId) => this.data.subsidies.find((d) => d._id === dId) as Subsidy);
    }

    @observable
    public subsidyOperator?: SubsidyOperator;

    @observable
    public isAddingSubsidyDisplayed = false;

    @action
    public displayAddingSubsidy() {
        if (!this.subsidyOperator) {
            this.subsidyOperator = new SubsidyOperator({
                modelConstructor: Subsidy,
                afterValidate: async (validations) => {
                    const validationError = this.priceModifiersCustomValidation(
                        getModifySubsidyPermission(this.subsidyOperator?.editedModel?.value),
                    );
                    if (validationError) validations.push(validationError);
                },
                onSave: async (subsidy) => {
                    if (!this.data.subsidies) {
                        this.data.subsidies = [];
                    }
                    subsidy.currency = this.pricingCurrency;
                    subsidy._id = await this.rpcClient.assignation.addSubsidyToAssignation(this.m._id, subsidy);
                    await this.updateAssignationPrice();

                    this.data.subsidies.push(subsidy);
                    this.m.subsidyIds.push(subsidy._id);

                    this.selectedSubsidyId = undefined;
                    this.isAddingSubsidyDisplayed = false;
                },
                modules: {},
                data: {},
            });
        }
        this.isAddingSubsidyDisplayed = true;
    }

    @action
    public hideAddingSubsidy() {
        this.isAddingSubsidyDisplayed = false;
    }

    @observable
    public selectedCompensationId?: string;

    @action
    public async removeCompensation(id: string): Promise<void> {
        await this.rpcClient.assignation.removeCompensationFromAssignation(this.m._id, id);
        this.edit((a) =>
            a.compensationIds.splice(
                this.m.compensationIds.findIndex((cid) => cid === id),
                1,
            ),
        );
        await this.updateAssignationPrice();
    }

    @computed
    public get compensations(): Array<Compensation> {
        return this.m.compensationIds.map((cId) => this.data.compensations.find((c) => c._id === cId) as Compensation);
    }

    @observable
    public compensationOperator?: CompensationOperator;

    @observable
    public isAddingCompensationDisplayed = false;

    @action
    public displayAddingCompensation() {
        if (!this.compensationOperator) {
            this.compensationOperator = new CompensationOperator({
                modelConstructor: Compensation,
                afterValidate: async (validations) => {
                    const validationError = this.priceModifiersCustomValidation(
                        getModifyCompensationPermission(this.compensationOperator?.editedModel?.value),
                    );
                    if (validationError) validations.push(validationError);
                },
                onSave: async (compensation) => {
                    if (!this.data.compensations) {
                        this.data.compensations = [];
                    }

                    compensation._id = await this.rpcClient.assignation.addCompensationToAssignation(
                        this.m._id,
                        compensation,
                    );
                    await this.updateAssignationPrice();

                    this.data.compensations.push(compensation);
                    this.m.compensationIds.push(compensation._id);

                    this.selectedCompensationId = undefined;
                    this.isAddingCompensationDisplayed = false;
                },
                modules: {},
                data: {},
            });
        }
        this.isAddingCompensationDisplayed = true;
    }

    @action
    public hideAddingCompensation() {
        this.isAddingCompensationDisplayed = false;
    }

    @observable
    public customerFeedbackOperator?: CustomerFeedbackOperator;

    @action
    public startAddingFeedback() {
        const feedback = new CustomerFeedback();
        feedback.driverUserId = this.m.userId;
        feedback.orderId = this.m.orderId;

        this.customerFeedbackOperator = new CustomerFeedbackOperator({
            modelConstructor: CustomerFeedback,
            model: feedback,
            data: null,
            modules: null,
            onSave: async (model) => {
                if (this.isSaved) {
                    if (this.customerFeedbackOperator && !this.customerFeedbackOperator.isSaved) {
                        const result = await this.rpcClient.feedback.createCustomerFeedback(model);
                        if (Number.isNaN(Number(result))) {
                            this.customerFeedbackOperator.isSaved = true;
                        } else {
                            this.customerFeedbackOperator.savingError = `Unable to add feedback. ${result}`;
                        }
                    } else {
                        await this.rpcClient.feedback.updateCustomerFeedback(model._id, model);
                    }
                }
            },
        });

        this.customerFeedbackOperator.toggleModal();
        this.customerFeedbackOperator.edit(() => {});
    }

    @action
    public async removeCustomerFeedback() {
        if (!isUndefinedOrNull(this.customerFeedbackOperator)) {
            await this.rpcClient.feedback.removeCustomerFeedback(this.customerFeedbackOperator.m._id);
            this.customerFeedbackOperator = undefined;
        }
    }

    @action
    public async addAssignationPricesOperationsFromPreviousAssignations(
        previousAssignationOperators: Array<AssignationOperator>,
    ): Promise<void> {
        let penaltyIds: Array<string> = [];
        let compensationIds: Array<string> = [];
        let subsidyIds: Array<string> = [];

        previousAssignationOperators.map((ao: AssignationOperator) => {
            // concat without duplicates

            penaltyIds = penaltyIds.concat(toJS(ao.model.penaltyIds));

            penaltyIds = penaltyIds.filter((item, pos) => penaltyIds.indexOf(item) === pos);

            compensationIds = compensationIds.concat(toJS(ao.model.compensationIds));

            compensationIds = compensationIds.filter((item, pos) => compensationIds.indexOf(item) === pos);

            subsidyIds = subsidyIds.concat(toJS(ao.model.subsidyIds));

            subsidyIds = subsidyIds.filter((item, pos) => subsidyIds.indexOf(item) === pos);

            return ao;
        });

        this.m.penaltyIds = this.m.penaltyIds.concat(penaltyIds);
        this.m.compensationIds = this.m.compensationIds.concat(compensationIds);
        this.m.subsidyIds = this.m.subsidyIds.concat(subsidyIds);
    }

    @observable
    public isSendingAssignationReminder: boolean = false;

    @observable
    public isAssignationReminderSent: boolean = false;

    @action
    public async sendReminder(): Promise<void> {
        this.isSendingAssignationReminder = true;

        await this.rpcClient.email.sendEmail<UpcomingTripReminderForDriverEmailArgs>(
            EmailTypes.sendUpcomingTripsRemindersForDriverEmails,
            { assignationIds: [this.model._id] },
        );

        this.isSendingAssignationReminder = false;
        this.isAssignationReminderSent = true;

        setTimeout(() => {
            this.isAssignationReminderSent = false;
        }, 3500);
    }
}
