import { SITE_DOMAIN } from "@daytrip/legacy-config";
import JSCookie from "js-cookie";

import { cookies } from "../../config.management";

export function removeAuthenticationCookies() {
    JSCookie.remove(cookies.authentication);
    JSCookie.remove(cookies.refresh);
    JSCookie.remove(cookies.authentication, { domain: SITE_DOMAIN });
    JSCookie.remove(cookies.refresh, { domain: SITE_DOMAIN });
}
