/* eslint-disable no-alert */
import type { AllRoles } from "@daytrip/legacy-enums";
import { RoleDetails } from "@daytrip/legacy-models";
import type { RoleDetailsKeys } from "@daytrip/legacy-models";
import { isUndefinedOrNull, validatePhoneNumber } from "@daytrip/utils";
import type { SimpleCountry } from "@legacy/domain/SimpleCountry";
import type { VehicleType } from "@legacy/domain/VehicleType";
import type { AutomaticEmailAttempt } from "@legacy/models/AutomaticEmailAttempt";
import { Driver } from "@legacy/models/Driver";
import { User } from "@legacy/models/User";
import { transformValidationErrorsToArrayString } from "@daytrip/legacy-transformers";
import autobind from "autobind-decorator";
import { classToPlain, plainToClass } from "class-transformer";
import type { ValidationError } from "class-validator";
import { validate } from "class-validator";
import { action, computed, observable, toJS } from "mobx";

import { globalManagementLogger } from "../../global-logger";
import { PageStore } from "../../stores/PageStore";
import { getPhoneNumberExtendedValidationMessage } from "../../utils/validateUserPhoneNumber";

import type { UserPageRouter } from "./UserPageRouter";

@autobind
export class UserPageStore extends PageStore<UserPageRouter, {}> {
    @observable
    public isDriverDrivingLicenseGrantedAtDateTimePickerOpened: boolean = false;

    @action
    public driverDrivingLicenseGrantedAtToggleDateTimePicker() {
        this.isDriverDrivingLicenseGrantedAtDateTimePickerOpened =
            !this.isDriverDrivingLicenseGrantedAtDateTimePickerOpened;
    }

    @observable
    public user?: User;

    @observable
    public userRoles?: AllRoles[];

    @observable
    public userRoleDetails?: RoleDetails;

    @observable
    public countries: Array<SimpleCountry> | undefined;

    @observable
    public editedUser: User | undefined;

    @observable
    public existingUser?: User;

    public goToExistingUser() {
        if (this.existingUser) {
            this.pageRouter.goToExistingUser(this.existingUser);
        }
    }

    @observable
    public editedUserValidationErrors: Array<ValidationError> = [];

    @observable
    public automaticEmailAttempts: Array<AutomaticEmailAttempt> = [];

    @action
    public async onFetchData() {
        await this.fetchUser();
    }

    @action
    public async fetchUser() {
        const { userId } = this.pageRouter;

        if (userId) {
            const { user, roles, roleDetails } = await this.rpcClient.userRolesV2.retrieveUserWithRoles(userId);
            this.user = user;
            this.userRoles = roles;
            this.userRoleDetails = roleDetails;
        } else {
            this.editedUser = classToPlain(new User()) as User;

            if (this.pageRouter.isDriver) {
                this.setDriver();
            }
        }

        const countries = await this.rpcClient.content.retrieveSimpleCountries({});
        this.countries = countries.sort((a, b) => a.englishName.localeCompare(b.englishName));
    }

    @action
    public userUpdateFirstName(value: string) {
        (this.editedUser as User).firstName = value;
    }

    @computed
    public get firstNameValidationMessage(): string | undefined {
        const validationErrors = this.editedUserValidationErrors.find((ve) => ve.property == "firstName");
        if (isUndefinedOrNull(validationErrors)) {
            return undefined;
        }
        return JSON.stringify(validationErrors.constraints);
    }

    @action
    public userUpdateLastName(value: string) {
        (this.editedUser as User).lastName = value;
    }

    @computed
    public get lastNameValidationMessage(): string | undefined {
        const validationErrors = this.editedUserValidationErrors.find((ve) => ve.property == "lastName");
        if (isUndefinedOrNull(validationErrors)) {
            return undefined;
        }
        return JSON.stringify(validationErrors.constraints);
    }

    @action
    public userUpdateEmail(value: string) {
        (this.editedUser as User).email = value;
    }

    @computed
    public get emailValidationMessage(): string | undefined {
        const validationErrors = this.editedUserValidationErrors.find((ve) => ve.property == "email");
        if (isUndefinedOrNull(validationErrors)) {
            return undefined;
        }
        return JSON.stringify(validationErrors.constraints);
    }

    @action
    public userRemovePaypalEmail() {
        (this.editedUser as User).paypalEmail = undefined;
    }

    @action
    public userAddPaypalEmail(value: string) {
        (this.editedUser as User).paypalEmail = value;
    }

    @action
    public userUpdatePaypalEmail(value: string) {
        (this.editedUser as User).paypalEmail = value;
    }

    @computed
    public get paypalEmailValidationMessage(): string | undefined {
        const validationErrors = this.editedUserValidationErrors.find((ve) => ve.property == "paypalEmail");
        if (isUndefinedOrNull(validationErrors)) {
            return undefined;
        }
        return JSON.stringify(validationErrors.constraints);
    }

    @action
    public userUpdateOtherEmail(index: number, value: string) {
        (this.editedUser as User).otherEmails[index] = value;
    }

    @computed
    public get otherEmailsValidationMessage(): string | undefined {
        const validationErrors = this.editedUserValidationErrors.find((ve) => ve.property == "otherEmails");
        if (isUndefinedOrNull(validationErrors)) {
            return undefined;
        }
        return JSON.stringify(validationErrors.constraints);
    }

    @action
    public userRemoveOtherEmail(index: number) {
        (this.editedUser as User).otherEmails.splice(index, 1);
    }

    @action
    public userAddOtherEmail(value: string) {
        (this.editedUser as User).otherEmails.push(value);
    }

    @action
    public userUpdatePhoneNumber(value: string) {
        (this.editedUser as User).phoneNumber = value;
    }

    @computed
    public get phoneNumberValidationMessage(): string | undefined {
        const validationErrors = this.editedUserValidationErrors.find((ve) => ve.property === "phoneNumber");
        const validationMessages = validationErrors?.constraints
            ? JSON.stringify(validationErrors.constraints)
            : undefined;

        return getPhoneNumberExtendedValidationMessage(
            ((this.editedUser ?? this.user) as User).phoneNumber,
            validationMessages,
            true,
        );
    }

    @action
    public userUpdateWhatsappNumber(value: string) {
        (this.editedUser as User).whatsappNumber = value;
    }

    @computed
    public get whatsappNumberValidationMessage(): string | undefined {
        const validationErrors = this.editedUserValidationErrors.find((ve) => ve.property == "whatsappNumber");
        if (isUndefinedOrNull(validationErrors)) {
            return undefined;
        }
        return JSON.stringify(validationErrors.constraints);
    }

    @action
    public userUpdateOtherPhoneNumber(index: number, value: string) {
        (this.editedUser as User).otherPhoneNumbers[index] = value;
    }

    @computed
    public get otherPhoneNumbersValidationMessage(): string | undefined {
        const validationErrors = this.editedUserValidationErrors.find((ve) => ve.property == "otherPhoneNumbers");
        if (isUndefinedOrNull(validationErrors)) {
            return undefined;
        }
        return JSON.stringify(validationErrors.constraints);
    }

    @action
    public userRemoveOtherPhoneNumber(index: number) {
        (this.editedUser as User).otherPhoneNumbers.splice(index, 1);
    }

    @action
    public userAddOtherPhoneNumber(value: string) {
        (this.editedUser as User).otherPhoneNumbers.push(value);
    }

    @action
    public userUpdateCountryId(value: string) {
        (this.editedUser as User).countryId = value;
    }

    @computed
    public get countryIdValidationMessage(): string | undefined {
        const validationErrors = this.editedUserValidationErrors.find((ve) => ve.property == "countryId");
        if (isUndefinedOrNull(validationErrors)) {
            return undefined;
        }
        return JSON.stringify(validationErrors.constraints);
    }

    @action
    public async setDriver() {
        if (this.editedUser == undefined) {
            this.userEdit();
        }
        (this.editedUser as User).driver = new Driver();
        await this.userEditSave();

        if (!isUndefinedOrNull(this.pageRouter.userId)) {
            this.pageRouter.gotoDriverProfile(this.pageRouter.userId);
        }
    }

    @action
    public updateDriverManagementNote(value: string) {
        ((this.editedUser as User).driver as Driver).managementNote = value;
    }

    @computed
    public get managementNoteValidationMessage(): string | undefined {
        const validationErrors = this.editedUserValidationErrors.find((ve) => ve.property == "managementNote");
        if (isUndefinedOrNull(validationErrors)) {
            return undefined;
        }
        return JSON.stringify(validationErrors.constraints);
    }

    @computed
    public get ibanValidationMessage(): string | undefined {
        const validationErrors = this.editedUserValidationErrors.find((ve) => ve.property == "iban");
        if (isUndefinedOrNull(validationErrors)) {
            return undefined;
        }
        return JSON.stringify(validationErrors.constraints);
    }

    @action
    public updateDriverDrivingLicenseGrantedAt(value: Date | undefined) {
        if (!isUndefinedOrNull(value)) {
            ((this.editedUser as User).driver as Driver).drivingLicenseGrantedAt = value;
        }
    }

    @computed
    public get drivingLicenseGrantedAtValidationMessage(): string | undefined {
        const validationErrors = this.editedUserValidationErrors.find((ve) => ve.property == "drivingLicenseGrantedAt");
        if (isUndefinedOrNull(validationErrors)) {
            return undefined;
        }
        return JSON.stringify(validationErrors.constraints);
    }

    @action
    public updateDriverWillingToRentVehicleTypes(value: Array<VehicleType>) {
        ((this.editedUser as User).driver as Driver).willingToRentVehicleTypes = value;
    }

    @action
    public updateDriverOriginLocationIds(value: Array<string>) {
        ((this.editedUser as User).driver as Driver).originLocationIds = value;
    }

    @computed
    public get isDriver(): boolean {
        return !!((this.user && this.user.driver) || (this.editedUser && this.editedUser.driver));
    }

    @observable
    public authorizedUser?: User;

    @action
    public userEdit() {
        this.editedUser = toJS(this.user);
    }

    @action
    public userEditCancelOrGoBack() {
        if (isUndefinedOrNull(this.user)) {
            window.history.back();
        } else {
            this.editedUser = undefined;
        }
    }

    @action
    public async userValidate() {
        this.editedUserValidationErrors = await validate(plainToClass(User, this.editedUser), {
            skipMissingProperties: true,
        });
    }

    @computed
    public get canUpdateRoles() {
        return this.authenticationStore.hasPermissions("UserRoles:Full");
    }

    @action
    public async userEditSave() {
        const { userId } = this.pageRouter;

        if (!this.editedUser) {
            return;
        }

        this.editedUserValidationErrors = await validate(plainToClass(User, this.editedUser), {
            skipMissingProperties: true,
        });
        if (this.editedUserValidationErrors.length > 0) {
            const validationMessages = transformValidationErrorsToArrayString(this.editedUserValidationErrors).join(
                "\n",
            );
            alert(`Oh, something is wrong. :(\n\nValidation errors:\n${validationMessages}`);
            return;
        }

        const validatePhoneNumberMessage = validatePhoneNumber((this.editedUser as User).phoneNumber, true);
        if (validatePhoneNumberMessage) {
            alert(`Oh, something is wrong. :(\n\nInvalid phone number with reason:\n${validatePhoneNumberMessage}`);
            return;
        }

        const isEditingNewUser = Boolean(!userId);

        if (isEditingNewUser) {
            const isUserAlreadyExist = await this.checkIsPossibleUserDuplicate();

            if (isUserAlreadyExist) {
                return;
            }
        }

        if (this.user) {
            await this.rpcClient.user.updateUser(this.editedUser._id, this.editedUser);
            if (this.editedUser.version != null) {
                this.editedUser.version += 1;
            } else {
                this.editedUser.version = 0;
            }
        } else {
            if (!this.userRoles?.length) {
                alert(
                    "Cannot save. User roles need to be provided, otherwise the user will be a customer. Go to Customers => create customer if you want to create a customer.",
                );
                return;
            }

            this.editedUser._id = await this.rpcClient.user.createUser(this.editedUser as User);
            this.pageRouter.gotoUserPage(this.editedUser._id);
        }
        if (this.canUpdateRoles) {
            await this.rpcClient.userRolesV2.updateRoles({
                userId: this.editedUser?._id ?? this.user?._id,
                roles: this.userRoles,
                roleDetails: this.userRoleDetails,
            });
        }

        this.user = toJS(this.editedUser);
        this.editedUser = undefined;
    }

    public async checkIsPossibleUserDuplicate(): Promise<boolean> {
        const isEmailUpdated = this.user?.email !== this.editedUser?.email;

        if (this.editedUser && this.editedUser.email && isEmailUpdated) {
            const [users, deletedUsers] = await Promise.all([
                this.rpcClient.user.retrieveUsers({ email: this.editedUser.email }),
                this.rpcClient.user.retrieveUsers({ email: this.editedUser.email, isDeleted: true }),
            ]);

            const [existingUser] = users;
            const [deletedUser] = deletedUsers;

            this.existingUser = existingUser || deletedUser;

            return !!existingUser;
        }
        return false;
    }

    @action
    public async deleteUser() {
        try {
            await this.rpcClient.user.deleteUser((this.user as User)._id);
            this.pageRouter.gotoUsersPage();
        } catch (e: any) {
            alert("Something went wrong during deletion.");
            globalManagementLogger.error(e);
        }
    }

    @action
    public updateRoles(roles: AllRoles[]) {
        this.userRoles = roles;
        const detailsKeys = Object.keys(this.userRoleDetails ?? {}) as RoleDetailsKeys[];
        const detailsToRemove = detailsKeys.filter((role) => !roles.includes(role));
        detailsToRemove.forEach((role) => {
            delete this.userRoleDetails?.[role];
        });
        if (detailsKeys.length === detailsToRemove.length) {
            this.userRoleDetails = undefined;
        }
    }

    public isDataFetched(): this is UserPageStore & UserPageStoreDataFetched {
        return (
            (!isUndefinedOrNull(this.user) || !isUndefinedOrNull(this.editedUser)) && !isUndefinedOrNull(this.countries)
        );
    }
}

export interface UserPageStoreDataFetched {
    user: User;
    userRoles: AllRoles[];
    countries: Array<SimpleCountry>;
}
