import type { PermissionParams } from "@daytrip/access-control";
import type { PlainRoute } from "react-router";

import type { RoutingStore } from "./container";
import { container, stores } from "./container";
import { getAuthenticationStore } from "./domain/authentication/AuthenticationStore.singleton";
import { getManagementAuthenticationToken } from "./utils/authentication/authenticationUtils";

export const routes = {
    index: "/",
    orders: "orders",
    ordersClaiming: "ordersClaiming",
    payments: "payments",
    customer: "customer",
    driverProfile: "driverProfile",
    driversCompanyProfile: "driversCompanyProfile",
    billingInformation: "billing-information",
    vehicles: "vehicles",
    passwordReset: "password-reset",
    customerFeedbacksForDriver: "customerFeedbacksForDriver",
    customerFeedbacksForDriversCompany: "customerFeedbacksForDriversCompany",
    users: "users",
    user: "user",
    countries: "countries",
    country: "country",
    locations: "locations",
    location: "location",
    poolLocation: "poolLocation",
    routes: "routes",
    route: "route",
    routeLandingContent: "routeLandingContent",
    routeLandingFAQs: "routeLandingFAQs",
    poolRoute: "poolRoute",
    contentMap: "contentMap",
    login: "login",
    travelDataEditor: "travelDataEditor",
    driverAvailability: "driverAvailability",
    driverEarlyOffers: "driverEarlyOffers",
    drivers: "drivers",
    driversCompanies: "driversCompanies",
    driversPayouts: "driversPayouts",
    order: "order",
    driversAssignation: "driversAssignation",
    blacklistedDepartureDates: "blacklistedDepartureDates",
    upcomingTrips: "upcomingTrips",
    driversCompanyUpcomingTrips: "driversCompanyUpcomingTrips",
    completedTrips: "completedTrips",
    driversCompanyCompletedTrips: "driversCompanyCompletedTrips",
    declinedAndCancelledTrips: "declinedAndCancelledTrips",
    driversCompanyDeclinedAndCancelledTrips: "driversCompanyDeclinedAndCancelledTrips",
    customers: "customers",
    documentTypes: "documentTypes",
    driversMap: "driversMap",
    vehicleModels: "vehicleModels",
    ordersAddressCheck: "ordersAddressCheck",
    ordersDepositCheck: "ordersDepositCheck",
    driversAssignations: "driversAssignations",
    driversBalances: "driversBalances",
    customersFeedbacks: "customersFeedbacks",
    driversFeedbacks: "driversFeedbacks",
    tags: "tags",
    companyDrivers: "companyDrivers",
    hostAgency: "hostAgency",
    hostAgencies: "hostAgencies",
    travelAgents: "travelAgents",
    travelAgentsPayouts: "travelAgentsPayouts",
    travelAgent: "travelAgent",
    currencies: "currencies",
    referralCodes: "referralCodes",
    translations: "translations",
    languages: "languages",
    exports: "exports",
    apiPartners: "apiPartners",
    apiPartner: "apiPartner",
    refunds: "refunds",
    numberingTemplates: "numberingTemplates",
    invoices: "invoices",
    assignationOffers: "assignationOffers",
    assignations: "assignations",
    flexibleOffers: "flexibleOffers",
    rides: "rides",
    ride: "ride",
    driverCountryRules: "driverCountryRules",
    notFoundPage: "notFoundPage",
    cooperationAgreement: "cooperationAgreement",
    cooperationAgreementReview: "cooperationAgreementReview",
    orderCombos: "order-combos",
    comboAssignationOffers: "combo-assignation-offers",
    comboAssignationOffer: "combo-assignation-offer",
    driverFaq: "driverFaq",
    tripsStatus: "trips-status",
    cooperationAgreementSign: "cooperation-agreement/sign",

    freshMeetingPositions: "fresh/meetingPositions",

    freshBookings: "fresh/bookings",
    freshBooking: "fresh/booking",

    freshTrips: "fresh/trips",
    freshTripCreation: "fresh/trip/create",
    freshTrip: "fresh/trip",

    freshPayoutRequests: "fresh/payout-requests",
    freshPayoutFulfillments: "fresh/payout-fulfillments",
    freshPayouts: "fresh/payouts",

    freshTripShapes: "fresh/trip-shapes",
    freshTripShapeCreation: "fresh/trip-shapes/create",
    freshTripShape: "fresh/trip-shapes/trip-shape",
} as const;

type Routes = typeof routes;
type RoutePath = Routes[keyof Routes];
export type RouteAccessPermission = {
    [key in RoutePath]: PermissionParams;
};

// WARNING: Permissions must be manually applied to pages via restrictedPageAccess function
export const routesAccessPermissions: Omit<RouteAccessPermission, "index" | "login" | "notFoundPage"> = {
    // -- Unprotected --
    [routes.index]: null,
    [routes.login]: null,
    [routes.notFoundPage]: null,

    // -- Users menu --
    [routes.users]: "UserRoles:Full",
    [routes.user]: { or: ["User_Myself:Read", "UserRoles:Full"] },

    // -- Customers menu items --
    [routes.orders]: "OrdersPage:Read",
    [routes.order]: "OrdersPage:Read",
    [routes.customers]: "CustomersPage:Read",
    [routes.customer]: "CustomersPage:Read",
    [routes.hostAgencies]: "HostAgenciesPage:Read",
    [routes.hostAgency]: "HostAgenciesPage:Read",
    [routes.travelAgents]: "TravelAgentsPage:Read",
    [routes.travelAgentsPayouts]: "TravelAgentsPayoutsPage:Read",
    [routes.travelAgent]: "TravelAgentsPage:Read",
    [routes.apiPartners]: "ApiPartnersPage:Read",
    [routes.apiPartner]: "ApiPartnersPage:Read",
    [routes.referralCodes]: "ReferralCodesPage:Read",
    [routes.payments]: "PaymentsPage:Read",
    [routes.refunds]: "RefundsPage:Read",
    [routes.ordersAddressCheck]: "OrdersAddressCheckPage:Read",
    [routes.ordersDepositCheck]: "OrdersDepositCheckPage:Read",
    [routes.customersFeedbacks]: "CustomersFeedbacksPage:Read",
    [routes.blacklistedDepartureDates]: "BlacklistedDepartureDatesPage:Read",
    // TODO(mg): going to be removed soon
    // but allowing very limited number of people access it by direct for some urgent cases if any
    [routes.rides]: "UserRoles:Full",
    [routes.ride]: "UserRoles:Full",

    // -- Drivers menu items --
    [routes.driversMap]: "DriversMapPage:Read",
    [routes.drivers]: "DriversPage:Read",
    [routes.driversCompanies]: "CompaniesPage:Read",
    [routes.assignationOffers]: "AssignationOffersPage:Read",
    [routes.driversAssignation]: "AssignationTool:Read",
    [routes.orderCombos]: "OrderCombos:Read",
    [routes.comboAssignationOffers]: "ComboAssignationPage:Read",
    [routes.comboAssignationOffer]: "ComboAssignationPage:Read",
    [routes.driversPayouts]: "Payout:Read",
    [routes.driversBalances]: "DriversBalancePage:Read",
    [routes.driversFeedbacks]: "DriversFeedbacksPage:Read",
    [routes.assignations]: "AssignationsPage:Read",
    [routes.vehicleModels]: "VehicleModel:Read",
    [routes.documentTypes]: "DocumentTypesPage:Read",
    [routes.cooperationAgreement]: "CooperationAgreementPage:Read",
    [routes.cooperationAgreementReview]: "CooperationAgreementReviewPage:Read",
    [routes.driverCountryRules]: "DriverCountryRulesPage:Read",
    [routes.driverFaq]: "DriverFaq:GetAll",
    [routes.tripsStatus]: "TripStatus:Read",
    [routes.passwordReset]: "PasswordResetPage:Read",
    [routes.companyDrivers]: "CompanyDriversPage:Read",
    [routes.driverProfile]: "DriverProfilePage:Read",
    [routes.driversCompanyProfile]: "DriverProfilePage:Read",
    [routes.vehicles]: "DriverVehiclesPage:Read",
    [routes.customerFeedbacksForDriver]: "CustomerFeedbacksForDriverPage:Read",
    [routes.customerFeedbacksForDriversCompany]: "CustomerFeedbacksForDriversCompanyPage:Read",
    [routes.upcomingTrips]: "UpcomingTripsPage:Read",
    [routes.driversCompanyUpcomingTrips]: "DriversCompanyUpcomingTrips:Page",
    [routes.completedTrips]: "CompletedTripsPage:Read",
    [routes.driversCompanyCompletedTrips]: "CompletedTripsPage:Read",
    [routes.declinedAndCancelledTrips]: "DeclinedAndCancelledTripsPage:Read",
    [routes.driversCompanyDeclinedAndCancelledTrips]: "DriversCompanyDeclinedAndCancelledTripsPage:Read",
    [routes.driversAssignations]: "DriverAssignations:Read",
    [routes.driverEarlyOffers]: "EarlyOfferCredit:Read",

    // -- Driver Layout Nav items --
    [routes.driverAvailability]: { or: ["Unavailability_Myself:Read", "Unavailability:Read"] },
    [routes.cooperationAgreementSign]: "CooperationAgreementSignPage:Read",

    // -- Driver Tab Nav items --
    [routes.invoices]: { or: ["Invoice_Myself:Read", "Invoice:Read"] },
    [routes.billingInformation]: "BillingInformationPage:Read",

    // -- Content menu items --
    [routes.contentMap]: "ContentMapPage:Read",
    [routes.countries]: "ContentCountriesPage:Read",
    [routes.country]: "ContentCountriesPage:Read",
    [routes.locations]: "ContentLocationsPage:Read",
    [routes.location]: "ContentLocationsPage:Read",
    [routes.poolLocation]: "ContentLocationsPage:Read",
    [routes.routes]: "ContentRoutesPage:Read",
    [routes.route]: "ContentRoutesPage:Read",
    [routes.routeLandingContent]: "ContentRoutesPage:Read",
    [routes.routeLandingFAQs]: "ContentRoutesPage:Read",
    [routes.poolRoute]: "ContentRoutesPage:Read",
    [routes.tags]: "ContentTagsPage:Read",
    [routes.travelDataEditor]: "ContentTravelDataPage:Read",
    [routes.currencies]: "ContentCurrenciesPage:Read",
    [routes.exports]: "ContentExportsPage:Read",
    [routes.translations]: "ContentTranslationsPage:Read",
    [routes.languages]: "ContentLanguagesPage:Read",
    [routes.numberingTemplates]: "ContentNumberingTemplatesPage:Read",
    [routes.flexibleOffers]: "ContentFlexibleOffersPage:Read",

    // -- Fresh menu items --
    [routes.freshBookings]: "Fresh",
    [routes.freshBooking]: "Fresh",
    [routes.freshTrips]: "Fresh",
    [routes.freshTrip]: "Fresh",
    [routes.freshTripCreation]: "Fresh",
    [routes.freshMeetingPositions]: "Fresh",
    [routes.freshPayoutRequests]: "Fresh",
    [routes.freshPayoutFulfillments]: "Fresh",
    [routes.freshPayouts]: "Fresh",
    [routes.freshTripShapes]: "Fresh",
    [routes.freshTripShape]: "Fresh",
    [routes.freshTripShapeCreation]: "Fresh",

    // -- Claim menu item --
    [routes.ordersClaiming]: "ClaimOrderRequest_Myself:Create",
};

export const rootRoute: PlainRoute = {
    path: routes.index,
    indexRoute: {
        getComponent: (_location, callback) => {
            require.ensure(
                [],
                (require) => {
                    callback(null, require<any>("./pages/Index/IndexPage").IndexPage);
                },
                "index",
            );
        },
        onEnter: () => {
            const routingStore = container.get<RoutingStore>(stores.routing);
            const authenticationStore = getAuthenticationStore();

            try {
                const authentication = getManagementAuthenticationToken();

                if (authentication) {
                    authenticationStore.setAuthenticationToken(authentication);
                }

                if (authenticationStore.isAuthenticationTokenSet) {
                    const { isDriver, isDriverActive, isDriversCompany, isDriversCompanyActive, isCompanyDriver } =
                        authenticationStore;

                    if (isDriver || isCompanyDriver) {
                        if (isDriverActive) {
                            routingStore.replace(routes.upcomingTrips);
                        } else {
                            routingStore.replace(routes.driverProfile);
                        }
                    } else if (isDriversCompany) {
                        if (isDriversCompanyActive) {
                            routingStore.replace(routes.driversCompanyUpcomingTrips);
                        } else {
                            routingStore.replace(routes.driversCompanyProfile);
                        }
                    }
                } else {
                    authenticationStore.logout();
                }
            } catch (e: any) {
                authenticationStore.logout();
            }
        },
    },
    childRoutes: [
        {
            path: routes.notFoundPage,
            getComponent: (_location, callback) => {
                require.ensure(
                    [],
                    (require) => {
                        callback(null, require<any>("./pages/NotFound/NotFoundPage").NotFoundPage);
                    },
                    "notFoundPage",
                );
            },
        },
        {
            path: routes.orders,
            getComponent: (_location, callback) => {
                require.ensure(
                    [],
                    (require) => {
                        callback(null, require<any>("./pages/Orders/OrdersPage").OrdersPage);
                    },
                    "orders",
                );
            },
        },
        {
            path: routes.customer,
            getComponent: (_location, callback) => {
                require.ensure(
                    [],
                    (require) => {
                        callback(null, require<any>("./pages/Customer/CustomerPage").CustomerPage);
                    },
                    "customer",
                );
            },
        },
        {
            path: routes.driverProfile,
            getComponent: (_location, callback) => {
                require.ensure(
                    [],
                    (require) => {
                        callback(null, require<any>("./pages/DriverProfile/DriverProfilePage").DriverProfilePage);
                    },
                    "driverProfile",
                );
            },
        },
        {
            path: routes.driversCompanyProfile,
            getComponent: (_location, callback) => {
                require.ensure(
                    [],
                    (require) => {
                        callback(null, require<any>("./pages/DriversCompany/DriversCompanyPage").DriversCompanyPage);
                    },
                    "driversCompanyProfile",
                );
            },
        },
        {
            path: routes.billingInformation,
            getComponent: (_location, callback) => {
                require.ensure(
                    [],
                    (require) => {
                        callback(
                            null,
                            require<any>("./pages/BillingInformation/BillingInformationPage").BillingInformationPage,
                        );
                    },
                    "billingInformation",
                );
            },
        },
        {
            path: routes.vehicles,
            getComponent: (_location, callback) => {
                require.ensure(
                    [],
                    (require) => {
                        callback(null, require<any>("./pages/Vehicles/VehiclesPage").VehiclesPage);
                    },
                    "vehicles",
                );
            },
        },
        {
            path: routes.passwordReset,
            getComponent: (_location, callback) => {
                require.ensure(
                    [],
                    (require) => {
                        callback(null, require<any>("./pages/PasswordReset/PasswordResetPage").PasswordResetPage);
                    },
                    "passwordReset",
                );
            },
        },
        {
            path: routes.customerFeedbacksForDriver,
            getComponent: (_location, callback) => {
                require.ensure(
                    [],
                    (require) => {
                        callback(
                            null,
                            require<any>("./pages/CustomerFeedbacksForDriver/CustomerFeedbacksForDriverPage")
                                .CustomerFeedbacksForDriverPage,
                        );
                    },
                    "customerFeedbacksForDriver",
                );
            },
        },
        {
            path: routes.customerFeedbacksForDriversCompany,
            getComponent: (_location, callback) => {
                require.ensure(
                    [],
                    (require) => {
                        callback(
                            null,
                            require<any>("./pages/CustomerFeedbacksForDriversCompany/CustomerFeedbacksForDriversCompanyPage")
                                .CustomerFeedbacksForDriversCompanyPage,
                        );
                    },
                    "customerFeedbacksForDriversCompany",
                );
            },
        },
        {
            path: routes.payments,
            getComponent: (_location, callback) => {
                require.ensure(
                    [],
                    (require) => {
                        callback(null, require<any>("./pages/Payments/PaymentsPage").PaymentsPage);
                    },
                    "payments",
                );
            },
        },
        {
            path: routes.refunds,
            getComponent: (_location, callback) => {
                require.ensure(
                    [],
                    (require) => {
                        callback(null, require<any>("./pages/Refunds/RefundsPage").RefundsPage);
                    },
                    "refunds",
                );
            },
        },
        {
            path: routes.users,
            getComponent: (_location, callback) => {
                require.ensure(
                    [],
                    (require) => {
                        callback(null, require<any>("./pages/Users/UsersPage").UsersPage);
                    },
                    "users",
                );
            },
        },
        {
            path: routes.user,
            getComponent: (_location, callback) => {
                require.ensure(
                    [],
                    (require) => {
                        callback(null, require<any>("./pages/User/UserPage").UserPage);
                    },
                    "user",
                );
            },
        },
        {
            path: routes.countries,
            getComponent: (_location, callback) => {
                require.ensure(
                    [],
                    (require) => {
                        callback(null, require<any>("./pages/Countries/CountriesPage").CountriesPage);
                    },
                    "countries",
                );
            },
        },
        {
            path: routes.country,
            getComponent: (_location, callback) => {
                require.ensure(
                    [],
                    (require) => {
                        callback(null, require<any>("./pages/Country/CountryPage").CountryPage);
                    },
                    "country",
                );
            },
        },
        {
            path: routes.locations,
            getComponent: (_location, callback) => {
                require.ensure(
                    [],
                    (require) => {
                        callback(null, require<any>("./pages/Locations/LocationsPage").LocationsPage);
                    },
                    "locations",
                );
            },
        },
        {
            path: routes.location,
            getComponent: (_location, callback) => {
                require.ensure(
                    [],
                    (require) => {
                        callback(null, require<any>("./pages/Location/LocationPage").LocationPage);
                    },
                    "location",
                );
            },
        },
        {
            path: routes.poolLocation,
            getComponent: (_location, callback) => {
                require.ensure(
                    [],
                    (require) => {
                        callback(null, require<any>("./pages/Location/PoolLocation/PoolLocationPage").PoolLocationPage);
                    },
                    "poolLocation",
                );
            },
        },
        {
            path: routes.routes,
            getComponent: (_location, callback) => {
                require.ensure(
                    [],
                    (require) => {
                        callback(null, require<any>("./pages/Routes/RoutesPage").RoutesPage);
                    },
                    "routes",
                );
            },
        },
        {
            path: routes.route,
            getComponent: (_location, callback) => {
                require.ensure(
                    [],
                    (require) => {
                        callback(null, require<any>("./pages/Route/RoutePage").RoutePage);
                    },
                    "route",
                );
            },
        },
        {
            path: routes.routeLandingContent,
            getComponent: (_location, callback) => {
                require.ensure(
                    [],
                    (require) => {
                        callback(
                            null,
                            require<any>("./pages/Route/LandingContent/LandingContentPage").RouteLandingContentPage,
                        );
                    },
                    "routeLandingContent",
                );
            },
        },
        {
            path: routes.routeLandingFAQs,
            getComponent: (_location, callback) => {
                require.ensure(
                    [],
                    (require) => {
                        callback(null, require<any>("./pages/Route/LandingFAQs/LandingFAQsPage").RouteLandingFAQsPage);
                    },
                    "routeLandingFAQs",
                );
            },
        },
        {
            path: routes.poolRoute,
            getComponent: (_location, callback) => {
                require.ensure(
                    [],
                    (require) => {
                        callback(null, require<any>("./pages/Route/PoolRoute/PoolRoutePage").PoolRoutePage);
                    },
                    "poolRoute",
                );
            },
        },
        {
            path: routes.contentMap,
            getComponent: (_location, callback) => {
                require.ensure(
                    [],
                    (require) => {
                        callback(null, require<any>("./pages/ContentMap/ContentMapPage").ContentMapPage);
                    },
                    "contentMap",
                );
            },
        },
        {
            path: routes.login,
            getComponent: (_location, callback) => {
                require.ensure(
                    [],
                    (require) => {
                        callback(null, require<any>("./pages/Login/LoginPage").LoginPage);
                    },
                    "login",
                );
            },
        },
        {
            path: routes.travelDataEditor,
            getComponent: (_location, callback) => {
                require.ensure(
                    [],
                    (require) => {
                        callback(
                            null,
                            require<any>("./pages/TravelDataEditor/TravelDataEditorPage").TravelDataEditorPage,
                        );
                    },
                    "travelDataEditor",
                );
            },
        },
        {
            path: routes.driverAvailability,
            getComponent: (_location, callback) => {
                require.ensure(
                    [],
                    (require) => {
                        callback(
                            null,
                            require<any>("./pages/DriverAvailability/DriverAvailabilityPage").DriverAvailabilityPage,
                        );
                    },
                    "driverAvailability",
                );
            },
        },
        {
            path: routes.driverEarlyOffers,
            getComponent: (_location, callback) => {
                require.ensure(
                    [],
                    (require) => {
                        callback(
                            null,
                            require<any>("./pages/DriverEarlyOffers/DriverEarlyOffersPage").DriverEarlyOffersPage,
                        );
                    },
                    "driverEarlyOffers",
                );
            },
        },
        {
            path: routes.drivers,
            getComponent: (_location, callback) => {
                require.ensure(
                    [],
                    (require) => {
                        callback(null, require<any>("./pages/Drivers/DriversPage").DriversPage);
                    },
                    "drivers",
                );
            },
        },
        {
            path: routes.driversCompanies,
            getComponent: (_location, callback) => {
                require.ensure(
                    [],
                    (require) => {
                        callback(
                            null,
                            require<any>("./pages/DriversCompanies/DriversCompaniesPage").DriversCompaniesPage,
                        );
                    },
                    "driversCompanies",
                );
            },
        },
        {
            path: routes.driversPayouts,
            getComponent: (_location, callback) => {
                require.ensure(
                    [],
                    (require) => {
                        callback(null, require<any>("./pages/DriversPayouts/DriversPayoutsPage").DriversPayoutsPage);
                    },
                    "driversPayouts",
                );
            },
        },
        {
            path: routes.order,
            getComponent: (_location, callback) => {
                require.ensure(
                    [],
                    (require) => {
                        callback(null, require<any>("./pages/Order/OrderPage").OrderPage);
                    },
                    "order",
                );
            },
        },
        {
            path: routes.driversAssignation,
            getComponent: (_location, callback) => {
                require.ensure(
                    [],
                    (require) => {
                        callback(
                            null,
                            require<any>("./pages/DriversAssignation/DriversAssignationPage").DriversAssignationPage,
                        );
                    },
                    "driversAssignation",
                );
            },
        },
        {
            path: routes.blacklistedDepartureDates,
            getComponent: (_location, callback) => {
                require.ensure(
                    [],
                    (require) => {
                        callback(
                            null,
                            require<any>("./pages/BlacklistedDepartureDates/BlacklistedDepartureDatesPage")
                                .BlacklistedDepartureDatesPage,
                        );
                    },
                    "blacklistedDepartureDates",
                );
            },
        },
        {
            path: routes.completedTrips,
            getComponent: (_location, callback) => {
                require.ensure(
                    [],
                    (require) => {
                        callback(null, require<any>("./pages/CompletedTrips/CompletedTripsPage").CompletedTripsPage);
                    },
                    "completedTrips",
                );
            },
        },
        {
            path: routes.driversCompanyCompletedTrips,
            getComponent: (_location, callback) => {
                require.ensure(
                    [],
                    (require) => {
                        callback(null, require<any>("./pages/CompletedTrips/CompletedTripsPage").CompletedTripsPage);
                    },
                    "completedTrips",
                );
            },
        },
        {
            path: routes.upcomingTrips,
            getComponent: (_location, callback) => {
                require.ensure(
                    [],
                    (require) => {
                        callback(null, require<any>("./pages/UpcomingTrips/UpcomingTripsPage").UpcomingTripsPage);
                    },
                    "upcomingTrips",
                );
            },
            onEnter: () => {
                const routingStore = container.get<RoutingStore>(stores.routing);
                const authenticationStore = getAuthenticationStore();

                if (authenticationStore.isAuthenticationTokenSet) {
                    const { isDriversCompany, isDriversCompanyActive } = authenticationStore;

                    if (!isDriversCompany) {
                        return;
                    }

                    routingStore.replace(
                        isDriversCompanyActive ? routes.driversCompanyUpcomingTrips : routes.driversCompanyProfile,
                    );
                }
            },
        },
        {
            path: routes.driversCompanyUpcomingTrips,
            getComponent: (_location, callback) => {
                require.ensure(
                    [],
                    (require) => {
                        callback(
                            null,
                            require<any>("./pages/DriversCompanyUpcomingTrips/DriversCompanyUpcomingTripsPage")
                                .DriversCompanyUpcomingTripsPage,
                        );
                    },
                    "driversCompanyUpcomingTrips",
                );
            },
        },
        {
            path: routes.declinedAndCancelledTrips,
            getComponent: (_location, callback) => {
                require.ensure(
                    [],
                    (require) => {
                        callback(
                            null,
                            require<any>("./pages/DeclinedAndCancelledTrips/DeclinedAndCancelledTripsPage")
                                .DeclinedAndCancelledTripsPage,
                        );
                    },
                    "declinedAndCancelledTrips",
                );
            },
        },
        {
            path: routes.driversCompanyDeclinedAndCancelledTrips,
            getComponent: (_location, callback) => {
                require.ensure(
                    [],
                    (require) => {
                        callback(
                            null,
                            require<any>("./pages/DriversCompanyDeclinedAndCancelledTrips/DriversCompanyDeclinedAndCancelledTripsPage")
                                .DriversCompanyDeclinedAndCancelledTripsPage,
                        );
                    },
                    "driversCompanyDeclinedAndCancelledTrips",
                );
            },
        },
        {
            path: routes.customers,
            getComponent: (_location, callback) => {
                require.ensure(
                    [],
                    (require) => {
                        callback(null, require<any>("./pages/Customers/CustomersPage").CustomersPage);
                    },
                    "customers",
                );
            },
        },
        {
            path: routes.apiPartners,
            getComponent: (_location, callback) => {
                require.ensure(
                    [],
                    (require) => {
                        callback(null, require<any>("./pages/ApiPartners/ApiPartnersPage").ApiPartnersPage);
                    },
                    "apiPartners",
                );
            },
        },
        {
            path: routes.apiPartner,
            getComponent: (_location, callback) => {
                require.ensure(
                    [],
                    (require) => {
                        callback(null, require<any>("./pages/ApiPartner/ApiPartnerPage").ApiPartnerPage);
                    },
                    "apiPartner",
                );
            },
        },
        {
            path: routes.documentTypes,
            getComponent: (_location, callback) => {
                require.ensure(
                    [],
                    (require) => {
                        callback(null, require<any>("./pages/DocumentTypes/DocumentTypesPage").DocumentTypesPage);
                    },
                    "documentTypes",
                );
            },
        },
        {
            path: routes.driversMap,
            getComponent: (_location, callback) => {
                require.ensure(
                    [],
                    (require) => {
                        callback(null, require<any>("./pages/DriversMap/DriversMapPage").DriversMapPage);
                    },
                    "driversMap",
                );
            },
        },
        {
            path: routes.vehicleModels,
            getComponent: (_location, callback) => {
                require.ensure(
                    [],
                    (require) => {
                        callback(null, require<any>("./pages/VehicleModels/VehicleModelsPage").VehicleModelsPage);
                    },
                    "vehicleModels",
                );
            },
        },
        {
            path: routes.driversAssignations,
            getComponent: (_location, callback) => {
                require.ensure(
                    [],
                    (require) => {
                        callback(
                            null,
                            require<any>("./pages/DriversAssignations/DriversAssignationsPage").DriversAssignationsPage,
                        );
                    },
                    "driversAssignations",
                );
            },
        },
        {
            path: routes.cooperationAgreementSign,
            getComponent: (_location, callback) => {
                require.ensure(
                    [],
                    (require) => {
                        callback(
                            null,
                            require<any>("./pages/CooperationAgreementSign/CooperationAgreementSignPage")
                                .CooperationAgreementSignPage,
                        );
                    },
                    "cooperationAgreementSign",
                );
            },
        },
        {
            path: routes.driversBalances,
            getComponent: (_location, callback) => {
                require.ensure(
                    [],
                    (require) => {
                        callback(null, require<any>("./pages/DriversBalances/DriversBalancesPage").DriversBalancesPage);
                    },
                    "driversBalances",
                );
            },
        },
        {
            path: routes.ordersAddressCheck,
            getComponent: (_location, callback) => {
                require.ensure(
                    [],
                    (require) => {
                        callback(
                            null,
                            require<any>("./pages/OrdersCheck/OrdersAddressCheck/OrdersAddressCheckPage")
                                .OrdersAddressCheckPage,
                        );
                    },
                    "ordersAddressCheck",
                );
            },
        },
        {
            path: routes.ordersDepositCheck,
            getComponent: (_location, callback) => {
                require.ensure(
                    [],
                    (require) => {
                        callback(
                            null,
                            require<any>("./pages/OrdersCheck/OrdersDepositCheck/OrdersDepositCheckPage")
                                .OrdersDepositCheckPage,
                        );
                    },
                    "ordersDepositCheck",
                );
            },
        },
        {
            path: routes.customersFeedbacks,
            getComponent: (_location, callback) => {
                require.ensure(
                    [],
                    (require) => {
                        callback(
                            null,
                            require<any>("./pages/CustomersFeedbacks/CustomersFeedbacksPage").CustomersFeedbacksPage,
                        );
                    },
                    "customersFeedbacks",
                );
            },
        },
        {
            path: routes.driversFeedbacks,
            getComponent: (_location, callback) => {
                require.ensure(
                    [],
                    (require) => {
                        callback(
                            null,
                            require<any>("./pages/CustomersFeedbacks/CustomersFeedbacksPage").DriversFeedbacksPage,
                        );
                    },
                    "driversFeedbacks",
                );
            },
        },
        {
            path: routes.tags,
            getComponent: (_location, callback) => {
                require.ensure(
                    [],
                    (require) => {
                        callback(null, require<any>("./pages/Tags/TagsPage").TagsPage);
                    },
                    "tags",
                );
            },
        },
        {
            path: routes.companyDrivers,
            getComponent: (_location, callback) => {
                require.ensure(
                    [],
                    (require) => {
                        callback(null, require<any>("./pages/CompanyDrivers/CompanyDriversPage").CompanyDriversPage);
                    },
                    "companyDrivers",
                );
            },
        },
        {
            path: routes.hostAgency,
            getComponent: (_location, callback) => {
                require.ensure(
                    [],
                    (require) => {
                        callback(null, require<any>("./pages/HostAgency/HostAgencyPage").HostAgencyPage);
                    },
                    "hostAgency",
                );
            },
        },
        {
            path: routes.hostAgencies,
            getComponent: (_location, callback) => {
                require.ensure(
                    [],
                    (require) => {
                        callback(null, require<any>("./pages/HostAgencies/HostAgenciesPage").HostAgenciesPage);
                    },
                    "hostAgencies",
                );
            },
        },
        {
            path: routes.travelAgents,
            getComponent: (_location, callback) => {
                require.ensure(
                    [],
                    (require) => {
                        callback(null, require<any>("./pages/TravelAgents/TravelAgentsPage").TravelAgentsPage);
                    },
                    "travelAgents",
                );
            },
        },
        {
            path: routes.travelAgentsPayouts,
            getComponent: (_location, callback) => {
                require.ensure(
                    [],
                    (require) => {
                        callback(
                            null,
                            require<any>("./pages/TravelAgentsPayouts/TravelAgentsPayoutsRequestsPage")
                                .TravelAgentsPayoutsRequestsPage,
                        );
                    },
                    "travelAgentsPayouts",
                );
            },
        },
        {
            path: routes.travelAgent,
            getComponent: (_location, callback) => {
                require.ensure(
                    [],
                    (require) => {
                        callback(null, require<any>("./pages/TravelAgent/TravelAgentPage").TravelAgentPage);
                    },
                    "travelAgent",
                );
            },
        },
        {
            path: routes.currencies,
            getComponent: (_location, callback) => {
                require.ensure(
                    [],
                    (require) => {
                        callback(null, require<any>("./pages/Currencies/CurrenciesPage").CurrenciesPage);
                    },
                    "currencies",
                );
            },
        },
        {
            path: routes.referralCodes,
            getComponent: (_location, callback) => {
                require.ensure(
                    [],
                    (require) => {
                        callback(null, require<any>("./pages/ReferralCodes/ReferralCodesPage").ReferralCodesPage);
                    },
                    "referralCodes",
                );
            },
        },
        {
            path: routes.translations,
            getComponent: (_location, callback) => {
                require.ensure(
                    [],
                    (require) => {
                        callback(null, require<any>("./pages/Translations/TranslationsPage").TranslationsPage);
                    },
                    "translations",
                );
            },
        },
        {
            path: routes.languages,
            getComponent: (_location, callback) => {
                require.ensure(
                    [],
                    (require) => {
                        callback(null, require<any>("./pages/Languages/LanguagesPage").LanguagesPage);
                    },
                    "languages",
                );
            },
        },
        {
            path: routes.exports,
            getComponent: (_location, callback) => {
                require.ensure(
                    [],
                    (require) => {
                        callback(null, require<any>("./pages/Exports/ExportsPage").ExportsPage);
                    },
                    "exports",
                );
            },
        },
        {
            path: routes.ordersClaiming,
            getComponent: (_location, callback) => {
                require.ensure(
                    [],
                    (require) => {
                        callback(null, require<any>("./pages/ClaimingTool/ClaimingToolPage").ClaimingToolPage);
                    },
                    "ordersClaiming",
                );
            },
        },
        {
            path: routes.numberingTemplates,
            getComponent: (_location, callback) => {
                require.ensure(
                    [],
                    (require) => {
                        callback(
                            null,
                            require<any>("./pages/NumberingTemplates/NumberingTemplatesPage").NumberingTemplatesPage,
                        );
                    },
                    "numberingTemplates",
                );
            },
        },
        {
            path: routes.invoices,
            getComponent: (_location, callback) => {
                require.ensure(
                    [],
                    (require) => {
                        callback(null, require<any>("./pages/Invoices/InvoicesPage").InvoicesPage);
                    },
                    "invoices",
                );
            },
        },
        {
            path: routes.tripsStatus,
            getComponent: (_location, cb) => {
                require.ensure(
                    [],
                    (require) => {
                        cb(null, require("./pages/TripsStatus/TripsStatusPage").TripsStatusPage);
                    },
                    "tripStatus",
                );
            },
        },
        {
            path: routes.assignationOffers,
            getComponent: (_location, cb) => {
                require.ensure(
                    [],
                    (require) => {
                        cb(null, require("./pages/AssignationOffers/AssignationOffersPage").AssignationOffersPage);
                    },
                    "assignationOffers",
                );
            },
        },
        {
            path: routes.assignations,
            getComponent: (_location, callback) => {
                require.ensure(
                    [],
                    (require) => {
                        callback(null, require<any>("./pages/Assignations/AssignationsPage").AssignationsPage);
                    },
                    "assignations",
                );
            },
        },
        {
            path: routes.flexibleOffers,
            getComponent: (_location, callback) => {
                require.ensure(
                    [],
                    (require) => {
                        callback(null, require<any>("./pages/FlexibleOffers/FlexibleOffersPage").FlexibleOffersPage);
                    },
                    "flexibleOffers",
                );
            },
        },
        {
            path: routes.rides,
            getComponent: (_location, callback) => {
                require.ensure(
                    [],
                    (require) => {
                        callback(null, require<any>("./pages/Rides/RidesPage").RidesPage);
                    },
                    "rides",
                );
            },
        },
        {
            path: routes.ride,
            getComponent: (_location, callback) => {
                require.ensure(
                    [],
                    (require) => {
                        callback(null, require<any>("./pages/Ride/RidePage").RidePage);
                    },
                    "ride",
                );
            },
        },
        {
            path: routes.cooperationAgreement,
            getComponent: (_location, callback) => {
                require.ensure(
                    [],
                    (require) => {
                        callback(
                            null,
                            require<any>("./pages/CooperationAgreement/CooperationAgreementPage")
                                .CooperationAgreementPage,
                        );
                    },
                    "cooperationAgreement",
                );
            },
        },
        {
            path: routes.cooperationAgreementReview,
            getComponent: (_location, callback) => {
                require.ensure(
                    [],
                    (require) => {
                        callback(
                            null,
                            require<any>("./pages/CooperationAgreementReview/CooperationAgreementReviewPage")
                                .CooperationAgreementReviewPage,
                        );
                    },
                    "cooperationAgreementReview",
                );
            },
        },
        {
            path: routes.orderCombos,
            getComponent: (_location, callback) => {
                require.ensure(
                    [],
                    (require) => {
                        callback(null, require<any>("./pages/OrderCombos/OrderCombosPage").OrderCombosPage);
                    },
                    "orderCombos",
                );
            },
        },
        {
            path: routes.comboAssignationOffers,
            getComponent: (_location, callback) => {
                require.ensure(
                    [],
                    (require) => {
                        callback(
                            null,
                            require<any>("./pages/ComboAssignationOffers/ComboAssignationOffersPage")
                                .ComboAssignationOffersPage,
                        );
                    },
                    "comboAssignationOffers",
                );
            },
        },
        {
            path: routes.comboAssignationOffer,
            getComponent: (_location, callback) => {
                require.ensure(
                    [],
                    (require) => {
                        callback(
                            null,
                            require<any>("./pages/ComboAssignationOffer/ComboAssignationOfferPage")
                                .ComboAssignationOfferPage,
                        );
                    },
                    "comboAssignationOffer",
                );
            },
        },
        {
            path: routes.driverCountryRules,
            getComponent: (_location, callback) => {
                require.ensure(
                    [],
                    (require) => {
                        callback(
                            null,
                            require<any>("./pages/DriverCountryRules/DriverCountryRulesPage").DriverCountryRulesPage,
                        );
                    },
                    "driverCountryRules",
                );
            },
        },
        {
            path: routes.driverFaq,
            getComponent: (_location, callback) => {
                require.ensure(
                    [],
                    (require) => {
                        callback(null, require<any>("./pages/DriversFaq/DriversFaqPage").DriversFaqPage);
                    },
                    "driverFaq",
                );
            },
        },
        {
            path: routes.freshMeetingPositions,
            getComponent: (_location, callback) => {
                require.ensure([], (require) => {
                    callback(
                        null,
                        require<any>("./fresh/pages/MeetingPositions/MeetingPositionsPage").MeetingPositionsPage,
                    );
                });
            },
        },
        {
            path: routes.freshTrip,
            getComponent: (_location, callback) => {
                require.ensure(
                    [],
                    (require) => {
                        callback(null, require<any>("./fresh/pages/Trip/TripPage").TripPage);
                    },
                    "freshTrip",
                );
            },
        },
        {
            path: routes.freshBooking,
            getComponent: (_location, callback) => {
                require.ensure(
                    [],
                    (require) => {
                        callback(null, require<any>("./fresh/pages/Booking/BookingPage").BookingPage);
                    },
                    "freshBooking",
                );
            },
        },
        {
            path: routes.freshBookings,
            getComponent: (_location, callback) => {
                require.ensure(
                    [],
                    (require) => {
                        callback(null, require<any>("./fresh/pages/Bookings/BookingsPage").BookingsPage);
                    },
                    "freshBookings",
                );
            },
        },
        {
            path: routes.freshTrips,
            getComponent: (_location, callback) => {
                require.ensure(
                    [],
                    (require) => {
                        callback(null, require<any>("./fresh/pages/Trips/TripsPage").TripsPage);
                    },
                    "freshTrips",
                );
            },
        },

        {
            path: routes.freshTripCreation,
            getComponent: (_location, callback) => {
                require.ensure(
                    [],
                    (require) => {
                        callback(null, require<any>("./fresh/pages/TripCreation/TripCreationPage").TripCreationPage);
                    },
                    "freshTripCreation",
                );
            },
        },
        {
            path: routes.freshPayoutRequests,
            getComponent: (_location, callback) => {
                require.ensure([], (require) => {
                    callback(null, require<any>("./fresh/pages/PayoutRequests/PayoutRequestsPage").PayoutRequestsPage);
                });
            },
        },
        {
            path: routes.freshPayoutFulfillments,
            getComponent: (_location, callback) => {
                require.ensure([], (require) => {
                    callback(
                        null,
                        require<any>("./fresh/pages/PayoutFulfillments/PayoutFulfillmentsPage").PayoutFulfillmentsPage,
                    );
                });
            },
        },
        {
            path: routes.freshPayouts,
            getComponent: (_location, callback) => {
                require.ensure([], (require) => {
                    callback(null, require<any>("./fresh/pages/Payouts/PayoutsPage").PayoutsPage);
                });
            },
        },

        {
            path: routes.freshTripShapes,
            getComponent: (_location, callback) => {
                require.ensure([], (require) => {
                    callback(null, require<any>("./fresh/pages/TripShapes/TripShapesPage").TripShapesPage);
                });
            },
        },

        {
            path: routes.freshTripShapeCreation,
            getComponent: (_location, callback) => {
                require.ensure([], (require) => {
                    callback(
                        null,
                        require<any>("./fresh/pages/TripShapeCreation/TripShapeCreationPage").TripShapeCreationPage,
                    );
                });
            },
        },

        {
            path: routes.freshTripShape,
            getComponent: (_location, callback) => {
                require.ensure([], (require) => {
                    callback(null, require<any>("./fresh/pages/TripShape/TripShapePage").TripShapePage);
                });
            },
        },
    ],
};
