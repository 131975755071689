import { Expose, Type } from "class-transformer";
import { IsArray, IsDefined, IsInt, IsNumber, IsOptional, IsString, IsUUID, Length } from "class-validator";
import { v4 as uuid } from "uuid";

import { Currency } from "../domain/Currency";
import { SimpleCustomer } from "../domain/SimpleCustomer";
import { SimpleOrderForReferralCode } from "../domain/SimpleOrderForReferralCode";
import { CDBaseEntity, CDColumn, CDEntity, CDObjectIdColumn } from "../orm";

@CDEntity("referralCodes")
export class ReferralCode extends CDBaseEntity {
    @CDObjectIdColumn()
    @IsUUID("4")
    @Expose()
    public _id: string = uuid();

    @CDColumn()
    @IsUUID("4")
    @IsDefined()
    @Expose()
    public referrerUserId: string;

    @CDColumn()
    @Length(8, 8)
    @IsString()
    @IsDefined()
    @Expose()
    public code: string;

    @CDColumn()
    @IsInt()
    @IsDefined()
    @Expose()
    public referrerReward: number = 20; // per one order

    @CDColumn()
    @IsInt()
    @IsDefined()
    @Expose()
    public referrerRewardCurrency: Currency = Currency.Dollar;

    @CDColumn()
    @IsNumber()
    @IsDefined()
    @Expose()
    public refereeCashbackPercentageCoefficient: number = 0.1;

    @CDColumn()
    @IsArray()
    @IsDefined()
    @Expose()
    @Type(() => String)
    public orderIds: string[] = [];

    @IsOptional()
    @Expose()
    public customer?: SimpleCustomer;

    @IsOptional()
    @Expose()
    public referrer?: SimpleCustomer;

    @IsOptional()
    @Expose()
    public order?: SimpleOrderForReferralCode;
}
