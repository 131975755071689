import autobind from "autobind-decorator";
import { classToPlain, plainToClass } from "class-transformer";
import { validate, ValidationError } from "class-validator";
import { action, observable } from "mobx";

import { DocumentSubject } from "@legacy/domain/DocumentSubject";
import { SimpleCountry } from "@legacy/domain/SimpleCountry";
import { DocumentType } from "@legacy/models/DocumentType";
import { RetrieveDocumentTypesOptions } from "@legacy/options/RetrieveDocumentTypesOptions";
import { transformValidationErrorsToArrayString } from "@daytrip/legacy-transformers";
import { PageStore } from "../../stores/PageStore";

import { DocumentTypesPageRouter } from "./DocumentTypesPageRouter";

@autobind
export class DocumentTypesPageStore extends PageStore<DocumentTypesPageRouter, null> {
    public documentSubjects: Array<DocumentSubject> = [
        DocumentSubject.Driver,
        DocumentSubject.Vehicle,
        DocumentSubject.CompanyDriver,
        DocumentSubject.DriversCompany,
        DocumentSubject.Soletrader,
        DocumentSubject.NaturalPerson,
        DocumentSubject.LegalPerson,
    ];

    @observable
    public documentTypes: Array<{ documentSubject: DocumentSubject; documentTypes?: Array<DocumentType> }> = [];

    @observable
    public countries: Array<SimpleCountry>;

    @observable
    public editedDocumentType?: DocumentType;

    @observable
    public editedDocumentTypeValidations: Array<ValidationError> = new Array();

    @observable
    public isDocumentTypeCreating: boolean;

    @observable
    public documentTypeCreatedAt: Date;

    @observable
    public newDocumentType: DocumentType;

    @observable
    public newDocumentTypeValidations: Array<ValidationError> = new Array();

    public get selectedCountry(): SimpleCountry | undefined {
        return this.countries.find((c) => c._id == this.pageRouter.countryId);
    }

    @action
    public async validateNewDocumentType(): Promise<Array<ValidationError>> {
        this.newDocumentTypeValidations = await validate(plainToClass(DocumentType, this.newDocumentType), {
            skipMissingProperties: true,
        });
        return this.newDocumentTypeValidations;
    }

    @action
    public async retrieveDocumentTypes() {
        const options = {} as RetrieveDocumentTypesOptions;
        options.countryIds = [this.pageRouter.countryId];

        this.documentTypes = [];

        const allDocumentTypes = await this.rpcClient.driver.retrieveDocumentTypes(
            Object.assign(options, { subjects: this.documentSubjects }),
        );

        for (const documentSubject of this.documentSubjects) {
            const subjectDocumentType = {
                documentSubject,
                documentTypes: allDocumentTypes.filter((dt) => dt.subject == documentSubject),
            };
            this.documentTypes.push(subjectDocumentType);
        }
    }

    @action
    public resetNewDocumentType() {
        this.newDocumentType = classToPlain(new DocumentType()) as DocumentType;
        this.newDocumentType.isRequired = false;
        this.newDocumentType.countryId = this.pageRouter.countryId;
    }

    @action
    public async onFetchData() {
        await this.fetchContent();
    }

    @action
    public async fetchContent() {
        this.resetNewDocumentType();

        this.countries = await this.rpcClient.content.retrieveCountries({
            excludeIsoCodes: ["eu"],
        });

        if (this.pageRouter.countryId == undefined) {
            const firstCountryId = this.countries.sort((a, b) => a.englishName.localeCompare(b.englishName))[0]._id;
            this.pageRouter.gotoCountryDocuments(firstCountryId);
        }

        await this.retrieveDocumentTypes();
    }

    @action
    public setEditedDocumentType(documentType: DocumentType) {
        this.editedDocumentType = documentType;
    }

    @action
    public releaseEditedDocumentType() {
        this.editedDocumentType = undefined;
    }

    @action
    public async saveEditedDocumentType() {
        if (this.editedDocumentType != undefined) {
            this.editedDocumentTypeValidations = await validate(plainToClass(DocumentType, this.editedDocumentType), {
                skipMissingProperties: true,
            });

            if (this.editedDocumentTypeValidations.length > 0) {
                const validationMessages = transformValidationErrorsToArrayString(
                    this.editedDocumentTypeValidations,
                ).join("\n");
                alert(`Oh, something is wrong. :(\n\nValidation errors:\n${validationMessages}`);
                return;
            }

            await this.rpcClient.driver.updateDocumentType(this.editedDocumentType);

            this.releaseEditedDocumentType();
            await this.retrieveDocumentTypes();
        }
    }

    @action
    public onChangeEditedDocumentTypeEnglishName(value: string) {
        (this.editedDocumentType as DocumentType).englishName = value;
    }

    @action
    public onChangeEditedDocumentTypeLocalName(value: string) {
        (this.editedDocumentType as DocumentType).localName = value;
    }

    @action
    public onChangeEditedDocumentTypeSubject(value: DocumentSubject) {
        (this.editedDocumentType as DocumentType).subject = value;
    }

    @action
    public onChangeEditedDocumentTypeManagementNote(value: string) {
        (this.editedDocumentType as DocumentType).managementNote = value;
    }

    @action
    public onChangeEditedDocumentTypeDescription(value: string) {
        (this.editedDocumentType as DocumentType).description = value;
    }

    @action
    public onChangeEditedDocumentIsRequired() {
        (this.editedDocumentType as DocumentType).isRequired = !(this.editedDocumentType as DocumentType).isRequired;
    }

    @action
    public onChangeEditedThumbnailImagePath(value: string) {
        (this.editedDocumentType as DocumentType).thumbnailImagePath = value;
    }

    @action
    public onChangeEditedDocumentTemplateUrl(value: string) {
        (this.editedDocumentType as DocumentType).templateUrl = value;
    }

    @action
    public onChangeEditedNumberOfRequiredFiles(value?: number) {
        (this.editedDocumentType as DocumentType).numberOfRequiredFiles = value;
    }

    @action
    public onChangeNewDocumentTypeEnglishName(value: string) {
        this.newDocumentType.englishName = value;
    }

    @action
    public onChangeNewDocumentTypeLocalName(value: string) {
        this.newDocumentType.localName = value;
    }

    @action
    public onChangeNewDocumentTypeSubject(value: DocumentSubject) {
        this.newDocumentType.subject = value;
    }

    @action
    public onChangeNewDocumentTypeManagementNote(value: string) {
        this.newDocumentType.managementNote = value;
    }

    @action
    public onChangeNewDocumentTypeDescription(value: string) {
        this.newDocumentType.description = value;
    }

    @action
    public onChangeNewDocumentIsRequired() {
        this.newDocumentType.isRequired = !this.newDocumentType.isRequired;
    }

    @action
    public onChangeNewDocumentThumbnailImagePath(value: string) {
        this.newDocumentType.thumbnailImagePath = value;
    }

    @action
    public onChangeNewDocumentTemplateUrl(value: string) {
        this.newDocumentType.templateUrl = value;
    }

    @action
    public onChangeNewDocumentNumberOfRequiredFiles(value?: number) {
        if (value == undefined) {
            this.newDocumentType.numberOfRequiredFiles = undefined;
        } else {
            this.newDocumentType.numberOfRequiredFiles = value;
        }
    }

    @action
    public onChangeExternalDocumentType(value?: number) {
        if (value == undefined) {
            this.newDocumentType.externalDocumentType = undefined;
        } else {
            this.newDocumentType.externalDocumentType = value;
        }
    }

    @action
    public async createDocumentType() {
        const subjectDocumentTypes = this.documentTypes.find(
            (dt) => dt.documentSubject == this.newDocumentType.subject,
        );
        if (!subjectDocumentTypes?.documentTypes) {
            return;
        }

        this.newDocumentType.order = subjectDocumentTypes.documentTypes.length;

        if (this.newDocumentType.numberOfRequiredFiles == 0) {
            this.newDocumentType.numberOfRequiredFiles = undefined;
        }

        const validationErrors = await this.validateNewDocumentType();

        if (validationErrors.length > 0) {
            alert(`can't save: validation error`);
            return;
        }

        await this.rpcClient.driver.createDocumentType(this.newDocumentType);
        subjectDocumentTypes.documentTypes.push(this.newDocumentType);
        this.resetNewDocumentType();
        await this.retrieveDocumentTypes();
    }

    @action
    public async removeDocumentType(documentTypeId: string) {
        await this.rpcClient.driver.deleteDocumentType(documentTypeId);

        this.documentTypes = this.documentTypes.map((dt) => {
            dt.documentTypes = undefined;
            return dt;
        });

        await this.retrieveDocumentTypes();
    }

    @action
    public async documentTypeSetOrder(subject: DocumentSubject, documentTypeId: string, order: number) {
        const subjectDocumentTypes = this.documentTypes.find((dt) => dt.documentSubject === subject);
        if (!subjectDocumentTypes?.documentTypes) {
            return;
        }

        let newDocumentTypes: Array<DocumentType> = [];
        newDocumentTypes = (subjectDocumentTypes.documentTypes as Array<DocumentType>).sort((a, b) =>
            a.order > b.order ? 1 : -1,
        );

        const updatingDocumentType = newDocumentTypes.find((dt) => dt._id === documentTypeId) as DocumentType;
        const updatingDocumentTypeOrder: number = updatingDocumentType.order;

        subjectDocumentTypes.documentTypes = await Promise.all(
            newDocumentTypes.map(async (documentType: DocumentType) => {
                if (documentType.order === order) {
                    // order increased - moving down
                    if (updatingDocumentTypeOrder < order) {
                        documentType.order -= 1;
                    } else if (updatingDocumentTypeOrder > order) {
                        // order decreased - moving up
                        documentType.order += 1;
                    }
                }

                if (documentType._id == documentTypeId) {
                    documentType.order = order;
                }

                await this.rpcClient.driver.updateDocumentType(documentType);

                return documentType;
            }),
        );
    }

    @observable
    public lightboxImagePath?: string;

    @observable
    public lightboxTitle?: string;

    @action
    public onOpenLightbox(imagePath: string, title: string): void {
        this.lightboxImagePath = imagePath;
        this.lightboxTitle = title;
    }

    @action
    public onCloseLightbox(): void {
        this.lightboxImagePath = undefined;
        this.lightboxTitle = undefined;
    }

    public isDataFetched(): this is DocumentTypesPageStore & DocumentTypesPageStoreDataFetched {
        for (const documentSubject of this.documentSubjects) {
            const subjectDocumentTypes = this.documentTypes.find((dt) => dt.documentSubject == documentSubject);
            if (!subjectDocumentTypes?.documentTypes) {
                return false;
            }
        }

        return this.countries != undefined;
    }
}

export interface DocumentTypesPageStoreDataFetched {
    documentTypes: Array<{ documentSubject: DocumentSubject; documentTypes?: Array<DocumentType> }>;
    selectedCountry: SimpleCountry;
}
