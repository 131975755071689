import { AvailabilityUpdateSchema } from "@legacy/domain/AvailabilityUpdateSchema";
import { Unavailability } from "@legacy/models/Unavailability";
import autobind from "autobind-decorator";
import { addDays } from "date-fns/esm";
import { injectable } from "inversify";
import { action, computed, observable, toJS } from "mobx";

import { PageStore } from "../../stores/PageStore";

import { compareLocalDateToUtcDate, dateToUTCDate } from "./Calendar/calendar.utils";
import { buildMonthDates, MonthDate } from "./Calendar/utils/buildMonthDates";
import { DriverAvailabilityPageRouter } from "./DriverAvailabilityPageRouter";
import { DriverAvailabilityService } from "./services/DriverAvailabilityService";
import { AnonymizedOrderWithLocations } from "./types";
import { AvailabilityDayStatus } from "@daytrip/legacy-enums";

@autobind
@injectable()
export class DriverAvailabilityPageStore extends PageStore<DriverAvailabilityPageRouter, {}> {
    private readonly driverAvailabilityService = new DriverAvailabilityService();

    private getNextStatus(status: AvailabilityDayStatus, times: number = 1): AvailabilityDayStatus {
        let result = status;
        for (let i = 0; i < times; i++) {
            result =
                result === AvailabilityDayStatus.Active ? AvailabilityDayStatus.Inactive : AvailabilityDayStatus.Active;
        }

        return result;
    }

    @observable
    public unavailability: Unavailability[] = [];

    @observable
    public assignations?: AnonymizedOrderWithLocations[][];

    @observable
    public canUpdateAvailability: boolean;

    @observable
    // eslint-disable-next-line no-undef
    public updateAvailabilityTimeout: NodeJS.Timer;

    @observable
    public pendingAvailabilitiesUpdates: AvailabilityUpdateSchema[] = observable.array([]);

    @observable
    public updateDelay: number = 1500;

    @observable
    public updateStatus: boolean = false;

    @computed
    public get isCalendarDisabled(): boolean {
        return !this.canUpdateAvailability;
    }

    @computed
    public get isUpdateInProgress(): boolean {
        return this.updateStatus || this.pendingAvailabilitiesUpdates.length > 0;
    }

    @computed
    public get availabilityOwnerId(): string {
        return this.pageRouter.userId ?? this.authenticationStore.userJWT.userId;
    }

    @observable
    public isLoading: boolean = false;

    @computed
    public get monthDays(): MonthDate[] {
        if (!this.assignations) {
            return [];
        }
        const currentMonth = this.pageRouter.month;
        const currentYear = this.pageRouter.year;
        const tomorrow = addDays(new Date(), 1);
        const days = buildMonthDates(
            currentMonth,
            currentYear,
            this.unavailability,
            this.pendingAvailabilitiesUpdates,
            this.assignations || [],
            tomorrow,
            this.isCalendarDisabled,
        );

        return days;
    }

    @computed
    public get showDisabledAlert(): boolean {
        // doesn't it make more sense to check for isDriver || isDriversCompany etc.
        return (
            !this.canUpdateAvailability &&
            !this.authenticationStore.hasInternalManagementPermission &&
            !this.authenticationStore.isDriverSupport &&
            !this.authenticationStore.isCustomerSupport && 
            !this.authenticationStore.isRegionalManager
        );
    }

    @action
    public setDate(year: number, month: number) {
        this.pageRouter.setYear(year);
        this.pageRouter.setMonth(month);
    }

    @action
    public nextMonth = () => {
        const { month, year } = this.pageRouter;
        if (month === 11) {
            this.pageRouter.setMonth(0);
            this.pageRouter.setYear(year + 1);
        } else {
            this.pageRouter.setMonth(month + 1);
        }
    };

    @action
    public prevMonth = () => {
        const { month, year } = this.pageRouter;
        if (month === 0) {
            this.pageRouter.setMonth(11);
            this.pageRouter.setYear(year - 1);
        } else {
            this.pageRouter.setMonth(month - 1);
        }
    };

    @action
    public async onFetchData() {
        await this.fetchContent();
    }

    @action
    public async fetchContent() {
        try {
            this.isLoading = true;

            // clear data in order to clear state
            this.assignations = undefined;
            this.unavailability = [];

            // fetch data
            await Promise.all([
                this.fetchAssignations(),
                this.fetchUnavailability(),
                this.fetchCanUpdateAvailability(),
            ]);
        } finally {
            this.isLoading = false;
        }
    }

    public isDataFetched() {
        return this.assignations !== undefined && !this.isLoading;
    }

    @action
    public async updateMultipleAvailabilities() {
        this.updateStatus = true;
        await this.driverAvailabilityService.updateUserMultipleAvailabilities(
            this.pendingAvailabilitiesUpdates,
            this.availabilityOwnerId as string,
        );
        await this.fetchUnavailability();
        this.pendingAvailabilitiesUpdates = [];
        this.updateStatus = false;
    }

    @action
    public async toggleAvailability(date: Date) {
        // If month update request is running already
        if (this.updateStatus) {
            return;
        }
        const unavailability = this.unavailability.find((a) => compareLocalDateToUtcDate(date, new Date(a.date)));
        clearTimeout(this.updateAvailabilityTimeout);

        const updateSchemaIndex = this.pendingAvailabilitiesUpdates.findIndex((aus: AvailabilityUpdateSchema) =>
            compareLocalDateToUtcDate(date, new Date(aus.date)),
        );

        if (updateSchemaIndex !== -1) {
            const tempAvailabilitiesQueue = toJS(this.pendingAvailabilitiesUpdates);
            tempAvailabilitiesQueue[updateSchemaIndex].dayStatus = this.getNextStatus(
                tempAvailabilitiesQueue[updateSchemaIndex].dayStatus,
            );

            this.pendingAvailabilitiesUpdates = tempAvailabilitiesQueue;
        } else {
            const newUpdateSchema = new AvailabilityUpdateSchema();

            newUpdateSchema.date = unavailability?.date || dateToUTCDate(date);

            newUpdateSchema.dayStatus = unavailability ? AvailabilityDayStatus.Active : AvailabilityDayStatus.Inactive;

            this.pendingAvailabilitiesUpdates.push(newUpdateSchema);
        }

        this.updateAvailabilityTimeout = setTimeout(this.updateMultipleAvailabilities, this.updateDelay);
    }

    @action
    public async setMonthAvailability(year: number, month: number) {
        this.updateStatus = true;
        await this.driverAvailabilityService.removeUnavailabilitiesForWholeMonth(
            this.availabilityOwnerId as string,
            year,
            month,
        );
        await this.fetchContent();
        this.updateStatus = false;
    }

    @action
    public async setMonthUnavailability(year: number, month: number) {
        this.updateStatus = true;
        await this.driverAvailabilityService.setUnavailabilitiesForWholeMonth(
            this.availabilityOwnerId as string,
            year,
            month,
        );
        await this.fetchContent();
        this.updateStatus = false;
    }

    @action
    private async fetchUnavailability() {
        this.unavailability = await this.driverAvailabilityService.retrieveUnavailabilities(
            this.availabilityOwnerId,
            this.pageRouter.year,
            this.pageRouter.month,
        );
    }

    @action
    private async fetchAssignations() {
        this.assignations = await this.driverAvailabilityService.getAssignationsForMonthOfDate(
            this.availabilityOwnerId,
            this.pageRouter.year,
            this.pageRouter.month,
        );
    }

    @action
    private async fetchCanUpdateAvailability() {
        this.canUpdateAvailability = this.authenticationStore.hasPermissions("Unavailability:Update")
            ? true
            : await this.driverAvailabilityService.retrieveCanUpdateAvailability();
    }
}
