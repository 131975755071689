/* eslint-disable */
// @ts-nocheck
import * as Types from "./graphql-types";

import { DocumentNode } from "graphql";
import gql from "graphql-tag";
export type GetAllCountriesForSelectQueryVariables = Types.Exact<{ [key: string]: never }>;

export type GetAllCountriesForSelectQueryOperationResult = {
    countriesByEnglishName: Array<{ _id: string; isoCode: string; englishName: string }>;
};

export type GetDynamicFormConfigQueryVariables = Types.Exact<{
    input: Types.DynamicFormConfigFindOneInput;
}>;

export type GetDynamicFormConfigQueryOperationResult = {
    getDynamicFormConfig: {
        _id: string;
        countryId?: string | null;
        entityType: Types.DynamicFormConfigEntityType;
        entityTypeLegalName: string;
        formType: Types.DynamicFormConfigFormType;
        fields: Array<{
            englishName: string;
            fieldName: string;
            isRequired: boolean;
            localName: string;
            order: number;
            placeholder: string;
        }>;
    };
};

export type UpsertDynamicFormConfigMutationVariables = Types.Exact<{
    input: Types.CreateDynamicFormConfigInput;
}>;

export type UpsertDynamicFormConfigMutationOperationResult = {
    upsertDynamicFormConfig: {
        _id: string;
        countryId?: string | null;
        entityType: Types.DynamicFormConfigEntityType;
        entityTypeLegalName: string;
        formType: Types.DynamicFormConfigFormType;
        fields: Array<{
            englishName: string;
            fieldName: string;
            isRequired: boolean;
            localName: string;
            order: number;
            placeholder: string;
        }>;
    };
};

export type DriverCountryRuleForEditFragment = {
    driverCountryId: string;
    originCountryId: string;
    destinationCountryId: string;
    canAcceptAssignationOffers: boolean;
    canBeAutomaticallyAssigned: boolean;
};

export type DriverCountryRuleForTableFragment = {
    driverCountryId: string;
    originCountryId: string;
    destinationCountryId: string;
    canAcceptAssignationOffers: boolean;
    canBeAutomaticallyAssigned: boolean;
    driverCountry: { isoCode: string; englishName: string };
    originCountry: { isoCode: string; englishName: string };
    destinationCountry: { isoCode: string; englishName: string };
};

export type CreateDriverCountryRuleMutationVariables = Types.Exact<{
    input: Types.DriverCountryRuleInput;
}>;

export type CreateDriverCountryRuleMutationOperationResult = { createDriverCountryRule: boolean };

export type DeleteDriverCountryRuleMutationVariables = Types.Exact<{
    driverCountryId: Types.Scalars["String"]["input"];
    originCountryId: Types.Scalars["String"]["input"];
    destinationCountryId: Types.Scalars["String"]["input"];
}>;

export type DeleteDriverCountryRuleMutationOperationResult = { deleteDriverCountryRule: boolean };

export type UpdateDriverCountryRuleMutationVariables = Types.Exact<{
    driverCountryId: Types.Scalars["String"]["input"];
    originCountryId: Types.Scalars["String"]["input"];
    destinationCountryId: Types.Scalars["String"]["input"];
    update: Types.DriverCountryRuleUpdateInput;
}>;

export type UpdateDriverCountryRuleMutationOperationResult = { updateDriverCountryRule: boolean };

export type GetDriverCountryRuleForEditQueryVariables = Types.Exact<{
    driverCountryId: Types.Scalars["String"]["input"];
    originCountryId: Types.Scalars["String"]["input"];
    destinationCountryId: Types.Scalars["String"]["input"];
}>;

export type GetDriverCountryRuleForEditQueryOperationResult = {
    driverCountryRule: {
        driverCountryId: string;
        originCountryId: string;
        destinationCountryId: string;
        canAcceptAssignationOffers: boolean;
        canBeAutomaticallyAssigned: boolean;
    };
};

export type ListDriverCountryRulesForTableQueryVariables = Types.Exact<{
    driverCountryIds?: Types.InputMaybe<Array<Types.Scalars["String"]["input"]> | Types.Scalars["String"]["input"]>;
    originCountryIds?: Types.InputMaybe<Array<Types.Scalars["String"]["input"]> | Types.Scalars["String"]["input"]>;
    destinationCountryIds?: Types.InputMaybe<
        Array<Types.Scalars["String"]["input"]> | Types.Scalars["String"]["input"]
    >;
    offset?: Types.InputMaybe<Types.Scalars["Int"]["input"]>;
    limit?: Types.InputMaybe<Types.Scalars["Int"]["input"]>;
}>;

export type ListDriverCountryRulesForTableQueryOperationResult = {
    driverCountryRules: {
        totalCount: number;
        data: Array<{
            driverCountryId: string;
            originCountryId: string;
            destinationCountryId: string;
            canAcceptAssignationOffers: boolean;
            canBeAutomaticallyAssigned: boolean;
            driverCountry: { isoCode: string; englishName: string };
            originCountry: { isoCode: string; englishName: string };
            destinationCountry: { isoCode: string; englishName: string };
        }>;
    };
};

export type AddCrossBorderTripWhitelistEntryMutationVariables = Types.Exact<{
    entry: Types.CrossBorderTripWhitelistEntryInput;
}>;

export type AddCrossBorderTripWhitelistEntryMutationOperationResult = { addCrossBorderTripWhitelistEntry: boolean };

export type CountriesByEnglishNameQueryVariables = Types.Exact<{ [key: string]: never }>;

export type CountriesByEnglishNameQueryOperationResult = { countriesByEnglishName: Array<{ isoCode: string }> };

export type CrossBorderWhitelistQueryVariables = Types.Exact<{ [key: string]: never }>;

export type CrossBorderWhitelistQueryOperationResult = {
    flexibleOffersRuntimeConfig: {
        crossBorderTripWhitelist: Array<{
            countryIsoA: string;
            countryIsoB: string;
            additionalFee?: number | null;
            additionalPrice?: number | null;
            preferredPricing?: string | null;
        }>;
    };
};

export type RemoveCrossBorderTripWhitelistEntryMutationVariables = Types.Exact<{
    entry: Types.CrossBorderTripWhitelistEntryInput;
}>;

export type RemoveCrossBorderTripWhitelistEntryMutationOperationResult = {
    removeCrossBorderTripWhitelistEntry: boolean;
};

export type UpdateCrossBorderTripWhitelistEntryMutationVariables = Types.Exact<{
    entry: Types.CrossBorderTripWhitelistEntryInput;
}>;

export type UpdateCrossBorderTripWhitelistEntryMutationOperationResult = {
    updateCrossBorderTripWhitelistEntry: boolean;
};

export type FlexibleOffersGlobalScalarConfigQueryVariables = Types.Exact<{ [key: string]: never }>;

export type FlexibleOffersGlobalScalarConfigQueryOperationResult = {
    flexibleOffersGlobalScalarConfig: Array<{ key: Types.FlexibleOffersRuntimeConfigScalarKeys; value: number }>;
};

export type UpdateFoScalarConfigParameterMutationVariables = Types.Exact<{
    key: Types.FlexibleOffersRuntimeConfigScalarKeys;
    value: Types.Scalars["Int"]["input"];
}>;

export type UpdateFoScalarConfigParameterMutationOperationResult = { configEntryPutScalarParameters: boolean };

export type UpdateRideMutationVariables = Types.Exact<{
    ride: Types.RideInput;
}>;

export type UpdateRideMutationOperationResult = { updateRide: string };

export interface PossibleTypesResultData {
    possibleTypes: {
        [key: string]: string[];
    };
}
const result: PossibleTypesResultData = {
    possibleTypes: {
        FlexibleOffersRuntimeConfigValues: [
            "FlexibleOffersRuntimeConfigValueEnabledRegions",
            "FlexibleOffersRuntimeConfigValueNumber",
        ],
    },
};
export default result;

export const DriverCountryRuleForEditFragmentDoc = gql`
    fragment DriverCountryRuleForEdit on DriverCountryRule {
        driverCountryId
        originCountryId
        destinationCountryId
        canAcceptAssignationOffers
        canBeAutomaticallyAssigned
    }
`;
export const DriverCountryRuleForTableFragmentDoc = gql`
    fragment DriverCountryRuleForTable on DriverCountryRule {
        driverCountryId
        driverCountry {
            isoCode
            englishName
        }
        originCountryId
        originCountry {
            isoCode
            englishName
        }
        destinationCountryId
        destinationCountry {
            isoCode
            englishName
        }
        canAcceptAssignationOffers
        canBeAutomaticallyAssigned
    }
`;
export const GetAllCountriesForSelectDocument = gql`
    query GetAllCountriesForSelect {
        countriesByEnglishName {
            _id
            isoCode
            englishName
        }
    }
`;
export const GetDynamicFormConfigDocument = gql`
    query GetDynamicFormConfig($input: DynamicFormConfigFindOneInput!) {
        getDynamicFormConfig(dynamicFormConfigFindOneInput: $input) {
            _id
            countryId
            entityType
            entityTypeLegalName
            fields {
                englishName
                fieldName
                isRequired
                localName
                order
                placeholder
            }
            formType
        }
    }
`;
export const UpsertDynamicFormConfigDocument = gql`
    mutation UpsertDynamicFormConfig($input: CreateDynamicFormConfigInput!) {
        upsertDynamicFormConfig(upsertDynamicFormConfigInput: $input) {
            _id
            countryId
            entityType
            entityTypeLegalName
            formType
            fields {
                englishName
                fieldName
                isRequired
                localName
                order
                placeholder
            }
        }
    }
`;
export const CreateDriverCountryRuleDocument = gql`
    mutation CreateDriverCountryRule($input: DriverCountryRuleInput!) {
        createDriverCountryRule(input: $input)
    }
`;
export const DeleteDriverCountryRuleDocument = gql`
    mutation DeleteDriverCountryRule(
        $driverCountryId: String!
        $originCountryId: String!
        $destinationCountryId: String!
    ) {
        deleteDriverCountryRule(
            driverCountryId: $driverCountryId
            originCountryId: $originCountryId
            destinationCountryId: $destinationCountryId
        )
    }
`;
export const UpdateDriverCountryRuleDocument = gql`
    mutation UpdateDriverCountryRule(
        $driverCountryId: String!
        $originCountryId: String!
        $destinationCountryId: String!
        $update: DriverCountryRuleUpdateInput!
    ) {
        updateDriverCountryRule(
            driverCountryId: $driverCountryId
            originCountryId: $originCountryId
            destinationCountryId: $destinationCountryId
            update: $update
        )
    }
`;
export const GetDriverCountryRuleForEditDocument = gql`
    query GetDriverCountryRuleForEdit(
        $driverCountryId: String!
        $originCountryId: String!
        $destinationCountryId: String!
    ) {
        driverCountryRule(
            driverCountryId: $driverCountryId
            originCountryId: $originCountryId
            destinationCountryId: $destinationCountryId
        ) {
            ...DriverCountryRuleForEdit
        }
    }
    ${DriverCountryRuleForEditFragmentDoc}
`;
export const ListDriverCountryRulesForTableDocument = gql`
    query ListDriverCountryRulesForTable(
        $driverCountryIds: [String!]
        $originCountryIds: [String!]
        $destinationCountryIds: [String!]
        $offset: Int
        $limit: Int
    ) {
        driverCountryRules(
            driverCountryIds: $driverCountryIds
            originCountryIds: $originCountryIds
            destinationCountryIds: $destinationCountryIds
            offset: $offset
            limit: $limit
        ) {
            data {
                ...DriverCountryRuleForTable
            }
            totalCount
        }
    }
    ${DriverCountryRuleForTableFragmentDoc}
`;
export const AddCrossBorderTripWhitelistEntryDocument = gql`
    mutation addCrossBorderTripWhitelistEntry($entry: CrossBorderTripWhitelistEntryInput!) {
        addCrossBorderTripWhitelistEntry(crossBorderTripWhitelistEntry: $entry)
    }
`;
export const CountriesByEnglishNameDocument = gql`
    query countriesByEnglishName {
        countriesByEnglishName {
            isoCode
        }
    }
`;
export const CrossBorderWhitelistDocument = gql`
    query crossBorderWhitelist {
        flexibleOffersRuntimeConfig {
            crossBorderTripWhitelist {
                countryIsoA
                countryIsoB
                additionalFee
                additionalPrice
                preferredPricing
            }
        }
    }
`;
export const RemoveCrossBorderTripWhitelistEntryDocument = gql`
    mutation removeCrossBorderTripWhitelistEntry($entry: CrossBorderTripWhitelistEntryInput!) {
        removeCrossBorderTripWhitelistEntry(crossBorderTripWhitelistEntry: $entry)
    }
`;
export const UpdateCrossBorderTripWhitelistEntryDocument = gql`
    mutation updateCrossBorderTripWhitelistEntry($entry: CrossBorderTripWhitelistEntryInput!) {
        updateCrossBorderTripWhitelistEntry(crossBorderTripWhitelistEntry: $entry)
    }
`;
export const FlexibleOffersGlobalScalarConfigDocument = gql`
    query flexibleOffersGlobalScalarConfig {
        flexibleOffersGlobalScalarConfig {
            key
            value
        }
    }
`;
export const UpdateFoScalarConfigParameterDocument = gql`
    mutation updateFOScalarConfigParameter($key: FlexibleOffersRuntimeConfigScalarKeys!, $value: Int!) {
        configEntryPutScalarParameters(RuntimeConfigEntry: { key: $key, value: $value, ttl: 60 })
    }
`;
export const UpdateRideDocument = gql`
    mutation UpdateRide($ride: RideInput!) {
        updateRide(ride: $ride)
    }
`;
export type Requester<C = {}> = <R, V>(doc: DocumentNode, vars?: V, options?: C) => Promise<R> | AsyncIterable<R>;
export function getSdk<C>(requester: Requester<C>) {
    return {
        GetAllCountriesForSelect(
            variables?: Types.GetAllCountriesForSelectQueryVariables,
            options?: C,
        ): Promise<Types.GetAllCountriesForSelectQueryOperationResult> {
            return requester<
                Types.GetAllCountriesForSelectQueryOperationResult,
                Types.GetAllCountriesForSelectQueryVariables
            >(
                GetAllCountriesForSelectDocument,
                variables,
                options,
            ) as Promise<Types.GetAllCountriesForSelectQueryOperationResult>;
        },
        GetDynamicFormConfig(
            variables: Types.GetDynamicFormConfigQueryVariables,
            options?: C,
        ): Promise<Types.GetDynamicFormConfigQueryOperationResult> {
            return requester<Types.GetDynamicFormConfigQueryOperationResult, Types.GetDynamicFormConfigQueryVariables>(
                GetDynamicFormConfigDocument,
                variables,
                options,
            ) as Promise<Types.GetDynamicFormConfigQueryOperationResult>;
        },
        UpsertDynamicFormConfig(
            variables: Types.UpsertDynamicFormConfigMutationVariables,
            options?: C,
        ): Promise<Types.UpsertDynamicFormConfigMutationOperationResult> {
            return requester<
                Types.UpsertDynamicFormConfigMutationOperationResult,
                Types.UpsertDynamicFormConfigMutationVariables
            >(
                UpsertDynamicFormConfigDocument,
                variables,
                options,
            ) as Promise<Types.UpsertDynamicFormConfigMutationOperationResult>;
        },
        CreateDriverCountryRule(
            variables: Types.CreateDriverCountryRuleMutationVariables,
            options?: C,
        ): Promise<Types.CreateDriverCountryRuleMutationOperationResult> {
            return requester<
                Types.CreateDriverCountryRuleMutationOperationResult,
                Types.CreateDriverCountryRuleMutationVariables
            >(
                CreateDriverCountryRuleDocument,
                variables,
                options,
            ) as Promise<Types.CreateDriverCountryRuleMutationOperationResult>;
        },
        DeleteDriverCountryRule(
            variables: Types.DeleteDriverCountryRuleMutationVariables,
            options?: C,
        ): Promise<Types.DeleteDriverCountryRuleMutationOperationResult> {
            return requester<
                Types.DeleteDriverCountryRuleMutationOperationResult,
                Types.DeleteDriverCountryRuleMutationVariables
            >(
                DeleteDriverCountryRuleDocument,
                variables,
                options,
            ) as Promise<Types.DeleteDriverCountryRuleMutationOperationResult>;
        },
        UpdateDriverCountryRule(
            variables: Types.UpdateDriverCountryRuleMutationVariables,
            options?: C,
        ): Promise<Types.UpdateDriverCountryRuleMutationOperationResult> {
            return requester<
                Types.UpdateDriverCountryRuleMutationOperationResult,
                Types.UpdateDriverCountryRuleMutationVariables
            >(
                UpdateDriverCountryRuleDocument,
                variables,
                options,
            ) as Promise<Types.UpdateDriverCountryRuleMutationOperationResult>;
        },
        GetDriverCountryRuleForEdit(
            variables: Types.GetDriverCountryRuleForEditQueryVariables,
            options?: C,
        ): Promise<Types.GetDriverCountryRuleForEditQueryOperationResult> {
            return requester<
                Types.GetDriverCountryRuleForEditQueryOperationResult,
                Types.GetDriverCountryRuleForEditQueryVariables
            >(
                GetDriverCountryRuleForEditDocument,
                variables,
                options,
            ) as Promise<Types.GetDriverCountryRuleForEditQueryOperationResult>;
        },
        ListDriverCountryRulesForTable(
            variables?: Types.ListDriverCountryRulesForTableQueryVariables,
            options?: C,
        ): Promise<Types.ListDriverCountryRulesForTableQueryOperationResult> {
            return requester<
                Types.ListDriverCountryRulesForTableQueryOperationResult,
                Types.ListDriverCountryRulesForTableQueryVariables
            >(
                ListDriverCountryRulesForTableDocument,
                variables,
                options,
            ) as Promise<Types.ListDriverCountryRulesForTableQueryOperationResult>;
        },
        addCrossBorderTripWhitelistEntry(
            variables: Types.AddCrossBorderTripWhitelistEntryMutationVariables,
            options?: C,
        ): Promise<Types.AddCrossBorderTripWhitelistEntryMutationOperationResult> {
            return requester<
                Types.AddCrossBorderTripWhitelistEntryMutationOperationResult,
                Types.AddCrossBorderTripWhitelistEntryMutationVariables
            >(
                AddCrossBorderTripWhitelistEntryDocument,
                variables,
                options,
            ) as Promise<Types.AddCrossBorderTripWhitelistEntryMutationOperationResult>;
        },
        countriesByEnglishName(
            variables?: Types.CountriesByEnglishNameQueryVariables,
            options?: C,
        ): Promise<Types.CountriesByEnglishNameQueryOperationResult> {
            return requester<
                Types.CountriesByEnglishNameQueryOperationResult,
                Types.CountriesByEnglishNameQueryVariables
            >(
                CountriesByEnglishNameDocument,
                variables,
                options,
            ) as Promise<Types.CountriesByEnglishNameQueryOperationResult>;
        },
        crossBorderWhitelist(
            variables?: Types.CrossBorderWhitelistQueryVariables,
            options?: C,
        ): Promise<Types.CrossBorderWhitelistQueryOperationResult> {
            return requester<Types.CrossBorderWhitelistQueryOperationResult, Types.CrossBorderWhitelistQueryVariables>(
                CrossBorderWhitelistDocument,
                variables,
                options,
            ) as Promise<Types.CrossBorderWhitelistQueryOperationResult>;
        },
        removeCrossBorderTripWhitelistEntry(
            variables: Types.RemoveCrossBorderTripWhitelistEntryMutationVariables,
            options?: C,
        ): Promise<Types.RemoveCrossBorderTripWhitelistEntryMutationOperationResult> {
            return requester<
                Types.RemoveCrossBorderTripWhitelistEntryMutationOperationResult,
                Types.RemoveCrossBorderTripWhitelistEntryMutationVariables
            >(
                RemoveCrossBorderTripWhitelistEntryDocument,
                variables,
                options,
            ) as Promise<Types.RemoveCrossBorderTripWhitelistEntryMutationOperationResult>;
        },
        updateCrossBorderTripWhitelistEntry(
            variables: Types.UpdateCrossBorderTripWhitelistEntryMutationVariables,
            options?: C,
        ): Promise<Types.UpdateCrossBorderTripWhitelistEntryMutationOperationResult> {
            return requester<
                Types.UpdateCrossBorderTripWhitelistEntryMutationOperationResult,
                Types.UpdateCrossBorderTripWhitelistEntryMutationVariables
            >(
                UpdateCrossBorderTripWhitelistEntryDocument,
                variables,
                options,
            ) as Promise<Types.UpdateCrossBorderTripWhitelistEntryMutationOperationResult>;
        },
        flexibleOffersGlobalScalarConfig(
            variables?: Types.FlexibleOffersGlobalScalarConfigQueryVariables,
            options?: C,
        ): Promise<Types.FlexibleOffersGlobalScalarConfigQueryOperationResult> {
            return requester<
                Types.FlexibleOffersGlobalScalarConfigQueryOperationResult,
                Types.FlexibleOffersGlobalScalarConfigQueryVariables
            >(
                FlexibleOffersGlobalScalarConfigDocument,
                variables,
                options,
            ) as Promise<Types.FlexibleOffersGlobalScalarConfigQueryOperationResult>;
        },
        updateFOScalarConfigParameter(
            variables: Types.UpdateFoScalarConfigParameterMutationVariables,
            options?: C,
        ): Promise<Types.UpdateFoScalarConfigParameterMutationOperationResult> {
            return requester<
                Types.UpdateFoScalarConfigParameterMutationOperationResult,
                Types.UpdateFoScalarConfigParameterMutationVariables
            >(
                UpdateFoScalarConfigParameterDocument,
                variables,
                options,
            ) as Promise<Types.UpdateFoScalarConfigParameterMutationOperationResult>;
        },
        UpdateRide(
            variables: Types.UpdateRideMutationVariables,
            options?: C,
        ): Promise<Types.UpdateRideMutationOperationResult> {
            return requester<Types.UpdateRideMutationOperationResult, Types.UpdateRideMutationVariables>(
                UpdateRideDocument,
                variables,
                options,
            ) as Promise<Types.UpdateRideMutationOperationResult>;
        },
    };
}
export type Sdk = ReturnType<typeof getSdk>;
