// eslint-disable-next-line max-classes-per-file

import type { Location } from "@daytrip/legacy-models";
import { Order } from "@daytrip/legacy-models";
import { OrderStatus } from "@legacy/domain/OrderStatus";
import type { SimpleUser } from "@legacy/domain/SimpleUser";
import { Payment } from "@legacy/models/Payment";
import { PaymentRequest } from "@legacy/models/PaymentRequest";
import type { RetrieveOrdersOptions } from "@legacy/options/RetrieveOrdersOptions";
import { isUndefinedOrNull } from "@legacy/utils";
import autobind from "autobind-decorator";
import { plainToClass } from "class-transformer";
import { action, observable } from "mobx";

import { PaymentOperator } from "../../../operators/PaymentOperator";
import { PaymentRequestOperator } from "../../../operators/PaymentRequestOperator";
import { PageStore } from "../../../stores/PageStore";

import type { OrdersDepositCheckPageRouter } from "./OrdersDepositCheckPageRouter";

export interface OrdersDepositCheckPageStoreDataFetched {
    ordersCount: number;
}

export class OrderWithPayments extends Order {
    public paymentOperators?: Array<PaymentOperator> = [];

    public paymentRequestOperators?: Array<PaymentRequestOperator> = [];

    public user?: SimpleUser;
}

@autobind
export class OrdersDepositCheckPageStore extends PageStore<OrdersDepositCheckPageRouter, null> {
    @observable
    public ordersWithPayments?: Array<OrderWithPayments>;

    @observable
    public ordersCount?: number = 0;

    @action
    public clearSearch() {
        this.pageRouter.clearQuery();
        this.fetchContent();
    }

    @action
    public async onFetchData() {
        await this.fetchContent();
    }

    @action
    public async fetchContent() {
        this.ordersWithPayments = undefined;
        const data = await this.ordersWithPaymentsFetch();
        this.ordersWithPayments = data.ordersWithPayments;
    }

    @action
    public async ordersWithPaymentsFetch(): Promise<{ ordersWithPayments: Array<OrderWithPayments> }> {
        try {
            const options: RetrieveOrdersOptions = {
                skip: this.pageRouter.skip,
                limit: this.pageRouter.limit,
                sortBy: this.pageRouter.sortBy as keyof Order,
                sortDirection: this.pageRouter.sortDirection,
                departureAtFrom: this.pageRouter.startDate
                    ? new Date(parseInt(this.pageRouter.startDate, 10))
                    : undefined,
                departureAtTo: this.pageRouter.endDate ? new Date(parseInt(this.pageRouter.endDate, 10)) : undefined,
                statusIn: this.pageRouter.statusIn ?? [
                    OrderStatus.Pending,
                    OrderStatus.Accepted,
                    OrderStatus.Confirmed,
                ],
                isDepositRequired: true,
            };

            this.ordersCount = await this.rpcClient.order.retrieveOrdersCount(options);
            const orders = plainToClass(Order, await this.rpcClient.order.retrieveOrders(options));

            const users = await this.rpcClient.user.retrieveSimpleUsers({
                userIds: orders.map((order) => order.userId),
            });
            const locations = await this.fetchLocations(orders);
            const { payments, paymentRequests } = await this.fetchPayments(orders.map((order) => order._id));

            const ordersWithPayments = orders.map((order: OrderWithPayments) => {
                order.originLocation = locations.find(
                    (location) => location._id === order.originLocationId,
                ) as Location;
                order.destinationLocation = locations.find(
                    (location) => location._id === order.destinationLocationId,
                ) as Location;

                const orderPayments = payments.filter((payment) => payment.orderId === order._id);
                const orderPaymentRequests = paymentRequests.filter((paymentReq) => paymentReq.orderId === order._id);
                order.paymentOperators = orderPayments.map(
                    (paymentModel) =>
                        new PaymentOperator({
                            modelConstructor: Payment,
                            model: paymentModel,
                            modules: null,
                            data: {
                                isChargebackEnabled: false,
                            },
                        }),
                );
                order.paymentRequestOperators = orderPaymentRequests.map(
                    (paymentRequestModel) =>
                        new PaymentRequestOperator({
                            modelConstructor: PaymentRequest,
                            model: paymentRequestModel,
                            modules: null,
                            data: null,
                        }),
                );

                order.user = users.find((user) => user._id === order.userId);

                return order;
            });

            return { ordersWithPayments };
        } catch (e: any) {
            return Promise.reject(e);
        }
    }

    public isDataFetched(): this is OrdersDepositCheckPageStore & OrdersDepositCheckPageStoreDataFetched {
        return !isUndefinedOrNull(this.ordersWithPayments);
    }

    private async fetchLocations(orders: Order[]): Promise<Location[]> {
        const locationIds: string[] = [];
        orders.forEach((order: Order) => {
            locationIds.push(order.originLocationId, order.destinationLocationId);
        });
        const locations: Location[] = await this.rpcClient.content.retrieveLocations({
            ids: [...new Set(locationIds)],
        });

        return locations;
    }

    private async fetchPayments(
        orderIds: string[],
    ): Promise<{ payments: Payment[]; paymentRequests: PaymentRequest[] }> {
        let { payments, paymentRequests } = await this.rpcClient.payment.retrievePaymentDataForOrderPage({ orderIds });
        paymentRequests = plainToClass(PaymentRequest, paymentRequests);
        payments = plainToClass(Payment, payments);

        return { payments, paymentRequests };
    }
}
