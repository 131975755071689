import { Ad } from "../models/Ad";

import { AdTemplateHelper } from "./AdTemplateHelper";

export class AdTemplate {
    public constructor(
        headlinePart1: string,
        headlinePart2: string,
        headlinePart3: string | undefined,
        description: string,
        description2: string | undefined,
        additionalTemplates: Array<AdTemplate> | undefined = undefined,
    ) {
        this.headlinePart1 = headlinePart1;
        this.headlinePart2 = headlinePart2;
        this.description = description;
        this.headlinePart3 = headlinePart3;
        this.description2 = description2;
        this.additionalTemplates = additionalTemplates;
    }

    public headlinePart1: string;

    public headlinePart2: string;

    public headlinePart3: string | undefined;

    public description: string;

    public description2: string | undefined;

    public additionalTemplates: Array<AdTemplate> | undefined;

    public transformToAd = (
        originLocationName: string,
        destinationLocationName: string,
        price: string,
        duration: string,
    ) => {
        const ad = new Ad();

        ad.headlinePart1 = AdTemplateHelper.fillTemplateByData(
            this.headlinePart1,
            originLocationName,
            destinationLocationName,
            price,
            duration,
            30,
        );

        ad.headlinePart2 = AdTemplateHelper.fillTemplateByData(
            this.headlinePart2,
            originLocationName,
            destinationLocationName,
            price,
            duration,
            30,
        );

        if (this.headlinePart3) {
            ad.headlinePart3 = AdTemplateHelper.fillTemplateByData(
                this.headlinePart3,
                originLocationName,
                destinationLocationName,
                price,
                duration,
                30,
            );
        }

        ad.description = AdTemplateHelper.fillTemplateByData(
            this.description,
            originLocationName,
            destinationLocationName,
            price,
            duration,
            90,
        );

        if (this.description2) {
            ad.description2 = AdTemplateHelper.fillTemplateByData(
                this.description2,
                originLocationName,
                destinationLocationName,
                price,
                duration,
                90,
            );
        }

        return ad;
    };

    public static transformToAd = (
        template: AdTemplate,
        originLocationName: string,
        destinationLocationName: string,
        price: string,
        duration: string,
    ) => template.transformToAd(originLocationName, destinationLocationName, price, duration);
}
