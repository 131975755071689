import { Expose } from "class-transformer";
import { IsDefined, IsString, IsUUID } from "class-validator";
import { v4 as uuid } from "uuid";

import { CDBaseEntity, CDColumn, CDEntity, CDObjectIdColumn } from "../orm";

@CDEntity("vehicleMakes")
export class VehicleMake extends CDBaseEntity {
    @CDObjectIdColumn()
    @IsUUID("4")
    @Expose()
    public _id: string = uuid();

    @CDColumn()
    @IsString()
    @IsDefined()
    @Expose()
    public name: string;
}
