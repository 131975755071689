import { getApolloSdk } from "../../apolloClient/apolloClient";
import type { DynamicFormConfigEntityType, DynamicFormConfigFieldInput } from "../../types/generated/graphql-types";
import { DynamicFormConfigFormType } from "../../types/generated/graphql-types";

export async function getCooperationAgreementDynamicFormConfigApi(
    countryId: string | null,
    entityType: DynamicFormConfigEntityType,
) {
    const { getDynamicFormConfig } = await getApolloSdk().GetDynamicFormConfig({
        input: {
            countryId,
            entityType,
            formType: DynamicFormConfigFormType.CooperationAgreement,
        },
    });

    return getDynamicFormConfig;
}

export async function upsertCooperationAgreementDynamicFormConfigApi(
    countryId: string | null,
    entityType: DynamicFormConfigEntityType,
    entityTypeLegalName: string,
    fields: DynamicFormConfigFieldInput[],
) {
    const payload = {
        countryId,
        entityType,
        entityTypeLegalName,
        fields,
        formType: DynamicFormConfigFormType.CooperationAgreement,
    };
    const { upsertDynamicFormConfig } = await getApolloSdk().UpsertDynamicFormConfig({ input: payload });
    return upsertDynamicFormConfig;
}

export async function getCooperationAgreementForCountryOrDefaultApi(
    countryId: string | null,
    entityType: DynamicFormConfigEntityType,
) {
    return await getCooperationAgreementDynamicFormConfigApi(countryId, entityType);
}
