import { Expose } from "class-transformer";
import { IsBoolean, IsDefined, IsEnum, IsInt, IsString, IsUrl, IsUUID, MaxLength, MinLength } from "class-validator";
import { v4 as uuid } from "uuid";

import { DocumentSubject } from "../domain/DocumentSubject";
import { DynamicDocumentType } from "../domain/DocumentTypes";
import { ExternalDocumentType } from "../domain/ExternalDocumentType";
import { CDBaseEntity, CDColumn, CDEntity, CDObjectIdColumn } from "../orm";

@CDEntity("documentTypes")
export class DocumentType extends CDBaseEntity {
    @CDObjectIdColumn()
    @IsUUID("4")
    @Expose()
    public _id: string = uuid();

    @CDColumn()
    @IsUUID("4")
    @IsDefined()
    @Expose()
    public countryId: string;

    @CDColumn()
    @MinLength(1)
    @IsString()
    @IsDefined()
    @Expose()
    public englishName: string = "";

    @CDColumn()
    @IsString()
    @IsDefined()
    @Expose()
    public localName: string = "";

    @CDColumn()
    @IsBoolean()
    @IsDefined()
    @Expose()
    public isRequired: boolean = false;

    @CDColumn()
    @IsInt()
    @Expose()
    public numberOfRequiredFiles?: number;

    @CDColumn()
    @IsInt()
    @IsDefined()
    @Expose()
    public subject: DocumentSubject = DocumentSubject.Driver;

    @CDColumn()
    @MaxLength(100)
    @IsString()
    @Expose()
    public managementNote?: string;

    @CDColumn()
    @MaxLength(300)
    @IsString()
    @Expose()
    public description?: string;

    @CDColumn()
    @IsString()
    @Expose()
    public thumbnailImagePath?: string;

    @CDColumn()
    @IsString()
    @IsUrl()
    @Expose()
    public templateUrl?: string;

    @CDColumn()
    @IsInt()
    @IsDefined()
    @Expose()
    public order: number;

    @CDColumn()
    @Expose()
    public postgreId: number | null;

    @CDColumn()
    @Expose()
    public externalDocumentType?: ExternalDocumentType;

    @CDColumn()
    @IsEnum(DynamicDocumentType)
    @Expose()
    public dynamicDocumentType?: DynamicDocumentType;

    @CDColumn()
    @IsBoolean()
    @Expose()
    public isDeleted?: boolean;
}
