import { isUndefinedOrNull } from "@daytrip/utils";
import { Exclude, Expose, Transform } from "class-transformer";
import { IsArray, IsBoolean, IsNumber, IsString } from "class-validator";

@Exclude()
export class RetrieveSimpleUsersOptions {
    @IsBoolean()
    @Expose()
    public isDriver?: boolean;

    @IsBoolean()
    @Expose()
    public isDriversCompany?: boolean;

    @IsBoolean()
    @Expose()
    public isCompanyDriver?: boolean;

    @IsArray()
    @Expose()
    @Transform((value) =>
        isUndefinedOrNull(value) ? undefined : (value as Array<typeof value>).map((item) => String(item)),
    )
    public userIds?: Array<string>;

    @IsArray()
    @Expose()
    @Transform((value) =>
        isUndefinedOrNull(value) ? undefined : (value as Array<typeof value>).map((item) => String(item)),
    )
    public countryIds?: Array<string>;

    @IsString()
    @Expose()
    public searchString?: string;

    @IsBoolean()
    @Expose()
    public notDriverOrCustomer?: boolean;

    @IsNumber()
    @Expose()
    public limit?: number;
}
