import { Exclude, Expose } from "class-transformer";
import { IsString, IsDefined } from "class-validator";

@Exclude()
export class PaymentMangopayInformation {
    @IsString()
    @IsDefined()
    @Expose()
    public payinId: string;

    @IsString()
    @IsDefined()
    @Expose()
    public walletId: string;

    @IsString()
    @IsDefined()
    @Expose()
    public authorId: string;
}
