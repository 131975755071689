import type { TaggableEntityType } from "@daytrip/legacy-enums";
import type { Location, Tag } from "@daytrip/legacy-models";
import type { Country } from "@legacy/models/Country";
import type { Region } from "@legacy/models/Region";
import type { RetrieveLocationsOptions } from "@legacy/options/RetrieveLocationsOptions";
import { isUndefinedOrNull } from "@legacy/utils";
import { action, observable } from "mobx";
import type { Option } from "react-select-legacy";

import { PageStore } from "../../stores/PageStore";

import type { LocationsPageRouter } from "./LocationsPageRouter";

export class LocationsPageStore extends PageStore<LocationsPageRouter, null> {
    @observable
    public locations?: Array<Location>;

    @observable
    public locationsCount: number = 0;

    @observable
    public countries?: Array<Country>;

    @observable
    public regions?: Array<Region>;

    @action
    public async onFetchData() {
        await this.fetchContent();
    }

    @action
    public async fetchContent() {
        this.locations = undefined;

        const options = {
            skip: this.pageRouter.skip,
            limit: this.pageRouter.limit,
            sortBy: this.pageRouter.sortBy,
            sortDirection: this.pageRouter.sortDirection,
            search: this.pageRouter.search,
        } as RetrieveLocationsOptions;

        options.countryIds = this.pageRouter.countryIds;
        options.isLocation = this.pageRouter.isLocation;
        options.isDestination = this.pageRouter.isDestination;
        options.isReady = this.pageRouter.isReady;
        options.isLive = this.pageRouter.isLive;
        options.hasPhoto = this.pageRouter.hasPhoto;
        options.tagIds = this.pageRouter.tagIds;

        let countries = [];
        [this.locations, this.locationsCount, countries] = await Promise.all([
            this.rpcClient.content.retrieveLocations(options),
            this.rpcClient.content.retrieveLocationsCount(options),
            this.rpcClient.content.retrieveCountries({}),
        ]);

        this.countries = countries.sort((a, b) => a.englishName.localeCompare(b.englishName));

        this.regions = await this.rpcClient.content.retrieveRegions({
            ids: this.locations.map((l) => l.regionId).filter((r) => !isUndefinedOrNull(r)) as Array<string>,
        });
    }

    public isDataFetched(): this is LocationsPageStore & LocationsPageStoreDataFetched {
        return (
            !isUndefinedOrNull(this.locations) && !isUndefinedOrNull(this.countries) && !isUndefinedOrNull(this.regions)
        );
    }

    @action
    public async fetchTags(
        searchString: string,
        applicableFor: TaggableEntityType[],
    ): Promise<{ options: Array<Option> }> {
        const tags: Tag[] = await this.rpcClient.content.searchTags({
            applicableFor,
            searchString,
        });

        const options = tags.map((tag) => ({
            value: tag._id,
            label: tag.title,
        }));

        return { options };
    }
}

export interface LocationsPageStoreDataFetched {
    locations: Array<Location>;
    countries: Array<Country>;
    regions: Array<Region>;
}
