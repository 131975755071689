import type { AllRoles } from "@daytrip/legacy-enums";
import { Exclude, Expose, Transform } from "class-transformer";
import { IsArray, IsBoolean, IsString } from "class-validator";

import type { User } from "../models/User";

import { SortingPaginationOptions } from "./SortingPaginationOptions";

@Exclude()
export class RetrieveUsersOptions extends SortingPaginationOptions<User> {
    @IsArray()
    @Expose()
    @Transform((value) => (value == undefined ? undefined : (value as Array<typeof value>).map((item) => String(item))))
    public userIds?: Array<string>;

    @IsArray()
    @Expose()
    @Transform((value) => (value == undefined ? undefined : (value as Array<typeof value>).map((item) => String(item))))
    public driversCompanyIds?: Array<string>;

    @IsArray()
    @Expose()
    @Transform((value) => (value == undefined ? undefined : (value as Array<typeof value>).map((item) => String(item))))
    public countryIds?: Array<string>;

    @IsBoolean()
    @Expose()
    public isDriver?: boolean;

    @IsBoolean()
    @Expose()
    public isDriversCompany?: boolean;

    @IsBoolean()
    @Expose()
    public isCompanyDriver?: boolean;

    @IsBoolean()
    @Expose()
    public hasMangopayInformation?: boolean;

    @IsBoolean()
    @Expose()
    public hasMangopayInformationApproved?: boolean;

    @IsBoolean()
    @Expose()
    public hasMangopayInformationDisabled?: boolean;

    @IsBoolean()
    @Expose()
    public isDeleted?: boolean = false;

    @IsString()
    @Expose()
    public searchString?: string;

    @IsString()
    @Expose()
    public search?: string;

    @IsString()
    @Expose()
    public name?: string;

    @IsString()
    @Expose()
    public email?: string;

    @IsBoolean()
    @Expose()
    public notDriverOrCustomer?: boolean;

    @IsBoolean()
    @Expose()
    public isCustomer?: boolean;

    @IsArray()
    @Expose()
    @Transform((value) => (value == undefined ? undefined : (value as Array<typeof value>).map((item) => String(item))))
    public managerIds?: Array<string>;

    @IsBoolean()
    @Expose()
    public isActive?: boolean;

    @IsArray()
    @Expose()
    public automaticEmailMailgunIds?: Array<string>;

    @IsArray()
    @Expose()
    public roles?: Array<AllRoles>;
}
