import type { Location } from "@daytrip/legacy-models";
import type { Ride } from "@daytrip/legacy-models";
import { AssignationForUpcomingTrips } from "@legacy/domain/AssignationForUpcomingTrips";
import { AssignationStatus } from "@legacy/domain/AssignationStatus";
import { DriverStatus } from "@legacy/domain/DriverStatus";
import type { SimpleDriver } from "@legacy/domain/SimpleDriver";
import autobind from "autobind-decorator";
import { action, observable } from "mobx";

import { AssignationWithOrderOperator } from "../../operators/AssignationWithOrderOperator";
import { PageStore } from "../../stores/PageStore";
import type { GroupedAssignationOperatorsByRideId } from "../../types/GroupedAssignationOperatorsByRideId";
import { determineAssignationOptions } from "../../utils/assignations/determineOptions";
import { sortAndGroupAssignationWithOrderOperators } from "../../utils/sortAndGroupAssignationWithOrderOperators";

import type { DriversCompanyUpcomingTripsPageRouter } from "./DriversCompanyUpcomingTripsRouter";

@autobind
export class DriversCompanyUpcomingTripsPageStore extends PageStore<DriversCompanyUpcomingTripsPageRouter, {}> {
    @observable
    public isLoading: boolean = true;

    @observable
    public error: string | undefined = undefined;

    @observable
    public pendingAssignationOperators: (AssignationWithOrderOperator | GroupedAssignationOperatorsByRideId)[] = [];

    @observable
    public pendingIsLoadingAssignationOperators: boolean = false;

    @observable
    public pendingErrorAssignationOperators: string | undefined = undefined;

    // @observable
    // public haveMorePending: boolean = true;

    @observable
    public acceptedAssignationOperators: (AssignationWithOrderOperator | GroupedAssignationOperatorsByRideId)[] = [];

    @observable
    public acceptedIsLoadingAssignationOperators: boolean = false;

    @observable
    public acceptedErrorAssignationOperators: string | undefined = undefined;

    // @observable
    // public haveMoreAccepted: boolean = true;

    @observable
    public locations: Array<Location> = [];

    @observable
    public rides: Array<Ride> = [];

    @observable
    public companyDrivers: Array<SimpleDriver> = [];

    @action
    private setIsLoading(status: AssignationStatus, isLoading: boolean) {
        if (status === AssignationStatus.Pending) {
            this.pendingIsLoadingAssignationOperators = isLoading;
        } else if (status === AssignationStatus.Accepted) {
            this.acceptedIsLoadingAssignationOperators = isLoading;
        }
    }

    @action
    private setError(status: AssignationStatus, error: string | undefined) {
        if (status === AssignationStatus.Pending) {
            this.pendingErrorAssignationOperators = error;
        } else if (status === AssignationStatus.Accepted) {
            this.acceptedErrorAssignationOperators = error;
        }
    }

    @action
    private async fetchAssignations(status: AssignationStatus): Promise<AssignationForUpcomingTrips[]> {
        return this.rpcClient.assignation.retrieveCompanyDriversAssignationsWithCustomerNameAndBookingReference(
            determineAssignationOptions(status, {
                limit: 1000, // @remark This is temporary solution to fix issue with loading trips for Domenico De Cristofaro who has more than 1000 trips [sc-35997]
            }),
        );
    }

    @action
    private async fetchContent(assignationsForUpcomingTrips: AssignationForUpcomingTrips[]) {
        const assignations = assignationsForUpcomingTrips.map(
            (assignationForUpcomingTrip) => assignationForUpcomingTrip.assignation,
        );
        const alreadyFetchedlocationIds = this.locations.map((location) => location._id);
        const alreadyFetchedRideIds = this.rides.map((ride) => ride._id);

        const locationIds: string[] = [];
        const rideIds: string[] = [];

        assignations.forEach((assignation) => {
            if (assignation.rideId && !alreadyFetchedRideIds.includes(assignation.rideId)) {
                rideIds.push(assignation.rideId);
            }
            if (!alreadyFetchedlocationIds.includes(assignation.order.originLocationId)) {
                locationIds.push(assignation.order.originLocationId);
            }
            if (!alreadyFetchedlocationIds.includes(assignation.order.destinationLocationId)) {
                locationIds.push(assignation.order.destinationLocationId);
            }
            locationIds.push(
                ...assignation.order.contentLocations
                    .map((cl) => cl.locationId)
                    .filter((location) => !alreadyFetchedlocationIds.includes(location)),
            );
        });

        const holdRidesMap = rideIds.map((rideId) => this.rpcClient.ride.retrieveRide(rideId));
        const holdRides = await Promise.all(holdRidesMap);
        this.rides.push(...holdRides);
        this.rides.forEach((ride) => {
            if (!alreadyFetchedlocationIds.includes(ride.originLocationId)) {
                locationIds.push(ride.originLocationId);
            }
            if (!alreadyFetchedlocationIds.includes(ride.destinationLocationId)) {
                locationIds.push(ride.destinationLocationId);
            }
        });

        const holdLocations = await this.rpcClient.content.retrieveLocations({ ids: locationIds });
        this.locations.push(...holdLocations);
    }

    @action
    private findLocation(locationId: string) {
        return this.locations.find((location) => location._id === locationId);
    }

    @action
    private convertAssignation(assignationForUpcomingTrips: AssignationForUpcomingTrips): AssignationWithOrderOperator {
        const { assignation, suspiciousCCActivity, ccAlias, ccHolderName, customerName, bookingReference } =
            assignationForUpcomingTrips;
        const { order } = assignation;
        const ride = this.rides.find((r) => r._id === assignation.rideId);
        const origin = ride ? this.findLocation(ride.originLocationId)! : this.findLocation(order.originLocationId)!;
        const destination = ride
            ? this.findLocation(ride.destinationLocationId)!
            : this.findLocation(order.destinationLocationId)!;
        const contentLocationIds = order.contentLocations.map((cl) => cl.locationId);
        const contentLocations = this.locations.filter((location) => contentLocationIds.includes(location._id));

        // if the user is not a company, select him as a driver
        const driverId = !this.authenticationStore.isDriversCompany ? assignation.driverId : undefined;

        const assingationWithOrderOperator = new AssignationWithOrderOperator(
            assignation,
            origin,
            destination,
            contentLocations,
            driverId,
            suspiciousCCActivity,
            ccAlias,
            ccHolderName,
            ride,
        );
        assingationWithOrderOperator.leadPassengerName = customerName;
        const currentUserId = this.authenticationStore.userJWT?.userId;
        if (currentUserId) {
            assingationWithOrderOperator.userId = currentUserId;
        }
        assingationWithOrderOperator.bookingReference = bookingReference;
        return assingationWithOrderOperator;
    }

    @action
    private async fetchAndConvertAssignations(
        status: AssignationStatus,
    ): Promise<AssignationWithOrderOperator[] | undefined> {
        this.setIsLoading(status, true);
        this.setError(status, undefined);
        try {
            const assingations = await this.fetchAssignations(status);
            await this.fetchContent(assingations);
            return assingations.map((assignation) => this.convertAssignation(assignation));
        } catch (e) {
            console.error(JSON.stringify(e, undefined, 2), e);
            this.setError(status, "Something went wrong!");
        } finally {
            this.setIsLoading(status, false);
        }
        return undefined;
    }

    @action
    public async loadMorePendingAssignations() {
        const assignations = await this.fetchAndConvertAssignations(AssignationStatus.Pending);
        if (assignations) {
            this.pendingAssignationOperators.push(...assignations);
            // this.haveMorePending = assignations?.length === LIMIT;
        }
    }

    @action
    public async loadMoreAcceptedAssignations() {
        const assignations = await this.fetchAndConvertAssignations(AssignationStatus.Accepted);
        if (assignations) {
            this.acceptedAssignationOperators.push(...assignations);
            // this.haveMoreAccepted = assignations?.length === LIMIT;
        }
    }

    @action
    public async reloadAssignations(fetchContent?: boolean) {
        this.isLoading = true;
        this.error = undefined;
        try {
            const [pendingAssignationOperators, acceptedAssignationOperators] = await Promise.all([
                this.fetchAssignations(AssignationStatus.Pending),
                this.fetchAssignations(AssignationStatus.Accepted),
            ]);

            if (fetchContent) {
                const [companyDrivers] = await Promise.all([
                    this.rpcClient.driver.retrieveSimpleDrivers({
                        driversCompanyUserId: this.authenticationStore.userJWT?.userId,
                        statusIn: [DriverStatus.Active],
                    }),
                    this.fetchContent([...pendingAssignationOperators, ...acceptedAssignationOperators]),
                ]);
                this.companyDrivers = companyDrivers;
            }

            const groupedPendingAssignationOperators = sortAndGroupAssignationWithOrderOperators(
                pendingAssignationOperators.map((assignation) => this.convertAssignation(assignation)),
            );
            this.pendingAssignationOperators = groupedPendingAssignationOperators;

            const groupedAcceptedAssignationOperators = sortAndGroupAssignationWithOrderOperators(
                acceptedAssignationOperators.map((assignation) => this.convertAssignation(assignation)),
            );
            this.acceptedAssignationOperators = groupedAcceptedAssignationOperators;
            // this.haveMorePending = pendingAssignationOperators.length === LIMIT;
            // this.haveMoreAccepted = acceptedAssignationOperators.length === LIMIT;
        } catch (e) {
            console.error(JSON.stringify(e, undefined, 2), e);
            this.error = "Something went wrong";
        } finally {
            this.isLoading = false;
        }
    }

    @action
    public async onFetchData() {
        await this.reloadAssignations(true);
    }

    public isDataFetched(): this is DriversCompanyUpcomingTripsPageStore {
        return !this.isLoading;
    }
}
