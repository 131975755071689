import type { UserJWT } from "@daytrip/legacy-models";
import { Logger } from "@daytrip/logger";
import { injectable, inject } from "inversify";
import type { Cursor as MongodbCursor } from "mongodb";
import type { Cursor as TypeormCursor } from "typeorm";

import { Context } from "./Context";
import { isUndefinedOrNull } from "./utils";

type Cursor<T> = TypeormCursor<T> | MongodbCursor;

@injectable()
export class Service<TUser, TUserJWT = UserJWT> {
    @inject(Context)
    public context: Context<TUser, TUserJWT>;

    @inject(Logger)
    public logger: Logger;

    protected paginate<T>(cursor: Cursor<T>, skip?: number, limit?: number): Cursor<T> {
        if (!isUndefinedOrNull(skip)) {
            cursor = cursor.skip(skip - 1);
        }
        if (!isUndefinedOrNull(limit) && !isUndefinedOrNull(skip)) {
            cursor = cursor.limit(limit - skip + 1);
        }
        return cursor;
    }

    protected sort<T>(cursor: Cursor<T>, property?: string, direction?: number): Cursor<T> {
        if (property && !isUndefinedOrNull(direction)) {
            cursor = cursor.sort({ [property]: direction });
        }
        return cursor;
    }

    protected sortAndPaginate<T>(
        cursor: Cursor<T>,
        property?: string,
        direction?: number,
        skip?: number,
        limit?: number,
    ): Cursor<T> {
        return this.paginate(this.sort(cursor, property, direction), skip, limit);
    }
}
