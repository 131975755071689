import type { OrderContentLocation } from "@daytrip/legacy-models";
import type { OrderCustomLocation } from "@daytrip/legacy-models";

import type { SimpleLocation } from "../domain/SimpleLocation";

export interface OrderPartialData {
    contentLocations: Array<OrderContentLocation>;
    customLocations: Array<OrderCustomLocation>;
}

export function describeLocationsDifference(
    originalData: OrderPartialData,
    newData: OrderPartialData,
    simpleLocations: Array<SimpleLocation>,
): string {
    const addedContentLocations = newData.contentLocations.filter(
        (cl) => originalData.contentLocations.find((ocl) => ocl.locationId === cl.locationId) === undefined,
    );
    const removedContentLocations = originalData.contentLocations.filter(
        (ocl) => newData.contentLocations.find((cl) => cl.locationId === ocl.locationId) === undefined,
    );

    const addedCustomLocations = newData.customLocations.filter(
        (cl) => originalData.customLocations.find((ocl) => ocl.title === cl.title) === undefined,
    );
    const removedCustomLocations = originalData.customLocations.filter(
        (ocl) => newData.customLocations.find((cl) => cl.title === ocl.title) === undefined,
    );

    let message = "";

    if (
        addedContentLocations.length +
            removedContentLocations.length +
            addedCustomLocations.length +
            removedCustomLocations.length >
        0
    ) {
        message += "Order locations have changed:\n";

        if (addedContentLocations.length > 0) {
            message += `Content locations added: ${addedContentLocations
                .map((cl) => {
                    const location = simpleLocations?.find((sl) => sl.locationId === cl.locationId);
                    return location ? location.name : cl.locationId;
                })
                .join(", ")}\n`;
        }

        if (removedContentLocations.length > 0) {
            message += `Content locations removed: ${removedContentLocations
                .map((cl) => {
                    const location = simpleLocations?.find((sl) => sl.locationId === cl.locationId);
                    return location ? location.name : cl.locationId;
                })
                .join(", ")}\n`;
        }

        if (addedCustomLocations.length > 0) {
            message += `Custom locations added: ${addedCustomLocations.map((cl) => cl.title).join(", ")}\n`;
        }

        if (removedCustomLocations.length > 0) {
            message += `Custom locations removed: ${removedCustomLocations.map((cl) => cl.title).join(", ")}\n`;
        }
    }

    return message;
}
