import { AvailabilityDayStatus } from "@daytrip/legacy-enums";
import { Exclude, Expose } from "class-transformer";
import { IsDefined } from "class-validator";

@Exclude()
export class AvailabilityUpdateSchema {
    @IsDefined()
    @Expose()
    public date: Date;

    @Expose()
    public dayStatus: AvailabilityDayStatus;
}
