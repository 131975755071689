import { Exclude, Expose } from "class-transformer";
import { IsDefined, IsNumber, IsString, IsUUID, Length } from "class-validator";

@Exclude()
export class SimpleOrderContentLocation {
    @IsUUID("4")
    @IsDefined()
    @Expose()
    public locationId: string;

    @Length(1, 64)
    @IsString()
    @IsDefined()
    @Expose()
    public name: string = "";

    @IsNumber()
    @IsDefined()
    @Expose()
    public order: number;

    @IsNumber()
    @IsDefined()
    @Expose()
    public duration: number;

    @IsUUID("4")
    @IsDefined()
    @Expose()
    public countryId: string;

    @IsString()
    @Expose()
    public imageUrl?: string;
}
