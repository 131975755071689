import { IsOptional } from "class-validator";

import { SimpleCustomer } from "./SimpleCustomer";
import { SimpleOrderForReferralCode } from "./SimpleOrderForReferralCode";

export class ExtendedReferralCode {
    public _id: string;

    @IsOptional()
    public customer?: SimpleCustomer;

    public referrer: SimpleCustomer;

    public code: string;

    @IsOptional()
    public order?: SimpleOrderForReferralCode;
}
