import type { SimpleDriver } from "@legacy/domain/SimpleDriver";
import type { SimpleUser } from "@legacy/domain/SimpleUser";
import type { Country } from "@legacy/models/Country";
import type { User } from "@legacy/models/User";
import type { RetrieveDriversOptions } from "@legacy/options/RetrieveDriversOptions";
import autobind from "autobind-decorator";
import { validate } from "class-validator";
import isEmpty from "lodash/isEmpty";
import { action, observable } from "mobx";

import { PageStore } from "../../stores/PageStore";

import type { DriversMapPageRouter } from "./DriversMapPageRouter";

@autobind
export class DriversMapPageStore extends PageStore<DriversMapPageRouter, null> {
    @observable
    public drivers?: Array<SimpleDriver>;

    @observable
    public countries?: Array<Country>;

    @observable
    public managers?: Array<SimpleUser>;

    @action
    public async onFetchData() {
        await Promise.all([this.fetchContent(), this.fetchDrivers()]);
    }

    @action
    public async fetchContent() {
        [this.countries, this.managers] = await Promise.all([
            this.rpcClient.content.retrieveCountries({}),
            this.rpcClient.user.getSimpleRegionalManagers(),
        ]);
    }

    @action
    public async fetchDrivers(): Promise<void> {
        this.drivers = undefined;
        const options: RetrieveDriversOptions = {
            isDriver: true,
            isCompanyDriver: true,
            isDriversCompany: false,
            searchString: this.pageRouter.search,
            managerIds: this.pageRouter.managerIds,
            countryIds: this.pageRouter.countryIds,
            statusIn: this.pageRouter.statuses,
        };

        // Too many drivers.
        if (!options.searchString && isEmpty(options.managerIds) && isEmpty(options.countryIds)) {
            this.drivers = [];
            return undefined;
        }

        const optionsValidationErrors = await validate(options, { skipMissingProperties: true });
        if (optionsValidationErrors.length > 0) {
            return Promise.reject("Validation error");
        }
        this.drivers = await this.rpcClient.driver.retrieveSimpleDrivers(options);
    }

    public isDataFetched(): this is DriversMapPageStore & DriversMapPageStoreDataFetched {
        return this.countries != undefined && this.drivers != undefined && this.managers != undefined;
    }
}

export interface DriversMapPageStoreDataFetched {
    countries: Array<Country>;
    drivers: Array<User>;
    managers: Array<SimpleUser>;
}
