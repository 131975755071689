import { Expose } from "class-transformer";
import { IsBoolean, IsDefined, IsInt, IsUUID } from "class-validator";
import { v4 as uuid } from "uuid";

import { CDBaseEntity, CDColumn, CDEntity, CDObjectIdColumn } from "../orm";

@CDEntity("travelData")
export class TravelData extends CDBaseEntity {
    @CDObjectIdColumn()
    @IsUUID("4")
    @Expose()
    public _id: string = uuid();

    @CDColumn()
    @IsUUID("4")
    @IsDefined()
    @Expose()
    public originId: string;

    @CDColumn()
    @IsUUID("4")
    @IsDefined()
    @Expose()
    public destinationId: string;

    @CDColumn()
    @IsInt()
    @IsDefined()
    @Expose()
    public distance: number = 0;

    @CDColumn()
    @IsInt()
    @IsDefined()
    @Expose()
    public duration: number = 0;

    @CDColumn()
    @IsBoolean()
    @IsDefined()
    @Expose()
    public isManuallyModified: boolean = false;
}
