import type { CreditLinePeriodModel as CreditLinePeriod } from "@daytrip/api";
import { CreateCreditLineDto, CreditLineModel as CreditLine } from "@daytrip/api";
import type { CreditLinePartnerType } from "@daytrip/legacy-enums";
import {
    CREDIT_LINE_BILLING_PERIOD_DURATION,
    CREDIT_LINE_BILLING_PERIOD_OPTIONS,
    CREDIT_LINE_PERIOD_UNIT_DESCRIPTIVE,
    CreditLinePeriodUnit,
    Currency,
} from "@daytrip/legacy-enums";
import { transformValidationErrorsToArrayString } from "@daytrip/legacy-transformers";
import { resolveBillingPeriod } from "@daytrip/legacy-utils";
import { isUndefinedOrNull } from "@daytrip/utils";
import autobind from "autobind-decorator";
import { plainToClass } from "class-transformer";
import type { ValidationError } from "class-validator";
import { validate } from "class-validator";
import { startOfWeek } from "date-fns";
import { action, computed, observable, toJS } from "mobx";

import { getRpcClient, type RpcClient } from "../../rpc-browser-sdk";

@autobind
export class CreditLineStore {
    private rpcClient: RpcClient;

    @observable
    public partnerId: string | undefined;

    @observable
    public partnerType: CreditLinePartnerType | undefined;

    public async init(options: { partnerId: string; partnerType: CreditLinePartnerType }) {
        this.partnerId = options.partnerId;
        this.partnerType = options.partnerType;
        this.editedCreditLine = undefined;
        this.newCreditLine = undefined;
        this.creditLinePeriod = undefined;
        this.temporaryAdjustmentAmount = undefined;
        await this.getCreditLine(options.partnerId);
    }

    public constructor() {
        this.rpcClient = getRpcClient();
    }

    @observable
    public creditLine: CreditLine | undefined;

    @observable
    public creditLinePeriod: CreditLinePeriod | undefined;

    @observable
    public editedCreditLine: CreditLine | undefined;

    @observable
    public newCreditLine: CreateCreditLineDto | undefined;

    @observable
    public creditLineValidationErrors: Array<ValidationError> = [];

    @observable
    public creditLineValidationMessages: Record<string, string> = {};

    @observable
    public showTemporaryIncreaseModal: boolean = false;

    @observable
    public temporaryAdjustmentAmount: number | undefined;

    public async getCreditLine(partnerId: string): Promise<void> {
        try {
            this.creditLine = await this.rpcClient.creditLine.getCreditLineByPartnerId(partnerId);
            if (this.creditLine) {
                this.creditLinePeriod = await this.rpcClient.creditLine.getLastCreditLinePeriodByPartnerId(partnerId);
                this.temporaryAdjustmentAmount = this.creditLinePeriod?.temporaryAdjustmentAmount;
            }
        } catch (error: any) {
            alert(`Oh, something is wrong. :(\nError:\n${error.message}`);
        }
    }

    @action
    public setCreditLineProperty<K extends keyof CreditLine>(key: K, value: CreditLine[K]) {
        if (this.editedCreditLine) {
            (this.editedCreditLine as CreditLine)[key] = value;
        } else if (this.newCreditLine) {
            (this.newCreditLine as CreditLine)[key] = value;
        }
        this.setFieldValidationMessagesCreditLine();
    }

    @action
    public addCreditLine() {
        if (!isUndefinedOrNull(this.partnerType) && !isUndefinedOrNull(this.partnerId)) {
            this.newCreditLine = {
                partnerType: this.partnerType,
                partnerId: this.partnerId,
                amount: 0,
                currency: Currency.Euro,
                periodUnit: CreditLinePeriodUnit.Week,
                periodLength: 1,
                firstPeriodStart: this.calculateStartingDate(CreditLinePeriodUnit.Week, 1),
            };
            scrollTo({ top: window.innerHeight, behavior: "smooth" });
        } else {
            alert(`Oh, something is wrong. :(\nYou should not see this error message, please contact dev team\n`);
        }
    }

    @computed
    public get billingPeriodDuration(): string {
        const defaultBillingPeriodDuration = CREDIT_LINE_BILLING_PERIOD_OPTIONS.one_week;
        if (!this.creditLine && !this.editedCreditLine && !this.newCreditLine) {
            return defaultBillingPeriodDuration;
        }

        const { periodUnit, periodLength } = this.newCreditLine ||
            this.editedCreditLine ||
            this.creditLine || { periodLength: 1, periodUnit: CreditLinePeriodUnit.Week };

        const billingPeriodDurationFromDb = `${periodLength} ${CREDIT_LINE_PERIOD_UNIT_DESCRIPTIVE[periodUnit]}${
            periodLength > 1 ? "s" : ""
        }`;

        return CREDIT_LINE_BILLING_PERIOD_DURATION[billingPeriodDurationFromDb]
            ? billingPeriodDurationFromDb
            : defaultBillingPeriodDuration;
    }

    private formatCurrentBillingPeriod(start: Date, end: Date): string {
        return `${start.getDate()}.${start.getMonth() + 1}.${start.getFullYear()}-${end.getDate()}.${
            end.getMonth() + 1
        }.${end.getFullYear()}`;
    }

    private formatCurrentBillingPeriodInUTC(start: Date, end: Date): string {
        return `${start.getUTCDate()}.${start.getUTCMonth() + 1}.${start.getUTCFullYear()}-${end.getUTCDate()}.${
            end.getUTCMonth() + 1
        }.${end.getUTCFullYear()}`;
    }

    @computed
    public get currentBillingPeriod(): string {
        const today = new Date();

        if (this.creditLinePeriod) {
            // format UTC as from BE response dates are in UTC
            return this.formatCurrentBillingPeriodInUTC(
                new Date(this.creditLinePeriod.periodStart),
                new Date(this.creditLinePeriod.periodEnd),
            );
        }

        if (!this.creditLine && !this.editedCreditLine && !this.newCreditLine) {
            return this.formatCurrentBillingPeriod(today, today);
        }

        const { periodUnit, periodLength, firstPeriodStart } = this.newCreditLine ||
            this.editedCreditLine ||
            this.creditLine || { periodLength: 1, periodUnit: CreditLinePeriodUnit.Week, firstPeriodStart: today };

        try {
            const { start, end } = resolveBillingPeriod(today, firstPeriodStart, periodUnit, periodLength);

            return this.formatCurrentBillingPeriod(start, end);
        } catch (error: any) {
            if (today < firstPeriodStart) {
                // billing period is in the future
                return "";
            } else {
                alert(`Oh, something is wrong. :(\nError:\n${error.message}`);
                return "";
            }
        }
    }

    private getUTCDate(date: Date) {
        return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
    }

    @action
    public calculateStartingDate(periodUnit: CreditLinePeriodUnit, periodLength: number): Date {
        // periodLength - 1 because we want to start from the beginning of the current week/month
        // and should not modify the current date
        const adjustedPeriod = periodLength - 1;
        const today = new Date();
        switch (periodUnit) {
            case CreditLinePeriodUnit.Week:
                const dateInThePastWeek = new Date(
                    today.getFullYear(),
                    today.getMonth(),
                    today.getDate() - adjustedPeriod * 7,
                );
                return this.getUTCDate(startOfWeek(dateInThePastWeek, { weekStartsOn: 1 }));
            case CreditLinePeriodUnit.Month:
                const firstDayInMonth = new Date(today.getFullYear(), today.getMonth() - adjustedPeriod, 1);
                return this.getUTCDate(firstDayInMonth);
            default:
                return new Date();
        }
    }

    private async creditLineValidate() {
        if (this.newCreditLine) {
            this.creditLineValidationErrors = await validate(
                plainToClass(CreateCreditLineDto, toJS(this.newCreditLine)),
                {
                    skipMissingProperties: true,
                },
            );
        } else {
            this.creditLineValidationErrors = await validate(plainToClass(CreditLine, toJS(this.editedCreditLine)), {
                skipMissingProperties: true,
            });
        }
    }

    @action
    public async creditLineEditSave() {
        await this.creditLineValidate();

        if (this.creditLineValidationErrors.length > 0) {
            const validationMessages = transformValidationErrorsToArrayString(this.creditLineValidationErrors).join(
                "\n",
            );
            alert(`Oh, something is wrong. :(\n\nValidation errors:\n${validationMessages}`);
            return;
        }

        if (this.editedCreditLine) {
            try {
                await this.rpcClient.creditLine.updateCreditLine(this.editedCreditLine);
                this.creditLine = toJS(this.editedCreditLine);
                this.editedCreditLine = undefined;
            } catch (error: any) {
                alert(`Oh, something is wrong. :(\nError:\n${error.message}`);
            }
        } else if (this.newCreditLine) {
            try {
                this.creditLine = await this.rpcClient.creditLine.createCreditLine(this.newCreditLine);
                this.creditLinePeriod = await this.rpcClient.creditLine.getLastCreditLinePeriodByPartnerId(
                    this.creditLine.partnerId,
                );
                this.newCreditLine = undefined;
            } catch (error: any) {
                alert(`Oh, something is wrong. :(\nError:\n${error.message}`);
            }
        } else {
            alert(`Oh, something is wrong. :(\nYou should not see this error message, please contact dev team\n`);
        }
    }

    @action
    public creditLineEdit() {
        this.editedCreditLine = toJS(this.creditLine);
    }

    @action
    public creditLineEditCancelOrGoBack() {
        this.editedCreditLine = undefined;
        this.newCreditLine = undefined;
        this.creditLineValidationMessages = {};
    }

    @action
    public async deleteCreditLine() {
        try {
            await this.rpcClient.creditLine.deleteCreditLine((this.creditLine as CreditLine)._id);
            this.creditLine = undefined;
            this.creditLinePeriod = undefined;
            this.temporaryAdjustmentAmount = undefined;
        } catch (error: any) {
            alert(`Oh, something is wrong. :(\nError:\n${error.message}`);
        }
    }

    private validateFirstPeriodStart() {
        const propertyName = "firstPeriodStart";
        const currentCreditLine = this.newCreditLine || this.editedCreditLine || this.creditLine;
        if (!currentCreditLine) {
            return;
        }
        if (
            this.editedCreditLine &&
            this.creditLine &&
            this.editedCreditLine.firstPeriodStart === this.creditLine.firstPeriodStart
        ) {
            return;
        }
        const { periodUnit, periodLength, firstPeriodStart } = currentCreditLine;
        const firstPeriodStartAsDate = new Date(firstPeriodStart);
        if (periodUnit === CreditLinePeriodUnit.Week) {
            // Sunday = 0, Monday = 1, Tuesday = 2, etc.
            if (firstPeriodStartAsDate.getDay() !== 1) {
                this.creditLineValidationMessages[propertyName] =
                    "Starting day must be a Monday because billing period duration is weekly-based";
            }
        }
        if (periodUnit === CreditLinePeriodUnit.Month) {
            if (firstPeriodStartAsDate.getDate() !== 1) {
                this.creditLineValidationMessages[propertyName] =
                    "Starting day must be a first day of the month because billing period duration is monthly-based";
            }
        }
    }

    private setFieldValidationMessageCreditLine(propertyName: string): void {
        const validationErrors = this.creditLineValidationErrors.find((ve) => ve.property === propertyName);
        if (validationErrors) {
            this.creditLineValidationMessages[propertyName] = JSON.stringify(validationErrors.constraints);
        }

        if (propertyName === "firstPeriodStart") {
            this.validateFirstPeriodStart();
        }
    }

    @action
    public async setFieldValidationMessagesCreditLine() {
        await this.creditLineValidate();
        this.creditLineValidationMessages = {};
        this.setFieldValidationMessageCreditLine("currency");
        this.setFieldValidationMessageCreditLine("amount");
        this.setFieldValidationMessageCreditLine("firstPeriodStart");
    }

    @action
    public setCreditLinePeriodTemporaryAdjustmentProperty(value: number) {
        this.temporaryAdjustmentAmount = value;
    }

    @action
    public async updateTemporaryCreditLineAdjustment() {
        if (this.creditLinePeriod && !isUndefinedOrNull(this.temporaryAdjustmentAmount)) {
            try {
                this.creditLinePeriod = await this.rpcClient.creditLine.setTemporaryCreditLineAdjustment({
                    partnerId: this.creditLinePeriod.partnerId,
                    adjustmentAmount: this.temporaryAdjustmentAmount,
                });
            } catch (error: any) {
                alert(`Oh, something is wrong. :(\nError:\n${error.message}`);
            }
        } else {
            alert(`Oh, something is wrong. :(\nYou should not see this error message, please contact dev team\n`);
        }
    }

    @action
    public closeTemporaryIncreaseModal() {
        this.showTemporaryIncreaseModal = false;
        this.temporaryAdjustmentAmount = this.creditLinePeriod?.temporaryAdjustmentAmount;
    }

    @action
    public openTemporaryIncreaseModal() {
        this.showTemporaryIncreaseModal = true;
    }
}
