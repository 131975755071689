import type { Location } from "@daytrip/legacy-models";
import type { Order } from "@daytrip/legacy-models";
import { Payment } from "@legacy/models/Payment";
import { PaymentRequest } from "@legacy/models/PaymentRequest";
import type { User } from "@legacy/models/User";
import type { RetrievePaymentsOptions } from "@legacy/options/RetrievePaymentsOptions";
import { transformValidationErrorsToArrayString } from "@daytrip/legacy-transformers";
import { isUndefinedOrNull } from "@legacy/utils";
import autobind from "autobind-decorator";
import { classToPlain, plainToClass } from "class-transformer";
import { validate } from "class-validator";
import { action, observable } from "mobx";

import { PaymentOperator } from "../../operators/PaymentOperator";
import { PageStore } from "../../stores/PageStore";

import type { PaymentsPageRouter } from "./PaymentsPageRouter";

@autobind
export class PaymentsPageStore extends PageStore<PaymentsPageRouter, null> {
    @observable
    public paymentOperators?: Array<PaymentOperator>;

    @observable
    public paymentsCount: number = 0;

    @observable
    public orders?: Array<Order>;

    @observable
    public locations: Array<Location>;

    @observable
    public users?: Array<User>;

    @action
    public async onFetchData() {
        await this.fetchContent();
        await this.fetchPayments();
    }

    @action
    public async fetchContent() {
        this.locations = await this.rpcClient.content.retrieveLocations({});
    }

    @action
    public async fetchPayments() {
        this.paymentOperators = undefined;

        const options = {
            skip: this.pageRouter.skip,
            limit: this.pageRouter.limit,
            sortBy: this.pageRouter.sortBy,
            sortDirection: this.pageRouter.sortDirection,
        } as RetrievePaymentsOptions;

        const payments = plainToClass(Payment, await this.rpcClient.payment.retrievePayments(options));

        [this.users, this.paymentsCount] = await Promise.all([
            this.rpcClient.user.retrieveUsers({ userIds: payments.map((p) => p.userId) }),
            this.rpcClient.payment.retrievePaymentsCount(options),
        ]);

        const paymentRequests = plainToClass(
            PaymentRequest,
            await this.rpcClient.payment.retrievePaymentRequests({
                ids: payments.reduce<string[]>((ids, p) => {
                    if (p.paymentRequestId != undefined) {
                        ids.push(p.paymentRequestId);
                    }
                    return ids;
                }, []),
            }),
        );

        const orders = await this.rpcClient.order.retrieveOrders({
            ids: payments.reduce<string[]>((ids, p) => {
                if (p.orderId != undefined) {
                    ids.push(p.orderId);
                }
                return ids;
            }, []),
        });

        this.paymentOperators = payments.map((p) => {
            const order = orders.find((o) => o._id == p.orderId);
            return new PaymentOperator({
                modelConstructor: Payment,
                model: p,
                paymentRequest: paymentRequests.find((pr) => pr._id == p.paymentRequestId),
                paymentUser: (this.users as Array<User>).find((u) => u._id == p.userId),
                paymentOrder: order,
                orderOriginLocation:
                    order != undefined ? this.locations.find((l) => l._id == order.originLocationId) : undefined,
                orderDestinationLocation:
                    order != undefined ? this.locations.find((l) => l._id == order.destinationLocationId) : undefined,
                modules: null,
                data: {},
            });
        });
    }

    public isDataFetched(): this is PaymentsPageStore & PaymentsPageStoreDataFetched {
        return !isUndefinedOrNull(this.paymentOperators) && !isUndefinedOrNull(this.users);
    }

    // new payment request
    @observable
    public newPaymentRequest = classToPlain(new PaymentRequest()) as PaymentRequest;

    @action
    public onChangePaymentRequestOrderId(orderId: string): void {
        this.newPaymentRequest.orderId = orderId;
    }

    @action
    public onChangePaymentRequestUserId(userId: string): void {
        this.newPaymentRequest.userId = userId;
    }

    @action
    public onChangePaymentRequestAmount(amount: number): void {
        this.newPaymentRequest.amount = amount;
    }

    @action
    public onChangePaymentRequestDescription(description: string): void {
        this.newPaymentRequest.description = description;
    }

    @observable
    public isPaymentRequestCreating = false;

    @observable
    public isPaymentRequestCreatingFailed = false;

    @observable
    public paymentRequestCreatedAt?: Date;

    @action
    public async createPaymentRequest(): Promise<void> {
        this.isPaymentRequestCreating = true;
        this.isPaymentRequestCreatingFailed = false;
        this.paymentRequestCreatedAt = undefined;

        const validations = await validate(plainToClass(PaymentRequest, this.newPaymentRequest), {
            skipMissingProperties: true,
        });

        if (validations.length > 0) {
            const validationMessages = transformValidationErrorsToArrayString(validations).join("\n");
            alert(`Oh, something is wrong. :(\n\nValidation errors:\n${validationMessages}`);
            return;
        }

        try {
            await this.rpcClient.payment.createPaymentRequest(this.newPaymentRequest);
            this.newPaymentRequest = classToPlain(new PaymentRequest()) as PaymentRequest;
            this.paymentRequestCreatedAt = new Date();
        } catch (e: any) {
            this.isPaymentRequestCreatingFailed = true;
        }
        this.isPaymentRequestCreating = false;
    }

    // table filtering
    @observable
    public sortByProperty: string;

    @observable
    public isSortDesc: boolean = true;

    public getLocationById(locationId: string): Location {
        return this.locations.find((l) => l._id == locationId) as Location;
    }
}

export interface PaymentsPageStoreDataFetched {
    paymentOperators: Array<PaymentOperator>;
    users: Array<User>;
}
