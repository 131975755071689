import { action, observable } from "mobx";

import { FrequentlyAskedQuestion } from "@legacy/models/FrequentlyAskedQuestion";

import { ModelOperator } from "./ModelOperator";
import { ModelOperatorOptions } from "./ModelOperatorOptions";

interface FrequentlyAskedQuestionOptions extends ModelOperatorOptions<FrequentlyAskedQuestion, null, null> {
    isNew: boolean;
    isUpdated: boolean;
}

export class FrequentlyAskedQuestionOperator extends ModelOperator<
    FrequentlyAskedQuestion,
    FrequentlyAskedQuestionOptions,
    null,
    null,
    null
> {
    public constructor(options: FrequentlyAskedQuestionOptions) {
        super(options);

        this.isNew = options.isNew;
        this.isUpdated = options.isUpdated;
    }
    @observable
    public isNew: boolean = false;

    @observable
    public isUpdated: boolean = false;
}
