import { Position } from "@daytrip/legacy-models";
import { Sanitize } from "class-sanitizer/decorators";
import { Exclude, Expose, Type } from "class-transformer";
import { IsArray, IsBoolean, IsDefined, IsInt, IsString, IsUUID, Length } from "class-validator";

import { MachineNameSanitizer } from "../sanitizers/MachineNameSanitizer";

@Exclude()
export class SimpleLocation {
    @IsUUID("4")
    @IsDefined()
    @Expose()
    public locationId: string;

    @IsUUID("4")
    @IsDefined()
    @Expose()
    public countryId: string;

    @Length(1, 64)
    @IsString()
    @IsDefined()
    @Expose()
    public name: string = "";

    @IsArray()
    @Expose()
    @Type(() => String)
    public localNames: Array<string> = [];

    @Length(1, 64)
    @IsString()
    @Sanitize(MachineNameSanitizer)
    @IsDefined()
    @Expose()
    public machineName: string = "";

    @IsArray()
    @Expose()
    @Type(() => String)
    public otherNames: Array<string> = [];

    @IsArray()
    @Expose()
    @Type(() => String)
    public airportCodes: Array<string> = [];

    @IsInt()
    @IsDefined()
    @Expose()
    public defaultDuration: number = 60;

    @IsDefined()
    @Expose()
    @Type(() => Position)
    public position: Position;

    @IsBoolean()
    @IsDefined()
    @Expose()
    public isLive: boolean = false;

    @IsBoolean()
    @IsDefined()
    @Expose()
    public isReady: boolean = false;

    @IsBoolean()
    @IsDefined()
    @Expose()
    public isDestination: boolean = false;

    @IsBoolean()
    @IsDefined()
    @Expose()
    public isLocation: boolean = false;

    @IsInt()
    @IsDefined()
    @Expose()
    public score: number = 0;

    @Expose()
    public countryName?: string;
}
