import { DriverFaq } from "@daytrip/api";
import type { RpcSurface } from "@daytrip/api";
import autobind from "autobind-decorator";
import { plainToClass } from "class-transformer";
import { action, computed, observable } from "mobx";

import type { PageRouter } from "../../PageRouter";
import { PageStore } from "../../stores/PageStore";

import { DriverFaqOperator } from "./DriverFaqOperator";

@autobind
export class DriversFaqPageStore extends PageStore<PageRouter, {}> {
    @observable
    isContentFetched: boolean = false;

    @observable
    serverFaqs: DriverFaq[] = [];

    @observable
    faqs: DriverFaqOperator[] = [];

    @observable
    categories: string[] = [];

    @observable
    inEditMode: boolean = false;

    @observable
    isNewCategoryModalShown: boolean = false;

    @observable
    newCategoryInput: string = "";

    @observable
    faqIdWithNewCategory: string | null = null;

    @observable
    newCategoryValidationMessage: string | null = null;

    @computed
    get visibleFaqs(): DriverFaqOperator[] {
        return this.faqs.filter((faq) => !faq.isDeleted);
    }

    @computed
    get minOrder(): number {
        return this.visibleFaqs.length > 0 ? Math.min(...this.visibleFaqs.map((faq) => faq.m.order)) : 0;
    }

    @computed
    get maxOrder(): number {
        return this.visibleFaqs.length > 0 ? Math.max(...this.visibleFaqs.map((faq) => faq.m.order)) : 0;
    }

    @action
    async onFetchData() {
        await Promise.all([this.fetchFaq(), this.fetchCategories()]);
    }

    @action
    async fetchFaq() {
        const rpcClient = this.rpcClient as RpcSurface;
        const frequentlyAskedQuestions = await rpcClient.driverFaq.getAllDriverFaqs();
        this.serverFaqs = frequentlyAskedQuestions;
        this.faqs = frequentlyAskedQuestions.map((question) => {
            return new DriverFaqOperator({
                modelConstructor: DriverFaq,
                model: plainToClass(DriverFaq, question),
                modules: null,
                data: null,
                isNew: false,
                isDeleted: false,
            });
        });
        this.isContentFetched = true;
    }

    @action
    async fetchCategories() {
        const rpcClient = this.rpcClient as RpcSurface;
        this.categories = await rpcClient.driverFaq.getDriverFaqCategories();
    }

    isDataFetched(): boolean {
        return this.isContentFetched;
    }

    @action
    enableEditingMode() {
        this.inEditMode = true;
    }

    @action
    onAddNewFaq() {
        const newFaq: DriverFaq = Object.assign(new DriverFaq(), {
            question: "",
            answer: "",
            order: this.maxOrder + 1,
            category: "",
            roles: [],
        });

        const newFaqOperator = new DriverFaqOperator({
            modelConstructor: DriverFaq,
            model: plainToClass(DriverFaq, newFaq),
            modules: null,
            data: null,
            isNew: true,
            isDeleted: false,
        });

        this.faqs.push(newFaqOperator);

        scrollTo({ top: window.innerHeight, behavior: "smooth" });
    }

    @action
    onRemoveFaq(faqId: string) {
        const faqOperator = this.faqs.find((faq) => faq.m._id === faqId);

        if (!faqOperator) {
            return;
        }

        if (faqOperator.isNew) {
            this.faqs = this.faqs.filter((item) => item.m._id !== faqId);
        } else {
            faqOperator.isDeleted = true;
        }

        this.faqs.forEach((faqo) => {
            if (faqo.m.order > faqOperator.m.order) {
                faqo.edit((faq) => {
                    faq.order -= 1;
                });
            }
        });
    }

    @action
    onCancelSaveFaq() {
        this.faqs = this.serverFaqs.map((question) => {
            return new DriverFaqOperator({
                modelConstructor: DriverFaq,
                model: plainToClass(DriverFaq, question),
                modules: null,
                data: null,
                isNew: false,
                isDeleted: false,
            });
        });
        this.inEditMode = false;
    }

    @action
    async onSaveFaq() {
        const faqsIdsToDelete = this.faqs.filter((faq) => faq.isDeleted).map((faq) => faq.m._id);
        const faqsToUpdate = this.faqs.filter((faq) => faq.isEdited() && !faq.isNew).map((faq) => faq.m);
        const faqsToCreate = this.faqs.filter((faq) => faq.isNew).map((faq) => faq.m);

        this.visibleFaqs.forEach((faq) => {
            faq.validate();
        });

        const rpcClient = this.rpcClient as RpcSurface;

        await rpcClient.driverFaq.updateDriverFaqs({
            create: faqsToCreate,
            update: faqsToUpdate,
            remove: faqsIdsToDelete,
        });

        this.inEditMode = false;

        await this.fetchData();
    }

    @action
    showNewCategoryModal(faqId: string) {
        this.isNewCategoryModalShown = true;
        this.newCategoryInput = "";
        this.faqIdWithNewCategory = faqId;
    }

    @action
    hideNewCategoryModal() {
        this.isNewCategoryModalShown = false;
        this.newCategoryInput = "";
        this.faqIdWithNewCategory = null;
    }

    @action
    addNewCategory() {
        this.categories.push(this.newCategoryInput);
        const faqoToEdit = this.faqs.find((faq) => faq.m._id === this.faqIdWithNewCategory);
        if (faqoToEdit) {
            faqoToEdit.edit((faq) => {
                faq.category = this.newCategoryInput;
            });
        }
        this.hideNewCategoryModal();
    }

    @action
    onChangeNewCategory(category: string) {
        this.newCategoryInput = category;
        this.newCategoryValidationMessage = this.validateNewCategory(category);
    }

    @action
    public changeOrder(faqId: string, orderAction: "increase" | "decrease") {
        const faqOperator = this.faqs.find((faq) => faq.m._id === faqId);

        if (!faqOperator) {
            return;
        }

        const currentOrder = faqOperator.m.order;
        const newOrderValue = orderAction === "increase" ? currentOrder + 1 : currentOrder - 1;

        const secondFaqOperator = this.visibleFaqs.find((faq) => faq.m.order === newOrderValue);

        if (!secondFaqOperator) {
            return;
        }

        faqOperator.edit((faq) => {
            faq.order = newOrderValue;
        });

        secondFaqOperator.edit((faq) => {
            faq.order = currentOrder;
        });
    }

    private validateNewCategory(newCategory: string): string | null {
        const isDuplicate = this.categories.some((category) => category.toLowerCase() === newCategory.toLowerCase());

        if (isDuplicate) {
            return "Category already exists.";
        }

        return null;
    }
}
