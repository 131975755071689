import { Currency } from "@legacy/domain/Currency";
import { PayoutData } from "@legacy/domain/PayoutData";
import { PayoutForPayoutsPage } from "@legacy/domain/PayoutForPayoutsPage";
import { PayoutStatus } from "@legacy/domain/PayoutStatus";
import { PayoutType } from "@legacy/domain/PayoutType";
import type { SimpleUser } from "@legacy/domain/SimpleUser";
import { RetrievePayoutsOptions } from "@legacy/options/RetrievePayoutsOptions";
import { valueOrArrayToArray } from "@daytrip/utils";
import autobind from "autobind-decorator";
import { action, computed, observable, reaction } from "mobx";
import type { Option } from "react-select-legacy";

import { DataStore } from "../../stores/DataStore";
import { PageStore } from "../../stores/PageStore";
import { PaginatedDataStore } from "../../stores/PaginatedDataStore";

import { DriversPayoutsPageRouter } from "./DriversPayoutsPageRouter";
import { transformPayoutPeriodIndexToDateRange } from "@daytrip/legacy-transformers";

@autobind
export class DriversPayoutsPageStore extends PageStore<DriversPayoutsPageRouter, {}> {
    public onInit(): void {
        this.checkAbilityToFailPayouts();

        const predefinedUserIds = this.pageRouter.location.query.payouts_userId;

        this.payouts = new PaginatedDataStore(
            PayoutForPayoutsPage,
            RetrievePayoutsOptions,
            (options) => this.fetchPayouts(options),
            (options) => {
                this.paypalTotalAmountEur.fetch();
                this.paypalTotalAmountUsd.fetch();
                this.mangopayTotalAmountEur.fetch();
                this.mangopayTotalAmountUsd.fetch();
                this.hyperWalletTotalAmountEur.fetch();
                this.hyperWalletTotalAmountUsd.fetch();
                this.stripeTotalAmountEur.fetch();
                this.stripeTotalAmountUsd.fetch();
                return this.fetchPayoutsCount(options);
            },
            this.pageRouter,
            "",
            {
                hasBaselessAmount: undefined,
                status: undefined,
                type: undefined,
                skip: 1,
                limit: 30,
                userIds: predefinedUserIds ? [predefinedUserIds] : undefined,
                currency: undefined,
                sortDirection: this.pageRouter.sortDirection,
                sortBy: "status",
            },
            true,
        );

        if (this.payouts.options.userIds != null) {
            this.fetchSimpleUsersForPayouts(valueOrArrayToArray(this.payouts.options.userIds));
        }

        this.openedPayout = new DataStore(async () => {
            if (this.openedPayoutId != null) {
                return this.rpcClient.payout.retrievePayoutData(this.openedPayoutId);
            }
            return undefined;
        }, PayoutData);

        const fetchTotalAmount = async (type: PayoutType, currency: Currency) => {
            const amount = await this.rpcClient.payout.retrievePayoutsTotalAmount({
                userIds: this.payouts.options.userIds,
                hasBaselessAmount: this.payouts.options.hasBaselessAmount,
                status: this.payouts.options.status,
                type: [type],
                currency,
                ...this.getStatusChangedAtOptions(),
            });

            return amount == null ? 0 : amount;
        };

        this.mangopayTotalAmountEur = new DataStore(() => fetchTotalAmount(PayoutType.Mangopay, Currency.Euro));
        this.mangopayTotalAmountUsd = new DataStore(() => fetchTotalAmount(PayoutType.Mangopay, Currency.Dollar));

        this.paypalTotalAmountEur = new DataStore(() => fetchTotalAmount(PayoutType.Paypal, Currency.Euro));
        this.paypalTotalAmountUsd = new DataStore(() => fetchTotalAmount(PayoutType.Paypal, Currency.Dollar));

        this.bankTransactionTotalAmountEur = new DataStore(() =>
            fetchTotalAmount(PayoutType.BankTransaction, Currency.Euro),
        );
        this.bankTransactionTotalAmountUsd = new DataStore(() =>
            fetchTotalAmount(PayoutType.BankTransaction, Currency.Dollar),
        );

        this.hyperWalletTotalAmountEur = new DataStore(() => fetchTotalAmount(PayoutType.Hyperwallet, Currency.Euro));
        this.hyperWalletTotalAmountUsd = new DataStore(() => fetchTotalAmount(PayoutType.Hyperwallet, Currency.Dollar));

        this.stripeTotalAmountEur = new DataStore(() => fetchTotalAmount(PayoutType.Stripe, Currency.Euro));
        this.stripeTotalAmountUsd = new DataStore(() => fetchTotalAmount(PayoutType.Stripe, Currency.Dollar));

        reaction(() => this.openedPayoutId, this.openedPayout.fetch);
    }

    private getStatusChangedAtOptions() {
        const defaultPeriod = transformPayoutPeriodIndexToDateRange(-1);

        if (!this.pageRouter.statusChangedAtFrom || !this.pageRouter.statusChangedAtTo) {
            this.pageRouter.filterStatusChangedAtFromUpdate(defaultPeriod.startAt.getTime());
            this.pageRouter.filterStatusChangedAtToUpdate(defaultPeriod.endAt.getTime());
        }

        return {
            statusChangedAtFrom: this.pageRouter.statusChangedAtFrom ?? defaultPeriod.startAt,
            statusChangedAtTo: this.pageRouter.statusChangedAtTo ?? defaultPeriod.endAt,
        };
    }

    private async fetchPayouts(options: RetrievePayoutsOptions) {
        return this.rpcClient.payout.retrievePayoutsForPayoutsPage({
            ...options,
            ...this.getStatusChangedAtOptions(),
        });
    }

    private async fetchPayoutsCount(options: RetrievePayoutsOptions) {
        return this.rpcClient.payout.retrievePayoutsForPayoutsPageCount({
            ...options,
            ...this.getStatusChangedAtOptions(),
        });
    }

    // table data
    @observable
    public payouts: PaginatedDataStore<RetrievePayoutsOptions, PayoutForPayoutsPage>;

    // total amounts
    @observable
    public mangopayTotalAmountEur: DataStore<number>;

    @observable
    public mangopayTotalAmountUsd: DataStore<number>;

    @observable
    public paypalTotalAmountEur: DataStore<number>;

    @observable
    public paypalTotalAmountUsd: DataStore<number>;

    @observable
    public bankTransactionTotalAmountEur: DataStore<number>;

    @observable
    public bankTransactionTotalAmountUsd: DataStore<number>;

    @observable
    public hyperWalletTotalAmountEur: DataStore<number>;

    @observable
    public hyperWalletTotalAmountUsd: DataStore<number>;

    @observable
    public stripeTotalAmountEur: DataStore<number>;

    @observable
    public stripeTotalAmountUsd: DataStore<number>;

    // payout modal
    @observable
    public openedPayout: DataStore<PayoutData>;

    @observable
    public openedPayoutId: string | undefined;

    @action
    public async openPayoutDetail(payoutId: string): Promise<void> {
        this.openedPayoutId = payoutId;
    }

    @action
    public closePayoutDetail(): void {
        this.openedPayoutId = undefined;
    }

    // payouts approval
    @computed
    public get markedPendingPayouts(): Array<PayoutForPayoutsPage> {
        return this.payouts?.markedData.filter((d) => d.payout.status === PayoutStatus.Pending) ?? [];
    }

    @observable
    public isPayoutsApproveFetching = false;

    @observable
    public isPayoutsApproved = false;

    @action
    public async approveMarkedPayouts(): Promise<void> {
        this.isPayoutsApproveFetching = true;

        await this.rpcClient.payout.approvePayouts(this.markedPendingPayouts.map((p) => p.payout._id));

        this.isPayoutsApproveFetching = false;
        this.isPayoutsApproved = true;

        this.payouts.fetch();
    }

    // payouting
    @computed
    public get markedPayoutsToSend(): Array<PayoutForPayoutsPage> {
        if (this.payouts != null) {
            return this.payouts.markedData.filter(
                (d) =>
                    d.payout.status === PayoutStatus.Approved ||
                    d.payout.status === PayoutStatus.Failed ||
                    d.payout.status === PayoutStatus.Unknown,
            );
        }
        return [];
    }

    @observable
    public isPayoutsSending = false;

    @observable
    public isPayoutsSent = false;

    @action
    public async sendMarkedPayouts(): Promise<void> {
        this.isPayoutsSending = true;
        await this.rpcClient.payout.payoutApprovedPayouts(this.markedPayoutsToSend.map((p) => p.payout._id));

        this.isPayoutsSending = false;
        this.isPayoutsSent = true;

        this.payouts.fetch();

        setTimeout(() => {
            this.isPayoutsSent = false;
        }, 10000);
    }

    // prepare payouts
    @observable
    public simpleUsersForPayouts?: Array<SimpleUser>;

    @action
    public async fetchSimpleUsersForPayouts(userIds?: Array<string>) {
        const simpleUsersForPayouts = await this.rpcClient.user.retrieveSimpleUsers({
            userIds,
            isDriversCompany: true,
            isDriver: true,
        });

        this.simpleUsersForPayouts = simpleUsersForPayouts;

        const fileteredUsers =
            this.payouts.options.userIds?.map((userId) => simpleUsersForPayouts.find((user) => user._id === userId)!) ??
            [];
        this.selectedUserOptions = this.transformSimpleUsersToSelectOptions(fileteredUsers);
    }

    public async fetchSimpleUsersForSelect(searchString: string) {
        if (searchString.length <= 3) {
            return { options: [], complete: false };
        }

        const simpleUsersForPayouts = await this.rpcClient.user.retrieveSimpleUsers({
            searchString,
            isDriversCompany: true,
            isDriver: true,
        });

        return {
            options: this.transformSimpleUsersToSelectOptions(simpleUsersForPayouts),
            complete: true,
        };
    }

    private transformSimpleUsersToSelectOptions(simpleUsers: SimpleUser[]) {
        return simpleUsers
            .sort((a, b) => (a.isCompany ? 1 : a.fullName.localeCompare(b.fullName)))
            .map((simpleUser) => ({
                label: `${simpleUser.isCompany ? "Company: " : ""}${simpleUser.fullName} <${simpleUser.email}>`,
                value: simpleUser._id,
            }));
    }

    @observable
    public selectedUserOptions?: Array<Option>;

    @action
    public selectUsers(selectedUserOptions: Array<Option>): void {
        this.selectedUserOptions = selectedUserOptions;
        this.isPayoutsPrepared = false;
        this.payouts.options.userIds = selectedUserOptions.map((option) => option.value as string);
    }

    @observable
    public isPayoutsPrepareFetching = false;

    @observable
    public isPayoutsPrepared = false;

    @observable
    public canFailPayouts = true;

    @action
    public async checkAbilityToFailPayouts() {
        this.canFailPayouts = this.authenticationStore.hasPermissions({
            or: ["Payout:Fail", "Payout_Pending:Fail"],
        });
    }

    @action
    public async preparePayoutsForSelectedUsers(): Promise<void> {
        this.isPayoutsPrepareFetching = true;
        // DS use statusChangedAtFrom and statusChangedAtTo to filter payouts by departure at
        await this.rpcClient.payout.preparePayouts({
            userIds: this.payouts.options.userIds,
            departureAtFrom: this.pageRouter.statusChangedAtFrom!,
            departureAtTo: this.pageRouter.statusChangedAtTo!,
        });

        this.isPayoutsPrepareFetching = false;
        this.isPayoutsPrepared = true;

        this.payouts.fetch();
    }

    public async failPayout(id: string): Promise<void> {
        await this.rpcClient.payout.failPayout(id);
        this.payouts.fetch();
    }
}
