import { PayoutType } from "./domain/PayoutType";
import type { MeetingPosition, DropoffPosition } from "@daytrip/legacy-models";

export type ParameterlessConstructor<T> = { new (): T; prototype: T };

export enum Direction {
    ASC = 1,
    DESC = -1,
}

// type checking
// tslint:disable-next-line:no-any
export function isNumber(variable: any): variable is number {
    return !isNaN(parseFloat(variable));
}

export function hasNumber(value: string): boolean {
    return /\d/.test(value);
}

/**
 * @deprecated The same method is available in @daytrip/utils/value-checkers
 */
// tslint:disable-next-line:no-any
export function isUndefinedOrNull(variable: any): variable is undefined | null {
    return typeof variable === "undefined" || variable === null;
}

export function transformPayoutTypeToStringIcons(type: PayoutType): string {
    switch (type) {
        case PayoutType.Mangopay:
            return "credit-card";
        case PayoutType.Paypal:
            return "paypal";
        case PayoutType.BankTransaction:
            return "bank";
        case PayoutType.Cash:
            return "money";
        case PayoutType.Hyperwallet:
            return "h-square";
        case PayoutType.Stripe:
            return "cc-stripe";
        default:
            return "question-circle";
    }
}

// tslint:disable-next-line:no-any
export function isObject(variable: any): variable is Object {
    return Object.prototype.toString.call(variable) === "[object Object]";
}

export function twoDigit(x: Number) {
    const output = String(x);
    return output.length === 1 ? `0${output}` : output;
}

export function getUtcDate(): Date {
    const now = new Date();
    return new Date(now.getTime() + now.getTimezoneOffset() * 60000);
}

/**
 * month: number - starts with 1
 * year: number
 */
export function getDaysInMonth(month: number, year: number): number {
    return month === 2 ? (year & 3 || (!(year % 25) && year & 15) ? 28 : 29) : 30 + ((month + (month >> 3)) & 1);
}

export function checkIfAddressIsAirport(address: string) {
    // 2 to 3 letters and 2 to 4 numbers pattern
    const regex = /[A-Z]{2,3}\d{2,4}(?!\d)(?![A-Z])/;
    const regexMatch = regex.test(address.trim());

    let isAirport = regexMatch;

    // if address doesn't contain flight number, but is marked as flight/fly etc.

    const airportIndicators = [
        "flight",
        "fly",
        "flying",
        "landing",
        "airport",
        "plane",
        "airfield",
        "arrivals",
        "aeroporto",
        "aeropuerto",
    ];

    const addressSplitted = address.split(/[\s,]+/);

    addressSplitted.map((word) => {
        if (airportIndicators.indexOf(word.toLowerCase()) > -1) {
            isAirport = true;
        }
        return isAirport;
    });

    return { isAirport, flightNumberMatch: regexMatch };
}

export function checkIfAddressIsBoat(address: string): boolean {
    const boatIndicators = [
        "boat",
        "ship",
        "viking",
        "uniworld",
        "ama",
        "dock",
        "docking",
        "port",
        "ferry",
        "cruise",
        "marina",
        "msc",
        "harbour",
    ];

    const addressSplitted = address.split(/[\s,]+/);

    let isBoat = false;

    addressSplitted.map((word) => {
        if (boatIndicators.indexOf(word.toLowerCase()) > -1) {
            isBoat = true;
        }
        return isBoat;
    });

    return isBoat;
}

export function checkIfAddressIsTrain(address: string): boolean {
    const trainIndicators = [
        "train",
        "rail",
        "railway",
        "centrale",
        "central",
        "railroad",
        "station",
        "stazione",
        "tgv",
        "gare",
        "afragola",
    ];

    const addressSplitted = address.split(/[\s,]+/);

    let isTrain = false;

    addressSplitted.map((word) => {
        if (trainIndicators.indexOf(word.toLowerCase()) > -1) {
            isTrain = true;
        }
        return isTrain;
    });

    return isTrain;
}

// Commented out so it will be checked by customer support manually every time
export function checkIfAddressIncomplete(_address: string): boolean {
    return false;

    // if (address.length == 0)
    // {
    //     return true;
    // }

    // if (address.toLowerCase().indexOf("hotel") > -1)
    // {
    //     return false;
    // }

    // commented regex for address check
    // address = address.trim();

    // // regex for: Streetname 123
    // const regex = new RegExp('^([A-ZÄÖÜ][a-zäöüß]+(([.] )|( )|([-])))+[1-9][0-9]{0,2}[a-z]?');
    // const regexMatch = regex.exec(address);

    // return regexMatch == null ||
    //     //!(regexMatch[0] == address) ||
    //     address.toLowerCase().indexOf("tbd") > -1 ||
    //     address.toLowerCase().indexOf("tba") > -1 ||
    //     address.toLowerCase().indexOf("not sure") > -1 ||
    //     address.toLowerCase().indexOf("later") > -1;
}

export function getDropoffPositionLocationUrl(dropoffPosition: DropoffPosition): string {
    if (dropoffPosition?.position) {
        return getLocationPointUrl(dropoffPosition.position.latitude, dropoffPosition.position.longitude);
    }

    return "";
}

export function getMeetingPositionLocationUrl(meetingPosition?: Pick<MeetingPosition, "position">): string {
    if (meetingPosition?.position) {
        return getLocationPointUrl(meetingPosition.position.latitude, meetingPosition.position.longitude);
    }

    return "";
}

export function getLocationPointUrl(latitude: number, longitude: number): string {
    if (latitude && longitude) {
        return `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`;
    }

    return "";
}
