import { DateRange } from "@daytrip/legacy-models";
import { transformPayoutPeriodIndexToDateRange } from "@daytrip/legacy-transformers";
import { AssignationDataForDriver } from "@legacy/domain/AssignationDataForDriver";
import { AssignationStatus } from "@legacy/domain/AssignationStatus";
import { Currency } from "@legacy/domain/Currency";
import type { DriverDebt } from "@legacy/models/DriverDebt";
import { Payout } from "@legacy/models/Payout";
import autobind from "autobind-decorator";
import { plainToClass } from "class-transformer";
import { action, computed, observable } from "mobx";

import { PageStore } from "../../stores/PageStore";
import { sumarizeAssignationDataForDriverValues } from "../../utils/sumarizeAssignationDataForDriverValues";

import type { CompletedTripsPageRouter } from "./CompletedTripsPageRouter";

@autobind
export class CompletedTripsPageStore extends PageStore<CompletedTripsPageRouter, {}> {
    @computed
    public get periodIndex(): number {
        // 0 = actual
        return this.pageRouter.periodIndex;
    }

    // This paramenter is used in driver app to show only earnings part of the page and hide completed trips
    @computed
    public get earningsOnly(): boolean {
        return this.pageRouter.earningsOnly;
    }

    @observable
    public isFetchingPeriod = false;

    @observable
    public isModalOpen = false;

    @observable
    // public dateFromCsv = new Date(new Date().getFullYear(), 0, 1, 0, 0, 0);
    public dateFromCsv = new Date(Date.UTC(new Date().getFullYear(), 0, 1));

    @observable
    public dateToCsv = new Date();

    @computed
    public get periodDateRange(): DateRange {
        return transformPayoutPeriodIndexToDateRange(this.periodIndex);
    }

    @action
    public previousPeriod(): void {
        this.changePeriod(this.periodIndex - 1);
    }

    @action
    public nextPeriod(): void {
        this.changePeriod(this.periodIndex + 1);
    }

    @action
    public setDateFromCsv(date: Date) {
        this.dateFromCsv = date;
    }

    @action
    public setDateToCsv(date: Date) {
        this.dateToCsv = date;
    }

    @action
    public validateDates() {
        if (this.dateToCsv.getTime() < this.dateFromCsv.getTime()) {
            return "Date to should be bigger than date from";
        }
    }

    @action
    public updateModal(value: boolean): void {
        this.isModalOpen = value;
    }

    @action
    public async changePeriod(index: number): Promise<void> {
        this.isFetchingPeriod = true;
        this.pageRouter.changePeriodIndex(index);
        this.pageRouter.updateQuery();

        await this.onFetchData();

        this.isFetchingPeriod = false;
    }

    @computed
    public get sumOfDriversPart(): number {
        return sumarizeAssignationDataForDriverValues("amountForDriver", this.assignationsDataForDriver);
    }

    @computed
    public get sumOfCashPayments(): number {
        return sumarizeAssignationDataForDriverValues("paidCash", this.assignationsDataForDriver);
    }

    @computed
    public get sumOfTotalPrice(): number {
        return sumarizeAssignationDataForDriverValues("totalPrice", this.sortedAndFilteredAssignationsDataForDriver);
    }

    @computed
    public get sumOfBalance(): number {
        return this.sumOfDriversPart - this.sumOfCashPayments;
    }

    @computed
    public get sumOfFee(): number {
        return sumarizeAssignationDataForDriverValues("fee", this.sortedAndFilteredAssignationsDataForDriver);
    }

    @observable
    public completedTripsCurrency: Currency;

    @action
    public async fetchCompletedTripsCurrency(): Promise<void> {
        const currency = await this.rpcClient.driver.getDriverCompletedTripsCurrency();
        this.completedTripsCurrency = currency;
    }

    @action
    public async fetchCsvReport(): Promise<void> {
        this.dateFromCsv.setUTCHours(0, 0, 0, 0);
        this.dateToCsv.setUTCHours(23, 59, 59, 999);
        const csvReport = await this.rpcClient.assignation.exportMyAssignationsDataToCSV({
            startDate: this.dateFromCsv,
            endDate: this.dateToCsv,
            statusIn: [AssignationStatus.Accepted, AssignationStatus.Declined, AssignationStatus.Cancelled],
        });
        const dataToCsvURI = encodeURI(`data:text/csv;charset=utf-8,${csvReport}`);
        window.open(dataToCsvURI, "_blank");
        this.isModalOpen = false;
    }

    // payouts

    @observable
    public payouts?: Array<Payout>;

    @action
    public async fetchPayouts(): Promise<void> {
        this.payouts = plainToClass(Payout, await this.rpcClient.payout.retrieveMyPayouts(this.periodIndex));
    }

    // driver debts

    @observable
    public driverDebts?: Array<DriverDebt>;

    @action
    public async fetchDriverDebts(): Promise<void> {
        this.driverDebts = await this.rpcClient.payout.retrieveMyDriverDebts(this.periodIndex);
    }

    @computed
    public get sumOfDebt(): number {
        if (this.driverDebts) {
            return this.driverDebts.reduce((prev: number, debt: DriverDebt) => prev + debt.amount, 0);
        }
        return 0;
    }

    // assignations

    @observable
    public assignationsDataForDriver?: Array<AssignationDataForDriver>;

    @computed
    public get sortedAndFilteredAssignationsDataForDriver(): Array<AssignationDataForDriver> | undefined {
        return this.assignationsDataForDriver
            ?.filter((a) => a.status === AssignationStatus.Accepted)
            .sort((a, b) => b.departureAt.getTime() - a.departureAt.getTime());
    }

    @action
    public async fetchAssignationsDataForDriver(): Promise<void> {
        this.assignationsDataForDriver = plainToClass(
            AssignationDataForDriver,
            await this.rpcClient.assignation.retrieveMyAssignationsData({
                periodIndex: this.periodIndex,
                statusIn: [AssignationStatus.Accepted, AssignationStatus.Declined, AssignationStatus.Cancelled],
            }),
        );
    }

    @action
    public async onFetchData(): Promise<void> {
        await Promise.all([
            this.fetchDriverDebts(),
            this.fetchPayouts(),
            this.fetchAssignationsDataForDriver(),
            this.fetchCompletedTripsCurrency(),
        ]);
    }

    public isDataFetched(): boolean {
        return !!this.assignationsDataForDriver;
    }
}
