import autobind from "autobind-decorator";
import { plainToClass } from "class-transformer";
import { action, observable } from "mobx";

import { ExtendedReferralCode } from "@legacy/domain/ExtendedReferralCode";
import { SimpleCustomer } from "@legacy/domain/SimpleCustomer";
import { SimpleOrderForReferralCode } from "@legacy/domain/SimpleOrderForReferralCode";
import { RetrieveReferralCodesOptions } from "@legacy/options/RetrieveReferralCodesOptions";
import { PageStore } from "../../stores/PageStore";

import { ReferralCodesPageRouter } from "./ReferralCodesPageRouter";
import { PaginatedDataStore } from "../../stores/PaginatedDataStore";

@autobind
export class ReferralCodesPageStore extends PageStore<ReferralCodesPageRouter, null> {
    @observable
    public referralCodes: PaginatedDataStore<RetrieveReferralCodesOptions, ExtendedReferralCode>;

    @observable
    public allReferrers: Array<SimpleCustomer> = [];

    @observable
    public allCustomers: Array<SimpleCustomer> = [];

    @action
    public async onFetchData() {}

    @action
    public async fetchAllReferrers() {
        this.allReferrers = await this.rpcClient.referral.retrieveReferrerUsers();
    }

    @action
    public async fetchAllCustomers() {
        this.allCustomers = await this.rpcClient.referral.retrieveCustomerUsers();
    }

    public onInit() {
        this.referralCodes = new PaginatedDataStore(
            ExtendedReferralCode,
            RetrieveReferralCodesOptions,
            async (options): Promise<Array<ExtendedReferralCode>> => {
                const codes = await this.rpcClient.referral.retrieveReferralCodes(options);
                return codes.map<ExtendedReferralCode>(
                    (doc) =>
                        ({
                            ...doc,
                            order:
                                doc.order &&
                                plainToClass<SimpleOrderForReferralCode, Record<string, any>>(
                                    SimpleOrderForReferralCode,
                                    doc.order,
                                ),
                            customer:
                                doc.customer &&
                                plainToClass<SimpleCustomer, Record<string, any>>(SimpleCustomer, doc.customer),
                            referrer: plainToClass<SimpleCustomer, Record<string, any>>(SimpleCustomer, doc.referrer!),
                        } as ExtendedReferralCode),
                );
            },
            this.rpcClient.referral.retrieveReferralCodesCount,
            this.pageRouter,
            "referralCodes_",
            {
                skip: 1,
                limit: 30,
                sortBy: "orderIds",
                sortDirection: -1,
                onlyUsed: true,
                code: undefined,
                referrerIds: [],
                customerIds: [],
                orderId: undefined,
            },
        );
    }
}

export interface ReferralCodesPageStoreDataFetched {
    referralCodes: Array<ExtendedReferralCode>;
}
