import { action, observable } from "mobx";

import { DriverCountryRulesPageRouter } from "./DriverCountryRulesRouter";

export class DriverCountryRuleFilterStore {
    private pageRouter: DriverCountryRulesPageRouter;

    @observable
    destinationCountryId?: string | undefined;

    @observable
    driverCountryId?: string | undefined;

    @observable
    originCountryId?: string | undefined;

    constructor(pageRouter: DriverCountryRulesPageRouter) {
        this.pageRouter = pageRouter;

        this.originCountryId = this.pageRouter.originCountryId;
        this.destinationCountryId = this.pageRouter.destinationCountryId;
        this.driverCountryId = this.pageRouter.driverCountryId;
    }

    @action setDriverCountryId(driverCountryId: string | undefined) {
        this.driverCountryId = driverCountryId;

        this.pageRouter.setDriverCountryId(driverCountryId);
    }

    @action setOriginCountryId(originCountryId: string | undefined) {
        this.originCountryId = originCountryId;

        this.pageRouter.setOriginCountryId(originCountryId);
    }

    @action setDestinationCountryId(destinationCountryId: string | undefined) {
        this.destinationCountryId = destinationCountryId;

        this.pageRouter.setDestinationCountryId(destinationCountryId);
    }
}
