import { CountryGroup } from "@legacy/domain/CountryGroup";

import { CountryGroupsStructure } from "../constants/countryGroupsStructure";
import { CountryGroupItem } from "../models/CountryGroupItem";

export function buildCountryGroupItems(): CountryGroupItem[] {
    function processGroup(groups: Record<CountryGroup, unknown>, parent?: CountryGroupItem): CountryGroupItem[] {
        return Object.keys(groups).flatMap((key) => {
            const countryGroupKey = Number(key) as CountryGroup;
            const subGroups = groups[countryGroupKey];

            let labelPrefix = "";
            if (parent) {
                labelPrefix = `${parent.label} - `;
            }

            const item: CountryGroupItem = {
                parent,
                key: countryGroupKey,
                label: `${labelPrefix}${CountryGroup[countryGroupKey] || "???"}`,
            };

            let subItems: CountryGroupItem[] = [];
            if (subGroups) {
                subItems = processGroup(subGroups as Record<CountryGroup, unknown>, item);
            }

            return [item, ...subItems];
        });
    }

    const allItems = processGroup(CountryGroupsStructure as Record<CountryGroup, unknown>);
    return allItems;
}
