import { statusGetter } from "@daytrip/legacy-models";
import { Exclude, Expose, Transform, Type } from "class-transformer";
import { IsDate, IsDefined, IsInt, IsNumber, IsString, IsUUID, ValidateIf } from "class-validator";
import { v4 as uuid } from "uuid";

import type { Chargeback } from "../models/Chargeback";

import { ChargebackStatus } from "./ChargebackStatus";
import { PaymentType } from "./PaymentType";
import { SimpleCustomer } from "./SimpleCustomer";

@Exclude()
export class RefundData {
    @IsUUID("4")
    @Expose()
    public _id: string = uuid();

    @IsUUID("4")
    @IsDefined()
    @ValidateIf((c: Chargeback) => c.orderId == undefined)
    @Expose()
    public paymentId?: string;

    @IsUUID("4")
    @IsDefined()
    @ValidateIf((c: Chargeback) => c.paymentId == undefined)
    @Expose()
    public orderId?: string;

    @IsNumber()
    @IsDefined()
    @Expose()
    public amount: number;

    @IsString()
    @IsDefined()
    @ValidateIf((p: Chargeback) => p.type == PaymentType.Mangopay || p.type == PaymentType.Paypal)
    @Expose()
    public gatewayId?: string;

    @IsInt()
    @IsDefined()
    @Expose()
    public type: PaymentType;

    @IsString()
    @IsDefined()
    @ValidateIf((c: Chargeback) => c.type == PaymentType.Other)
    @Expose()
    public description?: string;

    @IsDate()
    @IsDefined()
    @Expose()
    @Transform((value) => (value == undefined ? undefined : new Date(value)))
    @Type(() => Date)
    public createdAt: Date = new Date();

    @IsDate()
    @Expose()
    @Transform((value) => (value == undefined ? undefined : new Date(value)))
    public fulfilledAt?: Date;

    @IsDate()
    @Expose()
    @Transform((value) => (value == undefined ? undefined : new Date(value)))
    public failedAt?: Date;

    @Expose()
    @ValidateIf((c: Chargeback) => !!c.orderId)
    public customer?: SimpleCustomer;

    @IsUUID("4")
    @Expose()
    public createdBy: string;

    @Expose()
    @ValidateIf((c: Chargeback) => !!c.createdBy)
    public creator?: SimpleCustomer;

    public get status(): ChargebackStatus {
        return statusGetter(this, RefundData, ChargebackStatus);
    }

    public static statusPrototype(status: ChargebackStatus): RefundData {
        const defined = new Date();

        switch (status) {
            case ChargebackStatus.Failed:
                return {
                    failedAt: defined,
                } as RefundData;

            case ChargebackStatus.Fulfilled:
                return {
                    failedAt: undefined,
                    fulfilledAt: defined,
                } as RefundData;

            case ChargebackStatus.Pending:
            default:
                return {
                    failedAt: undefined,
                    fulfilledAt: undefined,
                } as RefundData;
        }
    }
}
