import autobind from "autobind-decorator";
import { observable } from "mobx";

import { PageStore } from "../../stores/PageStore";
import { CrossBorderWhitelistQueryOperationResult } from "../../types/generated/graphql-types";

import type { FlexibleOffersPageRouter } from "./FlexibleOffersPageRouter";

interface FlexibleOffersModules {}

@autobind
export class FlexibleOffersPageStore extends PageStore<FlexibleOffersPageRouter, FlexibleOffersModules> {
    @observable
    public addNewCrossBorderVisible: boolean = false;

    @observable
    public refreshCrossBorderTripWhitelist: () => void = () => {};

    @observable
    public crossBorderTripWhitelistData?: CrossBorderWhitelistQueryOperationResult;
}
