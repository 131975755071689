import type { ParameterlessConstructor } from "@legacy/utils";
import autobind from "autobind-decorator";
import { plainToClass } from "class-transformer";
import { action, observable } from "mobx";

type TFetchData<TModel> = () => Promise<undefined | TModel>;

@autobind
export class DataStore<TModel> {
    private dataConstructor?: ParameterlessConstructor<TModel>;

    private _fetchData: TFetchData<TModel>;

    public constructor(fetchData: TFetchData<TModel>, dataConstructor?: ParameterlessConstructor<TModel>) {
        this.dataConstructor = dataConstructor;
        this._fetchData = fetchData;

        if (!this.isFetched()) {
            this.fetch();
        }
    }

    // data

    @observable
    public data?: TModel;

    // data state

    @observable
    public isFetching = false;

    @observable
    public isFetchCompleted = false;

    @observable
    public isFetchFailed = false;

    // fetching

    @action
    public async fetch(): Promise<void> {
        this.isFetching = true;

        try {
            const data = await this._fetchData();
            if (data != undefined && this.dataConstructor != undefined) {
                this.data = plainToClass(this.dataConstructor, data);
            } else {
                this.data = data;
            }
        } catch (e: any) {
            alert(`Error fetching data: ${e}`);
            this.isFetchFailed = true;
        }

        this.isFetching = false;
    }

    public isFetched(): this is this & { data: TModel } {
        return this.data != undefined;
    }
}
