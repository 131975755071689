import { compareAsc, format, startOfDay } from "date-fns";

export const compareDatesByDate = (date1: Date, date2: Date): boolean =>
    compareAsc(startOfDay(date1), startOfDay(date2)) === 0;

export const compareLocalDateToUtcDate = (localDate: Date, utcDate: Date): boolean =>
    localDate.getDate() === utcDate.getUTCDate() &&
    localDate.getMonth() === utcDate.getUTCMonth() &&
    localDate.getFullYear() === utcDate.getUTCFullYear();

export const dateToUTCDate = (localDate: Date): Date =>
    new Date(Date.UTC(localDate.getFullYear(), localDate.getMonth(), localDate.getDate(), 0, 0, 0, 0));

export const formatYearAndMonth = (year: number, month: number): string => format(new Date(year, month), "LLLL yyyy");

export const getCurrentMonth = () => new Date().getMonth();

export const getCurrentYear = () => new Date().getFullYear();
