import type { TargetingLocation } from "./TargetingLocation";

export class CampaignTypeTargeting {
    public constructor(type: string, targetingLocations: Array<TargetingLocation>) {
        this.type = type;
        this.targetingLocations = targetingLocations;
    }

    public type: string;

    public targetingLocations: Array<TargetingLocation>;
}
