import { SimpleLocation } from "@legacy/domain/SimpleLocation";
import { Order } from "@daytrip/legacy-models";
import { RetrieveSimpleLocationsOptions } from "@legacy/options/RetrieveSimpleLocationsOptions";
import { describeLocationsDifference } from "@legacy/utils/describeLocationsDifference";

import { OrderOperator } from "../../../operators/OrderOperator";

export async function checkLocationsDifference(
    orderOperator: OrderOperator,
    originalOrder: Order,
    getSimpleLocations: (options: RetrieveSimpleLocationsOptions) => Promise<SimpleLocation[]>,
) {
    const contentLocations = orderOperator.contentLocationOperators.map((clo) => clo.m);
    const customLocations = orderOperator.customLocationOperators.map((clo) => clo.m);

    const locationsIds = [
        ...originalOrder.contentLocations.map((l) => l.locationId),
        ...contentLocations.map((l) => l.locationId),
    ];

    if (originalOrder) {
        locationsIds.push(originalOrder.originLocationId, originalOrder.destinationLocationId);
    }

    const simpleLocations = await getSimpleLocations({ ids: locationsIds });

    const locationsDifferenceMessage = describeLocationsDifference(
        {
            contentLocations: originalOrder.contentLocations,
            customLocations: originalOrder.customLocations,
        },
        {
            contentLocations,
            customLocations,
        },
        simpleLocations,
    );

    if (locationsDifferenceMessage) {
        // eslint-disable-next-line no-alert
        alert(locationsDifferenceMessage);
    }
}
