import type { TravelAgentCategory } from "@daytrip/legacy-enums";
import { Exclude, Expose, Transform } from "class-transformer";
import { IsArray, IsString } from "class-validator";

import type { TravelAgentStatus } from "../domain/TravelAgentStatus";
import type { TravelAgent } from "../models/TravelAgent";

import { SortingPaginationOptions } from "./SortingPaginationOptions";

@Exclude()
export class RetrieveTravelAgentsOptions extends SortingPaginationOptions<TravelAgent> {
    @IsArray()
    @Expose()
    @Transform((value) => (value == undefined ? undefined : (value as Array<typeof value>).map((item) => String(item))))
    public ids?: Array<string>;

    @IsString()
    @Expose()
    public search?: string;

    @IsArray()
    @Expose()
    @Transform((value) => (value == undefined ? undefined : (value as Array<typeof value>).map((item) => Number(item))))
    public statusIn?: Array<TravelAgentStatus>;

    @IsArray()
    @Expose()
    @Transform((value) => (value == undefined ? undefined : (value as Array<typeof value>).map((item) => String(item))))
    public ownerIds?: Array<string>;

    @IsArray()
    @Expose()
    @Transform((value) => (value == undefined ? undefined : (value as Array<typeof value>).map((item) => String(item))))
    public agentIds?: Array<string>;

    @IsArray()
    @Expose()
    @Transform((value) => {
        if (value == undefined) {
            return undefined;
        }
        if (!Array.isArray(value)) {
            value = [value];
        }
        return (value as Array<typeof value>).map((item) => String(item));
    })
    public hostAgencyIds?: Array<string>;

    @Expose()
    public hasPendingScheduledEmails?: boolean;

    @Expose()
    public isApproved?: boolean;

    @Expose()
    public countryIds?: Array<string>;

    @Expose()
    public includeTravelAgentsWithoutCountry?: boolean;

    @Expose()
    @Transform((value) => {
        if (value === "true") {
            return true;
        }
        if (value === "false") {
            return false;
        }
        return value;
    })
    public partnerBookingAvailable?: boolean;

    @Expose()
    public travelAgentCategory?: Array<TravelAgentCategory>;
}
