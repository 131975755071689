export function convertLocalToUTCDate<T extends Date | null | undefined>(date: T) {
    if (!date) {
        return date;
    }
    let dateToFormat = new Date(date);
    dateToFormat = new Date(
        Date.UTC(
            dateToFormat.getFullYear(),
            dateToFormat.getMonth(),
            dateToFormat.getDate(),
            dateToFormat.getHours(),
            dateToFormat.getMinutes(),
        ),
    );
    return dateToFormat;
}
