import { injectable } from "inversify";
import { observable } from "mobx";

import { CountryGroupItem } from "./models/CountryGroupItem";
import { buildCountryGroupItems } from "./utils/buildCountryGroupItems";

@injectable()
export class CountryGroupsStore {
    @observable countryGroups: CountryGroupItem[] = buildCountryGroupItems();
}
