import { plainToClass } from "class-transformer";

import { action, observable } from "mobx";

import { Tag } from "@daytrip/legacy-models";
import { TagOperator } from "../../operators/TagOperator";
import { PageStore } from "../../stores/PageStore";

import { globalManagementLogger } from "../../global-logger";
import { TagsPageRouter } from "./TagsPageRouter";

export class TagsPageStore extends PageStore<TagsPageRouter, {}> {
    @observable
    public tags?: Array<TagOperator>;

    @action
    public async onFetchData() {
        await this.fetchContent();
    }

    @action
    public async fetchContent() {
        let tags = await this.rpcClient.content.retrieveTags({});
        tags = tags.sort((a, b) => a.title.localeCompare(b.title));

        const getChildren: (parentId: string, level: number) => Array<TagOperator> = (
            parentId: string,
            level: number,
        ) =>
            tags
                .filter((t) => t.parentTagId === parentId)
                .map(
                    (cht) =>
                        new TagOperator({
                            children: getChildren(cht._id, level + 1),
                            level: level + 1,
                            isNew: false,
                            modelConstructor: Tag,
                            model: plainToClass(Tag, cht),
                            data: null,
                            modules: null,
                            onSave: async (model: Tag) => {
                                try {
                                    await this.rpcClient.content.updateTag(model._id, model);
                                } catch (e) {
                                    globalManagementLogger.error(e);
                                }
                            },
                            validateOptions: { skipMissingProperties: true },
                        }),
                );

        this.tags = tags
            .filter((t) => !t.parentTagId)
            .map(
                (t) =>
                    new TagOperator({
                        children: getChildren(t._id, 0),
                        level: 0,
                        isNew: false,
                        modelConstructor: Tag,
                        model: plainToClass(Tag, t),
                        data: null,
                        modules: null,
                        onSave: async (model: Tag) => {
                            try {
                                await this.rpcClient.content.updateTag(model._id, model);
                            } catch (e) {
                                globalManagementLogger.error(e);
                            }
                        },
                        validateOptions: { skipMissingProperties: true },
                    }),
            );
    }

    @action
    public addTopLevel(): void {
        const newTag = new TagOperator({
            children: [],
            level: 0,
            isNew: true,
            modelConstructor: Tag,
            model: plainToClass(Tag, new Tag()),
            data: null,
            modules: null,
            onSave: async (model: Tag) => {
                try {
                    const tagId = await this.rpcClient.content.createTag(model);
                    newTag.m._id = tagId;
                    newTag.isNew = false;
                } catch (e: any) {
                    globalManagementLogger.error(e);
                }
            },
        });

        newTag.edit(() => {});

        (this.tags as Array<TagOperator>).unshift(newTag);
    }

    public isDataFetched(): this is TagsPageStore & TagsPageStoreDataFetched {
        return !!this.tags;
    }
}

export interface TagsPageStoreDataFetched {
    tags: Array<TagOperator>;
}
