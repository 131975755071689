import type { AbstractTracer } from "@daytrip/tracer";
import { RejectionType } from "@daytrip/legacy-enums";
import type { UserJWT } from "@daytrip/legacy-models";
import type { ClientInfo } from "@daytrip/legacy-models";
import type { Container } from "inversify";

// :(

import type { AbstractNestLegacyInterop } from "./AbstractNestLegacyInterop";
import type { ContextOptions } from "./ContextOptions";
import type { ContextRequest } from "./ContextRequest";
import { ContextType } from "./ContextType";

export class Context<TUser, TUserJWT = UserJWT> {
    private _type: ContextType;

    public get type() {
        return this._type;
    }

    public user?: TUser;

    public userJWT?: TUserJWT;

    /**
     * Name of the client app, it should match the client type.
     */
    public appName?: string;

    public client?: ClientInfo;

    public lang?: string;

    public request?: ContextRequest;

    public tracer?: AbstractTracer;

    private _container?: Container;

    nestLegacyInterop?: AbstractNestLegacyInterop;

    public get container() {
        if (!this._container) {
            throw Error("Container is null");
        }
        return this._container;
    }

    public set container(value: Container) {
        this._container = value;
    }

    public constructor(type: ContextType, user?: TUser, options: ContextOptions<TUserJWT> = {}) {
        if (type === ContextType.Authenticated && user === undefined) {
            throw new TypeError();
        }
        this._type = type;
        this.user = user;
        this.request = options.request;
        this.userJWT = options.userJWT;
        this.appName = options.appName;
        this.client = options.client;
        this._container = options.container;

        if (this.request) {
            this.nestLegacyInterop = this.request.nestLegacyInterop;
        }
    }

    public authenticate(user?: TUser, userJWT?: TUserJWT, appName?: string) {
        this.appName = appName;
        this.user = user;
        this.userJWT = userJWT;
        this._type = ContextType.Authenticated;
    }

    public isAuthenticated(): this is {
        type: ContextType.Authenticated;
        user: TUser;
    } {
        return this._type === ContextType.Authenticated;
    }

    public isLocal() {
        return this._permissionChecked || this._type === ContextType.Local;
    }

    private _permissionChecked = false;

    /**
     * This fishy method is here in order to enable the legacy behavior where only the first service call is authorized and
     * all subsequent service calls are not checked for authorization anymore.
     *
     * This needs to be reviewed during refactoring and implementing new authorization system.
     */
    public setPermissionChecked() {
        this._permissionChecked = true;
    }

    public getAuthenticatedUserOrFail(): TUser {
        if (!this.user) {
            throw new Error(RejectionType.Forbidden);
        }

        return this.user;
    }

    /**
     * To be used in methods which could potentially be invoked into the cron runtime
     */
    public getAuthenticatedUser(): TUser | undefined {
        if (this.isLocal()) {
            return undefined;
        }

        return this.getAuthenticatedUserOrFail();
    }
}
