import { Tag } from "@daytrip/legacy-models";

export const getTagParents = (tag: Tag, tags: Tag[]): string[] => {
    const result = [tag.parentTagId as string];
    const parentTag = tags.find((t) => t._id === tag.parentTagId);
    if (parentTag) {
        result.push(...getTagParents(parentTag, tags));
    }
    return result;
};
