import {
    API_OPEN_VEHICLE_DOCUMENT_URL,
    API_OPEN_VEHICLE_IMAGE_URL,
    API_REPLACE_VEHICLE_IMAGE_URL,
    API_UPLOAD_VEHICLE_IMAGE_URL,
    MAX_FILE_UPLOAD_SIZE_IN_B,
    VEHICLE_MAX_IMAGES_COUNT,
} from "@daytrip/legacy-config";
import { DocumentSubject } from "@legacy/domain/DocumentSubject";
import type { FilePath } from "@legacy/domain/FilePath";
import { Document } from "@legacy/models/Document";
import { DocumentType } from "@legacy/models/DocumentType";
import { User } from "@legacy/models/User";
import { Vehicle } from "@legacy/models/Vehicle";
import { VehicleMake } from "@legacy/models/VehicleMake";
import { VehicleModel } from "@legacy/models/VehicleModel";
import autobind from "autobind-decorator";
import { classToPlain, plainToClass } from "class-transformer";
import { isUUID } from "class-validator";
import { action, computed, observable, reaction, toJS } from "mobx";
import { Option } from "react-select-legacy";
import { v4 as uuid } from "uuid";

import { globalManagementLogger } from "../../global-logger";
import { DocumentOperator } from "../../operators/DocumentOperator";
import { DocumentTypeOperator } from "../../operators/DocumentTypeOperator";
import { VehicleOperator } from "../../operators/VehicleOperator";
import { PageStore } from "../../stores/PageStore";

import type { VehiclesPageRouter } from "./VehiclesPageRouter";

@autobind
export class VehiclesPageStore extends PageStore<VehiclesPageRouter, {}> {
    @observable
    public user: User;

    @observable
    public vehicleMakes: Array<VehicleMake> = [];

    @observable
    public allVehicleMakes: Array<VehicleMake> = [];

    @observable
    public vehicleModels: Array<VehicleModel> = [];

    @observable
    public allVehicleModels: Array<VehicleModel> = [];

    @observable
    public editedVehicle?: VehicleOperator;

    @observable
    public vehicles: Array<VehicleOperator>;

    @observable
    public authenticationToken: string;

    @computed
    public get isOwner(): boolean {
        return this.authenticationStore.userJWT?.userId === this.user._id;
    }

    @action
    public async onFetchData() {
        let { userId } = this.pageRouter;
        const { userEmail } = this.pageRouter;
        if (this.authenticationStore.isDriver || this.authenticationStore.isDriversCompany) {
            userId = this.authenticationStore.userJWT?.userId;
        }

        try {
            if (userId) {
                this.user = plainToClass(User, await this.rpcClient.user.retrieveUser(userId));
            } else if (userEmail) {
                this.user = plainToClass(User, await this.rpcClient.user.retrieveUserByEmail(userEmail));
            }
        } catch (e: any) {
            return globalManagementLogger.error(e);
        }

        await this.fetchContent();
    }

    @action
    public async fetchContent() {
        [this.vehicleMakes, this.vehicleModels] = await Promise.all([
            this.rpcClient.vehicle.retrieveVehicleMakes(),
            this.rpcClient.vehicle.retrieveVehicleModels({}),
        ]);

        this.allVehicleModels = this.vehicleModels;
        this.allVehicleMakes = this.vehicleMakes;
        if (this.user.countryId) {
            this.vehicleModels = this.vehicleModels.filter((model) =>
                model.approvedForCountryIds.includes(this.user.countryId as string),
            );

            this.vehicleMakes = this.vehicleMakes.filter((make) => {
                const models = (this.vehicleModels as Array<VehicleModel>).filter((model) => model.makeId === make._id);

                return models.length > 0;
            });
        }

        await this.fetchVehiclesAndDocuments();

        this.authenticationToken = this.authenticationStore.authenticationToken;
    }

    public isDataFetched(): this is VehiclesPageStore & VehiclesPageStoreDataFetched {
        return (
            this.vehicleMakes != undefined &&
            this.vehicleModels != undefined &&
            this.vehicles != undefined &&
            this.vehicles.reduce((isFetched: boolean, p) => {
                if (isFetched && p.isDataFetched()) {
                    return true;
                }

                return false;
            }, true)
        );
    }

    public countryIdReaction = reaction(
        () => (this.user != undefined ? this.user.countryId : undefined),
        (countryId) => {
            if (countryId != undefined) {
                this.fetchVehiclesAndDocuments();
            }
        },
    );

    @action
    public async fetchVehiclesAndDocuments() {
        if (this.user != undefined) {
            const vehicleTypes = {};
            let vehicles = plainToClass(Vehicle, await this.rpcClient.vehicle.retrieveVehiclesByUserId(this.user._id));

            const collectVehicleType = (vehicle: Vehicle) => {
                const vehicleModel =
                    this.allVehicleModels &&
                    ((this.allVehicleModels as Array<VehicleModel>).find(
                        (vm) => vehicle.modelId === vm._id,
                    ) as VehicleModel);

                const vehicleMake =
                    this.allVehicleMakes &&
                    ((this.allVehicleMakes as Array<VehicleMake>).find(
                        (vmk) => !!vehicleModel && vehicleModel.makeId === vmk._id,
                    ) as VehicleMake);

                vehicleTypes[vehicle._id] = { vehicleModel, vehicleMake };
            };
            const isVehicleValid = (vehicle: Vehicle) =>
                !!(vehicleTypes[vehicle._id].vehicleModel && vehicleTypes[vehicle._id].vehicleMake);

            // remove deleted and invalid vehicles from the list
            vehicles = vehicles.filter((vehicle) => {
                collectVehicleType(vehicle);

                return !vehicle.deletedAt && isVehicleValid(vehicle);
            });

            const documentTypesForVehicle = plainToClass(
                DocumentType,
                await this.rpcClient.driver.retrieveDocumentTypes({
                    countryIds: [this.user.countryId as string],
                    subject: DocumentSubject.Vehicle,
                }),
            );

            this.vehicles = observable(
                await Promise.all(
                    vehicles.map(
                        async (vehicle) =>
                            new VehicleOperator({
                                modelConstructor: Vehicle,
                                model: vehicle,
                                documentTypes: documentTypesForVehicle.map((documentType) => {
                                    const document = vehicle.documents.find(
                                        (d) => d.documentTypeId == documentType._id,
                                    );

                                    return new DocumentTypeOperator({
                                        modelConstructor: DocumentType,
                                        model: documentType,
                                        documentOperator:
                                            document != undefined
                                                ? new DocumentOperator({
                                                      modelConstructor: Document,
                                                      model: document,
                                                      data: null,
                                                      modules: null,
                                                  })
                                                : undefined,
                                        data: null,
                                        modules: null,
                                        userId: this.user._id,
                                        vehicleId: vehicle._id,
                                        onVehicleDocumentUploaded: async () => {
                                            await this.fetchData();
                                            await this.fetchVehiclesAndDocuments();
                                        },
                                    });
                                }),
                                data: vehicleTypes[vehicle._id],
                                modules: null,
                                isDataFetchedCondition: (operator: VehicleOperator) =>
                                    operator.model != undefined &&
                                    operator.data.vehicleMake != undefined &&
                                    operator.data.vehicleModel != undefined,
                                onSave: async (v: Vehicle) => {
                                    try {
                                        await this.rpcClient.vehicle.updateVehicle(v._id, v);
                                    } catch (e) {
                                        alert("Not saved.");
                                        globalManagementLogger.error(e);
                                    }
                                },
                                validateOptions: { skipMissingProperties: true },
                            }),
                    ),
                ),
            );
        } else {
            this.vehicles = [];
        }
    }

    // ui

    @observable
    public isAddingNewVehicle: boolean = false;

    @action
    public onAddNewVehicle(): void {
        this.isAddingNewVehicle = true;
    }

    @action
    public onDismissVehicleCreation(): void {
        this.isAddingNewVehicle = false;
        this.vehicleCreationMake = undefined;
        this.vehicleCreationModel = undefined;
        this.vehicleCreationPassengers = undefined;
    }

    @observable
    public lightboxDocuments?: Array<FilePath>;

    @observable
    public lightboxInitialIndex: number = 0;

    @observable
    public lightboxDescription: string | undefined;

    @action
    public async revealLightbox(fileIds: Array<string>, title: string, index: number = 0, isImage: boolean = false) {
        this.lightboxInitialIndex = index;
        this.lightboxDocuments = await this.rpcClient.driver.retrieveFilesPaths(
            fileIds,
            isImage ? API_OPEN_VEHICLE_IMAGE_URL : API_OPEN_VEHICLE_DOCUMENT_URL,
        );
        this.lightboxDocuments = this.lightboxDocuments?.sort((a, b) => a.url.localeCompare(b.url));
        this.lightboxDescription = title;
    }

    @observable
    public imageLightboxUrl: string | undefined;

    @action
    public revealImageLightbox(imageUrl: string | undefined, description: string | undefined) {
        this.imageLightboxUrl = imageUrl;
        this.lightboxDescription = description;
    }

    @action
    public closeLightbox() {
        this.lightboxDocuments = undefined;
        this.lightboxInitialIndex = 0;
        this.lightboxDescription = undefined;
        this.imageLightboxUrl = undefined;
    }

    @observable
    public vehiclePhotoUploadingId: string | null = null;

    @action
    public startVehiclePhotoUpload(vehicleId: string) {
        this.vehiclePhotoUploadingId = vehicleId;
    }

    @action
    public isVehiclePhotoUploaded() {
        this.vehiclePhotoUploadingId = null;
    }

    // vehicle creation

    @observable
    public vehicleCreationMake?: Option;

    @observable
    public vehicleCreationModel?: Option;

    @observable
    public vehicleCreationPassengers?: number;

    @observable
    public newVehicleCreationDiabled: boolean = false;

    @action
    public onChangeCreatingVehicleMake(value: Option) {
        this.vehicleCreationMake = value;
        this.vehicleCreationModel = undefined;
        this.vehicleCreationPassengers = undefined;
    }

    @action
    public onChangeCreatingVehicleModel(value: Option) {
        this.newVehicleCreationDiabled = false;
        this.vehicleCreationModel = value;

        const vehicleModel = (this.vehicleModels as Array<VehicleModel>).find(
            (vm) => vm._id == value.value,
        ) as VehicleModel;
        if (vehicleModel != undefined && vehicleModel.disabledAt != undefined) {
            alert("We are sorry, but you can't drive with this car.");
            this.newVehicleCreationDiabled = true;
        }
    }

    @action
    public onChangePassengers(value: number) {
        this.vehicleCreationPassengers = value;
    }

    @observable
    public newVehicleMake?: VehicleMake;

    @observable
    public newVehicleModel?: VehicleModel;

    @action
    public async addNewVehicle() {
        const vModel = (this.vehicleModels as Array<VehicleModel>).find(
            (vm) => vm._id == (this.vehicleCreationModel as Option).value,
        ) as VehicleModel;
        if (vModel != undefined && vModel.disabledAt != undefined) {
            return;
        }

        if (isUUID((this.vehicleCreationMake as Option).value as string, "4") && this.vehicleMakes != undefined) {
            this.newVehicleMake = this.vehicleMakes.find(
                (vm) => vm._id == (this.vehicleCreationMake as Option).value,
            ) as VehicleMake;
        }
        if (isUUID((this.vehicleCreationModel as Option).value as string, "4") && this.vehicleModels != undefined) {
            this.newVehicleModel = this.vehicleModels.find(
                (vm) => vm._id == (this.vehicleCreationModel as Option).value,
            ) as VehicleModel;
        }
        if (!isUUID((this.vehicleCreationMake as Option).value as string, "4")) {
            this.newVehicleMake = new VehicleMake();
            this.newVehicleMake.name = toJS((this.vehicleCreationMake as Option).label) as string;
        }
        if (!isUUID((this.vehicleCreationModel as Option).value as string, "4")) {
            this.newVehicleModel = new VehicleModel();
            this.newVehicleModel.makeId = (this.newVehicleMake as VehicleMake)._id;
            this.newVehicleModel.name = toJS((this.vehicleCreationModel as Option).label) as string;
            this.newVehicleModel.seatsCount = this.vehicleCreationPassengers as number;
        }

        const newVehicle = classToPlain(new Vehicle()) as Vehicle;
        newVehicle.ownerUserId = this.user._id;

        const vehicleMake =
            (this.vehicleMakes as Array<VehicleMake>).find(
                (vm) => vm._id == (this.newVehicleMake as VehicleMake)._id,
            ) == undefined
                ? toJS(this.newVehicleMake)
                : undefined;
        const vehicleModel =
            (this.vehicleModels as Array<VehicleModel>).find(
                (vm) => vm._id == (this.newVehicleModel as VehicleModel)._id,
            ) == undefined
                ? toJS(this.newVehicleModel)
                : undefined;

        if (vehicleModel == undefined) {
            newVehicle.modelId = (this.newVehicleModel as VehicleModel)._id;
        } else {
            newVehicle.modelId = uuid();
        }

        const newVehicleOperator = new VehicleOperator({
            documentTypes: [],
            modelConstructor: Vehicle,
            model: plainToClass(Vehicle, newVehicle),
            data: {
                vehicleMake: this.newVehicleMake,
                vehicleModel: this.newVehicleModel,
            },
            modules: null,
            isDataFetchedCondition: (operator: VehicleOperator) =>
                operator.model != undefined &&
                operator.data.vehicleMake != undefined &&
                operator.data.vehicleModel != undefined,
            onSave: async (v: Vehicle) => {
                try {
                    await this.rpcClient.vehicle.createVehicle(v, vehicleModel, vehicleMake);

                    if (vehicleModel != undefined) {
                        this.vehicleModels = await this.rpcClient.vehicle.retrieveVehicleModels({});
                    }
                    if (vehicleMake != undefined) {
                        this.vehicleMakes = await this.rpcClient.vehicle.retrieveVehicleMakes();
                    }

                    this.fetchVehiclesAndDocuments();

                    this.vehicleCreationMake = undefined;
                    this.vehicleCreationModel = undefined;
                    this.vehicleCreationPassengers = undefined;
                } catch (e: any) {
                    globalManagementLogger.error(e);
                }
            },
            validateOptions: { skipMissingProperties: true },
        });

        newVehicleOperator.isNew = true;
        newVehicleOperator.edit(() => {});
        this.isAddingNewVehicle = false;

        this.vehicles.push(newVehicleOperator);
    }

    @action
    public cancelCreation(): void {
        this.fetchVehiclesAndDocuments();

        this.newVehicleMake = undefined;
        this.newVehicleModel = undefined;
        this.vehicleCreationMake = undefined;
        this.vehicleCreationModel = undefined;
        this.vehicleCreationPassengers = undefined;
    }

    @observable
    public uploadingPhotoVehicleId = "";

    @action
    public async vehicleImagesUpload(vehicleId: string, files: FileList) {
        const driverProfileImageFormData: FormData = new FormData();
        const currentVehicle = this.vehicles.find((vehicle) => vehicle.m._id === vehicleId);
        const vehicleImagesNumber = currentVehicle?.m.photoIds.length;
        if (files.length > VEHICLE_MAX_IMAGES_COUNT) {
            alert(`You can\u0027t upload more than ${VEHICLE_MAX_IMAGES_COUNT} images. Please try again.`);
            return;
        }
        if (vehicleImagesNumber && files.length + vehicleImagesNumber > VEHICLE_MAX_IMAGES_COUNT) {
            alert(`You can\u0027t have more than ${VEHICLE_MAX_IMAGES_COUNT} images. Please try again.`);
            return;
        }
        for (let index = 0; index < files.length; index++) {
            if (files.item(index)!.size > MAX_FILE_UPLOAD_SIZE_IN_B) {
                alert("The image can\u0027t be larger than 5 MB. Please try again.");
                return;
            }
            driverProfileImageFormData.append("files", files.item(index)!);
        }

        await this.rpcClient.authentication.isUserAuthenticated();
        const { authenticationToken } = this.authenticationStore;
        driverProfileImageFormData.append("vehicleId", vehicleId);

        this.uploadingPhotoVehicleId = vehicleId;
        const request = new XMLHttpRequest();
        request.open("POST", API_UPLOAD_VEHICLE_IMAGE_URL, true);
        request.onload = () => {
            if (request.status === 200 || request.status === 201) {
                this.onVehicleImagesUploadComplete();
            } else {
                alert(
                    "Can\u0027t upload. Please try again or contact administrator.\n\nIf you're using Os X Photos app, you need to export the photo before uploading it.",
                );
            }
        };
        request.setRequestHeader("Authorization", `Bearer ${authenticationToken}`);
        request.send(driverProfileImageFormData);
    }

    @observable
    public replacingPhotoVehicleId = "";

    @action
    public async vehicleImagesReplace(vehicleId: string, files: FileList) {
        const driverProfileImageFormData: FormData = new FormData();
        if (files.length > VEHICLE_MAX_IMAGES_COUNT) {
            alert(`You can\u0027t upload more than ${VEHICLE_MAX_IMAGES_COUNT} images. Please try again.`);
            return;
        }
        for (let index = 0; index < files.length; index++) {
            if (files.item(index)!.size > MAX_FILE_UPLOAD_SIZE_IN_B) {
                alert("The image can\u0027t be larger than 5 MB. Please try again.");
                return;
            }
            driverProfileImageFormData.append("files", files.item(index)!);
        }

        await this.rpcClient.authentication.isUserAuthenticated();
        const { authenticationToken } = this.authenticationStore;
        driverProfileImageFormData.append("vehicleId", vehicleId);

        this.replacingPhotoVehicleId = vehicleId;
        const request = new XMLHttpRequest();
        request.open("POST", API_REPLACE_VEHICLE_IMAGE_URL, true);
        request.onload = () => {
            if (request.status === 200 || request.status === 201) {
                this.onVehicleImagesReplaceComplete();
            } else {
                alert(
                    "Can\u0027t upload. Please try again or contact administrator.\n\nIf you're using Os X Photos app, you need to export the photo before uploading it.",
                );
            }
        };
        request.setRequestHeader("Authorization", `Bearer ${authenticationToken}`);
        request.send(driverProfileImageFormData);
    }

    @action
    public async onVehicleImagesUploadComplete() {
        const v = await this.rpcClient.vehicle.retrieveVehicle(this.uploadingPhotoVehicleId);

        const updatingVehicleOperator = this.vehicles.find(
            (vo) => vo.m._id == this.uploadingPhotoVehicleId,
        ) as VehicleOperator;
        updatingVehicleOperator.model.photoIds = v.photoIds;

        if (updatingVehicleOperator.isEdited()) {
            updatingVehicleOperator.edit((m) => (m.photoIds = v.photoIds));
        }

        this.uploadingPhotoVehicleId = "";
    }

    @action
    public async onVehicleImagesReplaceComplete() {
        const v = await this.rpcClient.vehicle.retrieveVehicle(this.replacingPhotoVehicleId);

        const replacingVehicleOperator = this.vehicles.find(
            (vo) => vo.m._id == this.replacingPhotoVehicleId,
        ) as VehicleOperator;
        replacingVehicleOperator.model.photoIds = v.photoIds;

        if (replacingVehicleOperator.isEdited()) {
            replacingVehicleOperator.edit((m) => (m.photoIds = v.photoIds));
        }

        this.replacingPhotoVehicleId = "";
    }
}

export interface VehiclesPageStoreDataFetched {
    user: User;
    vehicleMakes: Array<VehicleMake>;
    vehicleModel: Array<VehicleModel>;
    vehicles: Array<VehicleOperator>;
}
