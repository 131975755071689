import { SENTRY_ENABLED } from "@daytrip/legacy-config";
import type { Logger } from "@daytrip/logger";

import type { Context } from "../../../../legacy/source/Context";
// DON'T replace the absolute import with aliases. management is complaining :/

interface SpanDecoratorOptions {
    /**
     * Set this to `false` to disable attaching method parameters to the span.
     */
    attachParametersAsData: boolean;
}

interface InstanceType {
    logger?: Logger;
    context?: Context<any, never>;
}

export function isThenable(thing: any) {
    return typeof thing?.then === "function";
}

export function Span(options: SpanDecoratorOptions = { attachParametersAsData: true }): MethodDecorator {
    return function (
        target: Object,
        propertyKey: string | symbol,
        descriptor: TypedPropertyDescriptor<any>,
    ): TypedPropertyDescriptor<any> | void {
        if (SENTRY_ENABLED) {
            const originalMethod = descriptor.value;
            descriptor.value = function (this: InstanceType, ...methodArgs: any[]) {
                // TODO: very ugly, improve typings such that we end up with `this.context.tracer`. The tracer is available on context in runtime
                const contextTracer = this.context?.nestLegacyInterop?.legacyContext.tracer;
                if (!contextTracer) {
                    //                    const logger =
                    //                        this.logger ??
                    //                        new Logger({
                    //                            app: "api",
                    //                            cid: "no-correlation",
                    //                            env: ENVIRONMENT,
                    //                        });
                    // this log line was never used in production
                    //                    logger.warn({
                    //                        message: "no tracer on context",
                    //                        payload: {
                    //                            ctx: this.context,
                    //                            target,
                    //                            propertyKey,
                    //                            descriptor,
                    //                            originalMethod,
                    //                        },
                    //                    });

                    return originalMethod.apply(this, methodArgs);
                }

                // TODO: This shouldn't ever happen, but the mechanism of how/when this happens in not obvious
                // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
                const tracer = this.context?.nestLegacyInterop?.legacyContext.tracer!;
                const maybeUser = this.context?.user;

                const data: Record<string, any> = {};
                if (options.attachParametersAsData) {
                    let methodArgsStringified: string | any;
                    try {
                        methodArgsStringified = JSON.stringify(methodArgs, null, 2);
                    } catch (err) {
                        this.logger?.warn(`There was an error JSON parsing ${methodArgs}`);
                    }

                    data.methodArgs = methodArgsStringified;
                }

                if (maybeUser) {
                    data.authenticatedUser = {
                        _id: maybeUser._id,
                        email: maybeUser.email,
                    };
                }

                const span = tracer.startSpan({
                    description: propertyKey as string,
                    op: target.constructor.name,
                    data,
                });

                try {
                    const originalResult = originalMethod.apply(this, methodArgs);
                    if (isThenable(originalResult)) {
                        return originalResult.finally(() => {
                            span.finish();
                        });
                    } else {
                        span.finish();
                    }
                    return originalResult;
                } catch (err: any) {
                    span.finish();
                    throw err;
                }
            };

            return descriptor;
        }
    };
}
