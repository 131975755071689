import { CancellationInfo } from "@daytrip/legacy-models";
import type { MeetingPosition } from "@daytrip/legacy-models";
import { Exclude, Expose, Type } from "class-transformer";
import {
    IsArray,
    IsBoolean,
    IsDate,
    IsDefined,
    IsInt,
    IsString,
    IsUUID,
    ValidateNested,
    IsEnum,
    IsOptional,
} from "class-validator";
import { v4 as uuid } from "uuid";

import { AssignationAutomaticEmail } from "../models/AssignationAutomaticEmail";

import { AnonymizedOrderForDriver } from "./AnonymizedOrderForDriver";
import { AssignationStatus } from "./AssignationStatus";
import { Currency } from "./Currency";
import type { VehicleInfo } from "./VehicleInfo";
import { VehicleType } from "./VehicleType";

@Exclude()
export class AssignationWithAnonymizedOrder {
    @IsUUID("4")
    @IsDefined()
    @Expose()
    public _id: string = uuid();

    @IsDefined()
    @Expose()
    @IsString()
    public driverId: string;

    @IsDefined()
    @Expose()
    @IsString()
    public driverName?: string;

    @IsDefined()
    @Expose()
    @IsBoolean()
    public forCompany?: boolean = false;

    @IsDefined()
    @Expose()
    @Type(() => Date)
    public createdAt: Date = new Date();

    @IsInt()
    @IsDefined()
    @Expose()
    public vehicleType: VehicleType;

    @IsInt()
    @IsDefined()
    @Expose()
    public vehicleIndex: number;

    // The Assignation is accepted: if the vehicle is undefined, acceptation note is defined and contrariwise
    @IsUUID("4")
    @Expose()
    public vehicleId?: string;

    @IsString()
    @Expose()
    public acceptationNote?: string;

    @Expose()
    public meetingPosition?: Array<MeetingPosition>;

    @IsArray()
    @IsDefined()
    @Expose()
    @Type(() => String)
    public subsidyIds: Array<string> = [];

    @IsArray()
    @IsDefined()
    @Expose()
    @Type(() => String)
    public compensationIds: Array<string> = [];

    @IsArray()
    @IsDefined()
    @Expose()
    @Type(() => String)
    public penaltyIds: Array<string> = [];

    @IsDate()
    @Expose()
    public acceptedAt?: Date;

    @IsDate()
    @Expose()
    public declinedAt?: Date;

    @IsDate()
    @Expose()
    public cancelledAt?: Date;

    @IsString()
    @Expose()
    public reason?: string;

    @Expose()
    public cancellationInfo?: CancellationInfo;

    @IsUUID("4")
    @Expose()
    public payoutId?: string;

    @IsArray()
    @IsDefined()
    @ValidateNested()
    @Expose()
    @Type(() => AssignationAutomaticEmail)
    public automaticEmails: Array<AssignationAutomaticEmail> = [];

    @Expose()
    @IsInt()
    public price: number = 0;

    @Expose()
    @IsInt()
    public fee: number = 0;

    @IsEnum(Currency)
    @IsDefined()
    @Expose()
    public pricingCurrency: Currency = Currency.Euro;

    @Expose()
    @IsInt()
    public paidCash: number = 0;

    @Expose()
    @IsArray()
    public userVehicles: Array<VehicleInfo>;

    @Expose()
    public order: AnonymizedOrderForDriver;

    @Expose()
    public version: number = 0;

    @IsUUID("4")
    @Expose()
    @IsOptional()
    public rideId?: string;

    @IsUUID("4")
    @Expose()
    @IsOptional()
    public comboAssignationOfferId?: string;

    public get status(): AssignationStatus {
        if (this.cancelledAt !== undefined) {
            return AssignationStatus.Cancelled;
        }

        if (this.declinedAt !== undefined) {
            return AssignationStatus.Declined;
        }

        if (this.acceptedAt !== undefined) {
            return AssignationStatus.Accepted;
        }

        return AssignationStatus.Pending;
    }
}
