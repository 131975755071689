import { Discount } from "@legacy/models/Discount";
import { observable, action } from "mobx";

import { ModelOperator } from "./ModelOperator";
import { ModelOperatorOptions } from "./ModelOperatorOptions";

interface DiscountOperatorOptions extends ModelOperatorOptions<Discount, {}, {}> {}

export class DiscountOperator extends ModelOperator<Discount, DiscountOperatorOptions, {}, {}, {}> {
    @observable
    public percentageCoefficient: number = 0.05; // use most common discount coefficient as default

    @action
    public calculateFeeDiscount(totalPrice: number): number {
        return Math.round(totalPrice * this.percentageCoefficient);
    }
}
