import { observer } from "mobx-react";
import React, { useEffect, useMemo } from "react";

import { getManagementAuthenticationToken } from "../../utils/authentication/authenticationUtils";

import { AuthenticationStoreContext } from "./AuthenticationStore.context";
import { getAuthenticationStore } from "./AuthenticationStore.singleton";

export const AuthenticationStoreProvider: React.FC<React.PropsWithChildren<unknown>> = observer(({ children }) => {
    const authenticationStore = useMemo(() => getAuthenticationStore(), []);

    useEffect(() => {
        authenticationStore.setAuthenticationToken(getManagementAuthenticationToken());
    }, []);

    return (
        <AuthenticationStoreContext.Provider value={authenticationStore}>
            {children}
        </AuthenticationStoreContext.Provider>
    );
});
