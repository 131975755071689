import { statusGetter } from "@daytrip/legacy-models";
import { TransformToDate } from "@daytrip/legacy-transformers";
import { Expose, Transform, Type } from "class-transformer";
import {
    IsBoolean,
    IsDate,
    IsDefined,
    IsInt,
    IsString,
    IsUUID,
    ValidateIf,
    IsEnum,
    IsNumber,
    IsOptional,
    IsObject,
} from "class-validator";
import { v4 as uuid } from "uuid";

import { Currency } from "../domain/Currency";
import { PaymentStatus } from "../domain/PaymentStatus";
import { PaymentType } from "../domain/PaymentType";
import { GField, GInt, GObjectType } from "../graphql";
import { CDBaseEntity, CDColumn, CDCreateDateColumn, CDEntity, CDObjectIdColumn } from "../orm";

import { NethoneDetails } from "./NethoneDetails";
import { PaymentMangopayInformation } from "./PaymentMangopayInformation";

@GObjectType("Payment")
@CDEntity("payments")
export class Payment extends CDBaseEntity {
    @CDObjectIdColumn()
    @IsUUID("4")
    @Expose()
    public _id: string = uuid();

    @CDCreateDateColumn()
    @IsDate()
    @Expose()
    @TransformToDate
    public createdAt: Date = new Date();

    @CDColumn()
    @IsUUID("4")
    @IsDefined()
    @Expose()
    public userId: string;

    @CDColumn()
    @IsNumber()
    @IsDefined()
    @Expose()
    public amount: number;

    @CDColumn()
    @IsEnum(Currency)
    @IsDefined()
    @Expose()
    public currency = Currency.Euro;

    @CDColumn()
    @IsInt()
    @IsDefined()
    @Expose()
    public type: PaymentType;

    @CDColumn()
    @IsString()
    @IsDefined()
    @ValidateIf((p: Payment) => p.type === PaymentType.Other)
    @Expose()
    public description?: string;

    @CDColumn()
    @IsString()
    @Expose()
    public gatewayId?: string;

    @CDColumn()
    @IsString()
    @ValidateIf((p: Payment) => p.type === PaymentType.Mangopay)
    @Expose()
    public cardholderName?: string;

    @CDColumn()
    @IsString()
    @ValidateIf((p: Payment) => p.type === PaymentType.Mangopay)
    @Expose()
    public cardAlias?: string;

    @CDColumn()
    @IsString()
    @ValidateIf((p: Payment) => p.type === PaymentType.Mangopay)
    @Expose()
    public cardCountryId?: string;

    @CDColumn()
    @IsUUID("4")
    @Expose()
    @IsDefined()
    @ValidateIf((p) => p.paymentRequestId == null && p.poolOfferId == null)
    @Transform((value) => (value == null ? undefined : value))
    public orderId?: string;

    @CDColumn()
    @IsUUID("4")
    @Expose()
    @IsDefined()
    @ValidateIf((p) => p.orderId == null && p.poolOfferId == null)
    @Transform((value) => (value == null ? undefined : value))
    public paymentRequestId?: string;

    @CDColumn()
    @IsUUID("4")
    @Expose()
    @IsDefined()
    @ValidateIf((p) => p.orderId == null && p.paymentRequestId == null)
    @Transform((value) => (value == null ? undefined : value))
    public poolOfferId?: string;

    @CDColumn()
    @IsDate()
    @Expose()
    @TransformToDate
    public fulfilledAt?: Date;

    @CDColumn()
    @IsDate()
    @Expose()
    @TransformToDate
    public chargedBackAt?: Date;

    @CDColumn()
    @IsDate()
    @Expose()
    @TransformToDate
    public failedAt?: Date;

    @CDColumn()
    @IsBoolean()
    @IsDefined()
    @Expose()
    public isPreparedToPayout: boolean = false;

    @CDColumn()
    @IsBoolean()
    @IsDefined()
    @Expose()
    public isPaidOut: boolean = false;

    @CDColumn()
    @IsBoolean()
    @IsDefined()
    @Expose()
    public isRecord: boolean = false;

    @CDColumn()
    @Expose()
    @Type(() => PaymentMangopayInformation)
    public mangopay?: PaymentMangopayInformation;

    @CDColumn()
    @IsString()
    @IsOptional()
    @Expose()
    public setupIntentId?: string; // only for stripe payments

    @CDColumn()
    @IsString()
    @IsOptional()
    @Expose()
    public instrumentId?: string; // only for checkout payments

    @CDColumn()
    @IsDate()
    @Expose()
    @IsOptional()
    @TransformToDate
    public preAuthorizedAt?: Date;

    @CDColumn()
    @IsDate()
    @Expose()
    @IsOptional()
    @TransformToDate
    public uncapturedAt?: Date;

    @CDColumn()
    @IsDate()
    @Expose()
    @IsOptional()
    @TransformToDate
    public cancelledAt?: Date;

    @CDColumn()
    @IsBoolean()
    @Expose()
    @IsOptional()
    public isManuallyFailed?: boolean;

    @CDColumn()
    @IsBoolean()
    @Expose()
    @IsOptional()
    public isApplePay?: boolean;

    @CDColumn()
    @IsBoolean()
    @Expose()
    @IsOptional()
    public isGooglePay?: boolean;

    // TODO: remove checkoutNewPlatformPayment once old platform is stopped
    @CDColumn()
    @IsBoolean()
    @Expose()
    @IsOptional()
    public checkoutNewPlatformPayment?: boolean;

    @CDColumn()
    @IsObject()
    @Expose()
    @IsOptional()
    public nethone?: NethoneDetails;

    @CDColumn()
    @IsString()
    @Expose()
    @IsOptional()
    public countryISOOfResidence?: string;

    @GField(() => GInt)
    public get status(): PaymentStatus {
        return statusGetter(this, Payment, PaymentStatus);
    }

    public static statusPrototype(status: PaymentStatus): Payment {
        const defined = new Date();

        switch (status) {
            case PaymentStatus.Failed:
                return {
                    failedAt: defined,
                } as Payment;
            case PaymentStatus.Cancelled:
                return {
                    cancelledAt: defined,
                } as Payment;

            case PaymentStatus.Fulfilled:
                return {
                    failedAt: undefined,
                    fulfilledAt: defined,
                    chargedBackAt: undefined,
                } as Payment;

            case PaymentStatus.ChargedBack:
                return {
                    failedAt: undefined,
                    fulfilledAt: defined,
                    chargedBackAt: defined,
                } as Payment;
            case PaymentStatus.PreAuthorized:
                return {
                    preAuthorizedAt: defined,
                    fulfilledAt: undefined,
                    failedAt: undefined,
                    cancelledAt: undefined,
                    chargedBackAt: undefined,
                } as Payment;
            case PaymentStatus.Uncaptured:
                return {
                    preAuthorizedAt: undefined,
                    fulfilledAt: undefined,
                    failedAt: undefined,
                    chargedBackAt: undefined,
                    cancelledAt: undefined,
                    uncapturedAt: defined,
                } as Payment;
            case PaymentStatus.Pending:
            default:
                return {
                    failedAt: undefined,
                    fulfilledAt: undefined,
                    chargedBackAt: undefined,
                    preAuthorizedAt: undefined,
                    uncapturedAt: undefined,
                    cancelledAt: undefined,
                } as Payment;
        }
    }
}
