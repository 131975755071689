import { BaseEntity } from "typeorm/repository/BaseEntity.d";

import { Languages } from "./domain/Languages";

let typeorm: any;

if (global.DEPLOYMENT_NODE == true) {
    // tslint:disable-next-line:no-var-requires
    typeorm = require("typeorm");
}

export function CDEntity(...args: Array<any>) {
    if (global.DEPLOYMENT_NODE == true) {
        return typeorm.Entity.apply(typeorm, args);
    }

    return () => {};
}

export function CDPrimaryColumn(type?: string, options?: {}) {
    if (global.DEPLOYMENT_NODE == true) {
        return typeorm.PrimaryColumn(type, options);
    }

    return () => {};
}

export function CDObjectIdColumn() {
    if (global.DEPLOYMENT_NODE == true) {
        return typeorm.ObjectIdColumn();
    }

    return () => {};
}

export function CDPrimaryUUID() {
    if (global.DEPLOYMENT_NODE == true) {
        return typeorm.PrimaryGeneratedColumn("uuid");
    }

    return () => {};
}

export function CDColumn(type?: string, options?: {}) {
    if (global.DEPLOYMENT_NODE == true) {
        return typeorm.Column(type, options);
    }

    return () => {};
}

export function CDCreateDateColumn() {
    if (global.DEPLOYMENT_NODE == true) {
        return typeorm.CreateDateColumn();
    }

    return () => {};
}

export function CDUpdateDateColumn() {
    if (global.DEPLOYMENT_NODE == true) {
        return typeorm.UpdateDateColumn();
    }

    return () => {};
}

export function CDOneToOne(...args: Array<any>) {
    if (global.DEPLOYMENT_NODE == true) {
        return typeorm.Column.apply(typeorm, args);
    }

    return () => {};
}

export function CDManyToOne(...args: Array<any>) {
    if (global.DEPLOYMENT_NODE == true) {
        return typeorm.ManyToOne.apply(typeorm, args);
    }

    return () => {};
}

export function CDJoinColumn(...args: Array<any>) {
    if (global.DEPLOYMENT_NODE == true) {
        return typeorm.JoinColumn.apply(typeorm, args);
    }

    return () => {};
}

export function CDRelationId(...args: Array<any>) {
    if (global.DEPLOYMENT_NODE == true) {
        return typeorm.RelationId.apply(typeorm, args);
    }

    return () => {};
}

export function CDIndex(type?: string, options?: {}) {
    if (global.DEPLOYMENT_NODE == true) {
        return typeorm.Index(type, options);
    }

    return () => {};
}

let CDBaseEntity: { new (): BaseEntity };

// It looks so wrong
if (global.DEPLOYMENT_NODE == true) {
    CDBaseEntity = typeorm.BaseEntity as { new (): BaseEntity };
} else {
    CDBaseEntity = class {} as { new (): BaseEntity };
}

export { CDBaseEntity };

export const getTransformFromLocalizedField = (localizeFieldName: any) => (_: any, obj: any) => {
    const localizedField = obj[localizeFieldName];
    return localizedField == undefined ? "" : localizedField[Languages.en] ? localizedField[Languages.en] : "";
};
