export const cacheData = (storageKey: string, data: string, ttlInMilliseconds: number) => {
    const now = new Date();

    localStorage.setItem(
        storageKey,
        JSON.stringify({
            data,
            expires: now.getTime() + ttlInMilliseconds,
        }),
    );
};
