import autobind from "autobind-decorator";
import { action, computed, observable, toJS } from "mobx";

import { Languages } from "@legacy/domain/Languages";
import { Language } from "@legacy/models/Language";
import { PageStore } from "../../stores/PageStore";

import { TranslationsPage } from "./TranslationsPage";
import { TranslationsPageRouter } from "./TranslationsPageRouter";

export type MessageToRender = {
    id: string;
    msg: string;
    desc?: string;
    default?: string;
    checked?: boolean;
};

export type TranslationJSON = {
    [key: string]: string;
};

@autobind
export class TranslationsPageStore extends PageStore<TranslationsPageRouter, null> {
    public onInit() {
        this.selectedLanguage = this.pageRouter.lang;
    }

    public newOldDescription: string = "---> old or new(pending) translation <---";

    @action
    public async onFetchData() {
        this.languages = await this.rpcClient.content.retrieveLanguages({});
        await this.fetchSelectedTranslation();
    }

    @observable
    public languages: Array<Language>;

    @computed
    public get languagesSorted(): Language[] {
        return toJS(this.languages).sort((a: Language) => (a.isEnabled ? 1 : 0));
    }

    public pageInstance: TranslationsPage;

    public originalSelectedTranslation: MessageToRender[];

    @observable
    public messagesCount: number = 0;

    @observable
    public selectedLanguage: Languages;

    @observable
    public selectedTranslationForRender: MessageToRender[];

    @observable
    public newMessageId: string;

    @observable
    public isCSVImporting: boolean = false;

    @observable
    public importTranslationPayload: FormData;

    @observable
    public isJSONImporting: boolean = false;

    @observable
    public newMessageText: string;

    public editedFieldsToSave: TranslationJSON = {};

    public getMessageById(id: string): MessageToRender {
        return this.selectedTranslationForRender.find((msg) => msg.id === id) as MessageToRender;
    }

    public getOriginalMessageById(id: string): MessageToRender {
        return this.originalSelectedTranslation.find((msg) => msg.id === id) as MessageToRender;
    }

    @action
    public updateTranslation(newMessage: string, id: string): void {
        this.editedFieldsToSave[id] = newMessage;
        this.getMessageById(id).msg = newMessage;
    }

    @action
    public onChangeNewMessageId(newId: string): void {
        this.newMessageId = newId;
    }

    @action
    public async saveAllEditedMessages() {
        await this.rpcClient.translation.updateEditedTranslations(this.editedFieldsToSave, this.selectedLanguage);
        this.editedFieldsToSave = {};
    }

    @action
    public async createNewMessage() {
        if (!this.newMessageId || !this.newMessageText) {
            alert(`Please fill both "id" and "message" fields`);
            return;
        }
        if (this.getOriginalMessageById(this.newMessageId)) {
            alert(`Duplicite id: ${this.newMessageId}`);
            return;
        }
        this.selectedTranslationForRender.push({
            id: this.newMessageId,
            msg: this.newMessageText,
            desc: this.newOldDescription,
        });
        this.sort();
        await this.rpcClient.translation.createNewTranslationMessage(
            this.newMessageId,
            this.newMessageText,
            this.selectedLanguage,
        );
        this.newMessageId = "";
        this.newMessageText = "";
        await this.fetchSelectedTranslation();
    }

    @action
    public async removeSelectedMessages() {
        const removedMessagesObject = {};
        const removedMessages = this.selectedTranslationForRender.filter((msg) => msg.checked);
        removedMessages.forEach((msg) => {
            removedMessagesObject[msg.id] = msg.msg;
        });

        this.selectedTranslationForRender = this.selectedTranslationForRender.filter((msg) => !msg.checked);

        await this.rpcClient.translation.removeSelectedTranslations(removedMessagesObject, this.selectedLanguage);
        await this.fetchSelectedTranslation();
    }

    @action
    public toggleChecked(id: string) {
        const msg = this.getMessageById(id);
        msg.checked = !msg.checked;
    }

    @action
    public async onChangeLanguage(value: Languages) {
        this.selectedLanguage = value;
        this.pageRouter.setLang(value);
    }

    @action
    public onChangeNewMessageText(newMsg: string): void {
        this.newMessageText = newMsg;
    }

    @action
    public async fetchSelectedTranslation(lang: Languages = this.selectedLanguage) {
        const options = {
            skip: this.pageRouter.skip,
            limit: this.pageRouter.limit,
            sortBy: this.pageRouter.sortBy,
            sortDirection: this.pageRouter.sortDirection,
            search: this.pageRouter.search,
        };

        const selectedTranslation = await this.rpcClient.translation.getTranslationByLanguage(lang);
        this.originalSelectedTranslation = this.getTranslationToRender(selectedTranslation.translationJSON);
        this.messagesCount = Object.keys(this.originalSelectedTranslation).length;
        this.sort(this.originalSelectedTranslation);
        this.selectedTranslationForRender = this.originalSelectedTranslation.slice(options.skip - 1, options.limit);
    }

    public sanitizeForComparison(item: any): string {
        return String(item).trim().toLowerCase();
    }

    @action
    public sort(arr?: MessageToRender[]) {
        let messagesArr = arr && arr.length ? arr : this.selectedTranslationForRender;
        const q = this.pageRouter.routerStore.location.query;
        const sortBy = q.sortBy || "id";
        const sortDirection = q.sortDirection || -1;
        messagesArr = messagesArr.sort((a: MessageToRender | string, b: MessageToRender | string) => {
            let o: number;
            a = this.sanitizeForComparison(a[sortBy]);
            b = this.sanitizeForComparison(b[sortBy]);
            if (sortDirection == 1) {
                o = a > b ? -1 : 1;
            } else {
                o = a > b ? 1 : -1;
            }
            return o;
        });
    }

    public isDataFetched(): this is TranslationsPageStore {
        return this.selectedTranslationForRender != undefined;
    }

    @action
    public getTranslationToRender(translation: TranslationJSON): MessageToRender[] {
        const outputTranslation = [];
        // create arr from default translations
        return [];
        // Note: bring back code once we decide wahat to do
        //        for (const key in defaultMessages) {
        //            outputTranslation.push(
        //                {
        //                    id: key,
        //                    msg: translation[key] || "",
        //                    default: defaultMessages[key].msg,
        //                    desc: defaultMessages[key].desc || "",
        //                    checked: false,
        //                }
        //            );
        //            delete translation[key];
        //        }
        //        // add old/pending translations from db
        //        for (const key in translation) {
        //            outputTranslation.push(
        //                {
        //                    id: key,
        //                    msg: translation[key],
        //                    desc: this.newOldDescription,
        //                    checked: false,
        //                }
        //            );
        //            delete translation[key];
        //        }
        //
        //        return outputTranslation;
    }
}
