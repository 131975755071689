import { transformNameToMachineName } from "@daytrip/legacy-transformers";
import { SanitizerConstraint } from "class-sanitizer/decorators";
import type { SanitizerInterface } from "class-sanitizer/SanitizerInterface";

@SanitizerConstraint()
export class MachineNameSanitizer implements SanitizerInterface {
    public sanitize(value: string): string {
        return transformNameToMachineName(value);
    }
}
