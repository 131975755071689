import { VehicleType } from "@daytrip/legacy-enums";
import { isUndefinedOrNull } from "@daytrip/utils";

import type { SimpleCountry } from "../domain/SimpleCountry";
import type { Country } from "../models/Country";
import { Region } from "../models/Region";
import type { VehicleTypeCosts } from "../models/VehicleTypeCosts";

export function getVehicleTypeFeeCoefficient(
    pricingArea: Country | SimpleCountry | Region,
    vehicleType: VehicleType,
): number {
    return pricingArea instanceof Region
        ? getVehicleTypeFeeCoefficientFromVehicleTypeCosts(
              pricingArea.vehicleTypeCosts,
              vehicleType,
              pricingArea._id,
              undefined,
          )
        : getVehicleTypeFeeCoefficientFromVehicleTypeCosts(
              pricingArea.vehicleTypeCosts,
              vehicleType,
              undefined,
              pricingArea._id,
          );
}

export function getVehicleTypeFeeCoefficientFromVehicleTypeCosts(
    vehicleTypeCosts: VehicleTypeCosts[],
    vehicleType: VehicleType,
    pricingRegionId: string | undefined,
    pricingCountryId: string | undefined,
): number {
    const vehicleTypeCost = vehicleTypeCosts.find((cost) => cost.vehicleType === vehicleType);
    if (!vehicleTypeCost || isUndefinedOrNull(vehicleTypeCost.feeCoefficient)) {
        throw new Error(
            `Vehicle costs not defined for vehicle type ${vehicleType} in pricing ${
                pricingRegionId ? "region" : "country"
            } ${pricingRegionId ?? pricingCountryId}`,
        );
    }
    return vehicleTypeCost.feeCoefficient;
}

export function getDefaultFeeCoefficient(pricingArea: Country | SimpleCountry | Region): number {
    return getVehicleTypeFeeCoefficient(pricingArea, VehicleType.Sedan);
}
