import type { Order } from "@daytrip/legacy-models";

const sortArrayByStringValue = (array: Array<any>): Array<any> =>
    array.sort((a, b) => {
        if (JSON.stringify(a) < JSON.stringify(b)) {
            return -1;
        }

        if (JSON.stringify(a) > JSON.stringify(b)) {
            return 1;
        }

        return 0;
    });

export function compareEditedOrderWithFetchedOrder(editedOrder: Order, fetchedOrder: Order) {
    const changedKeys = (Object.keys(fetchedOrder) as Array<keyof Order>).filter((key) => {
        let oldValue = fetchedOrder[key];
        let newValue = editedOrder[key];

        if (
            ["automaticEmails"].includes(key) ||
            ((oldValue === undefined || oldValue === null) && (newValue === undefined || newValue === null)) ||
            // ignore this change if price was just determined
            (key === "totalPrice" && !oldValue)
        ) {
            return false;
        }

        if (oldValue instanceof Array && newValue instanceof Array) {
            // we need to sort arrays before comparing them,
            // since their ordering might change
            oldValue = sortArrayByStringValue(oldValue);
            newValue = sortArrayByStringValue(newValue);
        }

        return JSON.stringify(oldValue) !== JSON.stringify(newValue);
    });

    return changedKeys;
}
