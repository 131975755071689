import { Expose, Transform } from "class-transformer";
import { IsArray, IsBoolean, IsDate, IsEnum } from "class-validator";

import { Currency } from "../domain/Currency";
import type { PayoutStatus } from "../domain/PayoutStatus";
import type { PayoutType } from "../domain/PayoutType";
import type { Payout } from "../models/Payout";
import { valueOrArrayToArray } from "../utils/valueOrArrayToArray";

import { SortingPaginationOptions } from "./SortingPaginationOptions";

export class RetrievePayoutsOptions extends SortingPaginationOptions<Payout> {
    @IsArray()
    public ids?: Array<string>;

    @IsArray()
    @Transform((value) => (value == undefined ? undefined : valueOrArrayToArray(value)))
    public userIds?: Array<string>;

    @IsArray()
    public payoutGatewayIds?: Array<string>;

    @IsBoolean()
    @Transform((value) => (value == undefined ? undefined : !!(value == "true" || value == true)))
    public hasBaselessAmount?: boolean;

    @IsArray()
    @Transform((value) => (value == undefined ? undefined : valueOrArrayToArray(value).map((item) => Number(item))))
    public status?: Array<PayoutStatus>;

    @IsArray()
    @Transform((value) => (value == undefined ? undefined : valueOrArrayToArray(value).map((item) => Number(item))))
    public type?: Array<PayoutType>;

    @IsDate()
    @Transform((value) => (value == undefined ? undefined : new Date(value)))
    public createdAtFrom?: Date;

    @IsDate()
    @Transform((value) => (value == undefined ? undefined : new Date(value)))
    public createdAtTo?: Date;

    @IsDate()
    @Transform((value) => (value == undefined ? undefined : new Date(value)))
    public statusChangedAtFrom?: Date;

    @IsDate()
    @Transform((value) => (value == undefined ? undefined : new Date(value)))
    public statusChangedAtTo?: Date;

    @IsEnum(Currency)
    @Expose()
    public currency?: Currency;
}
