import { Exclude, Expose } from "class-transformer";
import { IsDefined, IsNumber } from "class-validator";

import type { UserAutomaticEmail } from "../models/UserAutomaticEmail";

@Exclude()
export class SimpleTravelAgent {
    @Expose()
    public fullName: string;

    @IsNumber()
    @IsDefined()
    @Expose()
    public discountCoefficient: number = 0;

    @IsNumber()
    @IsDefined()
    @Expose()
    public commissionPercentage: number = 0;

    @Expose()
    public agentId?: string;

    @Expose()
    public hostAgencyId?: string;

    @Expose()
    public countryName: string;

    @Expose()
    public userId: string;

    @Expose()
    public approvedAt?: Date;

    @Expose()
    public createdAt?: Date;

    @Expose()
    public declinedAt?: Date;

    @Expose()
    public declinationReason?: string;

    @Expose()
    public email: string;

    @Expose()
    public ownerId: string;

    @Expose()
    public partnerBookingAvailable: boolean;

    @Expose()
    public lastLoginAt?: Date;

    @Expose()
    public automaticEmails: Array<UserAutomaticEmail>;
}
