import { Exclude, Expose } from "class-transformer";
import { IsArray, IsEnum, IsOptional } from "class-validator";

import type { AssignationAutomaticEmailType } from "../domain/AssignationAutomaticEmailType";
import type { AssignationStatus } from "../domain/AssignationStatus";
import { Currency } from "../domain/Currency";
import { OrderType } from "../domain/OrderType";
import type { Assignation } from "../models/Assignation";

import { SortingPaginationOptions } from "./SortingPaginationOptions";

@Exclude()
export class RetrieveAssignationsOptions extends SortingPaginationOptions<Assignation> {
    @Expose()
    public ids?: Array<string>;

    @Expose()
    public departureAtFrom?: Date;

    @Expose()
    public departureAtTo?: Date;

    @Expose()
    public createdAtFrom?: Date;

    @Expose()
    public createdAtTo?: Date;

    @Expose()
    public driverIds?: Array<string>;

    @Expose()
    public payoutIds?: Array<string>;

    @Expose()
    public statusIn?: Array<AssignationStatus>;

    @Expose()
    public orderIds?: Array<string>;

    @Expose()
    public searchString?: string;

    @Expose()
    public subsidyIds?: Array<string>;

    @Expose()
    public penaltyIds?: Array<string>;

    @Expose()
    public compensationIds?: Array<string>;

    @IsArray()
    @Expose()
    public emailTypesSent?: Array<AssignationAutomaticEmailType>;

    @IsArray()
    @Expose()
    public emailTypesNotSent?: Array<AssignationAutomaticEmailType>;

    @Expose()
    public hasPendingScheduledEmails?: boolean;

    @Expose()
    public automaticEmailMailgunIds?: Array<string>;

    @Expose()
    public isInvoiced?: boolean;

    @Expose()
    public currency?: Currency;

    @Expose()
    public rideIds?: Array<string>;

    @Expose()
    public skip?: number;

    @Expose()
    public limit?: number;

    @IsEnum(OrderType, { each: true })
    @IsOptional()
    @Expose()
    public orderTypes?: OrderType[];
}
