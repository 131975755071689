import { describeVTPFDifference } from "@legacy/utils/describeVTPFDifference";
import type { OrderOperator } from "../../../operators/OrderOperator";

export function checkAndConfirmVehicleTypePriceFeeDifference(orderOperator: OrderOperator) {
    if (orderOperator.originalVehicleTypesPricesFees !== undefined) {
        let pricesDifferenceMessage = describeVTPFDifference(
            orderOperator.originalVehicleTypesPricesFees,
            orderOperator.m.vehicleTypesPricesFees,
        );

        if (pricesDifferenceMessage) {
            pricesDifferenceMessage += "Would you like to proceed this change?";

            // eslint-disable-next-line no-alert
            const keep = confirm(pricesDifferenceMessage);

            if (!keep) {
                orderOperator.m.vehicleTypesPricesFees.forEach((_vtpf, index) => {
                    orderOperator.m.vehicleTypesPricesFees[index].price =
                        orderOperator.originalVehicleTypesPricesFees![index].price;
                    orderOperator.m.vehicleTypesPricesFees[index].fee =
                        orderOperator.originalVehicleTypesPricesFees![index].fee;
                });
            }
        }
    }
}
