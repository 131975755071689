import type { User, VatValidation } from "@daytrip/legacy-models";
import { isUndefinedOrNull } from "@daytrip/utils";

import type { RpcClient } from "../rpc-browser-sdk";

const VAT_NOT_VALIDATED = "VAT ID has not been validated";
const VAT_NOT_VALID = "VAT ID is not valid for cross border transactions";

export function getVatErrorMessage(vatValidation: VatValidation | undefined): string | undefined {
    if (isUndefinedOrNull(vatValidation)) {
        return VAT_NOT_VALIDATED;
    }

    if (!vatValidation.isValid) {
        return VAT_NOT_VALID;
    }
}
export async function getVatValidationMessage(user: User, rpcClient: RpcClient): Promise<string | undefined> {
    const billingInfo = user.billingInformation.find((bio) => bio.isActive);
    if (!billingInfo) {
        return;
    }
    const { headquartersAddress, vatValidation } = billingInfo;
    const countryId = headquartersAddress?.countryId;
    if (!countryId) {
        return;
    }

    const isVatValidationSupported = await rpcClient.vat.isVatIdValidationSupported(countryId);

    if (!isVatValidationSupported) {
        return;
    }

    return getVatErrorMessage(vatValidation);
}
