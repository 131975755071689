import {
    VehicleType,
    VehicleTypesCapacity,
    VehicleTypesCarryOns,
    VehicleTypesSuitcases,
} from "@legacy/domain/VehicleType";

const getOptimalVehiclesByPassengersAndLuggageForPool = (
    passengers: number,
    suitcases: number,
    carryOns: number,
): Array<VehicleType> => {
    const vehicles: Array<VehicleType> = [];

    while (passengers > 0 || suitcases > 0 || carryOns > 0) {
        let vt: VehicleType;
        if (passengers > 7 || suitcases > 7 || carryOns > 7) {
            vt = VehicleType.Shuttle;
        } else if (passengers > 4 || suitcases > 4 || carryOns > 4) {
            vt = VehicleType.Van;
        } else if (passengers === 4 || suitcases === 4 || carryOns === 4) {
            vt = VehicleType.Mpv;
        } else {
            vt = VehicleType.Sedan;
        }

        vehicles.push(vt);
        suitcases -= VehicleTypesSuitcases[vt];
        carryOns -= VehicleTypesCarryOns[vt];
        passengers -= VehicleTypesCapacity[vt];
    }

    return vehicles;
};

const getOptimalVehiclesByPassengersAndLuggageForPrivate = (
    passengers: number,
    suitcases: number,
    carryOns: number,
) => {
    const vehicles: Array<VehicleType> = [];

    while (passengers > 0 || suitcases > 0 || carryOns > 0) {
        let vt: VehicleType;
        if (passengers > 4 || suitcases > 4 || carryOns > 4) {
            vt = VehicleType.Van;
        } else if (passengers === 4 || suitcases === 4 || carryOns === 4) {
            vt = VehicleType.Mpv;
        } else {
            vt = VehicleType.Sedan;
        }

        vehicles.push(vt);
        suitcases -= VehicleTypesSuitcases[vt];
        carryOns -= VehicleTypesCarryOns[vt];
        passengers -= VehicleTypesCapacity[vt];
    }

    return vehicles;
};

export const getOptimalVehiclesByPassengersAndLuggage = (
    passengers: number,
    suitcases: number,
    carryOns: number,
    isPool = false,
): Array<VehicleType> => {
    if (isPool) {
        return getOptimalVehiclesByPassengersAndLuggageForPool(passengers, suitcases, carryOns);
    }
    return getOptimalVehiclesByPassengersAndLuggageForPrivate(passengers, suitcases, carryOns);
};
