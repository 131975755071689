import getDistance from "geolib/es/getDistance";

interface GeoPoint {
    longitude: number;
    latitude: number;
}

export function checkPositionInRadius(originalPosition: GeoPoint, radius: number, searchPosition: GeoPoint) {
    const pointDistance = getDistance(originalPosition, searchPosition);

    const isWithinRadius = pointDistance <= radius * 1000;
    return isWithinRadius;
}
