import { API_ASSIGNATION_CSV_EXPORT_URL } from "@daytrip/legacy-config";
import { AssignationDataForPreview } from "@legacy/domain/AssignationDataForPreview";
import { Assignation } from "@legacy/models/Assignation";
import { RetrieveAssignationsOptions } from "@legacy/options/RetrieveAssignationsOptions";
import autobind from "autobind-decorator";
import { plainToClass } from "class-transformer";
import { isNumberString } from "class-validator";
import { action, computed, observable } from "mobx";

import { OrderType } from "../../../../legacy/source/domain/OrderType";
import { PageStore } from "../../stores/PageStore";

import { AssignationsPageRouter } from "./AssignationsPageRouter";

@autobind
export class AssignationsPageStore extends PageStore<AssignationsPageRouter, null> {
    @action
    public clearSearch() {
        this.pageRouter.clearQuery();
        this.onFetchData();
    }

    @action
    public async onFetchData() {
        if (this.pageRouter.departureAtFrom && this.pageRouter.departureAtTo) {
            const df = this.pageRouter.departureAtFrom;
            const dt = this.pageRouter.departureAtTo;

            if (
                df.getUTCFullYear() === dt.getUTCFullYear() &&
                df.getUTCMonth() === dt.getUTCMonth() &&
                df.getUTCDate() === dt.getUTCDate()
            ) {
                df.setUTCHours(0);
                df.setUTCMinutes(0);
                df.setUTCSeconds(0);
                this.pageRouter.filterDepartureAtFromUpdate(df.getTime().toString());

                dt.setUTCHours(23);
                dt.setUTCMinutes(59);
                dt.setUTCSeconds(59);
                this.pageRouter.filterDepartureAtToUpdate(dt.getTime().toString());
            }
        }

        this.fetchAssignationsDataPreview();
    }

    @computed
    public get assignationsOptions(): RetrieveAssignationsOptions {
        return {
            departureAtFrom: this.pageRouter.departureAtFrom,
            departureAtTo: this.pageRouter.departureAtTo,
            statusIn: this.pageRouter.statusIn,
            skip: this.pageRouter.skip,
            limit: this.pageRouter.limit,
            sortBy: this.pageRouter.sortBy as keyof Assignation,
            sortDirection: this.pageRouter.sortDirection,
            isInvoiced: this.pageRouter.isInvoiced,
            orderTypes: this.pageRouter.orderTypes,
        };
    }

    @observable
    public assignationDataForOverview?: Array<AssignationDataForPreview>;

    @observable
    public assignationDataForOverviewCount?: number;

    @action
    public async fetchAssignationsDataPreview(): Promise<void> {
        this.assignationDataForOverview = plainToClass(
            AssignationDataForPreview,
            await this.rpcClient.assignation.retrieveAssignationsPreview(this.assignationsOptions),
        );

        this.assignationDataForOverviewCount = await this.rpcClient.assignation.retrieveAssignationsCount(
            this.assignationsOptions,
        );
    }

    public isDataFetched(): this is AssignationsPageStore & AssignationsPageStoreDataFetched {
        return this.assignationDataForOverview != null;
    }

    public downloadAsCSV() {
        const { authenticationToken } = this.authenticationStore;
        window.location.href = `${API_ASSIGNATION_CSV_EXPORT_URL}?authenticationToken=${authenticationToken}&options=${JSON.stringify(
            this.assignationsOptions,
        )}`;
    }
}

export interface AssignationsPageStoreDataFetched {
    assignationDataForOverview: Array<AssignationDataForPreview>;
    assignationDataForOverviewCount: number;
}
