import type { SimpleCountry } from "@legacy/domain/SimpleCountry";
import autobind from "autobind-decorator";
import { orderBy } from "lodash";
import { action, computed, observable, reaction } from "mobx";

import { DynamicFormConfigEntityTypeLegalNameDefaults } from "../../constants/DynamicFormConfigEntityTypeLegalNameDefaults";
import {
    getCooperationAgreementDynamicFormConfigApi,
    upsertCooperationAgreementDynamicFormConfigApi,
} from "../../domain/dynamicFormConfigs/DynamicFormsConfigApi";
import { PageStore } from "../../stores/PageStore";
import { DynamicFormEntityLegalNames, DynamicFormTemplates } from "../../types/DynamicForm";
import { DynamicFormConfigEntityType } from "../../types/generated/graphql-types";
import type { DynamicFormConfigFieldType } from "../../types/generated/graphql-types";
import { GQLERROR_NOT_FOUND, getApolloErrorCode } from "../../utils/graphql/getGraphQLErrorCode";

import type { DynamicFormConfigFieldTypeExtended } from "./CooperationAgreementPage.types";
import { preparePayloadData } from "./CooperationAgreementPage.utils";
import type { CooperationAgreementPageRouter } from "./CooperationAgreementPageRouter";

@autobind
export class CooperationAgreementPageStore extends PageStore<CooperationAgreementPageRouter, null> {
    @observable
    public countries: Array<SimpleCountry> = [];

    @observable
    public driversData: Array<DynamicFormConfigFieldType> = [];

    @observable
    public companyData: Array<DynamicFormConfigFieldType> = [];

    @observable
    public defaultTemplates: DynamicFormTemplates = {
        [DynamicFormConfigEntityType.Driver]: null,
        [DynamicFormConfigEntityType.DriverCompany]: null,
    };

    @observable
    public entityTypeLegalNames: DynamicFormEntityLegalNames = {
        [DynamicFormConfigEntityType.Driver]: DynamicFormConfigEntityTypeLegalNameDefaults.DRIVER,
        [DynamicFormConfigEntityType.DriverCompany]: DynamicFormConfigEntityTypeLegalNameDefaults.DRIVER_COMPANY,
    };

    @computed
    public get countriesOptions() {
        const options = orderBy(this.countries, ["englishName"], ["asc"]).map((country: SimpleCountry) => ({
            label: country.englishName,
            value: country._id,
        }));
        options.unshift({ label: "All countries", value: "" });
        return options;
    }

    @action
    public async fetchContent() {
        this.countries = await this.rpcClient.content.retrieveCountries({
            excludeIsoCodes: ["eu"],
        });
    }

    public async onInit() {
        await this.getDriverCooperationAgreement();
        await this.getCompanyCooperationAgreement();
        reaction(() => this.pageRouter?.countryId, this.getDriverCooperationAgreement);
        reaction(() => this.pageRouter?.countryId, this.getCompanyCooperationAgreement);
    }

    @action
    public async getDriverCooperationAgreement() {
        const data = await this.getCooperationAgreementForCountryOrDefault(DynamicFormConfigEntityType.Driver);
        this.driversData = data?.fields ?? [];
        this.entityTypeLegalNames[DynamicFormConfigEntityType.Driver] =
            data?.entityTypeLegalName || DynamicFormConfigEntityTypeLegalNameDefaults.DRIVER;
    }

    @action
    public async getCompanyCooperationAgreement() {
        const data = await this.getCooperationAgreementForCountryOrDefault(DynamicFormConfigEntityType.DriverCompany);
        this.companyData = data?.fields ?? [];
        this.entityTypeLegalNames[DynamicFormConfigEntityType.DriverCompany] =
            data?.entityTypeLegalName || DynamicFormConfigEntityTypeLegalNameDefaults.DRIVER_COMPANY;
    }

    @action
    async getCooperationAgreementForCountryOrDefault(entityType: DynamicFormConfigEntityType) {
        try {
            const dynamicFormData = await getCooperationAgreementDynamicFormConfigApi(
                this.pageRouter.countryId || null,
                entityType,
            );
            return dynamicFormData;
        } catch (error) {
            if (getApolloErrorCode(error) === GQLERROR_NOT_FOUND) {
                await this.getDefaultDynamicFormConfig(entityType);
                return;
            }

            throw error;
        }
    }

    @action
    public async saveDriverCooperationAgreementDynamicFormConfig(templateData: DynamicFormConfigFieldTypeExtended[]) {
        this.driversData = await this.upsertCooperationAgreementDynamicFormConfig(
            templateData,
            DynamicFormConfigEntityType.Driver,
        );
    }

    @action
    public async saveCompanyCooperationAgreementDynamicFormConfig(templateData: DynamicFormConfigFieldTypeExtended[]) {
        this.companyData = await this.upsertCooperationAgreementDynamicFormConfig(
            templateData,
            DynamicFormConfigEntityType.DriverCompany,
        );
    }

    @action
    async upsertCooperationAgreementDynamicFormConfig(
        templateData: DynamicFormConfigFieldTypeExtended[],
        entityType: DynamicFormConfigEntityType,
    ) {
        const fields = preparePayloadData(templateData);
        const data = await upsertCooperationAgreementDynamicFormConfigApi(
            this.pageRouter.countryId || null,
            entityType,
            this.entityTypeLegalNames[entityType],
            fields,
        );
        const result = (data?.fields || []) as DynamicFormConfigFieldType[];
        if (!this.pageRouter.countryId) {
            this.defaultTemplates[entityType] = result;
        }
        return result;
    }

    async updateDynamicFormConfigLegalName(legalName: string, entityType: DynamicFormConfigEntityType) {
        this.entityTypeLegalNames[entityType] = legalName;
        const data = entityType === DynamicFormConfigEntityType.Driver ? this.driversData : this.companyData;
        await this.upsertCooperationAgreementDynamicFormConfig(
            data as DynamicFormConfigFieldTypeExtended[],
            entityType,
        );
    }

    async getDefaultDynamicFormConfig(entityType: DynamicFormConfigEntityType) {
        if (this.defaultTemplates[entityType]) {
            return this.defaultTemplates[entityType];
        }

        try {
            const defaultDynamicConfig = await getCooperationAgreementDynamicFormConfigApi(null, entityType);
            this.defaultTemplates[entityType] = defaultDynamicConfig?.fields || [];
            return defaultDynamicConfig;
        } catch (error) {
            if (getApolloErrorCode(error) === GQLERROR_NOT_FOUND) {
                alert(
                    "We couldn't find the default template for the entity type: " +
                        entityType +
                        ". To avoid issues, please create a cooperation agreement template for all countries.",
                );
                return;
            }

            throw error;
        }
    }
}
