import { action, observable } from "mobx";

import { getRpcClient, RpcClient } from "../../rpc-browser-sdk";

export class ChangePasswordStore {
    private rpcClient: RpcClient;

    @observable
    public oldPassword: string = "";

    @observable
    public oldPasswordValidationMessage?: string;

    @observable
    public newPassword: string = "";

    @observable
    public newPasswordValidationMessage?: string;

    @observable
    public newRepeatPassword: string = "";

    @observable
    public newRepeatPasswordValidationMessage?: string;

    public constructor() {
        this.rpcClient = getRpcClient();

        this.userUpdateOldPassword = this.userUpdateOldPassword.bind(this);
        this.userUpdateNewPassword = this.userUpdateNewPassword.bind(this);
        this.userUpdateNewRepeatPassword = this.userUpdateNewRepeatPassword.bind(this);
        this.validatePassword = this.validatePassword.bind(this);
        this.updatePassword = this.updatePassword.bind(this);
        this.resetPassword = this.resetPassword.bind(this);
    }

    @action
    public userUpdateOldPassword(value: string) {
        this.oldPassword = value;
    }

    @action
    public userUpdateNewPassword(value: string) {
        this.newPassword = value;
    }

    @action
    public userUpdateNewRepeatPassword(value: string) {
        this.newRepeatPassword = value;
    }

    @action
    public validatePassword() {
        this.oldPasswordValidationMessage = undefined;
        this.newPasswordValidationMessage = undefined;
        this.newRepeatPasswordValidationMessage = undefined;

        const uppercaseSymbolRegexp = /[A-Z]+/g;
        const numberRegexp = /\d/g;

        if (this.oldPassword.length === 0) {
            this.oldPasswordValidationMessage = "Old password should be specified";
            return false;
        }

        if (!uppercaseSymbolRegexp.test(this.newPassword)) {
            this.newPasswordValidationMessage = "Password should contain at least one uppercase symbol";
            return false;
        }

        if (!numberRegexp.test(this.newPassword)) {
            this.newPasswordValidationMessage = "Password should contain at least one number";
            return false;
        }

        if (this.newPassword !== this.newRepeatPassword) {
            this.newRepeatPasswordValidationMessage = "Passwords should be the same";
            return false;
        }

        return true;
    }

    @observable
    public isPasswordUpdating = false;

    @observable
    public passwordLastUpdated?: Date;

    @action
    public async updatePassword(userId: string) {
        this.isPasswordUpdating = true;
        if (!this.validatePassword()) {
            this.isPasswordUpdating = false;
            return;
        }

        const error = await this.rpcClient.user.updatePassword(userId, this.oldPassword, this.newPassword);
        this.isPasswordUpdating = false;

        if (error) {
            this.oldPasswordValidationMessage = error;
        } else {
            this.oldPassword = "";
            this.newPassword = "";
            this.newRepeatPassword = "";
            this.passwordLastUpdated = new Date();
            this.passwordLastReseted = undefined;
        }
    }

    @observable
    public isPasswordReseting = false;

    @observable
    public passwordLastReseted?: Date;

    @action
    public async resetPassword(userId: string) {
        this.isPasswordReseting = true;
        await this.rpcClient.user.resetPassword(userId);
        this.isPasswordReseting = false;
        this.passwordLastReseted = new Date();
        this.passwordLastUpdated = undefined;
    }
}
