import { US_STATES } from "@daytrip/constants";
import { APPLE_MAPS_TOKEN } from "@daytrip/legacy-config";
import type { LocationPosition, Position } from "@daytrip/legacy-models";
import { isUndefinedOrNull } from "@daytrip/utils";
import type { Response } from "node-fetch";
import fetch from "node-fetch";

import type { ComputeRoutesResult, GeoCodeResult, GeoPoint } from "../maps-client/maps-client";
import { MapsClientError } from "../maps-client/maps-client-error";

const APPLE_TOKEN_URL = "https://maps-api.apple.com/v1/token";

let accessToken: string | undefined;

const stateIsoCodes: string[] = US_STATES.map((state) => state.abbreviation.toLowerCase());

export interface RouteMetadata {
    distanceMeters: number;
    durationSeconds: number;
}

interface AppleEtasResult {
    etas: AppleRouteEta[];
}

interface AppleRouteEta {
    distanceMeters: number;
    staticTravelTimeSeconds: number;
}

interface AppleGeoCodeResult {
    coordinate: GeoPoint;
    name: string;
    country: string;
    countryCode: string;
}

interface AppleGeoCodeResults {
    results: AppleGeoCodeResult[];
}

const COUNTRY_IDS_USING_APPLE_MAPS = ["2c92d86c-cf58-4c1e-a892-d468e7252edd"];

export function shouldCountryUseAppleMaps(countryId: string) {
    return COUNTRY_IDS_USING_APPLE_MAPS.includes(countryId);
}

export async function computeRoutes(
    origin: GeoPoint,
    destination: GeoPoint,
    intermediates: GeoPoint[],
): Promise<ComputeRoutesResult> {
    if (!accessToken) {
        accessToken = await getAccessToken(APPLE_MAPS_TOKEN!);
    }
    const allPoints = [origin, ...intermediates, destination];
    const legs: { origin: GeoPoint; destination: GeoPoint }[] = [];
    for (let i = 0; i < allPoints.length - 1; i++) {
        legs.push({ origin: allPoints[i], destination: allPoints[i + 1] });
    }
    const results = await Promise.all(legs.map((leg) => computeMultipleRoutes(leg.origin, [leg.destination])));
    return mergeEtaResults(results.map((result) => result[0]));
}

export async function geoCodeAddress(address: string): Promise<GeoCodeResult> {
    const url = `https://maps-api.apple.com/v1/geocode?q=${address}`;
    try {
        if (!accessToken) {
            accessToken = await getAccessToken(APPLE_MAPS_TOKEN!);
        }
        let appleMapsResponse = await fetch(url, {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        });

        // refresh token and repeat the request
        if (appleMapsResponse.status === 401) {
            accessToken = await getAccessToken(APPLE_MAPS_TOKEN!);

            appleMapsResponse = await fetch(url, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        }

        if (!appleMapsResponse.ok) {
            const message = "Apple Maps API returned non-OK response";
            throw new MapsClientError(message, {
                url,
                status: appleMapsResponse.status,
                responseBody: await appleMapsResponse.json(),
            });
        }

        const responseBody: AppleGeoCodeResults = await appleMapsResponse.json();

        if (!responseBody?.results?.length) {
            const message = "Failed to geocode address using Apple Maps API";
            throw new MapsClientError(message, {
                url,
                status: 200,
                responseBody,
            });
        }

        return responseBody.results[0];
    } catch (err: any) {
        if (err instanceof MapsClientError) {
            throw err;
        }
        throw new MapsClientError("Error when calling Apple Maps API", {
            url,
            err,
        });
    }
}

export async function computeMultipleRoutes(origin: GeoPoint, destinations: GeoPoint[]): Promise<RouteMetadata[]> {
    if (
        destinations.length === 1 &&
        origin.latitude === destinations[0].latitude &&
        origin.longitude === destinations[0].longitude
    ) {
        return [
            {
                distanceMeters: 0,
                durationSeconds: 0,
            },
        ];
    }

    const destinationStrings = destinations.map((destination) => `${destination.latitude},${destination.longitude}`);
    const url = `https://maps-api.apple.com/v1/etas?origin=${origin.latitude},${
        origin.longitude
    }&destinations=${destinationStrings.join("|")}`;

    try {
        let appleMapsResponse = await fetch(url, {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        });

        // refresh token and repeat the request
        if (appleMapsResponse.status === 401) {
            accessToken = await getAccessToken(APPLE_MAPS_TOKEN!);

            appleMapsResponse = await fetch(url, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        }

        if (!appleMapsResponse.ok) {
            const message = "Apple Maps API returned non-OK response";
            throw new MapsClientError(message, {
                url,
                status: appleMapsResponse.status,
                responseBody: await appleMapsResponse.json(),
            });
        }

        const etasResult: AppleEtasResult = await appleMapsResponse.json();

        if (
            !etasResult.etas?.length ||
            etasResult.etas.length !== destinations.length ||
            etasResult.etas.some(
                (eta) => isUndefinedOrNull(eta.distanceMeters) || isUndefinedOrNull(eta.staticTravelTimeSeconds),
            )
        ) {
            const message = "Failed to calculate route using Apple Maps API - no route found";
            throw new MapsClientError(message, {
                url,
                status: 200,
                responseBody: etasResult,
            });
        }
        return etasResult.etas.map((eta) => ({
            distanceMeters: eta.distanceMeters,
            durationSeconds: eta.staticTravelTimeSeconds,
        }));
    } catch (err: any) {
        if (err instanceof MapsClientError) {
            throw err;
        }
        throw new MapsClientError("Error when calling Apple Maps API", {
            url,
            err,
        });
    }
}

export async function computeDistanceMatrix(locations: GeoPoint[]): Promise<RouteMetadata[][]> {
    try {
        const routeMetadata = await Promise.all(
            locations.map((location) => computeMultipleRoutes(location, locations)),
        );
        if (
            routeMetadata.length !== locations.length ||
            routeMetadata.some((result) => result.length !== locations.length)
        ) {
            throw new MapsClientError("Failed to compute distance matrix - an ETA is missing", {
                locations,
                etaResults: routeMetadata,
            });
        }
        return routeMetadata;
    } catch (err: any) {
        if (err instanceof MapsClientError) {
            throw err;
        }
        throw new MapsClientError("Failed to compute distance matrix", {
            locations,
            err,
        });
    }
}

async function getAccessToken(appleMapsToken: string) {
    const accessTokenResponse = await fetch(APPLE_TOKEN_URL, {
        headers: {
            Authorization: `Bearer ${appleMapsToken}`,
        },
    });

    if (!accessTokenResponse.ok) {
        const message = "Failed to get Apple Maps access token";
        throw new MapsClientError(message, {
            url: APPLE_TOKEN_URL,
            status: accessTokenResponse.status,
            responseBody: await accessTokenResponse.json(),
        });
    }

    const json = await accessTokenResponse.json();

    return json.accessToken;
}

async function fetchWithRetry(url: string, token: string): Promise<Response> {
    try {
        return await fetch(url, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
    } catch (error) {
        console.error("Error occurred while fetching:", error);
        return fetch(url, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
    }
}

export async function getUsState(
    position: Position | LocationPosition,
): Promise<{ stateName: string; stateCode: string }> {
    let accessToken = await getAccessToken(APPLE_MAPS_TOKEN!);

    const url = `https://maps-api.apple.com/v1/reverseGeocode?loc=${position.latitude},${position.longitude}`;

    let appleMapsResponse = await fetchWithRetry(url, accessToken);

    // refresh token
    if (appleMapsResponse.status === 401) {
        accessToken = await getAccessToken(APPLE_MAPS_TOKEN!);

        appleMapsResponse = await fetchWithRetry(url, accessToken);
    }

    if (!appleMapsResponse.ok) {
        throw new MapsClientError("Failed to get state for location", { responseStatus: appleMapsResponse.status });
    }

    const appleMapsJson = await appleMapsResponse.json();

    if (appleMapsJson.results.length === 0) {
        throw new MapsClientError("No results found for location", { response: appleMapsJson });
    }

    const { administrativeAreaCode, administrativeArea } = appleMapsJson.results[0].structuredAddress;

    if (!administrativeAreaCode || !administrativeArea) {
        throw new MapsClientError("No state found for location", { response: appleMapsJson });
    }

    if (!stateIsoCodes.includes(administrativeAreaCode?.toLowerCase())) {
        throw new MapsClientError("No valid state found for location", { administrativeAreaCode });
    }

    const stateName =
        US_STATES.find((state) => state.abbreviation.toLowerCase() === administrativeAreaCode.toLowerCase())?.name ??
        "";

    return { stateName, stateCode: administrativeAreaCode };
}

function mergeEtaResults(routeMetadata: RouteMetadata[]): ComputeRoutesResult {
    const distanceMeters = routeMetadata.reduce((sum, metadata) => sum + metadata.distanceMeters, 0);
    const durationSeconds = routeMetadata.reduce((sum, metadata) => sum + metadata.durationSeconds, 0);
    const legs = routeMetadata.map((metadata) => ({
        distanceMeters: metadata.distanceMeters,
        durationSeconds: metadata.durationSeconds,
    }));
    return {
        distanceMeters,
        durationSeconds,
        legs,
    };
}
