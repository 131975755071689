import JSCookie from "js-cookie";

import { cookies } from "../../config.management";

export const getManagementAuthenticationToken = () => JSCookie.get(cookies.authentication) ?? "";

export const setManagementAuthenticationToken = (token: string) => JSCookie.set(cookies.authentication, token);

export const getManagementRefreshToken = () => JSCookie.get(cookies.refresh) ?? "";

export const setManagementRefreshToken = (token: string) => JSCookie.set(cookies.refresh, token);
