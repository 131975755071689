import type { Region } from "@legacy/models/Region";

type RegionOptions = { value: string | null; label: string }[];

const mapRegionsToRegionOptions = (regions: Array<Region>): RegionOptions => {
    return regions.map((region: Region) => ({ label: region.englishName, value: region._id }));
};

export const prepareRegionOptionsWithNull = (regions?: Array<Region>): RegionOptions => {
    const noRegionOption = { label: "No Region", value: "None" };

    if (!regions) return [noRegionOption];

    const options = mapRegionsToRegionOptions(regions);
    options.push(noRegionOption);

    return options;
};

export const prepareRegionOptions = (regions?: Array<Region>): RegionOptions => {
    return regions ? mapRegionsToRegionOptions(regions) : [];
};

/**
 * Due to the fact that we are using react-select-legacy which doesn't render label for null value,
 * we have to use different value for "No region" option – "None".
 *
 * Here we are replacing the "None" value with null so that it can be used in the backend.
 *
 * @param routerRegionValues values from the router, which equals to selected values
 * @returns same array but with "None" value replaced with null
 */
export const processRouterRegionValues = (
    routerRegionValues: Array<string> | undefined,
): Array<string | null> | undefined => {
    if (!routerRegionValues) return routerRegionValues;
    return routerRegionValues.map((value: string) => (value === "None" ? null : value));
};
