import type { Location } from "@daytrip/legacy-models";
import type { Ride } from "@daytrip/legacy-models";
import { AssignationWithAnonymizedOrder } from "@legacy/domain/AssignationWithAnonymizedOrder";
import type { Compensation } from "@legacy/models/Compensation";
import type { Penalty } from "@legacy/models/Penalty";
import type { Subsidy } from "@legacy/models/Subsidy";
import { action, observable } from "mobx";

import { AssignationStatus, AssignationWithOrderOperator } from "../../operators/AssignationWithOrderOperator";
import type { PageRouter } from "../../PageRouter";
import { PageStore } from "../../stores/PageStore";

const LIMIT = 10;

export class DriversCompanyDeclinedAndCancelledTripsPageStore extends PageStore<PageRouter, {}> {
    @observable
    public isLoading: boolean = true;

    @observable
    public error: string | undefined = undefined;

    @observable
    public declinedAssignationOperators: AssignationWithOrderOperator[] = [];

    @observable
    public declinedIsLoadingAssignationOperators: boolean = false;

    @observable
    public declinedErrorAssignationOperators: string | undefined = undefined;

    @observable
    public haveMoreDeclined: boolean = true;

    @observable
    public cancelledAssignationOperators: AssignationWithOrderOperator[] = [];

    @observable
    public cancelledIsLoadingAssignationOperators: boolean = false;

    @observable
    public cancelledErrorAssignationOperators: string | undefined = undefined;

    @observable
    public haveMoreCancelled: boolean = true;

    @observable
    public subsidies: Array<Subsidy> = [];

    @observable
    public penalties: Array<Penalty> = [];

    @observable
    public compensations: Array<Compensation> = [];

    @observable
    public locations: Array<Location> = [];

    @observable
    public rides: Array<Ride> = [];

    @action
    private setIsLoading(status: AssignationStatus, isLoading: boolean) {
        if (status === AssignationStatus.Declined) {
            this.declinedIsLoadingAssignationOperators = isLoading;
        } else if (status === AssignationStatus.Cancelled) {
            this.cancelledIsLoadingAssignationOperators = isLoading;
        }
    }

    @action
    private setError(status: AssignationStatus, error: string | undefined) {
        if (status === AssignationStatus.Declined) {
            this.declinedErrorAssignationOperators = error;
        } else if (status === AssignationStatus.Cancelled) {
            this.cancelledErrorAssignationOperators = error;
        }
    }

    @action
    private getSkip(status: AssignationStatus) {
        if (status === AssignationStatus.Declined) {
            return this.declinedAssignationOperators?.length;
        }
        if (status === AssignationStatus.Cancelled) {
            return this.cancelledAssignationOperators?.length;
        }
        return undefined;
    }

    @action
    private async fetchAssignations(
        status: AssignationStatus,
    ): Promise<{ data: AssignationWithAnonymizedOrder[]; hasMore: boolean }> {
        const response = await this.rpcClient.assignation.retrieveCompanyDriversAssignationWithAnonymizedOrder({
            statusIn: [status],
            limit: LIMIT + 1,
            skip: this.getSkip(status),
        });
        const hasMore = response.length > LIMIT;
        const data = response.slice(0, LIMIT);
        return {
            data,
            hasMore,
        };
    }

    @action
    private async fetchContent(assignations: AssignationWithAnonymizedOrder[]) {
        const alreadyFetchedSubsidyIds = this.subsidies.map((subsidy) => subsidy._id);
        const alreadyFetchedPenaltyIds = this.penalties.map((penalty) => penalty._id);
        const alreadyFetchedCompensationIds = this.compensations.map((compensation) => compensation._id);
        const alreadyFetchedlocationIds = this.locations.map((location) => location._id);
        const alreadyFetchedRideIds = this.rides.map((ride) => ride._id);

        const subsidyIds: string[] = [];
        const penaltyIds: string[] = [];
        const compensationIds: string[] = [];
        const locationIds: string[] = [];
        const rideIds: string[] = [];

        assignations.forEach((assignation) => {
            subsidyIds.push(...assignation.subsidyIds.filter((subsidy) => !alreadyFetchedSubsidyIds.includes(subsidy)));
            penaltyIds.push(...assignation.penaltyIds.filter((penalty) => !alreadyFetchedPenaltyIds.includes(penalty)));
            compensationIds.push(
                ...assignation.compensationIds.filter(
                    (compensation) => !alreadyFetchedCompensationIds.includes(compensation),
                ),
            );

            if (assignation.rideId && !alreadyFetchedRideIds.includes(assignation.rideId)) {
                rideIds.push(assignation.rideId);
            }
            if (!alreadyFetchedlocationIds.includes(assignation.order.originLocationId)) {
                locationIds.push(assignation.order.originLocationId);
            }
            if (!alreadyFetchedlocationIds.includes(assignation.order.destinationLocationId)) {
                locationIds.push(assignation.order.destinationLocationId);
            }
            locationIds.push(
                ...assignation.order.contentLocations
                    .map((cl) => cl.locationId)
                    .filter((location) => !alreadyFetchedlocationIds.includes(location)),
            );
        });

        const holdRidesMap = rideIds.map((rideId) => this.rpcClient.ride.retrieveRide(rideId));
        const holdRides = await Promise.all(holdRidesMap);
        this.rides.push(...holdRides);
        this.rides.forEach((ride) => {
            if (!alreadyFetchedlocationIds.includes(ride.originLocationId)) {
                locationIds.push(ride.originLocationId);
            }
            if (!alreadyFetchedlocationIds.includes(ride.destinationLocationId)) {
                locationIds.push(ride.destinationLocationId);
            }
        });

        const [holdSubsidies, holdPenalties, holdCompensations, holdLocations] = await Promise.all([
            this.rpcClient.assignation.retrieveSubsidies({ ids: subsidyIds }),
            this.rpcClient.assignation.retrievePenalties({ ids: penaltyIds }),
            this.rpcClient.assignation.retrieveCompensations({ ids: compensationIds }),
            this.rpcClient.content.retrieveLocations({ ids: locationIds }),
        ]);

        this.subsidies.push(...holdSubsidies);
        this.penalties.push(...holdPenalties);
        this.compensations.push(...holdCompensations);
        this.locations.push(...holdLocations);
    }

    @action
    private findLocation(locationId: string) {
        return this.locations.find((location) => location._id === locationId);
    }

    @action
    private convertAssignation(assignation: AssignationWithAnonymizedOrder): AssignationWithOrderOperator {
        const { order } = assignation;
        const ride = this.rides.find((r) => r._id === assignation.rideId);
        const origin = ride ? this.findLocation(ride.originLocationId)! : this.findLocation(order.originLocationId)!;
        const destination = ride
            ? this.findLocation(ride.destinationLocationId)!
            : this.findLocation(order.destinationLocationId)!;
        const contentLocationIds = order.contentLocations.map((cl) => cl.locationId);
        const contentLocations = this.locations.filter((location) => contentLocationIds.includes(location._id));
        return new AssignationWithOrderOperator(assignation, origin, destination, contentLocations);
    }

    @action
    private async fetchAndConvertAssignations(
        status: AssignationStatus,
    ): Promise<{ data: AssignationWithOrderOperator[]; hasMore: boolean } | undefined> {
        this.setIsLoading(status, true);
        this.setError(status, undefined);
        try {
            const { data: assignations, hasMore } = await this.fetchAssignations(status);
            await this.fetchContent(assignations);
            const convertedAssignations = assignations.map((assignation) => this.convertAssignation(assignation));
            return {
                data: convertedAssignations,
                hasMore,
            };
        } catch (e) {
            console.error(JSON.stringify(e, undefined, 2), e);
            this.setError(status, "Something went wrong!");
        } finally {
            this.setIsLoading(status, false);
        }
        return undefined;
    }

    @action
    public async loadMoreDeclinedAssignations() {
        const assignations = await this.fetchAndConvertAssignations(AssignationStatus.Declined);
        if (!assignations) {
            return;
        }
        const data = assignations?.data;
        const hasMore = assignations?.hasMore;
        if (data) {
            this.declinedAssignationOperators.push(...data);
            this.haveMoreDeclined = hasMore;
        }
    }

    @action
    public async loadMoreCancelledAssignations() {
        const assignations = await this.fetchAndConvertAssignations(AssignationStatus.Cancelled);
        if (!assignations) {
            return;
        }
        const data = assignations?.data;
        const hasMore = assignations?.hasMore;
        if (data) {
            this.cancelledAssignationOperators.push(...data);
            this.haveMoreCancelled = hasMore;
        }
    }

    @action
    public async onFetchData() {
        this.isLoading = true;
        this.error = undefined;
        try {
            const [declinedAssignationOperatorsResult, cancelledAssignationOperatorsResult] = await Promise.all([
                this.fetchAssignations(AssignationStatus.Declined),
                this.fetchAssignations(AssignationStatus.Cancelled),
            ]);
            const { data: declinedAssignationOperators, hasMore: hasMoreDeclinedAssignations } =
                declinedAssignationOperatorsResult;

            const { data: cancelledAssignationOperators, hasMore: hasMoreCancelledAssignations } =
                cancelledAssignationOperatorsResult;

            await this.fetchContent([...declinedAssignationOperators, ...cancelledAssignationOperators]);
            this.declinedAssignationOperators.push(
                ...declinedAssignationOperators.map((assignation) => this.convertAssignation(assignation)),
            );
            this.cancelledAssignationOperators.push(
                ...cancelledAssignationOperators.map((assignation) => this.convertAssignation(assignation)),
            );
            this.haveMoreDeclined = hasMoreDeclinedAssignations;
            this.haveMoreCancelled = hasMoreCancelledAssignations;
        } catch (e) {
            console.error(JSON.stringify(e, undefined, 2), e);
            this.error = "Something went wrong";
        } finally {
            this.isLoading = false;
        }
    }

    public isDataFetched(): this is DriversCompanyDeclinedAndCancelledTripsPageStore {
        return !this.isLoading;
    }
}
