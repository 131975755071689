import type { Currency } from "@daytrip/legacy-enums";
import type { CommissionPayoutOrder } from "@daytrip/legacy-models";

export class CommissionSettlement {
    public _id: string;

    public hostAgencyId: string;

    public startDate: Date;

    public endDate: Date;

    public totalAmount: number;

    public currency: Currency;

    public createdAt: Date;

    public updatedAt: Date;

    public url?: string;

    public commissionPayoutOrders: CommissionPayoutOrder[];
}
