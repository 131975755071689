import type { SortingPaginationOptions } from "@daytrip/legacy/source/options/SortingPaginationOptions";
import type { ApiPartnerModel as ApiPartner } from "@daytrip/api";
import autobind from "autobind-decorator";
import { action, observable } from "mobx";

import { PageStore } from "../../stores/PageStore";

import type { ApiPartnersPageRouter } from "./ApiPartnersPageRouter";

@autobind
export class ApiPartnersPageStore extends PageStore<ApiPartnersPageRouter, {}> {
    @observable
    public apiPartners?: ApiPartner[];

    @observable
    public apiPartnersCount?: number;

    @action
    public async onFetchData() {
        await this.fetchContent();
    }

    @action
    public async fetchContent(): Promise<void> {
        const options = {
            skip: this.pageRouter.skip - 1,
            limit: this.pageRouter.limit,
            sortBy: this.pageRouter.sortBy,
            sortDirection: this.pageRouter.sortDirection,
            searchString: this.pageRouter.search,
        } as SortingPaginationOptions<ApiPartner>;

        const apiPartnersResponse = await this.rpcClient.apiPartner.getApiPartners(options);

        this.apiPartners = apiPartnersResponse.data;
        this.apiPartnersCount = apiPartnersResponse.total;
    }

    public isDataFetched(): this is ApiPartnersPageStore & ApiPartnersPageStoreDataFetched {
        return Boolean(this.apiPartners);
    }
}

export interface ApiPartnersPageStoreDataFetched {
    apiPartners: ApiPartner[];
    apiPartnersCount: number;
}
