/* eslint-disable no-nested-ternary */
import type { Country } from "@legacy/models/Country";
import { getDefaultFeeCoefficient } from "@legacy/utils/getVehicleTypeFeeCoefficient";
import { action, computed, observable } from "mobx";

import { PageStore } from "../../stores/PageStore";

import type { CountriesPageRouter } from "./CountriesPageRouter";

export class CountriesPageStore extends PageStore<CountriesPageRouter, null> {
    @observable
    public countries?: Array<Country>;

    @observable
    public search: string = "";

    @computed
    public get filteredCountries(): Array<Country> | undefined {
        if (!this.search || !this.countries) {
            return this.countries;
        }

        const search = this.search.toLowerCase();
        const filteredCountries = this.countries.filter((country) => {
            return (
                country.englishName.toLowerCase().includes(search) ||
                country.localName.toLowerCase().includes(search) ||
                country.isoCode.toLowerCase().includes(search)
            );
        });

        // move countries that start with search to the top
        return filteredCountries.sort((a, b) => {
            if (a.englishName.toLowerCase().startsWith(search)) {
                return -1;
            }
            if (b.englishName.toLowerCase().startsWith(search)) {
                return 1;
            }
            return 0;
        });
    }

    @action.bound
    public countriesSortHandler(propertyName: string) {
        this.pageRouter.setSortBy(propertyName);

        const isSortDesc = this.pageRouter.sortDirection === -1;

        this.countries = this.sortCountries(this.countries, propertyName, isSortDesc);
    }

    @action.bound
    public async fetchContent() {
        const countries = await this.rpcClient.content.retrieveCountries({});

        const pageQuery = this.pageRouter.routerStore.location.query;
        const isSortDesc = Number(pageQuery.sortDirection ?? 1) === -1;

        this.countries = this.sortCountries(countries, pageQuery.sortBy ?? "englishName", isSortDesc);
    }

    @action.bound
    public setSearch(search: string) {
        this.search = search;
    }

    public sortCountries(
        countries: Array<Country> | undefined,
        sortByProperty: string,
        isSortDesc: boolean,
    ): Array<Country> | undefined {
        if (!countries) {
            return undefined;
        }
        return countries.sort((a, b) => {
            let x: string | number | boolean;
            let y: string | number | boolean;
            switch (sortByProperty) {
                case "feeCoefficient":
                    let feeCoefficientA = 0;
                    let feeCoefficientB = 0;
                    try {
                        feeCoefficientA = getDefaultFeeCoefficient(a);
                    } catch (err) {}

                    try {
                        feeCoefficientB = getDefaultFeeCoefficient(b);
                    } catch (err) {}

                    x = feeCoefficientB;
                    y = feeCoefficientA;
                    break;

                default:
                    x = a[sortByProperty];
                    y = b[sortByProperty];
                    break;
            }
            if (!isSortDesc) {
                return x > y ? 1 : y > x ? -1 : 0;
            }

            return x < y ? 1 : y < x ? -1 : 0;
        });
    }

    public async onFetchData() {
        await this.fetchContent();
    }

    public isDataFetched(): this is CountriesPageStore & CountriesPageStoreDataFetched {
        return !!this.countries;
    }
}

export interface CountriesPageStoreDataFetched {
    countries: Array<Country>;
}
