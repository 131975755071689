import { GroupedAssignationOperatorsByRideId } from "../types/GroupedAssignationOperatorsByRideId";
import { AssignationWithOrderOperator } from "../operators/AssignationWithOrderOperator";

export function sortAndGroupAssignationWithOrderOperators(
    assignationWithOrderOperators: AssignationWithOrderOperator[],
): (AssignationWithOrderOperator | GroupedAssignationOperatorsByRideId)[] {
    return assignationWithOrderOperators
        .sort((a, b) => a.order.departureAt.getTime() - b.order.departureAt.getTime())
        .reduce<Array<AssignationWithOrderOperator | GroupedAssignationOperatorsByRideId>>((prev, curr) => {
            if (!curr.assignation.rideId) return [...prev, curr];

            const rideIndex = prev.findIndex(
                (assignation) =>
                    !(assignation instanceof AssignationWithOrderOperator) &&
                    assignation.rideId === curr.assignation.rideId,
            );

            if (rideIndex === -1) {
                const newGroup: GroupedAssignationOperatorsByRideId = {
                    rideId: curr.assignation.rideId,
                    assignationOperators: [curr],
                    ridePrice: curr.ride?.price,
                };
                return [...prev, newGroup];
            }

            const groupedAssignations = prev[rideIndex] as GroupedAssignationOperatorsByRideId;
            groupedAssignations.assignationOperators.push(curr);
            if (!groupedAssignations.ridePrice && curr.ride?.price) {
                groupedAssignations.ridePrice = curr.ride.price;
            }

            return prev;
        }, [] as (AssignationWithOrderOperator | GroupedAssignationOperatorsByRideId)[]);
}
