import type { Location } from "@daytrip/legacy-models";
import type { Country } from "@legacy/models/Country";
import type { Route } from "@legacy/models/Route";
import { RetrieveRoutesOptions } from "@legacy/options/RetrieveRoutesOptions";
import { isUndefinedOrNull } from "@legacy/utils";
import autobind from "autobind-decorator";
import { plainToClass } from "class-transformer";
import { action, observable } from "mobx";

import { routes } from "../../routes";
import { PageStore } from "../../stores/PageStore";

import type { ContentMapPageRouter } from "./ContentMapPageRouter";

@autobind
export class ContentMapPageStore extends PageStore<ContentMapPageRouter, null> {
    @observable
    public countries: Array<Country>;

    @observable
    public locations: Array<Location>;

    @observable
    public routes: Array<Route>;

    @action
    public async onFetchData() {
        this.countries = await this.rpcClient.content.retrieveCountries({});
        this.locations = [];
        this.routes = [];

        await this.fetchContent();
    }

    @action
    public async fetchContent() {
        const { countryIds } = this.pageRouter;
        if (!countryIds?.length) {
            this.locations = [];
            this.routes = [];
            return;
        }

        const routesOptions: RetrieveRoutesOptions = plainToClass(RetrieveRoutesOptions, {
            pricingCountryIds: this.pageRouter.countryIds,
        });

        const [locations, retrievedRoutes] = await Promise.all([
            this.rpcClient.content.retrieveLocations({ countryIds }),
            this.rpcClient.content.retrieveRoutes(routesOptions),
        ]);
        this.routes = retrievedRoutes;
        this.locations = locations;
    }

    public isDataFetched(): this is ContentMapPageStore & ContentMapPageStoreDataFetched {
        return (
            !isUndefinedOrNull(this.countries) && !isUndefinedOrNull(this.routes) && !isUndefinedOrNull(this.locations)
        );
    }

    public goToLocation(locationId: string) {
        this.pageRouter.routerStore.push<{ locationId: string }>({
            pathname: routes.location,
            query: { locationId } as any,
        });
    }

    public goToRoute(routeId: string) {
        this.pageRouter.routerStore.push<{ routeId: string }>({ pathname: routes.route, query: { routeId } as any });
    }
}

export interface ContentMapPageStoreDataFetched {
    countries: Array<Country>;
    locations: Array<Location>;
    routes: Array<Route>;
}
