import { Expose } from "class-transformer";
import { IsString } from "class-validator";

import { GInputType, GObjectType } from "../graphql";
import { CDColumn } from "../orm";

@GObjectType("SellingPoint")
@GInputType("SellingPointInput")
export class SellingPoint {
    @CDColumn()
    @IsString()
    @Expose()
    public iconName: string;

    @CDColumn()
    @IsString()
    @Expose()
    public text: string;
}
