import { RejectionType } from "../domain/RejectionType";

type ErrorParameters = {
    confirmationCode?: string;
};

export const alertError = (error: RejectionType | Error, defaultMessage: string, params: ErrorParameters = {}) => {
    let message = defaultMessage;
    const errorMessage = error instanceof Error ? error.message : error;
    if (errorMessage == RejectionType.CanceledOrder) {
        message = "Assignation not possible - you have tried to assign a driver to a cancelled order";
        message += params.confirmationCode != undefined ? ` ${params.confirmationCode}` : "";
    }

    // eslint-disable-next-line no-alert
    alert(message);
};
