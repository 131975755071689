import { User } from "@legacy/models/User";
import type { RetrieveUsersOptions } from "@legacy/options/RetrieveUsersOptions";
import autobind from "autobind-decorator";
import { plainToClass } from "class-transformer";
import { action, observable } from "mobx";

import { PageStore } from "../../stores/PageStore";

import type { CustomersPageRouter } from "./CustomersPageRouter";

@autobind
export class CustomersPageStore extends PageStore<CustomersPageRouter, null> {
    @observable
    public users?: Array<User>;

    @observable
    public usersCount?: number;

    public async onFetchData() {
        await this.fetchContent();
    }

    @action
    public async fetchContent(): Promise<void> {
        this.users = undefined;
        this.usersCount = undefined;

        const options = {
            skip: this.pageRouter.skip,
            limit: this.pageRouter.limit,
            sortBy: this.pageRouter.sortBy,
            sortDirection: this.pageRouter.sortDirection,
            searchString: typeof this.pageRouter.search == "string" ? this.pageRouter.search : this.pageRouter.search,
            isCustomer: true,
        } as RetrieveUsersOptions;

        this.users = plainToClass(User, await this.rpcClient.user.retrieveUsers(options));
        this.usersCount = await this.rpcClient.user.retrieveUsersCount(options);
    }

    public isDataFetched(): this is CustomersPageStore & CustomersPageStoreDataFetched {
        return this.users !== undefined && this.usersCount !== undefined;
    }
}

export interface CustomersPageStoreDataFetched {
    users: Array<User>;
    usersCount: number;
}
