import { transformPhoneNumberToNormalizedForm } from "@daytrip/utils";
import { Expose, Transform } from "class-transformer";
import { IsArray } from "class-validator";

import type { Assignation } from "../models/Assignation";
import type { Vehicle } from "../models/Vehicle";

import { SimpleDriver } from "./SimpleDriver";
import type { VehicleInfo } from "./VehicleInfo";

export class SimpleDriverWithVehicles extends SimpleDriver {
    @IsArray()
    @Expose()
    public vehicles: Array<VehicleInfo>;

    @IsArray()
    @Expose()
    public rawVehicles: Array<Vehicle>;

    @Expose()
    public available?: boolean;

    @Expose()
    public country: string;

    @Expose()
    public dateAssignations: Array<Assignation>;

    @Expose()
    public createdAt: Date;

    @Expose()
    @Transform((value) => transformPhoneNumberToNormalizedForm(value))
    public phoneNumber: string;
}
