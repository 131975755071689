import type { NormalizedCacheObject, ApolloQueryResult } from "@apollo/client";
import { ApolloClient, InMemoryCache, from } from "@apollo/client";

import { authLink } from "./apolloLinks/authLink";
import { errorLink } from "./apolloLinks/errorLink";
import { constructHttpLink } from "./apolloLinks/httpLink";
import { tokenRefreshLink } from "./apolloLinks/tokenRefreshLink";
import { getSdkApollo } from "./apolloRequester";

let apolloClient: ApolloClient<NormalizedCacheObject>;

export type ApolloClientOperation<TQuery, TVariables = undefined> = (
    client: ApolloClient<NormalizedCacheObject>,
    variables: TVariables,
) => Promise<ApolloQueryResult<TQuery>>;

function createApolloClient(initialState?: any) {
    const httpLink = constructHttpLink();
    return new ApolloClient({
        defaultOptions: {
            query: {
                fetchPolicy: "no-cache",
            },
        },
        cache: new InMemoryCache({
            typePolicies: {
                Route: {
                    keyFields: ["_id", "isOtherDirection"],
                },
                BookingData: {
                    keyFields: ["routeId", "originId"],
                },
            },
        }).restore(initialState || {}),
        link: from([tokenRefreshLink(), errorLink, authLink, httpLink]),
    });
}

export function initializeApolloClient() {
    apolloClient = createApolloClient();
}

export function getApolloClient() {
    if (!apolloClient) {
        throw Error("Apollo client is not initialized");
    }
    return apolloClient;
}

export function getApolloSdk() {
    const sdk = getSdkApollo(apolloClient);
    return sdk;
}
