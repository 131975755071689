import { TransformToDate } from "@daytrip/legacy-transformers";
import { Expose } from "class-transformer";
import { IsArray, IsBoolean, IsDate, IsDefined, IsUUID } from "class-validator";

import type { OrderChangesLogItem } from "./OrderChangesLogItem";

export class OrderChangesLog {
    @IsUUID("4")
    @Expose()
    public changedByUserId: string | undefined;

    @Expose()
    public cid: string | undefined;

    @IsDate()
    @IsDefined()
    @Expose()
    @TransformToDate
    public changedAt: Date;

    @IsArray()
    @IsDefined()
    @Expose()
    public changes: Array<OrderChangesLogItem> = [];

    @IsBoolean()
    @IsDefined()
    @Expose()
    public customerNotified?: boolean = false;
}
