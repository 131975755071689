import { validatePhoneNumber } from "@daytrip/utils";

export function getPhoneNumberValidationCustomMessage(phoneNumber: string, allowEmptyPhoneNumber: boolean = false) {
    const phoneValidationMessages = validatePhoneNumber(phoneNumber, allowEmptyPhoneNumber);
    return phoneValidationMessages ? `Invalid phone number with reason: ${phoneValidationMessages}` : undefined;
}

export function throwIfInvalidUserPhoneNumber(phoneNumber: string, allowEmptyPhoneNumber: boolean = false) {
    const phoneNumberValidationMessage = getPhoneNumberValidationCustomMessage(phoneNumber, allowEmptyPhoneNumber);
    if (phoneNumberValidationMessage) {
        throw new Error(phoneNumberValidationMessage);
    }
}

export function getPhoneNumberExtendedValidationMessage(
    phoneNumber: string,
    additionalValidationMessages: string | undefined,
    allowEmptyPhoneNumber: boolean = false,
): string | undefined {
    const phoneValidationMessages = getPhoneNumberValidationCustomMessage(phoneNumber, allowEmptyPhoneNumber);
    if (additionalValidationMessages && phoneValidationMessages)
        return `${additionalValidationMessages}\n${phoneValidationMessages}`;
    return additionalValidationMessages ?? phoneValidationMessages;
}

export function validatePhoneNumberWithAlert(phoneNumber: string, allowEmptyPhoneNumber: boolean = false) {
    const validatePhoneNumberMessage = getPhoneNumberValidationCustomMessage(phoneNumber, allowEmptyPhoneNumber);
    if (validatePhoneNumberMessage) {
        alert(`Oh, something is wrong. :(\n\n${validatePhoneNumberMessage}`);
        return false;
    }
    return true;
}
