import { Exclude, Expose, Transform } from "class-transformer";
import { IsBoolean, IsDefined, IsEmail, IsString, IsUUID, Length } from "class-validator";

@Exclude()
export class SimpleCustomer {
    @IsUUID("4")
    @IsDefined()
    @Expose()
    public _id: string;

    @IsString()
    @IsDefined()
    @Expose()
    public fullName: string;

    @IsEmail()
    @Length(5, 48)
    @Transform((value: string) => value.trim())
    @IsString()
    @IsDefined()
    @Expose()
    public email: string;

    @IsBoolean()
    @Expose()
    public suspiciousCCActivity?: boolean;
}
