import type { ParameterlessConstructor } from "@legacy/utils";

export class StoreManager<StoreType> {
    private _store!: StoreType;

    public getStore(): StoreType {
        return this._store;
    }

    public setStore(store: StoreType): void {
        this._store = store;
    }

    public resetStore(): void {
        this._store = new this.StoreConstructor();
    }

    private StoreConstructor: ParameterlessConstructor<StoreType>;

    public constructor(StoreConstructor: ParameterlessConstructor<StoreType>) {
        this.StoreConstructor = StoreConstructor;

        this.setStore(new StoreConstructor());
    }
}
