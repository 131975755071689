import type { ApolloError } from "@apollo/client";
import { Ride, RIDE_ASSIGNATION_CANCELLED_CANCELLATION_INFO } from "@daytrip/legacy-models";
import { AssignationStatus } from "@legacy/domain/AssignationStatus";
import { Currency } from "@legacy/domain/Currency";
import { Assignation } from "@legacy/models/Assignation";
import type { Location } from "@daytrip/legacy-models";
import type { Order } from "@daytrip/legacy-models";
import type { RideNote } from "@daytrip/legacy-models";
import { transformDurationToString } from "@daytrip/legacy-transformers";
import autobind from "autobind-decorator";
import { classToPlain, plainToClass } from "class-transformer";
import uniq from "lodash/uniq";
import { action, computed, observable } from "mobx";

import { getApolloSdk } from "../../apolloClient/apolloClient";
import { ObservableGqlQuery } from "../../apolloClient/ObservableGqlQuery";
import type { RoutingStore } from "../../container";
import { container, stores } from "../../container";
import { routes } from "../../routes";
import { PageStore } from "../../stores/PageStore";

import type { RidePageRouter } from "./RidePageRouter";
import type { AssignationWithAssignedOrder } from "./types/AssignationWithAssignedOrder";
import type { RideWithOrdersAndAssignations } from "./types/RideGroupWithOrdersAndAssignations";

@autobind
export class RidePageStore extends PageStore<RidePageRouter, {}> {
    private routingStore: RoutingStore;

    @observable
    updateRideMigration = new ObservableGqlQuery(getApolloSdk().UpdateRide);

    @observable
    public loading = false;

    @observable
    public error: ApolloError | undefined;

    public async onInit() {
        this.routingStore = container.get<RoutingStore>(stores.routing);
    }

    @observable
    public ride?: Ride;

    @observable
    public locations?: Location[];

    @observable
    public orders?: Order[];

    @observable
    public rideGroupWithOrdersAndAssignations?: RideWithOrdersAndAssignations[];

    @observable
    public assignationsWithAssignedUser?: AssignationWithAssignedOrder[];

    @observable
    public isSavingFailed = false;

    @computed
    public get currency() {
        // lets take currency from the first order if there is non lets use default which is error
        if (!this.orders || !this.orders[0]) {
            return Currency.Euro;
        }
        return this.orders[0].pricingCurrency ?? Currency.Euro;
    }

    @action
    public async onFetchData() {
        await this.fetchData();
    }

    @action
    public async fetchData() {
        if (!this.pageRouter.rideId) {
            this.routingStore.replace(routes.rides);
            return;
        }

        // Converting to plain to maintain the observability
        const ride = classToPlain(
            plainToClass(Ride, await this.rpcClient.ride.retrieveRide(this.pageRouter.rideId)),
        ) as Ride;

        const locationIds = [ride.originLocationId, ride.destinationLocationId];

        if (ride.stops.length) {
            locationIds.push(...ride.stops.map((stop) => stop.locationId));
        }

        const [locations, orders, assignationsWithAssignedUser] = await Promise.all([
            this.rpcClient.content.retrieveLocations({ ids: locationIds }),
            this.rpcClient.order.retrieveOrders({ ids: ride.orderIds }),
            this.fetchAssignationsData(ride._id),
        ]);

        this.locations = locations;
        this.orders = orders;
        this.ride = ride;
        this.assignationsWithAssignedUser = assignationsWithAssignedUser;

        this.loadRideGroupData(ride._id);
    }

    @action
    public async loadRideGroupData(rideId: string) {
        const rideGroup = await this.rpcClient.ride.retrieveRideGroup(rideId);
        const rideGroupWithoutSelectedRide = rideGroup.filter((ride) => ride._id !== rideId);

        const rideGroupOrderIds: string[] = [];
        rideGroupWithoutSelectedRide.forEach((ride) => rideGroupOrderIds.push(...ride.orderIds));

        const rideGroupOrders = await this.rpcClient.order.retrieveOrders({ ids: rideGroupOrderIds });
        const rideGroupAssignationsWithAssignedUser = await Promise.all(
            rideGroup.map((ride) => this.fetchAssignationsData(ride._id)),
        );

        this.rideGroupWithOrdersAndAssignations = rideGroupWithoutSelectedRide.map((ride) => ({
            ride,
            assignationsWithAssignedUser: rideGroupAssignationsWithAssignedUser.find(
                (assignations) => assignations?.find((assignation) => assignation.assignation.rideId === ride._id),
            ),
            orders: rideGroupOrders.filter((order) => ride.orderIds.includes(order._id)),
        }));
    }

    @action
    public async fetchAssignationsData(rideId: string) {
        const assignations = await this.rpcClient.ride.retrieveAssignations(rideId);

        if (!assignations?.length) return [];

        const pendingOrAcceptedAssignations = plainToClass(Assignation, assignations).filter((assignation) =>
            [AssignationStatus.Pending, AssignationStatus.Accepted].includes(assignation.status),
        );
        const assignedUserIds = uniq(pendingOrAcceptedAssignations.map((assignation) => assignation.userId));
        const assignedUsers = await this.rpcClient.user.retrieveUsers({ userIds: assignedUserIds });

        return pendingOrAcceptedAssignations.map((assignation) => ({
            assignation,
            assignedUser: assignedUsers.find((user) => user._id === assignation.userId)!,
        }));
    }

    @action
    public clearIsSavingFailed() {
        this.isSavingFailed = false;
    }

    @computed
    public get originLocation(): Location | undefined {
        if (!this.ride) return undefined;

        return this.locations?.find((location) => location._id === this.ride!.originLocationId);
    }

    @computed
    public get destinationLocation(): Location | undefined {
        if (!this.ride) return undefined;

        return this.locations?.find((location) => location._id === this.ride!.destinationLocationId);
    }

    @computed
    public get formattedStops(): string | undefined {
        if (!this.ride || this.ride.stops.length === 0 || this.locations === undefined) return undefined;

        const formattedStops = this.ride.stops.map((stop) => {
            const stopLocation = this.locations?.find((location) => location._id === stop.locationId)!;

            return `${stopLocation.name} (${transformDurationToString(stop.duration)})`;
        });

        return formattedStops.join(", ");
    }

    public async createRideAssignation(userId: string) {
        const rideId = this.ride!._id;
        await this.rpcClient.ride.createAssignations(rideId, userId);
        this.assignationsWithAssignedUser = await this.fetchAssignationsData(rideId);
    }

    public async declineRideAssignation(reason: string) {
        const rideId = this.ride!._id;
        await this.rpcClient.ride.declineAssignations(rideId, reason);
        this.assignationsWithAssignedUser = await this.fetchAssignationsData(rideId);
    }

    public async cancelRideAssignation(reason: string) {
        const rideId = this.ride!._id;
        await this.rpcClient.ride.cancelAssignations(
            rideId,
            { ...RIDE_ASSIGNATION_CANCELLED_CANCELLATION_INFO, note: reason },
            true,
        );
        this.assignationsWithAssignedUser = await this.fetchAssignationsData(rideId);
    }

    @action
    public async createRideInRideGroup() {
        const rideId = this.ride!._id;
        const newRideId = await this.rpcClient.ride.duplicateRide(rideId);
        const newRide = await this.rpcClient.ride.retrieveRide(newRideId);
        this.rideGroupWithOrdersAndAssignations = [
            ...(this.rideGroupWithOrdersAndAssignations ?? []),
            {
                ride: newRide,
                orders: [],
            },
        ];
    }

    @action
    public async deleteRide(rideId: string) {
        try {
            await this.rpcClient.ride.deleteRide(rideId);
            this.rideGroupWithOrdersAndAssignations = this.rideGroupWithOrdersAndAssignations?.filter(
                (rideGroup) => rideGroup.ride._id !== rideId,
            );
        } catch (error) {
            this.isSavingFailed = true;
        }
    }

    @action
    public async updateAvailableSeats(rideId: string, availableSeats: number) {
        try {
            await this.rpcClient.ride.setAvailableSeats(rideId, availableSeats);

            if (this.ride?._id === rideId) {
                this.ride.availableSeats = availableSeats;
            }

            const updatedRideIndex = this.rideGroupWithOrdersAndAssignations?.findIndex(
                (ride) => ride.ride._id === rideId,
            );
            if (updatedRideIndex !== undefined && updatedRideIndex > -1) {
                this.rideGroupWithOrdersAndAssignations![updatedRideIndex!].ride.availableSeats = availableSeats;
            }
        } catch (error) {
            this.isSavingFailed = true;
        } finally {
            this.fetchData();
        }
    }

    @action
    public async addNote(text: string) {
        alert(`Adding text ${text} cancelled. Adding note to the ride is no longer supported here.`);
    }

    @action
    private async updateNotes(notes: RideNote[]) {
        try {
            await this.rpcClient.ride.setNotes(this.ride._id, notes);
            this.ride.notes = notes;
        } catch (error) {
            this.isSavingFailed = true;
        }
    }

    public async assignOrderToRide(orderId: string, rideId: string) {
        let movedOrder: Order | null = null;

        // remove order from current ride in UI
        if (this.orders) {
            const orderIndex = this.orders.findIndex((order) => order._id === orderId);
            if (orderIndex > -1) {
                movedOrder = this.orders[orderIndex];
                this.orders.splice(orderIndex, 1);
            }
        }

        if (!movedOrder && this.rideGroupWithOrdersAndAssignations) {
            this.rideGroupWithOrdersAndAssignations = this.rideGroupWithOrdersAndAssignations?.map((rideWithOrders) => {
                const orderIndex = rideWithOrders.orders.map((order) => order._id).indexOf(orderId);

                if (orderIndex === -1) {
                    return rideWithOrders;
                }

                movedOrder = rideWithOrders.orders[orderIndex];

                return {
                    ride: rideWithOrders.ride,
                    orders: rideWithOrders.orders.filter((order) => order._id !== orderId),
                };
            });
        }

        // add order to current ride in UI
        if (this.ride?._id === rideId) {
            this.orders = [...this.orders!, movedOrder!];
        } else {
            this.rideGroupWithOrdersAndAssignations = this.rideGroupWithOrdersAndAssignations?.map((rideWithOrders) => {
                if (rideWithOrders.ride._id !== rideId) {
                    return rideWithOrders;
                }

                return {
                    ride: rideWithOrders.ride,
                    orders: [...rideWithOrders.orders, movedOrder!],
                };
            });
        }

        try {
            await this.rpcClient.ride.lockRideOrder(rideId, orderId);
        } catch (error) {
            this.isSavingFailed = true;
        } finally {
            this.fetchData();
        }
    }

    public isDataFetched(): this is RidePageStore & RidePageStoreDataFetched {
        return (
            this.ride !== undefined &&
            this.originLocation !== undefined &&
            this.destinationLocation !== undefined &&
            this.orders !== undefined
        );
    }

    public async updatePrice(price: number) {
        if (!this.pageRouter.rideId) {
            this.routingStore.replace(routes.rides);
            return;
        }
        const { rideId } = this.pageRouter;
        this.loading = true;
        try {
            await this.updateRideMigration.execute({ ride: { _id: rideId, price } });
            await this.fetchData();
        } catch (error) {
            this.error = error;
        }
        this.loading = false;
    }
}

export interface RidePageStoreDataFetched {
    ride: Ride;
    originLocation: Location;
    destinationLocation: Location;
    orders: Order[];
}
