import { Type } from "class-transformer";

import { User } from "../models/User";

import type { AssignationStatus } from "./AssignationStatus";
import { SimpleOrder } from "./SimpleOrder";

export class AssignationDataForPreview {
    public assignationId: string;

    public assignationStatus: AssignationStatus;

    @Type(() => User)
    public user: User;

    @Type(() => SimpleOrder)
    public simpleOrder: SimpleOrder;
}
