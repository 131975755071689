import { LuggageType } from "@legacy/domain/LuggageType";
import { VehicleType } from "@legacy/domain/VehicleType";
import { Passenger } from "@daytrip/legacy-models";

import { getOptimalVehiclesByPassengersAndLuggage } from "./getOptimalVehiclesByPassengersAndLuggage";

export function calculateOptimalVehiclesForPassengers(
    passengers: Array<Passenger>,
    isPool = false,
): Array<VehicleType> {
    const remainingPassengers = passengers.length;
    let remainingSuitcases = 0;
    let remainingCarryOns = 0;
    passengers.forEach((p) => {
        p.luggage.forEach((l) => {
            if (l === LuggageType.Suitcase) {
                remainingSuitcases += 1;
            } else if (l === LuggageType.CarryOn) {
                remainingCarryOns += 1;
            }
        });
    });

    return getOptimalVehiclesByPassengersAndLuggage(remainingPassengers, remainingSuitcases, remainingCarryOns, isPool);
}
