import autobind from "autobind-decorator";
import { action, observable } from "mobx";

import { Language } from "@legacy/models/Language";
import { PageStore } from "../../stores/PageStore";

import { LanguagesPageRouter } from "./LanguagesPageRouter";

@autobind
export class LanguagesPageStore extends PageStore<LanguagesPageRouter, null> {
    @observable
    public languages: Language[] = [];

    @action
    public async onFetchData() {
        await this.fetchLanguages();
    }

    public sanitizeForComparison(item: any): string {
        return String(item).trim().toLowerCase();
    }

    @action
    public sort() {
        const q = this.pageRouter.routerStore.location.query;
        const sortBy = q.sortBy || "isoCode";
        const sortDirection = q.sortDirection || -1;
        this.languages = this.languages.sort((a: Language | string, b: Language | string) => {
            let o: number;
            a = this.sanitizeForComparison(a[sortBy]);
            b = this.sanitizeForComparison(b[sortBy]);
            if (sortDirection == 1) {
                o = a > b ? -1 : 1;
            } else {
                o = a > b ? 1 : -1;
            }
            return o;
        });
    }

    @action
    public async toggleChecked(_id: string) {
        const enabled = !(this.languages.find((lang) => _id === lang._id) as Language).isEnabled;
        await this.rpcClient.content.toggleLanguage(_id, enabled);
        await this.fetchLanguages();
    }

    @action
    public async fetchLanguages() {
        this.languages = await this.rpcClient.content.getAllLanguages();
    }

    public isDataFetched(): boolean {
        return this.languages != undefined && !!this.languages.length;
    }
}
