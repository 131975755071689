import { Expose } from "class-transformer";

import { MaxLengthExcludingParameters } from "../domain/MaxLengthExcludingParameters";

export class Ad {
    // text: is just first part of parameeter, length is with } at the end and : between parameter and default value
    public static customizedParameters = [{ text: "{=Ad customizers PRICES.Price", length: 31 }];

    @Expose()
    // Google adgroup id
    public adGroupId: number;

    @Expose()
    public type: string = "ExpandedTextAd";

    @Expose()
    public finalUrls: string[];

    @MaxLengthExcludingParameters(Ad.customizedParameters, 30, {})
    @Expose()
    public headlinePart1: string;

    @MaxLengthExcludingParameters(Ad.customizedParameters, 30, {})
    @Expose()
    public headlinePart2: string;

    @MaxLengthExcludingParameters(Ad.customizedParameters, 30, {})
    @Expose()
    public headlinePart3: string;

    @MaxLengthExcludingParameters(Ad.customizedParameters, 90, {})
    @Expose()
    public description: string;

    @MaxLengthExcludingParameters(Ad.customizedParameters, 90, {})
    @Expose()
    public description2: string;

    @Expose()
    public status: string = "ENABLED";

    @Expose()
    public approvalStatus: string = "UNCHECKED";

    @Expose()
    public trademarkDisapproved: boolean = false;
}
