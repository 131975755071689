import type { OrderContentLocation } from "@daytrip/legacy-models";
import type { VehicleTypePriceFee } from "@daytrip/legacy-models";
import type { Order } from "@daytrip/legacy-models";
import { isUndefinedOrNull } from "@daytrip/utils";

import type { Discount } from "../models/Discount";

import { DiscountType } from "./DiscountType";
import type { VehicleType } from "./VehicleType";

export class PriceCalculator {
    public static calculateFeeFromPrice(price: number, feeCoefficient: number): number {
        return price / feeCoefficient - price;
    }

    public static calculateWaitingPriceFromDuration(duration: number, guidePrice: number): number {
        return (duration / 60) * guidePrice;
    }

    public static calculatePriceAndFeeFromValue(value: number, feeCoefficient: number): { price: number; fee: number } {
        const price = Math.round(value * feeCoefficient);
        const fee = Math.round(value - price);

        return { price, fee };
    }

    public static multiplyByCoefficient(value: number, coefficient?: number): number {
        return isUndefinedOrNull(coefficient) ? value : value * coefficient;
    }

    public static multiplyByCoefficientAndRound(value: number, coefficient?: number): number {
        return Math.round(this.multiplyByCoefficient(value, coefficient));
    }

    public static calculateRouteVehiclePriceFromDistance(
        distance: number,
        amortisationMinimalPrice: number,
        amortisationPrice: number,
        consumptionPrice: number,
    ): number {
        return Math.max(amortisationMinimalPrice, distance * amortisationPrice * 2) + distance * consumptionPrice * 2;
    }

    public static calculateRouteGuidePriceFromDuration(
        duration: number,
        guideMinimalPrice: number,
        guidePrice: number,
    ): number {
        return Math.max(guideMinimalPrice, (duration / 60) * guidePrice * 2);
    }

    public static calculateVehicleDetourPriceFromDistance(
        distance: number,
        amortisationPrice: number,
        consumptionPrice: number,
    ): number {
        return distance * (amortisationPrice + consumptionPrice);
    }

    public static calculateOrderPrice(
        order: Order,
        discounts: Discount[],
        countTravelAgentDiscount: boolean = true,
    ): number {
        let priceDiscount = 0;

        if (!isUndefinedOrNull(discounts) && discounts.length > 0) {
            priceDiscount = discounts
                // for cases when full price is displayed
                .filter((d) => countTravelAgentDiscount || d.type !== DiscountType.TravelAgent)
                .filter((d) => isUndefinedOrNull(d.priceDiscountCoefficient))
                .reduce((amount: number, discount: Discount) => amount + discount.price, 0);
        }

        let priceDiscountCoefficient = 0;

        if (!isUndefinedOrNull(discounts) && discounts.length > 0) {
            priceDiscountCoefficient = discounts
                // for cases when full price is displayed
                .filter((d) => countTravelAgentDiscount || d.type !== DiscountType.TravelAgent)
                .filter((d) => !isUndefinedOrNull(d.priceDiscountCoefficient))
                .reduce((total: number, discount: Discount) => total + (discount.priceDiscountCoefficient ?? 0), 0);
        }

        const price = order.vehicles.reduce<number>((sum, vehicleType) => {
            const vehiclePrice = this.calculateVehiclePrice(order, vehicleType);
            const vehicleFee = this.calculateVehicleFee(order, vehicleType);
            const totalPrice = vehiclePrice + vehicleFee;
            const totalPriceDiscount = Math.round(totalPrice * priceDiscountCoefficient);
            return sum + Math.round(vehiclePrice - totalPriceDiscount);
        }, 0);

        return Math.round(price - priceDiscount);
    }

    public static calculateVehiclePrice(order: Order, vehicleType: VehicleType): number {
        const baseVehicleTypePriceFee = order.vehicleTypesPricesFees.find(
            (vtpf) => vtpf.vehicleType === vehicleType,
        ) as VehicleTypePriceFee;
        if (!baseVehicleTypePriceFee) {
            throw new Error(`Order ${order._id} does not have pricing defined for vehicle type ${vehicleType}`);
        }
        let priceAdjustmentCoefficient = order.priceAdjustmentCoefficient;
        if (order.vehicleCoefficients?.length) {
            const vehicleCoefficient = order.vehicleCoefficients.find(
                (coefficient) => coefficient.vehicleType === vehicleType,
            );
            if (vehicleCoefficient) {
                priceAdjustmentCoefficient = vehicleCoefficient.priceAdjustmentCoefficient;
            }
        }
        const basePrice = PriceCalculator.multiplyByCoefficientAndRound(
            baseVehicleTypePriceFee.price,
            priceAdjustmentCoefficient,
        );

        const locationsPrice = order.contentLocations.reduce<number>(
            (oclSum, ocl) => oclSum + PriceCalculator.calculateContentLocationPrice(ocl, vehicleType),
            0,
        );

        const customLocationsPrice = order.customLocations.reduce<number>((oclSum, ocl) => {
            const oclVehicleTypePriceFee = ocl.vehicleTypesPricesFees.find(
                (vtpf) => vtpf.vehicleType === vehicleType,
            ) as VehicleTypePriceFee;
            return oclSum + ocl.waitingPrice + (oclVehicleTypePriceFee?.price ?? 0);
        }, 0);

        return Math.round(
            order.tollPrice * 2 + order.additionalPrice + basePrice + locationsPrice + customLocationsPrice,
        );
    }

    public static calculateOrderFee(
        order: Order,
        discounts: Discount[],
        countTravelAgentDiscount: boolean = true,
    ): number {
        let feeDiscount = 0;

        if (discounts && discounts.length > 0) {
            feeDiscount = discounts
                // for cases when full price is displayed
                .filter((d) => countTravelAgentDiscount || d.type !== DiscountType.TravelAgent)
                .filter((d) => isUndefinedOrNull(d.feeDiscountCoefficient))
                .reduce((amount: number, discount: Discount) => amount + discount.fee, 0);
        }

        let feeDiscountCoefficient = 0;

        if (!isUndefinedOrNull(discounts) && discounts.length > 0) {
            feeDiscountCoefficient = discounts
                // for cases when full price is displayed
                .filter((d) => countTravelAgentDiscount || d.type !== DiscountType.TravelAgent)
                .filter((d) => !isUndefinedOrNull(d.feeDiscountCoefficient))
                .reduce((total: number, discount: Discount) => total + (discount.feeDiscountCoefficient ?? 0), 0);
        }

        const fee = order.vehicles.reduce<number>((sum, vehicleType) => {
            const vehiclePrice = this.calculateVehiclePrice(order, vehicleType);
            const vehicleFee = this.calculateVehicleFee(order, vehicleType);
            const totalPrice = vehiclePrice + vehicleFee;
            const totalFeeDiscount = Math.round(totalPrice * feeDiscountCoefficient);
            return sum + Math.round(vehicleFee - totalFeeDiscount);
        }, 0);

        return Math.round(fee - feeDiscount);
    }

    public static calculateVehicleFee(order: Order, vehicleType: VehicleType): number {
        const baseVehicleTypePriceFee = order.vehicleTypesPricesFees.find(
            (vtpf) => vtpf.vehicleType === vehicleType,
        ) as VehicleTypePriceFee;
        if (!baseVehicleTypePriceFee) {
            throw new Error(`Order ${order._id} does not have pricing defined for vehicle type ${vehicleType}`);
        }
        let feeAdjustmentCoefficient = order.feeAdjustmentCoefficient ?? order.priceAdjustmentCoefficient;
        if (order.vehicleCoefficients?.length) {
            const vehicleCoefficient = order.vehicleCoefficients.find(
                (coefficient) => coefficient.vehicleType === vehicleType,
            );
            if (vehicleCoefficient) {
                feeAdjustmentCoefficient = vehicleCoefficient.feeAdjustmentCoefficient;
            }
        }
        const baseFee = PriceCalculator.multiplyByCoefficientAndRound(
            baseVehicleTypePriceFee.fee,
            feeAdjustmentCoefficient,
        );

        const locationsFee = order.contentLocations.reduce<number>(
            (oclSum, ocl) => oclSum + PriceCalculator.calculateContentLocationFee(ocl, vehicleType),
            0,
        );

        const customLocationsFee = order.customLocations.reduce<number>((oclSum, ocl) => {
            const oclVehicleTypePriceFee = ocl.vehicleTypesPricesFees.find(
                (vtpf) => vtpf.vehicleType === vehicleType,
            ) as VehicleTypePriceFee;
            return oclSum + ocl.waitingFee + (oclVehicleTypePriceFee?.fee ?? 0);
        }, 0);

        return Math.round(order.tollFee * 2 + order.additionalFee + baseFee + locationsFee + customLocationsFee);
    }

    public static calculateContentLocationPrice(location: OrderContentLocation, vehicleType: VehicleType): number {
        const oclVehicleTypePriceFee = location.vehicleTypesPricesFees.find(
            (vtpf) => vtpf.vehicleType === vehicleType,
        ) as VehicleTypePriceFee;
        if (!oclVehicleTypePriceFee) {
            throw new Error(
                `Content location ${location.locationId} does not have pricing defined for vehicle type ${vehicleType}`,
            );
        }
        return Math.round(
            location.entrancePrice +
                location.parkingPrice +
                location.tollPrice * 2 +
                location.additionalPrice +
                location.waitingPrice +
                oclVehicleTypePriceFee.price,
        );
    }

    public static calculateContentLocationFee(location: OrderContentLocation, vehicleType: VehicleType): number {
        const oclVehicleTypePriceFee = location.vehicleTypesPricesFees.find(
            (vtpf) => vtpf.vehicleType === vehicleType,
        ) as VehicleTypePriceFee;
        if (!oclVehicleTypePriceFee) {
            throw new Error(
                `Content location ${location.locationId} does not have pricing defined for vehicle type ${vehicleType}`,
            );
        }
        return Math.round(
            location.entranceFee +
                location.parkingFee +
                location.tollFee * 2 +
                location.additionalFee +
                location.waitingFee +
                oclVehicleTypePriceFee.fee,
        );
    }

    public static minusPercent(value: number, percent: number): number {
        return value * (1 - percent / 100);
    }
}
