import { CampaignTypeTargeting } from "../domain/CampaignTypeTargeting";
import { GoogleCampaignSettingType } from "../domain/GoogleCampaignSettingType";

import { transformCountryNameToTargetingLocation } from "./transformCountryNameToTargetingLocation";

const baseCampaignTypeName = {
    E: " E ",
    W: " W ",
};

export const campaignTypeNames = {
    [GoogleCampaignSettingType.Europe]: {
        W: " W ",
        E: " E ",
    },
    [GoogleCampaignSettingType.SouthEastAsia]: {
        SEA: " SEA ",
        W: " W ",
    },
    [GoogleCampaignSettingType.Africa]: baseCampaignTypeName,
    [GoogleCampaignSettingType.MiddleEast]: baseCampaignTypeName,
    [GoogleCampaignSettingType.LatinAmerica]: baseCampaignTypeName,
    [GoogleCampaignSettingType.USA]: baseCampaignTypeName,
    [GoogleCampaignSettingType.AustraliaNewZealand]: baseCampaignTypeName,
};

export const campaignTypes = {
    [GoogleCampaignSettingType.Europe]: [
        new CampaignTypeTargeting(campaignTypeNames[GoogleCampaignSettingType.Europe].W, [
            ...transformCountryNameToTargetingLocation(["United States"]),
            // ...transformCountryNameToTargetingLocation([
            //     "Australia", "Canada", "Taiwan (Republic of China)", "Malaysia", "New Zealand", "Philippines", "Singapore",
            //     "South Africa", "Thailand", "United Arab Emirates",
            //     "United States",
            // ]),
            // ...transformCountryNameToTargetingLocation(["Hong Kong"], TargetingLocationType.Region),
        ]),
        new CampaignTypeTargeting(campaignTypeNames[GoogleCampaignSettingType.Europe].E, []),
    ],
    [GoogleCampaignSettingType.SouthEastAsia]: [
        new CampaignTypeTargeting(campaignTypeNames[GoogleCampaignSettingType.SouthEastAsia].W, [
            ...transformCountryNameToTargetingLocation(["United States"]),
            // ...transformCountryNameToTargetingLocation([
            //     "Australia", "Canada", "Taiwan (Republic of China)", "New Zealand", "South Africa", "United Kingdom", "Japan",
            //     "Israel", "Portugal", "Spain", "France", "Italy", "Switzerland", "Germany", "Belgium", "Netherlands", "Denmark", "Norway",
            //     "Sweden", "Finland", "Poland", "Czech Republic", "Austria", "Kuwait", "Qatar", "United Arab Emirates", "Saudi Arabia",
            //     "United States",
            // ]),
            // ...transformCountryNameToTargetingLocation(["Hong Kong"], TargetingLocationType.Region),
        ]),
        new CampaignTypeTargeting(campaignTypeNames[GoogleCampaignSettingType.SouthEastAsia].SEA, []),
    ],
    [GoogleCampaignSettingType.Africa]: [
        new CampaignTypeTargeting(campaignTypeNames[GoogleCampaignSettingType.Africa].W, [
            ...transformCountryNameToTargetingLocation(["United States"]),
            // ...transformCountryNameToTargetingLocation([
            //     "Australia", "Canada", "Taiwan (Republic of China)", "New Zealand", "South Africa", "United Kingdom", "Japan",
            //     "Israel", "Portugal", "Spain", "France", "Italy", "Switzerland", "Germany", "Belgium", "Netherlands", "Denmark", "Norway",
            //     "Sweden", "Finland", "Poland", "Czech Republic", "Austria", "Kuwait", "Qatar", "United Arab Emirates", "Saudi Arabia",
            //     "United States",
            // ]),
            // ...transformCountryNameToTargetingLocation(["Hong Kong"], TargetingLocationType.Region),
        ]),
        new CampaignTypeTargeting(campaignTypeNames[GoogleCampaignSettingType.MiddleEast].E, []),
    ],
    [GoogleCampaignSettingType.MiddleEast]: [
        new CampaignTypeTargeting(campaignTypeNames[GoogleCampaignSettingType.MiddleEast].W, [
            ...transformCountryNameToTargetingLocation(["United States"]),
            // ...transformCountryNameToTargetingLocation([
            //     "Australia", "Canada", "Taiwan (Republic of China)", "New Zealand", "South Africa", "United Kingdom", "Japan",
            //     "Portugal", "Spain", "France", "Italy", "Switzerland", "Germany", "Belgium", "Netherlands", "Denmark", "Norway", "Sweden",
            //     "Finland", "Poland", "Czech Republic", "Austria",
            //     "United States",
            // ]),
            // ...transformCountryNameToTargetingLocation(["Hong Kong"], TargetingLocationType.Region),
        ]),
        new CampaignTypeTargeting(campaignTypeNames[GoogleCampaignSettingType.Africa].E, []),
    ],
    [GoogleCampaignSettingType.LatinAmerica]: [
        new CampaignTypeTargeting(campaignTypeNames[GoogleCampaignSettingType.LatinAmerica].W, [
            ...transformCountryNameToTargetingLocation(["United States"]),
            // ...transformCountryNameToTargetingLocation([
            //     "Australia", "Canada", "Taiwan (Republic of China)", "New Zealand", "South Africa", "United Kingdom", "Japan",
            //     "Israel", "Portugal", "Spain", "France", "Italy", "Switzerland", "Germany", "Belgium", "Netherlands", "Denmark", "Norway",
            //     "Sweden", "Finland", "Poland", "Czech Republic", "Austria", "Kuwait", "Qatar", "United Arab Emirates", "Saudi Arabia",
            //     "United States",
            // ]),
            // ...transformCountryNameToTargetingLocation(["Hong Kong"], TargetingLocationType.Region),
        ]),
        new CampaignTypeTargeting(campaignTypeNames[GoogleCampaignSettingType.LatinAmerica].E, []),
    ],
    [GoogleCampaignSettingType.USA]: [
        new CampaignTypeTargeting(campaignTypeNames[GoogleCampaignSettingType.USA].W, [
            ...transformCountryNameToTargetingLocation(["United Kingdom"]),
            // ...transformCountryNameToTargetingLocation([
            //     "Australia", "Austria", "Belgium", "Brazil", "Canada", "Czech Republic", "Denmark", "Finland", "France", "Germany",
            //     "Iceland", "Ireland", "Israel", "Italy", "Japan", "Kuwait", "Malaysia", "Mexico", "Netherlands", "New Zealand", "Norway",
            //     "Philippines", "Poland", "Portugal", "Qatar", "Saudi Arabia", "Singapore", "South Africa", "Spain", "Sweden", "Switzerland",
            //     "Taiwan (Republic of China)", "Thailand", "United Arab Emirates",
            //     "United Kingdom",
            // ]),
            // ...transformCountryNameToTargetingLocation(["Hong Kong"], TargetingLocationType.Region),
        ]),
        new CampaignTypeTargeting(
            campaignTypeNames[GoogleCampaignSettingType.USA].E,
            transformCountryNameToTargetingLocation(["United States"]),
        ),
    ],
    [GoogleCampaignSettingType.AustraliaNewZealand]: [
        new CampaignTypeTargeting(campaignTypeNames[GoogleCampaignSettingType.AustraliaNewZealand].W, [
            ...transformCountryNameToTargetingLocation(["United States"]),
            // ...transformCountryNameToTargetingLocation([
            //     "Australia", "Austria", "Belgium", "Canada", "Czech Republic", "Denmark", "Finland", "France", "Germany", "Iceland",
            //     "Ireland", "Israel", "Italy", "Japan", "Kuwait", "Malaysia", "Netherlands", "New Zealand", "Norway", "Philippines", "Poland",
            //     "Portugal", "Qatar", "Saudi Arabia", "Singapore", "South Africa", "Spain", "Sweden", "Switzerland", "Taiwan (Republic of China)",
            //     "Thailand", "United Arab Emirates", "United Kingdom",
            //     "United States",
            // ]),
            // ...transformCountryNameToTargetingLocation(["Hong Kong"], TargetingLocationType.Region),
        ]),
        new CampaignTypeTargeting(
            campaignTypeNames[GoogleCampaignSettingType.AustraliaNewZealand].E,
            transformCountryNameToTargetingLocation(["Australia", "New Zealand"]),
        ),
    ],
};
