import { Position } from "@daytrip/legacy-models";
import { LocationPosition } from "@daytrip/react-shared-components";
import type { MangopayPersonType } from "@legacy/domain/MangopayPersonType";
import type { SimpleCountry } from "@legacy/domain/SimpleCountry";
import type { SimpleLocation } from "@legacy/domain/SimpleLocation";
import type { SimpleUser } from "@legacy/domain/SimpleUser";
import { DriversCompany } from "@legacy/models/DriversCompany";
import type { User } from "@legacy/models/User";
import { isUndefinedOrNull } from "@legacy/utils";
import autobind from "autobind-decorator";
import type { ValidationError } from "class-validator";
import { action, computed, observable } from "mobx";

import { getPhoneNumberExtendedValidationMessage } from "../utils/validateUserPhoneNumber";

import { ModelOperator } from "./ModelOperator";
import type { ModelOperatorOptions } from "./ModelOperatorOptions";
import { getVatValidationMessage } from "../utils/getVatValidationMessage";

interface DriversCompanyOperatorOptions extends ModelOperatorOptions<User, null, DriversCompanyOperatorData> {}

interface DriversCompanyOperatorData {
    simpleLocations?: Array<SimpleLocation>; // used fot locations select
    simpleCountries?: Array<SimpleCountry>;
    managers?: Array<SimpleUser>; // driver managers
}

interface DriversCompanyOperatorDataFetched {
    simpleLocations: Array<SimpleLocation>;
    simpleCountries: Array<SimpleCountry>;
}

@autobind
export class DriversCompanyOperator extends ModelOperator<
    User,
    DriversCompanyOperatorOptions,
    null,
    DriversCompanyOperatorData,
    DriversCompanyOperatorDataFetched
> {
    @observable
    public isAddressValid?: boolean;

    @computed
    public get country(): undefined | SimpleCountry {
        return this.processWhenDataFetched((self) => self.data.simpleCountries.find((c) => c._id === this.m.countryId));
    }

    @computed
    public get mangopayPersonType(): MangopayPersonType | undefined {
        return isUndefinedOrNull(this.m.activeMangopayInformation)
            ? undefined
            : this.m.activeMangopayInformation.personType;
    }

    @action
    public setIsAddressValid(isValid: boolean) {
        this.isAddressValid = isValid;
    }

    @action
    public addressUpdate(name: string, position: LocationPosition) {
        this.driversCompany.address = name;
        this.driversCompany.position = new Position(position.latitude, position.longitude);
        this.driversCompany.town = name;
    }

    @computed
    public get driversCompany(): DriversCompany {
        return this.m.driversCompany as DriversCompany;
    }

    public getDriversCompanyValidations(propertyName: keyof DriversCompany): Array<ValidationError> {
        const driverValidation = this.getValidations("driversCompany")[0];
        if (!driverValidation || !driverValidation.children) {
            return [];
        }
        return driverValidation.children.filter((v) => v.property === propertyName);
    }

    // TODO: Check usage of this func. Probably it could be removed
    public getDriversCompanyValidationMessages(propertyName: keyof DriversCompany): string | undefined {
        const driversCompanyValidations = this.getDriversCompanyValidations(propertyName);
        const failedValidations: Array<ValidationError> = driversCompanyValidations.filter(
            (driversCompanyValidation) => driversCompanyValidation.constraints,
        );
        const someValidationsHaveFailed = failedValidations.length > 0;

        if (!someValidationsHaveFailed) {
            return;
        }

        const validationMessage = failedValidations
            .map((driversCompanyValidation) => Object.values(driversCompanyValidation.constraints!))
            .flatMap((i) => i)
            .join("\n");

        return validationMessage;
    }

    @computed
    public get getPhoneNumberValidationMessage(): string | undefined {
        const validationMessages = this.getValidationMessages("phoneNumber");

        return getPhoneNumberExtendedValidationMessage(this.m.phoneNumber, validationMessages);
    }

    @computed
    public get originLocationsNames(): string {
        if (this.data.simpleLocations && this.driversCompany.originLocationIds) {
            return this.driversCompany.originLocationIds
                .map((locationId: string) => {
                    const location = (this.data.simpleLocations as Array<SimpleLocation>).find(
                        (sl: SimpleLocation) => sl.locationId === locationId,
                    ) as SimpleLocation;
                    return location?.name || "";
                })
                .filter((locationId) => locationId !== "")
                .sort((a, b) => a.localeCompare(b))
                .join(", ");
        }

        return "";
    }

    @observable
    public vatValidationErrorMessage: string | undefined;

    @action
    public async setShouldShowInvalidVatError() {
        this.vatValidationErrorMessage = await getVatValidationMessage(this.m, this.rpcClient);
    }
}
