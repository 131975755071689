import {
    getManagementRefreshToken,
    getManagementAuthenticationToken,
} from "../../../utils/authentication/authenticationUtils";
import { getAuthenticationStore } from "../AuthenticationStore.singleton";

export function getAuthenticationTokensOrFail() {
    const refresh = getManagementRefreshToken();
    const authentication = getManagementAuthenticationToken();

    if (!refresh || !authentication) {
        if (location.hash.indexOf("#/login") === 0) {
            return;
        }
        getAuthenticationStore().logout(true);
        throw new Error("Missing authentication token");
    }
    return {
        refresh,
        authentication,
    };
}
