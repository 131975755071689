import { API_DOWNLOAD_INVOICE_URL } from "@daytrip/legacy-config";
import { Invoice } from "@legacy/models/Invoice";
import { User } from "@legacy/models/User";
import type { RetrieveInvoicesOptions } from "@legacy/options/RetrieveInvoicesOptions";
import autobind from "autobind-decorator";
import { plainToClass } from "class-transformer";
import { action, observable } from "mobx";

import { PageStore } from "../../stores/PageStore";

import type { InvoicesPageRouter } from "./InvoicesPageRouter";

@autobind
export class InvoicesPageStore extends PageStore<InvoicesPageRouter, {}> {
    @observable
    public user: User;

    @observable
    public invoices?: Invoice[];

    @observable
    public invoicesCount?: number;

    @action
    public async onFetchData() {
        let { userId } = this.pageRouter;
        if (this.authenticationStore.isDriver || this.authenticationStore.isDriversCompany) {
            userId = this.authenticationStore.userJWT?.userId;
        }

        const [df, dt] = [this.pageRouter.issuedAtFrom, this.pageRouter.issuedAtTo];

        if (df && dt) {
            if (
                df.getUTCFullYear() === dt.getUTCFullYear() &&
                df.getUTCMonth() === dt.getUTCMonth() &&
                df.getUTCDate() === dt.getUTCDate()
            ) {
                df.setUTCHours(0);
                df.setUTCMinutes(0);
                df.setUTCSeconds(0);
                this.pageRouter.filterIssuedAtFromUpdate(df.getTime().toString());

                dt.setUTCHours(23);
                dt.setUTCMinutes(59);
                dt.setUTCSeconds(59);
                this.pageRouter.filterIssuedAtToUpdate(dt.getTime().toString());
            }
        }

        if (userId) {
            this.user = plainToClass(User, await this.rpcClient.user.retrieveUser(userId));
        }

        await this.fetchInvoices();
    }

    @action
    public async fetchInvoices() {
        const options = {
            skip: this.pageRouter.skip,
            limit: this.pageRouter.limit,
            sortBy: this.pageRouter.sortBy,
            sortDirection: this.pageRouter.sortDirection,
            issuedAtFrom: this.pageRouter.issuedAtFrom,
            issuedAtTo: this.pageRouter.issuedAtTo,
            userIds: [this.user._id],
        } as RetrieveInvoicesOptions;

        this.invoices = plainToClass(Invoice, await this.rpcClient.invoice.getInvoices(options));
        this.invoicesCount = await this.rpcClient.invoice.getInvoicesCount(options);
    }

    public downloadInvoice(invoiceId: string) {
        const a = document.createElement("a");
        a.target = "_blank";
        a.href = `${API_DOWNLOAD_INVOICE_URL}?invoiceId=${invoiceId}&authenticationToken=${this.authenticationStore.authenticationToken}`;
        a.click();
    }

    public isDataFetched(): this is InvoicesPageStore & InvoicesPageStoreDataFetched {
        return !!this.invoices && this.invoicesCount !== undefined;
    }
}

export interface InvoicesPageStoreDataFetched {
    invoices: Invoice[];
}
