import { Exclude, Expose } from "class-transformer";
import { IsArray, IsBoolean, IsString } from "class-validator";

import type { ReferralCode } from "../models/ReferralCode";

import { SortingPaginationOptions } from "./SortingPaginationOptions";

@Exclude()
export class RetrieveReferralCodesOptions extends SortingPaginationOptions<ReferralCode> {
    @Expose()
    @IsArray()
    public referrerIds?: Array<string>;

    @Expose()
    @IsBoolean()
    public onlyUsed?: boolean;

    @Expose()
    @IsString()
    public code?: string;

    @Expose()
    @IsString()
    public orderId?: string;

    @Expose()
    @IsArray()
    public customerIds?: Array<string>;
}
