import type { Passenger } from "@daytrip/legacy-models";
import { ChildSeatTypes } from "@legacy/domain/ChildSeatType";
import { LuggageType } from "@legacy/domain/LuggageType";
import { PassengerType } from "@legacy/domain/PassengerType";
import { isUndefinedOrNull } from "@legacy/utils";
import autobind from "autobind-decorator";
import { action, computed, observable } from "mobx";
import { Option } from "react-select-legacy";

import { ModelOperator } from "./ModelOperator";
import type { ModelOperatorOptions } from "./ModelOperatorOptions";

interface PassengerOperatorOptions extends ModelOperatorOptions<Passenger, {}, {}> {
    editedModelReactionCallback?: (passenger: Passenger) => void;
}

@autobind
export class PassengerOperator extends ModelOperator<Passenger, PassengerOperatorOptions, {}, {}, {}> {
    @observable
    public isCollapsed: boolean = true;

    @observable
    public isBirthdayAtDateTImePickerOpened: boolean = false;

    @computed
    public get fullName(): string | undefined {
        if (this.m.firstName || this.m.lastName) {
            return [this.m.firstName, this.m.lastName].join(" ");
        }

        return undefined;
    }

    @action
    public toggleCollapse() {
        this.isCollapsed = !this.isCollapsed;
    }

    private childSeatTypes = ChildSeatTypes;

    @computed
    public get isChildSeatValid(): boolean {
        for (const cst of this.childSeatTypes) {
            if (isUndefinedOrNull(this.m.birthdayAt)) {
                continue;
            }

            const birthdayFrom = new Date(this.m.birthdayAt);
            birthdayFrom.setUTCFullYear(birthdayFrom.getUTCFullYear() + cst.yearsFrom);

            const birthdayTo = new Date(birthdayFrom);
            birthdayTo.setUTCFullYear(birthdayTo.getUTCFullYear() + cst.yearsDifference);

            if (
                this.m.childSeatType == cst.childSeatType &&
                !(birthdayFrom.getTime() < Date.now() && birthdayTo.getTime() > Date.now())
            ) {
                return false;
            }
        }

        return true;
    }

    @computed
    private get defaultBirthdayAtIfUndefined(): Date {
        const now = new Date();
        now.setFullYear(now.getFullYear() - 20);
        return this.m.birthdayAt == undefined ? now : this.m.birthdayAt;
    }

    @action
    private updateChildSeatTypeByBirthday() {
        if (this.m.type == PassengerType.Lead) {
            return;
        }

        const birthdayAt = this.defaultBirthdayAtIfUndefined;

        this.m.type = PassengerType.Adult;

        this.childSeatTypes.forEach((cy) => {
            const birthdayFrom = new Date(birthdayAt);

            birthdayFrom.setUTCFullYear(birthdayFrom.getUTCFullYear() + cy.yearsFrom);

            const birthdayTo = new Date(birthdayFrom);
            birthdayTo.setUTCFullYear(birthdayTo.getUTCFullYear() + cy.yearsDifference);

            if (birthdayFrom.getTime() < Date.now() && birthdayTo.getTime() > Date.now()) {
                this.m.type = PassengerType.Child;
                this.m.childSeatType = cy.childSeatType;
            }
        });
    }

    @action
    private validateLeadAge(date: Date): boolean {
        if ((Date.now() - date.getTime()) / 1000 / 60 / 60 / 24 / 365 < 18) {
            alert("Lead should be over 18 years old.");
            return false;
        }

        return true;
    }

    @action
    public updateBirthdayMonth(month: number) {
        const date = this.defaultBirthdayAtIfUndefined;

        date.setUTCMonth(month);

        if (this.m.isLead()) {
            if (!this.validateLeadAge(date)) {
                return;
            }
        }

        this.m.birthdayAt = undefined;

        this.edit((p) => {
            p.birthdayAt = date;
        });

        this.updateChildSeatTypeByBirthday();
    }

    @action
    public updateBirthdayDay(day: number) {
        const date = this.defaultBirthdayAtIfUndefined;

        date.setUTCDate(day);

        if (this.m.isLead()) {
            if (!this.validateLeadAge(date)) {
                return;
            }
        }

        this.m.birthdayAt = undefined;

        this.edit((p) => {
            p.birthdayAt = date;
        });

        this.updateChildSeatTypeByBirthday();
    }

    @action
    public updateBirthdayYear(year: number) {
        const date = this.defaultBirthdayAtIfUndefined;

        date.setUTCFullYear(year);

        if (this.m.isLead()) {
            if (!this.validateLeadAge(date)) {
                return;
            }
        }

        this.m.birthdayAt = undefined;

        this.edit((p) => {
            p.birthdayAt = date;
        });

        this.updateChildSeatTypeByBirthday();
    }

    @action
    public removeLuggage(index: number) {
        this.m.luggage.splice(index, 1);
    }

    @action
    public updateLuggage(value: number) {
        let i = 0;
        const passengerLuggage: Array<LuggageType> = [];
        for (i; i < value; i++) {
            passengerLuggage.push(...[LuggageType.CarryOn, LuggageType.Suitcase]);
        }

        this.m.luggage = passengerLuggage;
    }

    @action
    public updateType(typeOption: Option) {
        this.m.type = typeOption.value as PassengerType;

        if (isUndefinedOrNull(this.m.birthdayAt)) {
            this.m.birthdayAt = new Date();
        }

        if (typeOption.value == PassengerType.Child) {
            const oldestChildYears = this.childSeatTypes[this.childSeatTypes.length - 1].yearsDifference;
            this.m.birthdayAt.setUTCFullYear(new Date().getUTCFullYear() - oldestChildYears);
        } else {
            this.m.birthdayAt.setUTCFullYear(new Date().getUTCFullYear() - 18);
        }
    }

    public emailValidationMessage(): string | void {
        if (isUndefinedOrNull(this.m.email) && this.m.type != PassengerType.Lead) {
            return "Email should be specified.";
        }
        return undefined;
    }
}
