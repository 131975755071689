import { MINUTE_IN_MILLISECONDS } from "@daytrip/constants";
import { Notification } from "@legacy/models/Notification";
import { RetrieveNotificationsOptions } from "@legacy/options/RetrieveNotificationsOptions";
import { action, computed, observable } from "mobx";

import { localStorageKeys } from "../../config.management";
import { AuthenticationStore } from "../../domain/authentication/AuthenticationStore";
import { getAuthenticationStore } from "../../domain/authentication/AuthenticationStore.singleton";
import { getRpcClient, RpcClient } from "../../rpc-browser-sdk";
import { cacheData } from "../../utils/cacheData";
import { getCachedData } from "../../utils/getCachedData";

export const RETRIEVE_NOTIFICATIONS_INTERVAL = MINUTE_IN_MILLISECONDS * 10;

export class NotificationsStore {
    private rpcClient: RpcClient;

    private authenticationStore: AuthenticationStore;

    public constructor() {
        this.rpcClient = getRpcClient();
        this.authenticationStore = getAuthenticationStore();
    }

    @observable
    public notifications?: Array<Notification>;

    @computed
    public get notClosedNotifications() {
        return this.notifications?.filter((n) => n.closedAt == null);
    }

    @action.bound
    private updateAndCacheNotifications(notifications?: Array<Notification>) {
        this.notifications = notifications;

        cacheData(localStorageKeys.notifications, JSON.stringify(notifications), RETRIEVE_NOTIFICATIONS_INTERVAL);
    }

    @action.bound
    public async retrieveNotifications(isCacheSkipped?: boolean) {
        if (!this.authenticationStore.isAuthenticationTokenSet) {
            return;
        }

        if (!isCacheSkipped) {
            const cached = getCachedData(localStorageKeys.notifications);
            if (cached !== null) {
                const parsed = JSON.parse(cached);
                // check if the cached data is an array, if not, then it's not valid
                /// TODO: add a better check
                this.notifications = Array.isArray(parsed) ? parsed : [];
                return;
            }
        }

        const options: RetrieveNotificationsOptions = {
            skip: 1,
            limit: this.notificationsLimit,
            sortBy: "createdAt",
            sortDirection: -1,
        };

        const notifications = await this.rpcClient.notification.retrieveMyNotifications(options);
        this.updateAndCacheNotifications(notifications);
    }

    @action.bound
    public async closeNotification(notificationId: string) {
        await this.rpcClient.notification.closeNotification(notificationId);

        if (this.notifications) {
            const changedNotificationIndex = this.notifications?.findIndex((n) => n._id === notificationId);
            const updatedNotifications = this.notifications;

            updatedNotifications[changedNotificationIndex].closedAt = new Date();

            this.updateAndCacheNotifications(updatedNotifications);
        }
    }

    @observable
    public notificationsLimit: number = 15;

    @observable
    public lastScrollHeight: number = 0;

    @action.bound
    public async viewMoreNotifications(): Promise<void> {
        this.lastScrollHeight = (document.getElementById("notificationsScrollarea") as HTMLElement).scrollHeight;

        await this.loadMoreActions();
        this.checkScrollHeight();
    }

    @action.bound
    public async loadMoreActions(): Promise<void> {
        this.notificationsLimit += 15;
        await this.retrieveNotifications(true);
    }

    @action.bound
    public checkScrollHeight(): void {
        (document.getElementById("notificationsScrollarea") as HTMLElement).scrollTop =
            this.lastScrollHeight + 2 - window.innerHeight * 0.8;
    }

    @observable
    public isNotificationsOpened: boolean = false;

    @action.bound
    public closeNotifications() {
        if (this.notClosedNotifications?.length && this.isNotificationsOpened) {
            this.rpcClient.notification.closeMyNotifications();

            const allClosedNotifications = this.notifications?.map((n) => {
                const notification = n;
                notification.closedAt = new Date();
                return notification;
            });

            this.updateAndCacheNotifications(allClosedNotifications);
        }
        this.isNotificationsOpened = false;
    }

    @action.bound
    public onNotificationToggle(): void {
        if (this.isNotificationsOpened) {
            this.closeNotifications();
        } else {
            this.isNotificationsOpened = true;
        }
    }
}
