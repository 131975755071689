const REDIRECT_URL_QUERY_PARAMETER_NAME = "r";

export function constructSearchForLoginRedirect(location: { pathname: string; search: string }) {
    const redirectUrl = `${location.pathname}${location.search}`;

    const a = new URLSearchParams();
    a.append(REDIRECT_URL_QUERY_PARAMETER_NAME, redirectUrl);

    return `?${a.toString()}`;
}

export function retrieveLoginRedirectUrl() {
    const [, search] = window.location.hash.split("?");
    const p = new URLSearchParams(search);
    if (!p.has(REDIRECT_URL_QUERY_PARAMETER_NAME)) {
        return;
    }
    const url = p.get(REDIRECT_URL_QUERY_PARAMETER_NAME);
    return url;
}
