import autobind from "autobind-decorator";
import { action, computed, observable } from "mobx";

import { CustomerFeedbackForDriversCompany } from "@legacy/domain/CustomerFeedbackForDriversCompany";
import { isUndefinedOrNull } from "@legacy/utils";
import { PageStore } from "../../stores/PageStore";

import { CustomerFeedbacksForDriversCompanyPageRouter } from "./CustomerFeedbacksForDriversCompanyPageRouter";

@autobind
export class CustomerFeedbacksForDriversCompanyPageStore extends PageStore<
    CustomerFeedbacksForDriversCompanyPageRouter,
    null
> {
    @observable
    public feedback?: Array<CustomerFeedbackForDriversCompany>;

    @observable
    public feedbacksCount: number = 0;

    @observable
    public feedbacksScores?: Array<number>;

    @action
    public async onFetchData() {
        await this.fetchContent();
    }

    @action
    public async fetchContent(): Promise<void> {
        this.feedback = undefined;

        const feedback = await this.rpcClient.feedback.retrieveMyCustomerFeedbacksForDriversCompany({
            skip: this.pageRouter.skip,
            limit: this.pageRouter.limit,
            sortBy: "createdAt",
            sortDirection: -1,
        });

        this.feedback = feedback;

        this.feedbacksCount = await this.rpcClient.feedback.retrieveMyCustomerFeedbacksCount({});
        this.feedbacksScores = await this.rpcClient.feedback.retrieveMyCustomerFeedbacksScores({});
    }

    @computed
    public get driversAverageRating(): number {
        let sum = 0;

        if (!isUndefinedOrNull(this.feedbacksScores) && this.feedbacksScores.length > 0) {
            sum = this.feedbacksScores.reduce((prev: number, f: number) => prev + f, sum);

            sum /= this.feedbacksScores.length;
            return Number(sum.toLocaleString("en", { maximumFractionDigits: 2, useGrouping: false }));
        }

        return 0;
    }

    @computed
    public get fiveStarRatingsLegth(): number {
        if (!isUndefinedOrNull(this.feedbacksScores)) {
            return this.feedbacksScores.filter((f) => f == 5).length;
        }

        return 0;
    }

    @computed
    public get fourStarRatingsLegth(): number {
        if (!isUndefinedOrNull(this.feedbacksScores)) {
            return this.feedbacksScores.filter((f) => f == 4).length;
        }

        return 0;
    }

    @computed
    public get threeStarRatingsLegth(): number {
        if (!isUndefinedOrNull(this.feedbacksScores)) {
            return this.feedbacksScores.filter((f) => f == 3).length;
        }

        return 0;
    }

    @computed
    public get twoStarRatingsLegth(): number {
        if (!isUndefinedOrNull(this.feedbacksScores)) {
            return this.feedbacksScores.filter((f) => f == 2).length;
        }

        return 0;
    }

    @computed
    public get oneStarRatingsLegth(): number {
        if (!isUndefinedOrNull(this.feedbacksScores)) {
            return this.feedbacksScores.filter((f) => f == 1).length;
        }

        return 0;
    }

    public isDataFetched(): this is CustomerFeedbacksForDriversCompanyPageStore &
        CustomerFeedbacksForDriversCompanyPageStoreDataFetched {
        return this.feedback != undefined;
    }
}

export interface CustomerFeedbacksForDriversCompanyPageStoreDataFetched {
    feedback: Array<CustomerFeedbackForDriversCompany>;
}
