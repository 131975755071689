import type { OrderChange } from "@daytrip/legacy/source/models/OrderChange";
import type { OrderChangesLogItem } from "@daytrip/legacy/source/models/OrderChangesLogItem";
import type { Order, Passenger } from "@daytrip/legacy-models";

const allowedChangeKeys: Array<OrderChangesLogItem["key"]> = [
    "originLocationId",
    "destinationLocationId",
    "departureAt",
    "pickupAddress",
    "dropoffAddress",
    "contentLocations",
    "totalPrice",
    "passengers",
    "vehicles",
];

const allowedPassengerChangeKeys: Array<keyof Passenger> = [
    "birthdayAt",
    "firstName",
    "lastName",
    "email",
    "luggage",
    "countryId",
];

function hadPassengersChanged(previousValue: Passenger[], currentValue: Passenger[]): boolean {
    // If both sets of passengers are present, it means that something inside changed
    if (!previousValue || !currentValue) {
        return false;
    }
    if (previousValue.length !== currentValue.length) {
        return true;
    }

    return previousValue.some((prevPassenger, idx) => {
        const curPassenger = currentValue[idx];
        if (prevPassenger && curPassenger) {
            return allowedPassengerChangeKeys.some((allowedChangeKey) => {
                const prevKeyValue = JSON.stringify(prevPassenger[allowedChangeKey]);
                const currentKeyValue = JSON.stringify(curPassenger[allowedChangeKey]);
                if (prevKeyValue !== currentKeyValue) {
                    return true;
                }
            });
        }
    });
}

export function getPendingLogsFromOrderLog(orderChanges: OrderChange) {
    return (
        orderChanges.logs
            // log.customerNotified  = (true: notified; false: not notified; undefined: discarded)
            .filter((log) => log.customerNotified === false)
            .filter((log) => {
                // Keep only changes made in last two days
                const now = Date.now();
                return new Date(log.changedAt).getTime() >= now - 2 * 24 * 60 * 60 * 1000;
            })
            .filter((log) =>
                // Keep only changes where allowed keys are updated
                log.changes.find((change) => {
                    if (!allowedChangeKeys.includes(change.key as keyof Order)) {
                        return false;
                    }
                    if (change.key === "passengers") {
                        return hadPassengersChanged(
                            change.previousValue as Passenger[],
                            change.currentValue as Passenger[],
                        );
                    }
                    return true;
                }),
            )
            .sort((a, b) => new Date(b.changedAt).getTime() - new Date(a.changedAt).getTime())
    );
}
