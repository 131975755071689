import { OrderAddressValidator } from "../domain/OrderAddressValidator";
import {
    checkIfAddressIncomplete,
    checkIfAddressIsAirport,
    checkIfAddressIsBoat,
    checkIfAddressIsTrain,
} from "../utils";

export function validateOrderAddress(address: string, hasMeetingPosition: boolean): OrderAddressValidator {
    const orderAddressValidator = new OrderAddressValidator();
    let validateAllowed: boolean;

    if (hasMeetingPosition) {
        orderAddressValidator.isMeetingPosition = true;
    }

    validateAllowed = !orderAddressValidator.isMeetingPosition;

    orderAddressValidator.airportResult = validateAllowed
        ? checkIfAddressIsAirport(address)
        : { isAirport: false, flightNumberMatch: false };

    validateAllowed =
        validateAllowed &&
        !orderAddressValidator.airportResult.isAirport &&
        !orderAddressValidator.airportResult.flightNumberMatch;

    orderAddressValidator.isBoat = validateAllowed ? checkIfAddressIsBoat(address) : false;

    validateAllowed = validateAllowed && !orderAddressValidator.isBoat;

    orderAddressValidator.isTrain = validateAllowed ? checkIfAddressIsTrain(address) : false;

    validateAllowed = validateAllowed && !orderAddressValidator.isTrain;

    orderAddressValidator.isIncomplete = validateAllowed ? checkIfAddressIncomplete(address) : false;

    return orderAddressValidator;
}
