/* eslint-disable guard-for-in */
import { Logger } from "@daytrip/logger";
import autobind from "autobind-decorator";
import { observable } from "mobx";
import type { Location as RouterLocation, RouterStore } from "mobx-react-router";

import { Context } from "../../../legacy/source/Context";
import { ContextType } from "../../../legacy/source/ContextType";
import type { User } from "../../../legacy/source/models/User";
import { ENVIRONMENT } from "../config.management";
import type { AuthenticationStore } from "../domain/authentication/AuthenticationStore";
import { globalManagementLogger } from "../global-logger";
import type { PageRouter } from "../PageRouter";
import { getRpcClient } from "../rpc-browser-sdk";

export type RouterConstructor<T> = { new (location?: RouterLocation): T; prototype: T };

export interface InitOptions<TPageRouter extends PageRouter<{}> = PageRouter | undefined> {
    authenticationStore?: AuthenticationStore;
    logger?: Logger;
    pageRouterConstructor?: RouterConstructor<TPageRouter>;
    location?: RouterLocation;
    routerStore?: RouterStore;
    fetchingAttemptsLimit?: number;
    context?: Context<User>;
}

@autobind
export class PageStore<TPageRouter extends PageRouter | undefined = undefined, TPageModules = {} | undefined> {
    protected rpcClient = getRpcClient();

    protected authenticationStore: AuthenticationStore;

    public path?: string;

    public modules: TPageModules;

    public context?: Context<User>;

    public logger = new Logger({ app: "PageStoreLogger", env: ENVIRONMENT });

    public pageRouter: TPageRouter;

    @observable
    public fetchFailed: boolean = false;

    public async clear(): Promise<void> {
        delete (this as any).logger;
        delete (this as any).modules;
        delete (this as any).context;
        delete (this as any).services;
        delete (this as any).pageRouter;
    }

    public onInit(_location?: RouterLocation): void {}

    public async init(options: InitOptions<TPageRouter>): Promise<void> {
        // fetching attempts
        if (options.fetchingAttemptsLimit) {
            this.fetchingAttemptCountdown = options.fetchingAttemptsLimit;
        }

        // set logger if provided
        if (options.logger) {
            this.logger = options.logger;
        }

        // set context if provided
        if (options.context) {
            this.context = options.context;
        } else if (!this.context) {
            this.context = new Context(ContextType.Anonymous);
        }

        // set page router if provided
        if (options.pageRouterConstructor) {
            // eslint-disable-next-line new-cap
            this.pageRouter = new options.pageRouterConstructor(options.location);
        }

        if (options.authenticationStore) {
            this.authenticationStore = options.authenticationStore;
        }

        if (this.pageRouter) {
            this.pageRouter.init({
                location: options.location,
                routerStore: options.routerStore,
            });
        }

        this.onInit(options.location);
    }

    // fetching

    public fetchingAttemptCountdown: number = 3;

    public async onFetchData(): Promise<string | void> {
        return "/notFound"; // automatically redirect page without method onFetchData to the notFound
    }

    public redirectAttemptCountdown = 3;

    public async fetchData(logger?: Logger): Promise<string | void> {
        if (logger) {
            this.logger = logger;
        }

        try {
            const redirectTo = await this.onFetchData();

            if (this.pageRouter && redirectTo && this.redirectAttemptCountdown > 0) {
                this.redirectAttemptCountdown -= 1;
                if (this.pageRouter.routerStore) {
                    this.pageRouter.routerStore.replace(redirectTo);
                } else {
                    location.href = location.origin + redirectTo;
                }
                return await this.fetchData();
            }

            if (!location.hash) {
                window.scrollTo(0, 0);
            }
            return redirectTo;
        } catch (e: any) {
            globalManagementLogger.error(e);
            this.fetchFailed = true;

            this.fetchingAttemptCountdown -= 1;

            if (e === 404) {
                return "/notFound";
            }
            if (e === 403) {
                return "/#/login";
            }
            if (this.fetchingAttemptCountdown > 0) {
                return this.fetchData();
            }
        }
    }

    public isDataFetched(): boolean {
        return true;
    }
}
