import { injectable } from "inversify";
import { observable, action } from "mobx";

import { container, StoreManager, stores } from "../../../container";
import { CountryGroupsStore } from "../../../domain/countryGroups/CountryGroupsStore";
import { getRpcClient, RpcClient } from "../../../rpc-browser-sdk";

import { CountryGroupFilterItem } from "./models/CountryGroupFilterItem";
import { generateCountryGroupFilterItems } from "./utils/generateCountryGroupFilterItems";

@injectable()
export class CountryGroupFilterStore {
    private rpcClient: RpcClient;

    private countryGroupsStore: CountryGroupsStore;

    @observable isLoaded = false;

    @observable isLoading = false;

    @observable items: CountryGroupFilterItem[] = [];

    constructor() {
        this.rpcClient = getRpcClient();
        this.countryGroupsStore = container.get<StoreManager<CountryGroupsStore>>(stores.countryGroups).getStore();
    }

    @action async load() {
        if (this.isLoaded) {
            return;
        }

        this.isLoading = true;
        try {
            const countries = await this.rpcClient.content.retrieveCountries({ hasCountryGroup: true });

            this.items = generateCountryGroupFilterItems(this.countryGroupsStore.countryGroups, countries);

            this.isLoaded = true;
        } finally {
            this.isLoading = false;
        }
    }
}
