import { Exclude, Expose } from "class-transformer";
import { IsString } from "class-validator";

import { CommissionSettlement } from "../../../api/src/domain/host-agency/models/commission-settlement.model";

import { SortingPaginationOptions } from "./SortingPaginationOptions";

@Exclude()
export class RetrieveSettlementsOptions extends SortingPaginationOptions<CommissionSettlement> {
    @IsString()
    @Expose()
    public hostAgencyId!: string;

    @IsString()
    @Expose()
    public sortBy?: keyof CommissionSettlement;
}
