import { HOUR_IN_MILLISECONDS, MINUTE_IN_MILLISECONDS } from "@daytrip/constants";
import { PENDING_ASSIGNATION_TIME_LIMIT_IN_HOURS } from "@daytrip/legacy-config";
import { AssignationStatus } from "@legacy/domain/AssignationStatus";
import type { RetrieveAssignationsOptions } from "@legacy/options/RetrieveAssignationsOptions";
import { startOfDay, subHours } from "date-fns";

export const determineAssignationOptions = (
    status: AssignationStatus,
    pagination?: { skip?: number; limit?: number },
): RetrieveAssignationsOptions => {
    const options: RetrieveAssignationsOptions = {
        statusIn: [status],
        skip: pagination?.skip,
        limit: pagination?.limit,
    };
    if (status === AssignationStatus.Pending) {
        const now = new Date();
        const beforeXh = new Date();
        beforeXh.setTime(now.getTime() - PENDING_ASSIGNATION_TIME_LIMIT_IN_HOURS * HOUR_IN_MILLISECONDS);
        options.createdAtFrom = beforeXh;
        options.createdAtTo = now;
        options.sortDirection = -1;

        // Departure date/time is storing in DB as a local time but in UTC timezone
        // e.g. 2023-02-08T16:00:00.217Z is stored as 2023-02-08T16:00:00.000+00:00
        // that means that current date/time should be shifted on time zone/UTC difference
        options.departureAtFrom = new Date(now.getTime() - now.getTimezoneOffset() * MINUTE_IN_MILLISECONDS);
    }
    if (status === AssignationStatus.Accepted) {
        const now = new Date();
        const nowShiftedByTZ = new Date(now.getTime() - now.getTimezoneOffset() * MINUTE_IN_MILLISECONDS);
        const startOfThisDay = startOfDay(nowShiftedByTZ);
        options.departureAtFrom = subHours(startOfThisDay, 8);
        options.sortDirection = 1;
    }
    return options;
};
