import { AutomaticEmailAttempt } from "@legacy/models/AutomaticEmailAttempt";
import autobind from "autobind-decorator";
import { action, observable } from "mobx";

import { getRpcClient, RpcClient } from "../../rpc-browser-sdk";

@autobind
export class EmailAttemptsStore {
    public rpcClient: RpcClient;

    @observable
    public automaticEmailAttempts: Array<AutomaticEmailAttempt> = [];

    public userId?: string;

    public constructor() {
        this.rpcClient = getRpcClient();
    }

    public async updateUserId(userId?: string) {
        this.userId = userId;
        await this.fetchAutomaticEmailAttempts();
    }

    @action
    public async removeAutomaticEmailAttempt(email: string) {
        if (this.userId) {
            await this.rpcClient.email.removeAutomaticEmailAttempt(this.userId, email);
            await this.fetchAutomaticEmailAttempts();
        }
    }

    @action
    public async fetchAutomaticEmailAttempts() {
        this.automaticEmailAttempts = this.userId
            ? await this.rpcClient.email.retrieveUserAutomaticEmailAttemptsByUserId(this.userId)
            : [];
    }
}
