import { TransformToDate } from "@daytrip/legacy-transformers";
import { Expose } from "class-transformer";
import { IsDefined, IsEnum, IsInt, IsString, IsUUID, IsBoolean, IsOptional } from "class-validator";
import { v4 as uuid } from "uuid";

import { Currency } from "../domain/Currency";
import { CDBaseEntity, CDColumn, CDCreateDateColumn, CDEntity, CDObjectIdColumn } from "../orm";

@CDEntity("subsidies")
export class Subsidy extends CDBaseEntity {
    @CDObjectIdColumn()
    @IsUUID("4")
    @IsDefined()
    @Expose()
    public _id: string = uuid();

    @CDColumn()
    @IsInt()
    @IsDefined()
    @Expose()
    public value: number;

    @CDColumn()
    @IsEnum(Currency)
    @IsDefined()
    @Expose()
    public currency = Currency.Euro;

    @CDColumn()
    @IsString()
    @Expose()
    public managementNote?: string;

    @CDColumn()
    @IsBoolean()
    @IsOptional()
    @Expose()
    public fromCombo?: boolean;

    @CDColumn()
    @IsString()
    @IsDefined()
    @Expose()
    public description: string;

    @CDCreateDateColumn()
    @Expose()
    @TransformToDate
    public createdAt: Date = new Date();
}
