import { hasPermissionsSync, hasPermissions as hasPermissionsAsync } from "@daytrip/access-control";
import type { PermissionDynamicParams, PermissionParams } from "@daytrip/access-control";
import { RolesEnum } from "@daytrip/legacy-enums";
import type { UserJWT } from "@daytrip/legacy-models";
import { DriversCompanyStatus } from "@legacy/domain/DriversCompanyStatus";
import { DriverStatus } from "@legacy/domain/DriverStatus";
import * as Sentry from "@sentry/browser";
import { decodeJwt } from "jose";
import { action, computed, observable } from "mobx";

import { container } from "../../browser.container";
import type { RoutingStore } from "../../container";
import { stores } from "../../container";
import { routes } from "../../routes";
import { constructSearchForLoginRedirect } from "../../utils/loginRedirectUtils";
import { removeTokensAndLocalStorageItems } from "../../utils/logoutUtils";

export class AuthenticationStore {
    public routingStore = container.get<RoutingStore>(stores.routing);

    @observable
    public authenticationToken?: string;

    public constructor() {
        this.hasPermissions = this.hasPermissions.bind(this);
    }

    @computed
    public get isAuthenticationTokenSet(): boolean {
        return !!this.authenticationToken;
    }

    @computed
    public get userJWT(): UserJWT | undefined {
        if (!this.authenticationToken) {
            return undefined;
        }

        const user = decodeJwt(this.authenticationToken) as UserJWT | undefined;

        if (!user?.roles?.length) {
            this.logout();
            return undefined;
        }

        return user;
    }

    @computed
    public get isDriver(): boolean {
        return !!this.userJWT?.roles.includes(RolesEnum.Driver);
    }

    @computed
    public get isCompanyDriver(): boolean {
        return !!this.userJWT?.roles.includes(RolesEnum.DriversCompanyDriver);
    }

    @computed
    public get isDriversCompany(): boolean {
        return !!this.userJWT?.roles?.includes(RolesEnum.DriversCompany);
    }

    @computed
    public get isTransporter() {
        return this.isDriver || this.isCompanyDriver || this.isDriversCompany;
    }

    @computed
    public get isDriverActive(): boolean {
        return this.userJWT?.data?.driverStatus === DriverStatus.Active;
    }

    @computed
    public get isDriversCompanyActive(): boolean {
        return this.userJWT?.data?.driversCompanyStatus === DriversCompanyStatus.Active;
    }

    @computed
    public get isPremiumDriver(): boolean {
        return Boolean(this.userJWT?.data?.isPremiumDriver);
    }

    @computed
    public get isSupportMaster(): boolean {
        return !!this.userJWT?.roles.includes(RolesEnum.SupportMaster);
    }

    @computed
    public get isRegionalManager(): boolean {
        return !!(
            this.userJWT?.roles.includes(RolesEnum.RegionalManager) ||
            this.userJWT?.roles.includes(RolesEnum.SeniorRegionalManager)
        );
    }

    @computed
    public get isDriverSupport(): boolean {
        return !!this.userJWT?.roles.includes(RolesEnum.DriverSupport);
    }

    @computed
    public get isCustomerSupport(): boolean {
        return !!this.userJWT?.roles.includes(RolesEnum.CustomerSupport);
    }

    @computed
    public get isCampaignsManager(): boolean {
        return !!this.userJWT?.roles?.includes(RolesEnum.CampaignsManager);
    }

    @computed
    public get isDeveloper(): boolean {
        return !!this.userJWT?.roles?.includes(RolesEnum.Developer);
    }

    @computed
    public get hasInternalManagementPermission(): boolean {
        return this.hasPermissions("InternalManagement:Access");
    }

    public hasPermissions(requiredPermissions: PermissionParams): boolean {
        return hasPermissionsSync(this.userJWT, requiredPermissions);
    }

    public async hasDynamicPermissions(
        staticPermissions?: PermissionParams,
        dynamicPermissions?: PermissionDynamicParams,
    ): Promise<boolean> {
        return hasPermissionsAsync(this.userJWT, staticPermissions, dynamicPermissions);
    }

    @action.bound
    public async setAuthenticationToken(authenticationToken?: string): Promise<void> {
        if (this.authenticationToken === authenticationToken) {
            return;
        }

        this.authenticationToken = authenticationToken;

        if (this.userJWT?.userId) {
            Sentry.setUser({ id: this.userJWT.userId });
        }
    }

    @action.bound
    public logout(preserveCurrentLocation: boolean = false): void {
        this.authenticationToken = undefined;
        removeTokensAndLocalStorageItems();

        if (preserveCurrentLocation === true) {
            this.routingStore.replace({
                pathname: routes.login,
                search: constructSearchForLoginRedirect(this.routingStore.location),
            });
        } else {
            this.routingStore.push(routes.login);
        }
    }
}
