import { Position } from "@daytrip/legacy-models";
import { Exclude, Expose, Type } from "class-transformer";
import { IsArray, IsBoolean, IsDate, IsDefined, IsInt, IsNumber, IsString, Max, Min } from "class-validator";

import { AcceptanceRateInfo } from "../models/AcceptanceRateInfo";
import { isUndefinedOrNull } from "../utils";

import { DriverStatus } from "./DriverStatus";
import { SimpleUser } from "./SimpleUser";
import type { VehicleType } from "./VehicleType";

type SimpleIndividualDriver<SimpleDriverExtension extends SimpleDriver> = SimpleDriverExtension &
    Required<Pick<SimpleDriver, "canSpeakEnglish" | "isTCPDriver">>;

@Exclude()
export class SimpleDriver extends SimpleUser {
    @IsInt()
    @IsDefined()
    @Expose()
    @Exclude({ toClassOnly: true })
    public get status(): DriverStatus {
        if (!isUndefinedOrNull(this.declinedAt)) {
            return DriverStatus.Declined;
        }

        if (!isUndefinedOrNull(this.activeAt)) {
            return DriverStatus.Active;
        }

        return DriverStatus.Hired;
    }

    @IsNumber()
    @Max(5)
    @Min(0)
    @IsDefined()
    @Expose()
    public score: number = 0;

    @Expose()
    @Type(() => AcceptanceRateInfo)
    public acceptanceRateInfo?: AcceptanceRateInfo;

    @IsDate()
    @Expose()
    public hiredAt?: Date;

    @IsDate()
    @Expose()
    public activeAt?: Date;

    @IsDate()
    @Expose()
    public declinedAt?: Date;

    @IsString()
    @IsDefined()
    @Expose()
    public town: string = "";

    @IsString()
    @IsDefined()
    @Expose()
    public managementNote: string = "";

    @IsInt()
    @IsDefined()
    @Expose()
    public willingToSleepoverCount: number = 1;

    @IsArray()
    @IsDefined()
    @Expose()
    @Type(() => String)
    public originLocationIds: Array<string> = [];

    @Expose()
    @Type(() => Position)
    public position?: Position;

    @Expose()
    @IsString()
    public companyUserId?: string;

    @Expose()
    @IsBoolean()
    public isCompany?: boolean = false;

    @Expose()
    public amountOfDrivers?: number = 0;

    @Expose()
    @IsBoolean()
    public isCompanyDriver?: boolean = false;

    // Preactivated driver
    @Expose()
    @IsBoolean()
    public isPremiumDriver?: boolean = false;

    @Expose()
    @IsBoolean()
    public isSuitableForLuxury: boolean = false;

    @Expose()
    @IsBoolean()
    public isSuitableForPool: boolean = false;

    @IsBoolean()
    @IsDefined()
    @Expose()
    public canSpeakEnglish?: boolean = false;

    @IsBoolean()
    @IsDefined()
    @Expose()
    public willingToDoLite: boolean = true;

    @IsBoolean()
    @IsDefined()
    @Expose()
    public isTCPDriver?: boolean = false;

    @IsArray()
    @IsDefined()
    @Expose()
    @Type(() => Number)
    public willingToRentVehicleTypes: Array<VehicleType> = [];
}

export function isIndividualDriver<T extends SimpleDriver>(driver: T): driver is SimpleIndividualDriver<T> {
    return !driver.isCompany;
}
