import { statusGetter } from "@daytrip/legacy-models";
import { TransformToDate } from "@daytrip/legacy-transformers";
import { Expose, Transform, Type } from "class-transformer";
import { IsDate, IsDefined, IsInt, IsNumber, IsString, IsUUID, ValidateIf, IsEnum } from "class-validator";
import { v4 as uuid } from "uuid";

import { ChargebackStatus } from "../domain/ChargebackStatus";
import { Currency } from "../domain/Currency";
import { PaymentType } from "../domain/PaymentType";
import { CDBaseEntity, CDColumn, CDCreateDateColumn, CDEntity, CDObjectIdColumn } from "../orm";
import { isUndefinedOrNull } from "../utils";

@CDEntity("chargebacks")
export class Chargeback extends CDBaseEntity {
    @CDObjectIdColumn()
    @IsUUID("4")
    @Expose()
    public _id: string = uuid();

    @CDColumn()
    @IsUUID("4")
    @IsDefined()
    @ValidateIf((c: Chargeback) => isUndefinedOrNull(c.orderId))
    @Expose()
    public paymentId?: string;

    @CDColumn()
    @IsUUID("4")
    @IsDefined()
    @ValidateIf((c: Chargeback) => isUndefinedOrNull(c.paymentId))
    @Expose()
    public orderId?: string;

    @CDColumn()
    @IsNumber()
    @IsDefined()
    @Expose()
    public amount: number;

    @CDColumn()
    @IsEnum(Currency)
    @IsDefined()
    @Expose()
    public currency = Currency.Euro;

    @CDColumn()
    @IsString()
    @IsDefined()
    @ValidateIf((p: Chargeback) => p.type === PaymentType.Mangopay || p.type === PaymentType.Paypal)
    @Expose()
    public gatewayId?: string;

    @CDColumn()
    @IsInt()
    @IsDefined()
    @Expose()
    public type: PaymentType;

    @CDColumn()
    @IsString()
    @IsDefined()
    @ValidateIf((c: Chargeback) => c.type === PaymentType.Other)
    @Expose()
    public description?: string;

    @CDCreateDateColumn()
    @IsDate()
    @IsDefined()
    @Expose()
    @Transform((value) => (!value ? undefined : new Date(value)))
    @Type(() => Date)
    public createdAt: Date = new Date();

    @CDColumn()
    @IsDate()
    @Expose()
    @TransformToDate
    public fulfilledAt?: Date;

    @CDColumn()
    @IsDate()
    @Expose()
    @TransformToDate
    public failedAt?: Date;

    @CDColumn()
    @IsUUID("4")
    @Expose()
    public createdBy: string;

    public get status(): ChargebackStatus {
        return statusGetter(this, Chargeback, ChargebackStatus);
    }

    public static statusPrototype(status: ChargebackStatus): Chargeback {
        const defined = new Date();

        switch (status) {
            case ChargebackStatus.Failed:
                return {
                    failedAt: defined,
                } as Chargeback;

            case ChargebackStatus.Fulfilled:
                return {
                    failedAt: undefined,
                    fulfilledAt: defined,
                } as Chargeback;

            case ChargebackStatus.Pending:
            default:
                return {
                    failedAt: undefined,
                    fulfilledAt: undefined,
                } as Chargeback;
        }
    }
}
