import { DateRange } from "@daytrip/legacy-models";
import type { UserBalance } from "@legacy/dataTransferObjects/UserBalance";
import type { AssignationDataForPayout } from "@legacy/domain/AssignationDataForPayout";
import { PayoutType } from "@legacy/domain/PayoutType";
import type { SimpleUser } from "@legacy/domain/SimpleUser";
import type { Country } from "@legacy/models/Country";
import { DriverDebt } from "@legacy/models/DriverDebt";
import { Payout } from "@legacy/models/Payout";
import type { User } from "@legacy/models/User";
import type { RetrieveUsersBalancesOptions } from "@legacy/options/RetrieveUsersBalancesOptions";
import { isUndefinedOrNull } from "@legacy/utils";
import autobind from "autobind-decorator";
import { action, computed, observable, toJS, ObservableMap } from "mobx";

import { DriverDebtOperator } from "../../operators/DriverDebtOperator";
import { PayoutOperator } from "../../operators/PayoutOperator";
import { PageStore } from "../../stores/PageStore";

import type { DriversBalancesPageRouter } from "./DriversBalancesPageRouter";
import { transformPayoutPeriodIndexToDateRange } from "@daytrip/legacy-transformers";

export interface UserWithData {
    balance: UserBalance;
    isPayoutable: boolean;
    assignationsWithDataForPayout?: Array<AssignationDataForPayout>;
    driverDebts?: Array<DriverDebt>;
    driverDebtsAuthorSimpleUsers?: Array<SimpleUser>;
    payouts?: Array<Payout>;
}

export interface DriverWithDataForModal {
    balance: UserBalance;
    assignationsWithDataForPayout: Array<AssignationDataForPayout>;
    driverDebts: Array<DriverDebt>;
    driverDebtsAuthorSimpleUsers: Array<SimpleUser>;
}

@autobind
export class DriversBalancesPageStore extends PageStore<DriversBalancesPageRouter, {}> {
    @observable
    public isFetchingPeriod = false;

    @observable
    public periodIndex: number = 0;

    @computed
    public get periodDateRange(): DateRange {
        return transformPayoutPeriodIndexToDateRange(this.periodIndex);
    }

    @action
    public previousPeriod(): void {
        this.changePeriod(this.periodIndex - 1);
    }

    @action
    public nextPeriod(): void {
        this.changePeriod(this.periodIndex + 1);
    }

    @action
    public async changePeriod(index: number): Promise<void> {
        this.isFetchingPeriod = true;

        this.periodIndex = index;
        this.pageRouter.filterDepartureAtUpdate(this.periodDateRange);

        this.isFetchingPeriod = false;
    }

    @observable
    public isGeneratingCSVExportRequested: boolean = false;

    @action
    public async generateCSVExport() {
        await this.rpcClient.export.exportDriversBalances();
        this.isGeneratingCSVExportRequested = true;

        setTimeout(() => {
            this.isGeneratingCSVExportRequested = false;
        }, 3000);
    }

    @action
    public async onFetchData() {
        await this.fetchDrivers();
    }

    @observable
    public usersWithData: Array<UserWithData> | undefined;

    @computed
    public get payoutCreationUserWithData(): UserWithData | undefined {
        if (this.creatingPayoutOperator != undefined && this.usersWithData != undefined) {
            return this.usersWithData.find(
                (dwd) => dwd.balance.userId == (this.creatingPayoutOperator as PayoutOperator).driverUserId,
            );
        }

        return undefined;
    }

    @observable
    public driversCount: number = 0;

    @observable
    public balances: Array<UserBalance>;

    @observable
    public debts: Array<DriverDebt>;

    @observable
    public isCreatingDebt: boolean = false;

    @observable
    public creatingDebtOperator: DriverDebtOperator;

    @observable
    public isCreatingPayout: boolean = false;

    @observable
    public creatingPayoutOperator: PayoutOperator;

    @observable
    public showPayoutsUserId?: string;

    @observable
    public showDebtsUserId?: string;

    @action
    public async fetchDrivers(): Promise<void> {
        // empty data
        this.usersWithData = undefined;

        // fetch data for the table and overview
        const options: RetrieveUsersBalancesOptions = {
            departureAtFrom: new Date(this.pageRouter.departureAtFrom),
            departureAtTo: new Date(this.pageRouter.departureAtTo),
            skip: this.pageRouter.skip,
            limit: this.pageRouter.limit,
            sortBy: this.pageRouter.sortBy as keyof User,
            sortDirection: this.pageRouter.sortDirection,
            searchString: this.pageRouter.search,
            isIssued: this.pageRouter.isIssued,
        };

        this.driversCount = await this.rpcClient.payout.retrieveUsersBalancesCount(options);
        const balances = await this.rpcClient.payout.retrieveUsersBalances(options);

        this.usersWithData = balances.map((b) => ({
            balance: b,
            isPayoutable:
                b.amount != 0 &&
                (b.amount != b.debtAmount * -1 || (b.amount == b.debtAmount * -1 && b.amount > 0)) &&
                (b.payoutType != undefined || (b.payoutType == undefined && b.amount != b.debtAmount * -1)),
        }));
    }

    @observable
    public fetchingPayoutDataUserId?: string;

    @action
    public async fetchDriverData(userId: string, onlyIfEmpty?: boolean): Promise<DriverWithDataForModal | void> {
        if (this.usersWithData != undefined) {
            this.fetchingPayoutDataUserId = userId;

            const dwdIndex = this.usersWithData.findIndex((dvd) => dvd.balance.userId == userId);
            const dwd = toJS(this.usersWithData[dwdIndex]);

            if (dwd == undefined) {
                throw new Error("invalid user id");
            }

            const optionallyFetch = async (value: keyof DriverWithDataForModal, fetchCallback: () => Promise<any>) => {
                if ((onlyIfEmpty == true && dwd[value] == undefined) || onlyIfEmpty != true) {
                    dwd[value] = await fetchCallback();
                }
            };

            await optionallyFetch("assignationsWithDataForPayout", async () =>
                this.rpcClient.assignation.retrieveDriverAssignationsForPayout({
                    driverIds: [userId],
                    departureAtFrom: this.pageRouter.departureAtFromDate,
                    departureAtTo: this.pageRouter.departureAtToDate,
                    sortBy: "orderDepartureAt",
                    sortDirection: -1,
                }),
            );

            await optionallyFetch("driverDebts", async () => {
                const debts = await this.rpcClient.payout.retrieveDriversDebts({
                    driversIds: [userId],
                });

                return debts.concat(
                    (
                        await this.rpcClient.payout.retrieveDriversDebts({
                            driversIds: [userId],
                            hasAssignation: true,
                            assignationIds: (dwd.assignationsWithDataForPayout as Array<AssignationDataForPayout>).map(
                                (ab) => ab.assignationId,
                            ),
                        })
                    ).filter((d) => debts.findIndex((dd) => dd._id == d._id) == -1),
                );
            });

            await optionallyFetch("driverDebtsAuthorSimpleUsers", async () =>
                this.rpcClient.user.retrieveSimpleUsers({
                    userIds: (dwd.driverDebts as Array<DriverDebt>)
                        .filter((d) => d.createdByUserId != undefined)
                        .map((d) => d.createdByUserId as string),
                }),
            );

            this.usersWithData[dwdIndex] = dwd;
            this.fetchingPayoutDataUserId = undefined;
            return dwd as DriverWithDataForModal;
        }
    }

    @action
    public async updateUserData(userId: string): Promise<void> {
        if (this.usersWithData != undefined) {
            const dwd = this.usersWithData.find((dvd) => dvd.balance.userId == userId);

            if (dwd == undefined) {
                throw "invalid user id";
            }

            dwd.balance = (
                await this.rpcClient.payout.retrieveUsersBalances({
                    userIds: [userId],
                    departureAtFrom: new Date(this.pageRouter.departureAtFrom),
                    departureAtTo: new Date(this.pageRouter.departureAtTo),
                })
            )[0];

            await this.fetchDriverData(userId);
        }
    }

    public isDataFetched(): this is DriversBalancesPageStore & DriversPageStoreDataFetched {
        return this.usersWithData != undefined;
    }

    @observable
    public selectedDrivers: ObservableMap<string, boolean> = observable.map({});

    @computed
    public get payoutableUserWithData(): Array<UserWithData> | undefined {
        if (this.usersWithData != undefined) {
            return this.usersWithData.filter((uwd) => uwd.isPayoutable);
        }
        return undefined;
    }

    @computed
    public get isAllDriversSelected(): boolean {
        if (this.payoutableUserWithData == undefined) {
            return false;
        }

        let allSelected = true;

        this.payoutableUserWithData.forEach((dwd) => {
            if (this.selectedDrivers.get(dwd.balance.userId) !== true) {
                allSelected = false;
            }
        });

        return allSelected;
    }

    @action
    public toggleAllDrivers() {
        if (this.payoutableUserWithData == undefined) {
            return;
        }

        if (!this.isAllDriversSelected) {
            this.payoutableUserWithData.forEach((dwd) => {
                if (dwd.balance.amount != 0) {
                    this.selectedDrivers.set(dwd.balance.userId, true);
                }
            });
        } else {
            this.selectedDrivers.clear();
        }
    }

    @action
    public toggleDriver(userId: string) {
        if (this.selectedDrivers.get(userId) === true) {
            this.selectedDrivers.set(userId, false);
        } else {
            this.selectedDrivers.set(userId, true);
        }
    }

    @computed
    public get usersWithDataToPayout(): undefined | Array<UserWithData> {
        if (this.payoutableUserWithData == undefined) {
            return;
        }

        return this.payoutableUserWithData.filter((uwd) => this.selectedDrivers.get(uwd.balance.userId) === true);
    }

    @observable
    public isPayoutSelectedDriversInProgress = false;

    @action
    public async payoutSelectedDrivers(): Promise<void> {
        this.isPayoutSelectedDriversInProgress = true;

        if (this.usersWithDataToPayout != undefined && this.usersWithDataToPayout.length > 0) {
            await this.rpcClient.payout.preparePayouts({
                userIds: this.usersWithDataToPayout.map((uwd) => uwd.balance.userId),
                departureAtFrom: this.pageRouter.departureAtFromDate,
                departureAtTo: this.pageRouter.departureAtToDate,
                isIssued: this.pageRouter.isIssued,
            });

            await this.fetchDrivers();

            this.selectedDrivers.clear();
        }

        this.isPayoutSelectedDriversInProgress = false;
    }

    @action
    public toggleCreatingDebt(userId?: string) {
        if (!this.isCreatingDebt && userId != undefined) {
            const driverDebt = new DriverDebt();
            driverDebt.driverUserId = userId;

            this.creatingDebtOperator = new DriverDebtOperator({
                modelConstructor: DriverDebt,
                model: driverDebt,
                data: null,
                modules: null,
                onSave: async (model: DriverDebt) => {
                    await this.rpcClient.payout.createDriverDebt(model);

                    await this.updateUserData(model.driverUserId);

                    this.isCreatingDebt = false;
                },
            });

            this.isCreatingDebt = true;
        } else {
            this.isCreatingDebt = false;
        }
    }

    @action
    public async toggleCreatingPayout(userId?: string) {
        if (!this.isCreatingPayout && userId != undefined) {
            const dwd = await this.fetchDriverData(userId, true);

            if (!dwd) {
                return;
            }

            const payout = new Payout();
            payout.amount = dwd.balance.amount;
            payout.userId = userId;
            payout.type = dwd.balance.payoutType == null ? PayoutType.Other : dwd.balance.payoutType;
            payout.transferGatewayIds = [];

            this.creatingPayoutOperator = new PayoutOperator({
                modelConstructor: Payout,
                model: payout,
                data: null,
                modules: null,
                driverUserId: dwd.balance.userId,
                assignationsWithDataForPayout: dwd.assignationsWithDataForPayout,
                driverDebts: dwd.driverDebts,
                driverDebtsAuthorSimpleUsers: dwd.driverDebtsAuthorSimpleUsers,

                includedAssignationIds: dwd.assignationsWithDataForPayout
                    .filter((awd) => awd.driversBalance !== 0)
                    .map((awd) => awd.assignationId),
                includedDebtIds: dwd.driverDebts.filter((d) => !d.payoutId).map((d) => d._id),

                validateOptions: { skipMissingProperties: true },
                onSave: async (model: Payout) => {
                    model.amount = this.creatingPayoutOperator.totalAmount;
                    // TODO: For history - method was removed long time ago
                    // await this.rpcClient.payout.payoutDriver({
                    //     userId: dwd.balance.userId,
                    //     assignationIds: this.creatingPayoutOperator.includedAssignationIds,
                    //     debtIds: this.creatingPayoutOperator.includedDebtIds,
                    //     amount: model.amount,
                    //     type: model.type,
                    //     description: model.description
                    // } as PayoutDriverOptions);

                    await this.updateUserData(model.userId);

                    this.creatingPayoutOperator.isCreated = true;
                    this.isCreatingPayout = false;
                },
            });

            this.creatingPayoutOperator.edit();

            this.isCreatingPayout = true;
        } else {
            this.isCreatingPayout = false;
        }
    }

    @action
    public async toggleShowPayouts(userId?: string) {
        if (isUndefinedOrNull(this.showPayoutsUserId) && userId != undefined) {
            await this.fetchDriverData(userId, true);

            this.showPayoutsUserId = userId;
        } else {
            this.showPayoutsUserId = undefined;
        }
    }

    @action
    public async toggleShowDebts(userId?: string) {
        if (isUndefinedOrNull(this.showDebtsUserId) && userId != undefined) {
            await this.fetchDriverData(userId, true);
            this.showDebtsUserId = userId;
        } else {
            this.showDebtsUserId = undefined;
        }
    }
}

export interface DriversPageStoreDataFetched {
    countries: Array<Country>;
    drivers: Array<User>;
}
