import autobind from "autobind-decorator";
import { observable } from "mobx";

import { getRpcClient } from "../rpc-browser-sdk";

import { EnumOperatorOptions } from "./EnumOperatorOptions";

@autobind
export class EnumOperator<TEnum, TOptions, TModules, TData> {
    protected rpcClient = getRpcClient();

    @observable
    public enum: TEnum;

    // service (Don't use them for data fetching)
    public modules: TModules;

    // data like locations, and so..
    public data: TData;

    public constructor(options: EnumOperatorOptions<TEnum, TModules, TData> & TOptions) {
        this.enum = options.enum;

        this.modules = options.modules;

        this.data = options.data;
    }
}
