import { extendObservable } from "mobx";

export const observeModel = (object: Object, nonObservableProperties: Array<string>) => {
    for (const key in object) {
        if (nonObservableProperties.find((k) => k == key) == undefined) {
            if (
                (object[key] instanceof Object && !(object[key] instanceof Array)) || // not array
                (object[key] instanceof Array && object[key].length > 0 && object[key][0] instanceof Object)
            ) {
                // array of objects
                observeModel(object[key], nonObservableProperties);
            } else {
                object = extendObservable(object, { [key]: object[key] });
            }
        }
    }
};
