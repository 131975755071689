import { Exclude, Expose } from "class-transformer";
import { IsDefined, IsNumber } from "class-validator";

import { GField, GFloat, GObjectType } from "../graphql";

@GObjectType("SimpleTravelData")
@Exclude()
export class SimpleTravelData {
    @GField(() => GFloat)
    @IsNumber()
    @IsDefined()
    @Expose()
    public distance: number = 0;

    @GField(() => GFloat)
    @IsNumber()
    @IsDefined()
    @Expose()
    public duration: number = 0;

    @GField(() => String, { nullable: true })
    @Expose()
    public locationId?: string;
}
