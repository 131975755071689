import { Position } from "@daytrip/legacy-models";
import { Exclude, Expose } from "class-transformer";
import { IsDefined, IsNumber, IsOptional, IsString, Length } from "class-validator";

import { GField, GFloat, GInputType, GObjectType } from "../graphql";

@GObjectType("SimpleOrderCustomLocation")
@GInputType("SimpleOrderCustomLocationInput")
@Exclude()
export class SimpleOrderCustomLocation {
    @GField()
    @Length(1, 64)
    @IsString()
    @IsDefined()
    @Expose()
    public name: string = "";

    @GField(() => GFloat)
    @IsNumber()
    @IsDefined()
    @Expose()
    public order: number;

    @GField(() => GFloat)
    @IsNumber()
    @IsDefined()
    @Expose()
    public duration: number;

    @GField(() => Position)
    @Expose()
    public position: Position;

    @GField({ nullable: true })
    @IsString()
    @IsOptional()
    @Expose()
    public countryId?: string;
}
