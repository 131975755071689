/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */

import type { Country } from "@legacy/models/Country";
import { VehicleMake } from "@legacy/models/VehicleMake";
import { VehicleModel } from "@legacy/models/VehicleModel";
import type { SortingPaginationOptions } from "@legacy/options/SortingPaginationOptions";
import { isUndefinedOrNull } from "@legacy/utils";
import { Africa, Asia, Europe, LATAM } from "@legacy/utils/continentsCountriesISOCodes";
import autobind from "autobind-decorator";
import { isUUID } from "class-validator";
import { action, observable, computed, toJS } from "mobx";

import { PageStore } from "../../stores/PageStore";

import type { VehicleModelsPageRouter } from "./VehicleModelsPageRouter";

@autobind
export class VehicleModelsPageStore extends PageStore<VehicleModelsPageRouter, {}> {
    @action
    public async onFetchData() {
        await this.fetchVehicleMakes();
        await this.fetchVehicleModels();
        await this.fetchCountries();
    }

    @observable
    public vehicleMakes: Array<VehicleMake>;

    @observable
    public vehicleModels?: Array<VehicleModel>;

    @observable
    public newVehicleModelMake: string;

    @observable
    public newVehicleModelName: string;

    @observable
    public newVehicleModelSeatsCount: number;

    @observable
    public newVehicleModelDisabledAt?: Date;

    @observable
    public newVehicleModelIsLuxury: boolean = false;

    @observable
    public newVehicleModelApprovedForCountryIds: Array<string> = [];

    @observable
    public newVehicleModelLuxuryForCountryIds: Array<string> = [];

    @observable
    public editedVehicleModel?: VehicleModel;

    @observable
    public countries?: Array<Country>;

    @action
    public async fetchVehicleMakes() {
        this.vehicleMakes = await this.rpcClient.vehicle.retrieveVehicleMakes();
    }

    public transformCountryIdsToNames(countryIds: Array<string>): string {
        if (!countryIds) {
            countryIds = [];
        }

        if (!this.countries) {
            return "";
        }

        return this.countries
            .filter((c) => countryIds.indexOf(c._id) > -1)
            .map((c) => c.englishName)
            .join(", ");
    }

    @action
    public async fetchVehicleModels() {
        this.vehicleModels = undefined;

        const options = {
            sortBy: this.pageRouter.sortBy,
            sortDirection: this.pageRouter.sortDirection,
        } as SortingPaginationOptions<VehicleModel>;

        this.vehicleModels = await this.rpcClient.vehicle.retrieveVehicleModels(options);
    }

    @action
    public async fetchCountries() {
        this.countries = await this.rpcClient.content.retrieveCountries({});
    }

    public isDataFetched(): this is VehicleModelsPageStore & VehicleModelsPageStoreDataFetched {
        return !!this.vehicleModels && !!this.countries;
    }

    public getVehicleMakeNameById(vehicleMakeId: string): string {
        if (!isUndefinedOrNull(this.vehicleMakes)) {
            const vehicleMake = this.vehicleMakes.find((vm) => vm._id === vehicleMakeId);

            if (!isUndefinedOrNull(vehicleMake)) {
                return vehicleMake.name;
            }
        }

        return "";
    }

    @action
    public async onChangeNewModelMake(value: string) {
        if (!isUUID(value)) {
            const newVehicleMake = new VehicleMake();
            newVehicleMake.name = value;
            const newVehicleId = await this.rpcClient.vehicle.createVehicleMake(newVehicleMake);
            newVehicleMake._id = newVehicleId;
            this.vehicleMakes.push(newVehicleMake);

            this.newVehicleModelMake = newVehicleMake._id;
        } else {
            this.newVehicleModelMake = value;
        }
    }

    @action
    public onChangeNewModelName(value: string) {
        this.newVehicleModelName = value;
    }

    @action
    public onChangeNewModelPassengers(value: number) {
        this.newVehicleModelSeatsCount = value;
    }

    @action
    public onChangeNewModelDisabled(flag: boolean) {
        if (flag) {
            this.newVehicleModelDisabledAt = new Date();
        } else {
            this.newVehicleModelDisabledAt = undefined;
        }
    }

    @action
    public onChangeNewModelIsLuxury(flag: boolean) {
        this.newVehicleModelIsLuxury = flag;
    }

    @action
    public onChangeNewModelApprovedCountries(countryIds: Array<string>) {
        this.newVehicleModelApprovedForCountryIds = countryIds;
    }

    @action
    public setNewModelApprovedForCountries(countryIds: string[]) {
        this.newVehicleModelApprovedForCountryIds.push(...countryIds);
        this.newVehicleModelApprovedForCountryIds = this.newVehicleModelApprovedForCountryIds.filter(
            (value, index, self) => self.indexOf(value) === index,
        );
    }

    @action
    public setNewModelApprovedForEurope() {
        this.setNewModelApprovedForCountries(this.getEuropeCountryIds());
    }

    @action
    public setNewModelApprovedForAsia() {
        this.setNewModelApprovedForCountries(this.getAsiaCountryIds());
    }

    @action
    public setNewModelApprovedForLatam() {
        this.setNewModelApprovedForCountries(this.getLatamCountryIds());
    }

    @action
    public setNewModelApprovedForAfrica() {
        this.setNewModelApprovedForCountries(this.getAfricaCountryIds());
    }

    @action
    public onChangeNewModelLuxuryCountries(countryIds: Array<string>) {
        this.newVehicleModelLuxuryForCountryIds = countryIds;
    }

    @action
    public async createVehicleModel() {
        if (
            isUndefinedOrNull(this.newVehicleModelMake) ||
            isUndefinedOrNull(this.newVehicleModelName) ||
            isUndefinedOrNull(this.newVehicleModelSeatsCount)
        ) {
            return;
        }

        const vehicleModel = {} as VehicleModel;
        vehicleModel.makeId = this.newVehicleModelMake;
        vehicleModel.name = this.newVehicleModelName;
        vehicleModel.seatsCount = this.newVehicleModelSeatsCount;
        vehicleModel.disabledAt = this.newVehicleModelDisabledAt;
        vehicleModel.isLuxury = this.newVehicleModelIsLuxury;
        vehicleModel.approvedForCountryIds = this.newVehicleModelApprovedForCountryIds;
        vehicleModel.luxuryForCountryIds = this.newVehicleModelLuxuryForCountryIds;

        try {
            await this.rpcClient.vehicle.createVehicleModel(vehicleModel);
        } catch (e: any) {
            if (e === 500) {
                alert("Unable to create vehicle model. Please check if all values are correct.");
                return;
            }
            console.log(e);
            alert("There was an error creating vehicle model");
        }

        this.newVehicleModelMake = "";
        this.newVehicleModelName = "";
        this.newVehicleModelSeatsCount = 0;
        this.newVehicleModelDisabledAt = undefined;
        this.newVehicleModelApprovedForCountryIds = [];
        this.newVehicleModelLuxuryForCountryIds = [];

        this.vehicleModels = undefined;
        this.fetchVehicleModels();
    }

    @action
    public setEditedVehicleModelId(id: string) {
        if (this.vehicleModels) {
            const editedVehicle = this.vehicleModels.find((vm) => vm._id === id);
            this.editedVehicleModel = toJS(editedVehicle);
        }
    }

    @action
    public releaseEditedVehicleModel() {
        this.editedVehicleModel = undefined;
    }

    @action
    public onChangeEditedModelName(value: string) {
        const editedVehicle = this.editedVehicleModel;
        if (editedVehicle) {
            editedVehicle.name = value;
        }

        this.editedVehicleModel = editedVehicle;
    }

    @action
    public onChangeEditedModelPassengers(value: number) {
        const editedVehicle = this.editedVehicleModel;
        if (editedVehicle) {
            editedVehicle.seatsCount = value;
        }

        this.editedVehicleModel = editedVehicle;
    }

    @action
    public onChangeEditedModelDisabled(flag: boolean) {
        const editedVehicle = this.editedVehicleModel;
        if (editedVehicle) {
            editedVehicle.disabledAt = flag ? new Date() : undefined;
        }

        this.editedVehicleModel = editedVehicle;
    }

    @action
    public onChangeEditedModelIsLuxury(flag: boolean) {
        if (this.editedVehicleModel != undefined) {
            this.editedVehicleModel.isLuxury = flag;
        }
    }

    public countryGroups = {
        Asia,
        Europe,
        Africa,
        LatinAmerica: LATAM,
    };

    public getCountryIds(countryIsoCodes: string[]) {
        if (!this.countries) {
            return [];
        }
        return this.countries.filter((c) => countryIsoCodes.indexOf(c.isoCode) > -1).map((c) => c._id);
    }

    public getEuropeCountryIds(): Array<string> {
        return this.getCountryIds(this.countryGroups.Europe);
    }

    public getAsiaCountryIds(): Array<string> {
        return this.getCountryIds(this.countryGroups.Asia);
    }

    public getAfricaCountryIds(): Array<string> {
        return this.getCountryIds(this.countryGroups.Africa);
    }

    public getLatamCountryIds(): Array<string> {
        return this.getCountryIds(this.countryGroups.LatinAmerica);
    }

    @action
    public onChangeEditedModelApprovedCountries(countryIds: Array<string>) {
        if (this.editedVehicleModel != null) {
            this.editedVehicleModel.approvedForCountryIds = countryIds;
        }
    }

    @action
    public onChangeEditedModelLuxuryCountries(countryIds: Array<string>) {
        if (this.editedVehicleModel != null) {
            this.editedVehicleModel.luxuryForCountryIds = countryIds;
        }
    }

    @action
    public setEditedApprovedForCountries(countryIds: string[]) {
        if (this.editedVehicleModel != null) {
            this.editedVehicleModel.approvedForCountryIds.push(...countryIds);
            this.editedVehicleModel.approvedForCountryIds = this.editedVehicleModel.approvedForCountryIds.filter(
                (value, index, self) => self.indexOf(value) === index,
            );
        }
    }

    @action
    public setEditedApprovedForEurope() {
        this.setEditedApprovedForCountries(this.getEuropeCountryIds());
    }

    @action
    public setEditedApprovedForAsia() {
        this.setEditedApprovedForCountries(this.getAsiaCountryIds());
    }

    @action
    public setEditedApprovedForLatam() {
        this.setEditedApprovedForCountries(this.getLatamCountryIds());
    }

    @action
    public setEditedApprovedForAfrica() {
        this.setEditedApprovedForCountries(this.getAfricaCountryIds());
    }

    @action
    public async saveEditedVehicleModel() {
        if (!isUndefinedOrNull(this.editedVehicleModel) && this.vehicleModels && this.editedVehicleModel) {
            const editedVehicleModelIndex = this.vehicleModels.findIndex(
                (vm) => vm._id == this.editedVehicleModel!._id,
            );

            if (
                !isUndefinedOrNull(this.editedVehicleModel.disabledAt) &&
                (await this.rpcClient.vehicle.checkIsVehicleModelUsed(this.editedVehicleModel._id)) == true
            ) {
                const vehiclesToDecline = await this.rpcClient.vehicle.retrieveVehiclesByModelId(
                    this.editedVehicleModel._id,
                );

                for (const vi in vehiclesToDecline) {
                    const vehicle = vehiclesToDecline[vi];

                    await this.rpcClient.vehicle.declineVehicle(vehicle._id, "This vehicle model is forbidden");
                }
            }

            await this.rpcClient.vehicle.updateVehicleModel(this.editedVehicleModel);
            this.vehicleModels[editedVehicleModelIndex] = await this.rpcClient.vehicle.retrieveVehicleModel(
                this.editedVehicleModel._id,
            );

            this.releaseEditedVehicleModel();
        }
    }

    @action
    public async removeVehicleModel(id: string) {
        await this.rpcClient.vehicle.deleteVehicleModel(id);

        this.vehicleModels = undefined;
        this.fetchVehicleModels();
    }

    @observable
    public shouldDisplayAddNewCountriesToVehicleModelsBlock?: boolean;

    @computed
    public get hasModifyVehicleModelPermission() {
        return this.authenticationStore.hasPermissions("VehicleModel:Modify");
    }

    @action
    public async displayAddNewCountriesToVehicleModelsBlock(): Promise<void> {
        this.shouldDisplayAddNewCountriesToVehicleModelsBlock = true;
    }

    @action
    public async hideAddNewCountriesToVehicleModelsBlock(): Promise<void> {
        this.shouldDisplayAddNewCountriesToVehicleModelsBlock = false;
    }

    @observable
    public newCountryIdsForVehicleModels?: Array<string>;

    @observable
    public isSetToLuxuryForCountries?: boolean;

    @action
    public onChangeNewCountryIdsForVehicleModels(countryIds: Array<string>) {
        this.newCountryIdsForVehicleModels = countryIds;
    }

    @observable
    public vehicleModelIdsToApproveForCountries: Array<string> = [];

    @action
    public toggleVehicleModelToApproveForCountries(modelId: string): void {
        // add to array
        if (this.vehicleModelIdsToApproveForCountries.indexOf(modelId) === -1) {
            this.vehicleModelIdsToApproveForCountries.push(modelId);
        } else {
            // remove
            this.vehicleModelIdsToApproveForCountries = this.vehicleModelIdsToApproveForCountries.filter(
                (v) => v !== modelId,
            );
        }
    }

    @action
    public toggleAllVehicleModelsToApproveForCountries(): void {
        if (!this.vehicleModels) {
            return;
        }

        if (this.vehicleModelIdsToApproveForCountries.length == this.vehicleModels.length) {
            this.vehicleModelIdsToApproveForCountries = [];
        } else {
            this.vehicleModelIdsToApproveForCountries = this.vehicleModels.map((vm) => vm._id);
        }
    }

    @action
    public toggleIsSetToLuxuryForCountries(): void {
        this.isSetToLuxuryForCountries = !this.isSetToLuxuryForCountries;
    }

    @action
    public async approveVehicleModelsForCountries(): Promise<void> {
        await this.rpcClient.vehicle.approveVehicleModelsForCountries(
            this.vehicleModelIdsToApproveForCountries,
            this.newCountryIdsForVehicleModels || [],
            this.isSetToLuxuryForCountries,
        );

        this.vehicleModelIdsToApproveForCountries = [];
        this.newCountryIdsForVehicleModels = [];
        this.isSetToLuxuryForCountries = false;
        this.shouldDisplayAddNewCountriesToVehicleModelsBlock = false;

        await this.fetchVehicleModels();
    }
}

export interface VehicleModelsPageStoreDataFetched {
    vehicleModels: Array<VehicleModel>;
    countries: Array<Country>;
}
