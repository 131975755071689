import { getDay, getDaysInMonth, setDate, startOfMonth } from "date-fns";

import { AvailabilityUpdateSchema } from "@legacy/domain/AvailabilityUpdateSchema";
import { Unavailability } from "@legacy/models/Unavailability";
import { compareLocalDateToUtcDate, compareDatesByDate } from "../calendar.utils";
import { AnonymizedOrderWithLocations } from "../../types";
import { AvailabilityDayStatus } from "@daytrip/legacy-enums";

const assignationsToTitle = (assign: Array<AnonymizedOrderWithLocations>) =>
    assign
        .map(
            (a) => `${a.origin.name.substring(0, 3).toUpperCase()}-${a.destination.name.substring(0, 3).toUpperCase()}`,
        )
        .join(", ");

export type MonthDate =
    | {
          dayDate: Date;
          dayNumber: number;
          isPending: boolean;
          isToday: boolean;
          isDisabled: boolean;
          status: AvailabilityDayStatus;
          title: string;
      }
    | {
          status: AvailabilityDayStatus.Empty;
      };

export const buildMonthDates = (
    currentMonth: number,
    currentYear: number,
    availabilityArray: Array<Unavailability>,
    pendingChanges: Array<AvailabilityUpdateSchema>,
    assignationsArray: Array<Array<AnonymizedOrderWithLocations>>,
    disableDaysBefore?: Date,
    disabled?: boolean,
): MonthDate[] => {
    const firstDateInMonth = new Date(currentYear, currentMonth);
    const daysInMonth: number = getDaysInMonth(firstDateInMonth);
    const firstDayOfMonth: Date = startOfMonth(firstDateInMonth);
    const firstDayOfMonthInWeek = getDay(firstDayOfMonth) === 0 ? 7 : getDay(firstDayOfMonth);

    const emptyDays = Array(firstDayOfMonthInWeek - 1).fill({ status: AvailabilityDayStatus.Empty });

    const monthDays = Array(daysInMonth)
        .fill(0)
        .map((_, idx) => idx + 1)
        .map((dayNumber) => {
            const dayDate = setDate(firstDateInMonth, dayNumber);
            const result = {
                dayDate,
                dayNumber,
                isPending: false,
                isToday: false,
                isDisabled: false,
                status: AvailabilityDayStatus.Active,
                title: "",
            };

            const dayAvailability = availabilityArray.find((a) => compareLocalDateToUtcDate(dayDate, new Date(a.date)));
            const dayPendingChange = pendingChanges.find((pc) => compareLocalDateToUtcDate(dayDate, new Date(pc.date)));

            result.isPending = !!dayPendingChange;

            const dayAssignations = assignationsArray.length >= dayNumber ? assignationsArray[dayNumber - 1] : [];
            if (dayAssignations?.length > 0) {
                result.title = assignationsToTitle(dayAssignations);
                result.status = AvailabilityDayStatus.Assigned;
            } else if (dayAvailability) {
                result.status = AvailabilityDayStatus.Inactive;
            } else {
                result.status = AvailabilityDayStatus.Active;
            }

            if (dayPendingChange !== undefined) {
                result.status = dayPendingChange.dayStatus;
            }
            result.isToday = compareDatesByDate(new Date(), dayDate);
            result.isDisabled =
                (disableDaysBefore !== undefined && dayDate.getTime() < disableDaysBefore.getTime()) ||
                disabled ||
                false;

            return result;
        });
    return [...emptyDays, ...monthDays];
};
