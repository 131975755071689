import autobind from "autobind-decorator";
import { action, computed, observable } from "mobx";
import type { Location } from "mobx-react-router";

import { getApolloSdk } from "../../apolloClient/apolloClient";
import { PageStore } from "../../stores/PageStore";
import type { DriverCountryRuleForTableFragment } from "../../types/generated/graphql-types";
import { PaginatedType } from "../../types/PaginatedType";

import { DriverCountryRuleFilterStore } from "./DriverCountryRuleFilterStore";
import { DriverCountryRuleOperator } from "./DriverCountryRuleOperator";
import type { DriverCountryRulesPageRouter } from "./DriverCountryRulesRouter";
import { DriverCountryRuleCompoundId } from "./types";

interface DriverCountryRulesPageModules {}

@autobind
export class DriverCountryRulesPageStore extends PageStore<
    DriverCountryRulesPageRouter,
    DriverCountryRulesPageModules
> {
    @observable tableData: PaginatedType<DriverCountryRuleForTableFragment> = {
        data: [],
        totalCount: 0,
    };

    @observable editedRule?: DriverCountryRuleOperator;

    @observable editMode: "create" | "update" = "create";

    @computed get isEditingRule() {
        return !!this.editedRule;
    }

    public filterStore: DriverCountryRuleFilterStore;

    constructor() {
        super();

        this.configRefetchTableDataOnRuleEdited();
    }

    public onInit(_location?: Location): void {
        this.filterStore = new DriverCountryRuleFilterStore(this.pageRouter);
    }

    @action async fetchRules() {
        const { skip, limit, driverCountryId, originCountryId, destinationCountryId } = this.pageRouter;
        const offset = Number(skip) - 1;

        const { driverCountryRules } = await getApolloSdk().ListDriverCountryRulesForTable({
            limit,
            offset,
            driverCountryIds: driverCountryId ? [driverCountryId] : undefined,
            originCountryIds: originCountryId ? [originCountryId] : undefined,
            destinationCountryIds: destinationCountryId ? [destinationCountryId] : undefined,
        });

        this.tableData = driverCountryRules;
    }

    @action createNewRule() {
        this.editMode = "create";
        this.editedRule = new DriverCountryRuleOperator({
            canAcceptAssignationOffers: true,
            canBeAutomaticallyAssigned: true,
        });
    }

    @action async editRule(ruleId: DriverCountryRuleCompoundId) {
        this.editMode = "update";

        const { driverCountryRule } = await getApolloSdk().GetDriverCountryRuleForEdit({
            ...ruleId,
        });

        this.editedRule = new DriverCountryRuleOperator(driverCountryRule);
    }

    @action async finishEditingRule() {
        this.editedRule = undefined;

        this.fetchRules();
    }

    @action async deleteRule(ruleId: DriverCountryRuleCompoundId) {
        await getApolloSdk().DeleteDriverCountryRule({
            ...ruleId,
        });

        this.fetchRules();
    }

    private configRefetchTableDataOnRuleEdited() {}
}
