import { isEmail } from "class-validator";
import { action, observable, reaction } from "mobx";
import type { LocationDescriptor } from "mobx-react-router";
import type MobxReactRouter from "mobx-react-router";
import { KeyboardEvent } from "react";

import type { RoutingStore } from "../../container";
import { container, stores } from "../../container";
import { routes } from "../../routes";
import { PageStore } from "../../stores/PageStore";
import {
    setManagementAuthenticationToken,
    setManagementRefreshToken,
} from "../../utils/authentication/authenticationUtils";
import { retrieveLoginRedirectUrl } from "../../utils/loginRedirectUtils";

import type { LoginPageRouter } from "./LoginPageRouter";

export class LoginPageStore extends PageStore<LoginPageRouter, {}> {
    private routingStore: RoutingStore;

    @observable
    public email: string = "";

    @observable
    public isEmailValid: boolean = true;

    @observable
    public password: string = "";

    @observable
    public isLoginLocalFetching: boolean = false;

    @observable
    public loginError: null | number = null;

    @observable
    public redirect: null | LocationDescriptor = null;

    @observable
    public loginWindow: Window | null = null;

    private reactionDisposer: (() => void) | null = null;

    public constructor() {
        super();
        this.keyPressOnInput = this.keyPressOnInput.bind(this);
        this.routingStore = container.get<RoutingStore>(stores.routing);
    }

    public onInit(_location?: MobxReactRouter.Location) {
        super.onInit(_location);
        this.setupReactions();
    }

    @action.bound
    public async keyPressOnInput(event: KeyboardEvent<HTMLInputElement>) {
        if (event.key === "Enter") {
            await this.loginLocal();
        }
    }

    @action.bound
    public changeEmail(email: string) {
        this.email = email;
    }

    @action.bound
    public validateEmail() {
        this.isEmailValid = isEmail(this.email);
    }

    @action.bound
    public changePassword(password: string) {
        this.password = password;
    }

    @action
    public receiveLoginLocal(error: null | number) {
        this.loginError = error;
        this.isLoginLocalFetching = false;
        this.isEmailValid = true;
        this.email = "";
        this.password = "";
    }

    @observable
    public isForgotPasswordOpened = false;

    @action.bound
    public async loginLocal(): Promise<void> {
        if (this.isForgotPasswordOpened) {
            this.isForgotPasswordOpened = false;
            return;
        }

        this.loginError = null;
        this.isLoginLocalFetching = true;

        try {
            const tokenPair = await this.rpcClient.authentication.authenticateForManagement({
                email: this.email,
                password: this.password,
            });

            if (!tokenPair) {
                throw new Error("Authentication failed");
            }

            setManagementAuthenticationToken(tokenPair.authentication);
            setManagementRefreshToken(tokenPair.refresh);
            await this.authenticationStore.setAuthenticationToken(tokenPair.authentication);
            this.receiveLoginLocal(null);

            const locationBeforeRedirect = retrieveLoginRedirectUrl();
            if (locationBeforeRedirect) {
                this.routingStore.replace(locationBeforeRedirect);
            } else {
                this.routingStore.replace({ pathname: routes.index });
            }
        } catch (e: any) {
            this.receiveLoginLocal(e);
        }
    }

    @observable
    public isForgotPasswordFetching = false;

    @observable
    public isForgotPasswordSent = false;

    @observable
    public forgotPasswordError: null | number = null;

    @action.bound
    public openForgotPassword() {
        this.loginError = null;
        this.isForgotPasswordOpened = true;
    }

    @action.bound
    public async forgotPassword(): Promise<void> {
        this.loginError = null;

        if (!this.isForgotPasswordOpened) {
            return;
        }

        this.validateEmail();

        if (!this.isEmailValid) {
            return;
        }

        this.isForgotPasswordFetching = true;

        try {
            await this.rpcClient.authentication.requestPasswordReset(this.email);

            this.forgotPasswordError = null;
            this.isForgotPasswordOpened = false;
        } catch (e: any) {
            this.forgotPasswordError = e;
        }

        this.isForgotPasswordSent = true;
        this.isForgotPasswordFetching = false;
    }

    @action.bound
    private handleQueryParams() {
        if (this.pageRouter.email) {
            this.email = this.pageRouter.email;
        }

        if (this.pageRouter.email && this.pageRouter.autoEmailResetAction) {
            this.openForgotPassword();
            this.forgotPassword();
        }
    }

    private setupReactions() {
        this.reactionDisposer = reaction(
            () => (this.pageRouter.email ?? "") + this.pageRouter.autoEmailResetAction,
            this.handleQueryParams,
            { fireImmediately: true },
        );
    }

    public dispose() {
        if (this.reactionDisposer) {
            this.reactionDisposer();
        }
    }
}
