import { Type } from "class-transformer";

import { AssignationPayout } from "../models/AssignationPayout";
import { DriverDebt } from "../models/DriverDebt";

import { AssignationDataForPayout } from "./AssignationDataForPayout";
import { PayoutForPayoutsPage } from "./PayoutForPayoutsPage";

export class PayoutData {
    public _id: string;

    @Type(() => PayoutForPayoutsPage)
    public payout: PayoutForPayoutsPage;

    @Type(() => PayoutData)
    public otherPayouts: Array<PayoutData>;

    @Type(() => AssignationDataForPayout)
    public assignations: Array<AssignationDataForPayout>;

    @Type(() => AssignationPayout)
    public assignationPayouts: Array<AssignationPayout>;

    @Type(() => DriverDebt)
    public otherDebtsWithNonExistingPayout: Array<DriverDebt>;

    @Type(() => DriverDebt)
    public debts: Array<DriverDebt>;
}
