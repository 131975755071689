import { getManagementAuthenticationToken } from "../../utils/authentication/authenticationUtils";

import { AuthenticationStore } from "./AuthenticationStore";

let authenticationStore: AuthenticationStore | null = null;

export function getAuthenticationStore() {
    if (!authenticationStore) {
        authenticationStore = new AuthenticationStore();

        const authenticationToken = getManagementAuthenticationToken();
        authenticationStore.setAuthenticationToken(authenticationToken);
    }
    return authenticationStore;
}
