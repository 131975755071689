import { calculateDepartInFromBookingTime } from "./calculateDepartInFromBookingTime";

/**
 * Determines whether the departureAt of the order is in the past.
 * @param bookingDepartureAt Departure time in booking time format.
 */
export function isBookingDepartureInThePast(bookingDepartureAt: Date, originTimeZone: string) {
    const departureInHours = calculateDepartInFromBookingTime(bookingDepartureAt, originTimeZone);
    return departureInHours < 0;
}
