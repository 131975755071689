import { AssignationDataForPayout } from "@legacy/domain/AssignationDataForPayout";
import { SimpleUser } from "@legacy/domain/SimpleUser";
import { DriverDebt } from "@legacy/models/DriverDebt";
import { Payout } from "@legacy/models/Payout";
import autobind from "autobind-decorator";
import { action, computed, observable } from "mobx";

import { ModelOperator } from "./ModelOperator";
import { ModelOperatorOptions } from "./ModelOperatorOptions";

interface PayoutOperatorOptions extends ModelOperatorOptions<Payout, null, null> {
    driverUserId: string;

    assignationsWithDataForPayout: Array<AssignationDataForPayout>;
    driverDebts: Array<DriverDebt>;
    driverDebtsAuthorSimpleUsers: Array<SimpleUser>;

    includedAssignationIds?: Array<string>;
    includedDebtIds?: Array<string>;
}

@autobind
export class PayoutOperator extends ModelOperator<Payout, PayoutOperatorOptions, null, null, null> {
    public constructor(options: PayoutOperatorOptions) {
        super(options);

        this.driverUserId = options.driverUserId;
        this.assignationsWithDataForPayout = options.assignationsWithDataForPayout;
        this.driverDebts = options.driverDebts;
        this.driverDebtsAuthorSimpleUsers = options.driverDebtsAuthorSimpleUsers;

        if (options.includedAssignationIds) {
            this.includedAssignationIds = options.includedAssignationIds;
        }

        if (options.includedDebtIds) {
            this.includedDebtIds = options.includedDebtIds;
        }
    }

    public driverUserId: string;

    @observable
    public includedAssignationIds: Array<string> = [];

    @observable
    public includedDebtIds: Array<string> = [];

    @observable
    public assignationsWithDataForPayout: Array<AssignationDataForPayout>;

    @observable
    public driverDebts: Array<DriverDebt>;

    @observable
    public driverDebtsAuthorSimpleUsers: Array<SimpleUser>;

    @observable
    public isCreated: boolean = false;

    @action
    public toggleIncludedAssignation(assignationId: string) {
        if (this.includedAssignationIds.indexOf(assignationId) === -1) {
            this.includedAssignationIds.push(assignationId);
        } else {
            const index = this.includedAssignationIds.indexOf(assignationId);

            if (index !== -1) {
                this.includedAssignationIds.splice(index, 1);
            }
        }
    }

    @action
    public toggleIncludedDebt(debtId: string) {
        if (this.includedDebtIds.indexOf(debtId) === -1) {
            this.includedDebtIds.push(debtId);
        } else {
            const index = this.includedDebtIds.indexOf(debtId);

            if (index !== -1) {
                this.includedDebtIds.splice(index, 1);
            }
        }
    }

    @computed
    public get includedAssignationsWithDataForPayout(): Array<AssignationDataForPayout> {
        return this.assignationsWithDataForPayout.filter(
            (awd) => this.includedAssignationIds.findIndex((aid) => awd.assignationId === aid) > -1,
        );
    }

    @computed
    public get includedDebts(): Array<DriverDebt> {
        return this.driverDebts.filter((d) => this.includedDebtIds.findIndex((did) => d._id === did) > -1);
    }

    @computed
    public get penaltiesAmount(): number {
        return this.includedAssignationsWithDataForPayout.reduce((s, awd) => s + awd.sumOfPenalties, 0);
    }

    @computed
    public get compensationsAmount(): number {
        return this.includedAssignationsWithDataForPayout.reduce((s, awd) => s + awd.sumOfCompensations, 0);
    }

    @computed
    public get subsidiesAmount(): number {
        return this.includedAssignationsWithDataForPayout.reduce((s, awd) => s + awd.sumOfSubsidies, 0);
    }

    @computed
    public get debtsAmount(): number {
        return this.includedDebts.reduce((s, d) => s + d.amount, 0);
    }

    @computed
    public get assignationsAmount(): number {
        return this.includedAssignationsWithDataForPayout.reduce((s, awd) => s + awd.driversBalance, 0);
    }

    @computed
    public get totalAmount(): number {
        return this.assignationsAmount - this.debtsAmount;
    }

    @computed
    public get firstAssignationsDate(): number {
        const assignationsDates = this.assignationsWithDataForPayout.map((awd) =>
            new Date(awd.simpleOrder.departureAt).getTime(),
        );
        return Math.min(...assignationsDates);
    }

    @computed
    public get lastAssignationsDate(): number {
        const assignationsDates = this.assignationsWithDataForPayout.map((awd) =>
            new Date(awd.simpleOrder.departureAt).getTime(),
        );
        return Math.max(...assignationsDates);
    }
}
