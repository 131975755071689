import { DriverCooperationAgreementStatus } from "@daytrip/legacy-enums";
import type { Country } from "@legacy/models/Country";
import type { User } from "@legacy/models/User";
import { SaveCooperationAgreementDynamicFormInput } from "@legacy/types/SaveCooperationAgreementInputDynamicForm";
import autobind from "autobind-decorator";
import { action, computed, observable } from "mobx";
import type { Option } from "react-select";

import { DynamicFormConfigEntityTypeLegalNameDefaults } from "../../constants/DynamicFormConfigEntityTypeLegalNameDefaults";
import type { AuthenticationStore } from "../../domain/authentication/AuthenticationStore";
import { getAuthenticationStore } from "../../domain/authentication/AuthenticationStore.singleton";
import { getCooperationAgreementForCountryOrDefaultApi } from "../../domain/dynamicFormConfigs/DynamicFormsConfigApi";
import type { RpcClient } from "../../rpc-browser-sdk";
import { getRpcClient } from "../../rpc-browser-sdk";
import { DynamicFormEntityLegalNames, DynamicFormTemplates } from "../../types/DynamicForm";
import type { DynamicFormConfigFieldInput } from "../../types/generated/graphql-types";
import { DynamicFormConfigEntityType } from "../../types/generated/graphql-types";

@autobind
export class CooperationAgreementDynamicFormStore {
    private rpcClient: RpcClient;

    private authenticationStore: AuthenticationStore;

    public constructor() {
        this.rpcClient = getRpcClient();
        this.authenticationStore = getAuthenticationStore();
    }

    @observable
    public countries: Country[] = [];

    @observable
    public countryId: string | undefined;

    @observable
    public dynamicFormFields: DynamicFormConfigFieldInput[] = [];

    @observable
    public inProgress = false;

    @observable
    public entityType: DynamicFormConfigEntityType | null = null;

    @observable
    public dynamicFormTemplates: DynamicFormTemplates = {
        [DynamicFormConfigEntityType.Driver]: null,
        [DynamicFormConfigEntityType.DriverCompany]: null,
    };

    @observable
    public entityTypeLegalNames: DynamicFormEntityLegalNames = {
        [DynamicFormConfigEntityType.Driver]: DynamicFormConfigEntityTypeLegalNameDefaults.DRIVER,
        [DynamicFormConfigEntityType.DriverCompany]: DynamicFormConfigEntityTypeLegalNameDefaults.DRIVER_COMPANY,
    };

    @observable
    public declinationReason: string | undefined;

    @observable
    public cooperationAgreementStatus: DriverCooperationAgreementStatus;

    @observable
    public documentInput: SaveCooperationAgreementDynamicFormInput;

    @observable
    public isLoading = false;

    @observable
    public saveError: string | undefined;

    @computed
    public get countriesOptions(): Option[] {
        return this.countries.map((country) => ({
            label: country.englishName,
            value: country._id,
        }));
    }

    @computed
    public get countryName(): string | undefined {
        const countryData = this.countries.find((country) => country._id === this.countryId);
        return countryData?.englishName;
    }

    @action
    public async fetchData(user: User | undefined) {
        this.isLoading = true;
        const countries = await this.rpcClient.content.retrieveCountries({
            excludeIsoCodes: ["eu"],
        });
        this.countries = countries.sort((a, b) => a.englishName.localeCompare(b.englishName));

        if (user) {
            this.countryId = user?.countryId;

            await this.fetchDynamicForms();

            try {
                const { status, declinedDocument } =
                    await this.rpcClient.cooperationAgreementDynamicForm.getCooperationAgreementStatus(user._id);
                this.cooperationAgreementStatus = status;
                this.declinationReason = declinedDocument?.reason;
                this.documentInput = {
                    fields: Object.entries(declinedDocument?.documentInput || {}).map(([key, value]) => ({
                        fieldName: key,
                        value,
                    })),
                } as SaveCooperationAgreementDynamicFormInput;
            } catch (e) {
                console.log(e);
            }

            this.isLoading = false;
        }
    }

    @action
    private async fetchDynamicForms() {
        const [driverDynamicForm, companyDynamicForm] = await Promise.all([
            getCooperationAgreementForCountryOrDefaultApi(this.countryId ?? null, DynamicFormConfigEntityType.Driver),
            getCooperationAgreementForCountryOrDefaultApi(
                this.countryId ?? null,
                DynamicFormConfigEntityType.DriverCompany,
            ),
        ]);

        this.entityTypeLegalNames[DynamicFormConfigEntityType.Driver] =
            driverDynamicForm?.entityTypeLegalName ?? DynamicFormConfigEntityTypeLegalNameDefaults.DRIVER;
        this.entityTypeLegalNames[DynamicFormConfigEntityType.DriverCompany] =
            companyDynamicForm?.entityTypeLegalName ?? DynamicFormConfigEntityTypeLegalNameDefaults.DRIVER_COMPANY;

        this.dynamicFormTemplates[DynamicFormConfigEntityType.Driver] = driverDynamicForm?.fields ?? [];
        this.dynamicFormTemplates[DynamicFormConfigEntityType.DriverCompany] = companyDynamicForm?.fields ?? [];
    }

    @action
    public changeEntityType(entityType: DynamicFormConfigEntityType) {
        this.entityType = entityType;
        this.dynamicFormFields = this.dynamicFormTemplates[entityType] ?? [];
    }

    @action
    public async saveCooperationAgreement(form) {
        const input = {
            ...form,
            entityType: this.entityType,
            countryId: this.countryId,
        };
        try {
            this.inProgress = true;
            await this.rpcClient.cooperationAgreementDynamicForm.saveCooperationAgreementDynamicForm(input);
        } catch (err: any) {
            this.saveError = err.message;
        } finally {
            this.inProgress = false;
        }

        return !this.saveError;
    }

    @action dismissSaveError() {
        this.saveError = undefined;
    }
}
