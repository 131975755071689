import { Exclude, Expose, Transform, Type } from "class-transformer";
import { IsDefined, IsNumber, IsUUID } from "class-validator";

import { OrderStatus } from "./OrderStatus";

@Exclude()
export class SimpleOrderForReferralCode {
    @IsUUID("4")
    @IsDefined()
    @Expose()
    public orderId: string;

    @IsUUID("4")
    @IsDefined()
    @Expose()
    public userId: string;

    @IsNumber()
    @IsDefined()
    @Expose()
    public status: OrderStatus;

    @Type(() => Date)
    @Transform((value) => new Date(value))
    @IsDefined()
    @Expose()
    public departureAt: Date;
}
