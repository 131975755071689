import type { Location } from "@daytrip/legacy-models";
import { LocationImage } from "@daytrip/legacy-models";
import type { PoolLocation } from "@legacy/models/PoolLocation";
import autobind from "autobind-decorator";
import { classToPlain } from "class-transformer";
import type { ValidationError } from "class-validator";
import { action, computed, observable } from "mobx";

import { BASE_IMAGE_URL } from "../../config.management";
import { PageStore } from "../../stores/PageStore";

import type { LocationPageRouter } from "./LocationPageRouter";

@autobind
export class BaseLocationPageStore extends PageStore<LocationPageRouter, {}> {
    @action
    public async locationEdit() {
        throw Error("locationEdit must be implemented in child class");
    }

    @action
    public async fetchContent() {
        throw Error("fetchContent must be implemented in child class");
    }

    @observable
    public editedLocation?: Location | PoolLocation;

    @observable
    public location?: Location | PoolLocation;

    @observable
    public editedLocationValidationErrors: Array<ValidationError> = [];

    @action
    public locationUpdateImageUrl(value: string, index: number) {
        this.editedLocation!.images[index].url = value;
    }

    @computed
    public get validationMessages(): { [property: ValidationError["property"]]: string } {
        return this.editedLocationValidationErrors.reduce(
            (acc, ve) => ({ ...acc, [ve.property]: JSON.stringify(ve.constraints) }),
            {},
        );
    }

    @computed
    public get imageUrlValidationMessage(): string {
        return this.validationMessages.images;
    }

    @action
    public async changeLocationImageOrder(locationImage: LocationImage, isUp: boolean) {
        if (isUp) {
            const imageToSwitch = this.editedLocation!.images.find((image) => image.order === locationImage.order - 1);

            if (imageToSwitch) {
                imageToSwitch.order = locationImage.order;
            }

            // set higher priority (lower order)
            locationImage.order = locationImage.order > 0 ? locationImage.order - 1 : locationImage.order;
        } else {
            const imageToSwitch = this.editedLocation!.images.find((image) => image.order === locationImage.order + 1);

            if (imageToSwitch) {
                imageToSwitch.order = locationImage.order;
            }

            locationImage.order =
                locationImage.order < this.editedLocation!.images.length - 1
                    ? locationImage.order + 1
                    : locationImage.order;
        }

        this.editedLocation!.images = this.editedLocation!.images.sort((a, b) => {
            if (a.order > b.order) {
                return 1;
            }

            return -1;
        });
    }

    @action
    public async locationRemoveImage(index: number) {
        if (confirm("Do you want to delete this image permanently?")) {
            this.editedLocation!.images.splice(index, 1);

            await this.rpcClient.content.removeImage(this.editedLocation!._id, index);

            // update order for all images after removed
            if (index < this.editedLocation!.images.length) {
                for (let i = index; i < this.editedLocation!.images.length; i++) {
                    this.editedLocation!.images[i].order = i;
                }
            }
        }
    }

    @action
    public async triggerIsFirst(value: boolean, index: number) {
        // reset isFirst
        if (value) {
            this.editedLocation!.images.map((image) => {
                image.isFirst = false;
                return image;
            });
        }

        this.editedLocation!.images[index].isFirst = value;
    }

    @observable
    public purgingLocationImages: Array<string> = [];

    @action
    public async imagePurge(url: string) {
        const index = this.purgingLocationImages.push(url) - 1;
        await this.rpcClient.content.purgeImgixImage(url);
        this.purgingLocationImages.splice(index, 1);
    }

    @action
    public async locationAddImage() {
        if (!this.editedLocation) {
            await this.locationEdit();
        }

        const newLocationImage = new LocationImage();
        newLocationImage.order = this.editedLocation!.images.length;
        newLocationImage.url = `${BASE_IMAGE_URL}/`;
        this.editedLocation!.images = [...this.editedLocation!.images, classToPlain(newLocationImage) as LocationImage];
    }
}
