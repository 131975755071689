/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import { PAGINATION_DEFAULT_COUNT } from "@daytrip/legacy-config";
import { SortingPaginationOptions } from "@legacy/options/SortingPaginationOptions";
import type { ParameterlessConstructor } from "@legacy/utils";
import { Direction } from "@legacy/utils";
import autobind from "autobind-decorator";
import { plainToClass } from "class-transformer";
import { validate } from "class-validator";
import { action, computed, observable, toJS } from "mobx";

import { PageRouter } from "../PageRouter";

type TFetchData<TModel, TOriginalModel, TOptions extends SortingPaginationOptions<TOriginalModel>> = (
    options: TOptions,
) => Promise<Array<TModel>>;
type TFetchTotalCount<TOriginalModel, TOptions extends SortingPaginationOptions<TOriginalModel>> = (
    options: TOptions,
) => Promise<number>;
type TRouterKeyGenerator<TOptions extends SortingPaginationOptions<any>> = (key: keyof TOptions) => string;
type TDefaultOptionsWithPaginationData<TOriginalModel, TOptions extends SortingPaginationOptions<TOriginalModel>> =
    TOptions & { sortBy?: keyof TOriginalModel };
type TOptionsWithPaginationData<TOriginalModel, TOptions extends SortingPaginationOptions<TOriginalModel>> =
    TOptions & {
        skip: number;
        limit: number;
        sortBy?: keyof TOriginalModel | string;
        sortDirection?: Direction;
    };

type TMarkableData<TModel> = TModel & {
    isMarked: boolean;
    toggleMark: () => void;
};

@autobind
export class PaginatedDataStore<
    TOptions extends SortingPaginationOptions<TOriginalModel>,
    TModel,
    TPageRouter extends PageRouter = PageRouter,
    TOriginalModel = any,
> {
    // settings
    public isMarkable = false;

    private routerPrefix: TRouterKeyGenerator<TOptions>;

    private pageRouter: TPageRouter;

    private _fetchData: TFetchData<TModel, TOriginalModel, TOptions>;

    private _fetchTotalCount: TFetchTotalCount<TOriginalModel, TOptions>;

    private dataConstructor: ParameterlessConstructor<TModel>;

    // data
    @observable
    public data?: Array<TMarkableData<TModel>>;

    @observable
    public totalCount?: number;

    // options
    @observable
    public defaultOptions: TDefaultOptionsWithPaginationData<TOriginalModel, TOptions>;

    @observable
    public options = {} as TOptionsWithPaginationData<TOriginalModel, TOptions>;

    @observable
    public optionsValues = {} as TOptionsWithPaginationData<TOriginalModel, TOptions>;

    public constructor(
        dataConstructor: ParameterlessConstructor<TModel>,
        optionsConstructor: ParameterlessConstructor<TOptions>,
        fetchData: TFetchData<TModel, TOriginalModel, TOptions>,
        fetchTotalCount: TFetchTotalCount<TOriginalModel, TOptions>,
        pageRouter: TPageRouter,
        routerPrefix: string | TRouterKeyGenerator<TOptions> = "",
        defaultOptions: TDefaultOptionsWithPaginationData<
            TOriginalModel,
            TOptions
        > = {} as TDefaultOptionsWithPaginationData<TOriginalModel, TOptions>,
        isMarkable?: boolean,
    ) {
        this.isMarkable = isMarkable == true;

        this.dataConstructor = dataConstructor;

        this.defaultOptions = defaultOptions;
        this.pageRouter = pageRouter;
        this.routerPrefix =
            typeof routerPrefix === "string" ? (key: keyof TOptions) => routerPrefix + key.toString() : routerPrefix;
        this._fetchData = fetchData;
        this._fetchTotalCount = fetchTotalCount;

        // default pagination values
        this.defaultOptions.skip = this.defaultOptions.skip != undefined ? this.defaultOptions.skip : 1;
        this.defaultOptions.limit =
            this.defaultOptions.limit != undefined ? this.defaultOptions.limit : PAGINATION_DEFAULT_COUNT;
        this.defaultOptions.sortBy =
            this.defaultOptions.sortBy != undefined
                ? this.defaultOptions.sortBy
                : ("createdAt" as keyof TOriginalModel);
        this.defaultOptions.sortDirection =
            this.defaultOptions.sortDirection != undefined ? this.defaultOptions.sortDirection : Direction.DESC;

        this.setOptions(defaultOptions, optionsConstructor);

        if (!this.isFetched()) {
            this.fetch();
        }
    }

    @action
    public setOptions(
        defaultOptions: TDefaultOptionsWithPaginationData<
            TOriginalModel,
            TOptions
        > = {} as TDefaultOptionsWithPaginationData<TOriginalModel, TOptions>,
        optionsConstructor: ParameterlessConstructor<TOptions>,
    ): void {
        // options reactions
        const options = toJS(this.options);
        for (const key in defaultOptions) {
            const queryKey = this.routerPrefix(key as keyof SortingPaginationOptions<TOriginalModel>);

            this.options = Object.defineProperty(options, key, {
                set: async (newValue) => {
                    if (newValue instanceof Array && newValue.length === 0) {
                        newValue = undefined;
                    }

                    if (newValue === "true") {
                        newValue = true;
                    } else if (newValue === "false") {
                        newValue = false;
                    }

                    // set value
                    const newOptionsValues = plainToClass(
                        optionsConstructor,
                        Object.assign(this.optionsValues, { [key]: newValue }),
                    ) as TOptionsWithPaginationData<TOriginalModel, TOptions>;

                    // validate
                    const optionsValidationErrors = await validate(newOptionsValues, { skipMissingProperties: true });
                    if (optionsValidationErrors.length > 0) {
                        console.log("Validation error", optionsValidationErrors);
                        return;
                    }

                    this.optionsValues = newOptionsValues;

                    // set option to the url
                    const query = this.pageRouter.rawQuery;
                    if (
                        query[queryKey] !== this.optionsValues[key] &&
                        query[queryKey] !== String(this.optionsValues[key])
                    ) {
                        query[queryKey] = this.optionsValues[key];
                        this.pageRouter.replaceQuery(toJS(query));
                    }
                },
                get: () => this.optionsValues[key],
            });

            if (this.pageRouter.rawQuery[queryKey]) {
                options[key] = this.pageRouter.rawQuery[queryKey];
            } else {
                options[key] = this.defaultOptions[key];
            }
        }

        this.options = observable.object(options);
    }

    @action
    public setDisplayedCount(newCount: number): void {
        this.options.skip = 1;
        this.options.limit = newCount;

        this.fetchTotalCountPromises.push(this.fetchData());
    }

    @action
    public setStart(recordIndex: number): void {
        this.options.limit =
            this.displayedCount != undefined ? recordIndex + this.displayedCount : PAGINATION_DEFAULT_COUNT;
        this.options.skip = recordIndex;

        this.fetchTotalCountPromises.push(this.fetchData());
    }

    @action
    public setSorting(sortBy?: keyof TOriginalModel | undefined, sortDirection?: Direction): void {
        // just switch to the next posible state
        if (sortBy != undefined && sortBy == this.options.sortBy && sortDirection == undefined) {
            if (this.options.sortDirection == Direction.DESC) {
                this.options.sortDirection = Direction.ASC;
            } else {
                this.options.sortDirection = this.defaultOptions.sortDirection;
                (this.options.sortBy as keyof TOriginalModel) = this.defaultOptions
                    .sortBy as any as keyof TOriginalModel;
            }
        } else {
            (this.options.sortBy as keyof TOriginalModel) =
                sortBy != undefined
                    ? (sortBy as keyof TOriginalModel)
                    : (this.defaultOptions.sortBy as any as keyof TOriginalModel);
            this.options.sortDirection = sortDirection != undefined ? sortDirection : this.defaultOptions.sortDirection;
        }

        this.fetchDataPromises.push(this.fetchData());
    }

    @computed
    public get displayedCount(): number | undefined {
        if (this.options.limit != null) {
            return this.options.limit - (this.options.skip ?? 0);
        }

        return undefined;
    }

    // data state

    @observable
    public isFetching = false;

    @observable
    public isFetchCompleted = false;

    @observable
    public isFetchFailed = false;

    // data count state

    @observable
    public isTotalCountFetching = false;

    @observable
    public isTotalCountFetchCompleted = false;

    @observable
    public isTotalCountFetchFailed = false;

    // fetching

    @action
    public async move(count: number): Promise<void> {
        if (this.options.skip != undefined && this.options.limit != undefined) {
            // wait to resolve all previous fetching
            await Promise.all(this.fetchDataPromises);

            this.options.skip += count;
            this.options.limit += count;

            this.fetchDataPromises.push(this.fetchData());
        }
    }

    @action
    public async previous(): Promise<void> {
        if (this.displayedCount != undefined) {
            await this.move(-this.displayedCount);
        }
    }

    @action
    public async next(): Promise<void> {
        if (this.displayedCount != undefined) {
            await this.move(this.displayedCount);
        }
    }

    private fetchDataPromises: Array<Promise<void>> = [];

    @action
    public async fetchData(): Promise<void> {
        this.isFetching = true;

        const options = { ...this.optionsValues };

        try {
            const result = plainToClass(this.dataConstructor, await this._fetchData(this.optionsValues));

            if (JSON.stringify(options) === JSON.stringify(this.optionsValues)) {
                if (this.isMarkable) {
                    this.markedData = [];
                    this.data = result.map((d: TMarkableData<TModel>) => {
                        Object.defineProperty(d, "isMarked", {
                            get: () => this.markedData.includes(d),
                        });

                        d.toggleMark = () => this.toggleMark(d);

                        return d;
                    });
                } else {
                    this.data = result as Array<TMarkableData<TModel>>;
                }
            }
        } catch (e: any) {
            alert(`Error fetching data: ${e}`);
            this.isFetchFailed = true;
        }

        this.isFetching = false;
    }

    private fetchTotalCountPromises: Array<Promise<void>> = [];

    @action
    public async fetchTotalCount(): Promise<void> {
        this.isTotalCountFetching = true;

        const options = { ...this.optionsValues };

        try {
            const result = await this._fetchTotalCount(options);

            if (JSON.stringify(options) === JSON.stringify(this.optionsValues)) {
                this.totalCount = result;
            }
        } catch (e: any) {
            alert(`Error fetching total count: ${e}`);
            this.isTotalCountFetchFailed = true;
        }

        this.isTotalCountFetching = false;
    }

    @action
    public async fetch(): Promise<void> {
        // validate
        const optionsValidationErrors = await validate(this.options, { skipMissingProperties: true });
        if (optionsValidationErrors.length > 0) {
            alert(`Validation error${optionsValidationErrors.toString()}`);
            return Promise.reject(new Error("Validation error in options"));
        }

        this.fetchDataPromises.push(this.fetchTotalCount(), this.fetchData());
    }

    public isFetched(): this is this & { data: Array<TModel>; totalCount: number } {
        return this.data != null && this.totalCount != null;
    }

    // checking data

    @observable
    public markedData: Array<TModel> = [];

    @computed
    public get unmarkedData(): Array<TModel> {
        if (this.isFetched()) {
            return this.data.filter((d) => !this.markedData.includes(d));
        }

        return [];
    }

    @computed
    public get isAllMarked(): boolean {
        return this.unmarkedData.length === 0;
    }

    @action
    public toggleMarkAll(): void {
        if (this.isFetched()) {
            if (this.isAllMarked) {
                this.markedData = [];
            } else {
                this.markedData.push(...this.unmarkedData);
            }
        }
    }

    @action
    public toggleMark(instance: TMarkableData<TModel>): void {
        if (this.isFetched()) {
            if (instance.isMarked) {
                this.markedData.splice(this.markedData.indexOf(instance), 1);
            } else {
                this.markedData.push(instance);
            }
        }
    }
}
