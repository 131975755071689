import { TransformToDate } from "@daytrip/legacy-transformers";
import { Expose } from "class-transformer";
import { IsDate, IsDefined, IsString, IsUUID, ValidateIf, IsEnum, IsNumber, IsOptional } from "class-validator";
import { v4 as uuid } from "uuid";

import { Currency } from "../domain/Currency";
import { PaymentProvider } from "../domain/PaymentProvider";
import { PaymentRequestType } from "../domain/PaymentRequestType";
import { GField, GInt, GObjectType } from "../graphql";
import { CDBaseEntity, CDColumn, CDCreateDateColumn, CDEntity, CDObjectIdColumn } from "../orm";

@GObjectType("PaymentRequest")
@CDEntity("paymentRequests")
export class PaymentRequest extends CDBaseEntity {
    @GField()
    @CDObjectIdColumn()
    @IsUUID()
    @Expose()
    public _id: string = uuid();

    @GField({ nullable: true })
    @CDColumn()
    @IsUUID()
    @Expose()
    public orderId?: string;

    @GField({ nullable: true })
    @CDColumn()
    @IsUUID()
    @IsDefined()
    @Expose()
    @ValidateIf((travelVoucher) => travelVoucher.type !== PaymentRequestType.TravelVoucher)
    public userId?: string;

    @GField()
    @CDColumn()
    @IsNumber()
    @IsDefined()
    @Expose()
    public amount: number;

    @GField(() => GInt)
    @CDColumn()
    @IsEnum(Currency)
    @IsDefined()
    @Expose()
    public currency = Currency.Euro;

    @GField()
    @CDColumn()
    @IsString()
    @IsDefined()
    @Expose()
    public description: string;

    @GField()
    @CDCreateDateColumn()
    @IsDate()
    @Expose()
    @TransformToDate
    public createdAt: Date = new Date();

    @GField(() => GInt, { nullable: true })
    @CDColumn()
    @Expose()
    public type?: PaymentRequestType;

    @GField(() => PaymentProvider, { nullable: true })
    @CDColumn()
    @IsEnum(PaymentProvider)
    @IsOptional()
    @Expose()
    public paymentProvider?: PaymentProvider;

    @GField({ nullable: true })
    @CDColumn()
    @IsString()
    @IsOptional()
    @Expose()
    public pricingCountryId?: string;
}
