import { AssignationDataForDriver } from "@legacy/domain/AssignationDataForDriver";

export function sumarizeAssignationDataForDriverValues(
    key: keyof AssignationDataForDriver,
    assignationsData?: Array<AssignationDataForDriver>,
): number {
    if (assignationsData) {
        return assignationsData.reduce((prev: number, assignationData: AssignationDataForDriver) => {
            const paymentOperationValue = assignationData[key];
            if (typeof paymentOperationValue === "number") {
                return prev + paymentOperationValue;
            }
            return prev;
        }, 0);
    }
    return 0;
}
