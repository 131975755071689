export const resetTime = (date: Date | string | number): Date => {
    const newDate = new Date(date);
    newDate.setUTCHours(0, 0, 0, 0);

    return newDate;
};

export const generateFromToByRange = (date: Date | string | number, range: number): { from: Date; to: Date } => {
    const newDate = resetTime(date);

    const from = new Date(newDate.getTime());
    const to = new Date(newDate.getTime());

    to.setUTCDate(newDate.getUTCDate() + range);

    return { from, to };
};

export const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
];

export const compareDatesByUTCDate = (date1: Date, date2: Date): boolean => {
    return (
        date1.getUTCFullYear() === date2.getUTCFullYear() &&
        date1.getUTCMonth() === date2.getUTCMonth() &&
        date1.getUTCDate() === date2.getUTCDate()
    );
};

/**
Function that returns UTC ISO string without milliseconds for a date and undefined for undefined.
@param date The date
@returns UTC ISO string for the date in format, e.g. 2022-07-21T09:35:31Z or undefined
* */
export const toISOStringWithoutMs = (date: Date | undefined): string | undefined => {
    return date ? `${date.toISOString().split(".")[0]}Z` : undefined;
};
