import type { HostAgencyAndTravelAgentCount } from "@daytrip/legacy-models";
import type { RetrieveHostAgenciesAndTravelAgentCountOptions } from "@legacy/options/RetrieveHostAgenciesAndTravelAgentCountOptions";
import autobind from "autobind-decorator";
import { action, observable } from "mobx";

import { PageStore } from "../../stores/PageStore";

import type { HostAgenciesPageRouter } from "./HostAgenciesPageRouter";
@autobind
export class HostAgenciesPageStore extends PageStore<HostAgenciesPageRouter, {}> {
    @observable
    public hostAgencies?: Array<HostAgencyAndTravelAgentCount>;

    @observable
    public hostAgenciesCount?: number;

    @action
    public async onFetchData() {
        await this.fetchContent();
    }

    @action
    public async fetchContent(): Promise<void> {
        const options = {
            skip: this.pageRouter.skip - 1,
            limit: this.pageRouter.limit,
            sortBy: this.pageRouter.sortBy,
            sortDirection: this.pageRouter.sortDirection,
            search: this.pageRouter.search,
            businessConditions: this.pageRouter.businessConditions,
        } as RetrieveHostAgenciesAndTravelAgentCountOptions;

        const hostAgenciesResponse = await this.rpcClient.hostAgency.retrieveHostAgenciesAndTravelAgentCount(options);

        this.hostAgencies = hostAgenciesResponse.data;
        this.hostAgenciesCount = hostAgenciesResponse.totalCount;
    }

    public isDataFetched(): this is HostAgenciesPageStore & HostAgenciesDataFetched {
        return !!this.hostAgencies;
    }
}

export interface HostAgenciesDataFetched {
    partners: Array<HostAgencyAndTravelAgentCount>;
}
