import { AdTemplate } from "../domain/AdTemplate";
import { GoogleCampaignSettingType } from "../domain/GoogleCampaignSettingType";

export const adTemplates = {
    [GoogleCampaignSettingType.Europe]: [
        // adgroup 1
        [
            new AdTemplate(
                "Private Сar {originLocationName} to {destinationLocationName}",
                "From {=Ad customizers PRICES.Price:{price}} with a Local Driver",
                "Easy Online Booking",
                "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                "Explore the culture with exceptional sight-seeing stops on the way!",
                [
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName} by Car",
                        "From {=Ad customizers PRICES.Price:{price}} with a Local Driver",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName}",
                        "From {=Ad customizers PRICES.Price:{price}} with a Private Car",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                ],
            ),
            new AdTemplate(
                "{originLocationName} to {destinationLocationName} from {=Ad customizers PRICES.Price:{price}}",
                "Discover Europe with Locals",
                "Easy Online Booking",
                "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                "Explore the culture with exceptional sight-seeing stops on the way!",
                [
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName} from {=Ad customizers PRICES.Price:{price}}",
                        "Discover Europe with Locals",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName}",
                        "From {=Ad customizers PRICES.Price:{price}} Door-to-Door by Car",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                ],
            ),
            new AdTemplate(
                "{originLocationName} to {destinationLocationName} in {duration}",
                "Travel on Your Own Schedule",
                "Easy Online Booking",
                "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                "Explore the culture with exceptional sight-seeing stops on the way!",
                [
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName} in {duration}",
                        "Travel on Your Own Schedule",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName}",
                        "Just {duration} by Private Car",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                ],
            ),
        ],
        // adgroup 2
        [
            new AdTemplate(
                "Private Сar {originLocationName} to {destinationLocationName}",
                "From {=Ad customizers PRICES.Price:{price}} with a Local Driver",
                "Easy Online Booking",
                "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                "Explore the culture with exceptional sight-seeing stops on the way!",
                [
                    new AdTemplate(
                        "{originLocationName} to {destinationLocationName} by Car",
                        "From {=Ad customizers PRICES.Price:{price}} with a Local Driver",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName}",
                        "From {=Ad customizers PRICES.Price:{price}} with a Private Car",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                ],
            ),
            new AdTemplate(
                "{originLocationName} to {destinationLocationName} from {=Ad customizers PRICES.Price:{price}}",
                "Faster & Easier than the Bus",
                "Easy Online Booking",
                "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                "Explore the culture with exceptional sight-seeing stops on the way!",
                [
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName} from {=Ad customizers PRICES.Price:{price}}",
                        "Faster & Easier than the Bus",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName}",
                        "From {=Ad customizers PRICES.Price:{price}} & Easier than the Bus",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                ],
            ),
            new AdTemplate(
                "{originLocationName} to {destinationLocationName} in {duration}",
                "A Private Car Trumps the Bus",
                "Easy Online Booking",
                "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                "Explore the culture with exceptional sight-seeing stops on the way!",
                [
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName} in {duration}",
                        "A Private Car Trumps the Bus",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName}",
                        "{duration} by Car Trumps the Bus",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                ],
            ),
        ],
        // adgroup 3
        [
            new AdTemplate(
                "Private Сar {originLocationName} to {destinationLocationName}",
                "From {=Ad customizers PRICES.Price:{price}} with a Local Driver",
                "Easy Online Booking",
                "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                "Explore the culture with exceptional sight-seeing stops on the way!",
                [
                    new AdTemplate(
                        "{originLocationName} to {destinationLocationName} by Car",
                        "From {=Ad customizers PRICES.Price:{price}} with a Local Driver",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName}",
                        "From {=Ad customizers PRICES.Price:{price}} with a Private Car",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                ],
            ),
            new AdTemplate(
                "{originLocationName} to {destinationLocationName} from {=Ad customizers PRICES.Price:{price}}",
                "Faster & Easier than the Train",
                "Easy Online Booking",
                "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                "Explore the culture with exceptional sight-seeing stops on the way!",
                [
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName} from {=Ad customizers PRICES.Price:{price}}",
                        "Faster & Easier than the Train",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName}",
                        "From {=Ad customizers PRICES.Price:{price}} & Easier than a Train",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                ],
            ),
            new AdTemplate(
                "{originLocationName} to {destinationLocationName} in {duration}",
                "A Private Car Trumps the Train",
                "Easy Online Booking",
                "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                "Explore the culture with exceptional sight-seeing stops on the way!",
                [
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName} in {duration}",
                        "A Private Car Trumps the Train",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName}",
                        "{duration} by Car Beats the Train",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                ],
            ),
        ],
        // adgroup 4
        [
            new AdTemplate(
                "Private Сar {originLocationName} to {destinationLocationName}",
                "Safe, English-speaking Driver",
                "Easy Online Booking",
                "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                "Explore the culture with exceptional sight-seeing stops on the way!",
                [
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName} by Car",
                        "Safe, English-speaking Driver",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName}",
                        "Private Car with a Safe Driver",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                ],
            ),
            new AdTemplate(
                "{originLocationName} to {destinationLocationName} by Car",
                "From {=Ad customizers PRICES.Price:{price}} with a Local Driver",
                "Easy Online Booking",
                "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                "Explore the culture with exceptional sight-seeing stops on the way!",
                [
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName} by Car",
                        "From {=Ad customizers PRICES.Price:{price}} with a Local Driver",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName}",
                        "From {=Ad customizers PRICES.Price:{price}} in a Private Car",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                ],
            ),
            new AdTemplate(
                "{originLocationName} to {destinationLocationName} in {duration}",
                "Private Car with a Safe Driver",
                "Easy Online Booking",
                "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                "Explore the culture with exceptional sight-seeing stops on the way!",
                [
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName} in {duration}",
                        "Private Car with a Safe Driver",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName}",
                        "Just {duration} by Private Car",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                ],
            ),
        ],
        // adgroup 6
        [
            new AdTemplate(
                "{originLocationName} to {destinationLocationName} from {=Ad customizers PRICES.Price:{price}}",
                "Book a Car Instead of a Flight",
                "Easy Online Booking",
                "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                "Explore the culture with exceptional sight-seeing stops on the way!",
                [
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName} from {=Ad customizers PRICES.Price:{price}}",
                        "Book a Car instead of a Flight",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName}",
                        "Book a Car instead of a Flight",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                ],
            ),
            new AdTemplate(
                "{originLocationName} to {destinationLocationName} by Car",
                "From {=Ad customizers PRICES.Price:{price}} Door-to-Door",
                "Easy Online Booking",
                "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                "Explore the culture with exceptional sight-seeing stops on the way!",
                [
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName} by Car",
                        "From {=Ad customizers PRICES.Price:{price}} Door-to-Door",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName}",
                        "From {=Ad customizers PRICES.Price:{price}} Door-to-Door by Car",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                ],
            ),
            new AdTemplate(
                "{originLocationName} to {destinationLocationName} in {duration}",
                "Book a Car instead of a Flight",
                "Easy Online Booking",
                "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                "Explore the culture with exceptional sight-seeing stops on the way!",
                [
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName} in {duration}",
                        "Book a Car instead of a Flight",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName}",
                        "{duration} Door-to-Door by Car",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                ],
            ),
        ],
    ],
    [GoogleCampaignSettingType.SouthEastAsia]: [
        // adgroup 1
        [
            new AdTemplate(
                "Private Сar {originLocationName} to {destinationLocationName}",
                "From {=Ad customizers PRICES.Price:{price}} with a Local Driver",
                "Easy Online Booking",
                "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                "Explore the culture with exceptional sight-seeing stops on the way!",
                [
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName} by Car",
                        "From {=Ad customizers PRICES.Price:{price}} with a Local Driver",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName}",
                        "From {=Ad customizers PRICES.Price:{price}} with a Private Car",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                ],
            ),
            new AdTemplate(
                "{originLocationName} to {destinationLocationName} from {=Ad customizers PRICES.Price:{price}}",
                "Discover Asia with Locals",
                "Easy Online Booking",
                "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                "Explore the culture with exceptional sight-seeing stops on the way!",
                [
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName} from {=Ad customizers PRICES.Price:{price}}",
                        "Discover Asia with Locals",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName}",
                        "From {=Ad customizers PRICES.Price:{price}} Door-to-Door by Car",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                ],
            ),
            new AdTemplate(
                "{originLocationName} to {destinationLocationName} in {duration}",
                "Travel on Your Own Schedule",
                "Easy Online Booking",
                "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                "Explore the culture with exceptional sight-seeing stops on the way!",
                [
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName} in {duration}",
                        "Travel on Your Own Schedule",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName}",
                        "Just {duration} by Private Car",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                ],
            ),
        ],
        // adgroup 2
        [
            new AdTemplate(
                "Private Сar {originLocationName} to {destinationLocationName}",
                "From {=Ad customizers PRICES.Price:{price}} with a Local Driver",
                "Easy Online Booking",
                "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                "Explore the culture with exceptional sight-seeing stops on the way!",
                [
                    new AdTemplate(
                        "{originLocationName} to {destinationLocationName} by Car",
                        "From {=Ad customizers PRICES.Price:{price}} with a Local Driver",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName}",
                        "From {=Ad customizers PRICES.Price:{price}} with a Private Car",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                ],
            ),
            new AdTemplate(
                "{originLocationName} to {destinationLocationName} from {=Ad customizers PRICES.Price:{price}}",
                "Faster & Easier than the Bus",
                "Easy Online Booking",
                "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                "Explore the culture with exceptional sight-seeing stops on the way!",
                [
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName} from {=Ad customizers PRICES.Price:{price}}",
                        "Faster & Easier than the Bus",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName}",
                        "From {=Ad customizers PRICES.Price:{price}} & Easier than the Bus",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                ],
            ),
            new AdTemplate(
                "{originLocationName} to {destinationLocationName} in {duration}",
                "A Private Car Trumps the Bus",
                "Easy Online Booking",
                "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                "Explore the culture with exceptional sight-seeing stops on the way!",
                [
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName} in {duration}",
                        "A Private Car Trumps the Bus",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName}",
                        "{duration} by Car Trumps the Bus",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                ],
            ),
        ],
        // adgroup 3
        [
            new AdTemplate(
                "Private Сar {originLocationName} to {destinationLocationName}",
                "From {=Ad customizers PRICES.Price:{price}} with a Local Driver",
                "Easy Online Booking",
                "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                "Explore the culture with exceptional sight-seeing stops on the way!",
                [
                    new AdTemplate(
                        "{originLocationName} to {destinationLocationName} by Car",
                        "From {=Ad customizers PRICES.Price:{price}} with a Local Driver",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName}",
                        "From {=Ad customizers PRICES.Price:{price}} with a Private Car",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                ],
            ),
            new AdTemplate(
                "{originLocationName} to {destinationLocationName} from {=Ad customizers PRICES.Price:{price}}",
                "Faster & Easier than the Train",
                "Easy Online Booking",
                "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                "Explore the culture with exceptional sight-seeing stops on the way!",
                [
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName} from {=Ad customizers PRICES.Price:{price}}",
                        "Faster & Easier than the Train",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName}",
                        "From {=Ad customizers PRICES.Price:{price}} & Easier than a Train",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                ],
            ),
            new AdTemplate(
                "{originLocationName} to {destinationLocationName} in {duration}",
                "A Private Car Trumps the Train",
                "Easy Online Booking",
                "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                "Explore the culture with exceptional sight-seeing stops on the way!",
                [
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName} in {duration}",
                        "A Private Car Trumps the Train",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName}",
                        "{duration} by Car Beats the Train",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                ],
            ),
        ],
        // adgroup 4
        [
            new AdTemplate(
                "Private Сar {originLocationName} to {destinationLocationName}",
                "Safe, English-speaking Driver",
                "Easy Online Booking",
                "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                "Explore the culture with exceptional sight-seeing stops on the way!",
                [
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName} by Car",
                        "Safe, English-speaking Driver",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName}",
                        "Private Car with a Safe Driver",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                ],
            ),
            new AdTemplate(
                "{originLocationName} to {destinationLocationName} by Car",
                "From {=Ad customizers PRICES.Price:{price}} with a Local Driver",
                "Easy Online Booking",
                "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                "Explore the culture with exceptional sight-seeing stops on the way!",
                [
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName} by Car",
                        "From {=Ad customizers PRICES.Price:{price}} with a Local Driver",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName}",
                        "From {=Ad customizers PRICES.Price:{price}} in a Private Car",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                ],
            ),
            new AdTemplate(
                "{originLocationName} to {destinationLocationName} in {duration}",
                "Private Car with a Safe Driver",
                "Easy Online Booking",
                "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                "Explore the culture with exceptional sight-seeing stops on the way!",
                [
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName} in {duration}",
                        "Private Car with a Safe Driver",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName}",
                        "Just {duration} by Private Car",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                ],
            ),
        ],
        // adgroup 6
        [
            new AdTemplate(
                "{originLocationName} to {destinationLocationName} from {=Ad customizers PRICES.Price:{price}}",
                "Book a Car Instead of a Flight",
                "Easy Online Booking",
                "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                "Explore the culture with exceptional sight-seeing stops on the way!",
                [
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName} from {=Ad customizers PRICES.Price:{price}}",
                        "Book a Car instead of a Flight",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName}",
                        "Book a Car instead of a Flight",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                ],
            ),
            new AdTemplate(
                "{originLocationName} to {destinationLocationName} by Car",
                "From {=Ad customizers PRICES.Price:{price}} Door-to-Door",
                "Easy Online Booking",
                "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                "Explore the culture with exceptional sight-seeing stops on the way!",
                [
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName} by Car",
                        "From {=Ad customizers PRICES.Price:{price}} Door-to-Door",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName}",
                        "From {=Ad customizers PRICES.Price:{price}} Door-to-Door by Car",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                ],
            ),
            new AdTemplate(
                "{originLocationName} to {destinationLocationName} in {duration}",
                "Book a Car instead of a Flight",
                "Easy Online Booking",
                "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                "Explore the culture with exceptional sight-seeing stops on the way!",
                [
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName} in {duration}",
                        "Book a Car instead of a Flight",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName}",
                        "{duration} Door-to-Door by Car",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                ],
            ),
        ],
    ],
    [GoogleCampaignSettingType.Africa]: [
        // adgroup 1
        [
            new AdTemplate(
                "Private Сar {originLocationName} to {destinationLocationName}",
                "From {=Ad customizers PRICES.Price:{price}} with a Local Driver",
                "Easy Online Booking",
                "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                "Explore the culture with exceptional sight-seeing stops on the way!",
                [
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName} by Car",
                        "From {=Ad customizers PRICES.Price:{price}} with a Local Driver",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName}",
                        "From {=Ad customizers PRICES.Price:{price}} with a Private Car",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                ],
            ),
            new AdTemplate(
                "{originLocationName} to {destinationLocationName} from {=Ad customizers PRICES.Price:{price}}",
                "Discover Africa with Locals",
                "Easy Online Booking",
                "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                "Explore the culture with exceptional sight-seeing stops on the way!",
                [
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName} from {=Ad customizers PRICES.Price:{price}}",
                        "Discover Africa with Locals",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName}",
                        "From {=Ad customizers PRICES.Price:{price}} Door-to-Door by Car",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                ],
            ),
            new AdTemplate(
                "{originLocationName} to {destinationLocationName} in {duration}",
                "Travel on Your Own Schedule",
                "Easy Online Booking",
                "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                "Explore the culture with exceptional sight-seeing stops on the way!",
                [
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName} in {duration}",
                        "Travel on Your Own Schedule",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName}",
                        "Just {duration} by Private Car",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                ],
            ),
        ],
        // adgroup 2
        [
            new AdTemplate(
                "Private Сar {originLocationName} to {destinationLocationName}",
                "From {=Ad customizers PRICES.Price:{price}} with a Local Driver",
                "Easy Online Booking",
                "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                "Explore the culture with exceptional sight-seeing stops on the way!",
                [
                    new AdTemplate(
                        "{originLocationName} to {destinationLocationName} by Car",
                        "From {=Ad customizers PRICES.Price:{price}} with a Local Driver",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName}",
                        "From {=Ad customizers PRICES.Price:{price}} with a Private Car",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                ],
            ),
            new AdTemplate(
                "{originLocationName} to {destinationLocationName} from {=Ad customizers PRICES.Price:{price}}",
                "Faster & Easier than the Bus",
                "Easy Online Booking",
                "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                "Explore the culture with exceptional sight-seeing stops on the way!",
                [
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName} from {=Ad customizers PRICES.Price:{price}}",
                        "Faster & Easier than the Bus",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName}",
                        "From {=Ad customizers PRICES.Price:{price}} & Easier than the Bus",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                ],
            ),
            new AdTemplate(
                "{originLocationName} to {destinationLocationName} in {duration}",
                "A Private Car Trumps the Bus",
                "Easy Online Booking",
                "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                "Explore the culture with exceptional sight-seeing stops on the way!",
                [
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName} in {duration}",
                        "A Private Car Trumps the Bus",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName}",
                        "{duration} by Car Trumps the Bus",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                ],
            ),
        ],
        // adgroup 3
        [
            new AdTemplate(
                "Private Сar {originLocationName} to {destinationLocationName}",
                "From {=Ad customizers PRICES.Price:{price}} with a Local Driver",
                "Easy Online Booking",
                "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                "Explore the culture with exceptional sight-seeing stops on the way!",
                [
                    new AdTemplate(
                        "{originLocationName} to {destinationLocationName} by Car",
                        "From {=Ad customizers PRICES.Price:{price}} with a Local Driver",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName}",
                        "From {=Ad customizers PRICES.Price:{price}} with a Private Car",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                ],
            ),
            new AdTemplate(
                "{originLocationName} to {destinationLocationName} from {=Ad customizers PRICES.Price:{price}}",
                "Faster & Easier than the Train",
                "Easy Online Booking",
                "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                "Explore the culture with exceptional sight-seeing stops on the way!",
                [
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName} from {=Ad customizers PRICES.Price:{price}}",
                        "Faster & Easier than the Train",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName}",
                        "From {=Ad customizers PRICES.Price:{price}} & Easier than a Train",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                ],
            ),
            new AdTemplate(
                "{originLocationName} to {destinationLocationName} in {duration}",
                "A Private Car Trumps the Train",
                "Easy Online Booking",
                "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                "Explore the culture with exceptional sight-seeing stops on the way!",
                [
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName} in {duration}",
                        "A Private Car Trumps the Train",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName}",
                        "{duration} by Car Beats the Train",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                ],
            ),
        ],
        // adgroup 4
        [
            new AdTemplate(
                "Private Сar {originLocationName} to {destinationLocationName}",
                "Safe, English-speaking Driver",
                "Easy Online Booking",
                "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                "Explore the culture with exceptional sight-seeing stops on the way!",
                [
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName} by Car",
                        "Safe, English-speaking Driver",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName}",
                        "Private Car with a Safe Driver",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                ],
            ),
            new AdTemplate(
                "{originLocationName} to {destinationLocationName} by Car",
                "From {=Ad customizers PRICES.Price:{price}} with a Local Driver",
                "Easy Online Booking",
                "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                "Explore the culture with exceptional sight-seeing stops on the way!",
                [
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName} by Car",
                        "From {=Ad customizers PRICES.Price:{price}} with a Local Driver",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName}",
                        "From {=Ad customizers PRICES.Price:{price}} in a Private Car",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                ],
            ),
            new AdTemplate(
                "{originLocationName} to {destinationLocationName} in {duration}",
                "Private Car with a Safe Driver",
                "Easy Online Booking",
                "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                "Explore the culture with exceptional sight-seeing stops on the way!",
                [
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName} in {duration}",
                        "Private Car with a Safe Driver",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName}",
                        "Just {duration} by Private Car",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                ],
            ),
        ],
        // adgroup 6
        [
            new AdTemplate(
                "{originLocationName} to {destinationLocationName} from {=Ad customizers PRICES.Price:{price}}",
                "Book a Car Instead of a Flight",
                "Easy Online Booking",
                "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                "Explore the culture with exceptional sight-seeing stops on the way!",
                [
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName} from {=Ad customizers PRICES.Price:{price}}",
                        "Book a Car instead of a Flight",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName}",
                        "Book a Car instead of a Flight",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                ],
            ),
            new AdTemplate(
                "{originLocationName} to {destinationLocationName} by Car",
                "From {=Ad customizers PRICES.Price:{price}} Door-to-Door",
                "Easy Online Booking",
                "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                "Explore the culture with exceptional sight-seeing stops on the way!",
                [
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName} by Car",
                        "From {=Ad customizers PRICES.Price:{price}} Door-to-Door",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName}",
                        "From {=Ad customizers PRICES.Price:{price}} Door-to-Door by Car",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                ],
            ),
            new AdTemplate(
                "{originLocationName} to {destinationLocationName} in {duration}",
                "Book a Car instead of a Flight",
                "Easy Online Booking",
                "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                "Explore the culture with exceptional sight-seeing stops on the way!",
                [
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName} in {duration}",
                        "Book a Car instead of a Flight",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName}",
                        "{duration} Door-to-Door by Car",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                ],
            ),
        ],
    ],
    [GoogleCampaignSettingType.MiddleEast]: [
        // adgroup 1
        [
            new AdTemplate(
                "Private Сar {originLocationName} to {destinationLocationName}",
                "From {=Ad customizers PRICES.Price:{price}} with a Local Driver",
                "Easy Online Booking",
                "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                "Explore the culture with exceptional sight-seeing stops on the way!",
                [
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName} by Car",
                        "From {=Ad customizers PRICES.Price:{price}} with a Local Driver",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName}",
                        "From {=Ad customizers PRICES.Price:{price}} with a Private Car",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                ],
            ),
            new AdTemplate(
                "{originLocationName} to {destinationLocationName} from {=Ad customizers PRICES.Price:{price}}",
                "Travel the Middle East Safely",
                "Easy Online Booking",
                "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                "Explore the culture with exceptional sight-seeing stops on the way!",
                [
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName} from {=Ad customizers PRICES.Price:{price}}",
                        "Travel the Middle East Safely",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName}",
                        "From {=Ad customizers PRICES.Price:{price}} Door-to-Door by Car",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                ],
            ),
            new AdTemplate(
                "{originLocationName} to {destinationLocationName} in {duration}",
                "Travel on Your Own Schedule",
                "Easy Online Booking",
                "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                "Explore the culture with exceptional sight-seeing stops on the way!",
                [
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName} in {duration}",
                        "Travel on Your Own Schedule",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName}",
                        "Just {duration} by Private Car",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                ],
            ),
        ],
        // adgroup 2
        [
            new AdTemplate(
                "Private Сar {originLocationName} to {destinationLocationName}",
                "From {=Ad customizers PRICES.Price:{price}} with a Local Driver",
                "Easy Online Booking",
                "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                "Explore the culture with exceptional sight-seeing stops on the way!",
                [
                    new AdTemplate(
                        "{originLocationName} to {destinationLocationName} by Car",
                        "From {=Ad customizers PRICES.Price:{price}} with a Local Driver",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName}",
                        "From {=Ad customizers PRICES.Price:{price}} with a Private Car",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                ],
            ),
            new AdTemplate(
                "{originLocationName} to {destinationLocationName} from {=Ad customizers PRICES.Price:{price}}",
                "Faster & Easier than the Bus",
                "Easy Online Booking",
                "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                "Explore the culture with exceptional sight-seeing stops on the way!",
                [
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName} from {=Ad customizers PRICES.Price:{price}}",
                        "Faster & Easier than the Bus",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName}",
                        "From {=Ad customizers PRICES.Price:{price}} & Easier than the Bus",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                ],
            ),
            new AdTemplate(
                "{originLocationName} to {destinationLocationName} in {duration}",
                "A Private Car Trumps the Bus",
                "Easy Online Booking",
                "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                "Explore the culture with exceptional sight-seeing stops on the way!",
                [
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName} in {duration}",
                        "A Private Car Trumps the Bus",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName}",
                        "{duration} by Car Trumps the Bus",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                ],
            ),
        ],
        // adgroup 3
        [
            new AdTemplate(
                "Private Сar {originLocationName} to {destinationLocationName}",
                "From {=Ad customizers PRICES.Price:{price}} with a Local Driver",
                "Easy Online Booking",
                "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                "Explore the culture with exceptional sight-seeing stops on the way!",
                [
                    new AdTemplate(
                        "{originLocationName} to {destinationLocationName} by Car",
                        "From {=Ad customizers PRICES.Price:{price}} with a Local Driver",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName}",
                        "From {=Ad customizers PRICES.Price:{price}} with a Private Car",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                ],
            ),
            new AdTemplate(
                "{originLocationName} to {destinationLocationName} from {=Ad customizers PRICES.Price:{price}}",
                "Faster & Easier than the Train",
                "Easy Online Booking",
                "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                "Explore the culture with exceptional sight-seeing stops on the way!",
                [
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName} from {=Ad customizers PRICES.Price:{price}}",
                        "Faster & Easier than the Train",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName}",
                        "From {=Ad customizers PRICES.Price:{price}} & Easier than a Train",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                ],
            ),
            new AdTemplate(
                "{originLocationName} to {destinationLocationName} in {duration}",
                "A Private Car Trumps the Train",
                "Easy Online Booking",
                "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                "Explore the culture with exceptional sight-seeing stops on the way!",
                [
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName} in {duration}",
                        "A Private Car Trumps the Train",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName}",
                        "{duration} by Car Beats the Train",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                ],
            ),
        ],
        // adgroup 4
        [
            new AdTemplate(
                "Private Сar {originLocationName} to {destinationLocationName}",
                "Safe, English-speaking Driver",
                "Easy Online Booking",
                "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                "Explore the culture with exceptional sight-seeing stops on the way!",
                [
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName} by Car",
                        "Safe, English-speaking Driver",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName}",
                        "Private Car with a Safe Driver",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                ],
            ),
            new AdTemplate(
                "{originLocationName} to {destinationLocationName} by Car",
                "From {=Ad customizers PRICES.Price:{price}} with a Local Driver",
                "Easy Online Booking",
                "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                "Explore the culture with exceptional sight-seeing stops on the way!",
                [
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName} by Car",
                        "From {=Ad customizers PRICES.Price:{price}} with a Local Driver",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName}",
                        "From {=Ad customizers PRICES.Price:{price}} in a Private Car",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                ],
            ),
            new AdTemplate(
                "{originLocationName} to {destinationLocationName} in {duration}",
                "Private Car with a Safe Driver",
                "Easy Online Booking",
                "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                "Explore the culture with exceptional sight-seeing stops on the way!",
                [
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName} in {duration}",
                        "Private Car with a Safe Driver",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName}",
                        "Just {duration} by Private Car",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                ],
            ),
        ],
        // adgroup 6
        [
            new AdTemplate(
                "{originLocationName} to {destinationLocationName} from {=Ad customizers PRICES.Price:{price}}",
                "Book a Car Instead of a Flight",
                "Easy Online Booking",
                "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                "Explore the culture with exceptional sight-seeing stops on the way!",
                [
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName} from {=Ad customizers PRICES.Price:{price}}",
                        "Book a Car instead of a Flight",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName}",
                        "Book a Car instead of a Flight",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                ],
            ),
            new AdTemplate(
                "{originLocationName} to {destinationLocationName} by Car",
                "From {=Ad customizers PRICES.Price:{price}} Door-to-Door",
                "Easy Online Booking",
                "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                "Explore the culture with exceptional sight-seeing stops on the way!",
                [
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName} by Car",
                        "From {=Ad customizers PRICES.Price:{price}} Door-to-Door",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName}",
                        "From {=Ad customizers PRICES.Price:{price}} Door-to-Door by Car",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                ],
            ),
            new AdTemplate(
                "{originLocationName} to {destinationLocationName} in {duration}",
                "Book a Car instead of a Flight",
                "Easy Online Booking",
                "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                "Explore the culture with exceptional sight-seeing stops on the way!",
                [
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName} in {duration}",
                        "Book a Car instead of a Flight",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName}",
                        "{duration} Door-to-Door by Car",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                ],
            ),
        ],
    ],
    [GoogleCampaignSettingType.LatinAmerica]: [
        // adgroup 1
        [
            new AdTemplate(
                "Private Сar {originLocationName} to {destinationLocationName}",
                "From {=Ad customizers PRICES.Price:{price}} with a Local Driver",
                "Easy Online Booking",
                "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                "Explore the culture with exceptional sight-seeing stops on the way!",
                [
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName} by Car",
                        "From {=Ad customizers PRICES.Price:{price}} with a Local Driver",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName}",
                        "From {=Ad customizers PRICES.Price:{price}} with a Private Car",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                ],
            ),
            new AdTemplate(
                "{originLocationName} to {destinationLocationName} from {=Ad customizers PRICES.Price:{price}}",
                "Discover Latin America Safely",
                "Easy Online Booking",
                "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                "Explore the culture with exceptional sight-seeing stops on the way!",
                [
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName} from {=Ad customizers PRICES.Price:{price}}",
                        "Discover Latin America Safely",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName}",
                        "From {=Ad customizers PRICES.Price:{price}} Door-to-Door by Car",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                ],
            ),
            new AdTemplate(
                "{originLocationName} to {destinationLocationName} in {duration}",
                "Travel on Your Own Schedule",
                "Easy Online Booking",
                "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                "Explore the culture with exceptional sight-seeing stops on the way!",
                [
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName} in {duration}",
                        "Travel on Your Own Schedule",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName}",
                        "Just {duration} by Private Car",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                ],
            ),
        ],
        // adgroup 2
        [
            new AdTemplate(
                "Private Сar {originLocationName} to {destinationLocationName}",
                "From {=Ad customizers PRICES.Price:{price}} with a Local Driver",
                "Easy Online Booking",
                "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                "Explore the culture with exceptional sight-seeing stops on the way!",
                [
                    new AdTemplate(
                        "{originLocationName} to {destinationLocationName} by Car",
                        "From {=Ad customizers PRICES.Price:{price}} with a Local Driver",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName}",
                        "From {=Ad customizers PRICES.Price:{price}} with a Private Car",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                ],
            ),
            new AdTemplate(
                "{originLocationName} to {destinationLocationName} from {=Ad customizers PRICES.Price:{price}}",
                "Faster & Easier than the Bus",
                "Easy Online Booking",
                "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                "Explore the culture with exceptional sight-seeing stops on the way!",
                [
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName} from {=Ad customizers PRICES.Price:{price}}",
                        "Faster & Easier than the Bus",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName}",
                        "From {=Ad customizers PRICES.Price:{price}} & Easier than the Bus",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                ],
            ),
            new AdTemplate(
                "{originLocationName} to {destinationLocationName} in {duration}",
                "A Private Car Trumps the Bus",
                "Easy Online Booking",
                "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                "Explore the culture with exceptional sight-seeing stops on the way!",
                [
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName} in {duration}",
                        "A Private Car Trumps the Bus",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName}",
                        "{duration} by Car Trumps the Bus",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                ],
            ),
        ],
        // adgroup 3
        [
            new AdTemplate(
                "Private Сar {originLocationName} to {destinationLocationName}",
                "From {=Ad customizers PRICES.Price:{price}} with a Local Driver",
                "Easy Online Booking",
                "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                "Explore the culture with exceptional sight-seeing stops on the way!",
                [
                    new AdTemplate(
                        "{originLocationName} to {destinationLocationName} by Car",
                        "From {=Ad customizers PRICES.Price:{price}} with a Local Driver",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName}",
                        "From {=Ad customizers PRICES.Price:{price}} with a Private Car",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                ],
            ),
            new AdTemplate(
                "{originLocationName} to {destinationLocationName} from {=Ad customizers PRICES.Price:{price}}",
                "Faster & Easier than the Train",
                "Easy Online Booking",
                "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                "Explore the culture with exceptional sight-seeing stops on the way!",
                [
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName} from {=Ad customizers PRICES.Price:{price}}",
                        "Faster & Easier than the Train",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName}",
                        "From {=Ad customizers PRICES.Price:{price}} & Easier than a Train",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                ],
            ),
            new AdTemplate(
                "{originLocationName} to {destinationLocationName} in {duration}",
                "A Private Car Trumps the Train",
                "Easy Online Booking",
                "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                "Explore the culture with exceptional sight-seeing stops on the way!",
                [
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName} in {duration}",
                        "A Private Car Trumps the Train",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName}",
                        "{duration} by Car Beats the Train",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                ],
            ),
        ],
        // adgroup 4
        [
            new AdTemplate(
                "Private Сar {originLocationName} to {destinationLocationName}",
                "Safe, English-speaking Driver",
                "Easy Online Booking",
                "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                "Explore the culture with exceptional sight-seeing stops on the way!",
                [
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName} by Car",
                        "Safe, English-speaking Driver",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName}",
                        "Private Car with a Safe Driver",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                ],
            ),
            new AdTemplate(
                "{originLocationName} to {destinationLocationName} by Car",
                "From {=Ad customizers PRICES.Price:{price}} with a Local Driver",
                "Easy Online Booking",
                "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                "Explore the culture with exceptional sight-seeing stops on the way!",
                [
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName} by Car",
                        "From {=Ad customizers PRICES.Price:{price}} with a Local Driver",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName}",
                        "From {=Ad customizers PRICES.Price:{price}} in a Private Car",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                ],
            ),
            new AdTemplate(
                "{originLocationName} to {destinationLocationName} in {duration}",
                "Private Car with a Safe Driver",
                "Easy Online Booking",
                "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                "Explore the culture with exceptional sight-seeing stops on the way!",
                [
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName} in {duration}",
                        "Private Car with a Safe Driver",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName}",
                        "Just {duration} by Private Car",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                ],
            ),
        ],
        // adgroup 6
        [
            new AdTemplate(
                "{originLocationName} to {destinationLocationName} from {=Ad customizers PRICES.Price:{price}}",
                "Book a Car Instead of a Flight",
                "Easy Online Booking",
                "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                "Explore the culture with exceptional sight-seeing stops on the way!",
                [
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName} from {=Ad customizers PRICES.Price:{price}}",
                        "Book a Car instead of a Flight",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName}",
                        "Book a Car instead of a Flight",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                ],
            ),
            new AdTemplate(
                "{originLocationName} to {destinationLocationName} by Car",
                "From {=Ad customizers PRICES.Price:{price}} Door-to-Door",
                "Easy Online Booking",
                "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                "Explore the culture with exceptional sight-seeing stops on the way!",
                [
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName} by Car",
                        "From {=Ad customizers PRICES.Price:{price}} Door-to-Door",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName}",
                        "From {=Ad customizers PRICES.Price:{price}} Door-to-Door by Car",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                ],
            ),
            new AdTemplate(
                "{originLocationName} to {destinationLocationName} in {duration}",
                "Book a Car instead of a Flight",
                "Easy Online Booking",
                "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                "Explore the culture with exceptional sight-seeing stops on the way!",
                [
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName} in {duration}",
                        "Book a Car instead of a Flight",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName}",
                        "{duration} Door-to-Door by Car",
                        "Easy Online Booking",
                        "Safe, Private door-to-door service with a local driver. A flexible way to see more.",
                        "Explore the culture with exceptional sight-seeing stops on the way!",
                    ),
                ],
            ),
        ],
    ],
    [GoogleCampaignSettingType.USA]: [
        // adgroup 1
        [
            new AdTemplate(
                "Private Сar {originLocationName} to {destinationLocationName}",
                "Safe, Sanitized Vehicles",
                "Free Cancellations",
                "Comfortable door-to-door service with an experienced driver. Cars cleaned for every trip.",
                "The most convenient way to travel between cities. Enjoy sight-seeing stops on the way!",
                [
                    new AdTemplate(
                        "{originLocationName} to {destinationLocationName} by Car",
                        "Safe, Sanitized Vehicles",
                        "Free Cancellations",
                        "Comfortable door-to-door service with an experienced driver. Cars cleaned for every trip.",
                        "The most convenient way to travel between cities. Enjoy sight-seeing stops on the way!",
                    ),
                    new AdTemplate(
                        "{originLocationName} to {destinationLocationName}",
                        "Safe, Sanitized Private Cars",
                        "Free Cancellations",
                        "Comfortable door-to-door service with an experienced driver. Cars cleaned for every trip.",
                        "The most convenient way to travel between cities. Enjoy sight-seeing stops on the way!",
                    ),
                ],
            ),
            new AdTemplate(
                "{originLocationName} to {destinationLocationName} from {=Ad customizers PRICES.Price:{price}}",
                "Ride in Safety & Comfort",
                "Free Cancellations",
                "Comfortable door-to-door service with an experienced driver. Cars cleaned for every trip.",
                "The most convenient way to travel between cities. Enjoy sight-seeing stops on the way!",
                [
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName} from {=Ad customizers PRICES.Price:{price}}",
                        "Ride in Safety & Comfort",
                        "Free Cancellations",
                        "Comfortable door-to-door service with an experienced driver. Cars cleaned for every trip.",
                        "The most convenient way to travel between cities. Enjoy sight-seeing stops on the way!",
                    ),
                    new AdTemplate(
                        "{originLocationName} to {destinationLocationName}",
                        "From {=Ad customizers PRICES.Price:{price}} Door-to-Door by Car",
                        "Free Cancellations",
                        "Comfortable door-to-door service with an experienced driver. Cars cleaned for every trip.",
                        "The most convenient way to travel between cities. Enjoy sight-seeing stops on the way!",
                    ),
                ],
            ),
            new AdTemplate(
                "{originLocationName} to {destinationLocationName} in {duration}",
                "Ride in Safety & Comfort",
                "Free Cancellations",
                "Comfortable door-to-door service with an experienced driver. Cars cleaned for every trip.",
                "The most convenient way to travel between cities. Enjoy sight-seeing stops on the way!",
                [
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName} in {duration}",
                        "Ride in Safety & Comfort",
                        "Free Cancellations",
                        "Comfortable door-to-door service with an experienced driver. Cars cleaned for every trip.",
                        "The most convenient way to travel between cities. Enjoy sight-seeing stops on the way!",
                    ),
                    new AdTemplate(
                        "{originLocationName} to {destinationLocationName}",
                        "{duration} by Comfy Private Car",
                        "Free Cancellations",
                        "Comfortable door-to-door service with an experienced driver. Cars cleaned for every trip.",
                        "The most convenient way to travel between cities. Enjoy sight-seeing stops on the way!",
                    ),
                ],
            ),
        ],
        // adgroup 2
        [
            new AdTemplate(
                "Private Сar {originLocationName} to {destinationLocationName}",
                "A Comfy Car Ride Beats the Bus",
                "Free Cancellations",
                "Comfortable door-to-door service with an experienced driver. Cars cleaned for every trip.",
                "The most convenient way to travel between cities. Enjoy sight-seeing stops on the way!",
                [
                    new AdTemplate(
                        "{originLocationName} to {destinationLocationName} by Car",
                        "A Comfy Car Ride Beats the Bus",
                        "Free Cancellations",
                        "Comfortable door-to-door service with an experienced driver. Cars cleaned for every trip.",
                        "The most convenient way to travel between cities. Enjoy sight-seeing stops on the way!",
                    ),
                    new AdTemplate(
                        "{originLocationName} to {destinationLocationName}",
                        "A Comfy Car Ride Beats the Bus",
                        "Free Cancellations",
                        "Comfortable door-to-door service with an experienced driver. Cars cleaned for every trip.",
                        "The most convenient way to travel between cities. Enjoy sight-seeing stops on the way!",
                    ),
                ],
            ),
            new AdTemplate(
                "{originLocationName} to {destinationLocationName} from {=Ad customizers PRICES.Price:{price}}",
                "Faster & Easier than the Bus",
                "Free Cancellations",
                "Comfortable door-to-door service with an experienced driver. Cars cleaned for every trip.",
                "The most convenient way to travel between cities. Enjoy sight-seeing stops on the way!",
                [
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName} from {=Ad customizers PRICES.Price:{price}}",
                        "Faster & Easier than the Bus",
                        "Free Cancellations",
                        "Comfortable door-to-door service with an experienced driver. Cars cleaned for every trip.",
                        "The most convenient way to travel between cities. Enjoy sight-seeing stops on the way!",
                    ),
                    new AdTemplate(
                        "{originLocationName} to {destinationLocationName}",
                        "From {=Ad customizers PRICES.Price:{price}} & Easier than the Bus",
                        "Free Cancellations",
                        "Comfortable door-to-door service with an experienced driver. Cars cleaned for every trip.",
                        "The most convenient way to travel between cities. Enjoy sight-seeing stops on the way!",
                    ),
                ],
            ),
            new AdTemplate(
                "{originLocationName} to {destinationLocationName} in {duration}",
                "A Private Car Beats the Bus",
                "Free Cancellations",
                "Comfortable door-to-door service with an experienced driver. Cars cleaned for every trip.",
                "The most convenient way to travel between cities. Enjoy sight-seeing stops on the way!",
                [
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName} in {duration}",
                        "A Private Car Beats the Bus",
                        "Free Cancellations",
                        "Comfortable door-to-door service with an experienced driver. Cars cleaned for every trip.",
                        "The most convenient way to travel between cities. Enjoy sight-seeing stops on the way!",
                    ),
                    new AdTemplate(
                        "{originLocationName} to {destinationLocationName}",
                        "A {duration} Ride Beats the Bus",
                        "Free Cancellations",
                        "Comfortable door-to-door service with an experienced driver. Cars cleaned for every trip.",
                        "The most convenient way to travel between cities. Enjoy sight-seeing stops on the way!",
                    ),
                ],
            ),
        ],
        // adgroup 3
        [
            new AdTemplate(
                "Private Сar {originLocationName} to {destinationLocationName}",
                "Faster & Easier than the Train",
                "Free Cancellations",
                "Comfortable door-to-door service with an experienced driver. Cars cleaned for every trip.",
                "The most convenient way to travel between cities. Enjoy sight-seeing stops on the way!",
                [
                    new AdTemplate(
                        "{originLocationName} to {destinationLocationName} by Car",
                        "Faster & Easier than the Train",
                        "Free Cancellations",
                        "Comfortable door-to-door service with an experienced driver. Cars cleaned for every trip.",
                        "The most convenient way to travel between cities. Enjoy sight-seeing stops on the way!",
                    ),
                    new AdTemplate(
                        "{originLocationName} to {destinationLocationName}",
                        "A Comfy Ride Beats the Train",
                        "Free Cancellations",
                        "Comfortable door-to-door service with an experienced driver. Cars cleaned for every trip.",
                        "The most convenient way to travel between cities. Enjoy sight-seeing stops on the way!",
                    ),
                ],
            ),
            new AdTemplate(
                "{originLocationName} to {destinationLocationName} from {=Ad customizers PRICES.Price:{price}}",
                "A Comfy Car Beats the Train",
                "Free Cancellations",
                "Comfortable door-to-door service with an experienced driver. Cars cleaned for every trip.",
                "The most convenient way to travel between cities. Enjoy sight-seeing stops on the way!",
                [
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName} from {=Ad customizers PRICES.Price:{price}}",
                        "A Comfy Car Beats the Train",
                        "Free Cancellations",
                        "Comfortable door-to-door service with an experienced driver. Cars cleaned for every trip.",
                        "The most convenient way to travel between cities. Enjoy sight-seeing stops on the way!",
                    ),
                    new AdTemplate(
                        "{originLocationName} to {destinationLocationName}",
                        "A Ride from {=Ad customizers PRICES.Price:{price}} Beats a Train",
                        "Free Cancellations",
                        "Comfortable door-to-door service with an experienced driver. Cars cleaned for every trip.",
                        "The most convenient way to travel between cities. Enjoy sight-seeing stops on the way!",
                    ),
                ],
            ),
            new AdTemplate(
                "{originLocationName} to {destinationLocationName} in {duration}",
                "A Private Car Beats the Train",
                "Free Cancellations",
                "Comfortable door-to-door service with an experienced driver. Cars cleaned for every trip.",
                "The most convenient way to travel between cities. Enjoy sight-seeing stops on the way!",
                [
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName} in {duration}",
                        "A Private Car Beats the Train",
                        "Free Cancellations",
                        "Comfortable door-to-door service with an experienced driver. Cars cleaned for every trip.",
                        "The most convenient way to travel between cities. Enjoy sight-seeing stops on the way!",
                    ),
                    new AdTemplate(
                        "{originLocationName} to {destinationLocationName}",
                        "A {duration} Ride Beats the Train",
                        "Free Cancellations",
                        "Comfortable door-to-door service with an experienced driver. Cars cleaned for every trip.",
                        "The most convenient way to travel between cities. Enjoy sight-seeing stops on the way!",
                    ),
                ],
            ),
        ],
        // adgroup 4
        [
            new AdTemplate(
                "Private Сar {originLocationName} to {destinationLocationName}",
                "Clean, Comfortable, Convenient",
                "Free Cancellations",
                "Comfortable door-to-door service with an experienced driver. Cars cleaned for every trip.",
                "The most convenient way to travel between cities. Enjoy sight-seeing stops on the way!",
                [
                    new AdTemplate(
                        "{originLocationName} to {destinationLocationName} by Car",
                        "Clean, Comfortable, Convenient",
                        "Free Cancellations",
                        "Comfortable door-to-door service with an experienced driver. Cars cleaned for every trip.",
                        "The most convenient way to travel between cities. Enjoy sight-seeing stops on the way!",
                    ),
                    new AdTemplate(
                        "{originLocationName} to {destinationLocationName}",
                        "Clean, Comfortable Private Car",
                        "Free Cancellations",
                        "Comfortable door-to-door service with an experienced driver. Cars cleaned for every trip.",
                        "The most convenient way to travel between cities. Enjoy sight-seeing stops on the way!",
                    ),
                ],
            ),
            new AdTemplate(
                "{originLocationName} to {destinationLocationName} from {=Ad customizers PRICES.Price:{price}}",
                "Clean, Comfortable Private Car",
                "Free Cancellations",
                "Comfortable door-to-door service with an experienced driver. Cars cleaned for every trip.",
                "The most convenient way to travel between cities. Enjoy sight-seeing stops on the way!",
                [
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName} from {=Ad customizers PRICES.Price:{price}}",
                        "Clean, Comfortable Private Car",
                        "Free Cancellations",
                        "Comfortable door-to-door service with an experienced driver. Cars cleaned for every trip.",
                        "The most convenient way to travel between cities. Enjoy sight-seeing stops on the way!",
                    ),
                    new AdTemplate(
                        "{originLocationName} to {destinationLocationName}",
                        "From {=Ad customizers PRICES.Price:{price}} by Comfy Private Car",
                        "Free Cancellations",
                        "Comfortable door-to-door service with an experienced driver. Cars cleaned for every trip.",
                        "The most convenient way to travel between cities. Enjoy sight-seeing stops on the way!",
                    ),
                ],
            ),
            new AdTemplate(
                "{originLocationName} to {destinationLocationName} in {duration}",
                "Clean, Comfortable Private Car",
                "Free Cancellations",
                "Comfortable door-to-door service with an experienced driver. Cars cleaned for every trip.",
                "The most convenient way to travel between cities. Enjoy sight-seeing stops on the way!",
                [
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName} in {duration}",
                        "Clean, Comfortable Private Car",
                        "Free Cancellations",
                        "Comfortable door-to-door service with an experienced driver. Cars cleaned for every trip.",
                        "The most convenient way to travel between cities. Enjoy sight-seeing stops on the way!",
                    ),
                    new AdTemplate(
                        "{originLocationName} to {destinationLocationName}",
                        "{duration} by Comfy Private Car",
                        "Free Cancellations",
                        "Comfortable door-to-door service with an experienced driver. Cars cleaned for every trip.",
                        "The most convenient way to travel between cities. Enjoy sight-seeing stops on the way!",
                    ),
                ],
            ),
        ],
        // adgroup 6
        [
            new AdTemplate(
                "{originLocationName} to {destinationLocationName} from {=Ad customizers PRICES.Price:{price}}",
                "A Comfy Car Ride Beats Flying",
                "Free Cancellations",
                "Comfortable door-to-door service with an experienced driver. Cars cleaned for every trip.",
                "The most convenient way to travel between cities. Enjoy sight-seeing stops on the way!",
                [
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName} from {=Ad customizers PRICES.Price:{price}}",
                        "A Comfy Car Ride Beats Flying",
                        "Free Cancellations",
                        "Comfortable door-to-door service with an experienced driver. Cars cleaned for every trip.",
                        "The most convenient way to travel between cities. Enjoy sight-seeing stops on the way!",
                    ),
                    new AdTemplate(
                        "{originLocationName} to {destinationLocationName}",
                        "A Ride from {=Ad customizers PRICES.Price:{price}} Beats a Flight",
                        "Free Cancellations",
                        "Comfortable door-to-door service with an experienced driver. Cars cleaned for every trip.",
                        "The most convenient way to travel between cities. Enjoy sight-seeing stops on the way!",
                    ),
                ],
            ),
            new AdTemplate(
                "{originLocationName} to {destinationLocationName} by Car",
                "More Comfortable than Flying",
                "Free Cancellations",
                "Comfortable door-to-door service with an experienced driver. Cars cleaned for every trip.",
                "The most convenient way to travel between cities. Enjoy sight-seeing stops on the way!",
                [
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName} by Car",
                        "More Comfortable than Flying",
                        "Free Cancellations",
                        "Comfortable door-to-door service with an experienced driver. Cars cleaned for every trip.",
                        "The most convenient way to travel between cities. Enjoy sight-seeing stops on the way!",
                    ),
                    new AdTemplate(
                        "{originLocationName} to {destinationLocationName}",
                        "A Comfy Car Ride Beats Flying",
                        "Free Cancellations",
                        "Comfortable door-to-door service with an experienced driver. Cars cleaned for every trip.",
                        "The most convenient way to travel between cities. Enjoy sight-seeing stops on the way!",
                    ),
                ],
            ),
            new AdTemplate(
                "{originLocationName} to {destinationLocationName} in {duration}",
                "Take a Ride instead of Flight",
                "Free Cancellations",
                "Comfortable door-to-door service with an experienced driver. Cars cleaned for every trip.",
                "The most convenient way to travel between cities. Enjoy sight-seeing stops on the way!",
                [
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName} in {duration}",
                        "Take a Ride instead of Flight",
                        "Free Cancellations",
                        "Comfortable door-to-door service with an experienced driver. Cars cleaned for every trip.",
                        "The most convenient way to travel between cities. Enjoy sight-seeing stops on the way!",
                    ),
                    new AdTemplate(
                        "{originLocationName} to {destinationLocationName}",
                        "A {duration} Ride Beats a Flight",
                        "Free Cancellations",
                        "Comfortable door-to-door service with an experienced driver. Cars cleaned for every trip.",
                        "The most convenient way to travel between cities. Enjoy sight-seeing stops on the way!",
                    ),
                ],
            ),
        ],
    ],
    [GoogleCampaignSettingType.AustraliaNewZealand]: [
        // adgroup 1
        [
            new AdTemplate(
                "Private Сar {originLocationName} to {destinationLocationName}",
                "Safe, Sanitized Vehicles",
                "Free Cancellations",
                "Comfortable door-to-door service with an experienced driver. Cars cleaned for every trip.",
                "The most convenient way to travel between cities. Enjoy sight-seeing stops on the way!",
                [
                    new AdTemplate(
                        "{originLocationName} to {destinationLocationName} by Car",
                        "Safe, Sanitized Vehicles",
                        "Free Cancellations",
                        "Comfortable door-to-door service with an experienced driver. Cars cleaned for every trip.",
                        "The most convenient way to travel between cities. Enjoy sight-seeing stops on the way!",
                    ),
                    new AdTemplate(
                        "{originLocationName} to {destinationLocationName}",
                        "Safe, Sanitized Private Cars",
                        "Free Cancellations",
                        "Comfortable door-to-door service with an experienced driver. Cars cleaned for every trip.",
                        "The most convenient way to travel between cities. Enjoy sight-seeing stops on the way!",
                    ),
                ],
            ),
            new AdTemplate(
                "{originLocationName} to {destinationLocationName} from {=Ad customizers PRICES.Price:{price}}",
                "Ride in Safety & Comfort",
                "Free Cancellations",
                "Comfortable door-to-door service with an experienced driver. Cars cleaned for every trip.",
                "The most convenient way to travel between cities. Enjoy sight-seeing stops on the way!",
                [
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName} from {=Ad customizers PRICES.Price:{price}}",
                        "Ride in Safety & Comfort",
                        "Free Cancellations",
                        "Comfortable door-to-door service with an experienced driver. Cars cleaned for every trip.",
                        "The most convenient way to travel between cities. Enjoy sight-seeing stops on the way!",
                    ),
                    new AdTemplate(
                        "{originLocationName} to {destinationLocationName}",
                        "From {=Ad customizers PRICES.Price:{price}} Door-to-Door by Car",
                        "Free Cancellations",
                        "Comfortable door-to-door service with an experienced driver. Cars cleaned for every trip.",
                        "The most convenient way to travel between cities. Enjoy sight-seeing stops on the way!",
                    ),
                ],
            ),
            new AdTemplate(
                "{originLocationName} to {destinationLocationName} in {duration}",
                "Ride in Safety & Comfort",
                "Free Cancellations",
                "Comfortable door-to-door service with an experienced driver. Cars cleaned for every trip.",
                "The most convenient way to travel between cities. Enjoy sight-seeing stops on the way!",
                [
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName} in {duration}",
                        "Ride in Safety & Comfort",
                        "Free Cancellations",
                        "Comfortable door-to-door service with an experienced driver. Cars cleaned for every trip.",
                        "The most convenient way to travel between cities. Enjoy sight-seeing stops on the way!",
                    ),
                    new AdTemplate(
                        "{originLocationName} to {destinationLocationName}",
                        "{duration} by Comfy Private Car",
                        "Free Cancellations",
                        "Comfortable door-to-door service with an experienced driver. Cars cleaned for every trip.",
                        "The most convenient way to travel between cities. Enjoy sight-seeing stops on the way!",
                    ),
                ],
            ),
        ],
        // adgroup 2
        [
            new AdTemplate(
                "Private Сar {originLocationName} to {destinationLocationName}",
                "A Comfy Car Ride Beats the Bus",
                "Free Cancellations",
                "Comfortable door-to-door service with an experienced driver. Cars cleaned for every trip.",
                "The most convenient way to travel between cities. Enjoy sight-seeing stops on the way!",
                [
                    new AdTemplate(
                        "{originLocationName} to {destinationLocationName} by Car",
                        "A Comfy Car Ride Beats the Bus",
                        "Free Cancellations",
                        "Comfortable door-to-door service with an experienced driver. Cars cleaned for every trip.",
                        "The most convenient way to travel between cities. Enjoy sight-seeing stops on the way!",
                    ),
                    new AdTemplate(
                        "{originLocationName} to {destinationLocationName}",
                        "A Comfy Car Ride Beats the Bus",
                        "Free Cancellations",
                        "Comfortable door-to-door service with an experienced driver. Cars cleaned for every trip.",
                        "The most convenient way to travel between cities. Enjoy sight-seeing stops on the way!",
                    ),
                ],
            ),
            new AdTemplate(
                "{originLocationName} to {destinationLocationName} from {=Ad customizers PRICES.Price:{price}}",
                "Faster & Easier than the Bus",
                "Free Cancellations",
                "Comfortable door-to-door service with an experienced driver. Cars cleaned for every trip.",
                "The most convenient way to travel between cities. Enjoy sight-seeing stops on the way!",
                [
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName} from {=Ad customizers PRICES.Price:{price}}",
                        "Faster & Easier than the Bus",
                        "Free Cancellations",
                        "Comfortable door-to-door service with an experienced driver. Cars cleaned for every trip.",
                        "The most convenient way to travel between cities. Enjoy sight-seeing stops on the way!",
                    ),
                    new AdTemplate(
                        "{originLocationName} to {destinationLocationName}",
                        "From {=Ad customizers PRICES.Price:{price}} & Easier than the Bus",
                        "Free Cancellations",
                        "Comfortable door-to-door service with an experienced driver. Cars cleaned for every trip.",
                        "The most convenient way to travel between cities. Enjoy sight-seeing stops on the way!",
                    ),
                ],
            ),
            new AdTemplate(
                "{originLocationName} to {destinationLocationName} in {duration}",
                "A Private Car Beats the Bus",
                "Free Cancellations",
                "Comfortable door-to-door service with an experienced driver. Cars cleaned for every trip.",
                "The most convenient way to travel between cities. Enjoy sight-seeing stops on the way!",
                [
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName} in {duration}",
                        "A Private Car Beats the Bus",
                        "Free Cancellations",
                        "Comfortable door-to-door service with an experienced driver. Cars cleaned for every trip.",
                        "The most convenient way to travel between cities. Enjoy sight-seeing stops on the way!",
                    ),
                    new AdTemplate(
                        "{originLocationName} to {destinationLocationName}",
                        "A {duration} Ride Beats the Bus",
                        "Free Cancellations",
                        "Comfortable door-to-door service with an experienced driver. Cars cleaned for every trip.",
                        "The most convenient way to travel between cities. Enjoy sight-seeing stops on the way!",
                    ),
                ],
            ),
        ],
        // adgroup 3
        [
            new AdTemplate(
                "Private Сar {originLocationName} to {destinationLocationName}",
                "Faster & Easier than the Train",
                "Free Cancellations",
                "Comfortable door-to-door service with an experienced driver. Cars cleaned for every trip.",
                "The most convenient way to travel between cities. Enjoy sight-seeing stops on the way!",
                [
                    new AdTemplate(
                        "{originLocationName} to {destinationLocationName} by Car",
                        "Faster & Easier than the Train",
                        "Free Cancellations",
                        "Comfortable door-to-door service with an experienced driver. Cars cleaned for every trip.",
                        "The most convenient way to travel between cities. Enjoy sight-seeing stops on the way!",
                    ),
                    new AdTemplate(
                        "{originLocationName} to {destinationLocationName}",
                        "A Comfy Ride Beats the Train",
                        "Free Cancellations",
                        "Comfortable door-to-door service with an experienced driver. Cars cleaned for every trip.",
                        "The most convenient way to travel between cities. Enjoy sight-seeing stops on the way!",
                    ),
                ],
            ),
            new AdTemplate(
                "{originLocationName} to {destinationLocationName} from {=Ad customizers PRICES.Price:{price}}",
                "A Comfy Car Beats the Train",
                "Free Cancellations",
                "Comfortable door-to-door service with an experienced driver. Cars cleaned for every trip.",
                "The most convenient way to travel between cities. Enjoy sight-seeing stops on the way!",
                [
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName} from {=Ad customizers PRICES.Price:{price}}",
                        "A Comfy Car Beats the Train",
                        "Free Cancellations",
                        "Comfortable door-to-door service with an experienced driver. Cars cleaned for every trip.",
                        "The most convenient way to travel between cities. Enjoy sight-seeing stops on the way!",
                    ),
                    new AdTemplate(
                        "{originLocationName} to {destinationLocationName}",
                        "A Ride from {=Ad customizers PRICES.Price:{price}} Beats a Train",
                        "Free Cancellations",
                        "Comfortable door-to-door service with an experienced driver. Cars cleaned for every trip.",
                        "The most convenient way to travel between cities. Enjoy sight-seeing stops on the way!",
                    ),
                ],
            ),
            new AdTemplate(
                "{originLocationName} to {destinationLocationName} in {duration}",
                "A Private Car Beats the Train",
                "Free Cancellations",
                "Comfortable door-to-door service with an experienced driver. Cars cleaned for every trip.",
                "The most convenient way to travel between cities. Enjoy sight-seeing stops on the way!",
                [
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName} in {duration}",
                        "A Private Car Beats the Train",
                        "Free Cancellations",
                        "Comfortable door-to-door service with an experienced driver. Cars cleaned for every trip.",
                        "The most convenient way to travel between cities. Enjoy sight-seeing stops on the way!",
                    ),
                    new AdTemplate(
                        "{originLocationName} to {destinationLocationName}",
                        "A {duration} Ride Beats the Train",
                        "Free Cancellations",
                        "Comfortable door-to-door service with an experienced driver. Cars cleaned for every trip.",
                        "The most convenient way to travel between cities. Enjoy sight-seeing stops on the way!",
                    ),
                ],
            ),
        ],
        // adgroup 4
        [
            new AdTemplate(
                "Private Сar {originLocationName} to {destinationLocationName}",
                "Clean, Comfortable, Convenient",
                "Free Cancellations",
                "Comfortable door-to-door service with an experienced driver. Cars cleaned for every trip.",
                "The most convenient way to travel between cities. Enjoy sight-seeing stops on the way!",
                [
                    new AdTemplate(
                        "{originLocationName} to {destinationLocationName} by Car",
                        "Clean, Comfortable, Convenient",
                        "Free Cancellations",
                        "Comfortable door-to-door service with an experienced driver. Cars cleaned for every trip.",
                        "The most convenient way to travel between cities. Enjoy sight-seeing stops on the way!",
                    ),
                    new AdTemplate(
                        "{originLocationName} to {destinationLocationName}",
                        "Clean, Comfortable Private Car",
                        "Free Cancellations",
                        "Comfortable door-to-door service with an experienced driver. Cars cleaned for every trip.",
                        "The most convenient way to travel between cities. Enjoy sight-seeing stops on the way!",
                    ),
                ],
            ),
            new AdTemplate(
                "{originLocationName} to {destinationLocationName} from {=Ad customizers PRICES.Price:{price}}",
                "Clean, Comfortable Private Car",
                "Free Cancellations",
                "Comfortable door-to-door service with an experienced driver. Cars cleaned for every trip.",
                "The most convenient way to travel between cities. Enjoy sight-seeing stops on the way!",
                [
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName} from {=Ad customizers PRICES.Price:{price}}",
                        "Clean, Comfortable Private Car",
                        "Free Cancellations",
                        "Comfortable door-to-door service with an experienced driver. Cars cleaned for every trip.",
                        "The most convenient way to travel between cities. Enjoy sight-seeing stops on the way!",
                    ),
                    new AdTemplate(
                        "{originLocationName} to {destinationLocationName}",
                        "From {=Ad customizers PRICES.Price:{price}} by Comfy Private Car",
                        "Free Cancellations",
                        "Comfortable door-to-door service with an experienced driver. Cars cleaned for every trip.",
                        "The most convenient way to travel between cities. Enjoy sight-seeing stops on the way!",
                    ),
                ],
            ),
            new AdTemplate(
                "{originLocationName} to {destinationLocationName} in {duration}",
                "Clean, Comfortable Private Car",
                "Free Cancellations",
                "Comfortable door-to-door service with an experienced driver. Cars cleaned for every trip.",
                "The most convenient way to travel between cities. Enjoy sight-seeing stops on the way!",
                [
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName} in {duration}",
                        "Clean, Comfortable Private Car",
                        "Free Cancellations",
                        "Comfortable door-to-door service with an experienced driver. Cars cleaned for every trip.",
                        "The most convenient way to travel between cities. Enjoy sight-seeing stops on the way!",
                    ),
                    new AdTemplate(
                        "{originLocationName} to {destinationLocationName}",
                        "{duration} by Comfy Private Car",
                        "Free Cancellations",
                        "Comfortable door-to-door service with an experienced driver. Cars cleaned for every trip.",
                        "The most convenient way to travel between cities. Enjoy sight-seeing stops on the way!",
                    ),
                ],
            ),
        ],
        // adgroup 6
        [
            new AdTemplate(
                "{originLocationName} to {destinationLocationName} from {=Ad customizers PRICES.Price:{price}}",
                "A Comfy Car Ride Beats Flying",
                "Free Cancellations",
                "Comfortable door-to-door service with an experienced driver. Cars cleaned for every trip.",
                "The most convenient way to travel between cities. Enjoy sight-seeing stops on the way!",
                [
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName} from {=Ad customizers PRICES.Price:{price}}",
                        "A Comfy Car Ride Beats Flying",
                        "Free Cancellations",
                        "Comfortable door-to-door service with an experienced driver. Cars cleaned for every trip.",
                        "The most convenient way to travel between cities. Enjoy sight-seeing stops on the way!",
                    ),
                    new AdTemplate(
                        "{originLocationName} to {destinationLocationName}",
                        "A Ride from {=Ad customizers PRICES.Price:{price}} Beats a Flight",
                        "Free Cancellations",
                        "Comfortable door-to-door service with an experienced driver. Cars cleaned for every trip.",
                        "The most convenient way to travel between cities. Enjoy sight-seeing stops on the way!",
                    ),
                ],
            ),
            new AdTemplate(
                "{originLocationName} to {destinationLocationName} by Car",
                "More Comfortable than Flying",
                "Free Cancellations",
                "Comfortable door-to-door service with an experienced driver. Cars cleaned for every trip.",
                "The most convenient way to travel between cities. Enjoy sight-seeing stops on the way!",
                [
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName} by Car",
                        "More Comfortable than Flying",
                        "Free Cancellations",
                        "Comfortable door-to-door service with an experienced driver. Cars cleaned for every trip.",
                        "The most convenient way to travel between cities. Enjoy sight-seeing stops on the way!",
                    ),
                    new AdTemplate(
                        "{originLocationName} to {destinationLocationName}",
                        "A Comfy Car Ride Beats Flying",
                        "Free Cancellations",
                        "Comfortable door-to-door service with an experienced driver. Cars cleaned for every trip.",
                        "The most convenient way to travel between cities. Enjoy sight-seeing stops on the way!",
                    ),
                ],
            ),
            new AdTemplate(
                "{originLocationName} to {destinationLocationName} in {duration}",
                "Take a Ride instead of Flight",
                "Free Cancellations",
                "Comfortable door-to-door service with an experienced driver. Cars cleaned for every trip.",
                "The most convenient way to travel between cities. Enjoy sight-seeing stops on the way!",
                [
                    new AdTemplate(
                        "{originLocationName}-{destinationLocationName} in {duration}",
                        "Take a Ride instead of Flight",
                        "Free Cancellations",
                        "Comfortable door-to-door service with an experienced driver. Cars cleaned for every trip.",
                        "The most convenient way to travel between cities. Enjoy sight-seeing stops on the way!",
                    ),
                    new AdTemplate(
                        "{originLocationName} to {destinationLocationName}",
                        "A {duration} Ride Beats a Flight",
                        "Free Cancellations",
                        "Comfortable door-to-door service with an experienced driver. Cars cleaned for every trip.",
                        "The most convenient way to travel between cities. Enjoy sight-seeing stops on the way!",
                    ),
                ],
            ),
        ],
    ],
} as const;
