/* eslint-disable no-restricted-syntax */
/* eslint-disable no-alert */
import { FrequentlyAskedQuestionType } from "@daytrip/legacy-enums";
import { FrequentlyAskedQuestion } from "@legacy/models/FrequentlyAskedQuestion";
import type { Route } from "@legacy/models/Route";
import autobind from "autobind-decorator";
import { plainToClass } from "class-transformer";
import { action, computed, observable } from "mobx";

import { FrequentlyAskedQuestionOperator } from "../../../operators/FrequentlyAskedQuestionOperator";
import { PageStore } from "../../../stores/PageStore";
import { extractDirectionsFromRoute } from "../Route.utils";
import type { RoutePageRouter } from "../RoutePageRouter";

@autobind
export class LandingFAQsPageStore extends PageStore<RoutePageRouter, {}> {
    @observable
    public isContentFetched: boolean = false;

    @observable
    public routeId: string | undefined;

    @observable
    public machineName: string | undefined;

    @observable
    public route: Route | undefined;

    @observable
    public isRouteFetched: boolean = false;

    @observable
    public categories: string[] = [];

    @observable
    public frequentlyAskedQuestions: Array<FrequentlyAskedQuestionOperator> = [];

    @observable
    public showAddNewCategoryModal: boolean = false;

    @observable
    public newCategoryInput: string = "";

    @observable
    public newCategoryValidationMessage: string | undefined;

    @observable
    public faqIdWithNewCategory: string | undefined;

    @observable
    public inEditMode: boolean = false;

    @action
    public async updateMachineName(machineName: string) {
        this.machineName = machineName;
        this.isContentFetched = false;
        await this.fetchFaqs();
    }

    @action
    public async onFetchData() {
        const { routeId } = this.pageRouter;
        this.routeId = routeId;
        await this.fetchRoute();
        const { firstDirection } = extractDirectionsFromRoute(this.route);
        this.machineName = firstDirection;
        await this.fetchFaqs();
    }

    public isDataFetched(): this is LandingFAQsPageStore & LandingFAQsPageStoreDataFetched {
        return Boolean(this.isRouteFetched && this.isContentFetched);
    }

    @action
    public async fetchRoute() {
        if (!this.routeId) {
            return;
        }
        try {
            this.route = await this.rpcClient.content.retrieveRoute(this.routeId);
        } catch (error: any) {
            alert(`Oh, something is wrong. :(\nError:\n${error.message}`);
        }
        this.isRouteFetched = true;
    }

    @action
    public async fetchFaqs() {
        if (!this.machineName) {
            return;
        }
        try {
            const questionsResponse = await this.rpcClient.faq.getFaqByMachineName(this.machineName);
            this.frequentlyAskedQuestions = questionsResponse.map((questionData) => {
                const faqsOperator = new FrequentlyAskedQuestionOperator({
                    modelConstructor: FrequentlyAskedQuestion,
                    model: plainToClass(FrequentlyAskedQuestion, questionData),
                    modules: null,
                    data: null,
                    isNew: false,
                    isUpdated: false,
                    onSave: async (editedModel) => {
                        try {
                            await this.rpcClient.faq.updateFaq(editedModel._id, { ...editedModel, isPublished: false });
                        } catch (error: any) {
                            alert(`Oh, something went wrong while updating FAQ. :(\nError:\n${error.message}`);
                        }
                    },
                });
                return faqsOperator;
            });
            await this.setCategories();
        } catch (error: any) {
            alert(`Oh, something is wrong. :(\nError:\n${error.message}`);
        }
        this.isContentFetched = true;
    }

    @action
    public async onAddNewFAQ() {
        const newFAQ: FrequentlyAskedQuestion = Object.assign(new FrequentlyAskedQuestion(), {
            order: this.frequentlyAskedQuestions.length,
            machineName: this.machineName,
            category: this.categories[0] || "",
            humanReadableAnchor: `${this.frequentlyAskedQuestions.length}`,
            type: FrequentlyAskedQuestionType.LANDING,
            isPublished: false,
        });

        const newFAQOperator = new FrequentlyAskedQuestionOperator({
            modelConstructor: FrequentlyAskedQuestion,
            model: plainToClass(FrequentlyAskedQuestion, newFAQ),
            modules: null,
            data: null,
            isNew: true,
            isUpdated: false,
            onSave: async (editedModel) => {
                try {
                    await this.rpcClient.faq.createFaq(editedModel);
                    const updateIsNewFaqo = this.frequentlyAskedQuestions.find((faq) => faq.m._id === editedModel._id);
                    if (updateIsNewFaqo) {
                        updateIsNewFaqo.isNew = false;
                    }
                } catch (error: any) {
                    alert(`Oh, something went wrong while creating FAQ. :(\nError:\n${error.message}`);
                }
            },
        });

        newFAQOperator.edit(() => {});
        this.frequentlyAskedQuestions.push(newFAQOperator);

        scrollTo({ top: window.innerHeight, behavior: "smooth" });
    }

    @action
    public enableEditingMode() {
        this.frequentlyAskedQuestions.forEach((faq) => {
            faq.edit();
            faq.validate();
        });
        this.inEditMode = true;
    }

    private async setCategories() {
        this.categories = await this.rpcClient.faq.getCategoriesFromFaqs();
    }

    @action
    public async onRemoveFAQ(faqId: string) {
        try {
            await this.rpcClient.faq.deleteFaq(faqId);
            this.frequentlyAskedQuestions = this.frequentlyAskedQuestions.filter((item) => item.m._id !== faqId);
            await this.setCategories();
        } catch (error: any) {
            alert(`Oh, something went wrong while removing FAQ. :(\nError:\n${error.message}`);
            return;
        }
    }

    @action
    public async onSaveFAQ() {
        await Promise.all(
            this.frequentlyAskedQuestions
                .filter((faqoToSave) => faqoToSave.isEdited())
                .map(async (faqoToSave) => {
                    try {
                        await faqoToSave.validate();
                        faqoToSave.edit((editedModel) => {
                            editedModel.isPublished = false;
                        });
                        await faqoToSave.save();
                    } catch (error: any) {
                        return;
                    }
                }),
        );
        this.inEditMode = false;
    }

    @action
    public cancelSaveFAQ() {
        this.frequentlyAskedQuestions
            .filter((faqo) => faqo.isEdited())
            .forEach(async (faqo) => {
                faqo.cancelEdit();
                if (faqo.isNew) {
                    this.cancelNewFAQ(faqo.m._id);
                }
            });
        this.inEditMode = false;
    }

    @action
    public onOrderChange(faqId: string, newOrderValue: number) {
        const faqItem = this.frequentlyAskedQuestions.find((faq) => faq.m._id === faqId);
        if (!faqItem) {
            return;
        }
        faqItem.edit((faq) => {
            const shouldReplaceFaqo = this.frequentlyAskedQuestions.find(
                (faqToReplace) => faqToReplace.m.order === newOrderValue,
            );
            if (shouldReplaceFaqo) {
                shouldReplaceFaqo.edit((shouldReplace) => {
                    shouldReplace.order = faq.order;
                    shouldReplace.humanReadableAnchor = `${faq.order}`;
                });
            }
            faq.order = newOrderValue;
            faq.humanReadableAnchor = `${newOrderValue}`;
        });
    }

    @action
    public cancelNewFAQ(faqId: string) {
        this.frequentlyAskedQuestions = this.frequentlyAskedQuestions.filter((item) => item.m._id !== faqId);
    }

    @action
    public setShowNewCategoryModal(value: boolean, faqId?: string | undefined) {
        this.showAddNewCategoryModal = value;
        this.newCategoryInput = "";
        this.faqIdWithNewCategory = faqId;
    }

    @action
    public addNewCategory() {
        this.categories.push(this.newCategoryInput);
        const faqoToEdit = this.frequentlyAskedQuestions.find((faq) => faq.m._id === this.faqIdWithNewCategory);
        if (faqoToEdit) {
            faqoToEdit.edit((faq) => {
                faq.category = this.newCategoryInput;
            });
        }
        this.setShowNewCategoryModal(false);
    }

    @action
    public onChangeNewCategory(category: string) {
        this.newCategoryInput = category;
        this.newCategoryValidationMessage = this.validateNewCategory(category);
    }

    private validateNewCategory(newCategory: string) {
        let isDuplicate = false;
        this.categories.forEach((category) => {
            if (category.toLowerCase() === newCategory.toLowerCase()) {
                isDuplicate = true;
            }
        });
        if (isDuplicate) {
            return "Category already exists.";
        }
        return undefined;
    }

    @action
    public async publishFAQ(isPublished: boolean) {
        try {
            await Promise.all(
                this.frequentlyAskedQuestions.map(async (faqoToSave) => {
                    faqoToSave.m.isPublished = isPublished;
                    return this.rpcClient.faq.updateFaq(faqoToSave.m._id, { ...faqoToSave.m, isPublished });
                }),
            );
        } catch (error: any) {
            alert(`Oh, something went wrong while updating FAQ. :(\nError:\n${error.message}`);
        }
    }

    @computed
    public get isPublished(): boolean {
        return Boolean(this.frequentlyAskedQuestions.length) && this.frequentlyAskedQuestions[0].m.isPublished;
    }
}

export interface LandingFAQsPageStoreDataFetched {
    route: Route;
    frequentlyAskedQuestions: Array<FrequentlyAskedQuestionOperator>;
    categories: string[];
}
