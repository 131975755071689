import { Location } from "@daytrip/legacy-models";
import { Order } from "@daytrip/legacy-models";
import { Expose } from "class-transformer";
import { IsBoolean } from "class-validator";
import { observable } from "mobx";

export class OrderAddressCheckHolder {
    @Expose()
    @observable
    public order: Order;

    @IsBoolean()
    @Expose()
    @observable
    public isPickupAddressApproved: boolean;

    @IsBoolean()
    @Expose()
    @observable
    public isMeetingPosition: boolean | undefined = undefined;

    @IsBoolean()
    @Expose()
    @observable
    public isMeetingPositionResend: boolean | undefined = undefined;

    @IsBoolean()
    @Expose()
    @observable
    public isAirport: boolean | undefined = undefined;

    @IsBoolean()
    @Expose()
    @observable
    public isAirportResend: boolean | undefined = undefined;

    @IsBoolean()
    @Expose()
    @observable
    public isBoat: boolean | undefined = undefined;

    @IsBoolean()
    @Expose()
    @observable
    public isBoatResend: boolean | undefined = undefined;

    @IsBoolean()
    @Expose()
    @observable
    public isTrain: boolean | undefined = undefined;

    @IsBoolean()
    @Expose()
    @observable
    public isTrainResend: boolean | undefined = undefined;

    @IsBoolean()
    @Expose()
    @observable
    public isIncomplete: boolean | undefined = undefined;

    @IsBoolean()
    @Expose()
    @observable
    public isIncompleteResend: boolean | undefined = undefined;

    @Expose()
    public originLocation: Location;

    @Expose()
    public destinationLocation: Location;

    @IsBoolean()
    @Expose()
    public isCash: boolean;

    @IsBoolean()
    @Expose()
    @observable
    public isOk: boolean;

    @IsBoolean()
    @Expose()
    @observable
    public isMeetingPositionEmailSent: boolean = false;

    @IsBoolean()
    @Expose()
    @observable
    public isAirportEmailSent: boolean = false;

    @IsBoolean()
    @Expose()
    @observable
    public isBoatEmailSent: boolean = false;

    @IsBoolean()
    @Expose()
    @observable
    public isIncompleteEmailSent: boolean = false;

    @IsBoolean()
    @Expose()
    @observable
    public isTrainEmailSent: boolean = false;
}
