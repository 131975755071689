import { Exclude, Expose, Transform } from "class-transformer";
import { IsNumber, IsString } from "class-validator";

import { Direction } from "../utils";

@Exclude()
export class SortingPaginationOptions<T> {
    @IsNumber()
    @Transform((value) => (value !== undefined ? Number(value) : value))
    @Expose()
    public skip?: number;

    @IsNumber()
    @Transform((value) => (value !== undefined ? Number(value) : value))
    @Expose()
    public limit?: number;

    @IsString()
    @Transform((value) => (value !== undefined ? String(value) : value))
    @Expose()
    public sortBy?: keyof T | "routeOrdersCount";

    @IsNumber()
    @Transform((value) => (value !== undefined ? Number(value) : value))
    @Expose()
    public sortDirection?: Direction;
}
