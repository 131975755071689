import { valueOrArrayToArray } from "@daytrip/utils";
import type { User } from "@legacy/models/User";
import { RetrieveUsersOptions } from "@legacy/options/RetrieveUsersOptions";
import autobind from "autobind-decorator";
import { validate } from "class-validator";
import { action, computed, observable } from "mobx";

import { PageStore } from "../../stores/PageStore";

import type { UsersPageRouter } from "./UsersPageRouter";

@autobind
export class UsersPageStore extends PageStore<UsersPageRouter, null> {
    @observable
    public users?: User[];

    @observable
    public usersCount?: number;

    @action
    public clearSearch() {
        this.pageRouter.clearQuery();
        this.fetchUsers();
    }

    @action
    public async onFetchData() {
        await this.fetchUsers();
    }

    @action
    public async fetchUsers(): Promise<void> {
        this.users = undefined;

        const options = new RetrieveUsersOptions();

        options.skip = this.pageRouter.skip;
        options.limit = this.pageRouter.limit;
        options.sortBy = this.pageRouter.sortBy as keyof User;
        options.sortDirection = this.pageRouter.sortDirection;

        options.searchString = this.pageRouter.usersStringFilter;
        options.notDriverOrCustomer = true;
        const roles = this.pageRouter.roles && valueOrArrayToArray(this.pageRouter.roles);
        if (roles?.length) {
            options.roles = roles;
        }

        const optionsValidationErrors = await validate(options, { skipMissingProperties: true });
        if (optionsValidationErrors.length > 0) {
            return Promise.reject(new Error("Validation error"));
        }

        const { users, totalCount } = await this.rpcClient.user.retrieveUsersForUsersPage(options);
        this.users = users;
        this.usersCount = totalCount;
    }

    @computed
    public get isUsersFetched() {
        return !!this.users;
    }
}
