import type { Location } from "@daytrip/legacy-models";
import { AssignationStatus } from "@legacy/domain/AssignationStatus";
import type { AssignationWithAnonymizedOrder } from "@legacy/domain/AssignationWithAnonymizedOrder";
import type { AvailabilityUpdateSchema } from "@legacy/domain/AvailabilityUpdateSchema";
import type { Unavailability } from "@legacy/models/Unavailability";
import { addMonths, getDaysInMonth } from "date-fns";
import { injectable } from "inversify";

import { getRpcClient } from "../../../rpc-browser-sdk";
import type { AnonymizedOrderWithLocations } from "../types";

@injectable()
export class DriverAvailabilityService {
    protected rpcClient = getRpcClient();

    public async updateUserMultipleAvailabilities(
        availabilities: AvailabilityUpdateSchema[],
        availabilityOwnerId: string,
    ): Promise<void> {
        return this.rpcClient.unavailability.updateUserMultipleAvailabilities(availabilities, availabilityOwnerId);
    }

    public async retrieveUnavailabilities(userId: string, year: number, month: number): Promise<Unavailability[]> {
        return this.rpcClient.unavailability.retrieveUnavailabilities({
            userId,
            year,
            month,
        });
    }

    public async getAssignationsForMonthOfDate(
        userId: string | undefined,
        year: number,
        month: number,
    ): Promise<Array<Array<AnonymizedOrderWithLocations>>> {
        const firstDayOfMonth: Date = new Date(Date.UTC(year, month, 1));
        const lastDayOfMonth: Date = addMonths(firstDayOfMonth, 1);
        const daysInMonth: number = getDaysInMonth(firstDayOfMonth);
        const daysAssignations = new Array<Array<AnonymizedOrderWithLocations>>(daysInMonth);
        let assignations: Array<AssignationWithAnonymizedOrder>;

        if (userId) {
            assignations = await this.rpcClient.assignation.retrieveAssignationsWithAnonymizedOrder({
                driverIds: [userId],
                departureAtFrom: firstDayOfMonth,
                departureAtTo: lastDayOfMonth,
                statusIn: [AssignationStatus.Accepted, AssignationStatus.Pending],
            });
        } else {
            assignations = await this.rpcClient.assignation.retrieveMyAssignationWithAnonymizedOrder({
                departureAtFrom: firstDayOfMonth,
                departureAtTo: lastDayOfMonth,
                statusIn: [AssignationStatus.Accepted, AssignationStatus.Pending],
            });
        }

        const locations = await this.rpcClient.content.retrieveLocations({
            ids: assignations.reduce((ids, ao) => {
                ids.push(ao.order.originLocationId, ao.order.destinationLocationId);
                return ids;
            }, [] as string[]),
        });

        assignations.forEach(async (a: AssignationWithAnonymizedOrder) => {
            const assignation = {} as AnonymizedOrderWithLocations;

            assignation.order = a.order;
            assignation.origin = locations.find(
                (l: Location) => l._id === assignation.order.originLocationId,
            ) as Location;
            assignation.destination = locations.find(
                (l: Location) => l._id === assignation.order.destinationLocationId,
            ) as Location;

            const index = new Date(assignation.order.departureAt).getUTCDate() - 1;

            if (daysAssignations[index] == undefined) {
                daysAssignations[index] = [] as AnonymizedOrderWithLocations[];
            }

            daysAssignations[index].push(assignation);
        });

        return daysAssignations;
    }

    public async setUnavailabilitiesForWholeMonth(userId: string, year: number, month: number): Promise<void> {
        return this.rpcClient.unavailability.setUnavailabilitiesForWholeMonth(userId, year, month);
    }

    public async removeUnavailabilitiesForWholeMonth(userId: string, year: number, month: number): Promise<void> {
        return this.rpcClient.unavailability.removeUnavailabilitiesForWholeMonth(userId, year, month);
    }

    public async retrieveCanUpdateAvailability(): Promise<boolean> {
        return this.rpcClient.unavailability.retrieveCanUpdateAvailability();
    }
}
