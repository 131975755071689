/* eslint-disable eqeqeq */
import { Exclude, Expose, Transform } from "class-transformer";
import { IsArray, IsBoolean, IsDate, IsNumber, IsOptional, IsString } from "class-validator";

import type { Route } from "../models/Route";

import { SortingPaginationOptions } from "./SortingPaginationOptions";

/**
 * Specifies the pricing region option for a route:
 * - PRICING_REGION_ID_PROVIDED - search for routes that have a pricing region with provided ids assigned
 * - NO_PRICING_REGION_ASSIGNED - search for routes that have no pricing region assigned
 * - NO_PRICING_REGION_ASSIGNED_ON_PURPOSE - search for routes that have no pricing region assigned on purpose (by note mechanism)
 */
export enum PricingRegionOption {
    PRICING_REGION_ID_PROVIDED = "PRICING_REGION_ID_PROVIDED",
    NO_PRICING_REGION_ASSIGNED = "NO_PRICING_REGION_ASSIGNED",
    NO_PRICING_REGION_ASSIGNED_ON_PURPOSE = "NO_PRICING_REGION_ASSIGNED_ON_PURPOSE",
}

@Exclude()
export class RetrieveRoutesOptions extends SortingPaginationOptions<Route> {
    @IsArray()
    @Expose()
    @Transform((value) => (value == undefined ? undefined : (value as Array<typeof value>).map((item) => String(item))))
    public originLocationIds?: Array<string>;

    @IsArray()
    @Expose()
    @Transform((value) => (value == undefined ? undefined : (value as Array<typeof value>).map((item) => String(item))))
    public destinationLocationIds?: Array<string>;

    @IsArray()
    @Expose()
    @Transform((value) => (value == undefined ? undefined : (value as Array<typeof value>).map((item) => String(item))))
    public routeLocationIds?: Array<string>;

    @IsArray()
    @Expose()
    @Transform((value) => (value == undefined ? undefined : (value as Array<typeof value>).map((item) => String(item))))
    public routeIds?: Array<string>;

    @IsArray()
    @Expose()
    @Transform((value) => (value == undefined ? undefined : (value as Array<typeof value>).map((item) => String(item))))
    public routeMachineNames?: Array<string>;

    @IsArray()
    @Expose()
    @Transform((value) => (value == undefined ? undefined : (value as Array<typeof value>).map((item) => String(item))))
    public notInRouteIds?: Array<string>;

    @IsBoolean()
    @Expose()
    public isBidirectional?: boolean = false;

    @IsBoolean()
    @Expose()
    public retrieveBothDirections?: boolean = false;

    @IsBoolean()
    @IsOptional()
    @Expose()
    public isLive?: boolean;

    @IsBoolean()
    @IsOptional()
    @Expose()
    public isAirportTransfer?: boolean;

    @IsBoolean()
    @IsOptional()
    @Expose()
    public hasAvailabilitySet?: boolean;

    @IsBoolean()
    @Expose()
    public hasCampaigns?: boolean;

    @IsBoolean()
    @Expose()
    public hasLuxuryPricingEnabled?: boolean;

    @IsBoolean()
    @Expose()
    public hasLitePricingEnabled?: boolean;

    @IsArray()
    @Expose()
    @Transform((value) => (value == undefined ? undefined : (value as Array<typeof value>).map((item) => String(item))))
    public pricingCountryIds?: Array<string>;

    /**
     * Specifies the pricing region option for a route:
     * - PRICING_REGION_ID_PROVIDED - search for routes that have a pricing region with provided ids assigned
     * - NO_PRICING_REGION_ASSIGNED - search for routes that have no pricing region assigned
     * - NO_PRICING_REGION_ASSIGNED_ON_PURPOSE - search for routes that have no pricing region assigned on purpose (by note mechanism)
     *
     * When using PRICING_REGION_ID_PROVIDED, you must specify the pricing region ids in the pricingRegionIds property.
     *
     * @example
     * { pricingRegionOption: "PRICING_REGION_ID_PROVIDED", pricingRegionIds: ["some-region-id"] } // returns routes with some-region-id pricing region
     * { pricingRegionOption: "NO_PRICING_REGION_ASSIGNED" } // returns routes with no pricing region assigned
     * { pricingRegionOption: "NO_PRICING_REGION_ASSIGNED_ON_PURPOSE" } // returns routes with no pricing region assigned on purpose
     */
    @IsOptional()
    @Expose()
    public pricingRegionOption?: PricingRegionOption;

    @IsArray()
    @Expose()
    @Transform((value) => (value == undefined ? undefined : (value as Array<typeof value>).map((item) => String(item))))
    public pricingRegionIds?: Array<string>;

    @IsBoolean()
    @Expose()
    public hasPublicTransportDuration?: boolean;

    @IsNumber()
    @Expose()
    public randomCount?: number;

    @IsString()
    @Expose()
    public adwordsCustomerId?: string;

    @IsOptional()
    @IsBoolean()
    @Expose()
    public includeOriginDestination?: boolean;

    @IsOptional()
    @IsBoolean()
    @Expose()
    public includePricingCountry?: boolean;

    @IsOptional()
    @Expose()
    public project?: { [name: string]: number };

    @IsDate()
    @Expose()
    @Transform((value) => (value == undefined ? undefined : new Date(value)))
    public pricesNotRecalculatedSince?: Date;

    @IsDate()
    @Expose()
    @Transform((value) => (value == undefined ? undefined : new Date(value)))
    public availableFrom?: Date;

    @Expose()
    @IsArray()
    @IsOptional()
    public tagIds?: string[];
}
