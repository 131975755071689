import { CountryGroup } from "@legacy/domain/CountryGroup";

export const CountryGroupsStructure = {
    [CountryGroup.Europe]: {
        [CountryGroup["Northern Europe"]]: undefined,
        [CountryGroup["Southern Europe"]]: undefined,
        [CountryGroup["Western Europe"]]: undefined,
        [CountryGroup["Eastern Europe"]]: undefined,
    },
    [CountryGroup.Africa]: {
        [CountryGroup.Morocco]: undefined,
        [CountryGroup.Egypt]: undefined,
        [CountryGroup["Northern Africa"]]: undefined,
        [CountryGroup["Southern Africa"]]: undefined,
        [CountryGroup["Western Africa"]]: undefined,
        [CountryGroup["Eastern Africa"]]: undefined,
    },
    [CountryGroup["SE Asia"]]: undefined,
    [CountryGroup.LATAM]: {
        [CountryGroup.Mexico]: undefined,
        [CountryGroup["Costa Rica"]]: undefined,
        [CountryGroup["Central America"]]: undefined,
        [CountryGroup["Northern S. America"]]: undefined,
        [CountryGroup["Southern S. America"]]: undefined,
    },
    [CountryGroup["Middle East"]]: undefined,
    [CountryGroup["North America"]]: undefined,
    [CountryGroup.Oceania]: undefined,
    [CountryGroup.Other]: undefined,
    [CountryGroup.All]: undefined,
};
