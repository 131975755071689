import type { UserJWT } from "@daytrip/legacy-models";
import { plainToClass } from "class-transformer";
import { validate as validatorValidate, validateOrReject, ValidatorOptions } from "class-validator";
import type { ValidationError } from "class-validator";
import { injectable } from "inversify";

import { RejectionType } from "../domain/RejectionType";
import type { User } from "../models/User";
import { Service } from "../Service";
import { isObject } from "../utils";
import { ParameterlessConstructor } from "../utils";
import { Span } from "../utils/span.decorator";

@injectable()
export class ValidationService extends Service<User, UserJWT> {
    @Span()
    public async validate<T>(
        constructor: ParameterlessConstructor<T>,
        object: T,
        options?: ValidatorOptions,
    ): Promise<T> {
        if (!isObject(object)) {
            this.logger.error("Validation error: Not an object");
            throw Error(RejectionType.ValidationError);
        }

        const classedObject = plainToClass(constructor, object);

        const validationErrors = await validatorValidate(classedObject as Object, options);
        if (validationErrors.length > 0) {
            // TODO remove this eventually if children logging works well
            this.logger.validationError(validationErrors);
            this.__logChildErrors(validationErrors);
            throw Error(RejectionType.ValidationError);
        }

        return classedObject;
    }

    private __logChildErrors(validationErrors: ValidationError[], path = "root") {
        if (path.length > 200) {
            this.logger.warn({
                message: "possible circular dependency",
                payload: { path },
            });
            return;
        }

        validationErrors.forEach((errors) => {
            this.logger.error({
                message: "validation error",
                payload: {
                    path,
                    property: errors.property,
                    constraints: errors.constraints,
                    children: errors.children?.length,
                    value: typeof errors.value,
                },
            });
            if (errors.children?.length) {
                this.__logChildErrors(errors.children, `${path}.${errors.property}`);
            }
        });
    }
}

export const validateWithoutLogging = async <T>(
    constructor: ParameterlessConstructor<T>,
    object: T,
    options: ValidatorOptions,
) => {
    if (!isObject(object)) {
        throw Error(RejectionType.ValidationError);
    }
    const classedObject = plainToClass(constructor, object);
    return validateOrReject(classedObject as Object, options);
};
