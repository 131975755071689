import type { VehicleTypePriceFee } from "@daytrip/legacy-models";
import { transformVehicleTypeToString } from "@daytrip/legacy-transformers";

export function describeVTPFDifference(
    originalVehicleTypesPricesFees: Array<VehicleTypePriceFee>,
    updatedVehicleTypesPricesFees: Array<VehicleTypePriceFee>,
): void | string {
    let changed = false;

    let message = "The following prices have changed:\n";

    for (const vt in updatedVehicleTypesPricesFees) {
        const newPrice = updatedVehicleTypesPricesFees[vt];
        const oldPrice = originalVehicleTypesPricesFees[vt];

        if (oldPrice && newPrice.price + newPrice.fee != oldPrice.price + oldPrice.fee) {
            changed = true;
            message += `${transformVehicleTypeToString(Number(vt))} was changed from ${
                oldPrice.price + oldPrice.fee
            } to ${newPrice.price + newPrice.fee}\n`;
        }
    }

    if (!changed) {
        return;
    }

    return message;
}
