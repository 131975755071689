import { setContext } from "@apollo/client/link/context";

import { getManagementAuthenticationToken } from "../../utils/authentication/authenticationUtils";

export const authLink = setContext((_, { headers }) => {
    const authenticationToken = getManagementAuthenticationToken();

    const headersWithAuth: Record<string, string> = {};
    if (authenticationToken) {
        headersWithAuth.Authorization = `Bearer ${authenticationToken}`;
    }

    return {
        headers: {
            ...headers,
            ...headersWithAuth,
        },
    };
});
