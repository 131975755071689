export enum PaymentMethod {
    Cash = 0, // paid in cash to driver
    Online = 1, // paid online by card
    Partner = 2, // Partner API payment
    StatementOfBooking = 3, // Travel Agency statement of booking (paid by invoice at the end of billing period)
}

export const PaymentMethods = {
    Cash: PaymentMethod.Cash,
    Online: PaymentMethod.Online,
    Partner: PaymentMethod.Partner,
    StatementOfBooking: PaymentMethod.StatementOfBooking,
};
