import { Expose } from "class-transformer";
import { IsNumber, IsString } from "class-validator";

import { SimpleDriverWithVehicles } from "./SimpleDriverWithVehicles";
import type { VehicleInfo } from "./VehicleInfo";

export class DriverInfoForAssignation {
    @Expose()
    public driver: SimpleDriverWithVehicles;

    @Expose()
    public vehicles: Array<VehicleInfo>;

    @Expose()
    @IsString()
    public managementNote: string;

    // @Expose()
    // public assignationDatesWithStatus: Array<AssignationDateWithStatus>;

    @Expose()
    @IsNumber()
    public willingToSleepoverCount: number;
}
