/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */

import {
    API_UPLOAD_BILLING_INFO_DOCUMENT_URL,
    API_UPLOAD_DRIVER_DOCUMENT_URL,
    API_UPLOAD_DRIVERS_COMPANY_DOCUMENT_URL,
    API_UPLOAD_VEHICLE_DOCUMENT_URL,
    MAX_FILE_UPLOAD_SIZE_IN_B,
} from "@daytrip/legacy-config";
import { DocumentSubject } from "@legacy/domain/DocumentSubject";
import { DynamicDocumentType } from "@legacy/domain/DocumentTypes";
import { Document } from "@legacy/models/Document";
import { DocumentType } from "@legacy/models/DocumentType";
import { isUndefinedOrNull } from "@legacy/utils";
import autobind from "autobind-decorator";
import { action, computed, observable, ObservableMap, toJS } from "mobx";

import { COOPERATION_AGREEMENT_EN_NAME } from "../components/DocumentRow/constants";

import { DocumentOperator } from "./DocumentOperator";
import { ModelOperator } from "./ModelOperator";
import { ModelOperatorOptions } from "./ModelOperatorOptions";

interface DocumentTypeOperatorOptions extends ModelOperatorOptions<DocumentType, null, null> {
    documentOperator?: DocumentOperator;
    vehicleId?: string;
    userId: string;
    isMangopayInformationsFilled?: boolean;
    onDriverDocumentUploaded?: (document: Document) => void;
    onDriversCompanyDocumentUploaded?: (document: Document) => void;
    onVehicleDocumentUploaded?: (document: Document) => void;
    onMangopayDocumentUploaded?: (document: Document) => void;
    onBillingInformationDocumentUploaded?: (document: Document) => void;
}

@autobind
export class DocumentTypeOperator extends ModelOperator<DocumentType, DocumentTypeOperatorOptions, null, null, {}> {
    @observable
    public vehicleId?: string;

    @observable
    public userId: string;

    @observable
    public isMangopayInformationsFilled?: boolean;

    @observable
    public documentOperator?: DocumentOperator;

    @action
    public onDriverDocumentUploaded: (document: Document) => void;

    @action
    public onDriversCompanyDocumentUploaded: (document: Document) => void;

    @action
    public onVehicleDocumentUploaded: (document: Document) => void;

    @action
    public onMangopayDocumentUploaded: (document: Document) => void;

    @action
    public onBillingInformationDocumentUploaded: (document: Document) => void;

    @observable
    public files?: FileList;

    @action
    public delayUpload(files: FileList) {
        this.files = files;
    }

    @observable
    public specifiedAmountOfFiles: number = 1;

    public constructor(options: DocumentTypeOperatorOptions) {
        super(options);

        this.vehicleId = options.vehicleId;
        this.userId = options.userId;
        this.isMangopayInformationsFilled = options.isMangopayInformationsFilled;
        this.documentOperator = options.documentOperator;

        this.onDriverDocumentUploaded =
            options.onDriverDocumentUploaded != undefined ? options.onDriverDocumentUploaded : () => {};
        this.onDriversCompanyDocumentUploaded =
            options.onDriversCompanyDocumentUploaded != undefined ? options.onDriversCompanyDocumentUploaded : () => {};
        this.onVehicleDocumentUploaded =
            options.onVehicleDocumentUploaded != undefined ? options.onVehicleDocumentUploaded : () => {};
        this.onMangopayDocumentUploaded =
            options.onMangopayDocumentUploaded != undefined ? options.onMangopayDocumentUploaded : () => {};
        this.onBillingInformationDocumentUploaded =
            options.onBillingInformationDocumentUploaded != undefined
                ? options.onBillingInformationDocumentUploaded
                : () => {};

        if (!isUndefinedOrNull(this.documentOperator)) {
            this.setSpecifiedAmountOfFiles(
                this.documentOperator.m.fileIds.length > 1 ? this.documentOperator.m.fileIds.length : 1,
            );
            this.resetPreparedFiles();
        }
    }

    @action
    public resetPreparedFiles() {
        if (!isUndefinedOrNull(this.documentOperator)) {
            this.preparedFiles.clear();
        }
    }

    @observable
    public setSpecifiedAmountOfFiles(value: number) {
        this.specifiedAmountOfFiles = value;
    }

    @observable
    public isDocumentUploading = false;

    @computed
    public get isCooperationAgreement(): boolean {
        return (
            this.m.englishName === COOPERATION_AGREEMENT_EN_NAME ||
            this.m.dynamicDocumentType === DynamicDocumentType.COOPERATION_AGREEMENT
        );
    }

    @action
    public async uploadVehicleDocument(rawFiles: FileList | Array<File>, indexes?: Array<string>) {
        let files: Array<File> = [];

        if (rawFiles instanceof FileList) {
            for (let i = 0; i < rawFiles.length; i++) {
                const file = rawFiles.item(i);
                if (file) {
                    files.push();
                }
            }
        } else {
            files = toJS(rawFiles);
        }

        for (const i in rawFiles) {
            if (isUndefinedOrNull(rawFiles[i]) && !isUndefinedOrNull(indexes)) {
                indexes.splice(indexes.indexOf(i), 1);
            }
        }

        for (const file in files) {
            if (files[file] instanceof File && !this.validateFile(files[file])) {
                return;
            }
        }

        this.isDocumentUploading = true;

        const documentFormData = new FormData();

        if (!isUndefinedOrNull(this.m.numberOfRequiredFiles) && files.length != this.m.numberOfRequiredFiles) {
            alert(
                `You must upload exactly ${this.m.numberOfRequiredFiles} file${
                    this.m.numberOfRequiredFiles > 1 ? "s" : ""
                }!\n${this.m.description}`,
            );
            this.isDocumentUploading = false;
            return;
        }

        for (const index in files) {
            if (!isUndefinedOrNull(files[index]) && files[index].size > MAX_FILE_UPLOAD_SIZE_IN_B) {
                alert("The file can\u0027t be larger than 5 MB.");
                this.isDocumentUploading = false;
                return;
            }
            documentFormData.append("files", files[index]);
        }

        if (!isUndefinedOrNull(indexes)) {
            for (const i in indexes) {
                documentFormData.append("indexes", indexes[i]);
            }
        }

        documentFormData.append("vehicleId", this.vehicleId as string);
        documentFormData.append("documentTypeId", this.m._id);

        await this.rpcClient.authentication.isUserAuthenticated();
        const request = new XMLHttpRequest();
        request.open("POST", API_UPLOAD_VEHICLE_DOCUMENT_URL, true);
        request.onload = async () => {
            if (request.status === 200 || request.status === 201) {
                this.documentOperator = new DocumentOperator({
                    modelConstructor: Document,
                    modules: null,
                    data: null,
                });
                this.documentOperator.m.fileIds = JSON.parse(request.response);

                this.onVehicleDocumentUploaded(this.documentOperator.m);
            } else {
                alert(
                    "Can\u0027t upload. Please try again or contact administrator.\n\nIf you're using Os X Photos app, you need to export the photo before uploading it.",
                );
            }
            this.isDocumentUploading = false;
        };
        request.setRequestHeader("Authorization", `Bearer ${this.authenticationStore.authenticationToken}`);

        request.send(documentFormData);
    }

    @observable
    public preparedFiles: ObservableMap<string, File> = observable.map({});

    public validateFile(file: File): boolean {
        const a = file.name.split(".");
        if (
            !(
                a[a.length - 1].toLowerCase() == "pdf" ||
                a[a.length - 1].toLowerCase() == "png" ||
                a[a.length - 1].toLowerCase() == "jpeg" ||
                a[a.length - 1].toLowerCase() == "jpg"
            )
        ) {
            alert("This file format is not supported. \nPlease upload PDF, PNG or JPG.");
            return false;
        }
        return true;
    }

    @action
    public async setPreparedFile(files: FileList, index: number, billingInfoId?: string) {
        for (const file in files) {
            if (files[file] instanceof File && !this.validateFile(files[file])) {
                return;
            }
        }

        this.preparedFiles.set(String(index), files.item(0)!);

        let args: Array<any> = [];

        if (
            isUndefinedOrNull(this.m.numberOfRequiredFiles) &&
            Array.from(this.preparedFiles.values()).length === this.specifiedAmountOfFiles
        ) {
            args = [Array.from(this.preparedFiles.values()), Array.from(this.preparedFiles.keys())];
        } else if (
            !isUndefinedOrNull(this.m.numberOfRequiredFiles) &&
            Array.from(this.preparedFiles.values()).length === this.m.numberOfRequiredFiles
        ) {
            args = [Array.from(this.preparedFiles.values()), []];
        }

        if (args.length > 0) {
            // eslint-disable-next-line default-case
            switch (this.m.subject) {
                case DocumentSubject.Driver:
                case DocumentSubject.CompanyDriver:
                    await this.uploadDriverDocument.apply(this, args);
                    break;
                case DocumentSubject.DriversCompany:
                    await this.uploadDriversCompanyDocument.apply(this, args);
                    break;
                case DocumentSubject.Vehicle:
                    await this.uploadVehicleDocument.apply(this, args);
                    break;
                case DocumentSubject.NaturalPerson:
                case DocumentSubject.LegalPerson:
                case DocumentSubject.Soletrader:
                    args.push(billingInfoId);
                    await this.uploadBillingInformationDocument.apply(this, args);
                    break;
            }
        }
    }

    @action
    public async removeFile(index: number) {
        if (isUndefinedOrNull(this.documentOperator)) {
            return;
        }

        await this.rpcClient.driver.removeDriverDocument(this.userId, this.m._id, index);

        this.documentOperator.edit((m) => m.fileIds.splice(index, 1));
        this.setSpecifiedAmountOfFiles(this.specifiedAmountOfFiles - 1);
    }

    @action
    public async removeFiles() {
        if (isUndefinedOrNull(this.documentOperator)) {
            return;
        }

        for (let i = 0; i < this.specifiedAmountOfFiles; i++) {
            await this.rpcClient.driver.removeDriverDocument(this.userId, this.m._id, i);

            this.documentOperator.edit((m) => m.fileIds.splice(0, 1));
        }

        this.specifiedAmountOfFiles = 0;
    }

    @action
    public async uploadDriverDocument(rawFiles: FileList | Array<File>, indexes?: Array<string>) {
        let files: Array<File> = [];

        if (rawFiles instanceof FileList) {
            for (let i = 0; i < rawFiles.length; i++) {
                const file = rawFiles.item(i);
                if (file) {
                    files.push(file);
                }
            }
        } else {
            files = toJS(rawFiles);
        }

        for (const i in rawFiles) {
            if (isUndefinedOrNull(rawFiles[i]) && !isUndefinedOrNull(indexes)) {
                indexes.splice(indexes.indexOf(i), 1);
            }
        }

        for (const file in files) {
            if (files[file] instanceof File && !this.validateFile(files[file])) {
                return;
            }
        }

        this.isDocumentUploading = true;

        const documentFormData = new FormData();

        if (!isUndefinedOrNull(this.m.numberOfRequiredFiles) && files.length != this.m.numberOfRequiredFiles) {
            alert(
                `You must upload exactly ${this.m.numberOfRequiredFiles} file${
                    this.m.numberOfRequiredFiles > 1 ? "s" : ""
                }!\n${this.m.description}`,
            );
            this.isDocumentUploading = false;
            return;
        }

        for (const index in files) {
            if (!isUndefinedOrNull(files[index]) && files[index].size > MAX_FILE_UPLOAD_SIZE_IN_B) {
                alert("The file can\u0027t be larger than 5 MB.");
                this.isDocumentUploading = false;
                return;
            }
            documentFormData.append("files", files[index]);
        }

        if (!isUndefinedOrNull(indexes)) {
            indexes.forEach((index) => {
                documentFormData.append("indexes", index);
            });
        }

        documentFormData.append("userId", this.userId);
        documentFormData.append("documentTypeId", this.m._id);

        await this.rpcClient.authentication.isUserAuthenticated();
        const request = new XMLHttpRequest();
        request.open("POST", API_UPLOAD_DRIVER_DOCUMENT_URL, true);
        request.onload = async () => {
            if (request.status === 200 || request.status === 201) {
                this.documentOperator = new DocumentOperator({
                    modelConstructor: Document,
                    modules: null,
                    data: null,
                });
                this.documentOperator.m.fileIds = JSON.parse(request.response);
                this.specifiedAmountOfFiles = this.documentOperator.m.fileIds.length;

                this.onDriverDocumentUploaded(this.documentOperator.m);
            } else {
                alert(
                    "Can\u0027t upload. Please try again or contact administrator.\n\nIf you're using Os X Photos app, you need to export the photo before uploading it.",
                );
            }
            this.isDocumentUploading = false;
            this.resetPreparedFiles();
        };
        request.setRequestHeader("Authorization", `Bearer ${this.authenticationStore.authenticationToken}`);
        request.send(documentFormData);
    }

    @action
    public async uploadDriversCompanyDocument(rawFiles: FileList | Array<File>, indexes?: Array<string>) {
        let files: Array<File> = [];

        if (rawFiles instanceof FileList) {
            for (let i = 0; i < rawFiles.length; i++) {
                const file = rawFiles.item(i);
                if (file) {
                    files.push(file);
                }
            }
        } else {
            files = toJS(rawFiles);
        }

        for (const i in rawFiles) {
            if (isUndefinedOrNull(rawFiles[i]) && !isUndefinedOrNull(indexes)) {
                indexes.splice(indexes.indexOf(i), 1);
            }
        }

        for (const file in files) {
            if (files[file] instanceof File && !this.validateFile(files[file])) {
                return;
            }
        }

        this.isDocumentUploading = true;

        const documentFormData = new FormData();

        if (!isUndefinedOrNull(this.m.numberOfRequiredFiles) && files.length != this.m.numberOfRequiredFiles) {
            alert(
                `You must upload exactly ${this.m.numberOfRequiredFiles} file${
                    this.m.numberOfRequiredFiles > 1 ? "s" : ""
                }!\n${this.m.description}`,
            );
            this.isDocumentUploading = false;
            return;
        }

        for (const index in files) {
            if (!isUndefinedOrNull(files[index]) && files[index].size > MAX_FILE_UPLOAD_SIZE_IN_B) {
                alert("The file can\u0027t be larger than 5 MB.");
                this.isDocumentUploading = false;
                return;
            }
            documentFormData.append("files", files[index]);
        }

        // rawFiles = (rawFiles as Array<any>).filter((f) => !isUndefinedOrNull(f) && f != null);

        if (!isUndefinedOrNull(indexes)) {
            for (const i in indexes) {
                documentFormData.append("indexes", indexes[i]);
            }
        }

        documentFormData.append("userId", this.userId);
        documentFormData.append("documentTypeId", this.m._id);

        await this.rpcClient.authentication.isUserAuthenticated();
        const request = new XMLHttpRequest();
        request.open("POST", API_UPLOAD_DRIVERS_COMPANY_DOCUMENT_URL, true);

        request.onload = async () => {
            if (request.status === 200 || request.status === 201) {
                this.documentOperator = new DocumentOperator({
                    modelConstructor: Document,
                    modules: null,
                    data: null,
                });
                this.documentOperator.m.fileIds = JSON.parse(request.response);
                this.specifiedAmountOfFiles = this.documentOperator.m.fileIds.length;

                this.onDriversCompanyDocumentUploaded(this.documentOperator.m);
            } else {
                alert(
                    "Can\u0027t upload. Please try again or contact administrator.\n\nIf you're using Os X Photos app, you need to export the photo before uploading it.",
                );
            }
            this.isDocumentUploading = false;
            this.resetPreparedFiles();
        };
        request.setRequestHeader("authorization", `Bearer ${this.authenticationStore.authenticationToken}`);
        request.send(documentFormData);
    }

    @action
    public async uploadBillingInformationDocument(files: FileList, indexes: Array<string>, billingInfoId: string) {
        const documentFormData = new FormData();

        this.isDocumentUploading = true;

        // tslint:disable-next-line:prefer-for-of
        for (let index = 0; index < files.length; index++) {
            if (files[index].size > 7 * 1024 * 1024) {
                alert("The file can\u0027t be larger than 7 MB.");
                this.isDocumentUploading = false;
                return;
            }
            documentFormData.append("files", files[index]);
        }

        if (!isUndefinedOrNull(indexes)) {
            for (const i in indexes) {
                documentFormData.append("indexes", indexes[i]);
            }
        }

        documentFormData.append("userId", this.userId);
        documentFormData.append("billingInfoId", billingInfoId);
        documentFormData.append("documentTypeId", this.m._id);

        await this.rpcClient.authentication.isUserAuthenticated();
        const request = new XMLHttpRequest();
        request.open("POST", API_UPLOAD_BILLING_INFO_DOCUMENT_URL, true);
        request.onload = async () => {
            if (request.status === 200 || request.status === 201) {
                this.documentOperator = new DocumentOperator({
                    modelConstructor: Document,
                    modules: null,
                    data: null,
                });

                const response = JSON.parse(request.response);

                this.documentOperator.m.fileIds = response;

                await this.onBillingInformationDocumentUploaded(this.documentOperator.m);

                this.isDocumentUploading = false;
                return response.mangopayLegalKycDocumentId;
            }

            alert(
                "Can\u0027t upload. Please try again or contact administrator.\n\nIf you're using Os X Photos app, you need to export the photo before uploading it.",
            );
            this.isDocumentUploading = false;
        };
        request.setRequestHeader("Authorization", `Bearer ${this.authenticationStore.authenticationToken}`);
        request.send(documentFormData);
    }
}
