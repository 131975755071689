import { Expose, Type } from "class-transformer";
import { IsDefined, IsEnum, IsInt } from "class-validator";

import { Payout } from "../models/Payout";

import { Currency } from "./Currency";
import { SimpleUser } from "./SimpleUser";

export class PayoutForPayoutsPage {
    @Expose()
    @IsDefined()
    @Type(() => SimpleUser)
    public simpleUser: SimpleUser;

    @Expose()
    @IsDefined()
    @Type(() => Payout)
    public payout: Payout;

    @IsInt()
    @IsDefined()
    @Expose()
    public assignationPayoutsAmount: number;

    @IsInt()
    @IsDefined()
    @Expose()
    public debtsAmount: number;

    @IsEnum(Currency)
    @IsDefined()
    @Expose()
    public currency: Currency;

    @IsInt()
    @IsDefined()
    @Expose()
    public baselessAmount: number;
}
