import { GoogleRouteCampaign } from "@legacy/domain/GoogleRouteCampaign";
import { action } from "mobx";

import { getRpcClient } from "../rpc-browser-sdk";

export class RouteGoogleCampaignOperator {
    protected rpcClient = getRpcClient();

    public routeGoogleCampaign: GoogleRouteCampaign;

    public editedRouteGoogleCampaign?: GoogleRouteCampaign;

    public constructor(routeGoogleCampain: GoogleRouteCampaign) {
        this.routeGoogleCampaign = routeGoogleCampain;
    }

    public isEdited(): this is this & { editedRouteGoogleCampaign: GoogleRouteCampaign } {
        return !!this.editedRouteGoogleCampaign;
    }

    @action.bound
    public onOriginNameChange(value: string) {
        if (!this.editedRouteGoogleCampaign) return;
        this.editedRouteGoogleCampaign.originLocationName = value;
    }
}
