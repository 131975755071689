let typeGraphql: any;

if (global.DEPLOYMENT_NODE === true) {
    // tslint:disable-next-line:no-var-requires
    typeGraphql = require("type-graphql");
}

// Decorators
// Class
export function GObjectType(...args: any[]) {
    if (global.DEPLOYMENT_NODE === true) {
        return typeGraphql.ObjectType.apply(typeGraphql, args);
    }

    return () => {};
}

// Class param method
export function GField(returnTypeFuncOrOptions?: any, options?: any) {
    if (global.DEPLOYMENT_NODE === true) {
        return typeGraphql.Field(returnTypeFuncOrOptions, options);
    }

    return () => {};
}

export function GInputType(options?: any) {
    if (global.DEPLOYMENT_NODE === true) {
        return typeGraphql.InputType(options);
    }

    return () => {};
}

export function GArgsType(options?: any) {
    if (global.DEPLOYMENT_NODE === true) {
        return typeGraphql.ArgsType(options);
    }

    return () => {};
}

export function GAuthorized(options?: any) {
    if (global.DEPLOYMENT_NODE === true) {
        return typeGraphql.Authorized(options);
    }

    return () => {};
}

// Scalar types
let GInt = {};
let GID = {};
let GFloat = {};

if (global.DEPLOYMENT_NODE === true) {
    GInt = typeGraphql.Int;
    GID = typeGraphql.ID;
    GFloat = typeGraphql.Float;
}
export { GInt, GID, GFloat };
