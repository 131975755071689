export enum PaymentType {
    Mangopay = 0,
    Paypal = 1,
    Other = 2,
    Cash = 3,
    BankTransaction = 4,
    Checkout = 5,
    Stripe = 6,
    StatementOfBooking = 7, // Travel Agency statement of booking (paid by invoice at the end of billing period)
}
