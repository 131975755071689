import type { TaggableEntityType } from "@daytrip/legacy-enums";
import type { Tag, VehicleTypePriceFee } from "@daytrip/legacy-models";
import { transformTimestampStringToDate } from "@daytrip/legacy-transformers";
import { GoogleCampaignSettingType } from "@legacy/domain/GoogleCampaignSettingType";
import type { GoogleRouteCampaign } from "@legacy/domain/GoogleRouteCampaign";
import type { SimpleCountry } from "@legacy/domain/SimpleCountry";
import type { SimpleLocation } from "@legacy/domain/SimpleLocation";
import { VehicleType } from "@legacy/domain/VehicleType";
import type { Region } from "@legacy/models/Region";
import { Route } from "@legacy/models/Route";
import type { RouteLocation } from "@legacy/models/RouteLocation";
import { LocationGeoPolygonOptions } from "@legacy/options/LocationGeoPolygonOptions";
import { PricingRegionOption } from "@legacy/options/RetrieveRoutesOptions";
import type { RetrieveRoutesWithGeoFilterOptions } from "@legacy/options/RetrieveRoutesWithGeoFilterOptions";
import autobind from "autobind-decorator";
import { action, observable, computed, toJS } from "mobx";
import type { Option } from "react-select-legacy";

import type { NotificationsStore } from "../../components/Notifications/NotificationsStore";
import type { StoreManager } from "../../container";
import { container, stores } from "../../container";
import type { RouteGoogleCampaignOperator } from "../../operators/RouteGoogleCampaignOperator";
import { PageStore } from "../../stores/PageStore";
import { createRouteGoogleCampaignOperator } from "../../utils/googleCampaigns/createRouteGoogleCampaignOperator";
import { fillCampaignsCreateOptions } from "../../utils/googleCampaigns/fillCampaignsCreateOptions";
import { validateAds } from "../../utils/googleCampaigns/validateAds";

import type { RoutesPageRouter } from "./RoutesPageRouter";

export enum SelectedRoutesActions {
    setReady,
    setLive,
    createCampaigns,
    setAvailability,
}

export type SelectedRouteNames = { [key: string]: string };

@autobind
export class RoutesPageStore extends PageStore<RoutesPageRouter, {}> {
    @observable
    public routes?: Route[];

    @observable
    public routesCount: number = 0;

    @observable
    public simpleCountries?: SimpleCountry[];

    public regions?: Region[];

    @observable
    public adwordsCustomerIds?: string[];

    public campaignsToCreate: Array<{
        campaigns: Array<GoogleRouteCampaign>;
        campaignSettingType: GoogleCampaignSettingType;
    }> = [];

    @observable
    public availableFrom: string | undefined;

    @observable
    public isFetchingRoutes: boolean = true;

    @action
    public async onFetchData() {
        await this.fetchContent();
        await this.fetchRoutes();

        this.adwordsCustomerIds = await this.rpcClient.googleCampaign.retrieveAdwordsCustomerIds();
    }

    @action
    public async fetchLocations(searchString: string): Promise<{ options: Array<Option> }> {
        let options: Array<Option> = [];

        if (searchString && searchString.length > 2) {
            const locations = await this.rpcClient.content.retrieveSimpleLocations({
                isDestination: true,
                searchString,
            });

            options = locations.map((location) => ({
                value: location.locationId,
                label: location.name + (location.countryName ? `, ${location.countryName}` : ""),
            }));
        }

        return { options };
    }

    @action
    public async fetchTags(
        searchString: string,
        applicableFor: TaggableEntityType[],
    ): Promise<{ options: Array<Option> }> {
        const tags: Tag[] = await this.rpcClient.content.searchTags({
            applicableFor,
            searchString,
        });

        const options = tags.map((tag) => ({
            value: tag._id,
            label: tag.title,
        }));

        return { options };
    }

    @action
    public async fetchContent() {
        this.simpleCountries = await this.rpcClient.content.retrieveSimpleCountries({});
    }

    @action
    public async fetchRoutes(): Promise<void> {
        this.isFetchingRoutes = true;

        const originLocationIds =
            this.pageRouter.originLocationIds && this.pageRouter.destinationLocationIds
                ? [...this.pageRouter.originLocationIds, ...this.pageRouter.destinationLocationIds]
                : this.pageRouter.originLocationIds;
        const destinationLocationIds =
            this.pageRouter.destinationLocationIds && this.pageRouter.originLocationIds
                ? [...this.pageRouter.destinationLocationIds, ...this.pageRouter.originLocationIds]
                : this.pageRouter.destinationLocationIds;

        if (this.pageRouter.pricingCountryId) {
            this.regions = await this.fetchRegionsForCountry(this.pageRouter.pricingCountryId);
        } else {
            this.regions = [];
        }

        const skip = this.pageRouter.skip === 1 ? 0 : this.pageRouter.skip - 1;
        const options: RetrieveRoutesWithGeoFilterOptions = {
            skip,
            limit: this.pageRouter.limit - skip,
            isLive: this.pageRouter.isLive,
            isAirportTransfer: this.pageRouter.isAirportTransfer,
            hasAvailabilitySet: this.pageRouter.hasAvailabilitySet,
            sortBy: this.pageRouter.sortBy as keyof Route,
            hasCampaigns: this.pageRouter.hasCampaigns,
            sortDirection: this.pageRouter.sortDirection,
            originLocationIds,
            destinationLocationIds,
            retrieveBothDirections: true,
            pricingCountryIds: this.pageRouter.pricingCountryId ? [this.pageRouter.pricingCountryId] : undefined,
            pricingRegionOption: this.pricingRegionOption,
            pricingRegionIds:
                this.pageRouter.pricingRegionId &&
                this.pricingRegionOption === PricingRegionOption.PRICING_REGION_ID_PROVIDED
                    ? [this.pageRouter.pricingRegionId]
                    : undefined,
            hasLuxuryPricingEnabled: this.pageRouter.hasLuxuryPricing,
            hasLitePricingEnabled: this.pageRouter.hasLitePricing,
            adwordsCustomerId: this.pageRouter.adwordsCustomerId,
            pricesNotRecalculatedSince: transformTimestampStringToDate(this.pageRouter.pricesNotRecalculatedSince),
            availableFrom: transformTimestampStringToDate(this.pageRouter.availableFrom),
            geoPolygonFrom: this.locationGeoPolygonFilter?.geoPolygonFrom,
            geoPolygonTo: this.locationGeoPolygonFilter?.geoPolygonTo,
            geoPolygonDirectionSensitive: this.locationGeoPolygonFilter?.geoPolygonDirectionSensitive,
            originCountryIds: this.pageRouter.originCountryIds,
            destinationCountryIds: this.pageRouter.destinationCountryIds,
            isCrossBorder: this.pageRouter.isCrossBorder,
            isCountryDirectionSensitive: this.pageRouter.isCountryDirectionSensitive,
            tagIds: this.pageRouter.tagIds,
        };

        const { routes, totalCount } = await this.rpcClient.content.retrieveRoutesWithLocations(options);
        this.routes = routes;
        this.routesCount = totalCount;
        this.isFetchingRoutes = false;
    }

    @computed
    private get pricingRegionOption() {
        const regionId = this.pageRouter.pricingRegionId;

        switch (regionId) {
            case PricingRegionOption.NO_PRICING_REGION_ASSIGNED_ON_PURPOSE:
            case PricingRegionOption.NO_PRICING_REGION_ASSIGNED:
                return regionId;
            default:
                return regionId ? PricingRegionOption.PRICING_REGION_ID_PROVIDED : undefined;
        }
    }

    @action
    public fetchRegionsForCountry(countryId: string): Promise<Region[]> {
        return this.rpcClient.content.retrieveRegions({ countryIds: [countryId] });
    }

    public isDataFetched(): this is RoutesPageStore & RoutesPageStoreDataFetched {
        return !!(this.simpleCountries && this.adwordsCustomerIds);
    }

    @observable
    public isPricesRecalculating = false;

    @observable
    public recalculateProgress: number = 0;

    @observable
    public recalculateTotal: number = 0;

    @observable
    public recalculateFailed: number = 0;

    @action
    public async recalculateRoutesPrices() {
        this.isPricesRecalculating = true;

        const selectedRoutes = this.routes?.filter((route) => this.selectedRouteIds.includes(route._id)) ?? [];

        this.recalculateProgress = 0;
        this.recalculateTotal = selectedRoutes.length;
        this.recalculateFailed = 0;

        await asyncForEach(selectedRoutes, async (r: Route) => {
            const route = Object.assign(new Route(), r);
            route.vehicleTypesPricesFees = route.vehicleTypesPricesFees.filter(
                (vtpf: VehicleTypePriceFee) => vtpf.isManuallyModified,
            );
            route.locations = route.locations.map((l: RouteLocation) => {
                // eslint-disable-next-line no-param-reassign
                l.vehicleTypesPricesFees = l.vehicleTypesPricesFees.filter((vtpf) => vtpf.isManuallyModified);
                return l;
            });

            try {
                const updatedRoute = await this.rpcClient.content.calculateRoutePrices(route, false);
                updatedRoute.pricesRecalculatedAt = new Date();

                // update only vehicle type price fee available for this route
                const vehicleTypesToBeUpdated = r.vehicleTypesPricesFees.map((vtpf) => vtpf.vehicleType);
                updatedRoute.vehicleTypesPricesFees = updatedRoute.vehicleTypesPricesFees.filter(
                    (updatedVtpf) =>
                        vehicleTypesToBeUpdated.find(
                            (vehicleTypeToBeUpdated) => vehicleTypeToBeUpdated === updatedVtpf.vehicleType,
                        ) !== undefined,
                );
                await this.rpcClient.content.updateRoute(updatedRoute._id, updatedRoute);
            } catch (e: any) {
                this.recalculateFailed += 1;
                this.notUpdatedSelectedRouteIds.push(route._id);
            }

            this.recalculateProgress += 1;
        });

        this.openUpdateModal();
        this.isPricesRecalculating = false;
    }

    @observable
    public isAdCustomizerExportRequested = false;

    @action
    public requestAdCustomizerExport(): void {
        if (!this.adwordsCustomerIds) {
            // eslint-disable-next-line no-alert
            alert("Unable to request Ad Customizer Export");
            return;
        }

        this.adwordsCustomerIds.forEach((adwordsCustomerId) =>
            this.rpcClient.export.requestAdCustomizerExport(adwordsCustomerId),
        );
        this.isAdCustomizerExportRequested = true;

        setTimeout(() => {
            this.isAdCustomizerExportRequested = false;
        }, 3000);
    }

    // multiple routes selection and actions

    @observable
    public isMultipleSelectRevealed = false;

    @action
    public toggleMultipleSelect(): void {
        this.isMultipleSelectRevealed = !this.isMultipleSelectRevealed;
        this.selectedRouteIds = [];
    }

    @observable
    public selectedRouteIds: Array<string> = [];

    @computed
    public get selectedRoutesCount(): number {
        return this.selectedRouteIds.length;
    }

    @computed
    public get selectedRouteNames(): SelectedRouteNames {
        return this.routes
            ?.filter((route) => this.selectedRouteIds?.includes(route._id))
            ?.reduce((acc, route) => {
                const name = `${route?.originLocation?.machineName}-to-${route?.destinationLocation?.machineName} `;
                return {
                    ...acc,
                    [route._id]: name,
                };
            }, {});
    }

    @computed
    public get countryOptions() {
        return (
            this.simpleCountries
                ?.map((country) => ({ value: country._id, label: country.englishName }))
                .sort((a, b) => (a.label > b.label ? 1 : -1)) ?? []
        );
    }

    @observable
    public notUpdatedSelectedRouteIds: Array<string> = [];

    @computed
    public get notUpdatedRoutes(): Array<Route> {
        if (this.routes) {
            return this.routes.filter((route) => this.notUpdatedSelectedRouteIds.find((id) => id === route._id));
        }
        return [];
    }

    @computed
    public get notUpdatedRoutesCount(): number {
        return this.notUpdatedSelectedRouteIds.length;
    }

    @observable
    public isUpdateModalVisible = false;

    @observable
    public isSetCampaignsTriggered = false;

    @observable
    public isLocationGeoPolygonFilterOpen = false;

    @observable
    public isDuplicationRouteDialogOpened = false;

    @observable
    public isSetSedanLitePriceDialogOpened = false;

    @observable
    public isSetLuxurySedanPriceDialogOpened = false;

    @observable
    public locationGeoPolygonFilter: LocationGeoPolygonOptions = {};

    @action
    public toggleGeoPolygonFilterModal() {
        this.isLocationGeoPolygonFilterOpen = !this.isLocationGeoPolygonFilterOpen;
    }

    @action
    public applyGeoFilter(locationGeoFilter: LocationGeoPolygonOptions) {
        this.locationGeoPolygonFilter = locationGeoFilter;
        this.fetchRoutes();
    }

    @action
    public openUpdateModal(): void {
        this.isUpdateModalVisible = true;
        this.fetchRoutes();
    }

    @action
    public closeUpdateModal(): void {
        this.isUpdateModalVisible = false;
        this.isSetCampaignsTriggered = false;
        this.notUpdatedSelectedRouteIds = [];
    }

    @action
    public toggleSelectAllVisibleRoutes(): void {
        if (this.routes) {
            if (this.isAllVisibleRoutesSelected) {
                this.selectedRouteIds = [];
            } else {
                const selectedRoutesIds = this.routes.map((route) => route._id);
                this.selectedRouteIds = selectedRoutesIds;
            }
        }
    }

    @computed
    public get isAllVisibleRoutesSelected(): boolean {
        return this.routes?.length === this.routes?.filter((route) => this.selectedRouteIds.includes(route._id)).length;
    }

    @action
    public toggleRouteSelect(routeId: string): void {
        const exists = this.selectedRouteIds.includes(routeId);

        if (exists) {
            this.selectedRouteIds = this.selectedRouteIds.filter((selectedRouteId) => selectedRouteId !== routeId);
        } else {
            this.selectedRouteIds = [...this.selectedRouteIds, routeId];
        }
    }

    @observable
    public selectedRoutesActionInProgress?: SelectedRoutesActions = undefined;

    @action
    public async setSelectedRoutesReady(): Promise<void> {
        this.selectedRoutesActionInProgress = SelectedRoutesActions.setReady;

        const selectedRoutes = this.routes?.filter((route) => this.selectedRouteIds.includes(route._id));

        if (selectedRoutes) {
            await asyncForEach(selectedRoutes, async (route: Route) => {
                route.isReady = true;
                try {
                    await this.rpcClient.content.updateRoute(route._id, route);
                } catch (error: any) {
                    route.isReady = false;
                    this.notUpdatedSelectedRouteIds = [...this.notUpdatedSelectedRouteIds, route._id];
                }
            });

            this.openUpdateModal();
            this.selectedRoutesActionInProgress = undefined;
        }
    }

    @computed
    public get isSelectedRoutesSettingReady(): boolean {
        return this.selectedRoutesActionInProgress === SelectedRoutesActions.setReady;
    }

    @action
    public setAvailableFrom(value: string | undefined) {
        this.availableFrom = value;
    }

    @action
    public async setSelectedRoutesAvailability(): Promise<void> {
        this.selectedRoutesActionInProgress = SelectedRoutesActions.setAvailability;

        const selectedRoutes = this.routes?.filter((route) => this.selectedRouteIds.includes(route._id));

        if (!selectedRoutes) {
            return;
        }
        await asyncForEach(selectedRoutes, async (route: Route) => {
            const currentAvailability = route.availableFrom;
            route.availableFrom = transformTimestampStringToDate(this.availableFrom);
            try {
                await this.rpcClient.content.updateRoute(route._id, route);
            } catch (error: any) {
                route.availableFrom = currentAvailability;
                this.notUpdatedSelectedRouteIds = [...this.notUpdatedSelectedRouteIds, route._id];
            }
        });

        this.openUpdateModal();
        this.selectedRoutesActionInProgress = undefined;
    }

    @computed
    public get isSelectedRoutesSettingAvailability(): boolean {
        return this.selectedRoutesActionInProgress === SelectedRoutesActions.setAvailability;
    }

    @action
    public showDuplicateRouteDialog(): void {
        this.isDuplicationRouteDialogOpened = true;
    }

    @action
    public showSetSedanLitePriceDialog(): void {
        this.isSetSedanLitePriceDialogOpened = true;
    }

    @action
    public showSetLuxurySedanPriceDialog(): void {
        this.isSetLuxurySedanPriceDialogOpened = true;
    }

    @action
    public closeSetSedanLitePriceDialog(): void {
        this.isSetSedanLitePriceDialogOpened = false;
    }

    @action
    public closeSetLuxurySedanPriceDialog(): void {
        this.isSetLuxurySedanPriceDialogOpened = false;
    }

    @action
    public closeDuplicateRouteDialog(): void {
        this.isDuplicationRouteDialogOpened = false;
    }

    @action
    public async setSelectedRoutesLive(): Promise<void> {
        this.selectedRoutesActionInProgress = SelectedRoutesActions.setLive;

        const selectedRoutes = this.routes?.filter((route) => this.selectedRouteIds.includes(route._id));

        if (selectedRoutes) {
            await asyncForEach(selectedRoutes, async (route: Route) => {
                route.isLive = true;
                try {
                    await this.rpcClient.content.updateRoute(route._id, route);
                } catch (error: any) {
                    route.isLive = false;
                    this.notUpdatedSelectedRouteIds = [...this.notUpdatedSelectedRouteIds, route._id];
                }
            });

            this.openUpdateModal();
            this.selectedRoutesActionInProgress = undefined;
        }
    }

    @computed
    public get isSelectedRoutesSettingLive(): boolean {
        return this.selectedRoutesActionInProgress === SelectedRoutesActions.setLive;
    }

    @action
    public async setSelectedRoutesCampaigns(): Promise<void> {
        this.selectedRoutesActionInProgress = SelectedRoutesActions.createCampaigns;

        const selectedRoutes = this.routes?.filter((route) => this.selectedRouteIds.includes(route._id));
        if (selectedRoutes) {
            await asyncForEach(selectedRoutes, async (route: Route) => {
                try {
                    const [travelData] = await this.rpcClient.content.retrieveTravelData({
                        endpointIds: [route.originLocationId, route.destinationLocationId],
                    });

                    const originAndDestination = await this.rpcClient.content.retrieveSimpleLocations({
                        ids: [route.originLocationId, route.destinationLocationId],
                    });
                    const origin = originAndDestination.find(
                        (location) => location.locationId === route.originLocationId,
                    ) as SimpleLocation;
                    const destination = originAndDestination.find(
                        (location) => location.locationId === route.destinationLocationId,
                    ) as SimpleLocation;

                    const vehicleTypePrice = route.vehicleTypesPricesFees.find(
                        (vtpf) => vtpf.vehicleType === VehicleType.Van || vtpf.vehicleType === VehicleType.Shuttle,
                    ) as VehicleTypePriceFee;
                    const newRouteGoogleCampaign = createRouteGoogleCampaignOperator(
                        route,
                        vehicleTypePrice,
                        travelData,
                        origin,
                        destination,
                    );
                    const pricingCountry = await this.rpcClient.content.retrieveCountry(route.pricingCountryId);
                    const googleCampaignSettingType =
                        pricingCountry && pricingCountry.googleCampaignSettingType
                            ? pricingCountry.googleCampaignSettingType
                            : GoogleCampaignSettingType.Europe;

                    let originCountryIsoCode = "";
                    let destinationCountryIsoCode = "";

                    if (this.simpleCountries) {
                        const originCountry = this.simpleCountries.find((country) => country._id === origin.countryId);
                        const destinationCountry = this.simpleCountries.find(
                            (country) => country._id === destination.countryId,
                        );

                        if (originCountry) {
                            originCountryIsoCode = originCountry.isoCode;
                        }
                        if (destinationCountry) {
                            destinationCountryIsoCode = destinationCountry.isoCode;
                        }
                    }

                    const campaingsToCreate = fillCampaignsCreateOptions(
                        newRouteGoogleCampaign,
                        googleCampaignSettingType,
                        origin,
                        destination,
                        originCountryIsoCode,
                        destinationCountryIsoCode,
                    );

                    await this.prepareCampaigns(
                        campaingsToCreate,
                        newRouteGoogleCampaign,
                        googleCampaignSettingType,
                        origin,
                        destination,
                    );
                } catch (error: any) {
                    this.notUpdatedSelectedRouteIds = [...this.notUpdatedSelectedRouteIds, route._id];
                }
            });
            await this.createCampaigns();
            this.isSetCampaignsTriggered = true;
            this.openUpdateModal();
            this.selectedRoutesActionInProgress = undefined;
        } else {
            this.selectedRoutesActionInProgress = undefined;
        }
    }

    @action
    public async copySelectedRouteIdsToClipboard() {
        navigator.clipboard.writeText(this.selectedRouteIds.join(","));
    }

    @computed
    public get isSelectedRoutesSettingCampaigns(): boolean {
        return this.selectedRoutesActionInProgress === SelectedRoutesActions.createCampaigns;
    }

    private async prepareCampaigns(
        campaingsToCreate: Array<GoogleRouteCampaign>,
        newRouteGoogleCampaign: RouteGoogleCampaignOperator,
        googleCampaignSettingType: GoogleCampaignSettingType,
        originLocation: SimpleLocation,
        destinationLocation: SimpleLocation,
    ) {
        const validationErrors = await validateAds(
            newRouteGoogleCampaign,
            originLocation,
            destinationLocation,
            googleCampaignSettingType,
        );

        if (validationErrors.length > 0) {
            let validationMessage = "Some ads didn't pass validation and won't be created: \n";

            validationErrors.forEach((error) => {
                validationMessage += `${error}\n`;
            });

            validationMessage += "Do you still want to create campaigns?";

            // eslint-disable-next-line no-console
            console.warn(validationMessage);
        }

        this.campaignsToCreate.push({ campaigns: campaingsToCreate, campaignSettingType: googleCampaignSettingType });
    }

    private async createCampaigns() {
        // split array into smaller arrays
        const chunk = (
            array: Array<{ campaigns: Array<GoogleRouteCampaign>; campaignSettingType: GoogleCampaignSettingType }>,
            size: number,
        ) => array.reduce((prev, _, i) => (i % size ? prev : [...prev, array.slice(i, i + size)]), []);

        // split campaignsToCreate into arrays with max length of 10 elements
        const campaignsToCreateChunks = chunk(this.campaignsToCreate, 10);

        await asyncForEach(
            campaignsToCreateChunks,
            async (
                campaignsToCreateChunk: Array<{
                    campaigns: Array<GoogleRouteCampaign>;
                    campaignSettingType: GoogleCampaignSettingType;
                }>,
                index,
            ) => {
                // to prevent RateExceededError from Google, let's add 30s timeout between multiple chunks
                await this.rpcClient.googleCampaign.bulkRouteCampaignsCreation(campaignsToCreateChunk, index * 30000);
            },
        );

        const notificationsStore = container.get<StoreManager<NotificationsStore>>(stores.notifications).getStore();
        await notificationsStore.retrieveNotifications();
    }

    @observable
    public isCountryOrRegionSaving = false;

    @action
    public async setPricingCountryAndRegion(countryId: string, regionId: string | null) {
        await this.updatePricingCountryAndRegion({ countryId, regionId: regionId ?? undefined });
    }

    @action
    public async removePricingRegion() {
        await this.updatePricingCountryAndRegion({ regionId: undefined });
    }

    @action
    private async updatePricingCountryAndRegion({ countryId, regionId }: { countryId?: string; regionId?: string }) {
        const selectedRoutes = this.routes?.filter((route) => this.selectedRouteIds.includes(route._id));

        if (!selectedRoutes) {
            return;
        }

        this.isCountryOrRegionSaving = true;

        await asyncForEach(selectedRoutes, async (route: Route) => {
            const editedRoute = toJS(route);
            if (countryId) {
                editedRoute.pricingCountryId = countryId;
            }
            editedRoute.pricingRegionId = regionId;
            await this.rpcClient.content.updateRoute(route._id, editedRoute);
        });

        this.openUpdateModal();
        this.isCountryOrRegionSaving = false;
    }
}

export interface RoutesPageStoreDataFetched {
    routes: Route[];
    simpleCountries: SimpleCountry[];
    adwordsCustomerIds: string[];
}

async function asyncForEach(
    array: Array<any>,
    callback: (arrayItem: any, index: number, array: Array<any>) => Promise<void>,
) {
    for (let index = 0; index < array.length; index++) {
        // eslint-disable-next-line no-await-in-loop
        await callback(array[index], index, array);
    }
}
