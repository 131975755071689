import { PriceCalculator } from "@legacy/domain/PriceCalculator";
import type { SimpleCountry } from "@legacy/domain/SimpleCountry";
import { VehicleType } from "@legacy/domain/VehicleType";
import type { Location } from "@daytrip/legacy-models";
import type { OrderContentLocation } from "@daytrip/legacy-models";
import type { VehicleTypePriceFee } from "@daytrip/legacy-models";
import { getDefaultFeeCoefficient, getVehicleTypeFeeCoefficient } from "@legacy/utils/getVehicleTypeFeeCoefficient";
import autobind from "autobind-decorator";
import { action, computed, observable } from "mobx";

import { ModelOperator } from "./ModelOperator";
import type { ModelOperatorOptions } from "./ModelOperatorOptions";

export type OrderContentLocationOperatorOptions = ModelOperatorOptions<
    OrderContentLocation,
    null,
    OrderContentLocationOperatorData
>;

interface OrderContentLocationOperatorData {
    countries: Array<SimpleCountry>;
    location: Location;
}

interface OrderContentLocationOperatorDataFetched {
    countries: Array<SimpleCountry>;
    location: Location;
}

@autobind
export class ContentLocationOperator extends ModelOperator<
    OrderContentLocation,
    OrderContentLocationOperatorOptions,
    null,
    OrderContentLocationOperatorData,
    OrderContentLocationOperatorDataFetched
> {
    @observable
    public opened: boolean = false;

    @action
    public close() {
        this.opened = false;
    }

    @action
    public open() {
        this.opened = true;
    }

    @computed
    public get country(): SimpleCountry | undefined {
        return this.data.countries?.find((c) => c._id === this.data.location.countryId);
    }

    @action
    public orderDown() {
        this.edit((cl) => {
            cl.order -= 1;
        });
    }

    @action
    public orderUp() {
        this.edit((cl) => {
            cl.order += 1;
        });
    }

    @action
    public orderSet(newOrder: number) {
        this.edit((cl) => {
            cl.order = newOrder;
        });
    }

    @action
    public setDuration(duration: number, pricingCountryId: string) {
        this.edit(async (cl) => {
            const routePricingCountry = this.data.countries.find(
                (country) => country._id === pricingCountryId,
            ) as SimpleCountry;

            cl.duration = duration;
            cl.waitingPrice = PriceCalculator.calculateWaitingPriceFromDuration(
                duration,
                routePricingCountry.guideLocationPrice,
            );
            const feeCoefficient = getDefaultFeeCoefficient(routePricingCountry);
            cl.waitingFee = PriceCalculator.calculateFeeFromPrice(cl.waitingPrice, feeCoefficient);
        });
    }

    @action
    public setVehiclePrice(vehicleType: VehicleType, vehiclePrice: number, pricingCountryId: string) {
        let vehicleTypePriceFeeSetPriceIndex = this.m.vehicleTypesPricesFees.findIndex(
            (vtpf) => vtpf.vehicleType === vehicleType,
        );

        if (vehicleTypePriceFeeSetPriceIndex === -1) {
            this.m.vehicleTypesPricesFees.push({
                vehicleType,
                price: 0,
                fee: 0,
                isManuallyModified: false,
            } as VehicleTypePriceFee);

            vehicleTypePriceFeeSetPriceIndex = this.m.vehicleTypesPricesFees.findIndex(
                (vtpf) => vtpf.vehicleType === vehicleType,
            );
        }

        const pricingCountry = this.data.countries.find((country) => country._id === pricingCountryId) as SimpleCountry;
        const feeCoefficient = getVehicleTypeFeeCoefficient(pricingCountry, vehicleType);
        const price = Math.round(vehiclePrice * feeCoefficient);
        const fee = Math.round(vehiclePrice - price);

        this.edit((cl) => {
            cl.vehicleTypesPricesFees[vehicleTypePriceFeeSetPriceIndex].price = price;
            cl.vehicleTypesPricesFees[vehicleTypePriceFeeSetPriceIndex].fee = fee;
            cl.vehicleTypesPricesFees[vehicleTypePriceFeeSetPriceIndex].isManuallyModified = true;
        });
    }

    @action
    public setAdditionalPrice(additionalPrice: number, pricingCountryId: string) {
        this.edit((cl) => {
            cl.additionalPrice = additionalPrice;
            const feeCoefficient = getDefaultFeeCoefficient(
                this.data.countries.find((country) => country._id === pricingCountryId) as SimpleCountry,
            );
            cl.additionalFee = PriceCalculator.calculateFeeFromPrice(cl.additionalPrice, feeCoefficient);
        });
    }

    @action
    public setAdditionalFee(additionalFee: number) {
        this.edit((cl) => {
            cl.additionalFee = additionalFee;
        });
    }

    @action
    public setExtraPrice(priceType: string, extraPrice: number, pricingCountryId: string) {
        const feeCoefficient = getDefaultFeeCoefficient(
            this.data.countries.find((country) => country._id === pricingCountryId) as SimpleCountry,
        );
        const price = Math.round(extraPrice * feeCoefficient);
        const fee = Math.round(extraPrice - price);

        this.edit((cl) => {
            cl[`${priceType}Price`] = price;
            cl[`${priceType}Fee`] = fee;
        });
    }
}
