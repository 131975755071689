import { createHttpLink } from "@apollo/client";

import { API_MNG_GQL_URL, VERSION } from "../../config.management";

export function constructHttpLink() {
    const link = createHttpLink({
        uri: API_MNG_GQL_URL,
        headers: {
            lang: "en",
            "X-Daytrip-Client": `management-${VERSION}`,
        },
    });
    return link;
}
