import { Ad } from "../models/Ad";
import { validateLength } from "../utils/strings";

export class AdTemplateHelper {
    public static fillTemplateByData(
        teplate: string,
        originLocationName: string,
        destinationLocationName: string,
        price: string,
        duration: string,
        limit: number,
    ) {
        let templateWithData = teplate
            .replace("{originLocationName}", originLocationName)
            .replace("{destinationLocationName}", destinationLocationName)
            .replace("{price}", price)
            .replace("{duration}", duration);

        // try shorter version
        if (!validateLength(templateWithData, Ad.customizedParameters, limit)) {
            templateWithData = teplate
                .replace(
                    "{originLocationName} to {destinationLocationName}",
                    `${originLocationName}-${destinationLocationName}`,
                )
                .replace("{price}", price)
                .replace("{duration}", duration)
                .replace(" Hour", "Hr"); // Hours/Hour -> Hrs/Hr

            // try even shorter version of duration
            if (!validateLength(templateWithData, Ad.customizedParameters, limit)) {
                templateWithData = templateWithData.replace("Hrs ", "h ").replace("Hr ", "h ");
            }

            // if still exceeds limit but contains private car at the beginning
            if (
                !validateLength(templateWithData, Ad.customizedParameters, limit) &&
                templateWithData.indexOf("Private Car ") === 0
            ) {
                templateWithData = templateWithData.replace("Private Car ", "").concat(" by Car");
            }
        }

        return templateWithData;
    }
}
