import { TravelData } from "@legacy/models/TravelData";
import { transformValidationErrorsToArrayString } from "@daytrip/legacy-transformers";
import autobind from "autobind-decorator";
import { validate } from "class-validator";
import { action, observable } from "mobx";
import { Option } from "react-select-legacy";

import type { PageRouter } from "../../PageRouter";
import { PageStore } from "../../stores/PageStore";

@autobind
export class TravelDataEditorPageStore extends PageStore<PageRouter, null> {
    @observable
    public travelData?: TravelData;

    @action
    public onDurationChange(duration: number) {
        if (this.isTravelDataFetched()) {
            this.travelData.duration = duration;
            this.isTravelDataSaved = false;
        }
    }

    @action
    public onDistanceChange(distance: number) {
        if (this.isTravelDataFetched()) {
            this.travelData.distance = distance;
            this.isTravelDataSaved = false;
        }
    }

    @observable
    public isTravelDataSaved: boolean = false;

    @action
    public async onSaveTravelData() {
        if (this.isTravelDataFetched()) {
            const validationErrors = await validate(this.travelData);
            if (validationErrors.length > 0) {
                const validationMessages = transformValidationErrorsToArrayString(validationErrors).join("\n");
                alert(`Oh, something is wrong. :(\n\nValidation errors:\n${validationMessages}`);
            } else {
                try {
                    this.travelData.isManuallyModified = true;
                    await this.rpcClient.content.updateTravelData(this.travelData._id, this.travelData);
                    this.isTravelDataSaved = true;
                } catch (e: any) {
                    alert("Can't save, please check internet connection and try again.");
                    this.isTravelDataSaved = false;
                }
            }
        }
    }

    @observable
    public origin?: Option;

    @action
    public onChangeOrigin(option: Option) {
        this.origin = option;
    }

    @observable
    public destination?: Option;

    @action
    public onChangeDestination(option: Option) {
        this.destination = option;
    }

    @action
    public async searchTravelData() {
        if (this.origin && this.destination) {
            this.isTravelDataSaved = false;
            const travelDataResult = await this.rpcClient.content.retrieveTravelData({
                endpointIds: [this.origin.value, this.destination.value],
            });
            this.travelData = travelDataResult.find(
                (td) =>
                    (td.originId === this.origin.value && td.destinationId === this.destination.value) ||
                    (td.originId === this.destination.value && td.destinationId === this.origin.value),
            );
        } else {
            alert("Please select both origin and destination location.");
        }
    }

    @action
    public async fetchLocations(search: string): Promise<{ options: Array<Option> }> {
        let options: Array<Option> = [];
        if (search && search.length > 2) {
            const locations = await this.rpcClient.content.retrieveLocations({ search });
            options = locations.map((location) => ({
                label: location.name,
                value: location._id,
            }));
        }
        return { options };
    }

    public isTravelDataFetched(): this is TravelDataEditorPageStoreTravelDataFetched {
        return this.travelData !== undefined;
    }
}

interface TravelDataEditorPageStoreTravelDataFetched {
    travelData: TravelData;
}
