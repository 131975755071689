import { GoogleCampaignSettingType } from "@legacy/domain/GoogleCampaignSettingType";
import { GoogleRouteCampaign } from "@legacy/domain/GoogleRouteCampaign";
import { SimpleLocation } from "@legacy/domain/SimpleLocation";
import { TargetingLocation } from "@legacy/domain/TargetingLocation";
import { TargetingLocationType } from "@legacy/domain/TargetingLocationType";
import { campaignTypeNames, campaignTypes } from "@legacy/googleAdwordsTool/campaignTypes";
import { RouteGoogleCampaignOperator } from "../../operators/RouteGoogleCampaignOperator";

export function fillCampaignsCreateOptions(
    newRouteGoogleCampaign: RouteGoogleCampaignOperator,
    googleCampaignSettingType: GoogleCampaignSettingType,
    originLocation: SimpleLocation,
    destinationLocation: SimpleLocation,
    originCountryIsoCode: string,
    destinationCountryIsoCode: string,
) {
    const campaignsCreateOptions: Array<GoogleRouteCampaign> = [];

    if (newRouteGoogleCampaign.routeGoogleCampaign) {
        const campaign = newRouteGoogleCampaign.routeGoogleCampaign;
        const { campaignName } = campaign;

        const anotherDirectionCampaignName = `${new Date().getFullYear()}/${`0${(
            new Date().getMonth() + 1
        ).toString()}`.slice(-2)} ${campaign.destinationLocationName} to ${campaign.originLocationName}`;
        const anotherDirectionCampaign = { ...campaign };
        anotherDirectionCampaign.campaignName = anotherDirectionCampaignName;
        anotherDirectionCampaign.originLocationName = campaign.destinationLocationName;
        anotherDirectionCampaign.destinationLocationName = campaign.originLocationName;
        anotherDirectionCampaign.originMachineName = campaign.destinationMachineName;
        anotherDirectionCampaign.destinationMachineName = campaign.originMachineName;
        anotherDirectionCampaign.landingUrl = `https://daytrip.com/landing/${anotherDirectionCampaign.originMachineName}-to-${anotherDirectionCampaign.destinationMachineName}`;

        const currentCampaignTypes = campaignTypes[googleCampaignSettingType];

        currentCampaignTypes.forEach((type) => {
            const campaignCreateOption = { ...campaign };
            campaignCreateOption.campaignName = `${
                campaignName + type.type + originCountryIsoCode
            }-${destinationCountryIsoCode}`;
            campaignCreateOption.targetingLocations = type.targetingLocations;
            campaignCreateOption.isOtherDirection = false;

            const anotherDirectionCreateOption = { ...anotherDirectionCampaign };
            anotherDirectionCreateOption.campaignName = `${
                anotherDirectionCampaignName + type.type + destinationCountryIsoCode
            }-${originCountryIsoCode}`;
            anotherDirectionCreateOption.targetingLocations = type.targetingLocations;
            anotherDirectionCreateOption.isOtherDirection = true;

            if (
                type.type === campaignTypeNames[GoogleCampaignSettingType.Europe].E ||
                type.type === campaignTypeNames[GoogleCampaignSettingType.SouthEastAsia].SEA ||
                type.type === campaignTypeNames[GoogleCampaignSettingType.Africa].E
            ) {
                campaignCreateOption.targetingLocations = [
                    {
                        locationName: campaign.originLocationName,
                        type: TargetingLocationType.City,
                        locationId: originLocation.locationId,
                    } as TargetingLocation,
                ];

                anotherDirectionCreateOption.targetingLocations = [
                    {
                        locationName: anotherDirectionCreateOption.originLocationName,
                        type: TargetingLocationType.City,
                        locationId: destinationLocation.locationId,
                    } as TargetingLocation,
                ];
            }

            const excludeTargetingLocation = (
                campaignTypeName: string,
                countryIsoCode: string,
                locationNameToExclude: string,
            ) => {
                if (type.type === campaignTypeName && originCountryIsoCode === countryIsoCode) {
                    campaignCreateOption.targetingLocations = campaignCreateOption.targetingLocations.filter(
                        (ta) => ta.locationName !== locationNameToExclude,
                    );
                    anotherDirectionCreateOption.targetingLocations = campaignCreateOption.targetingLocations.filter(
                        (ta) => ta.locationName !== locationNameToExclude,
                    );
                }
            };

            // exclude South Africa from W targeting if it's route from South Africa
            excludeTargetingLocation(campaignTypeNames[GoogleCampaignSettingType.Africa].W, "za", "South Africa");

            // exclude UAE from W targeting if it's route from UAE
            excludeTargetingLocation(
                campaignTypeNames[GoogleCampaignSettingType.MiddleEast].W,
                "ae",
                "United Arab Emirates",
            );

            // exclude New Zealand from W targeting if it's route from New Zealand
            excludeTargetingLocation(
                campaignTypeNames[GoogleCampaignSettingType.AustraliaNewZealand].W,
                "nz",
                "New Zealand",
            );

            // exclude Australia from W targeting if it's route from Australia
            excludeTargetingLocation(
                campaignTypeNames[GoogleCampaignSettingType.AustraliaNewZealand].W,
                "au",
                "Australia",
            );

            campaignsCreateOptions.push(campaignCreateOption);
            campaignsCreateOptions.push(anotherDirectionCreateOption);
        });
    }

    return campaignsCreateOptions;
}
