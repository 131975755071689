import { CountryGroup } from "@legacy/domain/CountryGroup";
import { Country } from "@legacy/models/Country";

import type { CountryGroupItem } from "../../../../domain/countryGroups/models/CountryGroupItem";
import type { CountryGroupFilterItem } from "../models/CountryGroupFilterItem";

export function generateCountryGroupFilterItems(items: CountryGroupItem[], countries: Country[]) {
    return items.map<CountryGroupFilterItem>((item) => {
        const relevantCountryGroups = collectRelevantCountryGroups(items, item);
        return {
            ...item,
            countries: countries.filter(
                (country) => country.countryGroup != null && relevantCountryGroups.includes(country.countryGroup),
            ),
        };
    });
}

function collectRelevantCountryGroups(items: CountryGroupItem[], root: CountryGroupItem): CountryGroup[] {
    const children = items.reduce((acc, item) => {
        if (item.parent?.key === root.key) {
            return [...acc, ...collectRelevantCountryGroups(items, item)];
        }
        return acc;
    }, [] as CountryGroup[]);

    return [root.key, ...children];
}
