/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
/* eslint-disable no-alert */
import { DEFAULT_DESTINATION_RADIUS } from "@daytrip/constants";
import { US_COUNTRY_ID } from "@daytrip/legacy-config";
import { TaggableEntityType } from "@daytrip/legacy-enums";
import type { Tag } from "@daytrip/legacy-models";
import {
    Location,
    LocationPosition,
    MeetingPosition,
    Position,
    PreviewDeleteLocationResult,
} from "@daytrip/legacy-models";
import { transformNameToMachineName, transformValidationErrorsToArrayString } from "@daytrip/legacy-transformers";
import { getUsState } from "@legacy/clients/apple-maps-client/apple-maps-client";
import type { PositionWithCountry } from "@legacy/domain/PositionWithCountry";
import { PriceCalculator } from "@legacy/domain/PriceCalculator";
import type { SimpleLocation } from "@legacy/domain/SimpleLocation";
import { SimpleUser } from "@legacy/domain/SimpleUser";
import { Country } from "@legacy/models/Country";
import { Language } from "@legacy/models/Language";
import { Region } from "@legacy/models/Region";
import { Route } from "@legacy/models/Route";
import { RouteLocation } from "@legacy/models/RouteLocation";
import { RetrieveRoutesOptions } from "@legacy/options/RetrieveRoutesOptions";
import { getDefaultFeeCoefficient } from "@legacy/utils/getVehicleTypeFeeCoefficient";
import autobind from "autobind-decorator";
import { classToPlain, plainToClass } from "class-transformer";
import { validate } from "class-validator";
import { action, computed, observable, toJS } from "mobx";

import { MeetingPositionOperator } from "../../operators/MeetingPositionOperator";
import { getTagParents } from "../../utils/getTagParents";

import { BaseLocationPageStore } from "./BaseLocationPageStore";

@autobind
export class LocationPageStore extends BaseLocationPageStore {
    // content
    @observable
    public location?: Location;

    @observable
    public languages: Array<Language>;

    @computed
    public get languagesEnabled(): Language[] {
        return toJS(this.languages).filter((language: Language) => language.isEnabled);
    }

    @observable
    public editedLocation?: Location;

    @observable
    public simpleLocations?: Array<SimpleLocation>;

    @observable
    public countries?: Array<Country>;

    @observable
    public country?: Country;

    @observable
    public regions: Array<Region>;

    @observable
    public region?: Region;

    @observable
    public routes?: Array<Route>;

    @observable
    public updatingRoutes?: Array<Route>;

    @observable
    public routesSortProperty: string = "origin";

    @observable
    public isRoutesSortDesc: boolean;

    @observable
    public locationInRoutesAmount: number = 0;

    @observable
    public tags: Array<Tag> = [];

    @observable
    public createdByUser?: SimpleUser;

    @observable
    public meetingPositionOperators: Array<MeetingPositionOperator> = [];

    @action
    public async onFetchData() {
        await this.fetchContent();
    }

    private async fetchLanguages() {
        const languages = await this.rpcClient.content.retrieveLanguages({});

        const englishIndex = languages.findIndex((language) => language.isoCode === "en");
        const english = languages[englishIndex];
        languages.splice(englishIndex, 1); // remove english from languages array
        this.languages = [english!, ...languages]; // add english as the first

        [this.selectedLanguage] = this.languages; // set english as selected language
    }

    private async fetchTags() {
        if (!this.tags || this.tags.length === 0) {
            this.tags = await this.rpcClient.content.retrieveTags({ applicableFor: [TaggableEntityType.Location] });
        }
    }

    @action
    public async fetchContent() {
        const { locationId } = this.pageRouter;
        let countryId: string;

        [this.simpleLocations] = await Promise.all([
            this.rpcClient.content.retrieveSimpleLocations({}),
            this.fetchLanguages(),
        ]);

        if (!locationId) {
            const editedLocation = classToPlain(new Location()) as Location;
            this.editedLocation = observable(editedLocation);
            countryId = editedLocation.countryId;

            [this.countries, this.regions] = await Promise.all([
                this.rpcClient.content.retrieveCountries({}),
                this.rpcClient.content.retrieveRegions({ countryIds: [countryId] }),
                this.fetchTags(),
            ]);
        } else {
            const rawLocationObject = await this.rpcClient.content.retrieveLocation(locationId);
            this.location = classToPlain(plainToClass(Location, rawLocationObject)) as Location;
            this.country = await this.rpcClient.content.retrieveCountry(this.location.countryId);

            await this.fetchMeetingPositions();

            if (this.location.regionId) {
                this.region = await this.rpcClient.content.retrieveRegion(this.location.regionId);
            }

            await this.fetchRoutes();
            await this.calculateRoutes();

            if (this.location.tagIds) {
                await this.fetchTags();
            }
            this.locationInRoutesAmount = await this.rpcClient.content.retrieveLocationInRoutesAmount(locationId);

            countryId = this.location.countryId;

            // to set field region observable
            if (!this.location.regionId) {
                this.location.regionId = undefined;
            }
        }

        if (this.editedLocation) {
            this.locationValidate();
        }

        if (this.location && this.location.createdBy) {
            const fetchedUsers = await this.rpcClient.user.retrieveSimpleUsers({ userIds: [this.location.createdBy] });
            [this.createdByUser] = fetchedUsers;
        }
    }

    public async fetchMeetingPositions() {
        if (!this.location || !this.location._id) {
            return;
        }

        const meetingPositions = await this.rpcClient.content.retrieveMeetingPositions({
            locationIds: [this.location._id],
        });

        const meetingPositionOperators: Array<MeetingPositionOperator> = [];

        meetingPositions.forEach((mp) => {
            const meetingPositionOperator = new MeetingPositionOperator({
                modelConstructor: MeetingPosition,
                model: mp,
                nonObservableProperties: ["restrictedAreaPolygon"],
                modules: null,
                data: {},
                onFetchData: async (operator: MeetingPositionOperator) => {
                    operator.model = plainToClass(
                        MeetingPosition,
                        await this.rpcClient.content.retrieveMeetingPosition(operator.m._id),
                    );
                    if (operator.isEdited()) {
                        operator.editedModel = operator.model;
                    }
                },
                onSave: async (meetingPosition: MeetingPosition) => {
                    const thisOperator = this.meetingPositionOperators.find(
                        (cdo) => cdo.m._id === meetingPosition._id,
                    ) as MeetingPositionOperator;
                    if (!thisOperator.isAddressValid) {
                        throw new Error("Provided Google address is not valid");
                    }
                    try {
                        await this.rpcClient.content.updateMeetingPosition(meetingPosition._id, meetingPosition);
                    } catch (e: any) {
                        alert(`Not saved. Reason: ${e}`);
                        // eslint-disable-next-line no-console
                        console.error(e);
                    }
                    thisOperator.fetchData();
                },
                validateOptions: { skipMissingProperties: true },
            });
            meetingPositionOperators.push(meetingPositionOperator);
        });

        this.meetingPositionOperators = meetingPositionOperators;
    }

    @action
    public async fetchRoutes() {
        const { locationId } = this.pageRouter;

        if (locationId) {
            const retrieveRoutesOptions = new RetrieveRoutesOptions();
            retrieveRoutesOptions.routeLocationIds = [locationId];
            retrieveRoutesOptions.isBidirectional = undefined;

            this.routes = await this.rpcClient.content.retrieveRoutes(retrieveRoutesOptions);

            if (this.routes.length > 0) {
                if (!this.countries) {
                    this.countries = await this.rpcClient.content.retrieveCountries({});
                }
            }
        }
    }

    @action
    public addMeetingPosition(): void {
        if (!this.location || !this.location._id || !this.location.position) {
            alert("Please save new location first.");
            return;
        }

        const newMeetingPosition = new MeetingPosition();
        newMeetingPosition.position = new Position(this.location.position.latitude, this.location.position.longitude);
        newMeetingPosition.locationId = this.location._id;

        const newMeetingPositionOperator = new MeetingPositionOperator({
            modelConstructor: MeetingPosition,
            model: plainToClass(MeetingPosition, newMeetingPosition),
            modules: null,
            data: {},
            onSave: async (meetingPosition) => {
                try {
                    meetingPosition._id = await this.rpcClient.content.createMeetingPosition(meetingPosition);
                    await this.fetchMeetingPositions();
                } catch (e: any) {
                    alert("not saved.");
                    // eslint-disable-next-line no-console
                    console.error(e);
                }
            },
            // isNew: true,
            validateOptions: { skipMissingProperties: true },
        });
        newMeetingPositionOperator.edit(() => {});
        this.meetingPositionOperators.unshift(newMeetingPositionOperator);
    }

    @action
    public async deleteMeetingPosition(meetingPositionId: string): Promise<void> {
        const meetingPositionOperatorToDelete = this.meetingPositionOperators.find(
            (mpo) => mpo.model._id === meetingPositionId,
        );

        if (meetingPositionOperatorToDelete) {
            try {
                if (meetingPositionOperatorToDelete.model._id) {
                    await this.rpcClient.content.deleteMeetingPosition(meetingPositionOperatorToDelete.model._id);
                }

                this.meetingPositionOperators.splice(
                    this.meetingPositionOperators.indexOf(meetingPositionOperatorToDelete),
                    1,
                );
            } catch (e: any) {
                alert(
                    "Something went wrong during deletion. It might be because some order contains this meeting position",
                );
            }
        }
    }

    @action
    public async removeFromRoutes() {
        await this.rpcClient.content.removeLocationFromRoutes((this.location as Location)._id);
        this.locationInRoutesAmount = await this.rpcClient.content.retrieveLocationInRoutesAmount(
            (this.location as Location)._id,
        );
        await this.fetchRoutes();
    }

    @action
    public locationUpdateName(value: string) {
        this.searchedAddress =
            value +
            (!(this.editedLocation as Location).countryId
                ? ""
                : `, ${
                      (
                          (this.countries as Array<Country>).find(
                              (country) => country._id === (this.editedLocation as Location).countryId,
                          ) as Country
                      ).englishName
                  }`);
        (this.editedLocation as Location).name = value;

        // change machine name with changing name only for new location
        if (!this.location) {
            (this.editedLocation as Location).machineName = transformNameToMachineName(value);
        }

        this.locationValidate();
    }

    @computed
    public get nameValidationMessage(): string | undefined {
        const validationErrors = this.editedLocationValidationErrors.find((ve) => ve.property === "name");
        if (!validationErrors) {
            return undefined;
        }
        return JSON.stringify(validationErrors.constraints);
    }

    @action
    public locationUpdateLocalName(index: number, value: string) {
        (this.editedLocation as Location).localNames[index] = value;
    }

    @computed
    public get localNamesValidationMessage(): string | undefined {
        const validationErrors = this.editedLocationValidationErrors.find((ve) => ve.property === "localNames");
        if (!validationErrors) {
            return undefined;
        }
        return JSON.stringify(validationErrors.constraints);
    }

    @action
    public locationRemoveLocalName(index: number) {
        (this.editedLocation as Location).localNames.splice(index, 1);
    }

    @action
    public locationAddLocalName(value: string) {
        (this.editedLocation as Location).localNames.push(value);
    }

    @action
    public locationUpdateOtherName(index: number, value: string) {
        (this.editedLocation as Location).otherNames[index] = value;
    }

    @computed
    public get otherNamesValidationMessage(): string | undefined {
        const validationErrors = this.editedLocationValidationErrors.find((ve) => ve.property === "otherNames");
        if (!validationErrors) {
            return undefined;
        }
        return JSON.stringify(validationErrors.constraints);
    }

    @action
    public locationRemoveOtherName(index: number) {
        (this.editedLocation as Location).otherNames.splice(index, 1);
    }

    @action
    public locationAddOtherName(value: string) {
        (this.editedLocation as Location).otherNames.push(value);
    }

    @action
    public locationUpdateAirportCode(index: number, value: string) {
        (this.editedLocation as Location).airportCodes[index] = value;
    }

    @computed
    public get airportCodesValidationMessage(): string | undefined {
        const validationErrors = this.editedLocationValidationErrors.find((ve) => ve.property === "airportCodes");
        if (!validationErrors) {
            return undefined;
        }
        return JSON.stringify(validationErrors.constraints);
    }

    @action
    public locationRemoveAirportCode(index: number) {
        (this.editedLocation as Location).airportCodes.splice(index, 1);
    }

    @action
    public locationAddAirportCode(value: string) {
        (this.editedLocation as Location).airportCodes.push(value);
    }

    public machineNameUpdated: boolean = false;

    @action
    public locationUpdateMachineName(value: string) {
        (this.editedLocation as Location).machineName = value;
        this.locationValidate();
        this.machineNameUpdated = true;
    }

    @computed
    public get machineNameValidationMessage(): string | undefined {
        const validationErrors = this.editedLocationValidationErrors.find((ve) => ve.property === "machineName");
        const machineName = this.editedLocation?.machineName || "";
        const validMachineName = transformNameToMachineName(machineName);
        const validationMessages = [];
        let machineNameVlidationMessage = "";

        if (validMachineName !== machineName) {
            machineNameVlidationMessage =
                "Machine name is in invalid format. Please use only letters, numbers or hyphens.";
        }

        if (machineNameVlidationMessage !== "") {
            validationMessages.push(machineNameVlidationMessage);
        }

        if (validationErrors) {
            validationMessages.push(JSON.stringify(validationErrors.constraints));
        }

        if (validationMessages.length === 0) {
            return undefined;
        }
        return validationMessages.join("\n");
    }

    @action
    public locationUpdateDefaultDuration(value: number) {
        (this.editedLocation as Location).defaultDuration = value;
    }

    @computed
    public get defaultDurationValidationMessage(): string | undefined {
        const validationErrors = this.editedLocationValidationErrors.find((ve) => ve.property === "defaultDuration");
        if (!validationErrors) {
            return undefined;
        }
        return JSON.stringify(validationErrors.constraints);
    }

    @action
    public locationUpdateScore(value: number) {
        (this.editedLocation as Location).score = value;
    }

    @computed
    public get scoreValidationMessage(): string | undefined {
        const validationErrors = this.editedLocationValidationErrors.find((ve) => ve.property === "score");
        if (!validationErrors) {
            return undefined;
        }
        return JSON.stringify(validationErrors.constraints);
    }

    @action
    public locationUpdateNote(value: string) {
        (this.editedLocation as Location).note = value;
    }

    @computed
    public get noteValidationMessage(): string | undefined {
        const validationErrors = this.editedLocationValidationErrors.find((ve) => ve.property === "note");
        if (!validationErrors) {
            return undefined;
        }
        return JSON.stringify(validationErrors.constraints);
    }

    @action
    public locationUpdateIsReady(value: boolean) {
        if (!value && (this.editedLocation as Location).isLive) {
            if (
                confirm(
                    "This will make this location non live, be sure not to set location non live till it is used in some route!",
                )
            ) {
                (this.editedLocation as Location).isReady = false;
                (this.editedLocation as Location).isLive = false;
                return;
            }
            return;
        }

        (this.editedLocation as Location).isReady = value;

        this.locationValidate();
    }

    @computed
    public get isReadyValidationMessage(): string | undefined {
        const validationErrors = this.editedLocationValidationErrors.find((ve) => ve.property === "isReady");
        if (!validationErrors) {
            return undefined;
        }
        return JSON.stringify(validationErrors.constraints);
    }

    @action
    public locationUpdateIsLive(value: boolean) {
        // todo: cant set live when (isLocation AND copywriting is not done)
        // OR image is not defined
        // OR ready is false = create validation group for location (ask Peter if you dont know what is validation group)

        if (value) {
            if (confirm("This makes me visible on web!")) {
                (this.editedLocation as Location).isReady = true;
                (this.editedLocation as Location).isLive = true;
                return;
            }

            return;
        }

        if (
            confirm(
                "This will make this location non live, be sure not to set location non live till it is used in some route!",
            )
        ) {
            (this.editedLocation as Location).isLive = false;
        } else {
            return;
        }

        this.locationValidate();
    }

    @computed
    public get isLiveValidationMessage(): string | undefined {
        const validationErrors = this.editedLocationValidationErrors.find((ve) => ve.property === "isLive");
        if (!validationErrors) {
            return undefined;
        }
        return JSON.stringify(validationErrors.constraints);
    }

    @action
    public locationUpdateIsSuggested(value: boolean) {
        (this.editedLocation as Location).isSuggested = value;
    }

    @computed
    public get isSuggestedValidationMessage(): string | undefined {
        const validationErrors = this.editedLocationValidationErrors.find((ve) => ve.property === "isSuggested");
        if (!validationErrors) {
            return undefined;
        }
        return JSON.stringify(validationErrors.constraints);
    }

    @action
    public locationUpdateIsLocation(value: boolean) {
        (this.editedLocation as Location).isLocation = value;

        this.locationValidate();
    }

    @computed
    public get isLocationValidationMessage(): string | undefined {
        const validationErrors = this.editedLocationValidationErrors.find((ve) => ve.property === "isLocation");
        if (!validationErrors) {
            return undefined;
        }
        return JSON.stringify(validationErrors.constraints);
    }

    @action
    public locationUpdateIsDestination(value: boolean) {
        if (value && !(this.editedLocation as Location).radiusKm) {
            (this.editedLocation as Location).radiusKm = DEFAULT_DESTINATION_RADIUS;
        }
        (this.editedLocation as Location).isDestination = value;
    }

    @computed
    public get isDestinationValidationMessage(): string | undefined {
        const validationErrors = this.editedLocationValidationErrors.find((ve) => ve.property === "isDestination");
        if (!validationErrors) {
            return undefined;
        }
        return JSON.stringify(validationErrors.constraints);
    }

    @action
    public locationUpdateIsAirport(value: boolean) {
        (this.editedLocation as Location).isAirport = value;
    }

    @computed
    public get isAirportValidationMessage(): string | undefined {
        const validationErrors = this.editedLocationValidationErrors.find((ve) => ve.property === "isAirport");
        if (!validationErrors) {
            return undefined;
        }
        return JSON.stringify(validationErrors.constraints);
    }

    @action
    public locationUpdateIsHarbor(value: boolean) {
        (this.editedLocation as Location).isHarbor = value;
    }

    @action
    public locationUpdateIsApiOnly(value: boolean) {
        (this.editedLocation as Location).isApiOnly = value;
    }

    @computed
    public get isHarborValidationMessage(): string | undefined {
        const validationErrors = this.editedLocationValidationErrors.find((ve) => ve.property === "isHarbor");
        if (!validationErrors) {
            return undefined;
        }
        return JSON.stringify(validationErrors.constraints);
    }

    @computed
    public get isApiOnlyValidationMessage(): string | undefined {
        const validationErrors = this.editedLocationValidationErrors.find((ve) => ve.property === "isApiOnly");
        if (!validationErrors) {
            return undefined;
        }
        return JSON.stringify(validationErrors.constraints);
    }

    @action
    public locationUpdateIsGenerated(value: boolean) {
        (this.editedLocation as Location).isGenerated = value;
    }

    @computed
    public get isGeneratedValidationMessage(): string | undefined {
        const validationErrors = this.editedLocationValidationErrors.find((ve) => ve.property === "isGenerated");
        if (!validationErrors) {
            return undefined;
        }
        return JSON.stringify(validationErrors.constraints);
    }

    @action
    public locationUpdateIsVisited(value: boolean) {
        (this.editedLocation as Location).isVisited = value;
    }

    @computed
    public get isVisitedValidationMessage(): string | undefined {
        const validationErrors = this.editedLocationValidationErrors.find((ve) => ve.property === "isVisited");
        if (!validationErrors) {
            return undefined;
        }
        return JSON.stringify(validationErrors.constraints);
    }

    @action
    public locationUpdateIsReviewed(value: boolean) {
        (this.editedLocation as Location).isReviewed = value;
    }

    @computed
    public get isReviewedValidationMessage(): string | undefined {
        const validationErrors = this.editedLocationValidationErrors.find((ve) => ve.property === "isReviewed");
        if (!validationErrors) {
            return undefined;
        }
        return JSON.stringify(validationErrors.constraints);
    }

    @action
    public locationUpdateEntrancePrice(value: number) {
        (this.editedLocation as Location).entrancePrice = value;
        const feeCoefficient = getDefaultFeeCoefficient(
            (this.countries as Array<Country>).find(
                (country) => country._id === (this.editedLocation as Location).countryId,
            ) as Country,
        );
        (this.editedLocation as Location).entranceFee = PriceCalculator.calculateFeeFromPrice(value, feeCoefficient);
    }

    @computed
    public get entrancePriceValidationMessage(): string | undefined {
        const validationErrors = this.editedLocationValidationErrors.find((ve) => ve.property === "entrancePrice");
        if (!validationErrors) {
            return undefined;
        }
        return JSON.stringify(validationErrors.constraints);
    }

    @action
    public locationUpdateEntranceFee(value: number) {
        (this.editedLocation as Location).entranceFee = value;
    }

    @computed
    public get entranceFeeValidationMessage(): string | undefined {
        const validationErrors = this.editedLocationValidationErrors.find((ve) => ve.property === "entranceFee");
        if (!validationErrors) {
            return undefined;
        }
        return JSON.stringify(validationErrors.constraints);
    }

    @action
    public locationUpdateParkingPrice(value: number) {
        (this.editedLocation as Location).parkingPrice = value;
        const feeCoefficient = getDefaultFeeCoefficient(
            (this.countries as Array<Country>).find(
                (country) => country._id === (this.editedLocation as Location).countryId,
            ) as Country,
        );
        (this.editedLocation as Location).parkingFee = PriceCalculator.calculateFeeFromPrice(value, feeCoefficient);
    }

    @computed
    public get parkingPriceValidationMessage(): string | undefined {
        const validationErrors = this.editedLocationValidationErrors.find((ve) => ve.property === "parkingPrice");
        if (!validationErrors) {
            return undefined;
        }
        return JSON.stringify(validationErrors.constraints);
    }

    @action
    public locationUpdateParkingFee(value: number) {
        (this.editedLocation as Location).parkingFee = value;
    }

    @computed
    public get parkingFeeValidationMessage(): string | undefined {
        const validationErrors = this.editedLocationValidationErrors.find((ve) => ve.property === "parkingFee");
        if (!validationErrors) {
            return undefined;
        }
        return JSON.stringify(validationErrors.constraints);
    }

    @action
    public locationUpdateAdditionalPrice(value: number) {
        (this.editedLocation as Location).additionalPrice = value;
        const feeCoefficient = getDefaultFeeCoefficient(
            (this.countries as Array<Country>).find(
                (country) => country._id === (this.editedLocation as Location).countryId,
            ) as Country,
        );
        (this.editedLocation as Location).additionalFee = PriceCalculator.calculateFeeFromPrice(value, feeCoefficient);
    }

    @computed
    public get additionalPriceValidationMessage(): string | undefined {
        const validationErrors = this.editedLocationValidationErrors.find((ve) => ve.property === "additionalPrice");
        if (!validationErrors) {
            return undefined;
        }
        return JSON.stringify(validationErrors.constraints);
    }

    @action
    public locationUpdateAdditionalFee(value: number) {
        (this.editedLocation as Location).additionalFee = value;
    }

    @computed
    public get additionalFeeValidationMessage(): string | undefined {
        const validationErrors = this.editedLocationValidationErrors.find((ve) => ve.property === "additionalFee");
        if (!validationErrors) {
            return undefined;
        }
        return JSON.stringify(validationErrors.constraints);
    }

    @action
    public locationUpdateAddress(value: string) {
        (this.editedLocation as Location).address = value;
    }

    @action
    public locationUpdateState(value: string) {
        (this.editedLocation as Location).state = value;
    }

    @action
    public locationUpdateStateCode(value: string) {
        (this.editedLocation as Location).stateCode = value;
    }

    @computed
    public get addressValidationMessage(): string | undefined {
        const validationErrors = this.editedLocationValidationErrors.find((ve) => ve.property === "address");
        if (!validationErrors) {
            return undefined;
        }
        return JSON.stringify(validationErrors.constraints);
    }

    @action
    public async locationUpdateCountryId(value: string | undefined) {
        (this.editedLocation as Location).countryId = value || "";

        this.regions = value ? await this.rpcClient.content.retrieveRegions({ countryIds: [value] }) : [];
    }

    @action
    public locationUpdateRegionId(value: string | undefined) {
        (this.editedLocation as Location).regionId = value;
    }

    @action
    public locationUpdateTimezone(value: string | undefined) {
        (this.editedLocation as Location).timezone = value as string;
    }

    @computed
    public get countryIdValidationMessage(): string | undefined {
        const validationErrors = this.editedLocationValidationErrors.find((ve) => ve.property === "countryId");
        if (!validationErrors) {
            return undefined;
        }
        return JSON.stringify(validationErrors.constraints);
    }

    @computed
    public get timezoneIdValidationMessage(): string | undefined {
        const validationErrors = this.editedLocationValidationErrors.find((ve) => ve.property === "timezone");
        if (!validationErrors) {
            return undefined;
        }
        return JSON.stringify(validationErrors.constraints);
    }

    @observable
    public selectedLanguage: Language;

    @action
    public selectLanguage(language: Language): void {
        this.selectedLanguage = language;
    }

    public isTranslationComplete(location: Location, language: Language): boolean {
        if (language.isEnabled !== true) {
            return true;
        }

        return !!(
            (location.nameLocalized[language.isoCode] &&
                location.titleLocalized[language.isoCode] &&
                location.perexLocalized[language.isoCode] &&
                location.descriptionLocalized[language.isoCode]) ||
            !location.isLocation
        );
    }

    @action
    public locationUpdateNameLocalized(value: string) {
        this.editedLocation!.nameLocalized[this.selectedLanguage.isoCode] = value;
    }

    @action
    public locationUpdateTitleLocalized(value: string) {
        this.editedLocation!.titleLocalized[this.selectedLanguage.isoCode] = value;
    }

    @action
    public locationUpdateTitle(value: string) {
        this.editedLocation?.title = value;
    }

    @action
    public locationUpdatePerexLocalized(value: string) {
        this.editedLocation!.perexLocalized[this.selectedLanguage.isoCode] = value;
    }

    @action
    public locationUpdateDescriptionLocalized(value: string) {
        this.editedLocation!.descriptionLocalized[this.selectedLanguage.isoCode] = value;
    }

    @action
    public locationUpdateDescription(value: string) {
        this.editedLocation!.description = value;
    }

    @action
    public locationUpdatePosition(value: LocationPosition) {
        (this.editedLocation as Location).position = value;
        this.locationValidate();
    }

    @action
    public locationUpdateRadius(value: number) {
        (this.editedLocation as Location).radiusKm = value;
    }

    @computed
    public get positionValidationMessage(): string | undefined {
        const validationErrors = this.editedLocationValidationErrors.find((ve) => ve.property === "position");
        if (!validationErrors) {
            return undefined;
        }
        return JSON.stringify(validationErrors.constraints);
    }

    @computed
    public get stateValidationMessage(): string | undefined {
        const validationErrors = this.editedLocationValidationErrors.find((ve) => ve.property === "state");
        if (!validationErrors) {
            return undefined;
        }
        return JSON.stringify(validationErrors.constraints);
    }

    @computed
    public get radiusValidationMessage(): string | undefined {
        const validationErrors = this.editedLocationValidationErrors.find((ve) => ve.property === "radiusKm");
        if (!validationErrors) {
            return undefined;
        }
        return JSON.stringify(validationErrors.constraints);
    }

    @observable
    public searchedAddress: string;

    @observable
    public isLocationSearchFailed: boolean = false;

    @action
    public updateSearchedAddress(value: string) {
        this.searchedAddress = value;
    }

    @action
    public async locationCalculateAddressReceive(address: string, position: Position, countryId: string | undefined) {
        (this.editedLocation as Location).address = address;
        (this.editedLocation as Location).position = position;
        (this.editedLocation as Location).countryId = countryId || "";

        if (countryId === US_COUNTRY_ID) {
            try {
                const usState = await getUsState(position);
                (this.editedLocation as Location).stateCode = usState.stateCode;
                (this.editedLocation as Location).state = usState.stateName;
            } catch (err) {
                console.log(err);
                alert("Couldn't resolve US state");
            }
        }

        this.locationValidate();
    }

    @action
    public async locationCalculateAddress(address: string) {
        this.isLocationSearchFailed = false;
        try {
            const positionWithCountry: PositionWithCountry = await this.rpcClient.maps.fetchPositionByAddress(address);

            const { countryId } = positionWithCountry;

            await Promise.resolve(
                this.locationCalculateAddressReceive(
                    address,
                    plainToClass(Position, positionWithCountry.position),
                    countryId,
                ),
            );
        } catch (e: any) {
            this.isLocationSearchFailed = true;
        }
    }

    @action
    public async locationEdit() {
        this.editedLocation = toJS(this.location);

        if (!this.languages) {
            this.languages = await this.rpcClient.content.retrieveLanguages({});
        }
        if (!this.countries) {
            this.countries = await this.rpcClient.content.retrieveCountries({});
        }
        if (!this.regions) {
            this.regions = await this.rpcClient.content.retrieveRegions({ countryIds: [this.location!.countryId] });
        }
        await this.fetchTags();
    }

    @action
    public routesSortPropertyChangeHandler(property: string) {
        this.routesSortProperty = property;
    }

    @observable
    public isRoutesDurationsFetching = false;

    @observable
    public calculatedRoutes: Array<Route>;

    @action
    public async calculateRoutes(): Promise<void> {
        this.isRoutesDurationsFetching = true;
        this.calculatedRoutes = await Promise.all(
            (this.routes as Array<Route>).map(async (r: Route) => {
                const route = Object.assign(new Route(), r);
                route.locations = route.locations.map((rl) => {
                    const newRl = Object.assign(new RouteLocation(), rl);
                    return newRl;
                });

                let calculatedRoute;
                try {
                    calculatedRoute = await this.rpcClient.content.calculateRoutePrices(route, false);
                } catch (e: any) {
                    calculatedRoute = await this.rpcClient.content.calculateRoutePrices(route, true);
                }
                return calculatedRoute;
            }),
        );

        this.updatingRoutes = toJS(this.routes as Array<Route>).map((route: Route) => {
            const calculatedRoute = this.calculatedRoutes.find((r) => r._id === route._id) as Route;

            route.locations = route.locations.map((location: RouteLocation) => {
                const calculatedLocation = calculatedRoute.locations.find(
                    (rl) => rl.locationId === location.locationId,
                ) as RouteLocation;

                location.defaultDuration = calculatedLocation.defaultDuration;

                if (
                    location.locationId === (!this.location ? (this.editedLocation as Location)._id : this.location._id)
                ) {
                    location.defaultDuration = !this.location
                        ? (this.editedLocation as Location).defaultDuration
                        : this.location.defaultDuration;
                }

                return location;
            });

            return route;
        });

        this.isRoutesDurationsFetching = false;
    }

    @action
    public updateRouteLocationDuration(originalRoute: Route, remove: boolean) {
        this.updatingRoutes = (this.updatingRoutes as Array<Route>).map((updatingRoute: Route) => {
            if (originalRoute._id !== updatingRoute._id) {
                return updatingRoute;
            }

            updatingRoute.locations = updatingRoute.locations.map((updatingLocation: RouteLocation) => {
                if (
                    (!this.location ? (this.editedLocation as Location)._id : this.location._id) !==
                    updatingLocation.locationId
                ) {
                    return updatingLocation;
                }

                if (remove) {
                    updatingLocation.defaultDuration = !this.location
                        ? (this.editedLocation as Location).defaultDuration
                        : this.location.defaultDuration;
                } else {
                    const calculatedLocation = originalRoute.locations.find(
                        (rl) =>
                            rl.locationId ===
                            (!this.location ? (this.editedLocation as Location)._id : this.location._id),
                    ) as RouteLocation;
                    updatingLocation.defaultDuration = calculatedLocation.defaultDuration;
                }

                return updatingLocation;
            });

            return updatingRoute;
        });
    }

    @observable
    public isRoutesUpdating = false;

    @observable
    public routesUpdatedCount: number = 0;

    @observable
    public routesUpdatedAt?: Date;

    public async updateRoutesDefaultDuration() {
        this.isRoutesUpdating = true;
        this.routesUpdatedAt = undefined;

        await Promise.all(
            (this.updatingRoutes as Array<Route>).map((r, index) => {
                if (
                    (r.locations.find((rl) => rl.locationId === (this.location as Location)._id) as RouteLocation)
                        .defaultDuration !== (this.location as Location).defaultDuration
                ) {
                    return Promise.resolve("");
                }

                return new Promise<void>((resolve, reject) => {
                    setTimeout(async () => {
                        try {
                            r.pricesRecalculatedAt = new Date();
                            await this.rpcClient.content.updateRoute(r._id, r);
                            this.routesUpdatedCount += 1;
                            resolve();
                        } catch (e: any) {
                            reject(e);
                        }
                    }, index * 850);
                });
            }),
        );

        await this.fetchData();

        this.isRoutesUpdating = false;
        this.routesUpdatedCount = 0;
        this.routesUpdatedAt = new Date();
    }

    @action
    public locationEditCancelOrGoBack() {
        if (!this.location) {
            window.history.back();
        } else {
            this.editedLocation = undefined;
        }
    }

    @action
    public async locationValidate() {
        this.editedLocationValidationErrors = await validate(plainToClass(Location, toJS(this.editedLocation)), {
            skipMissingProperties: true,
        });
    }

    @observable
    public isDeleting = false;

    @observable
    public showPreviewDeleteModal = false;

    @observable
    public previewDeleteResult?: PreviewDeleteLocationResult;

    @action
    public async locationDelete() {
        try {
            this.previewDeleteResult = await this.rpcClient.content.previewDeleteLocation(
                (this.location as Location)._id,
            );
        } catch (error: any) {
            alert(`Failed to check if location can be deleted: ${error}`);
        }
        if (this.previewDeleteResult?.canBeDeleted) {
            await this.performDelete();
        } else if (this.previewDeleteResult) {
            this.showPreviewDeleteModal = true;
        }
    }

    private async performDelete() {
        this.isDeleting = true;
        try {
            await this.rpcClient.content.deleteLocation((this.location as Location)._id);
            this.pageRouter.gotoLocationsPage();
        } catch (error: any) {
            alert(`Failed to delete location: ${error}`);
            this.isDeleting = false;
        }
    }

    @action
    public async locationEditSave() {
        if (this.machineNameUpdated) {
            alert("Changing the machine name will affect running campaigns!");
        }

        await this.locationValidate();
        let isMachineNameValid = this.machineNameValidationMessage === undefined;
        (this.simpleLocations as Array<SimpleLocation>).forEach((locationData) => {
            if (
                locationData.locationId !== (this.editedLocation as Location)._id &&
                locationData.machineName === (this.editedLocation as Location).machineName
            ) {
                isMachineNameValid = false;

                if ((this.editedLocation as Location).countryId) {
                    const country = (this.countries || []).find(
                        (c) => c._id === (this.editedLocation as Location).countryId,
                    );

                    if (country) {
                        (this.editedLocation as Location).machineName = `${
                            (this.editedLocation as Location).machineName
                        }_${country.isoCode}`;

                        isMachineNameValid = true;
                    }
                }
            }
        });

        if (this.editedLocationValidationErrors.length > 0 || !isMachineNameValid) {
            let validationMessages = transformValidationErrorsToArrayString(this.editedLocationValidationErrors).join(
                "\n",
            );
            if (!isMachineNameValid) {
                validationMessages += "\nmachine name is invalid";
            }
            alert(`Oh, something is wrong. :(\n\nCheck validations, please:\n${validationMessages}`);
            return;
        }

        if (
            (this.editedLocation as Location).isLive &&
            ((this.editedLocation as Location).images.length === 0 ||
                (this.editedLocation as Location).images[0].url === "https://daytrip.imgix.net/")
        ) {
            alert("Oh, it seems like there is no image for this location. :(");
            return;
        }

        if (this.editedLocation?.timezone) {
            let timezoneForCoordinates;
            try {
                timezoneForCoordinates = await this.rpcClient.content.getTimezoneForCoordinates(
                    this.editedLocation.position.latitude,
                    this.editedLocation.position.longitude,
                );
            } catch (e: any) {
                alert("failed to get suggested timezone.");
            }
            if (timezoneForCoordinates && timezoneForCoordinates !== this.editedLocation.timezone) {
                this.suggestedTimezone = timezoneForCoordinates;
                this.showUpdateToSuggestedTimezoneModal = true;
                return;
            }
        }

        if (
            // only for US, if coordinates were changed and they resolve to different state, sugest state update in modal
            this.editedLocation?.countryId === US_COUNTRY_ID &&
            this.editedLocation?.position
        ) {
            let stateForCoordinates;
            try {
                stateForCoordinates = await getUsState(this.editedLocation?.position);
            } catch (e: any) {
                alert("Failed to get suggested US state for coordinates, make sure you set the correct one yourself");
            }
            if (stateForCoordinates && stateForCoordinates.stateCode !== this.editedLocation?.stateCode) {
                this.suggestedState = stateForCoordinates;
                this.showUpdateToSuggestedState = true;
                return;
            }
        }

        // clean up state data if location country changed from US
        if (this.editedLocation?.countryId !== US_COUNTRY_ID) {
            this.editedLocation!.stateCode = undefined;
            this.editedLocation!.state = undefined;
        }

        this.updateLocationHolder();
    }

    @observable
    public showUpdateToSuggestedTimezoneModal = false;

    @observable
    public showUpdateToSuggestedState = false;

    @observable
    public suggestedTimezone: string | undefined;

    @observable
    public suggestedState: { stateName: string; stateCode: string } | undefined;

    @action
    public async updateToSuggestedTimezone() {
        if (this.suggestedTimezone) {
            this.editedLocation!.timezone = this.suggestedTimezone;
            this.suggestedTimezone = undefined;
            this.showUpdateToSuggestedTimezoneModal = false;
        }
    }

    public async updateToSuggestedState() {
        if (this.suggestedState) {
            this.editedLocation!.stateCode = this.suggestedState.stateCode;
            this.editedLocation!.state = this.suggestedState.stateName;
            this.suggestedState = undefined;
            this.showUpdateToSuggestedState = false;
        }
    }

    @action
    public async updateLocationHolder() {
        const editedLocation = toJS(this.editedLocation as Location);

        try {
            if (this.location) {
                await this.rpcClient.content.updateLocation(
                    editedLocation._id,
                    editedLocation,
                    this.machineNameUpdated,
                );
                this.pageRouter.openLocation(editedLocation._id);
            } else {
                this.pageRouter.openLocation(await this.rpcClient.content.createLocation(editedLocation));
            }
            this.editedLocation = undefined;
            this.location = undefined;
            await this.fetchData();
        } catch (e: any) {
            alert("not saved, contact admin.");
        }
    }

    @action
    public updateLocationTags(value: Array<string>) {
        const result = this.tags
            .filter((tag) => value.find((tagId) => tagId === tag._id))
            .reduce((r: Array<string>, t: Tag) => {
                if (t.parentTagId) {
                    r.push(...getTagParents(t, this.tags));
                }
                return r;
            }, value);
        (this.editedLocation as Location).tagIds = Array.from(new Set(result));
        this.locationValidate();
    }

    @computed
    public get tagsValidationMessage(): string | undefined {
        const validationErrors = this.editedLocationValidationErrors.find((ve) => ve.property === "tagIds");
        if (!validationErrors) {
            return undefined;
        }
        return JSON.stringify(validationErrors.constraints);
    }

    public isDataFetched(): this is LocationPageStore & LocationPageStoreDataFetched {
        return !!(
            (
                ((this.location && this.routes && this.updatingRoutes && this.calculatedRoutes) ||
                    this.editedLocation) &&
                this.simpleLocations &&
                this.meetingPositionOperators
            )
            // this.countries && this.tags && this.regions
        );
    }
}

export interface LocationPageStoreDataFetched {
    location: Location;
    editedLocation: Location;
    languages: Array<Language>;
    locations: Array<Location>;
    countries: Array<Country>;
    regions: Array<Region>;
    routes: Array<Route>;
    tags: Array<Tag>;
}
