import { Passenger } from "@daytrip/legacy-models";
import { Transform, Type } from "class-transformer";

import { AssignationPayout } from "../models/AssignationPayout";
import { Compensation } from "../models/Compensation";
import { DriverDebt } from "../models/DriverDebt";
import { Penalty } from "../models/Penalty";
import { Subsidy } from "../models/Subsidy";

import type { AssignationStatus } from "./AssignationStatus";

export class AssignationDataForDriver {
    public assignationId: string;

    public originLocationName: string;

    public destinationLocationName: string;

    @Type(() => Date)
    @Transform((value) => new Date(value))
    public departureAt: Date;

    public vehicleTitle: string;

    public customerName: string;

    @Type(() => Passenger)
    public passengers: Array<Passenger>;

    public amountForDriver: number;

    public driversBalance: number;

    public paidCash: number;

    public paidOnline: number;

    @Type(() => Penalty)
    public penalties: Array<Penalty>;

    @Type(() => Compensation)
    public compensations: Array<Compensation>;

    @Type(() => Subsidy)
    public subsidies: Array<Subsidy>;

    public sumOfPenalties: number;

    public sumOfCompensations: number;

    public sumOfSubsidies: number;

    @Type(() => AssignationPayout)
    public assignationPayouts: Array<AssignationPayout>;

    @Type(() => DriverDebt)
    public driverDebts: Array<DriverDebt>;

    public sumOfPayouts: number;

    public sumOfDebts: number;

    public status: AssignationStatus;

    public driverName: string;

    public totalPrice: number;

    public fee: number;
}
