import { Expose } from "class-transformer";
import { IsArray, IsDefined, IsUUID } from "class-validator";
import { v4 as uuid } from "uuid";

import { CDBaseEntity, CDColumn, CDObjectIdColumn } from "../orm";

import type { ChangeLogItem } from "./ChangeLogItem";

export class ChangeLogs extends CDBaseEntity {
    @CDObjectIdColumn()
    @IsUUID("4")
    @Expose()
    public _id: string = uuid();

    @CDColumn()
    @IsUUID("4")
    @IsDefined()
    @Expose()
    public ownerId: string;

    @CDColumn()
    @IsArray()
    @IsDefined()
    @Expose()
    public logs: Array<ChangeLogItem> = [];
}
