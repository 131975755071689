import { CancellationInfo } from "@daytrip/legacy-models";
import type { Order } from "@daytrip/legacy-models";
import type { Passenger } from "@daytrip/legacy-models";
import { mapPassengersToVehicles, VehicleConfig } from "@daytrip/legacy-transformers";
import { AssignationStatus } from "@legacy/domain/AssignationStatus";
import { Currency } from "@legacy/domain/Currency";
import type { RentalVehicle } from "@legacy/domain/RentalVehicle";
import type { SimpleDriver } from "@legacy/domain/SimpleDriver";
import { isIndividualDriver } from "@legacy/domain/SimpleDriver";
import { VehicleInfo } from "@legacy/domain/VehicleInfo";
import { VehicleType } from "@legacy/domain/VehicleType";
import { filterSuitableVehicleInfo, isDriverWillingToRentSuitableVehicle } from "@legacy/filters/filterSuitableVehicle";
import { Assignation } from "@legacy/models/Assignation";
import { Compensation } from "@legacy/models/Compensation";
import { Penalty } from "@legacy/models/Penalty";
import { Subsidy } from "@legacy/models/Subsidy";
import type { VehicleMake } from "@legacy/models/VehicleMake";
import type { VehicleModel } from "@legacy/models/VehicleModel";
import { isUndefinedOrNull } from "@legacy/utils";
import {
    isCompanyDriverSuitableForVehicleType,
    isDriversCompanySuitableForVehicleType,
    isDriverSuitableForVehicleType,
} from "@legacy/utils/driverSuitabilityForVehicleTypes";
import { alertError } from "@legacy/utils/errorHelper";
import autobind from "autobind-decorator";
import { plainToClass } from "class-transformer";
import { action, computed, extendObservable, observable, reaction } from "mobx";
import { Option } from "react-select-legacy";
import { v4 as uuid } from "uuid";

import { AssignationOperator } from "./AssignationOperator";
import { CompensationOperator } from "./CompensationOperator";
import { EnumOperator } from "./EnumOperator";
import type { EnumOperatorOptions } from "./EnumOperatorOptions";
import { PenaltyOperator } from "./PenaltyOperator";
import { SubsidyOperator } from "./SubsidyOperator";

interface OrderVehicleOperatorOptions extends EnumOperatorOptions<VehicleType, null, OrderVehicleOperatorData> {
    orderId: string;
    originLocationId: string;
    destinationLocationId: string;
    vehicleIndex: number;
    passengers?: Array<Passenger>;
    assignationOperator?: AssignationOperator;
    price?: number;
    pricingCurrency?: Currency;
    order: Order;
}

interface OrderVehicleOperatorData {
    simpleDrivers?: Array<SimpleDriver>; // todo simple driver
    penalties?: Array<Penalty>;
    subsidies?: Array<Subsidy>;
    compensations?: Array<Compensation>;
}

@autobind
export class OrderVehicleOperator extends EnumOperator<
    VehicleType,
    OrderVehicleOperatorOptions,
    null,
    OrderVehicleOperatorData
> {
    // count of passengers/luggage size in this vehicle
    public requiredVehicleCapacity: number;

    public passengers: Array<Passenger>;

    public availablePassengers: Array<Passenger>;

    public constructor(options: OrderVehicleOperatorOptions) {
        super(options);

        this.orderId = options.orderId;
        this.destinationLocationId = options.destinationLocationId;
        this.originLocationId = options.originLocationId;

        this.passengers = options.passengers != undefined ? options.passengers : [];
        this.availablePassengers =
            options.passengers != undefined ? options.passengers.filter((p) => !p.assignationId) : [];
        this.selectedPassengersIndexes = this.availablePassengers.map((_p, i) => i);
        this.vehicleIndex = options.vehicleIndex;

        const { order } = options;

        // new vehicle added, let's put it into the array before calculating the vehicles config
        const vehicles = order.vehicles.slice();
        if (vehicles.length === this.vehicleIndex) {
            vehicles.push(this.enum);
        }

        const vehicleConfig = mapPassengersToVehicles({ passengers: order.passengers, vehicles })[options.vehicleIndex];

        this.requiredVehicleCapacity =
            vehicleConfig.adultsCount + vehicleConfig.childrenCount + vehicleConfig.additionalLuggagePairs;

        this.vehicleConfig = vehicleConfig;

        if (options.price != null) {
            this.price = options.price;
        }
        if (options.pricingCurrency != null) {
            this.pricingCurrency = options.pricingCurrency;
        }

        extendObservable(this, { assignationOperator: options.assignationOperator });
    }

    // assignation

    @observable
    public orderId: string;

    public destinationLocationId: string;

    public originLocationId: string;

    public vehicleIndex: number;

    public vehicleConfig: VehicleConfig;

    public priceReaction = reaction(
        () => this.price,
        () => {
            if (!isUndefinedOrNull(this.assignationOperator) && !this.assignationOperator.price) {
                this.assignationOperator.price = this.price;
            }
        },
    );

    @observable
    public price: number = 0;

    @observable
    public pricingCurrency: Currency = Currency.Euro;

    @observable
    public assignationOperator?: AssignationOperator;

    @observable
    public isAcceptationModalVisible: boolean = false;

    @action
    public toggleIsAcceptationModalVisible() {
        this.isAcceptationModalVisible = !this.isAcceptationModalVisible;
    }

    @observable
    public newSelectedVehicleId?: string;

    @observable
    public vehicleMakes: Array<VehicleMake>;

    @observable
    public vehicleMakeIdToRent?: string;

    @observable
    public vehicleModels: Array<VehicleModel>;

    @observable
    public vehicleModelIdToRent?: string;

    @computed
    public get rentalVehicle(): RentalVehicle | undefined {
        if (!this.newSelectedVehicleId && this.vehicleMakeIdToRent && this.vehicleModelIdToRent) {
            return {
                makeId: this.vehicleMakeIdToRent,
                modelId: this.vehicleModelIdToRent,
            };
        }
    }

    @action
    public async selectNewVehicle(vehicleId: string) {
        this.newSelectedVehicleId = vehicleId;

        // if rental car was selected
        if (this.newSelectedVehicleId == "") {
            const locations = await this.rpcClient.content.retrieveLocations({
                ids: [this.destinationLocationId, this.originLocationId],
            });

            this.vehicleModels = await this.rpcClient.vehicle.retrieveSuitableVehicleModels({
                approvedForCountryIds: locations.map((l) => l.countryId),
                type: this.enum,
                requiredVehicleCapacity: this.requiredVehicleCapacity,
            });

            const vehicleMakes = await this.rpcClient.vehicle.retrieveVehicleMakes();
            this.vehicleMakes = vehicleMakes.filter(
                (vm) => this.vehicleModels.map((m) => m.makeId).indexOf(vm._id) > -1,
            );
        }
    }

    @observable
    public isAssignationStatusProcessing: boolean = false;

    @observable
    public isSaving: boolean = false;

    @action
    public async setNewDriverAndVehicle() {
        if (this.assignationOperator && this.assignationOperator.availableVehicles) {
            const assignation = this.assignationOperator.m;

            if (this.newSelectedVehicleId == "") {
                this.newSelectedVehicleId = undefined;
            }

            let rentalVehicle: RentalVehicle | undefined;
            if (!this.newSelectedVehicleId) {
                if (!this.vehicleMakeIdToRent || !this.vehicleModelIdToRent) {
                    alert("Either vehicle or rental vehicle must be selected.");
                    return;
                }
                rentalVehicle = {
                    makeId: this.vehicleMakeIdToRent,
                    modelId: this.vehicleModelIdToRent,
                };
            }

            assignation.vehicleId = this.newSelectedVehicleId;
            assignation.userId = this.selectedDriverId ?? this.assignationOperator!.m.userId;
            assignation.acceptationNote = this.acceptationNote;

            this.isSaving = true;
            // Using two separate functions is kinda questionable, it might be better to
            // detect whether we need to execute `updateAcceptedAssignation` in `updateAssignation`
            // and what we should pass there
            if (assignation.status == AssignationStatus.Accepted) {
                await this.rpcClient.assignation.updateAcceptedAssignation(
                    assignation._id,
                    assignation.vehicleId,
                    assignation.acceptationNote,
                    assignation.userId,
                    rentalVehicle,
                );
            } else {
                await this.rpcClient.assignation.updateAssignation(assignation._id, assignation);
            }
            this.isSaving = false;

            this.assignationOperator.edit((m) => {
                m.vehicleId = this.newSelectedVehicleId;
                m.userId = assignation.userId;
                m.version += 1;
            });
            this.assignationOperator.driver = this.data.simpleDrivers!.find((d) => d._id === assignation.userId);
            const vehicleTitle = !this.newSelectedVehicleId
                ? this.acceptationNote
                : this.assignationOperator.availableVehicles.find((av) => av._id === this.newSelectedVehicleId)?.title;
            this.assignationOperator.vehicleTitle = vehicleTitle || "";

            this.newSelectedVehicleId = undefined;
            this.isAcceptationModalVisible = false;
        }
    }

    @observable
    public selectedOfferDriverId?: string;

    @action
    public setOfferDriverId(id: string) {
        this.selectedOfferDriverId = id;
    }

    @observable
    public selectedDriverOption?: Option;

    // By default we assume that the driver is suitable as he might have been selected and assigned already
    @observable
    public selectedDriverIsSuitable: boolean = true;

    @observable
    public showUnsuitableDriverModal: boolean = false;

    @action
    public setUnsuitableDriverModal(show: boolean) {
        this.showUnsuitableDriverModal = show;
    }

    @action
    public async setDriverOption(option?: Option) {
        if (!option || !option.value || !this.data.simpleDrivers?.length) {
            return;
        }
        const selectedDriver = this.data.simpleDrivers.find((sd) => sd._id === option.value);
        this.selectedDriverIsSuitable = await this.isSelectedDriverSuitable(selectedDriver);

        this.selectedDriverOption = option;
    }

    @computed
    public get selectedDriverId(): string | undefined {
        return this.selectedDriverOption?.value as string;
    }

    private async isSelectedDriverSuitable(selectedDriver?: SimpleDriver) {
        if (!selectedDriver) {
            return false;
        }

        // We are not checking for suitable vehicles as the company had them during assignation
        if (selectedDriver.isCompanyDriver) {
            return this.isSelectedDriverSuitableForVehicleType(selectedDriver);
        }

        const [isSuitableForVehicleType, hasOrRentsCompatibleVehicle] = await Promise.all([
            await this.isSelectedDriverSuitableForVehicleType(selectedDriver),
            await this.hasOrRentsCompatibleVehicle(selectedDriver),
        ]);

        return isSuitableForVehicleType && hasOrRentsCompatibleVehicle;
    }

    private async isSelectedDriverSuitableForVehicleType(selectedDriver: SimpleDriver) {
        if (!isIndividualDriver(selectedDriver)) {
            const companyDrivers = await this.rpcClient.driver.retrieveSimpleDrivers({
                isCompanyDriver: true,
                driversCompanyIds: [selectedDriver._id],
                isActive: true,
            });
            return isDriversCompanySuitableForVehicleType({
                driversCompany: selectedDriver,
                companyDrivers: companyDrivers?.filter(isIndividualDriver) || [],
                vehicleType: this.enum,
            });
        }
        if (selectedDriver.isCompanyDriver) {
            // At the point where we pick company driver, we know that the company is willing to do lite
            return isCompanyDriverSuitableForVehicleType({
                driver: selectedDriver,
                driversCompany: { willingToDoLite: true },
                vehicleType: this.enum,
            });
        }
        return isDriverSuitableForVehicleType({ driver: selectedDriver, vehicleType: this.enum });
    }

    private async hasOrRentsCompatibleVehicle(selectedDriver: SimpleDriver) {
        if (isDriverWillingToRentSuitableVehicle(selectedDriver.willingToRentVehicleTypes, this.enum)) {
            return true;
        }
        const availableVehicles = await this.getDriverSuitableOwnedVehicles(selectedDriver);
        return availableVehicles.length > 0;
    }

    private async getDriverSuitableOwnedVehicles(driver: SimpleDriver) {
        const vehicleOwnersIds: string[] = [];
        const locations = await this.rpcClient.content.retrieveLocations({
            ids: [this.destinationLocationId, this.originLocationId],
        });

        if (driver.isCompany || (!driver.isCompany && !driver.isCompanyDriver)) {
            vehicleOwnersIds.push(driver._id);
        } else if (driver.isCompanyDriver && driver.companyUserId) {
            vehicleOwnersIds.push(driver.companyUserId);
        }

        const ownedVehicles = plainToClass(
            VehicleInfo,
            await this.rpcClient.vehicle.retrieveUsersVehiclesInfo(vehicleOwnersIds),
        );
        const suitableVehicles = ownedVehicles.filter(
            filterSuitableVehicleInfo(
                this.enum,
                this.requiredVehicleCapacity,
                locations.map((l) => l.countryId),
            ),
        );
        return suitableVehicles;
    }

    @action
    public async updateAssignationPrice() {
        if (this.assignationOperator) {
            const assignationPrice = await this.rpcClient.assignation.retrieveAssignationPrice(
                this.assignationOperator.m,
            );
            this.assignationOperator.price = assignationPrice.price;
        }
    }

    @action
    public async assignDriver(driverId: string, vehicleIndex: number) {
        if (this.data.simpleDrivers && driverId) {
            const assignation = new Assignation();
            assignation.orderId = this.orderId;
            assignation.userId = driverId;
            assignation.vehicleType = this.enum;
            assignation.vehicleIndex = vehicleIndex;

            const [driver] = await this.rpcClient.driver.retrieveSimpleDrivers({ ids: [driverId] });

            const suitableOwnedVehicles = await this.getDriverSuitableOwnedVehicles(driver);

            this.assignationOperator = new AssignationOperator({
                driver,
                vehicle: this.enum,
                modelConstructor: Assignation,
                model: assignation,
                modules: null,
                validateOptions: { skipMissingProperties: true },
                data: {
                    compensations: this.data.compensations as Array<Compensation>,
                    subsidies: this.data.subsidies as Array<Subsidy>,
                    penalties: this.data.penalties as Array<Penalty>,
                },
                onSave: async (model: Assignation) => {
                    try {
                        if (this.assignationOperator) {
                            model.createdAt = undefined as any;
                            this.assignationOperator.m._id = await this.rpcClient.assignation.createManualAssignation(
                                model,
                                this.preparedSubsidies,
                                this.preparedCompensations,
                                this.preparedPenalties,
                            );
                            this.assignationOperator.isSaved = true;

                            const updatedAssignation = await this.rpcClient.assignation.retrieveAssignation(
                                this.assignationOperator.m._id,
                            );

                            const [subsidies, compensations, penalties] = await Promise.all([
                                this.rpcClient.assignation.retrieveSubsidies({ ids: updatedAssignation.subsidyIds }),
                                this.rpcClient.assignation.retrieveCompensations({
                                    ids: updatedAssignation.compensationIds,
                                }),
                                this.rpcClient.assignation.retrievePenalties({ ids: updatedAssignation.penaltyIds }),
                            ]);

                            this.assignationOperator.data = { subsidies, compensations, penalties };
                            this.data = { ...this.data, ...this.assignationOperator.data };

                            this.assignationOperator.edit((a) => {
                                a.subsidyIds = updatedAssignation.subsidyIds;
                                a.compensationIds = updatedAssignation.compensationIds;
                                a.penaltyIds = updatedAssignation.penaltyIds;
                            });
                        }
                    } catch (e: any) {
                        let confirmationCode: string | undefined;
                        if (this.assignationOperator && this.assignationOperator.model) {
                            confirmationCode = this.assignationOperator.model.orderConfirmationCode;
                        }
                        alertError(e, "Unable to assign driver.", { confirmationCode });

                        this.assignationOperator = undefined;
                    }
                },
                newAssignation: true,
                price: this.price,
                pricingCurrency: this.pricingCurrency,
                availableVehicles: suitableOwnedVehicles?.map((v) => plainToClass(VehicleInfo, v)),
            });
        }
    }

    @observable
    public companyDrivers?: Array<SimpleDriver>;

    @observable
    public acceptationNote?: string;

    @action
    public updateAcceptationNote() {
        const make = this.vehicleMakes.find((vm) => vm._id === this.vehicleMakeIdToRent);
        const model = this.vehicleModels.find((vm) => vm._id === this.vehicleModelIdToRent);

        if (make !== undefined && model !== undefined) {
            this.acceptationNote = `${make.name} ${model.name}`;
        }
    }

    @computed
    public get saveAssignationDisabled(): boolean {
        if (!this.assignationOperator) {
            return true;
        }

        // disabled until driver and car/note are set in company accept assignation modal
        return (
            (this.assignationOperator.driver?.isCompany &&
                !(this.selectedDriverId && (this.newSelectedVehicleId || this.acceptationNote))) ||
            (!this.assignationOperator.driver?.isCompany && !(this.newSelectedVehicleId || this.acceptationNote))
        );
    }

    @action
    public async acceptAssignation() {
        if (this.assignationOperator) {
            this.isAssignationStatusProcessing = true;
            if (!this.isAcceptationModalVisible) {
                this.companyDrivers = this.data.simpleDrivers!.filter(
                    (sd) => sd.companyUserId && sd.companyUserId === this.assignationOperator!.driver?._id,
                );
                this.isAcceptationModalVisible = true;
            } else {
                this.isSaving = true;

                try {
                    await this.assignationOperator.accept(
                        this.acceptationNote || "accepted by management",
                        this.selectedDriverId,
                        this.newSelectedVehicleId || undefined,
                        this.rentalVehicle,
                    );
                } catch (e: any) {
                    if (typeof e === "string") {
                        alert(e);
                    }
                }

                this.isSaving = false;

                if (this.selectedDriverId && this.data.simpleDrivers && this.data.simpleDrivers.length) {
                    this.assignationOperator.driver = this.data.simpleDrivers.find(
                        (sd) => sd._id === this.selectedDriverId,
                    );
                }

                if (this.assignationOperator.availableVehicles) {
                    this.assignationOperator.vehicleTitle = this.newSelectedVehicleId
                        ? (
                              this.assignationOperator.availableVehicles.find(
                                  (av) => av._id === this.newSelectedVehicleId,
                              ) as VehicleInfo
                          ).title
                        : this.acceptationNote || "";
                }

                if (!this.assignationOperator.availableVehicles) {
                    const locations = await this.rpcClient.content.retrieveLocations({
                        ids: [this.destinationLocationId, this.originLocationId],
                    });
                    this.assignationOperator.availableVehicles = plainToClass(
                        VehicleInfo,
                        await this.rpcClient.vehicle.retrieveUsersVehiclesInfo([this.assignationOperator.driver!._id]),
                    ).filter(
                        filterSuitableVehicleInfo(
                            (this.assignationOperator as AssignationOperator).model.vehicleType,
                            this.requiredVehicleCapacity,
                            locations.map((l) => l.countryId),
                        ),
                    );
                }

                this.isAcceptationModalVisible = false;
            }
            this.isAssignationStatusProcessing = false;
        }
    }

    @action
    public cancelAcceptAssignationForCompany() {
        this.isAcceptationModalVisible = false;
    }

    @action
    public async cancelAssignation(cancellationInfo: CancellationInfo) {
        this.isAssignationStatusProcessing = true;
        await this.assignationOperator!.cancel(cancellationInfo);
        this.selectedDriverOption = undefined;
        this.assignationOperator = undefined;
        this.isAssignationStatusProcessing = false;
    }

    @observable
    public declinationReason = "";

    @observable
    public preparedSubsidies: Array<Subsidy> = [];

    @observable
    public isAddingSubsidyDisplayed: boolean = false;

    // todo what's going on? Looks like penalty and subsidy mixed up here
    @action
    public displayAddingSubsidy() {
        if (this.newSubsidyOperator == undefined) {
            this.newSubsidyOperator = new SubsidyOperator({
                modelConstructor: Subsidy,
                onSave: async (subsidy) => {
                    subsidy._id = uuid();
                    this.preparedSubsidies.push(subsidy);

                    this.isAddingSubsidyDisplayed = false;
                },
                modules: {},
                data: {},
            });
        }

        this.isAddingSubsidyDisplayed = true;
    }

    @action
    public hideAddingSubsidy() {
        this.isAddingSubsidyDisplayed = false;
    }

    @observable
    public newSubsidyOperator: SubsidyOperator;

    @action
    public removePreparedSubsidy(id: string) {
        this.preparedSubsidies = this.preparedSubsidies.filter((s) => s._id != id);
    }

    @observable
    public preparedPenalties: Array<Penalty> = [];

    @observable
    public isAddingPenaltyDisplayed: boolean = false;

    @action
    public displayAddingPenalty() {
        if (this.newPenaltyOperator == undefined) {
            this.newPenaltyOperator = new PenaltyOperator({
                modelConstructor: Penalty,
                onSave: async (penalty) => {
                    penalty._id = uuid();
                    this.preparedPenalties.push(penalty);

                    this.isAddingPenaltyDisplayed = false;
                },
                modules: {},
                data: {},
            });

            this.newPenaltyOperator.edit((p) => (p._id = uuid()));
        }

        this.isAddingPenaltyDisplayed = true;
    }

    @action
    public hideAddingPenalty() {
        this.isAddingPenaltyDisplayed = false;
    }

    @observable
    public newPenaltyOperator: PenaltyOperator;

    @action
    public removePreparedPenalty(id: string) {
        this.preparedPenalties = this.preparedPenalties.filter((p) => p._id !== id);
    }

    @observable
    public preparedCompensations: Array<Compensation> = [];

    @observable
    public isAddingCompensationDisplayed: boolean = false;

    @action
    public displayAddingCompensation() {
        if (this.newCompensationOperator == undefined) {
            this.newCompensationOperator = new CompensationOperator({
                modelConstructor: Compensation,
                onSave: async (compensation) => {
                    compensation._id = uuid();
                    this.preparedCompensations.push(compensation);

                    this.isAddingCompensationDisplayed = false;
                },
                modules: {},
                data: {},
            });

            this.newCompensationOperator.edit((c) => (c._id = uuid()));
        }

        this.isAddingCompensationDisplayed = true;
    }

    @action
    public hideAddingCompensation() {
        this.isAddingCompensationDisplayed = false;
    }

    @observable
    public newCompensationOperator: CompensationOperator;

    @action
    public removePreparedCompensation(id: string) {
        this.preparedCompensations = this.preparedCompensations.filter((c) => c._id !== id);
    }

    @action
    public clearPreparedFinancialOperations() {
        this.preparedSubsidies = [];
        this.preparedPenalties = [];
        this.preparedCompensations = [];
    }

    // post to APP
    @observable
    public isPostToAppModalOpened = false;

    @observable
    public isCreatePublicOfferModalVisible = false;

    @observable
    public selectedPassengersIndexes: number[] = [];

    @action
    public selectPassengers(passengersIndexes: Array<number>): void {
        this.selectedPassengersIndexes = passengersIndexes;
    }

    @action
    public togglePostToAppModal() {
        this.isPostToAppModalOpened = !this.isPostToAppModalOpened;
    }

    public toggleCreatePublicOfferModal() {
        this.isCreatePublicOfferModalVisible = !this.isCreatePublicOfferModalVisible;
    }

    @observable
    public isPostingToApp = false;

    @observable
    public isPostingToAppFailed = false;

    @action
    public async postToApp() {
        this.isPostingToApp = true;

        try {
            await this.rpcClient.order.postToApp(
                this.orderId,
                this.enum,
                this.availablePassengers != undefined
                    ? this.selectedPassengersIndexes.map((i) => (this.availablePassengers as Array<Passenger>)[i])
                    : undefined,
            );
        } catch (e: any) {
            this.isPostingToAppFailed = true;
        }

        this.isPostingToApp = false;
        this.isPostToAppModalOpened = false;
    }

    @computed
    public get isSedanLite() {
        return this.enum === VehicleType.SedanLite;
    }
}
