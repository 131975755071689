import { User } from "@legacy/models/User";
import { plainToClass } from "class-transformer";
import { action, observable } from "mobx";
import { globalManagementLogger } from "../../global-logger";

import { PageStore } from "../../stores/PageStore";

import { PasswordResetPageRouter } from "./PasswordResetPageRouter";

export class PasswordResetPageStore extends PageStore<PasswordResetPageRouter, {}> {
    @observable
    public user: User;

    @action.bound
    public async onFetchData() {
        let { userId } = this.pageRouter;
        const { userEmail } = this.pageRouter;

        if (this.authenticationStore.isDriver || this.authenticationStore.isDriversCompany) {
            userId = this.authenticationStore.userJWT?.userId;
        }

        try {
            if (userId) {
                this.user = plainToClass(User, await this.rpcClient.user.retrieveUser(userId));
            } else if (userEmail) {
                this.user = plainToClass(User, await this.rpcClient.user.retrieveUserByEmail(userEmail));
            }
        } catch (e: any) {
            return globalManagementLogger.error(e);
        }
    }

    public isDataFetched(): this is PasswordResetPageStore & PasswordResetPageStoreDataFetched {
        return !!this.user;
    }
}

export interface PasswordResetPageStoreDataFetched {
    user: User;
}
