import { VehicleStatus } from "@daytrip/legacy-enums";
import { transformPassengersCountToVehicleType } from "@daytrip/legacy-transformers";
import { Exclude, Expose } from "class-transformer";
import { IsArray, IsBoolean, IsDefined, IsUUID } from "class-validator";

import { Color } from "./Color";

@Exclude()
export class VehicleInfo {
    @IsUUID("4")
    @IsDefined()
    @Expose()
    public _id: string;

    @IsDefined()
    @Expose()
    public title: string;

    @IsDefined()
    @Expose()
    public manufactureYear: number;

    @IsDefined()
    @Expose()
    public seatsCount: number;

    @IsBoolean()
    @IsDefined()
    @Expose()
    public isLuxury: boolean = false;

    @IsDefined()
    @Expose()
    public color: Color;

    @Expose()
    public ownerUserId?: string;

    @IsArray()
    @Expose()
    public approvedForCountryIds: Array<string>;

    @IsArray()
    @Expose()
    public luxuryForCountryIds: Array<string>;

    @IsDefined()
    @Expose()
    public licencePlate: string;

    public get type() {
        return transformPassengersCountToVehicleType(this.seatsCount, this.isLuxury, false);
    }

    @Expose()
    public status: VehicleStatus;
}
