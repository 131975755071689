import { SimpleLocation } from "@legacy/domain/SimpleLocation";
import { Route } from "@legacy/models/Route";
import { TravelData } from "@legacy/models/TravelData";
import { VehicleTypePriceFee } from "@daytrip/legacy-models";
import { RouteGoogleCampaignOperator } from "../../operators/RouteGoogleCampaignOperator";

export function createRouteGoogleCampaignOperator(
    route: Route,
    vehicleTypePrice: VehicleTypePriceFee,
    travelData: TravelData,
    originLocation: SimpleLocation,
    destinationLocation: SimpleLocation,
): RouteGoogleCampaignOperator {
    return new RouteGoogleCampaignOperator({
        _id: "",
        campaignName: `${new Date().getFullYear()}/${`0${(new Date().getMonth() + 1).toString()}`.slice(-2)} ${
            originLocation.name
        } to ${destinationLocation.name}`,
        budget: 40,
        bid: 42.5,
        originLocationName: originLocation.name,
        destinationLocationName: destinationLocation.name,
        price: Math.ceil(
            (vehicleTypePrice.price +
                vehicleTypePrice.fee +
                route.additionalPrice +
                route.additionalFee +
                Math.ceil((route.tollPrice + route.tollFee) * 2)) /
                7,
        ),
        duration: travelData.duration,
        landingUrl: `https://daytrip.com/landing/${originLocation.machineName}-to-${destinationLocation.machineName}`,
        keywordBid: 30000, // microAmount,
        startDate: new Date(),
        routeId: route._id,
        originMachineName: originLocation.machineName,
        destinationMachineName: destinationLocation.machineName,
        campaignId: "",
        isOtherDirection: false,
        targetingLocations: [],
    });
}
