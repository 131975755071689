import { ObservableQuery, QueryOptions } from "@apollo/client";
import type { GraphQLError } from "graphql";
import { action, observable, runInAction } from "mobx";

export function initDefaultValue<T>(initializer: () => T) {
    return initializer();
}

type ObservableQueryOptions<TVariables> = Omit<QueryOptions<TVariables>, "variables" | "query">;
type QueryHandler<TVariables, TQueryResult> = (
    variables: TVariables,
    options?: ObservableQueryOptions<TVariables>,
) => Promise<TQueryResult>;
type ObservableQueryHandler<TVariables, TQueryResult> = (variables: TVariables) => ObservableQuery<TQueryResult>;
export type QueryOperation<TVariables, TQueryResult> =
    | QueryHandler<TVariables, TQueryResult>
    | ObservableQueryHandler<TVariables, TQueryResult>;

type Subscription = { unsubscribe: () => void };

export class ObservableGqlQuery<TQueryResult, TVariables = undefined, TResult = TQueryResult> {
    @observable public errors?: GraphQLError[];

    @observable public loading = false;

    @observable public value: TResult | null;

    @observable public called = false;

    private queryFn: QueryOperation<TVariables, TQueryResult>;

    private activeSubscription?: Subscription;

    public constructor(queryOperation: QueryOperation<TVariables, TQueryResult>) {
        this.queryFn = queryOperation;
    }

    @action public async execute(
        variables?: TVariables,
        options?: ObservableQueryOptions<TVariables>,
    ): Promise<TResult | null> {
        this.loading = true;
        try {
            const result = this.queryFn(variables as TVariables, options);

            if ("subscribe" in result) {
                return this.handleObservableQuery(result);
            }

            return this.handlerQueryResult(await result);
        } catch (err: any) {
            runInAction(() => {
                this.errors = err as GraphQLError[];
            });
            throw err;
        } finally {
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    @action private handlerQueryResult(result: TQueryResult) {
        this.value = result ? (result as any) : null;

        if (!this.called) {
            this.called = true;
        }

        return this.value;
    }

    @action private async handleObservableQuery(observableQuery: ObservableQuery<TQueryResult>) {
        return new Promise<TResult | null>((resolve) => {
            this.activeSubscription = observableQuery.subscribe((queryResult) => {
                runInAction(() => {
                    // after first result indicate loading as completed
                    this.loading = false;
                });
                const value = this.handlerQueryResult(queryResult?.data);
                resolve(value);
            });
        });
    }

    @action setValue(value: TResult) {
        this.value = value;
    }

    @action reset() {
        if (this.activeSubscription) {
            this.activeSubscription.unsubscribe();
            this.activeSubscription = undefined;
        }
    }
}
