import { validatePhoneNumber } from "@daytrip/utils";
import { PassengerType } from "@legacy/domain/PassengerType";

import type { OrderOperator } from "../operators/OrderOperator";

export function validatePassengersPhoneNumbers(
    orderOperator: OrderOperator,
): { alertMessage: string; shouldNotSaveOrderWithInvalidPhones: boolean } | undefined {
    const passengerValidationPhoneNumbers: string[] = orderOperator.m.passengers
        .map((p, index) => {
            const validationMessage = validatePhoneNumber(p.phoneNumber, p.type !== PassengerType.Lead);
            if (validationMessage) {
                return `Invalid phone number for passenger index: ${index} with reason: ${validationMessage}.`;
            }
            return "";
        })
        .filter((msg) => msg.length > 0);

    if (passengerValidationPhoneNumbers.length > 0) {
        return {
            alertMessage: `Validation error.\nSome Passengers have invalid phone numbers.\n${passengerValidationPhoneNumbers.join(
                "\nSaving will continue for partner API booking",
            )}`,
            shouldNotSaveOrderWithInvalidPhones: !orderOperator.m.apiPartnerId,
        };
    }
}
