import autobind from "autobind-decorator";
import { observable, action } from "mobx";

import { VERSION_URL } from "../../config.management";
import { PageRouter } from "../../PageRouter";
import { PageStore } from "../../stores/PageStore";

@autobind
export class IndexPageStore extends PageStore<PageRouter<{}>> {
    @observable
    public serverVersion?: string;

    @action
    public async setServerVersion() {
        this.serverVersion = await (await fetch(VERSION_URL)).text();
    }
}
