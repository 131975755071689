import { action, computed, observable } from "mobx";

import { getApolloSdk } from "../../apolloClient/apolloClient";

import { DriverCountryRuleForEdit } from "./types";

export class DriverCountryRuleOperator implements DriverCountryRuleForEdit {
    @observable
    canAcceptAssignationOffers: boolean;

    @observable
    canBeAutomaticallyAssigned: boolean;

    @observable
    destinationCountryId?: string | undefined;

    @observable
    driverCountryId?: string | undefined;

    @observable
    originCountryId?: string | undefined;

    @computed get canSave() {
        return !!this.driverCountryId && !!this.originCountryId && !!this.destinationCountryId;
    }

    constructor(init: DriverCountryRuleForEdit) {
        Object.assign(this, init);
    }

    @action async create() {
        await getApolloSdk().CreateDriverCountryRule({
            input: {
                canAcceptAssignationOffers: this.canAcceptAssignationOffers,
                canBeAutomaticallyAssigned: this.canBeAutomaticallyAssigned,
                destinationCountryId: this.destinationCountryId!,
                driverCountryId: this.driverCountryId!,
                originCountryId: this.originCountryId!,
            },
        });
    }

    @action async update() {
        await getApolloSdk().UpdateDriverCountryRule({
            driverCountryId: this.driverCountryId!,
            originCountryId: this.originCountryId!,
            destinationCountryId: this.destinationCountryId!,
            update: {
                canAcceptAssignationOffers: this.canAcceptAssignationOffers,
                canBeAutomaticallyAssigned: this.canBeAutomaticallyAssigned,
            },
        });
    }
}
