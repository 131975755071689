import { TransformToDate } from "@daytrip/legacy-transformers";
import { Expose } from "class-transformer";
import { IsDefined, IsNumber, IsString, IsUUID } from "class-validator";
import { v4 as uuid } from "uuid";

import { Currency } from "../domain/Currency";
import { GField, GInputType, GObjectType, GInt } from "../graphql";
import { CDBaseEntity, CDColumn, CDEntity, CDObjectIdColumn } from "../orm";

@GObjectType("CurrencyRate")
@CDEntity("currencyRates")
@GInputType("CurrencyRateInput")
export class CurrencyRate extends CDBaseEntity {
    @GField()
    @CDObjectIdColumn()
    @IsUUID("4")
    @Expose()
    public _id: string = uuid();

    @GField(() => GInt)
    @CDColumn()
    @IsDefined()
    @Expose()
    public type: Currency;

    @GField()
    @CDColumn()
    @IsNumber()
    @Expose()
    public rate: number;

    @GField(() => GInt)
    @CDColumn()
    @IsDefined()
    @Expose()
    public baseCurrency: Currency;

    @CDColumn()
    @Expose()
    @TransformToDate
    public date?: Date;

    @GField()
    @CDColumn()
    @IsString()
    @Expose()
    public dayMonthYearString: string;
}
