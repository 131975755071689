import { SimpleCountry } from "@legacy/domain/SimpleCountry";
import { UBO } from "@legacy/domain/UBO";
import autobind from "autobind-decorator";
import { action, computed, observable } from "mobx";

import { ModelOperator } from "./ModelOperator";
import { ModelOperatorOptions } from "./ModelOperatorOptions";

interface UBOOperatorOptions extends ModelOperatorOptions<UBO, {}, UBOOperatorData> {}

interface UBOOperatorData {
    simpleCountries: Array<SimpleCountry>;
}

interface UBOOperatorDataFetched extends UBOOperatorData {
    simpleCountries: Array<SimpleCountry>;
}

@autobind
export class UBOOperator extends ModelOperator<UBO, UBOOperatorOptions, {}, UBOOperatorData, UBOOperatorDataFetched> {
    @observable
    public birthAtDay: number;

    @observable
    public birthAtMonth: number;

    @observable
    public birthAtYear: number;

    @action
    public updateBirthAtDay(value: number) {
        this.birthAtDay = value;
        const model = this.m;
        if (model.birthdayAt != undefined) {
            const date = new Date(model.birthdayAt);
            this.birthAtMonth = date.getUTCMonth();
            this.birthAtYear = date.getUTCFullYear();
        }
        if (this.birthAtDay != undefined && this.birthAtMonth != undefined && this.birthAtYear != undefined) {
            this.updateBirthAt(this.birthAtDay, this.birthAtMonth, this.birthAtYear);
        }
    }

    @action.bound
    public updateBirthAtMonth(value: number) {
        this.birthAtMonth = value;
        const model = this.m;
        if (model.birthdayAt != undefined) {
            const date = new Date(model.birthdayAt);
            this.birthAtDay = date.getUTCDate();
            this.birthAtYear = date.getUTCFullYear();
        }
        if (this.birthAtDay != undefined && this.birthAtMonth != undefined && this.birthAtYear != undefined) {
            this.updateBirthAt(this.birthAtDay, this.birthAtMonth, this.birthAtYear);
        }
    }

    @action.bound
    public updateBirthAtYear(value: number) {
        this.birthAtYear = value;
        const model = this.m;
        if (model.birthdayAt != undefined) {
            const date = new Date(model.birthdayAt);
            this.birthAtDay = date.getUTCDate();
            this.birthAtMonth = date.getUTCMonth();
        }
        if (this.birthAtDay != undefined && this.birthAtMonth != undefined && this.birthAtYear != undefined) {
            this.updateBirthAt(this.birthAtDay, this.birthAtMonth, this.birthAtYear);
        }
    }

    @action.bound
    public updateBirthAt(day: number, month: number, year: number) {
        const date: Date = new Date(year, month, day, 9);
        if (date != undefined) {
            this.m.birthdayAt = date;
        }
    }

    @computed
    public get nationality(): string {
        const nationality = this.data.simpleCountries.find((sc) => sc.isoCode == this.m.nationalityCountryISOCode);
        return nationality != undefined ? nationality.englishName : "";
    }

    @computed
    public get addressCountry(): string {
        const addressCountry = this.data.simpleCountries.find((sc) => sc.isoCode == this.m.address.countryISOCode);
        return addressCountry != undefined ? addressCountry.englishName : "";
    }

    @computed
    public get birthplaceCountry(): string {
        const birthplaceCountry = this.data.simpleCountries.find((sc) => sc.isoCode == this.m.birtplace.countryISOCode);
        return birthplaceCountry != undefined ? birthplaceCountry.englishName : "";
    }
}
