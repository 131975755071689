import { ApolloClient, InMemoryCache } from "@apollo/client";
import { isTokenExpired } from "@legacy/utils/jwt";
import { TokenRefreshLink } from "apollo-link-token-refresh";

import { API_MNG_GQL_URL } from "../../config.management";
import { MUTATION_REFRESH_TOKENS } from "../../operations/authentication/refreshTokens.operation";
import {
    getManagementAuthenticationToken,
    getManagementRefreshToken,
    setManagementAuthenticationToken,
    setManagementRefreshToken,
} from "../../utils/authentication/authenticationUtils";

const fetchAccessToken = async () => {
    const client = new ApolloClient({
        cache: new InMemoryCache(),
        uri: API_MNG_GQL_URL,
    });
    const response = await client.mutate({
        mutation: MUTATION_REFRESH_TOKENS,
        variables: { refreshBoth: true },
        context: {
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${getManagementRefreshToken()}`,
            },
        },
    });
    return response.data;
};

const handleResponse = () => async (response: any) => ({
    tokens: {
        token: response.refreshTokens.authentication,
        refresh: response.refreshTokens.refresh,
    },
});

const handleFetch = (tokens: AuthTokenProvider) => {
    const { token, refresh } = tokens;
    if (token) {
        setManagementAuthenticationToken(token);
    }

    if (refresh) {
        setManagementRefreshToken(refresh);
    }
};

const isTokenValidOrUndefined = async () => {
    const token = getManagementAuthenticationToken();
    if (!token) {
        return true;
    }
    return !isTokenExpired(token, 1000);
};

export const tokenRefreshLink = () =>
    new TokenRefreshLink<{ authentication: string; refresh: string }>({
        accessTokenField: "tokens",
        fetchAccessToken,
        handleResponse,
        handleFetch,
        isTokenValidOrUndefined,
    });
