import type { SimpleCountry } from "@legacy/domain/SimpleCountry";
import { VehicleType } from "@legacy/domain/VehicleType";
import type { OrderCustomLocation } from "@daytrip/legacy-models";
import type { VehicleTypePriceFee } from "@daytrip/legacy-models";
import { getDefaultFeeCoefficient, getVehicleTypeFeeCoefficient } from "@legacy/utils/getVehicleTypeFeeCoefficient";
import autobind from "autobind-decorator";
import { action, observable } from "mobx";

import { ModelOperator } from "./ModelOperator";
import type { ModelOperatorOptions } from "./ModelOperatorOptions";

type OrderCustomLocationOperatorOptions = ModelOperatorOptions<
    OrderCustomLocation,
    {},
    OrderCustomLocationOperatorData
>;

interface OrderCustomLocationOperatorData {
    countries: Array<SimpleCountry>;
}

interface OrderCustomLocationOperatorDataFetched {
    countries: Array<SimpleCountry>;
}

@autobind
export class CustomLocationOperator extends ModelOperator<
    OrderCustomLocation,
    OrderCustomLocationOperatorOptions,
    {},
    OrderCustomLocationOperatorData,
    OrderCustomLocationOperatorDataFetched
> {
    @observable
    public opened: boolean = false;

    @action
    public close() {
        this.opened = false;
    }

    @action
    public open() {
        this.opened = true;
    }

    @action
    public orderDown() {
        this.edit((cl) => {
            cl.order -= 1;
        });
    }

    @action
    public orderUp() {
        this.edit((cl) => {
            cl.order += 1;
        });
    }

    @action
    public orderSet(newOrder: number) {
        this.edit((cl) => {
            cl.order = newOrder;
        });
    }

    @action
    public setDuration(duration: number) {
        this.edit((cl) => (cl.duration = duration));
    }

    @action
    public setVehiclePrice(vehicleType: VehicleType, vehiclePrice: number, pricingCountryId: string) {
        let vehicleTypePriceFeeSetPriceIndex = this.m.vehicleTypesPricesFees.findIndex(
            (vtpf) => vtpf.vehicleType === vehicleType,
        );

        if (vehicleTypePriceFeeSetPriceIndex === -1) {
            this.m.vehicleTypesPricesFees.push({
                vehicleType,
                price: 0,
                fee: 0,
                isManuallyModified: false,
            } as VehicleTypePriceFee);

            vehicleTypePriceFeeSetPriceIndex = this.m.vehicleTypesPricesFees.findIndex(
                (vtpf) => vtpf.vehicleType === vehicleType,
            );
        }

        const pricingCountry = this.data.countries.find((country) => country._id === pricingCountryId) as SimpleCountry;
        const feeCoefficient = getVehicleTypeFeeCoefficient(pricingCountry, vehicleType);
        const price = Math.round(vehiclePrice * feeCoefficient);
        const fee = Math.round(vehiclePrice - price);

        this.edit((cl) => {
            cl.vehicleTypesPricesFees[vehicleTypePriceFeeSetPriceIndex].price = price;
            cl.vehicleTypesPricesFees[vehicleTypePriceFeeSetPriceIndex].fee = fee;
            cl.vehicleTypesPricesFees[vehicleTypePriceFeeSetPriceIndex].isManuallyModified = true;
        });
    }

    @action
    public setExtraPrice(priceType: string, extraPrice: number, pricingCountryId: string) {
        const feeCoefficient = getDefaultFeeCoefficient(
            this.data.countries.find((country) => country._id === pricingCountryId) as SimpleCountry,
        );
        const price = Math.round(extraPrice * feeCoefficient);
        const fee = Math.round(extraPrice - price);

        this.edit((cl) => {
            cl[`${priceType}Price`] = price;
            cl[`${priceType}Fee`] = fee;
        });
    }
}
