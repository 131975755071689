import type { ValidationOptions } from "class-validator";
import { registerDecorator } from "class-validator";

export function MaxLengthExcludingParameters(
    excludedParameters: Array<object>,
    maxLength: number,
    validationOptions: ValidationOptions,
) {
    return function (object: Object, propertyName: string) {
        registerDecorator({
            name: "MaxLengthExcludingParameters",
            target: object.constructor,
            propertyName,
            options: validationOptions,
            validator: {
                validate(value: any) {
                    let currentValueLength = value.length;

                    excludedParameters.forEach((parameter: Record<string, any>) => {
                        if (value.indexOf(parameter.text) > -1) {
                            currentValueLength -= parameter.length;
                        }
                    });

                    return typeof value === "string" && currentValueLength <= maxLength;
                },
            },
        });
    };
}
