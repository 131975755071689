/* eslint-disable no-param-reassign */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-alert */
import type { ApiPartnerModel } from "@daytrip/api";
import { TaggableEntityType } from "@daytrip/legacy-enums";
import type { Tag } from "@daytrip/legacy-models";
import { Location } from "@daytrip/legacy-models";
import { Position } from "@daytrip/legacy-models";
import { VehicleTypePriceFee } from "@daytrip/legacy-models";
import { transformNameToMachineName } from "@daytrip/legacy-transformers";
import { transformValidationErrorsToArrayString } from "@daytrip/legacy-transformers";
import { transformVehicleTypeToString } from "@daytrip/legacy-transformers";
import { GoogleCampaignSettingType } from "@legacy/domain/GoogleCampaignSettingType";
import type { GoogleRouteCampaign } from "@legacy/domain/GoogleRouteCampaign";
import { PriceCalculator } from "@legacy/domain/PriceCalculator";
import { SimpleLocation } from "@legacy/domain/SimpleLocation";
import type { SimpleRoute } from "@legacy/domain/SimpleRoute";
import { SimpleTravelData } from "@legacy/domain/SimpleTravelData";
import { SimpleUser } from "@legacy/domain/SimpleUser";
import { VehicleType } from "@legacy/domain/VehicleType";
import { Country } from "@legacy/models/Country";
import { Region } from "@legacy/models/Region";
import { Route } from "@legacy/models/Route";
import { RouteLocation } from "@legacy/models/RouteLocation";
import { TravelData } from "@legacy/models/TravelData";
import { UpsellRoute } from "@legacy/models/UpsellRoute";
import type { VehicleTypeCosts } from "@legacy/models/VehicleTypeCosts";
import { Waypoint } from "@legacy/models/Waypoint";
import { RetrieveRoutesOptions } from "@legacy/options/RetrieveRoutesOptions";
import { getDefaultFeeCoefficient, getVehicleTypeFeeCoefficient } from "@legacy/utils/getVehicleTypeFeeCoefficient";
import autobind from "autobind-decorator";
import { classToPlain, plainToClass } from "class-transformer";
import type { ValidationError } from "class-validator";
import { validate } from "class-validator";
import throttle from "lodash/throttle";
import uniq from "lodash/uniq";
import { action, computed, observable, toJS } from "mobx";
import { Option } from "react-select-legacy";

import type { NotificationsStore } from "../../components/Notifications/NotificationsStore";
import type { StoreManager } from "../../container";
import { container, stores } from "../../container";
import { RouteGoogleCampaignOperator } from "../../operators/RouteGoogleCampaignOperator";
import { PageStore } from "../../stores/PageStore";
import { getTagParents } from "../../utils/getTagParents";
import { createRouteGoogleCampaignOperator } from "../../utils/googleCampaigns/createRouteGoogleCampaignOperator";
import { fillCampaignsCreateOptions } from "../../utils/googleCampaigns/fillCampaignsCreateOptions";
import { validateAds } from "../../utils/googleCampaigns/validateAds";

import type { RoutePageRouter } from "./RoutePageRouter";

@autobind
export class RoutePageStore extends PageStore<RoutePageRouter, {}> {
    @observable
    public openedRouteLocations: Array<string> = [];

    @action
    public routeLocationOpen(locationId: string) {
        if (!(this.editedRoute as Route).pricingCountryId) {
            alert("Select pricing country first.");
        }
        this.openedRouteLocations.push(locationId);
    }

    @action
    public routeLocationClose(locationId: string) {
        const newOpenedLocations = this.openedRouteLocations;
        const locationIndex = newOpenedLocations.findIndex((lid) => lid === locationId);
        newOpenedLocations.splice(locationIndex, 1);
        this.openedRouteLocations = newOpenedLocations;
    }

    protected getValidationMessage(propertyName: string): undefined | string {
        const validationErrors = this.routeValidationErrors.find((ve) => ve.property === propertyName);
        if (!validationErrors) {
            return undefined;
        }
        return JSON.stringify(validationErrors.constraints);
    }

    // content
    @observable
    public route?: Route;

    @observable
    public editedRoute?: Route;

    @observable
    public routeValidationErrors: Array<ValidationError> = [];

    @observable
    public locations: Array<SimpleLocation> = [];

    @observable
    public routeLocations: Array<Location> = [];

    @observable
    public countries?: Array<Country>;

    @observable
    public pricingCountry?: Country;

    @observable
    public regions?: Array<Region>;

    @observable
    public pricingRegion?: Region;

    @observable
    public simpleRoutes?: Array<SimpleRoute>;

    @observable
    public upsellRoutes?: Array<SimpleRoute>;

    @observable
    public routeTravelData?: TravelData;

    @observable
    public routeGoogleCampaigns?: Array<RouteGoogleCampaignOperator>;

    @observable
    public newRouteGoogleCampaign?: RouteGoogleCampaignOperator;

    @observable
    public isExistingCampaignSending = false;

    @observable
    public isExistingCampaignSendingFailed = false;

    @observable
    public existingCampaignSentAt?: Date;

    @observable
    public existingCampaignId: string = "";

    @observable
    public existingCampaignIsOtherDirection: boolean = false;

    @observable
    public existingCampaignAdwordsCustomerId: string = "";

    @observable
    public isCampaignCreating = false;

    @observable
    public isCampaignCreated = false;

    @observable
    public campaignsCreateOptions: Array<GoogleRouteCampaign> = [];

    @observable
    public campaingsToCreate: Array<GoogleRouteCampaign> = [];

    @observable
    public createAllCampaigns: boolean = false;

    @observable
    public baseRouteTravelData: SimpleTravelData;

    @observable
    public locationsTravelData: Array<SimpleTravelData> = [];

    @observable
    public createdByUser?: SimpleUser;

    @observable
    public googleCampaignSettingType: GoogleCampaignSettingType;

    @observable
    public showNoPricingRegionChosenModal: boolean = false;

    @observable
    public isNoPricingRegionIntentional: boolean = false;

    @observable
    public tags: Array<Tag> = [];

    @observable
    public apiPartners: ApiPartnerModel[] = [];

    @action
    public async addExistingCampaign(): Promise<void> {
        this.isExistingCampaignSendingFailed = false;
        this.isExistingCampaignSending = true;

        try {
            await this.rpcClient.googleCampaign.createExistingAdwordsCampaign(
                this.route!._id,
                this.existingCampaignId.trim(),
                this.existingCampaignIsOtherDirection,
                this.existingCampaignAdwordsCustomerId.trim(),
                this.googleCampaignSettingType,
            );
            this.isExistingCampaignSending = false;
            this.existingCampaignSentAt = new Date();
            await this.fetchGoogleRouteCampaigns();

            setTimeout(() => {
                this.existingCampaignSentAt = undefined;
            }, 2000);
        } catch (e: any) {
            this.isExistingCampaignSending = false;
            this.isExistingCampaignSendingFailed = true;
        }
    }

    @observable
    public upsellRoutesGenerating = false;

    @observable
    public upsellRoutesGenerated = false;

    public originalRoute?: Route;

    public async generateUpsellRoutes(): Promise<void> {
        if (this.route) {
            this.upsellRoutesGenerating = true;

            await this.rpcClient.content.generateUpsellRoutes(this.route._id);
            await this.fetchContent();

            this.upsellRoutesGenerating = false;
            this.upsellRoutesGenerated = true;

            setTimeout(() => {
                this.upsellRoutesGenerated = false;
            }, 3500);
        } else {
            alert("Couldn't generate upsell routes because route is undefined. Make sure you saved created route.");
        }
    }

    public async copyRoute() {
        if (!this.route) {
            return;
        }

        this.originalRoute = { ...this.route, isReady: false, isLive: false } as Route;
        this.route = undefined;
        this.pageRouter.gotoRoutePage();
        await this.fetchContent();
    }

    @action
    public async onFetchData() {
        await this.fetchContent();
    }

    @action
    public async fetchContent() {
        const { routeId } = this.pageRouter;
        // Array<Location> that this route contains
        this.routeLocations = [];

        await Promise.all([this.setCountries(), this.setRegions()]);

        if (!routeId) {
            await this.createRoute();
        } else {
            await this.viewRoute();
        }

        await Promise.all([this.routeValidate(), this.fetchTags(), this.fetchApiPartners()]);
    }

    protected async viewRoute() {
        await this.fetchExistingRoute();

        if (this.route) {
            if (this.originLocation && this.destinationLocation && this.routeTravelData) {
                const vehicleTypePrice = this.route.vehicleTypesPricesFees.find(
                    (vtpf) => vtpf.vehicleType === VehicleType.Van || vtpf.vehicleType === VehicleType.Shuttle,
                ) as VehicleTypePriceFee;

                if (vehicleTypePrice) {
                    this.newRouteGoogleCampaign = createRouteGoogleCampaignOperator(
                        this.route,
                        vehicleTypePrice,
                        this.routeTravelData,
                        this.originLocation,
                        this.destinationLocation,
                    );

                    this.campaingsToCreate = [];
                    this.campaignsCreateOptions = fillCampaignsCreateOptions(
                        this.newRouteGoogleCampaign,
                        this.googleCampaignSettingType,
                        this.originLocation,
                        this.destinationLocation,
                        this.originLocationCountry ? this.originLocationCountry.isoCode : "",
                        this.destinationLocationCountry ? this.destinationLocationCountry.isoCode : "",
                    );
                }
            }

            if (this.route.createdBy) {
                const fetchedUsers = await this.rpcClient.user.retrieveSimpleUsers({ userIds: [this.route.createdBy] });
                const [createdByUser] = fetchedUsers;
                this.createdByUser = createdByUser;
            }
        }

        const routeLocations = this.locations;
        await this.setNearestLocations();
        this.locations.push(...routeLocations);
    }

    protected async fetchExistingRoute() {
        const { routeId } = this.pageRouter;

        if (!routeId) {
            return;
        }

        try {
            this.route = await this.rpcClient.content.retrieveRoute(routeId);
        } catch (error: any) {
            alert(`Failed to load the route: ${error}`);
            return;
        }

        // load route locations first
        await this.setSimpleRouteLocations(this.route);
        await this.setRouteLocations(this.route);

        if (this.route.pricingRegionId) {
            this.pricingRegion = await this.rpcClient.content.retrieveRegion(this.route.pricingRegionId);
        }

        if (this.route.pricingCountryId) {
            this.pricingCountry = await this.rpcClient.content.retrieveCountry(this.route.pricingCountryId);

            this.googleCampaignSettingType = !this.pricingCountry
                ? GoogleCampaignSettingType.Europe
                : this.pricingCountry.googleCampaignSettingType || GoogleCampaignSettingType.Europe;
        }

        const originDestinationCountries = await this.rpcClient.content.retrieveCountries({
            ids: [
                (this.originLocation as SimpleLocation).countryId,
                (this.destinationLocation as SimpleLocation).countryId,
            ],
        });

        this.originLocationCountry = originDestinationCountries.find(
            (c) => c._id === (this.originLocation as SimpleLocation).countryId,
        );
        this.destinationLocationCountry = originDestinationCountries.find(
            (c) => c._id === (this.destinationLocation as SimpleLocation).countryId,
        );

        this.validateSimilarRouteExists(this.route);
        try {
            this.baseRouteTravelData = await this.rpcClient.content.calculateBaseRouteTravelData(
                this.route.originLocationId,
                this.route.destinationLocationId,
                this.route.waypoints,
            );
        } catch (err: any) {
            this.baseRouteTravelData = new SimpleTravelData();
            alert("Can not calculate baseRouteTravelData. Make sure that all positions are accessible");
        }

        await Promise.all(
            this.route.locations.map(async (location) => {
                const route = this.route as Route;
                let locationTravelData;

                try {
                    locationTravelData = await this.rpcClient.content.calculateRouteLocationTravelData(
                        location.locationId,
                        location.order,
                        route.originLocationId,
                        route.destinationLocationId,
                        route.waypoints,
                    );

                    if (locationTravelData) {
                        locationTravelData.locationId = location.locationId;
                        this.locationsTravelData.push(locationTravelData);
                    }
                } catch (e: any) {
                    alert("Cannot calculate RouteLocation travel data");
                }
            }),
        );

        this.routeTravelData = await this.retrieveTravelData();

        if (!this.routeTravelData) {
            // let calculateRoutePrices method fetch travel data from google
            this.route = await this.rpcClient.content.calculateRoutePrices(this.route, true);

            this.routeTravelData = await this.retrieveTravelData();

            if (!this.routeTravelData) {
                alert("Failed to get travel data for this route");
            }
        }

        await this.fetchGoogleRouteCampaigns();
    }

    private async retrieveTravelData(): Promise<TravelData | undefined> {
        const [travelData] = await this.rpcClient.content.retrieveTravelData({
            endpointIds: [
                (this.originLocation as SimpleLocation).locationId,
                (this.destinationLocation as SimpleLocation).locationId,
            ],
        });
        return travelData;
    }

    protected async createRoute() {
        let route: Route;
        if (this.originalRoute) {
            route = await this._createCopiedRoute();
        } else {
            route = this._createNewRoute();
            this.locations = [];
        }
        this.editedRoute = observable(route);
    }

    protected async _createCopiedRoute(): Promise<Route> {
        const editedRoute: Route = this.originalRoute as Route;
        editedRoute.originLocationId = "";
        editedRoute.destinationLocationId = "";
        await this.setRouteLocations(editedRoute);

        return editedRoute;
    }

    @action
    public addVehicleTypePrice(vehicleType: VehicleType) {
        if (!this.editedRoute) {
            return;
        }

        const existingVT = this.editedRoute.vehicleTypesPricesFees.findIndex(
            (rvtpf: VehicleTypePriceFee) => vehicleType === rvtpf.vehicleType,
        );

        if (existingVT > -1) {
            return;
        }

        const emptyVTPF = {
            vehicleType,
            price: 0,
            fee: 0,
            isManuallyModified: false,
        } as VehicleTypePriceFee;

        this.editedRoute.vehicleTypesPricesFees.push(Object.assign(emptyVTPF, {}));

        for (const location of this.editedRoute.locations) {
            const existingLocationVT = location.vehicleTypesPricesFees.findIndex(
                (rvtpf: VehicleTypePriceFee) => vehicleType === rvtpf.vehicleType,
            );

            if (existingLocationVT === -1) {
                location.vehicleTypesPricesFees.push(Object.assign(emptyVTPF, {}));
            }
        }
    }

    @action
    public removeVehicleTypePrice(vehicleType: VehicleType) {
        if (!this.editedRoute) {
            return;
        }

        this.editedRoute.vehicleTypesPricesFees = this.editedRoute.vehicleTypesPricesFees.filter(
            (vtpf: VehicleTypePriceFee) => vtpf.vehicleType !== vehicleType,
        );

        for (const location of this.editedRoute.locations) {
            location.vehicleTypesPricesFees = location.vehicleTypesPricesFees.filter(
                (vtpf: VehicleTypePriceFee) => vtpf.vehicleType !== vehicleType,
            );
        }
    }

    public isDataFetched(): this is RoutePageStore & RoutePageStoreDataFetched {
        const isDataFetched = !!(
            (this.route || this.editedRoute) &&
            (!this.route || this.routeGoogleCampaigns) &&
            //            (!this.route || this.newRouteGoogleCampaign) &&
            this.locations &&
            (!this.newRouteGoogleCampaign || this.campaignsCreateOptions) &&
            ((this.pageRouter.routeId && this.routeTravelData) || !this.pageRouter.routeId)
        );
        console.log(
            {
                isDataFetched,
                route: this.route,
                editedRoute: this.editedRoute,
                locations: this.locations,
                newRouteGoogleCampaign: this.newRouteGoogleCampaign,
                routeGoogleCampaigns: this.routeGoogleCampaigns,
                pageRouter: this.pageRouter,
                routeTravelData: this.routeTravelData,
            },
            !!(this.route || this.editedRoute),
            !!(!this.route || this.routeGoogleCampaigns),
            !!(!this.route || this.newRouteGoogleCampaign),
            !!this.locations,
            !!(!this.newRouteGoogleCampaign || this.campaignsCreateOptions),
            !!((this.pageRouter.routeId && this.routeTravelData) || !this.pageRouter.routeId),
        );

        return isDataFetched;
    }

    @observable
    public similarRoutes: SimpleRoute[] = [];

    private async validateSimilarRouteExists(route: Route, alertIfSimilarExists?: boolean): Promise<void> {
        const options: RetrieveRoutesOptions = {
            originLocationIds: [route.originLocationId, route.destinationLocationId],
            destinationLocationIds: [route.originLocationId, route.destinationLocationId],
            isBidirectional: true,
            notInRouteIds: [route._id],
        };

        this.similarRoutes = await this.rpcClient.content.retrieveSimpleRoutes(options);

        if (this.similarRoutes.length !== 0 && alertIfSimilarExists === true) {
            alert("Warning: Route with same origin and destination already esists.");
        }
    }

    public async fetchSimpleRoutes(): Promise<void> {
        this.simpleRoutes = await this.rpcClient.content.retrieveSimpleRoutes(new RetrieveRoutesOptions());
    }

    @observable
    public selectedLocation?: string;

    @action
    public routeSelectLocationToAdd(locationId: string) {
        this.selectedLocation = locationId;
    }

    public getCountryForLocation(location: Location | SimpleLocation): Country | undefined {
        return this.countries && (this.countries.find((c) => c._id === location.countryId) as Country);
    }

    @action
    public async fetchGoogleRouteCampaigns() {
        this.routeGoogleCampaigns = [];

        const campaigns = await this.rpcClient.googleCampaign.retrieveGoogleRouteCampaigns([this.route!._id]);

        if (campaigns && campaigns.length > 0) {
            campaigns.forEach((campaign) => {
                if (this.routeGoogleCampaigns) {
                    const routeCampaignOperator = new RouteGoogleCampaignOperator(campaign);
                    this.routeGoogleCampaigns.push(routeCampaignOperator);
                }
            });
        }
    }

    public selectCamapignsToCreate(options: Array<Option>) {
        this.campaingsToCreate = [];

        options.forEach((option) => {
            const campaign = this.campaignsCreateOptions.find(({ campaignName }) => campaignName === option.value);

            if (campaign) {
                this.campaingsToCreate.push(campaign);
            }
        });
    }

    @computed
    public get routeThisDirectionGoogleCampaigns(): Array<RouteGoogleCampaignOperator> | undefined {
        if (this.routeGoogleCampaigns) {
            return this.routeGoogleCampaigns.filter((campaign) => !campaign.routeGoogleCampaign.isOtherDirection);
        }
        return undefined;
    }

    @computed
    public get routeOtherDirectionGoogleCampaigns(): Array<RouteGoogleCampaignOperator> | undefined {
        if (this.routeGoogleCampaigns) {
            return this.routeGoogleCampaigns.filter((campaign) => campaign.routeGoogleCampaign.isOtherDirection);
        }
        return undefined;
    }

    @action
    public autoSelectPricingCountry() {
        const route = this.editedRoute as Route;

        if (!route.originLocationId || !route.destinationLocationId || !this.isDataFetched()) {
            return;
        }

        const origin: SimpleLocation = this.locations.find(
            (location) => location.locationId === route.originLocationId,
        ) as SimpleLocation;
        const originCountry = this.countries.find((country: Country) => origin.countryId === country._id) as Country;

        const destination: SimpleLocation = this.locations.find(
            (location) => location.locationId === route.destinationLocationId,
        ) as SimpleLocation;
        const destinationCountry = this.countries.find(
            (country: Country) => destination.countryId === country._id,
        ) as Country;

        if (originCountry.guidePrice > destinationCountry.guidePrice) {
            this.routeUpdatePricingCountryId(destinationCountry._id);
        } else {
            this.routeUpdatePricingCountryId(originCountry._id);
        }
    }

    @computed
    public get isOriginLocationLiveValidationMessage(): string | undefined {
        if (this.originLocation && !this.originLocation.isLive) {
            return "Origin location is not live!";
        }

        return undefined;
    }

    @computed
    public get originLocationValidationMessage(): string | undefined {
        let validationMessage = this.isOriginLocationLiveValidationMessage;
        const validationString = this.getValidationMessage("originLocationId");

        if (validationString) {
            validationMessage = validationMessage ? `${validationMessage}\n${validationString}` : validationString;
        }

        return validationMessage;
    }

    @computed
    public get isDestinationLocationLiveValidationMessage(): string | undefined {
        if (this.destinationLocation && !this.destinationLocation.isLive) {
            return "Destination location is not live!";
        }

        return undefined;
    }

    @computed
    public get destinationLocationValidationMessage(): string | undefined {
        let validationMessage = this.isDestinationLocationLiveValidationMessage;
        const validationString = this.getValidationMessage("destinationLocationId");

        if (validationString) {
            validationMessage = validationMessage ? `${validationMessage}\n${validationString}` : validationString;
        }

        return validationMessage;
    }

    @action
    public routeUpdatePricingCountryId(value: string | undefined) {
        if ((this.editedRoute as Route).pricingCountryId !== value) {
            this.routeUpdatePricingRegionId(undefined);
        }

        (this.editedRoute as Route).pricingCountryId = value!;
    }

    @computed
    public get pricingCountryValidationMessage(): string | undefined {
        return this.getValidationMessage("pricingCountryId");
    }

    @action
    public routeUpdatePricingRegionId(value?: string) {
        (this.editedRoute as Route).pricingRegionId = value;

        if (this.route) {
            (this.route as Route).pricingRegionId = value;
        }
    }

    @computed
    public get pricingRegionValidationMessage(): string | undefined {
        return this.getValidationMessage("pricingRegionId");
    }

    @action
    public routeUpdatePublicTransportDuration(value: number | null) {
        (this.editedRoute as Route).publicTransportDuration = value;
    }

    @computed
    public get publicTransportDurationValidationMessage(): string | undefined {
        return this.getValidationMessage("publicTransportDuration");
    }

    @action
    public routeUpdateRouteType(value: number) {
        (this.editedRoute as Route).type = value;
    }

    @computed
    public get routeTypeValidationMessage(): string | undefined {
        return this.getValidationMessage("type");
    }

    @action
    public routeUpdateNote(value: string) {
        (this.editedRoute as Route).note = value;
    }

    @computed
    public get noteValidationMessage(): string | undefined {
        return this.getValidationMessage("note");
    }

    @action
    public routeUpdateIsReady(value: boolean) {
        (this.editedRoute as Route).isReady = value;
        this.routeValidate();
    }

    @action
    public routeUpdateIsLive(value: boolean) {
        (this.editedRoute as Route).isLive = value;
        this.routeValidate();
    }

    @action
    public routeUpdateIsAirportTransfer(value: boolean) {
        (this.editedRoute as Route).isAirportTransfer = value;
    }

    @action
    public routeUpdateIsMeetAndGreet(value: boolean) {
        (this.editedRoute as Route).isMeetAndGreet = value;
    }

    @computed
    public get isAirportTransferValidationMessage(): string | undefined {
        return this.getValidationMessage("isAirportTransfer");
    }

    @action
    public routeUpdateApiPartnerIds(value: string[]) {
        (this.editedRoute as Route).noMeetAndGreetApiPartnerIds = value;
        this.routeValidate();
    }

    @computed
    public get routeNoMeetAndGreetApiPartnerIds(): string | undefined {
        return this.getValidationMessage("noMeetAndGreetApiPartnerIds");
    }

    @action
    public routeUpdateIsSuggested(value: boolean) {
        (this.editedRoute as Route).isSuggested = value;
    }

    @action
    public setUpsellReturnTrip(value: boolean) {
        (this.editedRoute as Route).upsellReturnTrip = value;
    }

    @action
    public setUpsellReturnTripInOtherDirection(value: boolean) {
        (this.editedRoute as Route).upsellReturnTripInOtherDirection = value;
    }

    @observable
    public isAvailableFromDatePickerOpened = false;

    @action
    public routeUpdateAvailableFrom(value: Date | undefined) {
        if (value) {
            value.setUTCHours(0, 0, 0, 0);
        }
        (this.editedRoute as Route).availableFrom = value;
        this.isAvailableFromDatePickerOpened = false;
    }

    @action
    public toggleAvailableFromDatePicker() {
        this.isAvailableFromDatePickerOpened = !this.isAvailableFromDatePickerOpened;
    }

    @action
    public routeUpdateIsBidirectional(value: boolean) {
        (this.editedRoute as Route).isBidirectional = value;
    }

    @action
    public routeUpdateIsGenerated(value: boolean) {
        (this.editedRoute as Route).isGenerated = value;
    }

    @action
    public routeUpdateIsVisited(value: boolean) {
        (this.editedRoute as Route).isVisited = value;
    }

    @action
    public routeUpdateIsReviewed(value: boolean) {
        (this.editedRoute as Route).isReviewed = value;
    }

    @action
    public routeUpdateIsApiAvailable(value: boolean) {
        (this.editedRoute as Route).isApiAvailable = value;
    }

    @action
    public routeUpdateVehicleTypePrice(vehicleType: VehicleType, value: number) {
        if (!(this.editedRoute as Route).pricingCountryId) {
            alert("Select pricing country first.");
            return;
        }

        let pricingRegion: Region | undefined;
        if (this.editedRoute?.pricingRegionId) {
            pricingRegion = (this.regions as Array<Region>).find(
                (region) => region._id === (this.editedRoute as Route).pricingRegionId,
            );
        }
        const pricingCountry = (this.countries as Array<Country>).find(
            (country) => country._id === (this.editedRoute as Route).pricingCountryId,
        ) as Country;

        let vehicleTypePriceFeeIndex = (this.editedRoute as Route).vehicleTypesPricesFees.findIndex(
            (vtpf) => vtpf.vehicleType === vehicleType,
        );

        if (vehicleTypePriceFeeIndex === -1) {
            // if vehicle type costs for this vehicle type are not set in pricing country
            if (!pricingCountry.vehicleTypeCosts.find((vtc: VehicleTypeCosts) => vtc.vehicleType === vehicleType)) {
                alert(`First you have to set ${transformVehicleTypeToString(vehicleType)} costs for pricing country.`);
                return;
            }

            if (
                pricingRegion &&
                !pricingRegion.vehicleTypeCosts.find((vtc: VehicleTypeCosts) => vtc.vehicleType === vehicleType)
            ) {
                alert(`First you have to set ${transformVehicleTypeToString(vehicleType)} costs for pricing region.`);
                return;
            }

            (this.editedRoute as Route).vehicleTypesPricesFees.push({
                vehicleType,
                price: 0,
                fee: 0,
                isManuallyModified: false,
            } as VehicleTypePriceFee);

            vehicleTypePriceFeeIndex = (this.editedRoute as Route).vehicleTypesPricesFees.findIndex(
                (vtc) => vtc.vehicleType === vehicleType,
            );
        }

        const newVehicleTypesPricesFees = (this.editedRoute as Route).vehicleTypesPricesFees;

        const { price, fee } = PriceCalculator.calculatePriceAndFeeFromValue(
            value,
            getVehicleTypeFeeCoefficient(pricingRegion ?? pricingCountry, vehicleType),
        );

        newVehicleTypesPricesFees[vehicleTypePriceFeeIndex].price = price;
        newVehicleTypesPricesFees[vehicleTypePriceFeeIndex].fee = fee;

        newVehicleTypesPricesFees[vehicleTypePriceFeeIndex].isManuallyModified = true;
        (this.editedRoute as Route).vehicleTypesPricesFees = newVehicleTypesPricesFees;
    }

    @computed
    public get vehicleTypesPricesFeesValidationMessage(): string | undefined {
        return this.getValidationMessage("vehicleTypesPricesFees");
    }

    @action
    public routeUpdateAdditionalPrice(value: number) {
        if (!(this.editedRoute as Route).pricingCountryId) {
            alert("Select pricing country first.");
            return;
        }
        const feeCoefficient = getDefaultFeeCoefficient(
            (this.countries as Array<Country>).find(
                (country) => country._id === (this.editedRoute as Route).pricingCountryId,
            ) as Country,
        );
        (this.editedRoute as Route).additionalFee = PriceCalculator.calculateFeeFromPrice(value, feeCoefficient);
        (this.editedRoute as Route).additionalPrice = value;
    }

    @computed
    public get additionalPriceValidationMessage(): string | undefined {
        return this.getValidationMessage("additionalPrice");
    }

    @action
    public routeUpdateAdditionalFee(value: number) {
        (this.editedRoute as Route).additionalFee = value;
    }

    @computed
    public get additionalFeeValidationMessage(): string | undefined {
        return this.getValidationMessage("additionalFee");
    }

    @action
    public routeUpdateTollPrice(value: number) {
        if (!(this.editedRoute as Route).pricingCountryId) {
            alert("Select pricing country first.");
            return;
        }

        let toll = { price: 0, fee: 0 };

        if (this.pricingCountry?.applyTollPriceCoefficient) {
            toll.price = value;
            toll.fee = toll.price * 0.25;
        } else {
            const feeCoefficient = getDefaultFeeCoefficient(
                (this.countries as Array<Country>).find(
                    (country) => country._id === (this.editedRoute as Route).pricingCountryId,
                ) as Country,
            );
            toll = PriceCalculator.calculatePriceAndFeeFromValue(value, feeCoefficient);
        }

        (this.editedRoute as Route).tollPrice = toll.price;
        (this.editedRoute as Route).tollFee = toll.fee;
    }

    @computed
    public get tollPriceValidationMessage(): string | undefined {
        return this.getValidationMessage("tollPrice");
    }

    @computed
    public get tollValue(): number {
        const route = this.editedRoute ? this.editedRoute : (this.route as Route);

        if (this.pricingCountry?.applyTollPriceCoefficient) {
            return route.tollPrice;
        }

        return route.tollPrice + route.tollFee;
    }

    public originLocationCountry?: Country;

    public destinationLocationCountry?: Country;

    @computed
    public get notLiveLocations(): SimpleLocation[] {
        const routeLocations: SimpleLocation[] = [];

        const route = this.editedRoute ?? this.route;
        if (!route || route.locations.length === 0) {
            return [];
        }

        route.locations.forEach((routeLocation) => {
            const location = this.locations.find((l) => l.locationId === routeLocation.locationId);
            if (location) {
                routeLocations.push(location);
            }
        });

        return routeLocations.filter((routeLocation) => !routeLocation.isLive);
    }

    @computed
    public get locationsValidationErrors(): ValidationError[] {
        return this.routeValidationErrors.filter((rv) => rv.property === "locations");
    }

    @computed
    private get isCountryWithRegionsButNoneChosen(): boolean {
        if (this.editedRoute?.pricingRegionId || !this.regions) {
            return false;
        }

        return this.regions.some((r) => r.countryId === this.editedRoute?.pricingCountryId);
    }

    @computed
    private get isNoPricingRegionNoteAttached(): boolean {
        return !!this.editedRoute?.note?.includes("No pricing region");
    }

    @action
    public routeUpdateLocationRemove(value: string) {
        if (this.editedRoute && this.locations) {
            let newLocations = this.editedRoute.locations.sort((a, b) => (a.order > b.order ? 1 : -1));
            const locationIndex = newLocations.findIndex((routeLocation) => routeLocation.locationId === value);
            const newLocation = new RouteLocation();
            const location = this.editedRoute.locations[locationIndex];

            if (
                newLocation.defaultDuration !== location.defaultDuration ||
                newLocation.additionalPrice !== location.additionalPrice ||
                newLocation.additionalFee !== location.additionalFee ||
                newLocation.tollPrice !== location.tollPrice ||
                newLocation.tollFee !== location.tollFee
                // TODO check vehicle prices too
            ) {
                if (!confirm("Do you really want to remove this location?")) {
                    return;
                }
            }

            const locationsBeforeChanged = newLocations.slice(0, locationIndex);
            let locationsAfterChanged = newLocations.slice(locationIndex + 1);

            locationsAfterChanged = locationsAfterChanged.map((routeLocation) => {
                if (!this.editedRoute) {
                    // eslint-disable-next-line no-console
                    console.log("No edited route.");
                } else {
                    routeLocation.order -= 1;
                }
                return routeLocation;
            });

            newLocations = locationsBeforeChanged.concat(locationsAfterChanged);

            (this.editedRoute as Route).locations = newLocations;
            this.routeLocations = this.routeLocations.filter(
                (l) => newLocations.map((nl) => nl.locationId).indexOf(l._id) > -1,
            );
        }

        this.routeValidate();
    }

    @action
    public routeUpdateWaypointRemove(value: string) {
        if (this.editedRoute && confirm("Do you really want to remove this waypoint?")) {
            let newWaypoints = this.editedRoute.waypoints.sort((a, b) => (a.order > b.order ? 1 : -1));
            const waypointIndex = newWaypoints.findIndex((wp) => wp._id === value);

            const waypointsBeforeChanged = newWaypoints.slice(0, waypointIndex);
            let waypointsAfterChanged = newWaypoints.slice(waypointIndex + 1);

            waypointsAfterChanged = waypointsAfterChanged.map((wp) => {
                wp.order -= 1;
                return wp;
            });

            newWaypoints = waypointsBeforeChanged.concat(waypointsAfterChanged);

            (this.editedRoute as Route).waypoints = newWaypoints;
        }
    }

    @action
    public routeWaypointUpdate(index: number, position: Position) {
        if (this.editedRoute) {
            if (this.editedRoute.waypoints[index]) {
                this.editedRoute.waypoints[index].position.latitude = position.latitude;
                this.editedRoute.waypoints[index].position.longitude = position.longitude;
            } else {
                // eslint-disable-next-line no-console
                console.log(`waypoint with index ${index} does not exist.`);
            }
        }
    }

    @action
    public routeWaypointAddAuto() {
        const route = this.editedRoute as Route;
        if (!this.locations || !route.originLocationId || !route.destinationLocationId) {
            return;
        }

        const originPosition = this.locations.find(
            (location) => location.locationId === route.originLocationId,
        )!.position;

        this.routeWaypointAdd(originPosition);
    }

    @action
    public routeWaypointAdd(position: Position) {
        if (this.editedRoute) {
            this.editedRoute.waypoints.push(
                new Waypoint(
                    position,
                    (this.editedRoute.waypoints.map((wp) => wp.order).sort((a, b) => b - a)[0] || 0) + 1,
                ),
            );
        }
    }

    @action
    public async routeUpdateLocationAdd(locationId: string) {
        if (!locationId) {
            return;
        }
        const newLocations = (this.editedRoute as Route).locations.sort((a, b) => (a.order > b.order ? 1 : -1));
        const location = (this.locations as Array<SimpleLocation>).find((l) => l.locationId === locationId);

        const routeVehicleTypes = (this.editedRoute as Route).vehicleTypesPricesFees.map(
            (rvtpf: VehicleTypePriceFee) => rvtpf.vehicleType,
        );

        const newLocation = new RouteLocation();
        newLocation.locationId = locationId;
        newLocation.order = (this.editedRoute as Route).locations.length;
        newLocation.defaultDuration = (location as SimpleLocation).defaultDuration;
        newLocation.vehicleTypesPricesFees = plainToClass(
            VehicleTypePriceFee,
            routeVehicleTypes.map((vehicleType: VehicleType) => ({
                vehicleType,
                price: 0,
                fee: 0,
            })) as Array<VehicleTypePriceFee>,
        );

        newLocations.push(newLocation);

        // calculate location detour travel data
        const route = this.editedRoute as Route;
        const locationTravelData = await this.rpcClient.content
            .calculateRouteLocationTravelData(
                newLocation.locationId,
                newLocation.order,
                route.originLocationId,
                route.destinationLocationId,
                route.waypoints,
            )
            .catch(() => alert("Cant calculate route location travel data"));

        if (locationTravelData) {
            (locationTravelData as SimpleTravelData).locationId = newLocation.locationId;

            this.locationsTravelData.push(locationTravelData as SimpleTravelData);
        }

        (this.editedRoute as Route).locations = newLocations;

        const routeLocation = await this.rpcClient.content.retrieveLocation(newLocation.locationId);
        this.routeLocations.push(routeLocation);

        (this.locations as Array<SimpleLocation>).push({
            locationId: routeLocation._id,
            countryId: routeLocation.countryId,
            name: routeLocation.name,
            localNames: routeLocation.localNames,
            machineName: routeLocation.machineName,
            otherNames: routeLocation.otherNames,
            airportCodes: routeLocation.airportCodes,
            defaultDuration: routeLocation.defaultDuration,
            position: routeLocation.position,
            isDestination: routeLocation.isDestination,
            isLocation: routeLocation.isLocation,
            isLive: routeLocation.isLive,
            score: routeLocation.score,
        } as SimpleLocation);

        this.routeValidate();

        // for new route pricing country is undefined, so locations are not displayed without pricing country
        if ((this.editedRoute as Route).pricingCountryId && !this.pricingCountry) {
            this.pricingCountry = await this.rpcClient.content.retrieveCountry(
                (this.editedRoute as Route).pricingCountryId,
            );
        }

        this.selectedLocation = undefined;
    }

    @action
    public routeUpdateLocationSetIsFirst(locationId: string, value: boolean) {
        const newLocations = (this.editedRoute as Route).locations.sort((a, b) => (a.order > b.order ? 1 : -1));
        const locationIndex = newLocations.findIndex((routeLocation) => routeLocation.locationId === locationId);
        newLocations[locationIndex].isFirst = value;

        (this.editedRoute as Route).locations = newLocations;
    }

    @action
    public routeUpdateLocationSetIsSuggested(locationId: string, value: boolean) {
        const newLocations = (this.editedRoute as Route).locations.sort((a, b) => (a.order > b.order ? 1 : -1));
        const locationIndex = newLocations.findIndex((routeLocation) => routeLocation.locationId === locationId);
        newLocations[locationIndex].isSuggested = value;

        (this.editedRoute as Route).locations = newLocations;
    }

    @action
    public routeUpdateWaypointSetOrder(waypointId: string, order: number) {
        let newWaypoints = (this.editedRoute as Route).waypoints.sort((a, b) => (a.order > b.order ? 1 : -1));

        const updatingWaypointOrder: number = (
            newWaypoints.find((waypoint: Waypoint) => waypoint._id === waypointId) as Waypoint
        ).order;

        newWaypoints = newWaypoints.map((waypoint: Waypoint) => {
            if (waypoint.order === order) {
                // order increased - moving down
                if (updatingWaypointOrder < order) {
                    waypoint.order -= 1;
                } else if (updatingWaypointOrder > order) {
                    // order decreased - moving up
                    waypoint.order += 1;
                }
            }

            if (waypoint._id === waypointId) {
                waypoint.order = order;
            }

            return waypoint;
        });

        (this.editedRoute as Route).waypoints = newWaypoints;
    }

    @action
    public routeUpdateLocationSetOrder(locationId: string, order: number) {
        let newLocations = (this.editedRoute as Route).locations.sort((a, b) => (a.order > b.order ? 1 : -1));

        const updatingWaypointOrder: number = (
            newLocations.find((location) => location.locationId === locationId) as RouteLocation
        ).order;

        newLocations = newLocations.map((location: RouteLocation) => {
            if (location.order === order) {
                // order increased - moving down
                if (updatingWaypointOrder < order) {
                    location.order -= 1;
                } else if (updatingWaypointOrder > order) {
                    // order decreased - moving up
                    location.order += 1;
                }
            }

            if (location.locationId === locationId) {
                location.order = order;
            }

            return location;
        });

        (this.editedRoute as Route).locations = newLocations;

        // sort route locations by order
        this.routeLocations = newLocations
            .sort((a, b) => (a.order > b.order ? 1 : -1))
            .map(
                (routeLocation) =>
                    (this.routeLocations as Array<Location>).find(
                        (location) => location._id === routeLocation.locationId,
                    ) as Location,
            );
    }

    @action
    public routeUpdateLocationDefaultDuration(locationId: string, value: number) {
        const newLocations = (this.editedRoute as Route).locations.sort((a, b) => (a.order > b.order ? 1 : -1));
        const locationIndex = newLocations.findIndex((routeLocation) => routeLocation.locationId === locationId);
        newLocations[locationIndex].defaultDuration = value;

        (this.editedRoute as Route).locations = newLocations;
    }

    @action
    public routeUpdateLocationVehiclePrice(locationId: string, vehicleType: VehicleType, value: number) {
        const newLocations = (this.editedRoute as Route).locations.sort((a, b) => (a.order > b.order ? 1 : -1));
        const locationIndex = newLocations.findIndex((routeLocation) => routeLocation.locationId === locationId);
        let vehicleTypePriceFeeIndex = newLocations[locationIndex].vehicleTypesPricesFees.findIndex(
            (vtpf) => vtpf.vehicleType === vehicleType,
        );

        if (vehicleTypePriceFeeIndex === -1) {
            newLocations[locationIndex].vehicleTypesPricesFees.push({
                vehicleType,
                price: 0,
                fee: 0,
                isManuallyModified: false,
            } as VehicleTypePriceFee);

            vehicleTypePriceFeeIndex = newLocations[locationIndex].vehicleTypesPricesFees.findIndex(
                (vtc) => vtc.vehicleType === vehicleType,
            );
        }

        let pricingRegion: Region | undefined;
        if (this.editedRoute?.pricingRegionId) {
            pricingRegion = (this.regions as Array<Region>).find(
                (region) => region._id === (this.editedRoute as Route).pricingRegionId,
            );
        }
        const pricingCountry = (this.countries as Array<Country>).find(
            (country) => country._id === (this.editedRoute as Route).pricingCountryId,
        ) as Country;
        const feeCoeficient = getVehicleTypeFeeCoefficient(pricingRegion ?? pricingCountry, vehicleType);

        const { price, fee } = PriceCalculator.calculatePriceAndFeeFromValue(value, feeCoeficient);

        newLocations[locationIndex].vehicleTypesPricesFees[vehicleTypePriceFeeIndex].price = price;
        newLocations[locationIndex].vehicleTypesPricesFees[vehicleTypePriceFeeIndex].fee = fee;

        newLocations[locationIndex].vehicleTypesPricesFees[vehicleTypePriceFeeIndex].isManuallyModified = true;

        (this.editedRoute as Route).locations = newLocations;
    }

    @action
    public routeUpdateLocationUpdateAdditionalPrice(locationId: string, price: number) {
        const newLocations = (this.editedRoute as Route).locations.sort((a, b) => (a.order > b.order ? 1 : -1));
        const feeCoefficient = getDefaultFeeCoefficient(
            (this.countries as Array<Country>).find(
                (country) => country._id === (this.editedRoute as Route).pricingCountryId,
            ) as Country,
        );

        const locationIndex = newLocations.findIndex((routeLocation) => routeLocation.locationId === locationId);

        newLocations[locationIndex].additionalPrice = Math.round(price);
        newLocations[locationIndex].additionalFee = Math.round(
            PriceCalculator.calculateFeeFromPrice(price, feeCoefficient),
        );

        (this.editedRoute as Route).locations = newLocations;
    }

    @action
    public routeUpdateLocationUpdateAdditionalFee(locationId: string, fee: number) {
        const newLocations = (this.editedRoute as Route).locations.sort((a, b) => (a.order > b.order ? 1 : -1));
        const locationIndex = newLocations.findIndex((routeLocation) => routeLocation.locationId === locationId);
        newLocations[locationIndex].additionalFee = Math.round(fee);

        (this.editedRoute as Route).locations = newLocations;
    }

    @action
    public routeUpdateLocationUpdateTollPrice(locationId: string, value: number) {
        const newLocations = (this.editedRoute as Route).locations.sort((a, b) => (a.order > b.order ? 1 : -1));
        const feeCoefficient = getDefaultFeeCoefficient(
            (this.countries as Array<Country>).find(
                (country) => country._id === (this.editedRoute as Route).pricingCountryId,
            ) as Country,
        );

        const locationIndex = newLocations.findIndex((routeLocation) => routeLocation.locationId === locationId);

        const { price, fee } = PriceCalculator.calculatePriceAndFeeFromValue(value, feeCoefficient);

        newLocations[locationIndex].tollPrice = price;
        newLocations[locationIndex].tollFee = fee;

        (this.editedRoute as Route).locations = newLocations;
    }

    @observable
    public isDeleting = false;

    @action
    public async routeDelete() {
        try {
            this.isDeleting = true;
            await this.rpcClient.content.deleteRoute((this.route as Route)._id);
            this.pageRouter.gotoRoutesPage();
        } catch (error: any) {
            alert(`Failed to delete the route: ${error}`);
            this.isDeleting = false;
        }
    }

    @action
    public async routeEdit() {
        await Promise.all([this.setCountries(), this.setRegions()]);

        this.editedRoute = toJS(this.route);
        this.isNoPricingRegionIntentional = false;
    }

    @action
    public async deleteCampaign(campaignOperator: RouteGoogleCampaignOperator) {
        if (this.routeGoogleCampaigns && campaignOperator && campaignOperator.routeGoogleCampaign) {
            try {
                await this.rpcClient.googleCampaign.deleteCampaign(
                    campaignOperator.routeGoogleCampaign._id,
                    this.googleCampaignSettingType,
                );
                this.routeGoogleCampaigns.splice(this.routeGoogleCampaigns.indexOf(campaignOperator), 1);
            } catch (e: any) {
                alert("Error during deleting, contact admin. Possible reasons: campaign not found in adwords.");
            }
        }
    }

    @action
    public routeEditCancelOrGoBack() {
        if (!this.route) {
            this.pageRouter.gotoRoutesPage();
        } else {
            this.editedRoute = undefined;
        }
    }

    @action
    public async routeValidate() {
        this.routeValidationErrors = await validate(plainToClass(Route, this.editedRoute || this.route), {
            skipMissingProperties: true,
        });
    }

    @action
    public attachNoPricingRegionNote() {
        if (!this.editedRoute) {
            return;
        }

        if (!this.editedRoute.note) {
            this.editedRoute.note = "";
        }

        if (!this.isNoPricingRegionNoteAttached) {
            this.editedRoute.note = `${this.editedRoute.note}\nNo pricing region`;
        }
    }

    @action
    public async routeEditSave() {
        await this.routeValidate();

        if (this.routeValidationErrors.length > 0) {
            const validationMessages = transformValidationErrorsToArrayString(this.routeValidationErrors).join("\n");
            alert(`Oh, something is wrong. :(\n\nValidation errors:\n${validationMessages}`);
            return;
        }

        if (!this.isBaseRouteTravelDataValid()) {
            alert(
                "Failed to calculate travel data, make sure that a land route between origin, waypoints and destination exists",
            );
            return;
        }

        if (this.editedRoute?.isReady || this.editedRoute?.isLive) {
            const isLiveValidationMessages = [];

            if (this.isOriginLocationLiveValidationMessage) {
                isLiveValidationMessages.push(this.isOriginLocationLiveValidationMessage);
            }

            if (this.isDestinationLocationLiveValidationMessage) {
                isLiveValidationMessages.push(this.isDestinationLocationLiveValidationMessage);
            }

            if (this.notLiveLocations.length > 0) {
                const notLiveLocationsNames = this.notLiveLocations.map((location) => location.name).join(", ");
                isLiveValidationMessages.push(`Not live locations: ${notLiveLocationsNames}`);
            }

            if (isLiveValidationMessages.length > 0) {
                alert(`Oh, something is wrong. :(\n\nValidation errors:\n${isLiveValidationMessages.join("\n")}`);
                return;
            }
        }

        if (this.isCountryWithRegionsButNoneChosen && !this.isNoPricingRegionIntentional) {
            this.showNoPricingRegionChosenModal = true;
            return;
        }

        await this.updateRouteHolder();
        this.originalRoute = undefined;
    }

    @observable
    public isPricesRecalculating = false;

    @observable
    public isPricesRecalculated = false;

    @action
    public async calculatePrices() {
        if (!this.editedRoute) {
            return;
        }

        this.isPricesRecalculating = true;
        const route = Object.assign(new Route(), this.editedRoute);
        route.vehicleTypesPricesFees = route.vehicleTypesPricesFees.filter(
            (vtpf) => vtpf.isManuallyModified && vtpf.price !== 0,
        );

        route.locations = route.locations.map((l) => {
            l.vehicleTypesPricesFees = l.vehicleTypesPricesFees.filter(
                (vtpf) => vtpf.isManuallyModified && vtpf.price !== 0,
            );
            return l;
        });

        const calculatedRoute = await this.rpcClient.content.calculateRoutePrices(route, true);

        if (!calculatedRoute.vehicleTypesPricesFees.find((price) => price.vehicleType === VehicleType.Sedan)) {
            alert(
                "Can't calculate route prices - price for sedan vehicle type is not defined in the selected pricing country.",
            );
            this.isPricesRecalculating = false;
            return;
        }

        this.editedRoute.vehicleTypesPricesFees = this.editedRoute.vehicleTypesPricesFees
            .map(
                (vtpf: VehicleTypePriceFee) =>
                    calculatedRoute.vehicleTypesPricesFees.find(
                        (calculatedVTPF: VehicleTypePriceFee) => calculatedVTPF.vehicleType === vtpf.vehicleType,
                    ) as VehicleTypePriceFee,
            )
            .filter(Boolean);

        for (const location of this.editedRoute.locations) {
            const calculatedRouteLocation = calculatedRoute.locations.find(
                (l) => l.locationId === location.locationId,
            ) as RouteLocation;

            // we might use route VTPF here as they're cannot be using different vehicle types
            location.vehicleTypesPricesFees = this.editedRoute.vehicleTypesPricesFees.map(
                (vtpf: VehicleTypePriceFee) =>
                    calculatedRouteLocation.vehicleTypesPricesFees.find(
                        (calculatedVTPF: VehicleTypePriceFee) => calculatedVTPF.vehicleType === vtpf.vehicleType,
                    ) as VehicleTypePriceFee,
            );
        }

        this.editedRoute.pricesRecalculatedAt = new Date();

        this.isPricesRecalculating = false;
        this.isPricesRecalculated = true;
        setTimeout(() => {
            this.isPricesRecalculated = false;
        }, 3500);
    }

    @action
    public async updateRouteHolder() {
        const editedRoute = toJS(this.editedRoute as Route);
        const errorAlertMessages: Array<string> = [];

        // Show warning if some of route's VTPF or it's location VTPF is 0
        editedRoute.vehicleTypesPricesFees.forEach((vtpf) => {
            if (vtpf.price + vtpf.fee === 0) {
                errorAlertMessages.push(`Price for ${transformVehicleTypeToString(vtpf.vehicleType)} cannot be 0`);
            }

            if (this.locations) {
                editedRoute.locations.forEach((routeLocation) => {
                    const location = this.locations.find(
                        (l) => l.locationId === routeLocation.locationId,
                    ) as SimpleLocation;

                    routeLocation.vehicleTypesPricesFees.forEach((locationVtpf) => {
                        if (
                            locationVtpf.price + locationVtpf.fee === 0 &&
                            locationVtpf.vehicleType !== VehicleType.SedanLite
                        ) {
                            this.routeLocationOpen(location.locationId);
                            errorAlertMessages.push(
                                `Price for ${transformVehicleTypeToString(locationVtpf.vehicleType)} at "${
                                    location.name
                                }" cannot be 0. Please, see Locations section below.`,
                            );
                        }
                    });
                });
            }
        });

        if (errorAlertMessages.length > 0) {
            return alert(uniq(errorAlertMessages).join("\n"));
        }

        try {
            if (this.route) {
                await this.rpcClient.content.updateRoute(editedRoute._id, editedRoute);
                this.pageRouter.openRoute(editedRoute._id);
                this.route = undefined;
            } else {
                this.pageRouter.openRoute(await this.rpcClient.content.createRoute(editedRoute));
            }
            this.editedRoute = undefined;

            await this.fetchData();
        } catch (e: any) {
            alert("Not saved, contact admin.");
        }
    }

    @action
    public async createCampaigns() {
        if (
            this.campaingsToCreate &&
            this.campaingsToCreate.length > 0 &&
            this.newRouteGoogleCampaign &&
            this.originLocation &&
            this.destinationLocation
        ) {
            this.isCampaignCreating = true;

            try {
                const validationErrors = await validateAds(
                    this.newRouteGoogleCampaign,
                    this.originLocation,
                    this.destinationLocation,
                    this.googleCampaignSettingType,
                );

                if (validationErrors.length > 0) {
                    let validationMessage = "Some ads didn't pass validation and won't be created: \n";

                    validationErrors.forEach((error) => {
                        validationMessage += `${error}\n`;
                    });

                    validationMessage += "Do you still want to create campaigns?";

                    // eslint-disable-next-line no-console
                    console.warn(validationMessage);
                }

                await this.rpcClient.googleCampaign.createRouteCampaigns(
                    this.campaingsToCreate,
                    this.googleCampaignSettingType,
                );

                const notificationsStore = container
                    .get<StoreManager<NotificationsStore>>(stores.notifications)
                    .getStore();
                await notificationsStore.retrieveNotifications();

                this.isCampaignCreating = false;

                // eslint-disable-next-line max-len
                // todo: decide if display campaigns created green check, or it's clear enough, because created campaigns appear in campaigns list
                // this.isCampaignCreated = true;

                if (!this.routeGoogleCampaigns) {
                    this.routeGoogleCampaigns = [];
                }

                // await this.fetchGoogleRouteCampaigns();

                // set campaigns to create empty
                this.campaingsToCreate = [];
            } catch (e: any) {
                // todo: display error on page
                alert("Error during creating campaigns. Please contact administrator.");
                this.isCampaignCreating = false;
                // this.isCampaignCreated = false;
            }
        }
    }

    @action
    public async createLocation(option: Option): Promise<string | undefined> {
        const positionWithCountry = await this.rpcClient.maps.fetchPositionByAddress(option.value as string);

        const { countryId } = positionWithCountry;

        let location = classToPlain(new Location()) as Location;

        location = Object.assign(location, {
            name: option.value as string,
            localNames: [],
            position: positionWithCountry.position,
            countryId,
            machineName: transformNameToMachineName(option.value as string),
            isLocation: true,
        });

        const locationValidation = await validate(plainToClass(Location, location), { skipMissingProperties: true });

        if (locationValidation.length > 0) {
            const validationMessages = transformValidationErrorsToArrayString(locationValidation).join("\n");
            alert(`Oh, something is wrong. :(\n\nValidation errors:\n${validationMessages}`);
            return undefined;
        }

        const locationId = await this.rpcClient.content.createLocation(location);
        location._id = locationId;

        const simpleLocation = new SimpleLocation();
        simpleLocation.locationId = location._id;
        simpleLocation.airportCodes = location.airportCodes;
        simpleLocation.countryId = location.countryId;
        simpleLocation.defaultDuration = location.defaultDuration;
        simpleLocation.localNames = location.localNames;
        simpleLocation.machineName = location.machineName;
        simpleLocation.name = location.name;
        simpleLocation.otherNames = location.otherNames;
        simpleLocation.position = location.position as Position;
        simpleLocation.isDestination = location.isDestination;
        simpleLocation.isLocation = location.isLocation;
        simpleLocation.isLive = location.isLive;
        simpleLocation.score = location.score;

        this.locations.push(simpleLocation);

        this.routeUpdateLocationAdd(location._id);
        this.selectedLocation = undefined;

        window.focus();
        if (document.activeElement) {
            (document.activeElement as HTMLElement).blur();
        }

        return Promise.resolve(locationId);
    }

    @action
    public async newLocationHandler(option: Option) {
        await this.createLocation(option);
    }

    @computed
    public get allSimpleRoutesOptions(): Array<Option> {
        const allSimpleRoutes: Array<Option> = [];

        if (this.simpleRoutes) {
            this.simpleRoutes.forEach((r) => {
                allSimpleRoutes.push({
                    value: r.routeId,
                    label: `${r.originLocationName} to ${r.destinationLocationName}`,
                });

                if (r.isBidirectional) {
                    allSimpleRoutes.push({
                        value: r.routeId,
                        label: `${r.destinationLocationName} to ${r.originLocationName}`,
                    });
                }
            });
        }

        return allSimpleRoutes;
    }

    @observable
    public newToOriginUpsellRoute?: UpsellRoute;

    @observable
    public newFromDestinationUpsellRoute?: UpsellRoute;

    @observable
    public newToOriginInOtherDirectionUpsellRoute?: UpsellRoute;

    @observable
    public newFromDestinationInOtherDirectionUpsellRoute?: UpsellRoute;

    @action
    public routeUpdateUpsellRoutes(option: Option, isToOrigin: boolean, isInOtherDirection: boolean): void {
        if (!option) {
            if (isInOtherDirection) {
                if (isToOrigin) {
                    this.newToOriginInOtherDirectionUpsellRoute = undefined;
                } else {
                    this.newFromDestinationInOtherDirectionUpsellRoute = undefined;
                }
            } else if (isToOrigin) {
                this.newToOriginUpsellRoute = undefined;
            } else {
                this.newFromDestinationUpsellRoute = undefined;
            }

            return;
        }

        const route = (this.simpleRoutes as Array<SimpleRoute>).find(
            (sr) => sr.routeId === (option.value as string),
        ) as SimpleRoute;
        const originName = (option.label as string).split(" to ")[0];

        const upsellRoute = new UpsellRoute();
        upsellRoute.isToOrigin = isToOrigin;
        upsellRoute.routeId = option.value as string;
        upsellRoute.isOtherDirection = route.originLocationName !== originName;
        upsellRoute.order =
            // eslint-disable-next-line no-nested-ternary
            !isInOtherDirection
                ? isToOrigin
                    ? (this.editedRoute as Route).upsellRoutes.filter((r) => r.isToOrigin).length
                    : (this.editedRoute as Route).upsellRoutes.filter((r) => !r.isToOrigin).length
                : isToOrigin
                  ? (this.editedRoute as Route).upsellRoutesInOtherDirection.filter((r) => r.isToOrigin).length
                  : (this.editedRoute as Route).upsellRoutesInOtherDirection.filter((r) => !r.isToOrigin).length;

        if (isInOtherDirection) {
            if (isToOrigin) {
                this.newToOriginInOtherDirectionUpsellRoute = upsellRoute;
            } else {
                this.newFromDestinationInOtherDirectionUpsellRoute = upsellRoute;
            }
        } else if (isToOrigin) {
            this.newToOriginUpsellRoute = upsellRoute;
        } else {
            this.newFromDestinationUpsellRoute = upsellRoute;
        }

        if (!this.upsellRoutes) {
            this.upsellRoutes = [];
        }
        this.upsellRoutes.push(route);
    }

    @action
    public routeUpdateUpsellRouteAdd() {
        if (this.newToOriginUpsellRoute) {
            (this.editedRoute as Route).upsellRoutes.push(toJS(this.newToOriginUpsellRoute));
            this.newToOriginUpsellRoute = undefined;
        } else if (this.newFromDestinationUpsellRoute) {
            (this.editedRoute as Route).upsellRoutes.push(toJS(this.newFromDestinationUpsellRoute));
            this.newFromDestinationUpsellRoute = undefined;
        } else if (this.newToOriginInOtherDirectionUpsellRoute) {
            (this.editedRoute as Route).upsellRoutesInOtherDirection.push(
                toJS(this.newToOriginInOtherDirectionUpsellRoute),
            );
            this.newToOriginInOtherDirectionUpsellRoute = undefined;
        } else if (this.newFromDestinationInOtherDirectionUpsellRoute) {
            (this.editedRoute as Route).upsellRoutesInOtherDirection.push(
                toJS(this.newFromDestinationInOtherDirectionUpsellRoute),
            );
            this.newFromDestinationInOtherDirectionUpsellRoute = undefined;
        } else {
            alert("You must select upsell route first.");
        }
    }

    @action
    public removeUpsellRoute(routeId: string, isToOrigin: boolean | undefined) {
        let newUpsellRoutes = (this.editedRoute as Route).upsellRoutes
            .filter((r) => r.isToOrigin === !!isToOrigin)
            .sort((a, b) => (a.order > b.order ? 1 : -1));
        const upsellRouteIndex = newUpsellRoutes.findIndex((ur) => ur.routeId === routeId);

        const upsellRoutesBeforeChanged = newUpsellRoutes.slice(0, upsellRouteIndex);
        let upsellRoutesAfterChanged = newUpsellRoutes.slice(upsellRouteIndex + 1);

        upsellRoutesAfterChanged = upsellRoutesAfterChanged.map((wp) => {
            wp.order -= 1;
            return wp;
        });

        newUpsellRoutes = upsellRoutesBeforeChanged.concat(upsellRoutesAfterChanged);

        (this.editedRoute as Route).upsellRoutes = (this.editedRoute as Route).upsellRoutes
            .filter((r) => r.isToOrigin !== !!isToOrigin)
            .concat(newUpsellRoutes);
    }

    @action
    public removeInOtherDirectionUpsellRoute(routeId: string, isToOrigin: boolean | undefined) {
        let newUpsellRoutes = (this.editedRoute as Route).upsellRoutesInOtherDirection
            .filter((r) => r.isToOrigin === !!isToOrigin)
            .sort((a, b) => (a.order > b.order ? 1 : -1));
        const upsellRouteIndex = newUpsellRoutes.findIndex((ur) => ur.routeId === routeId);

        const upsellRoutesBeforeChanged = newUpsellRoutes.slice(0, upsellRouteIndex);
        let upsellRoutesAfterChanged = newUpsellRoutes.slice(upsellRouteIndex + 1);

        upsellRoutesAfterChanged = upsellRoutesAfterChanged.map((wp) => {
            wp.order -= 1;
            return wp;
        });

        newUpsellRoutes = upsellRoutesBeforeChanged.concat(upsellRoutesAfterChanged);

        (this.editedRoute as Route).upsellRoutesInOtherDirection = (
            this.editedRoute as Route
        ).upsellRoutesInOtherDirection
            .filter((r) => r.isToOrigin !== !!isToOrigin)
            .concat(newUpsellRoutes);
    }

    @action
    public routeUpdateUpsellRouteSetOrder(
        routeId: string,
        order: number,
        isForOtherDirection: boolean,
        isToOrigin: boolean | undefined,
    ) {
        let newUpsellRoutes = isForOtherDirection
            ? (this.editedRoute as Route).upsellRoutesInOtherDirection.sort((a, b) => (a.order > b.order ? 1 : -1))
            : (this.editedRoute as Route).upsellRoutes.sort((a, b) => (a.order > b.order ? 1 : -1));

        const updatingUpsellRoutesOrder: number = (newUpsellRoutes.find((r) => r.routeId === routeId) as UpsellRoute)
            .order;

        const unchangedUpsellRouteToAnotherLocation = newUpsellRoutes.filter((r) => r.isToOrigin !== !!isToOrigin);

        newUpsellRoutes = newUpsellRoutes
            .filter((r) => r.isToOrigin === !!isToOrigin)
            .map((upsellRoute: UpsellRoute) => {
                if (upsellRoute.order === order) {
                    // order increased - moving down
                    if (updatingUpsellRoutesOrder < order) {
                        upsellRoute.order -= 1;
                    } else if (updatingUpsellRoutesOrder > order) {
                        // order decreased - moving up
                        upsellRoute.order += 1;
                    }
                }

                if (upsellRoute.routeId === routeId) {
                    upsellRoute.order = order;
                }

                return upsellRoute;
            });

        if (isForOtherDirection) {
            (this.editedRoute as Route).upsellRoutesInOtherDirection = newUpsellRoutes.concat(
                unchangedUpsellRouteToAnotherLocation,
            );
        } else {
            (this.editedRoute as Route).upsellRoutes = newUpsellRoutes.concat(unchangedUpsellRouteToAnotherLocation);
        }
    }

    @observable
    public routeLocationOption?: Option;

    private async setSimpleRouteLocations(route: Route): Promise<void> {
        this.locations = await this.rpcClient.content.retrieveSimpleLocations({
            ids: [route.originLocationId, route.destinationLocationId, ...route.locations.map((l) => l.locationId)],
        });
    }

    private async setRouteLocations(route: Route): Promise<void> {
        const options: RetrieveRoutesOptions = plainToClass(RetrieveRoutesOptions, {
            routeIds: route.upsellRoutes
                .map((r) => r.routeId)
                .concat(route.upsellRoutesInOtherDirection.map((r) => r.routeId)),
        });
        this.upsellRoutes = await this.rpcClient.content.retrieveSimpleRoutes(options);

        this.routeLocations = await this.rpcClient.content.retrieveLocations({
            ids: route.locations.map((l) => l.locationId),
        });
        this.routeLocations = route.locations
            .sort((a, b) => (a.order > b.order ? 1 : -1))
            .map(
                (routeLocation) =>
                    (this.routeLocations as Array<Location>).find(
                        (location) => location._id === routeLocation.locationId,
                    ) as Location,
            );
    }

    // search and set origin/destination - start
    @observable
    public selectOriginLocationsOptions: Array<Option> = [];

    @observable
    public selectDestinationLocationsOptions: Array<Option> = [];

    public setSelectLocationsOptionsThrottle = throttle(this._setNewSelectLocationsOptions, 1000);

    private locationSearchRequest?: Promise<Array<SimpleLocation>>;

    public async loadSimpleLocations(options = {}): Promise<Array<SimpleLocation>> {
        return this.rpcClient.content.retrieveSimpleLocations(options);
    }

    public async setOriginLocationsOptions(text: string) {
        await this._setLocationsOptions(text, "selectOriginLocationsOptions");
    }

    public async setDestinationLocationsOptions(text: string) {
        await this._setLocationsOptions(text, "selectDestinationLocationsOptions");
    }

    private async _setLocationsOptions(text: string, variableName: string) {
        if (text.length > 2) {
            this.setSelectLocationsOptionsThrottle(text, variableName);
        }
    }

    public prepareSelectLocationsOptions(locations: Array<SimpleLocation>, variableName: string) {
        this[variableName] = locations
            .filter((location) => location.isDestination)
            .map((location) => ({
                value: location.locationId,
                label: location.name + (location.countryName ? `, ${location.countryName}` : ""),
            }));
    }

    private findLocationById(id: string): SimpleLocation | undefined {
        return !!id && this.locations && this.locations.length > 0
            ? (this.locations.find((location: SimpleLocation) => location.locationId === id) as SimpleLocation)
            : undefined;
    }

    @action
    public async updateOriginLocationId(value: string) {
        (this.editedRoute as Route).originLocationId = value;
        this.originLocation = this.findLocationById(value);
        await this._afterRouteLocationIdUpdate();
    }

    @action
    public async updateDestinationLocationId(value: string) {
        (this.editedRoute as Route).destinationLocationId = value;
        this.destinationLocation = this.findLocationById(value);
        await this._afterRouteLocationIdUpdate();
    }

    private isBaseRouteTravelDataValid(): boolean {
        return this.baseRouteTravelData?.distance > 0 && this.baseRouteTravelData?.duration > 0;
    }

    private async _afterRouteLocationIdUpdate() {
        const route = this.editedRoute as Route;
        if (route.originLocationId && route.destinationLocationId) {
            await this.validateSimilarRouteExists(route, true);
            this.baseRouteTravelData = await this.rpcClient.content.calculateBaseRouteTravelData(
                route.originLocationId,
                route.destinationLocationId,
                route.waypoints,
            );
            if (!this.isBaseRouteTravelDataValid()) {
                alert(
                    "Failed to calculate travel data, make sure that a land route between origin, waypoints and destination exists",
                );
                return;
            }
            await this._loadMapData();
            this.autoSelectPricingCountry();
        }
    }

    private async _setNewSelectLocationsOptions(text: string, variableName: string): Promise<void> {
        // todo abort existing request
        // if (this.locationSearchRequest)
        // {
        // }
        this.locationSearchRequest = this.loadSimpleLocations({ searchString: text });

        this.locations = await this.locationSearchRequest;
        delete this.locationSearchRequest;

        this.prepareSelectLocationsOptions(this.locations, variableName);
    }
    // search and set origin/destination - end

    // origin/destination setters and getters - start
    @observable
    private _originLocation?: SimpleLocation;

    @observable
    private _destinationLocation?: SimpleLocation;

    @computed
    public get originLocation(): SimpleLocation | undefined {
        if (!this._originLocation) {
            const route: Route = this.route || (this.editedRoute as Route);

            return this.findLocationById(route.originLocationId);
        }

        return this._originLocation;
    }

    public set originLocation(location: SimpleLocation | undefined) {
        this._originLocation = location;
    }

    @computed
    public get destinationLocation(): SimpleLocation | undefined {
        if (!this._destinationLocation) {
            const route: Route = this.route || (this.editedRoute as Route);

            return this.findLocationById(route.destinationLocationId);
        }

        return this._destinationLocation;
    }

    public set destinationLocation(location: SimpleLocation | undefined) {
        this._destinationLocation = location;
    }
    // origin/destination setters and getters - end

    @computed
    public get mapLocations(): Array<SimpleLocation> {
        return this.originLocation && this.destinationLocation && this.locations && this.locations.length > 0
            ? this.locations
            : [];
    }

    @computed
    public get tagsValidationMessage(): string | undefined {
        const validationErrors = this.routeValidationErrors.find((ve) => ve.property === "tagIds");
        if (!validationErrors) {
            return undefined;
        }
        return JSON.stringify(validationErrors.constraints);
    }

    @action
    public updateRouteTags(value: string[]) {
        const result = value.reduce((r: Array<string>, tagId: string) => {
            const tag = this.tags.find((t) => t._id === tagId);
            if (tag) {
                r.push(tagId);
                if (tag.parentTagId) {
                    r.push(...getTagParents(tag, this.tags));
                }
            }
            return r;
        }, []);
        (this.editedRoute as Route).tagIds = [...new Set(result)];
        this.routeValidate();
    }

    protected async setCountries(): Promise<void> {
        if (!this.countries?.length) {
            this.countries = await this.rpcClient.content.retrieveCountries({});
        }

        return Promise.resolve();
    }

    protected async setRegions(): Promise<void> {
        if (!this.regions?.length) {
            this.regions = await this.rpcClient.content.retrieveRegions({});
        }

        return Promise.resolve();
    }

    protected async setNearestLocations() {
        if ((!this.route && !this.editedRoute) || !this.originLocation || !this.destinationLocation) {
            await this.setAllLocations();
            return;
        }
        try {
            this.locations = await this.rpcClient.content.retrieveSimpleNearLocations(
                this.originLocation,
                this.destinationLocation,
            );
        } catch (e: any) {
            await this.setAllLocations();
            alert("Can't load nearest locations. All locations were loaded");
        }
    }

    protected async setAllLocations() {
        this.locations = await this.rpcClient.content.retrieveSimpleLocations({});
    }

    private async _loadMapData(): Promise<any> {
        return Promise.all([this.setNearestLocations(), this.setCountries(), this.setRegions()]);
    }

    private _createNewRoute(): Route {
        const route = classToPlain(new Route()) as Route;
        route.vehicleTypesPricesFees = [VehicleType.Sedan, VehicleType.Mpv, VehicleType.Van, VehicleType.Shuttle].map(
            (vehicleType: VehicleType) => ({
                vehicleType,
                price: 0,
                fee: 0,
                isManuallyModified: false,
            }),
        );

        return route;
    }

    private async fetchTags() {
        this.tags = await this.rpcClient.content.retrieveTags({ applicableFor: [TaggableEntityType.Route] });
    }

    private async fetchApiPartners() {
        // if we introduce more than 20 API partners in PROD, this need to be updated
        const apiPartnersResponse = await this.rpcClient.apiPartner.getApiPartners({ limit: 20 });

        this.apiPartners = apiPartnersResponse.data;
    }
}

export interface RoutePageStoreDataFetched {
    route: Route;
    routeGoogleCampaigns: Array<RouteGoogleCampaignOperator>;
    newRouteGoogleCampaign: RouteGoogleCampaignOperator;
    editedRoute: Route;
    locations: Array<Location>;
    countries: Array<Country>;
    simpleRoutes: Array<SimpleRoute>;
    pricingCountry: Country;
}
