import { action, observable } from "mobx";

import { CustomerFeedback } from "@legacy/models/CustomerFeedback";

import { ModelOperator } from "./ModelOperator";

export class CustomerFeedbackOperator extends ModelOperator<CustomerFeedback, {}, null, null, {}> {
    @observable
    public isSaved: boolean = false;

    @observable
    public savingError: string | null = null;

    @observable
    public isModalRevealed: boolean = false;

    @action.bound
    public toggleModal() {
        this.isModalRevealed = !this.isModalRevealed;
    }

    @action.bound
    public async remove() {
        await this.rpcClient.feedback.removeCustomerFeedback(this.m._id);
    }
}
