import { Expose } from "class-transformer";
import { IsNumber, IsString } from "class-validator";

import { SimpleDriverWithVehicles } from "./SimpleDriverWithVehicles";
import type { VehicleInfo } from "./VehicleInfo";

export class DriverCompanyInfoForAssignation {
    @Expose()
    public vehicles: Array<VehicleInfo>;

    @Expose()
    @IsString()
    public managementNote: string;

    @Expose()
    @IsNumber()
    public willingToSleepoverCount: number;

    @Expose()
    public company: SimpleDriverWithVehicles;

    @Expose()
    @IsNumber()
    public amountOfDrivers: number;
}
