import { Expose } from "class-transformer";
import { IsDefined, IsUUID } from "class-validator";

import { OrderAutomaticEmailType } from "./OrderAutomaticEmailType";

export class OrderAutomaticEmailTypePair {
    @IsUUID("4")
    @IsDefined()
    @Expose()
    public orderId: string;

    @IsDefined()
    @Expose()
    public type: OrderAutomaticEmailType;
}
