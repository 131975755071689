/* eslint-disable no-continue */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable max-classes-per-file */
import { MouseEvent } from "react";

import { mapPassengersToVehicles, transformPassengersCountToVehicleType } from "@daytrip/legacy-transformers";
import { transformVehiclesToAssignationToolDriverVehicles } from "@daytrip/legacy-transformers";
import type { AssignationDto } from "@legacy/domain/AssignationDto";
import { AssignationStatus } from "@legacy/domain/AssignationStatus";
import type { AssignationWithAnonymizedOrder } from "@legacy/domain/AssignationWithAnonymizedOrder";
import type { BasicOrderForAssignationTool } from "@legacy/domain/BasicOrderForAssignationTool";
import type { CompanyDriverForAssignationTool } from "@legacy/domain/CompanyDriverForAssignationTool";
import { DriverCompanyInfoForAssignation } from "@legacy/domain/DriverCompanyInfoForAssignation";
import type { DriverForAssignationTool } from "@legacy/domain/DriverForAssignationTool";
import { DriverInfoForAssignation } from "@legacy/domain/DriverInfoForAssignation";
import { OrderStatus } from "@legacy/domain/OrderStatus";
import { isIndividualDriver } from "@legacy/domain/SimpleDriver";
import { SimpleDriverWithVehicles } from "@legacy/domain/SimpleDriverWithVehicles";
import type { SimpleLocation } from "@legacy/domain/SimpleLocation";
import { SimpleOrderForAssignation } from "@legacy/domain/SimpleOrderForAssignation";
import type { VehicleInfo } from "@legacy/domain/VehicleInfo";
import { VehicleType } from "@legacy/domain/VehicleType";
import { filterSuitableVehicleInfo, isDriverWillingToRentSuitableVehicle } from "@legacy/filters/filterSuitableVehicle";
import { Assignation } from "@legacy/models/Assignation";
import type { Unavailability } from "@legacy/models/Unavailability";
import type { Vehicle } from "@legacy/models/Vehicle";
import type { VehicleModel } from "@legacy/models/VehicleModel";
import type { DriversForAssignationOptions } from "@legacy/options/DriversForAssignationOptions";
import type { RetrieveOrdersForAssignationOptions } from "@legacy/options/RetrieveOrdersForAssignationOptions";
import { isUndefinedOrNull } from "@legacy/utils";
import { checkPositionInRadius } from "@legacy/utils/checkPositionInRadius";
import {
    determineSuitableVehicleTypesForDriver,
    determineSuitableVehicleTypesForDriversCompany,
} from "@legacy/utils/driverSuitabilityForVehicleTypes";
import { alertError } from "@legacy/utils/errorHelper";
import autobind from "autobind-decorator";
import { plainToClass } from "class-transformer";
import { format } from "date-fns";
import cloneDeep from "lodash/cloneDeep";
import get from "lodash/get";
import { action, computed, observable, ObservableMap, toJS } from "mobx";

import { globalManagementLogger } from "../../global-logger";
import { PageStore } from "../../stores/PageStore";

import { CountryGroupFilterStore } from "./CountryGroupFilter/CountryGroupFilterStore";
import { getCountryIdsByCountryGroups } from "./CountryGroupFilter/utils/getCountryIdsByCountryGroups";
import type { DriversAssignationPageRouter } from "./DriversAssignationPageRouter";

const similarOrdersKey = (order: SimpleOrderForAssignation) => {
    const departure = order.departureAt ? format(new Date(order.departureAt), "yyyy/MM/dd-HH:mm") : undefined;
    return `${departure}-${order.destinationLocationId}-${order.originLocationId}-${order.simplePassengers.length}`;
};

const sameCustomerOrdersKey = (order: SimpleOrderForAssignation) => {
    const departure = order.departureAt ? format(new Date(order.departureAt), "yyyy/MM/dd") : undefined;
    return `${departure}-${order.customer?._id}`;
};

export class AssignationRoute {
    @observable
    public originLocationIds?: Array<string> = [];

    @observable
    public destinationLocationIds?: Array<string> = [];

    @computed
    public get isComplete(): boolean {
        return !isUndefinedOrNull(this.originLocationIds) && !isUndefinedOrNull(this.destinationLocationIds);
    }
}

export interface ReadyToProcessAssignation {
    assignation: Assignation;
    index: number;
    day: number;
}

@autobind
export class AssignationDriver {
    public driver: SimpleDriverWithVehicles;

    public locationNames: Array<string>;

    public hasAssignations: boolean;

    public vehicleTypes: Array<DriversVehicle>;

    public ownedVehicles: Array<Omit<VehicleInfo, "manufactureYear">>;

    public hasDeclinedAssignations: boolean;

    public orders: Array<BasicOrderForAssignationTool>;

    public assignations: Array<Assignation>;

    public companyDrivers?: Array<CompanyDriverForAssignationTool>;

    public driverUnavailability: Array<Unavailability>;

    public get suitableForVehicleTypes(): VehicleType[] {
        if (isIndividualDriver(this.driver)) {
            return determineSuitableVehicleTypesForDriver({ driver: this.driver });
        }
        // Filter is done just for type assertion here
        const companyDrivers = this.companyDrivers?.map((cd) => cd.driver).filter(isIndividualDriver) ?? [];
        return determineSuitableVehicleTypesForDriversCompany({ driversCompany: this.driver, companyDrivers });
    }

    public get isSedanLiteSuitable() {
        return this.suitableForVehicleTypes.includes(VehicleType.SedanLite);
    }

    public get canSpeakEnglish(): boolean {
        return Boolean(this.driver.isCompany || this.driver.canSpeakEnglish);
    }

    public canDrive(order?: SelectedOrder): boolean {
        if (!order) {
            return false;
        }

        if (this.vehicleTypes.length === 0) {
            return false;
        }

        // If driver is unsuitable for vehicle type it doesn't matter if he has required vehicle. Example: driver has Sedan but cannot speak english.
        if (!this.suitableForVehicleTypes.includes(order.vehicleType)) {
            return false;
        }

        // If driver is willing to rent suiitable vehicle, he doesn't need to own it.
        if (isDriverWillingToRentSuitableVehicle(this.driver.willingToRentVehicleTypes, order.vehicleType)) {
            return true;
        }

        const { simplePassengers, originCountryId, destinationCountryId, orderVehicles, vehicleIndex } = order.order;

        const vehicleConfig = mapPassengersToVehicles({ passengers: simplePassengers, vehicles: orderVehicles })[
            vehicleIndex
        ];
        const requiredCapacity =
            vehicleConfig.adultsCount + vehicleConfig.childrenCount + vehicleConfig.additionalLuggagePairs;
        const driverHasSuitableVehicle = this.ownedVehicles.some(
            filterSuitableVehicleInfo(order.vehicleType, requiredCapacity, [originCountryId, destinationCountryId]),
        );
        return driverHasSuitableVehicle;
    }

    public constructor() {
        this.canDrive = this.canDrive.bind(this);
    }
}

export interface AssignationPageTender {
    order: SimpleOrderForAssignation;
    combo: boolean;
    perfectCombo: boolean;
    assignedDriverName?: string;
    originCountry: string;
    hasDeclinedAssignations?: boolean;
    similarGroupColor?: string;
}

export interface SelectedDriver {
    driver: AssignationDriver;
    day: number;
}

export interface SelectedOrder {
    order: SimpleOrderForAssignation;
    vehicleType: VehicleType;
    day: number;
    key: number;
}

export interface DriversVehicle {
    type: VehicleType;
    own: boolean;
    luxuryCountryIds?: Array<string>;
}

interface PageModules {
    countryGroupFilterStore: CountryGroupFilterStore;
}

@autobind
export class DriversAssignationPageStore extends PageStore<DriversAssignationPageRouter, PageModules> {
    public onInit() {
        this.modules = {
            countryGroupFilterStore: new CountryGroupFilterStore(),
        };
    }

    private dateToIndex(date: Date): string {
        return `${date.getUTCFullYear().toString().padStart(4, "0")}-${(date.getUTCMonth() + 1)
            .toString()
            .padStart(2, "0")}-${date.getUTCDate().toString().padStart(2, "0")}`;
    }

    public dayIndexToDateIndex(i: number) {
        return this.dateToIndex(this.dayIndexToDate(i));
    }

    public dayIndexToDate(i: number) {
        const dayDate = new Date(this.pageRouter.start!);
        dayDate.setUTCDate(dayDate.getUTCDate() + i);
        return dayDate;
    }

    @observable
    public tripsButtonSize: number;

    @observable
    public guidesButtonSize: number;

    @observable
    public readyToAddAssignations: Array<ReadyToProcessAssignation> = [];

    @observable
    public readyToRemoveAssignations: Array<ReadyToProcessAssignation> = [];

    @observable
    public daysDrivers: ObservableMap<string, Array<AssignationDriver>> = observable.map({});

    @observable
    public driverAssignationInfoModalVisible?: boolean = false;

    @observable
    public driverCompanyAssignationInfoModalVisible?: boolean = false;

    @observable
    public assignationsDto: Array<AssignationDto> = [];

    @observable
    public isAssignationsDtoReady: boolean = false;

    @observable
    public assignationsLoading: boolean = false;

    @observable
    public isFiltering: boolean = false;

    @computed
    public get startDateTS(): number {
        const startDate = new Date(this.pageRouter.start!);
        return Date.UTC(startDate.getUTCFullYear(), startDate.getUTCMonth(), startDate.getUTCDate(), 0, 0);
    }

    @computed
    public get endDateTS(): number {
        const startDate = new Date(this.pageRouter.start!);
        return Date.UTC(
            startDate.getUTCFullYear(),
            startDate.getUTCMonth(),
            startDate.getUTCDate() + this.pageRouter.days - 1,
            23,
            59,
        );
    }

    @action
    public async getUTCDaysDriversOptimized() {
        this.isAssignationsDtoReady = false;

        try {
            const allUsersIds: string[] = [];
            const options: DriversForAssignationOptions = {
                fromUTC: this.startDateTS,
                toUTC: this.endDateTS,
            };

            const { showUnavailableDrivers, countryGroups } = this.pageRouter;

            options.countryIds =
                countryGroups &&
                getCountryIdsByCountryGroups(toJS(this.modules.countryGroupFilterStore.items), countryGroups);

            const [allDrivers, allCompanies] = await Promise.all([
                this.rpcClient.driver.getDriversOptimized(options),
                this.rpcClient.driver.getCompaniesOptimized(options),
            ]);

            const allAssignationDrivers: AssignationDriver[] = [];
            const allCompanyDrivers = await this.rpcClient.driver.getCompanyDriversOptimized(
                allCompanies.map((c) => c.driver._id),
                options,
            );

            // consolidate all drivers and companies into one array
            [...allDrivers, ...allCompanies].forEach((driverOrCompany: DriverForAssignationTool) => {
                const assignationDriver = new AssignationDriver();
                const { isCompany } = driverOrCompany.driver;
                let additionalRootProps: any = {};
                let additionalDriverProps: any = {};
                const vehicles = transformVehiclesToAssignationToolDriverVehicles(
                    driverOrCompany.driver.rawVehicles,
                    this.models,
                );

                assignationDriver.ownedVehicles = vehicles;
                assignationDriver.locationNames = [];

                if (isCompany) {
                    const companyDrivers = allCompanyDrivers.filter(
                        (driver) => driver.driver.companyUserId === driverOrCompany.driver._id,
                    );

                    additionalRootProps = { companyDrivers };
                    additionalDriverProps = {
                        amountOfDrivers: companyDrivers.length || 0,
                        isSuitableForLuxury: !!companyDrivers.find((d) => d.driver.isSuitableForLuxury),
                    };

                    assignationDriver.locationNames = driverOrCompany.driver.originLocationIds.map(
                        (locationId: string) => (this.findLocationById(locationId) as SimpleLocation).name,
                    );
                }

                const ownVehicles: Array<DriversVehicle> = vehicles.map(
                    (model) =>
                        ({
                            own: true,
                            type: transformPassengersCountToVehicleType(model.seatsCount, model.isLuxury, false),
                            luxuryCountryIds: model.luxuryForCountryIds,
                        }) as DriversVehicle,
                );

                assignationDriver.vehicleTypes = [
                    ...ownVehicles,
                    ...driverOrCompany.driver.willingToRentVehicleTypes.map(
                        (type) => ({ type, own: false }) as DriversVehicle,
                    ),
                ];
                // keep unique vehicle types
                assignationDriver.vehicleTypes = assignationDriver.vehicleTypes.filter(
                    (vt, index, self) => self.indexOf(vt) === index,
                );

                allUsersIds.push(driverOrCompany.driver._id);
                allAssignationDrivers.push({
                    ...assignationDriver,
                    driver: {
                        ...driverOrCompany.driver,
                        country: driverOrCompany.driver.country[0],
                        vehicles,
                        ...additionalDriverProps,
                    },
                    ...additionalRootProps,
                    driverUnavailability: driverOrCompany.dateUnavailability,
                    assignations: driverOrCompany.driverAssignations,
                });
            });

            this.selectedDrivers.clear();

            // assign daysDrivers
            const daysDriversSet: ObservableMap<string, Array<AssignationDriver>> = observable.map({});

            // map days into driver assignations
            const days = new Array(this.pageRouter.days).fill(0).map((_, index) => index);
            const driverAssignationsDaysPromises = days.map(async (dayIndex) => {
                const dayDate = new Date(this.pageRouter.start!);
                dayDate.setUTCDate(dayDate.getUTCDate() + dayIndex);
                const dateIndex = this.dateToIndex(dayDate);

                let daysDrivers: AssignationDriver[] = allAssignationDrivers.filter((driver) => {
                    if (showUnavailableDrivers) {
                        return true;
                    }
                    if (!driver.driverUnavailability || !driver.driverUnavailability[dateIndex]) {
                        return true;
                    }

                    return false;
                });

                const driversOrderIds: { [key: string]: Array<string> } = {};

                daysDrivers = daysDrivers.map((rawDriver: AssignationDriver) => {
                    const driver = Object.assign(new AssignationDriver(), cloneDeep(rawDriver)) as AssignationDriver;
                    driver.orders = [];
                    driver.assignations = plainToClass(Assignation, driver.assignations);
                    driver.driver.available = !get(driver.driverUnavailability, dateIndex);
                    driver.hasAssignations = driver.assignations.some(
                        (a) =>
                            dateIndex === this.dateToIndex(a.orderDepartureAt) &&
                            (a.status === AssignationStatus.Pending || a.status === AssignationStatus.Accepted),
                    );

                    const allAssignationsOrderIds: string[] = [];
                    driver.assignations.forEach(async (assignation: Assignation) => {
                        // Do not count declined and cancelled trips
                        if (
                            [AssignationStatus.Pending, AssignationStatus.Accepted].indexOf(assignation.status) === -1
                        ) {
                            return;
                        }
                        allAssignationsOrderIds.push(assignation.orderId);
                    });

                    if (allAssignationsOrderIds.length > 0) {
                        driversOrderIds[driver.driver._id] = allAssignationsOrderIds;
                    }

                    return driver;
                });

                const orders = await this.rpcClient.order.retrieveBasicOrdersForAssignationTool({
                    ids: Object.values(driversOrderIds).flat(),
                });

                daysDrivers = daysDrivers.map((driver: AssignationDriver) => {
                    if (driversOrderIds[driver.driver._id]) {
                        driver.orders = orders.filter((o) => driversOrderIds[driver.driver._id].includes(o._id));
                    }

                    return driver;
                });

                if (options.isAvailable) {
                    daysDrivers = daysDrivers.filter((driver: AssignationDriver) => driver.driver.available);
                }

                // use intermediate object to prevent redundant rerenders
                daysDriversSet.set(dateIndex, daysDrivers);
            });

            // retrieve assignations with a week before and week after AT dates
            const [assignationsDepartureFrom, assignationsDepartureTo] = this.getAssignationDepartureFromTo();

            // resolve everything at once
            await Promise.all([
                this.fetchAssignationsDto(allUsersIds, assignationsDepartureFrom, assignationsDepartureTo),
                ...driverAssignationsDaysPromises,
            ]);

            this.daysDrivers.merge(daysDriversSet);
        } finally {
            this.isAssignationsDtoReady = true;
        }
    }

    public getAssignationDepartureFromTo(): [Date, Date] {
        const assignationsDepartureFrom = new Date(this.startDateTS - 7 * 24 * 60 * 60 * 1000);
        assignationsDepartureFrom.setUTCHours(0);
        assignationsDepartureFrom.setUTCMinutes(0);
        assignationsDepartureFrom.setUTCSeconds(0);
        assignationsDepartureFrom.setUTCMilliseconds(0);
        const assignationsDepartureTo = new Date(this.endDateTS + 7 * 24 * 60 * 60 * 1000);
        assignationsDepartureTo.setUTCHours(23);
        assignationsDepartureTo.setUTCMinutes(59);
        assignationsDepartureTo.setUTCSeconds(59);
        assignationsDepartureTo.setUTCMilliseconds(999);

        return [assignationsDepartureFrom, assignationsDepartureTo];
    }

    @action
    public async fetchAssignationsDto(driverIds: Array<string>, departureAtFrom: Date, departureAtTo: Date) {
        const assignationsDto = await this.rpcClient.assignation.retrieveAssignationsDto({
            driverIds,
            departureAtFrom,
            departureAtTo,
        });
        if (!this.assignationsDto || this.assignationsDto.length == 0) {
            this.assignationsDto = assignationsDto;
        } else {
            // If there are some assignations already,
            // we remove old driver's assignations and put new
            // ones instead
            this.assignationsDto = this.assignationsDto.filter((adto) => !driverIds.includes(adto.userId));
            this.assignationsDto.push(...assignationsDto);
        }
    }

    @action
    public async fetchCompanyDetailedAssignations(companyId: string): Promise<AssignationWithAnonymizedOrder[]> {
        // retrieve assignations with a week before and week after AT dates
        const [assignationsDepartureFrom, assignationsDepartureTo] = this.getAssignationDepartureFromTo();
        return this.rpcClient.assignation.retrieveAssignationsWithAnonymizedOrder({
            driverIds: [companyId],
            departureAtFrom: assignationsDepartureFrom,
            departureAtTo: assignationsDepartureTo,
        });
    }

    @action
    public async getUTCDaysTenders() {
        this.selectedOrders.clear();
        const tendersRequests = [];
        for (let i = 0; i < this.pageRouter.days; i++) {
            let dayDate = new Date(this.pageRouter.start as number);
            dayDate = new Date(
                Date.UTC(dayDate.getUTCFullYear(), dayDate.getUTCMonth(), dayDate.getUTCDate() + i, 0, 0),
            );
            const dateIndex = this.dateToIndex(dayDate);

            if (!this.daysLocations.has(dateIndex)) {
                this.daysLocations.set(dateIndex, new AssignationRoute());
            }

            tendersRequests.push(() => this.getTendersForIndex(i));
        }
        Promise.all([tendersRequests.map((fn: Function) => fn())]);
    }

    @observable
    public selectedDrivers: ObservableMap<string, SelectedDriver> = observable.map({});

    @action
    public selectDriver(day: number, ad: AssignationDriver) {
        let selectedDriver = this.selectedDrivers.get(day.toString());

        if (
            !isUndefinedOrNull(selectedDriver) &&
            selectedDriver.day === day &&
            selectedDriver.driver.driver._id === ad.driver._id
        ) {
            this.selectedDrivers.delete(day.toString());
        } else {
            selectedDriver = {} as SelectedDriver;
            selectedDriver.day = day;
            selectedDriver.driver = ad;
            this.selectedDrivers.set(day.toString(), selectedDriver);
        }
    }

    @observable
    public locations: ObservableMap<string, SimpleLocation> = observable.map({});

    @observable
    public vehicles: Array<Vehicle>;

    @observable
    public models: Array<VehicleModel>;

    @observable
    public daysLocations: ObservableMap<string, AssignationRoute> = observable.map({});

    @observable
    public daysTenders: ObservableMap<string, Array<AssignationPageTender>> = observable.map({});

    @observable
    public isLoadingBaseData: boolean = false;

    private findLocationById(id: string): SimpleLocation | undefined {
        return this.locations.get(id);
    }

    @action
    public async getTendersForIndex(index: number) {
        const dayDate = new Date(this.pageRouter.start!);
        dayDate.setUTCDate(dayDate.getUTCDate() + index);
        const dateIndex = this.dateToIndex(dayDate);

        if (this.daysTenders.has(dateIndex)) {
            return;
        }

        const indexDateFrom = new Date(this.pageRouter.start!);
        const indexDateTo = new Date(this.pageRouter.start!);

        indexDateFrom.setUTCDate(indexDateFrom.getUTCDate() + index);
        indexDateFrom.setUTCHours(0);
        indexDateFrom.setUTCMinutes(0);
        indexDateFrom.setUTCSeconds(0);

        indexDateTo.setUTCDate(indexDateTo.getUTCDate() + index);
        indexDateTo.setUTCHours(23);
        indexDateTo.setUTCMinutes(59);
        indexDateTo.setUTCSeconds(59);

        const options = {
            departureAtFrom: indexDateFrom,
            departureAtTo: indexDateTo,
            hasAssignations: this.pageRouter.showAssignedTrips,
            declinedAssignations: this.pageRouter.showDeclinedTrips,
        } as RetrieveOrdersForAssignationOptions;

        const { originLocationIds, destinationLocationIds } = this.daysLocations.get(dateIndex)!;

        if (originLocationIds && originLocationIds.length !== 0) {
            options.originLocationIds = originLocationIds;
        }

        if (destinationLocationIds && destinationLocationIds.length !== 0) {
            options.destinationLocationIds = destinationLocationIds;
        }

        options.originCountryIds =
            this.pageRouter.countryGroups &&
            getCountryIdsByCountryGroups(
                toJS(this.modules.countryGroupFilterStore.items),
                this.pageRouter.countryGroups,
            );
        options.statusIn = [OrderStatus.Confirmed];

        let orders = await this.rpcClient.order.retrieveOrdersForAssignations(options);

        orders = orders.filter((order: SimpleOrderForAssignation) => !!order);

        const similarOrderIdsMap = {};
        const sameCustomerOrderIdsMap = {};

        orders.forEach((order: SimpleOrderForAssignation) => {
            if (order.customer && order.customer.isTravelAgent) {
                return;
            }

            const key = similarOrdersKey(order);
            if (!similarOrderIdsMap[key]) {
                similarOrderIdsMap[key] = new Set();
            }
            similarOrderIdsMap[key].add(order.orderId);

            const customerKey = sameCustomerOrdersKey(order);
            if (!sameCustomerOrderIdsMap[customerKey]) {
                sameCustomerOrderIdsMap[customerKey] = new Set();
            }
            sameCustomerOrderIdsMap[customerKey].add(order.orderId);
        });

        const assignationTenders = await Promise.all(
            orders.map(async (order: SimpleOrderForAssignation) => {
                const assignationTender = {} as AssignationPageTender;

                assignationTender.order = order;

                const key = similarOrdersKey(order);
                const customerKey = sameCustomerOrdersKey(order);

                if (sameCustomerOrderIdsMap[customerKey] && sameCustomerOrderIdsMap[customerKey].size > 1) {
                    assignationTender.similarGroupColor = "rgb(232, 64, 76)";
                } else if (similarOrderIdsMap[key] && similarOrderIdsMap[key].size > 1) {
                    assignationTender.similarGroupColor = "rgb(150, 218, 238)";
                }

                if (assignationTender.order.hasAssignation && assignationTender.order.assignedDriver) {
                    assignationTender.assignedDriverName = assignationTender.order.assignedDriver.fullName;
                }

                assignationTender.originCountry = order.originCountryName;

                return assignationTender;
            }),
        );

        this.setDayTendersWithPossibleCombos(assignationTenders, index);
    }

    public setVisibleDaysTendersWithPossibleCombos(): void {
        for (let dayIndex = 0; dayIndex < this.pageRouter.days; dayIndex++) {
            const dateIndex = this.dayIndexToDateIndex(dayIndex);

            const tenders = this.daysTenders.get(dateIndex) as Array<AssignationPageTender>;

            if (!tenders) {
                continue;
            }

            this.setDayTendersWithPossibleCombos(tenders, dayIndex);
        }
    }

    @action
    public setDayTendersWithPossibleCombos(assignationTenders: AssignationPageTender[], dayIndex: number): void {
        const dayIndexString = dayIndex.toString();
        assignationTenders
            .sort((a, b) => a.order.originCountryName.localeCompare(b.order.originCountryName))
            .sort((a, b) => new Date(a.order.departureAt).getTime() - new Date(b.order.departureAt).getTime())
            .forEach((tender: AssignationPageTender, tenderIndex: number) => {
                tender.combo = false;
                tender.perfectCombo = false;

                let selection: undefined | SelectedOrder;

                // highlight selected order
                if (
                    this.selectedOrders.get(dayIndexString) &&
                    this.selectedOrders.get(dayIndexString)!.order.orderId === tender.order.orderId &&
                    this.selectedOrders.get(dayIndexString)!.vehicleType === tender.order.vehicleType &&
                    this.selectedOrders.get(dayIndexString)!.key === tenderIndex
                ) {
                    selection = this.selectedOrders.get(dayIndexString);
                }

                let highlighted: boolean = false;

                const prevDaySelection = this.selectedOrders.get((dayIndex - 1).toString());
                const nextDaySelection = this.selectedOrders.get((dayIndex + 1).toString());

                const currentDayIsNotSelected = !this.selectedOrders.get(dayIndexString);

                const checkOtherDayLocations = (daySelection: SelectedOrder, isDestinationOriginalPosition = true) => {
                    const originalPositionName = isDestinationOriginalPosition
                        ? "destinationLocationId"
                        : "originLocationId";
                    const searchPositionName = isDestinationOriginalPosition
                        ? "originLocationId"
                        : "destinationLocationId";

                    const selectedDateLocation = this.locations.get(daySelection.order[originalPositionName])!;
                    const currentDateLocation = this.locations.get(tender.order[searchPositionName])!;

                    return checkPositionInRadius(
                        selectedDateLocation.position,
                        this.pageRouter.comboRadius,
                        currentDateLocation.position,
                    );
                };

                if (
                    currentDayIsNotSelected &&
                    ((prevDaySelection && checkOtherDayLocations(prevDaySelection)) ||
                        (nextDaySelection && checkOtherDayLocations(nextDaySelection, false)))
                ) {
                    highlighted = true;
                    tender.combo = true;
                }

                const selectedDaysArray = Array.from(this.selectedOrders.values())
                    .filter((so) => !!so)
                    .sort(
                        // eslint-disable-next-line no-nested-ternary
                        (a, b) => (a.day > b.day ? 1 : b.day > a.day ? -1 : 0),
                    );

                const firstSelectedDay = selectedDaysArray[0];
                const lastSelectedDay = selectedDaysArray[selectedDaysArray.length - 1];

                // this condition could probably be much more simple
                if (
                    !selection &&
                    highlighted &&
                    firstSelectedDay &&
                    ((firstSelectedDay.day < dayIndex && checkOtherDayLocations(firstSelectedDay, false)) ||
                        (firstSelectedDay.day > dayIndex && checkOtherDayLocations(lastSelectedDay)) ||
                        checkPositionInRadius(
                            this.locations.get(firstSelectedDay.order.originLocationId)!.position,
                            this.pageRouter.comboRadius,
                            this.locations.get(lastSelectedDay.order.destinationLocationId)!.position,
                        ))
                ) {
                    tender.perfectCombo = true;
                }
            });

        this.daysTenders.set(this.dayIndexToDateIndex(dayIndex), assignationTenders);
    }

    @observable
    public selectedOrders: ObservableMap<string, SelectedOrder> = observable.map({});

    @action
    public selectOrder(
        day: number,
        tenderIndex: number,
        order: SimpleOrderForAssignation,
        vehicleType: VehicleType,
        vehicleIndex: number,
    ) {
        const dayString = day.toString();
        let selectedOrder = this.selectedOrders.get(dayString);

        if (
            !isUndefinedOrNull(selectedOrder) &&
            selectedOrder.order.orderId == order.orderId &&
            selectedOrder.vehicleType == vehicleType &&
            selectedOrder.order.vehicleIndex == vehicleIndex &&
            selectedOrder.key == tenderIndex
        ) {
            this.selectedOrders.delete(dayString);
        } else {
            selectedOrder = {} as SelectedOrder;
            selectedOrder.day = day;
            selectedOrder.order = order;
            selectedOrder.vehicleType = vehicleType;
            selectedOrder.key = tenderIndex;
            this.selectedOrders.set(dayString, selectedOrder);
        }

        this.setVisibleDaysTendersWithPossibleCombos();
    }

    @action
    public setUTCDayOriginLocationId(index: number, originLocationIds: Array<string>) {
        const dateIndex = this.dayIndexToDateIndex(index);

        const dayLocation = this.daysLocations.get(dateIndex);
        dayLocation!.originLocationIds = originLocationIds;
        this.daysLocations.set(dateIndex, dayLocation);

        this.daysTenders.delete(dateIndex);

        this.getUTCDaysDriversOptimized();
        this.getTendersForIndex(index);
    }

    @action
    public setUTCDayDestinationLocationId(index: number, destinationLocationIds: Array<string>) {
        const dateIndex = this.dayIndexToDateIndex(index);

        const dayLocation = this.daysLocations.get(dateIndex);
        dayLocation!.destinationLocationIds = destinationLocationIds;
        this.daysLocations.set(dateIndex, dayLocation);

        this.daysTenders.delete(dateIndex);

        this.getUTCDaysDriversOptimized();
        this.getTendersForIndex(index);
    }

    @action
    public async onFetchData() {
        await this.fetchContent();

        if (this.pageRouter.start) {
            this.getUTCDaysTenders();
            this.getUTCDaysDriversOptimized();
        }
    }

    @action
    public async fetchContent() {
        this.isLoadingBaseData = true;

        const [locations, models] = await Promise.all([
            this.rpcClient.content.retrieveSimpleLocations({}),
            this.rpcClient.vehicle.retrieveVehicleModels({}),
            this.modules.countryGroupFilterStore.load(),
        ]);

        this.models = models;
        locations.map((location) => this.locations.set(location.locationId, location));

        this.isLoadingBaseData = false;
    }

    @observable
    public isConfirmingAssignations = false;

    @action
    public async checkAndConfirmAssignations() {
        this.isConfirmingAssignations = true;

        const driverAssignations: { [key: string]: Array<Array<AssignationPageTender>> } = {};

        const flatAssignationPageTenders: Array<AssignationPageTender> = [];
        const flatDrivers = [];
        for (let i = 0; i < this.pageRouter.days; i++) {
            const dateIndex = this.dayIndexToDateIndex(i);

            const tenders = this.daysTenders.get(dateIndex) as Array<AssignationPageTender>;

            if (!tenders) {
                continue;
            }

            flatAssignationPageTenders.push(...tenders);

            const drivers = this.daysDrivers.get(dateIndex) as Array<AssignationDriver>;

            if (!drivers) {
                continue;
            }

            flatDrivers.push(...drivers);

            this.readyToAddAssignations
                .filter((rta) => rta.day == i)
                .forEach(async (rta) => {
                    if (!Array.isArray(driverAssignations[rta.assignation.userId])) {
                        driverAssignations[rta.assignation.userId] = [];
                    }

                    if (!Array.isArray(driverAssignations[rta.assignation.userId][rta.day])) {
                        driverAssignations[rta.assignation.userId][rta.day] = [];
                    }

                    driverAssignations[rta.assignation.userId][rta.day].push(
                        flatAssignationPageTenders.find(
                            (t) =>
                                t.order.orderId == rta.assignation.orderId &&
                                t.order.vehicleType == rta.assignation.vehicleType,
                        ) as AssignationPageTender,
                    );
                });
        }

        let alertText = `Next driver(s) has multiple orders assigned to them on one day:\n`;
        let tooBusyDrivers = false;

        for (const userId in driverAssignations) {
            const daysOrders = driverAssignations[userId] as Array<Array<AssignationPageTender>>;

            for (const dayI in daysOrders) {
                const orders = daysOrders[dayI];

                if (orders.length > 1) {
                    tooBusyDrivers = true;

                    alertText += `${
                        (flatDrivers.find((d) => d.driver._id == userId) as AssignationDriver).driver.fullName
                    }\n`;

                    orders.forEach((o) => {
                        alertText += `  ${o.order.originLocationName} to ${o.order.destinationLocationName}\n`;
                    });
                }
            }
        }

        if (tooBusyDrivers) {
            const result = window.confirm(alertText);

            if (result === true) {
                await this.confirmAssignations();
            }
        } else {
            await this.confirmAssignations();
        }

        this.isConfirmingAssignations = false;
    }

    @action
    public async confirmAssignations() {
        const driverIds: Array<string> = [];

        for (const rta of this.readyToAddAssignations) {
            driverIds.push(rta.assignation.userId);

            try {
                rta.assignation._id = await this.rpcClient.assignation.createManualAssignation(rta.assignation);

                for (let i = 0; i < this.pageRouter.days; i++) {
                    const dateIndex = this.dayIndexToDateIndex(i);

                    let tenders = this.daysTenders.get(dateIndex) as Array<AssignationPageTender>;

                    if (!tenders) {
                        continue;
                    }

                    tenders = tenders.filter(
                        (t, index: number) =>
                            !(
                                t.order.orderId == rta.assignation.orderId &&
                                t.order.vehicleType == rta.assignation.vehicleType &&
                                rta.index == index
                            ),
                    );
                    this.daysTenders.set(dateIndex, tenders);
                }
            } catch (e: any) {
                globalManagementLogger.error(e);
                alertError(e, "Failed creating assignation. Already exists?", {
                    confirmationCode: rta.assignation.orderConfirmationCode,
                });
            }
        }
        const [assignationsDepartureFrom, assignationsDepartureTo] = this.getAssignationDepartureFromTo();

        // Fetch new assignations
        await this.fetchAssignationsDto(driverIds, assignationsDepartureFrom, assignationsDepartureTo);

        this.readyToRemoveAssignations.forEach(async (rta) => {
            try {
                await this.rpcClient.assignation.deleteAssignation(rta.assignation._id);
            } catch (e: any) {
                alert("Failed deleting assignation. Already deleted?");
            }
        });

        this.readyToAddAssignations = [];
        this.readyToRemoveAssignations = [];
    }

    @observable
    public lastTenderClickId: string;

    @observable
    public lastTenderClickVehicleType: VehicleType;

    @action
    public tenderClickHandler(
        e: MouseEvent<HTMLDivElement>,
        day: number,
        tenderIndex: number,
        tender: AssignationPageTender,
        vehicleType: VehicleType,
    ) {
        if (e.ctrlKey || e.metaKey) {
            this.goToOrderPage(tender.order.orderId);
        } else {
            const addedAssignation = this.readyToAddAssignations.find(
                (rta) =>
                    rta.assignation.orderId === tender.order.orderId &&
                    rta.assignation.vehicleType === tender.order.vehicleType &&
                    rta.assignation.vehicleIndex === tender.order.vehicleIndex &&
                    rta.index === tenderIndex,
            );

            if (!addedAssignation) {
                this.selectOrder(day, tenderIndex, tender.order, tender.order.vehicleType, tender.order.vehicleIndex);
            }
        }

        const dayDate = new Date(tender.order.departureAt);
        const dayDrivers = this.daysDrivers.get(this.dateToIndex(dayDate)) as Array<AssignationDriver>;

        dayDrivers?.forEach((driver: AssignationDriver) => {
            if (
                driver.assignations.find(
                    (assignation: Assignation) =>
                        assignation.declinedAt && assignation.orderId === tender.order.orderId,
                )
            ) {
                driver.hasDeclinedAssignations = !driver.hasDeclinedAssignations;
            }
        });

        this.lastTenderClickId = tender.order.orderId;
        this.lastTenderClickVehicleType = vehicleType;
    }

    @action
    public tenderDoubleClickHandler(
        _e: MouseEvent<HTMLDivElement>,
        day: number,
        tender: AssignationPageTender,
        vehicleType: VehicleType,
    ) {
        this.cancelAssignation(day, tender);
        this.lastTenderClickId = tender.order.orderId;
        this.lastTenderClickVehicleType = vehicleType;
    }

    @observable
    public lastDriverClickId: string;

    @action
    public driverClickHandler(e: MouseEvent<HTMLDivElement>, driver: AssignationDriver, date: Date) {
        if (e.ctrlKey === true || e.metaKey === true) {
            if (driver.driver.isCompany) {
                this.openDriverCompanyInfoForAssignationModal(driver.driver, date);
            } else {
                this.openDriverInfoForAssignationModal(driver.driver, date);
            }
        }

        this.lastDriverClickId = driver.driver._id;
    }

    @action
    public async createAssignationForDriver(driver: AssignationDriver) {
        this.lastDriverClickId = driver.driver._id;

        if (isUndefinedOrNull(driver)) {
            return;
        }

        for (let i = 0; i < this.pageRouter.days; i++) {
            const dateIndex = this.dayIndexToDateIndex(i);

            const selectedOrder = this.selectedOrders.get(i.toString());
            // const selectedDriver = this.selectedDrivers.get(i);

            if (isUndefinedOrNull(selectedOrder)) {
                continue;
            }

            if (selectedOrder.order.isAirportTransfer) {
                window.alert(
                    "Airport transfers can only be assigned to an AT partner driver on the order details page.",
                );
                continue;
            }

            if (
                selectedOrder.order.hasAssignationOffer &&
                (await this.rpcClient.assignation.hasActiveOfferForVehicle(
                    selectedOrder.order.orderId,
                    selectedOrder.vehicleType,
                ))
            ) {
                window.alert("Assignation cannot be created for trip with active offer.");
                continue;
            }

            if (
                selectedOrder.order.protectedComboState &&
                (await this.rpcClient.combo.isOrderProtectedForCombo(selectedOrder.order.orderId))
            ) {
                window.alert("Assignation cannot be created for trip protected for combo.");
                continue;
            }

            const existingAssignation = this.readyToAddAssignations.find(
                (rta) =>
                    rta.assignation.orderId === selectedOrder.order.orderId &&
                    rta.assignation.userId === driver.driver._id &&
                    rta.assignation.vehicleType == selectedOrder.vehicleType,
            );

            if (!isUndefinedOrNull(existingAssignation)) {
                continue;
            }

            const assignation = new Assignation();
            assignation.orderId = selectedOrder.order.orderId;
            assignation.orderDepartureAt = selectedOrder.order.departureAt;
            assignation.userId = driver.driver._id;
            assignation.vehicleType = selectedOrder.vehicleType;
            assignation.vehicleIndex = selectedOrder.order.vehicleIndex;

            this.readyToAddAssignations.push({ assignation, index: selectedOrder.key, day: i });

            const daysTenders = this.daysTenders.get(dateIndex)!.map((tender: AssignationPageTender) => {
                if (
                    tender.order.orderId === selectedOrder.order.orderId &&
                    tender.order.vehicleType == selectedOrder.vehicleType /* && selectedOrder.key == index */
                ) {
                    tender.order.assignation = assignation;
                    tender.order.assignedDriver = driver.driver;
                    tender.assignedDriverName = driver.driver.fullName;
                }

                tender.originCountry = tender.order.originCountryName;

                return tender;
            });

            this.daysTenders.set(dateIndex, daysTenders);

            const dayDrivers = this.daysDrivers.get(dateIndex)!.map((d: AssignationDriver) => {
                if (driver.driver._id === d.driver._id) {
                    d.hasAssignations = true;
                }

                return d;
            });

            this.daysDrivers.set(dateIndex, dayDrivers);
        }

        this.selectedDrivers.clear();
        this.selectedOrders.clear();
    }

    @action
    public async cancelAssignation(i: number, tender: AssignationPageTender) {
        const dateIndex = this.dayIndexToDateIndex(i);

        if (!isUndefinedOrNull(tender.order.assignation) && !isUndefinedOrNull(tender.order.assignedDriver)) {
            const drivers = this.daysDrivers.get(dateIndex) as Array<AssignationDriver>;
            const driver = drivers.find(
                (u) => u.driver._id === (tender.order.assignedDriver as SimpleDriverWithVehicles)._id,
            ) as AssignationDriver;

            const readyAssignation = this.readyToAddAssignations.find(
                (a) => a.assignation._id == (tender.order.assignation as Assignation)._id,
            );

            if (!isUndefinedOrNull(readyAssignation)) {
                this.readyToAddAssignations = this.readyToAddAssignations.filter(
                    (a) => a.assignation._id != (tender.order.assignation as Assignation)._id,
                );
            } else {
                this.readyToRemoveAssignations.push({ assignation: tender.order.assignation, index: 0, day: i });
            }

            tender.assignedDriverName = undefined;
            tender.order.assignation = undefined;
            tender.order.assignedDriver = undefined;

            driver.hasAssignations = false;

            // dumb way to make map reactive
            this.daysDrivers.set(dateIndex, undefined);
            this.daysDrivers.set(dateIndex, drivers);
        }
    }

    public goToOrderPage(orderId: string) {
        const a = document.createElement("a");
        a.target = "_blank";
        a.href = `#/order?orderId=${orderId}`;
        a.click();
    }

    @observable
    public driverInfoForAssingationModal: DriverInfoForAssignation;

    @observable
    public driverCompanyInfoAssignationModal: DriverCompanyInfoForAssignation;

    @observable
    public modalDate: Date;

    public async openDriverInfoForAssignationModal(driver: SimpleDriverWithVehicles, date: Date) {
        this.driverInfoForAssingationModal = {
            driver,
            vehicles: driver.vehicles,
            managementNote: driver.managementNote,
            willingToSleepoverCount: driver.willingToSleepoverCount,
        };

        this.modalDate = date;
        this.driverAssignationInfoModalVisible = true;
    }

    @action
    public openDriverCompanyInfoForAssignationModal(company: SimpleDriverWithVehicles, date: Date) {
        this.driverCompanyInfoAssignationModal = {
            company,
            vehicles: company.vehicles,
            managementNote: company.managementNote,
            willingToSleepoverCount: company.willingToSleepoverCount,
            amountOfDrivers: company.amountOfDrivers ? company.amountOfDrivers : 0,
        };

        this.modalDate = date;
        this.driverCompanyAssignationInfoModalVisible = true;
    }

    @action
    public closeInfoForAssignationModal() {
        this.driverCompanyAssignationInfoModalVisible = false;
        this.driverAssignationInfoModalVisible = false;
    }

    @action
    public setIsFiltering(value: boolean) {
        this.isFiltering = value;
    }
}
