import autobind from "autobind-decorator";
import { action, observable } from "mobx";

import type { RoutingStore } from "../../container";
import { stores, container } from "../../container";
import type { AuthenticationStore } from "../../domain/authentication/AuthenticationStore";
import { getAuthenticationStore } from "../../domain/authentication/AuthenticationStore.singleton";

@autobind
export class LayoutStore {
    public routingStore = container.get<RoutingStore>(stores.routing);

    public authenticationStore: AuthenticationStore;

    public constructor() {
        this.authenticationStore = getAuthenticationStore();
    }

    @observable
    public isMobileMenuOpened: boolean = false;

    @action
    public toggleMobileMenu(): void {
        this.isMobileMenuOpened = !this.isMobileMenuOpened;
    }
}
