import { valueOrArrayToArray } from "@daytrip/utils";
import type { SimpleUser } from "@legacy/domain/SimpleUser";
import type { ExportRecord } from "@legacy/models/ExportRecord";
import type { RetrieveExportRecordsOptions } from "@legacy/options/RetrieveExportRecordsOptions";
import { RetrieveSimpleUsersOptions } from "@legacy/options/RetrieveSimpleUsersOptions";
import { plainToClass } from "class-transformer";
import { action, observable, toJS } from "mobx";

import { PageStore } from "../../stores/PageStore";

import type { ExportsPageRouter } from "./ExportsPageRouter";

export class ExportsPageStore extends PageStore<ExportsPageRouter, null> {
    @observable
    public exportRecords?: Array<ExportRecord>;

    @observable
    public exportRecordsCount: number = 0;

    @observable
    public users?: Array<SimpleUser>;

    @action
    public async onFetchData() {
        await this.fetchContent();
    }

    @observable
    public authenticationToken?: string;

    @action
    public async fetchContent() {
        this.exportRecords = undefined;
        this.authenticationToken = this.authenticationStore.authenticationToken;

        const options = {
            skip: this.pageRouter.skip,
            limit: this.pageRouter.limit,
            sortBy: this.pageRouter.sortBy,
            sortDirection: this.pageRouter.sortDirection,
            search: this.pageRouter.search,
            requesterIds:
                this.pageRouter.requesterIds == undefined
                    ? undefined
                    : toJS(valueOrArrayToArray(this.pageRouter.requesterIds)),
            exportItem: this.pageRouter.exportItem == undefined ? undefined : this.pageRouter.exportItem,
        } as RetrieveExportRecordsOptions;
        this.exportRecords = await this.rpcClient.export.retrieveExportRecords(options);
        this.exportRecordsCount = await this.rpcClient.export.retrieveExportRecordsCount(options);

        const userOptions: RetrieveSimpleUsersOptions = plainToClass(RetrieveSimpleUsersOptions, {
            userIds: this.exportRecords.map((er) => er.requestedBy),
        });
        this.users = await this.rpcClient.user.retrieveSimpleUsers(userOptions);
    }

    public isDataFetched(): this is ExportsPageStore & ExportsPageStoreDataFetched {
        return !!(this.exportRecords && this.users);
    }
}

export interface ExportsPageStoreDataFetched {
    exportRecords: Array<ExportRecord>;
}
