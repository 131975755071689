/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import type { Location } from "@daytrip/legacy-models";
import { AdTemplate } from "@legacy/domain/AdTemplate";
import type { GoogleCampaignSettingType } from "@legacy/domain/GoogleCampaignSettingType";
import type { SimpleLocation } from "@legacy/domain/SimpleLocation";
import { adTemplates } from "@legacy/googleAdwordsTool/adTemplates";
import { plainToClass } from "class-transformer";
import { validate } from "class-validator";

import type { RouteGoogleCampaignOperator } from "../../operators/RouteGoogleCampaignOperator";
import { getRpcClient } from "../../rpc-browser-sdk";

export async function validateAds(
    newRouteGoogleCampaign: RouteGoogleCampaignOperator,
    originLocation: Location | SimpleLocation,
    destinationLocation: Location | SimpleLocation,
    googleCampaignSettingType: GoogleCampaignSettingType,
) {
    const rpcClient = getRpcClient();
    const validationFailedTexts: Array<string> = [];

    if (originLocation && destinationLocation && newRouteGoogleCampaign) {
        const price = `€${newRouteGoogleCampaign.routeGoogleCampaign.price}`;
        const duration = `${Math.round(newRouteGoogleCampaign.routeGoogleCampaign.duration / 60)}h`;

        const originNames = getAllLocationNames(originLocation);
        const destinationNames = getAllLocationNames(destinationLocation);

        const locationNames = await rpcClient.googleCampaign.generateAllLocationNamesPairs(
            originNames,
            destinationNames,
        );
        const adTemplatesToValidate = adTemplates[googleCampaignSettingType];

        for (const agi in adTemplatesToValidate) {
            const adGroup = adTemplatesToValidate[agi];

            for (const ti in adGroup) {
                const template = adGroup[ti];

                for (const npi in locationNames) {
                    const namePair = locationNames[npi];

                    const ad = AdTemplate.transformToAd(
                        plainToClass(AdTemplate, template),
                        namePair[0],
                        namePair[1],
                        price,
                        duration,
                    );

                    // eslint-disable-next-line no-await-in-loop
                    const validationErrors = await validate(ad, { skipMissingProperties: true });
                    if (validationErrors.length > 0) {
                        validationErrors.forEach((error) => {
                            if (validationFailedTexts.indexOf(error.value) < 0) {
                                validationFailedTexts.push(error.value);
                            }
                        });
                    }
                }
            }
        }
    }

    return validationFailedTexts;
}

function getAllLocationNames(location: Location | SimpleLocation) {
    const names: Array<string> = [];

    if (location) {
        names.push(location.name);

        if (location.localNames) {
            location.localNames.forEach((name) => {
                names.push(name);
            });
        }

        if (location.otherNames) {
            location.otherNames.forEach((name) => {
                names.push(name);
            });
        }
    }

    return names;
}
