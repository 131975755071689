import type { SimpleLocation } from "@legacy/domain/SimpleLocation";
import { BlacklistedDepartureDateRangeSave } from "@legacy/models/BlacklistedDepartureDate";
import type { BlacklistedDepartureDate } from "@legacy/models/BlacklistedDepartureDate";
import type { Country } from "@legacy/models/Country";
import autobind from "autobind-decorator";
import { action, computed, observable } from "mobx";
import type { Option } from "react-select-legacy";

import { PageStore } from "../../stores/PageStore";

import type { BlacklistedDepartureDatesPageRouter } from "./BlacklistedDepartureDatesPageRouter";

@autobind
export class BlacklistedDepartureDatesPageStore extends PageStore<BlacklistedDepartureDatesPageRouter, {}> {
    @observable
    public dates: BlacklistedDepartureDate[] = [];

    @observable
    public totalCount: number = 0;

    @observable
    public countries: Country[] = [];

    @observable
    public locations: SimpleLocation[] = [];

    @observable
    public lastCreated?: Date;

    @observable
    public isLoading: boolean = true;

    @observable
    public error: string | undefined = undefined;

    @computed
    public get blacklistedDateOptions() {
        return {
            dateFrom: this.pageRouter.dateFrom,
            dateTo: this.pageRouter.dateTo,
            countryIds: this.pageRouter.countryIds,
            locationIds: this.pageRouter.locationIds,
            routeIds: this.pageRouter.routeIds,
            description: this.pageRouter.description,
            skip: this.pageRouter.skip - 1,
            limit: this.pageRouter.limit,
        };
    }

    @computed
    public get locationIdsToNamesArray() {
        if (!this.pageRouter.locationIds) {
            return [];
        }

        return this.pageRouter.locationIds.map((locationId) => {
            const location = this.locations.find(({ locationId: id }) => id === locationId);
            if (!location) {
                return "";
            }

            return location.name || "";
        });
    }

    @action
    public async fetchLocations(value: string): Promise<{ options: Array<Option> }> {
        let options: Array<Option> = [];
        if (value && value.length > 2) {
            const locations = await this.rpcClient.content.retrieveSimpleLocations({
                searchString: value,
            });

            options = locations.map((location) => ({
                value: location.locationId,
                label: location.name + (location.countryName ? `, ${location.countryName}` : ""),
            }));
        }

        return { options };
    }

    @action
    public async fetchDates() {
        const paginatedData = await this.rpcClient.blacklistedDate.retrievePaginatedBlacklistedDepartureDates(
            this.blacklistedDateOptions,
        );
        this.dates = paginatedData.data;
        this.totalCount = paginatedData.totalCount;
    }

    @action
    public async fetchContent() {
        const [countries, locations] = await Promise.all([
            this.rpcClient.content.retrieveCountries({}),
            this.rpcClient.content.retrieveSimpleLocations({}),
        ]);
        this.countries = countries;
        this.locations = locations;
    }

    @action
    public async onFetchData() {
        this.isLoading = true;
        this.error = undefined;
        try {
            await Promise.all([this.fetchDates(), this.fetchContent()]);
        } catch (e) {
            console.error(JSON.stringify(e, undefined, 2), e);
            this.error = "Something went wrong";
        } finally {
            this.isLoading = false;
        }
    }

    @action
    public async createDate(date: BlacklistedDepartureDateRangeSave) {
        this.isLoading = true;
        this.error = undefined;
        try {
            await this.rpcClient.blacklistedDate.createBlacklistedDepartureDates(date);
            this.lastCreated = new Date();
            await this.fetchDates();
        } catch (e) {
            console.error(JSON.stringify(e, undefined, 2), e);
            this.error = "Something went wrong";
        } finally {
            this.isLoading = false;
        }
    }

    @action
    public async removeDate(id: string) {
        this.isLoading = true;
        this.error = undefined;
        try {
            await this.rpcClient.blacklistedDate.deleteBlacklistedDepartureDate(id);
            await this.fetchDates();
        } catch (e) {
            console.error(JSON.stringify(e, undefined, 2), e);
            this.error = "Something went wrong";
        } finally {
            this.isLoading = false;
        }
    }

    @action
    public async editDate(id: string, data: BlacklistedDepartureDateRangeSave) {
        this.isLoading = true;
        this.error = undefined;
        try {
            await this.rpcClient.blacklistedDate.editBlacklistedDepartureDate(id, data);
            await this.fetchDates();
        } catch (e) {
            console.error(JSON.stringify(e, undefined, 2), e);
            this.error = "Something went wrong";
        } finally {
            this.isLoading = false;
        }
    }

    public isDataFetched(): this is BlacklistedDepartureDatesPageStore {
        return !this.isLoading;
    }

    @observable
    public isMultipleSelectRevealed = false;

    @observable
    public selectedBlacklistedDepartureDatesIds: Array<string> = [];

    @action
    public toggleMultipleSelect(): void {
        this.isMultipleSelectRevealed = !this.isMultipleSelectRevealed;
        this.selectedBlacklistedDepartureDatesIds = [];
    }

    @action
    public toggleBlacklistedDepartureDateSelect(inputId: string): void {
        const exists = this.selectedBlacklistedDepartureDatesIds.includes(inputId);

        if (exists) {
            this.selectedBlacklistedDepartureDatesIds = this.selectedBlacklistedDepartureDatesIds.filter(
                (selectedId) => selectedId !== inputId,
            );
        } else {
            this.selectedBlacklistedDepartureDatesIds = [...this.selectedBlacklistedDepartureDatesIds, inputId];
        }
    }

    @action
    public toggleSelectAllVisibleDates(): void {
        if (this.dates) {
            if (this.isAllVisibleDatesSelected) {
                this.selectedBlacklistedDepartureDatesIds = [];
            } else {
                const selectedDatesIds = this.dates.map((date) => date._id);
                this.selectedBlacklistedDepartureDatesIds = selectedDatesIds;
            }
        }
    }

    @computed
    public get isAllVisibleDatesSelected(): boolean {
        return (
            this.dates?.length ===
            this.dates?.filter((date) => this.selectedBlacklistedDepartureDatesIds.includes(date._id)).length
        );
    }

    @action
    public async removeAllSelectedDates() {
        this.isLoading = true;
        this.error = undefined;
        try {
            await Promise.all(
                this.selectedBlacklistedDepartureDatesIds.map((id) =>
                    this.rpcClient.blacklistedDate.deleteBlacklistedDepartureDate(id),
                ),
            );
            await this.fetchDates();
            this.selectedBlacklistedDepartureDatesIds = [];
            this.dateToEdit = null;
        } catch (e) {
            console.error(JSON.stringify(e, undefined, 2), e);
            this.error = "Something went wrong";
        } finally {
            this.isLoading = false;
        }
    }

    @observable
    public dateToEdit: BlacklistedDepartureDate | null = null;

    @action
    public setDateToEdit(date: BlacklistedDepartureDate | null) {
        this.dateToEdit = date;
    }

    @action
    public async editAllSelectedDates(data: BlacklistedDepartureDateRangeSave) {
        this.isLoading = true;
        this.error = undefined;
        try {
            const datesToEdit = this.dates.filter((date) =>
                this.selectedBlacklistedDepartureDatesIds.includes(date._id),
            );
            await Promise.all(
                datesToEdit.map(({ _id: id, date }) =>
                    this.rpcClient.blacklistedDate.editBlacklistedDepartureDate(id, { ...data, date }),
                ),
            );
            await this.fetchDates();
            this.selectedBlacklistedDepartureDatesIds = [];
            this.dateToEdit = null;
        } catch (e) {
            console.error(JSON.stringify(e, undefined, 2), e);
            this.error = "Something went wrong";
        } finally {
            this.isLoading = false;
        }
    }
}

export interface BlacklistedDepartureDatesPageStoreDataFetched {
    data: BlacklistedDepartureDate[];
    totalCount: number;
}
