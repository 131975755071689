import { RefundData } from "@legacy/domain/RefundData";
import { SimpleUser } from "@legacy/domain/SimpleUser";
import { RetrieveRefundsDataOptions } from "@legacy/options/RetrieveRefundsDataOptions";
import autobind from "autobind-decorator";
import { plainToClass } from "class-transformer";
import { action, observable, toJS } from "mobx";
import { Option } from "react-select-legacy";

import { PageStore } from "../../stores/PageStore";

import { RefundsPageRouter } from "./RefundsPageRouter";

@autobind
export class RefundsPageStore extends PageStore<RefundsPageRouter, {}> {
    @observable
    public refunds?: Array<RefundData>;

    @observable
    public refundsCount: number = 0;

    @observable
    public customerSearchOption?: Option;

    @observable
    public creatorSearchOption?: Option;

    @observable
    public users: Array<SimpleUser> = [];

    @observable
    public creators: Array<SimpleUser> = [];

    @action
    public async onFetchData() {
        await this.fetchContent();
    }

    @action
    public async fetchContent() {
        this.refunds = undefined;

        const options = {
            skip: this.pageRouter.skip,
            limit: this.pageRouter.limit,
            sortBy: this.pageRouter.sortBy,
            sortDirection: this.pageRouter.sortDirection,
            isAssigned: this.pageRouter.assigned,
            voucher: this.pageRouter.voucher,
            customerIds: this.pageRouter.userIds,
            refundedByIds: this.pageRouter.creatorIds,
            status: this.pageRouter.statusIn,
        } as RetrieveRefundsDataOptions;
        await this.setInitialValuesForFilters();
        this.refunds = plainToClass(
            RefundData,
            (await this.rpcClient.payment.retrieveRefundsData(options)) as RefundData[],
        );
        this.refundsCount = (await this.rpcClient.payment.retrieveRefundsData(options, true)) as number;
    }

    public isDataFetched(): this is RefundsPageStore & RefundsPageStoreDataFetched {
        return this.refunds != undefined;
    }

    @action
    public async onCustomersFilterInputChange(value?: string): Promise<void> {
        if (value != undefined && (value as string).length > 2) {
            this.users = plainToClass(
                SimpleUser,
                await this.rpcClient.user.retrieveSimpleUsers({
                    isDriver: false,
                    isDriversCompany: false,
                    searchString: value,
                }),
            );
        }
    }

    @action
    public async onCustomerFilterSelect(option?: Option): Promise<void> {
        this.customerSearchOption = option;
        this.pageRouter.filterUserIds(!option ? [] : ([option.value] as string[]));
    }

    public async setInitialValuesForFilters() {
        const userIds = toJS(this.pageRouter.userIds) as string[];
        const creatorIds = toJS(this.pageRouter.creatorIds) as string[];
        const haveCustomersFilter = Array.isArray(userIds) && userIds.length;
        const haveCreatorsFilter = Array.isArray(creatorIds) && creatorIds.length;
        const needToLoadIds = [];
        if (!haveCustomersFilter && !haveCreatorsFilter) {
            // In this case we do not need to load users
            return;
        }

        if (haveCustomersFilter) {
            needToLoadIds.push(...userIds);
        }
        if (haveCreatorsFilter) {
            needToLoadIds.push(...creatorIds);
        }

        const usersFromFilters = plainToClass(
            SimpleUser,
            await this.rpcClient.user.retrieveSimpleUsers({
                userIds: needToLoadIds,
            }),
        );
        this.users = usersFromFilters;
        this.creators = usersFromFilters;

        if (haveCreatorsFilter) {
            const creator = usersFromFilters.find((u) => creatorIds.includes(u._id));
            this.creatorSearchOption = creator
                ? {
                      label: `${creator.fullName} <${creator.email}>`,
                      value: creator._id,
                  }
                : undefined;
        }

        if (haveCustomersFilter) {
            const customer = usersFromFilters.find((u) => userIds.includes(u._id));
            this.customerSearchOption = customer
                ? {
                      label: `${customer.fullName} <${customer.email}>`,
                      value: customer._id,
                  }
                : undefined;
        }
    }

    @action
    public async onCreatorsFilterInputChange(value?: string): Promise<void> {
        if (value != undefined && (value as string).length > 2) {
            this.creators = plainToClass(
                SimpleUser,
                await this.rpcClient.user.retrieveSimpleUsers({
                    notDriverOrCustomer: true,
                    searchString: value,
                }),
            );
        }
    }

    @action
    public async onCreatorsFilterSelect(option: Option): Promise<void> {
        this.creatorSearchOption = option;
        this.pageRouter.filterCreatorIds(!option ? [] : ([option.value] as string[]));
    }
}

export interface RefundsPageStoreDataFetched {
    refunds: Array<RefundData>;
}
