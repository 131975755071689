import { PaymentRequest } from "@legacy/models/PaymentRequest";
import { isUndefinedOrNull } from "@legacy/utils";
import { action, observable } from "mobx";

import { ModelOperator } from "./ModelOperator";
import { ModelOperatorOptions } from "./ModelOperatorOptions";

interface PaymentRequestOperatorOptions extends ModelOperatorOptions<PaymentRequest, null, null> {}

export class PaymentRequestOperator extends ModelOperator<
    PaymentRequest,
    PaymentRequestOperatorOptions,
    null,
    null,
    null
> {
    @observable
    public orderIdToAssign?: string;

    @observable
    public isOrderConnected = false;

    @action.bound
    public assignOrder(orderId: string): void {
        this.orderIdToAssign = orderId;
    }

    @action.bound
    public async confirmAssignOrder(): Promise<void> {
        if (!isUndefinedOrNull(this.orderIdToAssign)) {
            try {
                await this.rpcClient.payment.connectPaymentRequestWithOrder(this.m._id, this.orderIdToAssign);
                this.edit((pr) => (pr.orderId = this.orderIdToAssign));

                this.isOrderConnected = true;
            } catch (e: any) {
                alert("Unable to connect payment request with order.");
            }
        } else {
            alert("Please select order, which you would like to connect payment request with.");
        }
    }
}
