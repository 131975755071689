import { Currency } from "../domain/Currency";

export class CountryInvoiceInfo {
    public isVat: boolean = false;

    public vatPercentage: number = 0;

    public isReverseCharge: boolean = false;

    public numberingTemplateId: string;

    public invoiceCurrency: Currency = Currency.Euro;
}
