import autobind from "autobind-decorator";
import { action, observable, toJS, computed } from "mobx";

import { Currency } from "@legacy/domain/Currency";
import { Country } from "@legacy/models/Country";
import { CurrencyRate } from "@legacy/models/CurrencyRate";
import { PageRouter } from "../../PageRouter";
import { PageStore } from "../../stores/PageStore";

@autobind
export class CurrenciesPageStore extends PageStore<PageRouter, null> {
    @action
    public async onFetchData() {
        await this.fetchCountries();
        await this.fetchCurrencyRates();
    }

    @observable
    public currencyRates: Array<CurrencyRate>;

    @observable
    public countries: Array<Country>;

    @observable
    public editedCurrencyRate?: CurrencyRate;

    @action
    public async fetchCurrencyRates() {
        const currencyRates = await this.rpcClient.currency.retrieveCurrencyRates();

        this.currencyRates = currencyRates;
    }

    @action
    public async fetchCountries() {
        this.countries = await this.rpcClient.content.retrieveCountries({});
    }

    public isDataFetched(): this is CurrenciesPageStore & CurrenciesPageStoreDataFetched {
        return !!(this.currencyRates && this.countries);
    }

    @action
    public setEditedCurrecnyRateId(id: string) {
        const editedCurrencyRate = this.currencyRates.find((cr) => cr._id === id) as CurrencyRate;
        this.editedCurrencyRate = toJS(editedCurrencyRate);
    }

    @action
    public releaseEditedCurrencyRate() {
        this.editedCurrencyRate = undefined;
    }

    @action
    public async saveEditedCurrencyRate() {
        if (this.editedCurrencyRate) {
            await this.rpcClient.content.updateCurrencyRate(this.editedCurrencyRate);
            await this.releaseEditedCurrencyRate();
            await this.fetchData();
        }
    }

    @action
    public async onChangeEditedCurrencyRateCoefficient(rate: number) {
        (this.editedCurrencyRate as CurrencyRate).rate = rate;
    }

    @computed
    public get availableCurrencyRatesTypes(): Array<Currency> {
        if (this.currencyRates) {
            return [...new Set(this.currencyRates.map((currencyRate) => currencyRate.baseCurrency))];
        }
        return [];
    }

    @computed
    public get availableCurrencies(): Array<Currency> {
        if (this.currencyRates) {
            return [...new Set(this.currencyRates.map((currencyRate) => currencyRate.type))];
        }
        return [];
    }
}

export interface CurrenciesPageStoreDataFetched {
    currencyRates: Array<CurrencyRate>;
    countries: Array<Country>;
}
