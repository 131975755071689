import { CountryGroup } from "@legacy/domain/CountryGroup";

import { CountryGroupFilterItem } from "../models/CountryGroupFilterItem";

export function getCountryIdsByCountryGroups(items: CountryGroupFilterItem[], groups: CountryGroup[]): string[] {
    const countriesIds = items.reduce((acc, item) => {
        if (groups.includes(item.key) || groups.includes(CountryGroup.All)) {
            return [...acc, ...item.countries.map((country) => country._id)];
        }
        return acc;
    }, []);

    return Array.from(new Set(countriesIds));
}
