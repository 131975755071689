import { GeoJsonGeofence, MeetingPosition } from "@daytrip/legacy-models";
import { LocationPosition } from "@daytrip/react-shared-components";
import autobind from "autobind-decorator";
import { action, observable } from "mobx";

import { ModelOperator } from "./ModelOperator";

@autobind
export class MeetingPositionOperator extends ModelOperator<MeetingPosition, {}, null, {}, {}> {
    @observable
    public meetingPosition: MeetingPosition;

    @observable
    public searchAddress?: string;

    @observable
    public zoom?: number;

    @observable
    public generateSatelliteImage: boolean = true;

    @observable
    public meetingGeoJsonGeofenceValidationError?: string;

    @observable
    public isAddressValid?: boolean;

    @action
    public setIsAddressValid(isValid: boolean) {
        this.isAddressValid = isValid;
    }

    @action
    public async setMeetingPositionGeofence(geofence?: GeoJsonGeofence) {
        this.m.restrictedAreaPolygon = geofence;
        await this.validate();
    }

    @action
    public updateAddress(name: string, position: LocationPosition) {
        this.m.googleAddress = name;
        this.m.position.latitude = position.latitude;
        this.m.position.longitude = position.longitude;
    }
}
