/* eslint-disable no-param-reassign */
/* eslint-disable no-alert */
import { SimpleUser } from "@legacy/domain/SimpleUser";
import { PoolLocation } from "@legacy/models/PoolLocation";
import { SellingPoint } from "@legacy/models/SellingPoint";
import { transformValidationErrorsToArrayString } from "@daytrip/legacy-transformers";
import autobind from "autobind-decorator";
import { classToPlain, plainToClass } from "class-transformer";
import { validate } from "class-validator";
import { action, computed, observable, toJS } from "mobx";

import { BaseLocationPageStore } from "../BaseLocationPageStore";

@autobind
export class PoolLocationPageStore extends BaseLocationPageStore {
    @observable
    public location?: PoolLocation;

    @observable
    public editedLocation?: PoolLocation;

    @observable
    public createdByUser?: SimpleUser;

    @observable
    public isDeleting = false;

    @observable
    public isEdited = false;

    @observable
    public isInCreateMode = false;

    @observable
    public createModeConfirmed = false;

    @observable
    public isCreating = false;

    @computed
    public get poolLocation() {
        return this.isEdited ? this.editedLocation : this.location;
    }

    @action
    public async onFetchData() {
        await this.fetchContent();
    }

    @action
    public async fetchContent() {
        const { locationId } = this.pageRouter;

        if (!locationId) {
            throw Error("This page must be entered from location tab!");
        }

        const rawPoolLocationObject = await this.rpcClient.content.retrievePoolLocation(locationId);
        if (rawPoolLocationObject) {
            this.location = classToPlain(plainToClass(PoolLocation, rawPoolLocationObject)) as PoolLocation;
            this.editedLocation = toJS(this.location);

            if (this.location?.createdBy) {
                const fetchedUsers = await this.rpcClient.user.retrieveSimpleUsers({
                    userIds: [this.location.createdBy],
                });
                [this.createdByUser] = fetchedUsers;
            }
        } else {
            const relatedLocation = await this.rpcClient.content.retrieveLocation(locationId);
            if (!relatedLocation) {
                throw new Error(`Location ${locationId} does not exist!`);
            }
            this.isInCreateMode = true;
            this.isEdited = true;
            // eslint-disable-next-line prefer-object-spread
            this.editedLocation = Object.assign({}, new PoolLocation(), {
                locationId,
                radiusKm: relatedLocation.radiusKm || 15,
                images: relatedLocation.images,
                perex: relatedLocation.perexLocalized.en,
                createdBy: this.authenticationStore.userJWT?.userId,
            });
        }
    }

    @computed
    public get locationId() {
        return this.pageRouter.locationId;
    }

    @action
    public updateField<PoolLocationKey extends keyof PoolLocation>(
        property: PoolLocationKey,
        value: PoolLocation[PoolLocationKey],
    ) {
        this.editedLocation![property] = value;
    }

    @action
    public updateSellingPoint(updatedValue: Partial<SellingPoint>, index: number) {
        this.editedLocation!.sellingPoints[index] = {
            ...this.editedLocation?.sellingPoints[index],
            ...updatedValue,
        } as SellingPoint;
    }

    @action
    public addSellingPoint() {
        this.editedLocation!.sellingPoints.push({ text: "", iconName: "" });
    }

    @action
    public removeSellingPoint(index: number) {
        this.editedLocation!.sellingPoints.splice(index, 1);
    }

    @action
    public async locationValidate() {
        this.editedLocationValidationErrors = await validate(plainToClass(PoolLocation, toJS(this.editedLocation)), {
            skipMissingProperties: true,
        });
        if (this.editedLocationValidationErrors.length > 0) {
            const validationMessages = transformValidationErrorsToArrayString(this.editedLocationValidationErrors).join(
                "\n",
            );
            alert(`Oh, something is wrong. :(\n\nCheck validations, please:\n${validationMessages}`);
        }
    }

    public async updatePoolLocation() {
        await this.locationValidate();

        if (this.editedLocationValidationErrors.length > 0) {
            return;
        }
        try {
            await this.rpcClient.content.updatePoolLocation(toJS(this.editedLocation!));
            this.isEdited = false;
            this.editedLocation = undefined;
            await this.fetchData();
        } catch (e: any) {
            alert(`Not saved, contact admin. error: ${e}`);
        }
    }

    @action
    public locationEditCancelOrGoBack() {
        if (!this.editedLocation) {
            window.history.back();
        } else {
            this.isEdited = false;
            this.editedLocation = this.location;
        }
    }

    @action
    public async locationEdit() {
        this.isEdited = true;
    }

    @action
    public async confirmCreateMode() {
        this.createModeConfirmed = true;
    }

    @action
    public async locationAdd() {
        await this.locationValidate();

        if (this.editedLocationValidationErrors.length > 0) {
            return;
        }
        //   console.log("here");
        try {
            this.isCreating = true;
            await this.rpcClient.content.createPoolLocation(toJS(this.editedLocation!));
            this.isEdited = false;
            this.isInCreateMode = false;
            this.editedLocation = undefined;
            await this.fetchData();
        } catch (e: any) {
            alert(`Not saved, contact admin. error: ${e}`);
        } finally {
            this.isCreating = false;
        }
    }

    @action
    public async locationDelete() {
        this.isDeleting = true;
        try {
            await this.rpcClient.content.deletePoolLocation(this.poolLocation!.locationId);
            this.pageRouter.gotoLocationsPage();
        } catch (e: any) {
            this.isDeleting = false;
            alert(`Not deleted, contact admin. error: ${e}`);
        }
    }

    public isDataFetched(): this is PoolLocationPageStore & PoolLocationPageStoreDataFetched {
        return !!this.poolLocation;
    }
}

export interface PoolLocationPageStoreDataFetched {
    location: PoolLocation;
    editedLocation: PoolLocation;
    poolLocation: PoolLocation;
}
