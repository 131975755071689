import { Expose, Transform, Type } from "class-transformer";
import { IsDate, IsDefined, IsEnum, IsOptional, IsString, IsUUID, IsNumber } from "class-validator";
import { v4 as uuid } from "uuid";

import { Currency } from "../domain/Currency";
import { CDBaseEntity, CDColumn, CDCreateDateColumn, CDEntity, CDObjectIdColumn } from "../orm";

@CDEntity("debts")
export class DriverDebt extends CDBaseEntity {
    @CDObjectIdColumn()
    @IsUUID("4")
    @Expose()
    public _id: string = uuid();

    @CDCreateDateColumn()
    @IsDate()
    @IsDefined()
    @Expose()
    @Type(() => Date)
    @Transform((value) => new Date(value))
    public createdAt: Date = new Date();

    @CDColumn()
    @IsString()
    @IsOptional()
    @Expose()
    public createdByUserId?: string;

    @CDColumn()
    @IsUUID("4")
    @IsDefined()
    @Expose()
    public driverUserId: string;

    @CDColumn()
    @IsNumber()
    @IsDefined()
    @Expose()
    public amount: number;

    @CDColumn()
    @IsString()
    @IsOptional()
    @Expose()
    public note?: string;

    @CDColumn()
    // assignation where the debt was made (for example the penalty was added after the assignation was paid out)
    @IsUUID("4")
    @IsOptional()
    @Expose()
    public assignationId?: string;

    @CDColumn()
    // when defined, the debt was removed in this payout
    @IsUUID("4")
    @IsOptional()
    @Expose()
    public payoutId?: string;

    @CDColumn()
    @IsEnum(Currency)
    @IsDefined()
    @Expose()
    public currency = Currency.Euro;
}
