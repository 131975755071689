import type { PayoutType } from "../domain/PayoutType";

export class UserBalance {
    public userId: string;

    public fullName: string;

    public payoutType?: PayoutType;

    public payoutProblem?: string;

    public driversCompanyName?: string;

    public email: string;

    public amount: number;

    public debtAmount: number;

    public penaltiesAmount: number;

    public subsidiesAmount: number;

    public compensationsAmount: number;
}
