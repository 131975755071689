import autobind from "autobind-decorator";
import { action, observable } from "mobx";

import { Currency } from "@legacy/domain/Currency";
import { NumberingTemplate } from "@legacy/models/NumberingTemplate";
import { PageStore } from "../../stores/PageStore";

@autobind
export class NumberingTemplatesPageStore extends PageStore<any, null> {
    @observable
    public numberingTemplates?: Array<NumberingTemplate>;

    @observable
    public prefix: string = "";

    @observable
    public digits: number = 0;

    @observable
    public invoiceEntity: string = "";

    @observable
    // name "currency" is defined in PageStore, and we cannot overload it with different type
    public numberingTemplateCurrency: Currency = Currency.Euro;

    @action
    public setPrefix(prefix: string) {
        this.prefix = prefix;
    }

    @action
    public setDigits(digits: number) {
        this.digits = digits;
    }

    @action
    public setInvoiceEntity(invoiceEntity: string) {
        this.invoiceEntity = invoiceEntity;
    }

    @action
    public setNumberingTemplateCurrency(currency: Currency) {
        this.numberingTemplateCurrency = currency;
    }

    @observable
    public creatingInProgress: boolean = false;

    @observable
    public lastCreated?: Date;

    @action
    public async createNumberingTemplate() {
        if (!this.prefix || !this.digits || !this.invoiceEntity) {
            return;
        }

        this.creatingInProgress = true;

        await this.rpcClient.invoice.createNumberingTemplate(
            Object.assign(new NumberingTemplate(), {
                prefix: this.prefix,
                digits: this.digits,
                invoiceEntity: this.invoiceEntity,
                currency: this.numberingTemplateCurrency,
            }),
        );

        this.prefix = "";
        this.digits = 0;
        this.invoiceEntity = "";
        this.numberingTemplateCurrency = Currency.Euro;

        this.lastCreated = new Date();
        this.creatingInProgress = false;

        this.numberingTemplates = undefined;
        await this.fetchData();
    }

    public isDataFetched(): boolean {
        return !!this.numberingTemplates;
    }

    @action
    public async onFetchData() {
        this.numberingTemplates = await this.rpcClient.invoice.getAllNumberingTemplates();
    }

    @action
    public async removeNumberingTemplate(id: string) {
        await this.rpcClient.invoice.removeNumberingTemplate(id);

        this.numberingTemplates = undefined;
        await this.fetchData();
    }
}

export interface NumberingTemplatesPageStoreDataFetched {
    numberingTemplates: Array<NumberingTemplate>;
}
