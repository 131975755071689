import { Position } from "@daytrip/legacy-models";
import { LocationPosition } from "@daytrip/react-shared-components";
import type { MangopayPersonType } from "@legacy/domain/MangopayPersonType";
import type { SimpleCountry } from "@legacy/domain/SimpleCountry";
import type { SimpleUser } from "@legacy/domain/SimpleUser";
import { Driver } from "@legacy/models/Driver";
import type { User } from "@legacy/models/User";
import { isUndefinedOrNull } from "@legacy/utils";
import autobind from "autobind-decorator";
import type { ValidationError } from "class-validator";
import { action, computed, observable } from "mobx";

import { getPhoneNumberExtendedValidationMessage } from "../utils/validateUserPhoneNumber";

import { ModelOperator } from "./ModelOperator";
import type { ModelOperatorOptions } from "./ModelOperatorOptions";
import { getVatValidationMessage } from "../utils/getVatValidationMessage";

interface DriverOperatorOptions extends ModelOperatorOptions<User, null, DriverOperatorData> {}

interface DriverOperatorData {
    simpleCountries?: Array<SimpleCountry>;
    managers?: Array<SimpleUser>; // driver managers
}

interface DriverOperatorDataFetched {
    simpleCountries: Array<SimpleCountry>;
}

@autobind
export class DriverOperator extends ModelOperator<
    User,
    DriverOperatorOptions,
    null,
    DriverOperatorData,
    DriverOperatorDataFetched
> {
    @observable
    public isAddressValid?: boolean;

    @computed
    public get country(): undefined | SimpleCountry {
        return this.processWhenDataFetched((self) => self.data.simpleCountries.find((c) => c._id === this.m.countryId));
    }

    @computed
    public get mangopayPersonType(): MangopayPersonType | undefined {
        return isUndefinedOrNull(this.m.activeMangopayInformation)
            ? undefined
            : this.m.activeMangopayInformation.personType;
    }

    @action setIsAddressValid(isValid: boolean) {
        this.isAddressValid = isValid;
    }

    @action
    public addressUpdate(name: string, position: LocationPosition) {
        this.driver.address = name;
        this.driver.position = new Position(position.latitude, position.longitude);
        this.driver.town = name;
    }

    @computed
    public get driver(): Driver {
        return this.m.driver as Driver;
    }

    // preactivated
    @observable
    public isPremium: boolean = false;

    @observable
    public isSuitableForLuxury: boolean = false;

    @observable
    public isSuitableForPool: boolean = false;

    @observable
    public isKYCValid: boolean = false;

    public getDriverValidations(propertyName: keyof Driver): Array<ValidationError> {
        const driverValidation = this.getValidations("driver")[0];
        return driverValidation?.children?.filter((v) => v.property === propertyName) || [];
    }

    @computed
    public get getPhoneNumberValidationMessage(): string | undefined {
        const validationMessages = this.getValidationMessages("phoneNumber");

        return getPhoneNumberExtendedValidationMessage(this.m.phoneNumber, validationMessages);
    }

    public getDriverValidationMessages(propertyName: keyof Driver): string | undefined {
        const result = this.getDriverValidations(propertyName)
            .map((v) => Object.values(v?.constraints || {}).join("\n"))
            .join("");
        return result || undefined;
    }

    @action
    public async triggerPremium(): Promise<void> {
        (this.m.driver as Driver).premiumFrom = await this.rpcClient.driver.triggerPremiumDriver(this.m._id);

        this.isPremium = !isUndefinedOrNull((this.m.driver as Driver).premiumFrom);

        this.m.version += 1;
    }

    @action
    public async enableIsSuitableForLuxury(): Promise<void> {
        await this.rpcClient.driver.enableIsSuitableForLuxury(this.m._id);
        (this.m.driver as Driver).isSuitableForLuxury = true;
        this.isSuitableForLuxury = true;
        this.m.version += 1;
    }

    @action
    public async disableIsSuitableForLuxury(): Promise<void> {
        await this.rpcClient.driver.disableIsSuitableForLuxury(this.m._id);
        (this.m.driver as Driver).isSuitableForLuxury = false;
        this.isSuitableForLuxury = false;
        this.m.version += 1;
    }

    @action
    public async enableIsSuitableForPool(): Promise<void> {
        await this.rpcClient.driver.enableIsSuitableForPool(this.m._id);
        (this.m.driver as Driver).isSuitableForPool = true;
        this.isSuitableForPool = true;
        this.m.version += 1;
    }

    @action
    public async disableIsSuitableForPool(): Promise<void> {
        await this.rpcClient.driver.disableIsSuitableForPool(this.m._id);
        (this.m.driver as Driver).isSuitableForPool = false;
        this.isSuitableForPool = false;
        this.m.version += 1;
    }

    @observable
    public vatValidationErrorMessage: string | undefined;

    @action
    public async setShouldShowInvalidVatError() {
        this.vatValidationErrorMessage = await getVatValidationMessage(this.m, this.rpcClient);
    }
}
